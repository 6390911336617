/* @flow */
import React from "react";

import ElipisImage from "components/common/images/ellipse.png";
import ProductPreview from "components/common/images/product_preview.png";

import type { Props } from "./types.js";
import { Content, Header, Image, Data, Attribute } from "./styles";

import { Title } from "../styles";

const ShopifyFormPreview = (props: Props) => {
  const { importAttributes } = props;

  return (
    <>
      <Title>Post preview</Title>
      <Content>
        <Header>
          <img src={ElipisImage} alt="account" />
          <span>Account</span>
        </Header>

        <Image>
          <div>
            <img src={ProductPreview} alt="product_preview" />
          </div>
        </Image>

        {importAttributes?.length ? (
          <Data>
            {importAttributes.includes("name") && (
              <Attribute className="name">Product Name</Attribute>
            )}
            {importAttributes.includes("pricing") && (
              <Attribute>$12.34</Attribute>
            )}
            {importAttributes.includes("description") && (
              <Attribute>Product description</Attribute>
            )}
            {importAttributes.includes("url") && (
              <Attribute>
                http://YourShopURL.myshopify.com/products/example-product
              </Attribute>
            )}
          </Data>
        ) : null}
      </Content>
    </>
  );
};

export default ShopifyFormPreview;
