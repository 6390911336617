// @flow
import React, { Component } from "react";
import Alert from "layout/Alert";
import styles from "./index.css";

import type { Flash as FlashFlowType } from "reducers/flash";

type Props = {
  value: FlashFlowType,
  onDismiss: (id: string) => void,
  useNewUI?: boolean
};

// Formula for the delay before dismissing a flash based on discussion here:
// http://cogsci.stackexchange.com/questions/1147/how-long-does-it-take-to-read-a-sentence-with-x-number-of-characters
const MIN_TIME = 3500;
const BASELINE_TIME = 1300;
const PER_CHAR_TIME = 65;
const getDisplayTime = (msg: string) => {
  const time = BASELINE_TIME + msg.length * PER_CHAR_TIME;
  return time > MIN_TIME ? time : MIN_TIME;
};

export default class Flash extends Component<Props> {
  componentDidMount() {
    const {
      value: { body, persistent }
    } = this.props;

    if (persistent) {
      return;
    }

    setTimeout(this.handleDismiss, getDisplayTime(body));
  }

  handleDismiss = () => {
    const {
      value: { id },
      onDismiss
    } = this.props;
    onDismiss(id);
  };

  render() {
    const {
      value: { type, body },
      useNewUI
    } = this.props;

    return (
      <Alert
        className={useNewUI ? styles.root : styles.rootOld}
        type={type}
        onDismiss={this.handleDismiss}
      >
        <span dangerouslySetInnerHTML={{ __html: body }} />
      </Alert>
    );
  }
}
