/* @flow */
import React, { Component } from "react";
import { compact, values, pick } from "lodash";
import withFilter from "behavioral/withFilter";
import Caption from "typography/Caption";
import Link from "links/Link";
import Icon from "icons/Icon";
import styles from "./index.css";

import type { ContentFilter } from "types";

type Props = {
  filter: ContentFilter,
  onFilterPatch: (filterChange: ContentFilter) => void
};

const CLEARABLE_FILTER_KEYS: Array<string> = [
  "feed",
  "category",
  "platform",
  "account",
  "useOnce",
  "startDate",
  "endDate",
  "hasPublishableVariations"
];

export class Clear extends Component<Props> {
  getClearableFilter = (): ContentFilter =>
    pick(this.props.filter, CLEARABLE_FILTER_KEYS);

  appliedFiltersCount = (): number =>
    compact(values(this.getClearableFilter())).length;

  handleClear = (): void =>
    this.props.onFilterPatch(
      CLEARABLE_FILTER_KEYS.reduce(
        (memo, filterKey) => ({
          ...memo,
          [filterKey]: null
        }),
        this.getClearableFilter()
      )
    );

  render() {
    if (this.appliedFiltersCount() === 0) {
      return null;
    }

    return (
      <Caption helpText className={styles.root}>
        <Link className={styles.link} onClick={this.handleClear}>
          Clear all <Icon type="times" />
        </Link>
      </Caption>
    );
  }
}

export default withFilter()(Clear);
