/* @flow */
import React, { useState, useEffect, useCallback } from "react";
import { colorFromIndex } from "colors";
import Select, { Props as SelectProps } from "forms/Select";

import { useQuery, useMutation } from "@apollo/react-hooks";
import AddCategoryOption from "./AddCategoryOption";
import CategoryActionModal from "components/Categories/CategoryActionModal";

import { DEFAULT_CATEGORY_NAME } from "constants";
import { CATEGORIES_SELECTOR } from "queries/category";
import { CREATE_CATEGORY } from "mutations/category";

export type CategoryOption = {
  id: string,
  colorIndex: number,
  name: string
};

type Props = {
  disableAddButton?: boolean,
  disableDefaultCategory?: boolean,
  addRandomCategory?: boolean,
  readOnly?: boolean
} & SelectProps;

function colorWithRandom(idx) {
  if (!idx) {
    return null;
  }
  return idx == -1 ? "var(--inky400)" : colorFromIndex(idx);
}

const CategorySelect = ({
  value,
  disableAddButton,
  disableDefaultCategory,
  addRandomCategory,
  onChange,
  readOnly,
  ...selectProps
}: Props) => {
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const { loading, error, data } = useQuery(CATEGORIES_SELECTOR);

  let categories = addRandomCategory
    ? [
        {
          id: "0",
          colorIndex: -1,
          name: "Random"
        }
      ]
    : [];
  let canAddCategory;

  const handleChange = useCallback(
    ({ value, settingDefaultValue }) => {
      if (readOnly) return;
      const nextCategory =
        (categories && categories.find(({ id }) => id === value)) ?? {};

      setSelectedCategory({
        value: nextCategory.id,
        label: nextCategory.name,
        color: colorWithRandom(nextCategory.colorIndex)
      });

      return onChange({ ...nextCategory, settingDefaultValue });
    },
    [categories, onChange, readOnly]
  );

  const handleOnAddedCategory = useCallback(
    category => {
      if (readOnly) return;
      setSelectedCategory({
        value: category.id,
        label: category.name,
        color: colorWithRandom(category.colorIndex)
      });

      onChange({ ...category, isNew: true });
    },
    [onChange, readOnly]
  );

  useEffect(() => {
    const currentCategory = categories.find(c => c.id === value);

    if (currentCategory) {
      if (currentCategory.id !== selectedCategory?.value) {
        setSelectedCategory({
          value: currentCategory.id,
          label: currentCategory.name,
          color: colorWithRandom(currentCategory.colorIndex)
        });
      }
    } else if (!disableDefaultCategory && categories.length && !value) {
      const defaultCategory = categories.find(
        ({ name }) => name === DEFAULT_CATEGORY_NAME
      );

      if (!defaultCategory) {
        throw new Error(
          `Attempted to set "${DEFAULT_CATEGORY_NAME}" category as default but it does not exist.`
        );
      }

      handleChange({
        value: defaultCategory.id,
        settingDefaultValue: true
      });
    }
  }, [
    categories,
    value,
    disableDefaultCategory,
    handleChange,
    selectedCategory
  ]);

  if (!loading && !error && data) {
    categories = categories.concat(data.categories);
    canAddCategory = disableAddButton
      ? false
      : !!(data.company && data.company.categoryLimit > categories.length);
  }

  const options = categories
    ? categories.map(c => ({
        value: c.id,
        label: c.name,
        color: colorWithRandom(c.colorIndex)
      }))
    : [];

  return (
    <>
      <Select
        id="select-category"
        {...selectProps}
        value={selectedCategory}
        options={options}
        onChange={handleChange}
        isDisabled={!!readOnly}
        footer={
          canAddCategory ? (
            <AddCategoryOption onClick={() => setShowAddCategory(true)} />
          ) : null
        }
      />
      {!readOnly && showAddCategory ? (
        <AddCategoryModal
          onCloseAddCategory={() => setShowAddCategory(false)}
          onAddedCategory={handleOnAddedCategory}
        />
      ) : null}
    </>
  );
};

const AddCategoryModal = ({ onCloseAddCategory, onAddedCategory }) => {
  const [addModalErrors, setAddModalErrors] = useState([]);
  const [createCategory] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [{ query: CATEGORIES_SELECTOR }],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    onCompleted: ({ createCategory: { category, errors } }) => {
      setAddModalErrors(errors);

      if (errors.length) {
        return;
      }
      onCloseAddCategory();
      onAddedCategory(category);
    }
  });

  const handleCreateCategory = (_categoryId, name, useForRandom) => {
    createCategory({
      variables: {
        input: {
          name,
          useForRandom
        }
      }
    });
  };

  return (
    <CategoryActionModal
      errors={addModalErrors}
      handleClose={onCloseAddCategory}
      handleSubmit={handleCreateCategory}
    />
  );
};

export default CategorySelect;
