// @flow
import React, { type Node } from "react";
// import { connect } from "react-redux";
import invariant from "invariant";
import classNames from "classnames";
import { FocusOn } from "react-focus-on";
import CloseButton from "buttons/CloseButton";
import { H3 } from "typography";
import { useNewUICached } from "util";

// import type { AppState } from "types";

import styles from "./index.css";
// import appStyles from "../../../../App/index.css";

type Props = {
  size?: "small" | "medium" | "large" | "legacy",
  title?: string,
  footer?: Node,
  newFooter?: Node,
  closeOnOverlayClick?: boolean,
  autoFocus?: boolean,
  className?: string,
  children: Node,
  onClose?: ?(evt: Event) => void,
  // navbarCollapsed: boolean,
  forceCenter?: boolean
};

class Modal extends React.Component<Props> {
  componentDidMount() {
    invariant(document.body, "document.body does not exist!");
    document.body.classList.toggle(styles.lockBodyScroll, true);
  }

  componentWillUnmount() {
    invariant(document.body, "document.body does not exist!");
    document.body.classList.toggle(styles.lockBodyScroll, false);
  }

  handleClose = (evt: Event): void => {
    const { onClose } = this.props;
    evt.stopPropagation();
    if (onClose) onClose(evt);
  };

  handleOverlayClick = (evt: Event): void => {
    if (this.props.closeOnOverlayClick) {
      this.handleClose(evt);
    }
  };

  render() {
    const useNewUI = useNewUICached();

    const {
      children,
      title,
      footer = null,
      newFooter = null,
      className,
      size = "medium",
      autoFocus = true,
      onClose = null,
      forceCenter
    } = this.props;

    const rootClasses = classNames(styles.root, styles[size], {
      [className || ""]: !!className,
      [styles.hasHeader]: !!title,
      [styles.hasFooter]: !!footer || !!newFooter,
      [styles.simpleHeader]: !footer && !newFooter && !!title,
      [styles.newRootUI]: useNewUI,
      [styles.newRootUICollapsedSchedule]:
        useNewUI && window.location.pathname.includes("/schedule"),
      [styles.forceCenter]: forceCenter
      // [appStyles.newRootUICollapsed]: useNewUI && this.props.navbarCollapsed
    });

    return (
      <>
        <div className={styles.overlay} onClick={this.handleOverlayClick} />
        <FocusOn
          autoFocus={autoFocus}
          onClickOutside={this.handleOverlayClick}
          onEscapeKey={this.handleClose}
          shards={[]}
        >
          <div
            className={rootClasses}
            role="dialog"
            tabIndex="-1"
            aria-modal="true"
            aria-labelledby="title"
          >
            {!!onClose && (
              <CloseButton
                className={styles.closeButton}
                onClick={this.handleClose}
              />
            )}

            {!!title && (
              <header>
                <H3 id="title">{title}</H3>
              </header>
            )}

            <main className={styles.main}>{children}</main>

            {!!footer && <footer className={styles.footer}>{footer}</footer>}
            {!!newFooter && (
              <footer className={styles.newFooter}>{newFooter}</footer>
            )}
          </div>
        </FocusOn>
      </>
    );
  }
}

// const mapStateToProps = (state: AppState) => ({
//   navbarCollapsed: state.layout?.data.navbarCollapsed
// });

// export default connect(mapStateToProps)(Modal);
export default Modal;
