/* @flow */
import React from "react";
import Select from "../common/Select";
import SocialMediaIcon from "icons/SocialMediaIcon";
import type { Platform } from "graphql-types/loadFilters";

type Props = {
  loading: boolean,
  platforms: { value: Platform, name: string }[]
};

export const SocialNetwork = ({ loading, platforms }: Props) => {
  const getLabel = (platform) => {
    switch (platform.name) {
      case "Google":
        return "Google My Business";
      case "Twitter":
        return "X";
      default:
        return platform.name;
    }
  }
  const options = platforms.map(platform => ({
    value: platform.value.toLowerCase(),
    label: getLabel(platform),
    icon: <SocialMediaIcon value={platform.value.toLowerCase()} />
  }));
  return (
    <Select
      label="Social network"
      filterKey="platform"
      options={options}
      loading={loading}
    />
  );
};

export default SocialNetwork;
