/* @flow */
import React from "react";

import { featureFlag } from "util";

import ConnectionImportCard from "./ConnectionImportCard";

import styles from "./index.css";
import { availableConnections } from "./utils";

function sortConnections(providerArray) {
  // product provides us with a specific display order
  return providerArray.sort((a, b) => {
    if (a.displayPriority < b.displayPriority) {
      return -1;
    }
    return 1;
  });
}

const AddNew = () => {
  const sortedConnections = sortConnections(availableConnections);

  /**
   * cards know how to handle actions based on provider properties that are
   *  passed in; no need to define them here
   */
  return (
    <div className={styles.root}>
      {sortedConnections.map(connection =>
        connection.title === "Shopify" && !featureFlag("shopify_ui") ? null : (
          <ConnectionImportCard
            key={connection.title}
            connection={connection}
          />
        )
      )}
    </div>
  );
};

export default AddNew;
