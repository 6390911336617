/* eslint-disable flowtype/require-valid-file-annotation */
import React, { type Node } from "react";
import classnames from "classnames";
import styles from "./index.css";

type Props = {
  padded?: boolean,
  isRecommended?: boolean,
  children: Node,
  isLink?: Boolean,
  onClick?: EventHandler,
  className?: string
};

const Card = ({
  padded = true,
  isRecommended = false,
  children,
  isLink = false,
  onClick,
  className = ""
}: Props) => (
  <div
    className={classnames(
      className,
      styles.root,
      padded ? styles.padded : null,
      isRecommended ? styles["is-recommended"] : null,
      isLink ? styles["is-link"] : null
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Card;
