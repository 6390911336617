/* @flow */
import React from "react";
import classnames from "classnames";
import * as TimeUtil from "./time";
import styles from "./index.css";

type Props = {
  value: string,
  disabled?: boolean,
  onChange: (newValue: string) => void,
  onBlur?: () => void,
  id?: string,
  className?: string
};

function handleChange(onChange, evt) {
  return onChange(evt.target.value);
}

function handleBlur(onChange, onBlur, evt) {
  const value = TimeUtil.parse(evt.target.value);
  return new Promise(resolve => {
    setTimeout(() => {
      onChange(TimeUtil.stringify(value));
      onBlur && onBlur();
      resolve();
    }, 0);
  });
}

const TimeInput = ({
  id,
  value,
  disabled,
  onChange,
  onBlur,
  className
}: Props) => (
  <input
    id={id}
    value={value}
    disabled={disabled}
    onChange={handleChange.bind(null, onChange)}
    onBlur={handleBlur.bind(null, onChange, onBlur)}
    className={classnames(styles.root, className)}
  />
);

export { TimeUtil };
export default TimeInput;
