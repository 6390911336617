/* @flow */
import React from "react";
import classNames from "classnames";

import Label from "typography/Label";
import Caption from "typography/Caption";
import RadioButton from "forms/RadioButton2";

import type {
  getUserSettings_user_company as Company,
  getUserSettings_user_company_subscriptionPlan as SubscriptionPlan
} from "graphql-types/getUserSettings";

import styles from "./index.css";

type Props = {
  isCurrent?: boolean,
  isSelectable?: boolean,
  isSelected?: boolean,
  plan: SubscriptionPlan,
  refPlan?: SubscriptionPlan,
  selectPaymentCycle?: (selectedPaymentCycle: ?string) => void,
  company?: Company
};

const DEFAULT_PLAN_SAVINGS_AMOUNT = -1;
const MONTHS_IN_YEAR = 12;

export const computeYearlyPlanAmount = (plan: SubscriptionPlan): number => {
  const amount = plan.amount || 0;
  switch (plan.interval) {
    case "month":
      return amount * MONTHS_IN_YEAR;
    case "year":
      return amount;
    default:
      return DEFAULT_PLAN_SAVINGS_AMOUNT;
  }
};

const computeYearlyPlanSavingsAmount = (
  plan: SubscriptionPlan,
  refPlan: SubscriptionPlan
): number => {
  const planYearly = computeYearlyPlanAmount(plan);
  const refPlanYearly = computeYearlyPlanAmount(refPlan);
  if (refPlanYearly < 0 || planYearly < 0) {
    return DEFAULT_PLAN_SAVINGS_AMOUNT;
  }
  return refPlanYearly - planYearly;
};

export const formatAmount = (amount: number): string => {
  return `$${amount.toFixed(2)}`;
};

export const formatInterval = (
  interval: string,
  intervalCount: number
): string => {
  if (interval === "") {
    return "";
  }
  return intervalCount > 1 ? `every ${interval}s` : `per ${interval}`;
};

const computePaymentCycleLabel = (plan: SubscriptionPlan) => {
  if (!["month", "year"].includes(plan.interval)) {
    return "Custom";
  } else if (plan.interval === "year") {
    return "Annual";
  } else {
    return "Monthly";
  }
};

const additionalAccounts = (plan: SubscriptionPlan) => {
  if (plan.productName == "edgar_lite") {
    return plan.interval == "month"
      ? "$4.99/mo per additional account"
      : "$3.99/month ($47.88/yr) per additional account";
  } else if (plan.productName == "edgar") {
    return plan.interval == "month"
      ? "$2.99/mo per additional account"
      : "$1.99/month ($23.88/yr) per additional account";
  }
};

export const Body = ({
  isSelectable = false,
  plan,
  refPlan,
  company
}: Props) => {
  const yearlyPlanSavingsAmount = refPlan
    ? computeYearlyPlanSavingsAmount(plan, refPlan)
    : DEFAULT_PLAN_SAVINGS_AMOUNT;

  const amountDetails = company => {
    return (
      <div>
        <span>
          {formatAmount(plan.amount || 0)}{" "}
          {formatInterval(plan.interval || "", plan.intervalCount || 0)}
        </span>
        {(yearlyPlanSavingsAmount === 100.88 ||
          Number(yearlyPlanSavingsAmount.toFixed(2)) === 60.88) && (
          <Caption className={styles.savings}>
            (save {formatAmount(yearlyPlanSavingsAmount)} every year!)
          </Caption>
        )}
        {!company?.shopifyOrigin && (
          <Caption className={styles.savings}>
            {additionalAccounts(plan)}
          </Caption>
        )}
      </div>
    );
  };

  return (
    <>
      <Label
        className={classNames({
          [styles.labelOverride]: isSelectable
        })}
      >
        {computePaymentCycleLabel(plan)}
      </Label>

      {amountDetails(company)}
    </>
  );
};

const PaymentCycle = (props: Props) => {
  const { isCurrent = false, isSelectable = false, isSelected = false } = props;
  if (isSelectable) {
    const addClass = isSelected ? styles.selectedPlan : styles.unselectedPlan;
    return (
      <div
        className={`${styles.plan} ${styles.selectablePlan} ${addClass}`}
        data-plan-is-current={isCurrent}
        data-plan-is-selected={isSelected}
        onClick={() =>
          props.selectPaymentCycle &&
          props.selectPaymentCycle(props.plan.interval)
        }
      >
        <RadioButton className={styles.radioButton} selected={isSelected} />
        <div>
          <Body {...props} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.plan} data-plan-is-current={isCurrent}>
      <Body {...props} />
    </div>
  );
};

export default PaymentCycle;
