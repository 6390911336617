// @flow
import { flatMap, get, toPairs } from "lodash";
import invariant from "invariant";

import type { ThunkedAction } from "types";
import type { FlashType } from "reducers/flash";

export const CREATE_FLASH = "CREATE_FLASH";
export const DISMISS_FLASH = "DISMISS_FLASH";

type CreateFlashAction = {
  type: "CREATE_FLASH",
  payload: { type: FlashType, body: string }
};
export const createFlash = (type: FlashType, body: string) =>
  ({
    type: CREATE_FLASH,
    payload: { type, body }
  }: CreateFlashAction);

type DismissFlashAction = {
  type: "DISMISS_FLASH",
  payload: { id: string }
};
export const dismissFlash = (id: string) =>
  ({
    type: DISMISS_FLASH,
    payload: { id }
  }: DismissFlashAction);

type MessagesByType = { [key: FlashType]: string };
const extractMessages = ({ meta: { messages } }) =>
  flatMap(
    Object.keys(messages),
    (key): MessagesByType[] => {
      const msgType = key.replace(/s$/, "");
      return messages[key].map(msg => ({ [msgType]: msg }));
    }
  );

export const createFlashesFromApiResponse = (
  response: Object
): ThunkedAction => dispatch => {
  if (!get(response, "meta.messages")) {
    return Promise.resolve(null);
  }

  return Promise.resolve(
    extractMessages(response).map(msg => {
      const pair = toPairs(msg)[0];
      invariant(
        pair[0] === "notice" || pair[0] === "error" || pair[0] === "alert",
        "Unexpected flash type"
      );
      return dispatch(createFlash(pair[0], pair[1]));
    })
  );
};

export type FlashAction = CreateFlashAction | DismissFlashAction;
