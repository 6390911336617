/* @flow */
import { TOGGLE_NAVBAR_COLLAPSE } from "actions/layout";
import type { Action } from "types";

export type LayoutState = {
  data: {
    navbarCollapsed: boolean
  }
};

export const initialState: LayoutState = {
  data: {
    navbarCollapsed:
      (localStorage.getItem("navbarCollapsed") || "").toString() === "true"
  }
};

export default (state: LayoutState = initialState, action: Action) => {
  switch (action.type) {
    case TOGGLE_NAVBAR_COLLAPSE: {
      localStorage.setItem("navbarCollapsed", action.payload.toString());
      return {
        data: {
          ...state.data,
          navbarCollapsed: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
};
