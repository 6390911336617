/* @flow */
import styled from "styled-components";

export const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
`;

export const UpgradeIcon = styled.img`
  display: block;
  margin: 0 auto 0;
  width: 100%;
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: var(--semiBold);
  margin: 20px 0 12px;
`;

export const Subtitle = styled.p`
  color: #a7a7a7;
  font-size: 14px;

  a {
    color: var(--kelp400);

    &:focus {
      outline: none;
    }
  }
`;

export const PlanWrapper = styled.div`
  margin: 0 auto;
`;

export const ButtonWrapper = styled.div`
  margin: 20px 0 0;
`;
