/* @flow */
import { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import moment from "moment";

import REPLY_INSTAGRAM_COMMENT from "mutations/replyInstagramComment";

import type { IProps } from "./types.js";

const useComment = (props: IProps) => {
  const apolloClient = useApolloClient();
  const [showWriteReply, setShowWriteReply] = useState(false);
  const [newReply, setNewReply] = useState("");
  const [creatingReply, setCreatingReply] = useState(false);
  const [newReplies, setNewReplies] = useState([]);
  const [repliesOrder, setRepliesOrder] = useState("ASC");
  const [error, setError] = useState<string>("");

  const toggleWriteReply = () => setShowWriteReply(!showWriteReply);

  const toggleRepliesOrder = () =>
    setRepliesOrder("ASC" === repliesOrder ? "DESC" : "ASC");

  const handleReplyChange = (e: any) => {
    setError("");
    setNewReply(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key == "Enter" || e.keyCode == 13) {
      createReply();
    }
  };

  const createReply = async () => {
    setError("");
    if (newReply.length > 2200) return false;
    if (!newReply.trim().length || creatingReply) return false;

    const { item, post } = props;
    setCreatingReply(true);

    try {
      const res: any = await apolloClient.mutate({
        mutation: REPLY_INSTAGRAM_COMMENT,
        variables: {
          postId: parseInt(post.id),
          commentBody: newReply,
          commentId: item.id
        }
      });

      const result = res.data.instagramReplyComment;
      if (result.id) {
        const mountedReply = {
          id: result.id,
          text: newReply,
          timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
          attachment: null,
          likeCount: 0,
          userLikes: false,
          from: {
            id: post.account.id,
            username: post.account.name
          }
        };
        setNewReplies([...newReplies, mountedReply]);
        setShowWriteReply(false);
        setNewReply("");
      } else if (result.error) {
        setError(result.error);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } catch (e) {
    } finally {
      setCreatingReply(false);
    }
  };

  return {
    showWriteReply,
    toggleWriteReply,
    newReply,
    handleReplyChange,
    creatingReply,
    createReply,
    newReplies,
    repliesOrder,
    toggleRepliesOrder,
    handleKeyPress,
    error
  };
};

export default useComment;
