import styled from "styled-components";

export const Wrapper = styled.div`
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 7.5px;
`;

export const FormGroup = styled.div`
  margin-bottom: var(--largeVertGutter);
  width: 100%;

  @media (min-width: 768px) {
    ${({ col }) =>
      col &&
      `
      width: 48%;
    `}
  }
`;

export const Row = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Required = styled.sup`
  color: var(--coral500);
  margin-left: 2px;
`;

export const Input = styled.input`
  background-color: #fff;
  border-radius: 3px;
  border: solid 1px var(--inky150);
  color: black;
  font-size: 15px;
  line-height: 34px;
  margin-top: var(--tinyVertGutter);
  max-height: 36px;
  padding: 0 10px;
  width: 100%;
`;

export const ErrorWrapper = styled.div`
  color: #f44336;
  font-weight: 500;
  font-size: 13px;
  margin: 5px 0 0;
`;

export default {
  input: {
    backgroundColor: "#fff",
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "var(--inky150)",
    color: "black",
    fontSize: 15,
    lineHeight: "34px",
    marginTop: "var(--tinyVertGutter)",
    maxHeight: 36,
    padding: "0 10px",
    width: "100%"
  }
};
