/* @flow */
import { useState } from "react";

const useNewCategory = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);

  return { isModalVisible, showModal, hideModal };
};

export default useNewCategory;
