/* @flow */
import React from "react";
import FilterSelect, {
  type Props as FilterProps
} from "components/common/FilterSelect";
import Label from "../Label";
import styles from "./index.css";

type Props = FilterProps & {
  label: string
};

export const Select = ({ label, ...rest }: Props) => {
  return (
    <div className={styles.root}>
      <Label className={styles.label}>{label}</Label>
      <FilterSelect {...rest} className={styles.select} />
    </div>
  );
};

export default Select;
