// @flow
import React from "react";

import SVGPlus from "./plus.svg";

import type { Props } from "./types.js";
import { Wrapper, Button } from "./styles";

const AddCategoryOption = ({ showModal }: Props) => (
  <Wrapper>
    <Button
      title="Click to add a new category"
      role="option"
      onClick={showModal}
    >
      <img src={SVGPlus} alt="plus icon" />
      Add a category
    </Button>
  </Wrapper>
);

export default AddCategoryOption;
