import styled from "styled-components";

const breakpointWidth = 620;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 17.5px 0 0;

  @media (min-width: ${breakpointWidth}px) {
    flex-direction: row;
  }
`;

export const Left = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  margin: 0 0 12.5px 0;

  @media (min-width: ${breakpointWidth}px) {
    margin-bottom: 0;
  }
`;

export const Checkbox = styled.input`
  display: inline-block !important;
  margin: 0 5px 0 0 !important;
`;
