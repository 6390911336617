/* @flow */
import React, { type Node } from "react";

import styles from "./index.css";

type Props = {
  children?: Node | Node[],
  className?: string
};

const TableBody = ({ children, className = "" }: Props) => {
  return <td className={`${styles.tableBody} ${className}`}>{children}</td>;
};

export default TableBody;
