/* @flow */
import React from "react";

import PNGDownload from "./icons/download.png";

import type { Props } from "./types.js";
import { Wrapper, Attachments } from "./styles";

const ThreadMessage = (props: Props) => {
  const { owner, message, attachments, isInstagram } = props;

  return message ||
    (message === "" && isInstagram) ||
    (attachments && attachments.length > 0) ? (
    <Wrapper className={owner ? "owner" : ""}>
      {isInstagram &&
      message === "" &&
      (!attachments || !attachments.length) ? (
        <div className="message">
          <p className="warning">
            Instagram Direct Messages does not support this type of message.
          </p>
        </div>
      ) : message ? (
        <div className="message">
          <p>{message}</p>
        </div>
      ) : null}

      {attachments && attachments.length > 0 && (
        <Attachments>
          {attachments.map((attachment, index) => (
            <React.Fragment key={index}>
              {attachment.previewUrl ? (
                <div>
                  <img
                    src={attachment.previewUrl}
                    alt="attachment"
                    className="attachment"
                  />
                </div>
              ) : attachment.fileUrl ? (
                <div className="message">
                  <p>
                    <a
                      href={attachment.fileUrl}
                      target="_blank"
                      title="Click to download"
                    >
                      <img
                        src={PNGDownload}
                        alt="download"
                        className="download"
                      />
                      {attachment.name}
                    </a>
                  </p>
                </div>
              ) : (
                <div className="message">
                  <p className="warning">
                    Our platform does not yet support this file extension.
                  </p>
                </div>
              )}
            </React.Fragment>
          ))}
        </Attachments>
      )}
    </Wrapper>
  ) : null;
};

export default ThreadMessage;
