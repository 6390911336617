/* @flow */
import React from "react";

import Thumbnail from "components/Thumbnail";

import styles from "./index.css";

import type {
  getContents_contents_data as ContentData,
  getContents_contents_data_variations as VariationData
} from "graphql-types/getContents";

type Props = {
  content: ContentData,
  variation: VariationData
};

const Variation = ({ content, variation }: Props) => {
  return (
    <div className={styles.variation}>
      <div className={styles.thumbnailWrapper}>
        <Thumbnail
          contentId={content.id}
          images={variation.images}
          video={
            variation.videos && variation.videos.length > 0
              ? variation.videos[0]
              : null
          }
          videos={variation.videos}
          displayTotalCount={true}
          size={36}
        />
      </div>
      <div className={styles.text}>{variation.displayText}</div>
    </div>
  );
};

export default Variation;
