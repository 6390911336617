import styled from "styled-components";

const breakpoint = 768;

export const Wrapper = styled.div`
  @media (min-width: ${breakpoint}px) {
    display: flex;
  }
`;

export const Left = styled.div`
  flex: 1;
  padding: 0 15px 0 0;
`;

export const Title = styled.div`
  color: #444444;
  font-size: 20px;
  font-weight: 600;
`;

export const Right = styled.div`
  flex: 1;
  margin: 25px 0 0;

  @media (min-width: ${breakpoint}px) {
    margin: 0;
    min-width: 393px;
  }
`;

export const BtnNext = styled.div`
  margin-top: 20px;
  text-align: right;

  img {
    margin-right: 10px;
  }
`;

export const SectionTitle = styled.div`
  font-size: var(--bodyFontSize);
  font-weight: var(--semiBold);
  line-height: var(--midLineHeight);
  margin: 0 0 5px;
`;

export const Error = styled.div`
  color: var(--dangerColor);
  margin: 7.5px 0 0;
  text-align: right;

  div {
    display: inline-block;
    max-width: 300px;
  }
`;
