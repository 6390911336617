// @flow
import { get } from "lodash";
import type { AppState } from "types";

export const selectIsOnboarding = (state: AppState): boolean =>
  state.onboarding.data.attributes.dismissedAt == null;

const selectIsContentOnboardingComplete = ({ onboarding }: AppState): boolean =>
  get(onboarding, "data.attributes.addContent") != null;

export const selectPrepareSeeMorePendingContentDialog = (
  state: AppState
): boolean =>
  selectIsOnboarding(state) && !selectIsContentOnboardingComplete(state);
