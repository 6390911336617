/* @flow */
import React, { type Node } from "react";
import { RadioButton as RadioButtonBase } from "forms/RadioGroup";
import styles from "./index.css";

type Props = {
  value: string,
  title: string,
  children: Node,
  onClick?: (id: string) => void,
  selected: boolean
};

const RadioButton = (props: Props) => {
  const { value, title, children, selected, onClick } = props;
  return (
    <RadioButtonBase
      key={value}
      value={value}
      selected={selected}
      onClick={onClick}
    >
      <span className={styles.title}>{title}</span>
      <div className={styles.children} onClick={onClick}>
        {children}
      </div>
    </RadioButtonBase>
  );
};

export default RadioButton;
