import styled from "styled-components";

export const Label = styled.label`
  color: #000;
  cursor: pointer;
  display: inline-block;
`;

export const Icon = styled.img`
  &[disabled] {
    cursor: not-allowed;
    opacity: var(--lighterDisabledOpacity);
  }

  &:hover:not([disabled]) {
    opacity: var(--hoverOpacity);
  }
`;
