/* eslint-disable flowtype/require-valid-file-annotation */
import React from "react";
import classNames from "classnames";
import Tab from "./Tab";
import styles from "./index.css";

type Props = {
  className?: string,
  children?: React.Element<*>
};

const NavTabs = ({ className, children }: Props) => {
  const classes = classNames(styles.root, { [className]: !!className });
  return <nav className={classes}>{children}</nav>;
};

export default NavTabs;
export { Tab };
