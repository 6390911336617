/* @flow */
import React, { Component } from "react";
import Button, { type ButtonType } from "../Button";
import Icon from "icons/Icon";
import DropDown from "layout/DropDown";
import styles from "./index.css";

type Props = {
  disableSaveButton?: boolean,
  postsLimitReached?: postsLimitReached,
  disabled?: boolean,
  loading?: boolean,
  size?: string,
  type?: ButtonType,
  buttonClassName?: string,
  children: any,
  label?: string
};

type State = { open: boolean };

export default class DropDownButton extends Component<Props, State> {
  state = { open: false, closing: false };

  toggleDropDown: () => void = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    const {
      size,
      type,
      disabled,
      loading,
      buttonClassName = null,
      children,
      label,
      disableSaveButton,
      postsLimitReached
    } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Button
          type={type}
          size={size}
          disabled={disabled}
          loading={loading}
          enforceMinWidth={false}
          className={buttonClassName || ""}
          onClick={this.toggleDropDown}
          disableSaveButton={disableSaveButton}
          postsLimitReached={postsLimitReached}
        >
          {label ? <span className={styles.label}>{label}</span> : null}
          <Icon type="caret-down" />
        </Button>
        <DropDown
          open={open}
          anchor="bottomRight"
          onClose={this.toggleDropDown}
        >
          {children}
        </DropDown>
      </div>
    );
  }
}
