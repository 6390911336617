/* @flow */
import React from "react";

import NewAccountSelect from "components/common/NewAccountSelect";
import PulseGIF from "components/common/images/loading-pulse.svg";

import type { Props } from "./types.js";
import useShopifyFormAccounts from "./use";
import { Wrapper, Loading } from "./styles";

import { SectionTitle } from "../styles";

const ShopifyFormAccounts = (props: Props) => {
  const {
    accountIds,
    handleAccounts,
    errors,
    onSelectPinterestBoard,
    selectedPinterestBoards
  } = props;
  const { loading, accounts } = useShopifyFormAccounts(props);
  const newAccounts = accounts.filter(account => account.provider !== "TIKTOK");

  return (
    <Wrapper>
      {loading ? (
        <Loading>
          <SectionTitle>Accounts</SectionTitle>
          <img src={PulseGIF} alt="loading" width="30" />
        </Loading>
      ) : (
        <NewAccountSelect
          accounts={newAccounts}
          selected={accountIds}
          onChange={handleAccounts}
          hasError={errors?.accountIds}
          onSelectPinterestBoard={onSelectPinterestBoard}
          allowMultipleTwitter
          stackOptions
          selectedPinterestBoardsByAccount={
            selectedPinterestBoards
              ? selectedPinterestBoards.reduce((acc, board) => {
                  acc[board.accountId] = [board.pinterestBoardId];
                  return acc;
                }, {})
              : null
          }
        />
      )}
    </Wrapper>
  );
};

export default ShopifyFormAccounts;
