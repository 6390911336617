/* @flow */
import React from "react";

import Button from "buttons/Button";
import Modal from "layout/modals/Modal";

import type { IProps } from "./types.js";
import { Wrapper, Title, Description, Buttons } from "./styles";

const AreYouSure = (props: IProps) => {
  const { hideModal, hideNewUIBanner } = props;

  return (
    <Modal
      closeOnOverlayClick
      size="medium"
      autoFocus={false}
      onClose={hideModal}
    >
      <Wrapper>
        <Title>Are you sure?</Title>
        <Description>
          You're about to dismiss Edgar's new layout changes. If you change your
          mind, you can still switch to the new view in the top drop down menu.
        </Description>

        <Buttons>
          <Button onClick={hideModal} type="secondary">
            Cancel
          </Button>
          <Button onClick={hideNewUIBanner} type="primary">
            Ok
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  );
};

export default AreYouSure;
