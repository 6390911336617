/* @flow */
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: { legend: { display: false } },
  interaction: { intersect: false },
  scale: {
    ticks: {
      precision: 0
    }
  }
};

export const dataExample = JSON.parse(`
{
  "data": {
    "dashboardMainGraph": {
      "netGrowth": 25,
      "graphData": {
        "20-09-2022": 7,
        "21-09-2022": 1,
        "22-09-2022": 2,
        "23-09-2022": 6,
        "24-09-2022": 1,
        "25-09-2022": 1,
        "26-09-2022": 8,
        "27-09-2022": 2
      },
      "graphType": "facebook page"
    }
  }
}
`);
