import styled from "styled-components";

export const Wrapper = styled.div``;

export const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  text-align: center;
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px 0 25px;

  img {
    width: 15px;
    margin-left: 0.5rem;
  }
`;

export const GrayBox = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
`;

export const HowMany = styled.div`
  margin: 0 0 15px;
`;

export const Form = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  input {
    border: 1px solid #dedede;
    border-radius: 0;
    padding: 8px 0 8px 18px;
    text-align: center;
    width: 70px;

    &:focus {
      outline: none;
      border-color: #d1d1d1;
    }
  }
`;

export const FormText = styled.div`
  margin-left: 10px;

  span {
    font-weight: 700;
  }
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 25px;

  > div {
    flex: 1;
  }
`;

export const SecondButton = styled.div`
  margin-left: 15px;
`;
