// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  expanded: boolean,
  className?: string,

  // If this is too short children will be vertically clipped. If it is too tall
  // the animation will happen too quickly, which is likely the lesser of two
  // evils. So overestimate and leave some extra margin for error.
  expandedHeight?: number,
  children?: Node
};

const Expander = ({ expanded, expandedHeight, className, children }: Props) => (
  <div
    className={classNames(styles.root, {
      [styles.expanded]: expanded,
      [styles.collapsed]: !expanded,
      [className || ""]: !!className
    })}
    style={{ maxHeight: expandedHeight ? `${expandedHeight}px` : undefined }}
  >
    {children}
  </div>
);

export default Expander;
