/* @flow */
import React, { useState } from "react";
import Field from "forms/Field";
import TextInput from "forms/TextInput";
import CheckboxInput from "forms/CheckboxInput";
import { Label } from "typography";
import Button from "buttons/Button";
import Modal from "layout/modals/Modal";

import type { Category } from "graphql-types/category";
import styles from "./index.css";

const DUPLICATE_ERROR_MESSAGE = "Name already exists";

type Props = {
  category: ?Category,
  errors: string[],
  handleClose: () => void,
  handleSubmit: (
    categoryId: ?number,
    categoryName: string,
    useForRandom: boolean
  ) => void
};

export default function CategoryActionModal({
  category,
  errors,
  handleClose,
  handleSubmit
}: Props) {
  const [categoryName, setCategoryName] = useState(
    category ? category.name : ""
  );
  const [useForRandom, setUseForRandom] = useState(
    category ? category.useForRandom : true
  );
  const categoryId = category ? category.id : null;

  function handleOnChangeCategoryName(value) {
    setCategoryName(value);
  }

  function handleToggleUseForRandom(value) {
    setUseForRandom(value);
  }

  function renderFooter() {
    return (
      <div className={styles.actions}>
        <span className={styles.secondaryActions}>
          <Button onClick={handleClose} type="secondary">
            Cancel
          </Button>
        </span>
        <Button
          submit
          onClick={() => handleSubmit(categoryId, categoryName, useForRandom)}
          type="primary"
        >
          Save
        </Button>
      </div>
    );
  }

  const showDuplicateErrorMessage = errors.includes(DUPLICATE_ERROR_MESSAGE);

  return (
    <Modal
      title={category ? "Edit Category" : "Add Category"}
      footer={renderFooter()}
      closeOnOverlayClick
      size="medium"
      onClose={handleClose}
    >
      <Field error={!!errors.length}>
        <Label className={styles.label}>
          <span className={styles.requiredSymbol}>*</span> Category Name
        </Label>
        <TextInput
          autoFocus
          autoComplete="off"
          name="categoryName"
          placeholder="Category name"
          className={styles.categoryNameTextInput}
          value={categoryName}
          onChange={handleOnChangeCategoryName}
          hasError={!!errors.length}
        />

        <DuplicateCategoryNameErrorMessage
          display={showDuplicateErrorMessage}
        />
      </Field>

      <Field>
        <CheckboxInput
          value={useForRandom}
          label="Included when selecting random content to post."
          className={styles.categoryUseForRandomCheckbox}
          labelClassName={styles.categoryUseForRandomCheckboxLabel}
          extraMargin
          onChange={handleToggleUseForRandom}
        />
      </Field>
    </Modal>
  );
}

const DuplicateCategoryNameErrorMessage = ({ display }) => (
  <p
    className={styles.errorMessage}
    style={{ visibility: display ? null : "hidden" }}
  >
    The category name already exists
  </p>
);
