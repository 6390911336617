import styled from "styled-components";

import { breakpointWidth } from "../../styles";

export const Wrapper = styled.div`
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  position: absolute;
  padding: 0 0 10px;
  top: 0;
  width: 100%;
  z-index: 9999;

  @media (min-width: ${breakpointWidth}px) {
    padding: 0;
  }
`;

export const Body = styled.div`
  padding: 0 5px 20px 20px;
  overflow: hidden;
  overflow-y: auto;

  @media (min-width: 600px) {
    display: flex;
  }
`;

export const Input = {padding: "10px", marginLeft: "auto", maxWidth: "490px", width: "490px",outline:"none", marginBottom: "1rem", marginRight: "1rem", border: "1px solid #e6e6e6", height: "50px", borderRadius: "6px"};