// @flow
import React, { type ComponentType, type Element } from "react";
import TetherComponent from "react-tether";
import { Transition } from "react-transition-group";
import classNames from "classnames";
import H5 from "typography/H5";
import Caption from "typography/Caption";
import styles from "./index.css";

type State = { open: boolean, closing: boolean };
type Attachment =
  | "bottom left"
  | "bottom center"
  | "bottom right"
  | "middle left"
  | "middle center"
  | "middle right"
  | "top left"
  | "top center"
  | "top right";

// TODO add tests
type Options = {
  attachment?: Attachment,
  maxWidth?: string,
  title?: string,
  Subtitle?: ComponentType<any>,
  closeDelay?: number,
  alwaysOpen?: boolean,
  narrowDownArrow?: boolean,
  leftAlignDownArrow?: boolean,
  isAutoArrow?: boolean,
  targetClassName?: string,
  tetherClassName?: string
};
type HoF = (WrappedComponent: ComponentType<any>) => ComponentType<any>;

const DEFAULT_FADE_DURATION = 200;
const tipTransitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0 },
  unmounted: { opacity: 0 }
};

export const tooltipper = (
  Body?: ComponentType<any>,
  options?: Options = {}
): HoF => WrappedComponent => {
  const {
    attachment = "bottom center",
    maxWidth = "234px",
    title,
    Subtitle,
    closeDelay = DEFAULT_FADE_DURATION,
    alwaysOpen = false,
    narrowDownArrow = false,
    leftAlignDownArrow = false,
    isAutoArrow = false,
    targetClassName = "",
    tetherClassName = ""
  } = options;

  const autoAttachmentArrow =
    "autoArrow" +
    attachment
      .split(/\s+/g)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join("");

  return class extends React.Component<any, State> {
    state = { open: alwaysOpen || false, closing: false };

    // This method enables testing the tooltip which gets rendered. It returns a
    // ReactElement that should either be mounted or shallowed in the spec. Kind of
    // hacky in that it is very much aware of react-tether's internals.
    getTip() {
      if (!this.tether) {
        return null;
      }
      return this.tether.props.renderElement();
    }

    tether: ?Element<TetherComponent>;

    closeTimeout: TimeoutID;

    handleMouseEnter = () => {
      if (!this.getTooltipPresent()) return;
      this.setState({ open: true, closing: false });
      clearTimeout(this.closeTimeout);
    };

    handleMouseLeave = () => {
      this.setState({ closing: true });
      this.closeTimeout = setTimeout(() => {
        this.setState({ open: false, closing: false });
      }, closeDelay);
    };

    getTooltipPresent() {
      return Body || this.props.tooltipBody;
    }

    getTooltipBody() {
      if (!!Body) {
        return <Body {...this.props} />;
      } else if (this.props.tooltipBody) {
        return this.props.tooltipBody;
      }
      return null;
    }

    renderTarget = ref => {
      return (
        <span
          className={classNames(
            styles.wrappedComponentContainer,
            targetClassName,
            {
              [styles.hasTooltip]: this.getTooltipPresent()
            }
          )}
          ref={ref}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          <WrappedComponent {...this.props} />
        </span>
      );
    };

    renderElement = ref => {
      const { open, closing } = this.state;

      const tipStyles = {
        transition: `opacity ${closeDelay}ms ease-in-out`,
        opacity: 0
      };
      return (
        <Transition in={open && !closing} timeout={closeDelay} ref={ref}>
          {state =>
            open && (
              <div
                className={styles.root}
                style={{ ...tipStyles, ...tipTransitionStyles[state] }}
              >
                <div
                  className={classNames(styles.tip, {
                    [styles.autoArrow]:
                      styles[autoAttachmentArrow] && isAutoArrow,
                    [styles[autoAttachmentArrow]]:
                      styles[autoAttachmentArrow] && isAutoArrow
                  })}
                  style={{ maxWidth }}
                >
                  {title && (
                    <H5 className={styles.tipTitle}>
                      {title}
                      {!!Subtitle && (
                        <span className={styles.tipSubtitle}>
                          <Subtitle {...this.props} />
                        </span>
                      )}
                    </H5>
                  )}
                  <Caption className={styles.tipBody}>
                    {this.getTooltipBody()}
                  </Caption>
                </div>
                {!isAutoArrow && (
                  <div
                    className={classNames(styles.downArrow, {
                      [styles.narrowDownArrow]: narrowDownArrow,
                      [styles.leftAlignDownArrow]: leftAlignDownArrow
                    })}
                  />
                )}
              </div>
            )
          }
        </Transition>
      );
    };

    render() {
      if (this.props.tooltipDisabled) {
        return <WrappedComponent {...this.props} />;
      }

      return (
        <TetherComponent
          attachment={attachment}
          className={classNames(tetherClassName)}
          ref={tether => {
            this.tether = tether;
          }}
          renderTarget={ref => this.renderTarget(ref)}
          renderElement={ref => this.renderElement(ref)}
        />
      );
    }
  };
};

export default tooltipper;
