import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Icon from "icons/Icon";
import Label from "typography/Label";
import Button from "buttons/Button";

import type { FlashType } from "reducers/flash";
import type { cancelSubscription as CancelSubscriptionResult } from "graphql-types/cancelSubscription";

import styles from "./index.css";

type Props = {
  cancelSubscription?: () => Promise<{ data: CancelSubscriptionResult }>,
  cancelSubscriptionLoading?: boolean,
  flash: (type: FlashType, body: string) => void
};

const AccountCancellation = ({
  cancelSubscription,
  cancelSubscriptionLoading,
  flash
}: Props) => {
  const [state, updateState] = useState({
    isModalOpen: false
  });
  const closeModal = _evt => {
    updateState({ ...state, isModalOpen: false });
  };
  return (
    <>
      {state.isModalOpen && (
        <Modal onClose={closeModal}>
          <div className={styles.main}>
            <Icon color="danger" size="2x" type="ban" />
            <div className={styles.message}>
              <Label>Close my account</Label>
              <p>
                Is there anything we can help you with before you close your
                account? Please contact Team Edgar!
              </p>
            </div>
            <Button
              className={styles.buttonCancel}
              href="mailto:support@meetedgar.com"
              type="secondary"
            >
              Contact support
            </Button>
            <Button
              disabled={cancelSubscriptionLoading}
              onClick={(): void => {
                const promise = cancelSubscription?.();
                if (promise) {
                  promise.then(response => {
                    const errors =
                      response?.data?.cancelSubscription?.errors || [];
                    if (errors.length > 0) {
                      flash("error", errors[0]);
                    } else {
                      location.href = "/cancellation";
                    }
                  });
                }
              }}
              type="primary-negative"
            >
              No, thanks. Close my account
            </Button>
          </div>
        </Modal>
      )}
      <Button
        className={`${styles.button} ${styles.pushRight}`}
        disabled={cancelSubscriptionLoading}
        onClick={(): void => {
          updateState({ ...state, isModalOpen: true });
        }}
        type="primary-negative"
      >
        Close my account
      </Button>
    </>
  );
};

export default AccountCancellation;
