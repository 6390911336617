// @flow
import { update, isArray } from "lodash";

export type ServerErrorsByField = {
  base?: string[],
  category_id?: string[],
  send_at?: string[],
  variations?: { text: string[], fbText: string[] }
};

export type ServerError = { field: string, messages: string[] };

function validate(errors: ServerError[]): ServerErrorsByField {
  if (!isArray(errors)) {
    return {
      base: ["An unexpected error occurred!"]
    };
  }

  return errors.reduce(
    (result, { field, messages }) =>
      update(result, field, errors =>
        errors ? errors.concat(messages) : messages
      ),
    {}
  );
}

export default validate;
