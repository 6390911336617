/* @flow */
import React from "react";

import CategorySelect from "components/common/CategorySelect";

import type { Props } from "./types.js";
import { Wrapper } from "./styles";

import { SectionTitle } from "../styles";

const ShopifyFormCategory = (props: Props) => {
  const { categoryId, handleCategory, errors } = props;

  return (
    <Wrapper>
      <SectionTitle>Category</SectionTitle>
      <CategorySelect
        id="category"
        value={categoryId}
        disableDefaultCategory
        onChange={value => handleCategory(value.id)}
        hasError={errors?.categoryId}
      />
    </Wrapper>
  );
};

export default ShopifyFormCategory;
