import { gql } from "apollo-boost";
import { ALL_CATEGORY_FIELDS_FRAGMENT } from "../fragments/category";

export const CREATE_CATEGORY = gql`
  ${ALL_CATEGORY_FIELDS_FRAGMENT}

  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      category {
        ...allCategoryFields
        timeslotCount
        randomTimeslotCount
      }

      errors
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      deletedId
      errors
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
        name
        useForRandom
      }
      errors
    }
  }
`;

export const SHUFFLE_CATEGORY = gql`
  mutation ShuffleCategory($id: ID!) {
    shuffleCategory(id: $id) {
      errors
    }
  }
`;
