/* @flow */
import React from "react";
import Information from "components/common/layout/Information";
import Link from "components/common/links/Link";

type Props = {
  className: string
};

const PendingContentInformation = ({ className }: Props) => (
  <Information className={className} name="pendingContentInformation">
    The content imported from your website or other{" "}
    <Link
      url="https://help.meetedgar.com/edgar-integrations/import-content-with-rss-feeds/rss-feeds-features-overview"
      target="_blank"
    >
      Rss feeds
    </Link>{" "}
    is kept on the Pending tab of your Library. Click Approve to add the content
    to your library, or click Edit to add media,{" "}
    <Link
      url="https://help.meetedgar.com/edgar-integrations/import-content-with-rss-feeds/rss-feed-faqs#why-is-there-a-separate-facebook-text-box-on-some-posts"
      target="_blank"
    >
      Facebook text
    </Link>
    , or variations! Click Reject if you don't want to keep the content.
  </Information>
);

export default PendingContentInformation;
