/* @flow */
import React from "react";
import Icon from "icons/Icon";
import get from "lodash/get";
import { colorFromIndex } from "colors";
import type { Category as CategoryType } from "graphql-types/category";
import Skeleton from "react-loading-skeleton";

import styles from "./index.css";

type Props = {
  category: ?CategoryType
};

const Category = ({ category }: Props) => (
  <div className={styles.root}>
    <Icon
      type="circle"
      style={{ color: colorFromIndex(get(category, "colorIndex")) }}
    />
    &nbsp;
    <span>{get(category, "name") || <Skeleton width={200} />}</span>
  </div>
);

export default Category;
