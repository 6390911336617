/* @flow */
/* global EventHandler */
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import withFilter, {
  parseFilterParams,
  buildPaginator
} from "behavioral/withFilter";
import { ActionBarButton } from "buttons";

import DELETE_CONTENT from "mutations/deleteContent";
import GET_CONTENTS from "queries/getContents";

import type { ContentFilter } from "types";
import type { Match, Location } from "react-router-dom";

type OwnProps = {
  contentId: string,
  disabled: boolean,
  type?: string
};

type Props = OwnProps & {
  filter: ContentFilter,
  onDelete: () => void,
  location: Location,
  match: Match,
  loading: boolean
};

export const Delete = ({
  type,
  disabled,
  loading,
  contentId,
  onDelete
}: Props) => (
  <ActionBarButton
    confirm={true}
    confirmType={"warning"}
    confirmMessage={"You won't be able to undo this action."}
    disabled={disabled || loading || !contentId}
    loading={loading}
    onClick={() => onDelete()}
    type={type || "primary-negative"}
  >
    Delete
  </ActionBarButton>
);

export default withFilter()((props: Props) => {
  const PER_PAGE = 25;
  const { contentId } = props;
  const { filter, page: pageNum } = parseFilterParams(
    props.match,
    props.location
  );

  const [deleteContent, { loading }] = useMutation(DELETE_CONTENT, {
    variables: { id: contentId },
    refetchQueries: _ => [
      {
        query: GET_CONTENTS,
        variables: {
          filter,
          page: buildPaginator(pageNum, PER_PAGE)
        }
      }
    ],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true
  });

  return (
    <Delete
      {...props}
      loading={loading}
      onDelete={() => deleteContent(contentId)}
    />
  );
});
