import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1376px;
  padding: 5px 10px 10px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
`;

export const Item = styled.div`
  margin: 5px 0 0;
`;
