// @flow
import React from "react";
import CheckboxInput from "forms/CheckboxInput";
import Caption from "typography/Caption";
import styles from "./index.css";

type Props = {
  value: boolean,
  onChange: (val: boolean) => void
};

const HELP_LINK =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/organize-your-content-with-edgar-s-categories/use-once";

const UseOnceField = ({ value, onChange }: Props) => (
  <div>
    <CheckboxInput label="Use Once" value={value} onChange={onChange} />
    <Caption helpText className={styles.helpText}>
      Selecting Use Once means this post will only be published once. If the
      post contains variations, only the last variation will be published and
      then the entire post will be expired. Use Once should not be used in
      combination with variations and it is best to use either feature
      individually.{" "}
      <a href={HELP_LINK} target="_blank">
        Learn more about Use Once.
      </a>
    </Caption>
  </div>
);

export default UseOnceField;
