/* @flow */
import { compact, flatMap, get, omit } from "lodash";
import selectUserForReporting from "selectors/users/userForReporting";

const bugsnagExists = () => typeof window.Bugsnag !== "undefined";

let configureBugsnag = () => {
  // Adblockers may have blocked Bugsnag
  if (bugsnagExists() && get(window.INITIAL_STORE_STATE, "users.data[0]")) {
    window.Bugsnag.user = selectUserForReporting(window.INITIAL_STORE_STATE);
  }
  configureBugsnag = () => null;
};

export const reportException = (e: Object, name: string, meta: ?Object) => {
  configureBugsnag();
  // Adblockers may have blocked Bugsnag
  if (bugsnagExists()) {
    window.Bugsnag.notifyException(e, name, meta);
  }
};

export const reportMessage = (name: string, description: string | Object) => {
  configureBugsnag();
  // Adblockers may have blocked Bugsnag
  if (bugsnagExists()) {
    window.Bugsnag.notify(name, description);
  }
};

export type ValidationRule = {
  message: string,
  rule: (resouce: Object) => boolean,
  suppressDisplay?: boolean
};

export type ValidationRuleSet = { [key: string]: ValidationRule[] };

export type ValidationError = {
  origin?: "server" | "client",
  message: string,
  name?: string,
  suppressDisplay?: boolean
};

class ApiError {
  constructor(
    message: string = "An unknown error occured",
    status: number = -1,
    body: Object = {}
  ) {
    this.status = status;
    this.body = body;
    this.message = this.buildMessage(message);
  }

  name: string;

  body: Object;

  message: string;

  status: number;

  buildMessage(message: string): string {
    const jsonApiErrors = this.extractJsonApiErrors();
    switch (jsonApiErrors.length) {
      case 0:
        return message;
      case 1:
        return jsonApiErrors[0];
      case 2:
        return jsonApiErrors.join(" and ");
      default:
        jsonApiErrors[jsonApiErrors.length - 1] = `and ${
          jsonApiErrors[jsonApiErrors.length - 1]
        }`;
        return jsonApiErrors.join(", ");
    }
  }

  extractJsonApiErrors(): Array<string> {
    const errors = get(this, "body.errors");
    if (!errors || !errors.length) {
      return [];
    }

    const matchAfterLastSlash = /([^/]+$)/;
    return errors.map(err => {
      const pointer = get(err, "source.pointer");
      if (!pointer) {
        return err.detail;
      }
      const match = err.source.pointer.match(matchAfterLastSlash);
      return `${match[0]} ${err.detail}`;
    });
  }
}

export type StripeError = {
  detail: string,
  source: Object // can't find this defined on https://stripe.com/docs/api#errors ?
};

Object.setPrototypeOf(ApiError, Error);
ApiError.prototype.name = "ApiError";
ApiError.prototype.message = "";
ApiError.prototype.status = -1;
ApiError.prototype.body = {};

export { ApiError };

export const validateResource = (
  resource: Object,
  validations: Object
): ValidationError[] =>
  compact(
    flatMap(validations, (fieldValidations, key) =>
      fieldValidations.map(validation =>
        validation.rule(resource)
          ? null
          : { ...omit(validation, "rule"), name: key, origin: "client" }
      )
    )
  );
