import styled from "styled-components";

export const Icon = styled.div`
  margin-top: 13px;
  text-align: center;

  span {
    background: #d1fad2;
    border-radius: 28px;
    border: 12px solid #e0ffe5;
    height: 48px;
    width: 48px;

    img {
      height: 20px;
      position: relative;
      top: -2px;
      width: 20px;
    }

    &.error {
      background: #fee4e2;
      border: 12px solid #fef3f2;
    }
  }
`;
