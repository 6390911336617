/* @flow */
import React from "react";
import { useQuery } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import GET_ACCOUNT_CANCELLATION_CONFIRMATION_DATA from "queries/getAccountCancellationConfirmationData";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Message from "layout/Message";
import Link from "links/Link";
import LoadingIcon from "icons/LoadingIcon";
import ErrorWithRetry from "components/ErrorWithRetry";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { getAccountCancellationConfirmationData as AccountCancellationConfirmationData } from "graphql-types/getAccountCancellationConfirmationData";

import styles from "./index.css";

type OwnProps = {
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  data?: AccountCancellationConfirmationData,
  error?: string,
  loading?: boolean
} & OwnProps;

const AccountCancellationConfirmationMain = ({ error, loading }: Props) => {
  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (error) {
    return <ErrorWithRetry>{error}</ErrorWithRetry>;
  }
  return (
    <div className={styles.section}>
      You have successfully canceled your account, and you won't be billed again
      unless you reactivate it. Thanks for getting to know Edgar &ndash; he'll
      never forget you.
    </div>
  );
};

const AccountCancellationConfirmation = (props: Props) => {
  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      messages={
        props.data?.user?.company?.queueStatus === "PAUSED" && (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from the{" "}
            <Link href="/queue">Queue</Link> section.
          </Message>
        )
      }
      isNewHeader
      header={<PageTitle>Account Closed</PageTitle>}
    >
      <AccountCancellationConfirmationMain {...props} />
    </App>
  );
};

const AccountCancellationConfirmationWrapper = (ownProps: OwnProps) => {
  const { loading, error, data } = useQuery(
    GET_ACCOUNT_CANCELLATION_CONFIRMATION_DATA
  );
  if (loading) {
    return <AccountCancellationConfirmation {...ownProps} loading />;
  }
  if (error) {
    return (
      <AccountCancellationConfirmation
        {...ownProps}
        error="Uh-oh something went wrong 😿"
      />
    );
  }
  return <AccountCancellationConfirmation {...ownProps} data={data} />;
};

export default AccountCancellationConfirmationWrapper;
