/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 10px 0 0 0;
`;

export const Title = styled.div`
  margin: 10px 0 0;
`;

export const Items = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 0;
`;

export const Account = styled.div`
  margin: 0 7.5px;
  position: relative;

  > img {
    border-radius: 50%;
    height: 35px !important;
    width: 35px !important;
  }
`;

export const ProviderWrapper = styled.div`
  bottom: -1px;
  position: absolute;
  right: -3px;

  img {
    border-radius: 2px;
    height: 15px;
    width: 15px;
  }
`;
