import React from "react";
import { Formik, Form } from "formik";

import LoadingBar from "components/LoadingBar";
import EdgarLogoWhite from "components/common/images/edgarlogo-white.png";

import FormInvitation from "./form";
import Actions from "./actions";

import useInvitation from "./use";
import { initialValues, formSchema } from "./utils";
import {
  Wrapper,
  Header,
  Content,
  WhiteBox,
  Title,
  FeedbackText,
  ErrorSending
} from "./styles";

const Invitation = props => {
  const {
    loading,
    sending,
    error,
    errorSending,
    invitation,
    hasAgreed,
    setHasAgreed,
    handleSubmit
  } = useInvitation(props);

  return (
    <Wrapper>
      <Header>
        <a href="/">
          <img src={EdgarLogoWhite} alt="logo white" />
        </a>
        <a href="/users/sign_in">Login</a>
      </Header>

      <Content>
        <WhiteBox>
          <Title>
            Hello! Please tell us more about yourself to accept the invitation.
          </Title>

          {loading ? (
            <>
              <LoadingBar isAuto />
              <FeedbackText>Loading invitation...</FeedbackText>
            </>
          ) : error.trim() !== "" ? (
            <FeedbackText>{error}</FeedbackText>
          ) : (
            <Formik
              initialValues={initialValues(invitation)}
              validationSchema={formSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {formRef => (
                <Form>
                  <FormInvitation formRef={formRef} />
                  <Actions
                    hasAgreed={hasAgreed}
                    setHasAgreed={setHasAgreed}
                    sending={sending}
                  />
                  {errorSending.trim() !== "" && (
                    <ErrorSending>{errorSending}</ErrorSending>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </WhiteBox>
      </Content>
    </Wrapper>
  );
};

export default Invitation;
