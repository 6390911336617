/* @flow */
/* global EventHandler */
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import withFilter, {
  parseFilterParams,
  buildPaginator
} from "behavioral/withFilter";
import { ActionBarButton } from "buttons";

import APPROVE_CONTENT from "mutations/approveContent";
import GET_CONTENTS from "queries/getContents";

import type { Match, Location } from "react-router-dom";

type OwnProps = {
  contentId: string,
  disabled: boolean
};
type Props = OwnProps & {
  location: Location,
  match: Match,
  onApprove: () => void,
  loading: boolean
};

export const Approve = ({ disabled, loading, contentId, onApprove }: Props) => (
  <ActionBarButton
    disabled={disabled || loading || !contentId}
    loading={loading}
    onClick={() => onApprove()}
    type="primary"
  >
    Approve
  </ActionBarButton>
);

export default withFilter()((props: Props) => {
  const PER_PAGE = 25;
  const { contentId } = props;
  const { filter, page: pageNum } = parseFilterParams(
    props.match,
    props.location
  );

  const [approveContent, { loading }] = useMutation(APPROVE_CONTENT, {
    variables: { id: contentId },
    refetchQueries: () => [
      {
        query: GET_CONTENTS,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        variables: {
          filter,
          page: buildPaginator(pageNum, PER_PAGE)
        }
      }
    ],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true
  });

  return (
    <Approve
      {...props}
      loading={loading}
      onApprove={() => approveContent(contentId)}
    />
  );
});
