/* @flow */
import React, { Component } from "react";
import Icon, { type AnimationType } from "icons/Icon";
import styles from "./index.css";

type Props = {
  loading: boolean,
  timeout: number,
  failed: boolean,
  children?: any
};
type State = { finishTimeoutId: ?TimeoutID };

export default class AnimatedLoadingLabel extends Component<Props, State> {
  static defaultProps: {
    loading: boolean,
    timeout: number,
    failed: boolean
  } = { loading: false, timeout: 3000, failed: false };

  state = { finishTimeoutId: null };

  componentWillReceiveProps(nextProps: Props) {
    const { finishTimeoutId } = this.state;
    const { timeout } = this.props;
    if (!nextProps.loading && this.props.loading) {
      if (finishTimeoutId) {
        clearTimeout(finishTimeoutId);
      }
      this.setState({
        finishTimeoutId: setTimeout(
          () => this.setState({ finishTimeoutId: null }),
          timeout
        )
      });
    }
  }

  componentWillUnmount() {
    const { finishTimeoutId } = this.state;
    if (finishTimeoutId) {
      clearTimeout(finishTimeoutId);
    }
  }

  render() {
    const { loading, failed, children } = this.props;
    const { finishTimeoutId } = this.state;
    const iconType = !failed ? "check" : "ban";
    if (loading) {
      return (
        <Indicator type="spinner" animation="spin">
          {children}
        </Indicator>
      );
    }
    if (finishTimeoutId) {
      return <Indicator type={iconType}>{children}</Indicator>;
    }
    return <span>{children}</span>;
  }
}

export const Indicator = ({
  type,
  animation,
  children
}: {
  type: string,
  animation?: AnimationType,
  children?: any
}) => (
  <span className={styles.indicator}>
    <span className={styles.indicatorChildren}>{children}</span>

    <span className={styles.icon}>
      <Icon type={type} animation={animation} />
    </span>
  </span>
);
