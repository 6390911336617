/* @flow */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/react-hooks";
import classNames from "classnames";
import type { Match, Location, RouterHistory } from "react-router-dom";
import { omit, pick } from "lodash";

import { createFlash } from "actions/flash";

import GET_USER_PROFILE from "queries/getUserProfile";
import UPDATE_USER from "mutations/updateUser";
import App from "components/App";
import PageTitle from "links/PageTitle";
import { LoadingIcon } from "icons";
import ErrorWithRetry from "components/ErrorWithRetry";
import Label from "typography/Label";
import TextInput from "forms/TextInput";
import Select from "forms/Select";
import CheckboxInput from "forms/CheckboxInput";
import Button from "buttons/Button";

import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { getUserProfile_user as User } from "graphql-types/getUserProfile";
import type {
  updateUser as UpdateUserResult,
  updateUserVariables
} from "graphql-types/updateUser";

import styles from "./index.css";

type SelectOption = {
  isDisabled?: boolean,
  label: string,
  value: string
};

type SelectOptGroup = {
  label: string,
  options: SelectOption[]
};

type OwnProps = {
  countryOptions: SelectOption[],
  flash: (type: FlashType, body: string) => void,
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  timeZoneOptions: SelectOptGroup[],
  topNav: TopNavProps
};

type Props = {
  error?: string,
  loading?: boolean,
  updateUser?: ({
    variables: updateUserVariables
  }) => Promise<{ data: UpdateUserResult }>,
  updateUserData?: UpdateUserResult,
  updateUserError?: boolean,
  updateUserLoading?: boolean,
  user?: User
} & OwnProps;

const OWNER_ROLE = "owner";

export const getUserCountryDefaultValue = (
  country: string,
  countryOptions: SelectOption[]
): ?SelectOption => {
  if (country === "") {
    return null;
  }
  return countryOptions.find(opt => country === opt.value);
};

export const getUserTimeZoneDefaultValue = (
  timeZone: string,
  timeZoneOptions: SelectOptGroup[]
): ?SelectOption => {
  if (timeZone === "") {
    return null;
  }
  for (const { options } of timeZoneOptions) {
    const value = options.find(opt => timeZone === opt.value);
    if (value) {
      return value;
    }
  }
  return null;
};

const COMPANY_FIELDS = [
  "timeZone",
  "address1",
  "address2",
  "city",
  "region",
  "zip",
  "country"
];

const getCompanyInput = formState => {
  return {
    name: formState.companyName,
    ...pick(formState, COMPANY_FIELDS)
  };
};

const getUserInput = formState => {
  return omit(formState, ["companyName", ...COMPANY_FIELDS]);
};

export const UserProfileForm = ({
  countryOptions,
  flash,
  timeZoneOptions,
  updateUser,
  user = {}
}: Props) => {
  const [formState, setFormState] = useState({
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    timeZone: user.company?.timeZone || "",
    phone: user.phone || "",
    companyName: user.company?.name || "",
    address1: user.company?.address1 || "",
    address2: user.company?.address2 || "",
    city: user.company?.city || "",
    region: user.company?.region || "",
    zip: user.company?.zip || "",
    country: user.company?.country || "",
    sendPerformanceReport: user.sendPerformanceReport === false ? false : true,
    password: "",
    currentPassword: ""
  });
  const handleTextInputChange = (value: string, name: string) => {
    setFormState({ ...formState, [name]: value });
  };
  const isFormValid = () => {
    return (
      formState.currentPassword !== "" &&
      formState.firstName.trim() !== "" &&
      formState.lastName.trim() !== "" &&
      formState.email.trim() !== "" &&
      formState.timeZone !== ""
    );
  };

  return (
    <>
      <form
        className={styles.container}
        onSubmit={(evt: SyntheticEvent<HTMLFormElement>): void => {
          evt.preventDefault();
          const promise = updateUser?.({
            variables: {
              input: {
                ...getUserInput(formState),
                company: getCompanyInput(formState)
              }
            }
          });
          if (promise) {
            promise.then(
              (response): void => {
                const errors = response?.data?.updateUser?.errors || [];
                if (errors.length > 0) {
                  flash("error", errors[0]);
                } else {
                  location.href = "/settings#profile_updated";
                }
              }
            );
          }
        }}
      >
        <div className={styles.section}>
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabelRequired}>
              First name
            </Label>
            <TextInput
              className={classNames(styles.sectionFieldInput, {
                [styles.sectionFieldInputInvalid]:
                  formState.firstName.trim() === ""
              })}
              name="firstName"
              onChange={handleTextInputChange}
              value={formState.firstName}
            />
          </div>
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabelRequired}>
              Last name
            </Label>
            <TextInput
              className={classNames(styles.sectionFieldInput, {
                [styles.sectionFieldInputInvalid]:
                  formState.lastName.trim() === ""
              })}
              name="lastName"
              onChange={handleTextInputChange}
              value={formState.lastName}
            />
          </div>
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabelRequired}>Email</Label>
            <TextInput
              className={classNames(styles.sectionFieldInput, {
                [styles.sectionFieldInputInvalid]: formState.email.trim() === ""
              })}
              name="email"
              onChange={handleTextInputChange}
              type="email"
              value={formState.email}
            />
          </div>
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabelRequired}>
              Time zone
            </Label>
            <Select
              className={styles.sectionFieldInput}
              defaultValue={getUserTimeZoneDefaultValue(
                formState.timeZone,
                timeZoneOptions
              )}
              hasError={formState.timeZone === ""}
              onChange={({ value: timeZone }) => {
                setFormState({ ...formState, timeZone });
              }}
              isDisabled={user.role != OWNER_ROLE}
              options={timeZoneOptions}
            />
          </div>
          {user.role === OWNER_ROLE && (
            <>
              <div className={styles.sectionField}>
                <Label>Company name</Label>
                <TextInput
                  className={styles.sectionFieldInput}
                  name="companyName"
                  onChange={handleTextInputChange}
                  value={formState.companyName}
                />
              </div>
              <div className={styles.sectionField}>
                <Label>Phone number</Label>
                <TextInput
                  className={styles.sectionFieldInput}
                  name="phone"
                  onChange={handleTextInputChange}
                  value={formState.phone}
                />
              </div>
              <div className={styles.sectionField}>
                <Label>Address line 1</Label>
                <TextInput
                  className={styles.sectionFieldInput}
                  name="address1"
                  onChange={handleTextInputChange}
                  value={formState.address1}
                />
              </div>
              <div className={styles.sectionField}>
                <Label>Address line 2</Label>
                <TextInput
                  className={styles.sectionFieldInput}
                  name="address2"
                  onChange={handleTextInputChange}
                  value={formState.address2}
                />
              </div>
              <div className={styles.sectionField}>
                <Label>City</Label>
                <TextInput
                  className={styles.sectionFieldInput}
                  name="city"
                  onChange={handleTextInputChange}
                  value={formState.city}
                />
              </div>
              <div className={styles.sectionField}>
                <Label>State/Province/Region</Label>
                <TextInput
                  className={styles.sectionFieldInput}
                  name="region"
                  onChange={handleTextInputChange}
                  value={formState.region}
                />
              </div>
              <div className={styles.sectionField}>
                <Label>Zip/Postal code</Label>
                <TextInput
                  className={styles.sectionFieldInput}
                  name="zip"
                  onChange={handleTextInputChange}
                  value={formState.zip}
                />
              </div>
              <div className={styles.sectionField}>
                <Label>Country</Label>
                <Select
                  className={styles.sectionFieldInput}
                  defaultValue={getUserCountryDefaultValue(
                    formState.country,
                    countryOptions
                  )}
                  onChange={({ value: country }) => {
                    setFormState({ ...formState, country });
                  }}
                  options={countryOptions}
                />
              </div>
              <div className={styles.sectionField}>
                <CheckboxInput
                  label="Receive performance reports"
                  onChange={sendPerformanceReport => {
                    setFormState({ ...formState, sendPerformanceReport });
                  }}
                  value={formState.sendPerformanceReport}
                />
              </div>
            </>
          )}
          <div className={styles.sectionField}>
            <Label>New password</Label>
            <small className={styles.sectionFieldDescription}>
              Leave blank if you do not wish to change your password
            </small>
            <TextInput
              className={styles.sectionFieldInput}
              name="password"
              onChange={handleTextInputChange}
              type="password"
              value={formState.password}
            />
          </div>
        </div>
        <div className={`${styles.section} ${styles.sectionAction}`}>
          <div className={styles.sectionField}>
            <div className={styles.sectionFieldLabelWrapper}>
              <Label className={styles.sectionFieldLabelRequired}>
                Current password
              </Label>
              <small className={styles.sectionFieldRequired}>
                (Required to save your changes)
              </small>
            </div>
            <small className={styles.sectionFieldDescription}>
              Current password required to confirm your changes
            </small>
            <TextInput
              className={styles.sectionFieldInput}
              name="currentPassword"
              onChange={handleTextInputChange}
              type="password"
              value={formState.currentPassword}
            />
          </div>
          <div
            className={`${styles.sectionField} ${styles.sectionFieldAction}`}
          >
            <Button
              className={styles.sectionButtonCancel}
              href="/settings"
              type="secondary"
            >
              Cancel
            </Button>
            <Button
              className={styles.saveButton}
              disabled={!isFormValid()}
              submit
              type="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export const UserProfileMain = (props: Props) => {
  if (props.loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (props.error) {
    return <ErrorWithRetry>{props.error}</ErrorWithRetry>;
  }
  return <UserProfileForm {...props} />;
};

export const UserProfile = (props: Props) => {
  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      isNewHeader
      header={<PageTitle>Edit profile</PageTitle>}
    >
      <UserProfileMain {...props} />
    </App>
  );
};

export const UserProfileWrapper = (ownProps: OwnProps) => {
  const { loading, error, data } = useQuery(GET_USER_PROFILE);
  const [
    updateUser,
    { loading: updateUserLoading, error: updateUserError, data: updateUserData }
  ] = useMutation(UPDATE_USER, {
    onError(): void {
      ownProps.flash("error", "An error occurred while updating your profile.");
    }
  });
  if (loading) {
    return <UserProfile {...ownProps} loading />;
  }
  if (error) {
    return <UserProfile {...ownProps} error="Uh-oh something went wrong 😿" />;
  }
  return (
    <UserProfile
      {...ownProps}
      updateUser={updateUser}
      updateUserData={updateUserData}
      updateUserError={updateUserError}
      updateUserLoading={updateUserLoading}
      user={data?.user}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserProfileWrapper);
