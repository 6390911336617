/* @flow */
import React from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import AccountAvatar from "images/AccountAvatar";
import AccountProviderIcon from "icons/AccountProviderIcon";

import SVGArrowDown from "../icons/arrow-down.svg";
import BodySkeleton from "../Categories/BodySkeleton";

import useAccounts from "./use";
import type { IProps } from "./types.js";
import {
  Wrapper,
  Header,
  HeaderItem,
  Body,
  Empty,
  BodyRow,
  BodyItem,
  AvatarWrapper
} from "../Categories/styles";

const AccountsBreakdown = (props: IProps) => {
  const { loading, data } = useAccounts(props);

  return (
    <Wrapper>
      <Header>
        <HeaderItem className="label">
          Accounts
          <img src={SVGArrowDown} alt="arrow down" />
        </HeaderItem>
        <HeaderItem className="span-col-3">Content sent</HeaderItem>
        <HeaderItem className="span-col-3">Total clicks</HeaderItem>
        <HeaderItem className="span-col-3">Impressions</HeaderItem>
      </Header>

      <Body>
        {loading ? (
          <BodySkeleton />
        ) : (
          <>
            {!data || !data?.length ? (
              <Empty>There are no data for the selected period.</Empty>
            ) : (
              <>
                {data.map((item, index) =>
                  item.account ? (
                    <BodyRow key={index}>
                      <BodyItem className="label">
                        <AvatarWrapper
                          className="accounts"
                          data-for="accounts-breakdown-tooltip"
                          data-tip={item.account.providerDisplayName}
                        >
                          <AccountAvatar
                            src={item.account.image}
                            alt={item.account.provider}
                            key={index}
                          />
                          <AccountProviderIcon
                            provider={item.account.provider}
                            className="providerIcon"
                          />
                        </AvatarWrapper>

                        {item.account.name}
                      </BodyItem>
                      <BodyItem className="span-col-3">
                        {item.sentCount}
                      </BodyItem>
                      <BodyItem className="span-col-3">
                        {item.totalClicks}
                      </BodyItem>
                      <BodyItem className="span-col-3">
                        {item.impressionCount}
                      </BodyItem>
                    </BodyRow>
                  ) : null
                )}

                <ReactTooltip
                  effect="solid"
                  place="top"
                  type="info"
                  backgroundColor="#000"
                  id="accounts-breakdown-tooltip"
                />
              </>
            )}
          </>
        )}
      </Body>
    </Wrapper>
  );
};

export default connect(
  undefined,
  undefined
)(AccountsBreakdown);
