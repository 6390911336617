/* @flow */
import React from "react";

import { featureFlag } from "util";

import DropDown from "layout/DropDown";
import AccountProviderIcon from "icons/AccountProviderIcon";

import SVGCaretDown from "components/common/images/caret-down.svg";
import SVGLoading from "components/common/images/loading-pulse.svg";

import useAccountSelect from "./use";
import type { Props } from "./types.js";
import styles from "./index.css";
import { Wrapper, Container, Loading, Selected, Item } from "./styles";

const AccountSelect = (props: Props) => {
  const { selectAccount, selectedAccount } = props;
  const {
    loading,
    accounts,
    dropdownVisible,
    showDropdown,
    hideDropdown,
    handleAccount
  } = useAccountSelect(props);

  return (
    <Wrapper>
      <Container>
        {loading ? (
          <Loading>
            Loading
            <img src={SVGLoading} alt="Loading" width="22" />
          </Loading>
        ) : (
          <>
            <Selected
              onClick={featureFlag("dashboard_v2") ? showDropdown : () => {}}
              hasFeatureFlag={featureFlag("dashboard_v2")}
            >
              {!selectedAccount ? (
                "All accounts"
              ) : (
                <>
                  <AccountProviderIcon
                    provider={selectedAccount.provider}
                    className={styles.selectedProviderIcon}
                  />
                  {selectedAccount.name}
                </>
              )}
              {featureFlag("dashboard_v2") && (
                <img
                  src={SVGCaretDown}
                  className="caret-down"
                  alt="dropdown icon"
                />
              )}
            </Selected>
            {accounts.length ? (
              <DropDown
                open={dropdownVisible}
                anchor="bottomRight"
                onClose={hideDropdown}
                handleEscapeKeyPress={hideDropdown}
                includeOverlay
              >
                <Item onClick={() => selectAccount()}>All accounts</Item>
                {accounts.map((item, index) => (
                  <Item key={index} onClick={() => handleAccount(item)}>
                    <AccountProviderIcon
                      provider={item.provider}
                      className={styles.providerIcon}
                    />
                    {item.name}
                  </Item>
                ))}
              </DropDown>
            ) : null}
          </>
        )}
      </Container>
    </Wrapper>
  );
};

export default AccountSelect;
