/* @flow */
import React, { type Node } from "react";

import IconDownload from "./download-icon.png";

import styles from "./index.css";

type Props = {
  text?: Node,
  title?: Node,
  top?: Node,
  downloadable?: boolean
};

const ArticleCard = ({ text, title, top, downloadable }: Props) => {
  if (!top && !title && !text) {
    return null;
  }
  return (
    <div className={styles.card}>
      {downloadable && (
        <div className={styles.download}>
          <img src={IconDownload} alt="download" />
        </div>
      )}
      {top && <div className={styles.top}>{top}</div>}
      <div className={styles.body}>
        {title && <p className={styles.title}>{title}</p>}
        {text && <small>{text}</small>}
      </div>
    </div>
  );
};

export default ArticleCard;
