import styled from "styled-components";

export const Content = styled.div`
  border-radius: 4px;
  border: 1px solid #c5c5c5;
  margin-top: 23px;
`;

export const Header = styled.div`
  margin: 20px 15px;

  span {
    color: #444;
    font-size: 14px;
    font-weight: 400;
    margin: 13px 0 0 5px;
  }
`;

export const Image = styled.div`
  > div {
    height: 220px;
    max-width: 100%;

    img {
      max-width: 100%;
    }
  }

  span {
    font-size: 16px;
    font-weight: 400;
    left: 38%;
    position: absolute;
    top: 132px;
  }
`;

export const Data = styled.div`
  margin: 15px 15px 20px;
`;

export const Attribute = styled.div`
  color: #444;
  font-size: 14px;
  font-weight: 400;
  margin-top: 13px;

  &.name {
    font-size: 15px;
    font-weight: 600;
  }
`;
