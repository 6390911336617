/* @flow */
import React from "react";
import classNames from "classnames";
import styles from "./index.css";

type DotType = "ac" | "sm" | "md" | "lg";

type Props = {
  type: DotType
};

export const Indicator = ({ type }: Props) => (
  <div
    className={classNames(styles.root, {
      [styles.active]: type == "ac",
      [styles.small]: type == "sm",
      [styles.medium]: type == "md",
      [styles.large]: type == "lg"
    })}
  />
);

export default Indicator;
