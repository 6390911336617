// @flow
import { compact, get } from "lodash";
import type { Provider } from "graphql-types/loadComposer";

export default (rawRichTextEntityMap: ?Object): ?Provider => {
  const entityMap = get(rawRichTextEntityMap, "entityMap");
  if (!entityMap) return null;

  const accountProviders = compact(
    Object.keys(entityMap).map(key =>
      get(entityMap, `[${key}].data.mention.provider`)
    )
  ).map(p => p.toUpperCase().replace(" ", "_"));

  return accountProviders.length ? accountProviders[0] : null;
};
