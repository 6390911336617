/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation bulkContentUpdate($input: BulkContentUpdateInput!) {
    bulkContentUpdate(input: $input) {
      errors
    }
  }
`;
