/* @flow */
import { gql } from "apollo-boost";

export default gql`
  subscription refreshList($pageId: String!) {
    refreshList(pageId: $pageId) {
      refreshList
    }
  }
`;
