/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation upgradeSubscription($totalAccount: Int!) {
    upgradeSubscription(totalAccount: $totalAccount) {
      company {
        id
        categoryLimit
      }
      errors
    }
  }
`;
