/* @flow */
/* global EventHandler */
import React from "react";
import Icon from "icons/Icon";
import styles from "./index.css";

const EditButton = ({ onClick }: { onClick?: EventHandler }) => (
  <Icon type="pencil" className={styles.editButton} onClick={onClick} />
);

export default EditButton;
