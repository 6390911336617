import styled from "styled-components";

export const Loading = styled.div`
  padding: 24px;
`;

export const Header = styled.div`
  display: flex;
  padding: 24px;

  img {
    border-radius: 50%;
    height: 60px;
    object-fit: contain;
    width: 60px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 14px;

    h4 {
      font-size: 16px;
      font-weight: 400;
    }

    span {
      color: #a6a6a6;
      font-size: 12px;
    }
  }
`;
