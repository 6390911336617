// @flow
import React from "react";
import RejectionModal from "../common/RejectionModal";

type Props = {
  reasons: string[],
  onAcknowledge: () => void
};

const InvalidContent = ({ reasons, onAcknowledge }: Props) => {
  if (reasons.includes("Instagram posts require a minimum of one image.")) {
    return (
      <RejectionModal
        reasons={[]}
        title={"Please add an image to this update"}
        subTitle={
          "Instagram requires an image to be attached to any content posted."
        }
        onAcknowledge={onAcknowledge}
      />
    );
  }
  return (
    <RejectionModal
      reasons={reasons}
      title={"Content is invalid"}
      subTitle={""}
      onAcknowledge={onAcknowledge}
    />
  );
};

export default InvalidContent;
