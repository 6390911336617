/* @flow */
import React from "react";
import { connect } from "react-redux";

import { featureFlag } from "util";
import type { AppState } from "types";

import SVGLayout from "./img/layout.svg";
import SVGArrowRight from "./img/arrow-right.svg";

import useSwitchToOld from "./use";
import type { IProps } from "./types.js";
import { Wrapper, Header, Button, CollapsedIcon } from "./styles";

const SwitchToOld = (props: IProps) => {
  const { navbarCollapsed } = props;
  const {
    loading,
    handleOldUI,
    windowWidth,
    showOldViewButton
  } = useSwitchToOld();

  return !featureFlag("force_new_ui") && showOldViewButton ? (
    <Wrapper
      className={
        !!navbarCollapsed ? "collapsed " : "" + (loading ? "disabled" : "")
      }
      data-tip={navbarCollapsed ? "Go back to the old UI" : ""}
      onClick={handleOldUI}
    >
      {navbarCollapsed || windowWidth < 1300 ? (
        <CollapsedIcon src={SVGLayout} alt="layout icon" />
      ) : (
        <>
          <Header>
            <h3>You're using the new MeetEdgar view</h3>
            <img src={SVGLayout} alt="layout icon" />
          </Header>
          <Button>
            Go back to the old MeetEdgar view
            <img src={SVGArrowRight} alt="arrow right" />
          </Button>
        </>
      )}
    </Wrapper>
  ) : null;
};

const mapStateToProps = (state: AppState) => ({
  navbarCollapsed: state.layout?.data.navbarCollapsed
});

export default connect(mapStateToProps)(SwitchToOld);
