/* @flow */
import React from "react";

import * as TimeUtil from "./time";
import { Wrapper } from "./styles";

type Props = {
  value: string,
  disabled?: boolean,
  onChange: (newValue: string) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  onKeyDown?: () => void,
  id?: string
};

function handleChange(onChange, evt) {
  return onChange(evt.target.value);
}

const TimeInput = ({
  id,
  value,
  disabled,
  onChange,
  onFocus,
  onBlur,
  onKeyDown
}: Props) => (
  <Wrapper>
    <input
      id={id}
      value={value}
      disabled={disabled}
      onChange={handleChange.bind(null, onChange)}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  </Wrapper>
);

export { TimeUtil };

export default TimeInput;
