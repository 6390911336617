// @flow
import React, { Component } from "react";
import tokenizeText from "./tokenizeText";
import styles from "./index.css";

import type { RawRichTextEntityMap } from "types";

type Props = {
  value: string,
  decorateMentions: boolean,
  rawRichTextEntityMap: ?RawRichTextEntityMap,
  onClick: () => void
};

class BlurredInput extends Component<Props> {
  handleClick = (evt: Event) => {
    evt.stopPropagation();
    this.props.onClick();
  };

  render() {
    const { value, decorateMentions, rawRichTextEntityMap } = this.props;
    const tokens = tokenizeText(value, rawRichTextEntityMap);

    return (
      <div className={styles.root} onClick={this.handleClick}>
        {tokens.map(({ value: tokenValue, type }, i) =>
          decorateMentions && type === "mention" ? (
            <span key={i}>{tokenValue}</span>
          ) : (
            <span key={i}>{tokenValue}</span>
          )
        )}
      </div>
    );
  }
}

export default BlurredInput;
