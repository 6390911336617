import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  color: #90a4ae;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin: 2.5px 0 -2.5px;
`;

export const Like = styled.div`
  align-items: center;
  height: 30px;
  display: flex;

  img {
    margin: -0.5px 0 0 0;
    width: 30px;
  }

  span {
    margin: 2px -1px 0 0;
  }
`;

export const Reply = styled.div`
  cursor: pointer;
  margin: 0 0 0 7.5px;
`;
