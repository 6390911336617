import styled, { keyframes } from 'styled-components';

const minWidth = window.location.href.includes("/schedule") ? 1336 : 1300;

export const Wrapper = styled.div`
  @media (min-width: ${minWidth}px) {
    &:hover > .nav-collapse-button {
      display: flex;
    }
  }
`;

export const Sidebar = styled.div`
  background-color: #272833;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding: 30px 0 22.5px;
  position: fixed;
  top: 0;
  width: 88px;
  z-index: 10;

  img.icon {
    margin: 0 8px 0 0;
  }

  @media (min-width: ${minWidth}px) {
    transition: all 1s linear;
    max-width: 272px;
    width: 100%;

    &:hover + .nav-collapse-button {
      display: flex;
    }
  }

  @media print {
    display: none;
  }

  &.collapsed {
    transition: none;
    width: 88px;
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  padding: 0 22.5px;

  .letters {
    display: none;
    margin-top: 3px;
    width: 123px;
  }

  @media (min-width: ${minWidth}px) {
    .letters {
      display: block;
    }
  }
`;

export const Body = styled.div`
  flex: 2;
  margin: 20px 0;
  padding: 0 13px;
`;

export const Item = styled.a`
  align-items: center;
  border-radius: 50px;
  color: #fff !important;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 50px;
  justify-content: center;
  line-height: 25px;
  margin: 1px 0;
  padding: 0 18px;
  position: relative;
  text-decoration: none;

  img {
    width: 22px;
  }

  span {
    display: none;
  }

  &:hover {
    background: rgba(113, 120, 121, 0.25);
    text-decoration: none;
  }

  &.active {
    background: rgba(113, 120, 121, 0.5);
  }

  @media (min-width: ${minWidth}px) {
    justify-content: flex-start;

    img {
      margin-right: 9px;
    }

    span {
      display: block;
    }
  }

  &.collapsed {
    justify-content: center;

    img {
      margin-right: 0;
    }
  }
`;

export const AlertIcon = styled.div`
  color: #a04250;
  font-size: 20px;
  left: 3px;
  position: absolute;
  top: -3px;
  z-index: 1;

  .background {
    background-color: #fff;
    border-radius: 50%;
    height: 14px;
    margin: 6px 0 0 3px;
    position: absolute;
    width: 14px;
    z-index: -1;
  }
`;

export const LitePlanUpgrade = styled.div`
  background: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  color: #272833;
  display: none;
  font-size: 10px;
  height: 18px;
  line-height: 18px;
  margin: 0 0 0 10px;
  padding: 0 5px;

  @media (min-width: 1300px) {
    display: block;
  }
`;


const blinkAnimation = keyframes`
  50% {
    opacity: 0;
  }
`;

export const Footer = styled.div`
  padding: 0 13px;

  .inky-collapsed {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-bottom: 15px;
  }

  .new {
    color: #fff;
    font-weight: bold;
    animation: ${blinkAnimation} 1s linear infinite;
  }

  .inky {
    margin-left: 12px;
    width: 200px;
    height: 200px;
  }
  .inky-text {
    color: #fff;
    font-style: italic;
  }
`;


export const Collapse = styled.div`
  align-items: center;
  background-color: rgba(39, 40, 51, 1);
  border-radius: 15px;
  cursor: pointer;
  display: none;
  height: 50px;
  justify-content: center;
  left: 272px;
  margin: -25px 0 0 -15px;
  position: fixed;
  top: 50%;
  width: 30px;
  z-index: 11;

  &:hover {
    background: rgba(29, 30, 41, 1);
  }

  @media (min-width: ${minWidth}px) {
    transition: all 0.1s linear;
    left: 272px;
  }

  &.collapsed {
    left: 88px;
    transition: none;

    > img {
      transform: rotate(180deg);
    }
  }
`;
