/* @flow */
import React from "react";
import TotalClicksChart from "./TotalClicksChart";
import ShortLinksTable from "./ShortLinksTable";

import type {
  getContentPerformance_content_variations as VariationData,
  getContentPerformance_content_variations_shortLinks as ShortLinkData
} from "graphql-types/getContentPerformance";

function shortLinkLabel(variations, variationId, shortLinkId) {
  const variationIdx = variations.findIndex(v => v.id === variationId);
  const variation = variations[variationIdx];
  const shortLinks = variation.shortLinks;
  let letter = "";

  if (shortLinks.length > 1) {
    const shortLinkIdx = shortLinks.findIndex(s => s.id === shortLinkId);
    letter = String.fromCharCode("a".charCodeAt(0) + shortLinkIdx);
  }

  return `Variation ${variationIdx + 1}${letter}`;
}

type Props = {
  shortLinks: ShortLinkData[],
  variations: VariationData[]
};

export const Analytics = ({ shortLinks, variations }: Props) => {
  if (!shortLinks.length) return null;

  const totalClicksForContent = shortLinks.reduce(
    (res, link) => res + link.totalClicks,
    0
  );

  const labeledShortLinks = shortLinks.map(shortLink => ({
    ...shortLink,
    label: shortLinkLabel(variations, shortLink.variation.id, shortLink.id)
  }));

  return (
    <div>
      {totalClicksForContent > 0 && (
        <TotalClicksChart shortLinks={labeledShortLinks} />
      )}

      <ShortLinksTable shortLinks={labeledShortLinks} />
    </div>
  );
};

export default Analytics;
