/* @flow */
import React from "react";
import moment from "moment-timezone";
import sortBy from "lodash/sortBy";
import { H2, H3 } from "typography";
import { Icon } from "icons";
import { Link } from "links";
import Table from "components/common/Table";
import Card from "layout/Card";
import Paginator from "components/Paginator";
import styles from "./index.css";

import type {
  getContentPerformance_content_variations as VariationData,
  getContentPerformance_content_posts as PostData
} from "graphql-types/getContentPerformance";

function getPostsForVariation(posts, variation) {
  return (posts || []).filter(p => p.variation.id === variation.id);
}

function formatSentAt(sentAt, timezone) {
  if (!sentAt) {
    return null;
  }
  const localTime = moment.tz(sentAt, timezone);
  return `${localTime.format("ddd, MMM Do YYYY")} at ${localTime.format(
    "h:mma"
  )}`;
}

type Props = {
  variations: ?(VariationData[]),
  timezone: ?string,
  posts: ?(PostData[]),
  totalPostCount: ?number,
  page: ?number,
  pageCount: ?number,
  totalPostCount: ?number,
  onPageChange: ?(number) => void
};

const ContentHistory = ({
  variations,
  timezone,
  posts,
  totalPostCount,
  page,
  pageCount,
  onPageChange
}: Props) => (
  <>
    <H2>Content History</H2>
    <p>Total sent: {totalPostCount}</p>
    <Card>
      {variations
        ? variations.map((variation, idx) => {
            const postsForVariation = sortBy(
              getPostsForVariation(posts, variation),
              p => p.sentAt || p.sendAt
            ).reverse();
            if (postsForVariation.length === 0) {
              return null;
            }
            return (
              <div key={idx} className={styles.variationHistory}>
                <H3>Variation {idx + 1}</H3>
                <Table className={styles.historyTable}>
                  <thead>
                    <tr>
                      <th>Sent at</th>
                      <th>Account</th>
                    </tr>
                  </thead>
                  <tbody>
                    {postsForVariation.map((post, idx) => (
                      <tr key={idx}>
                        <td>
                          {formatSentAt(post.sentAt || post.sendAt, timezone)}
                          {post.url && post.videoStatus === "processed" ? (
                            <Link
                              href={post.url}
                              target="_blank"
                              className={styles.postLink}
                            >
                              <Icon type="external-link" />
                            </Link>
                          ) : null}
                        </td>
                        <td>{post.account.displayName}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            );
          })
        : null}
    </Card>
    <Paginator page={page} pageCount={pageCount} onPageChange={onPageChange} />
  </>
);

export default ContentHistory;
