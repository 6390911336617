/* @flow */
import React, { useState, useEffect } from "react";

import DesktopCalendar from "./DesktopCalendar";
import MobileCalendar from "./MobileCalendar";

import type {
  CalendarDay,
  CalendarHour,
  CalendarMinute,
  CalendarItem
} from "./util.js";

export type { CalendarItem };
export type Props = {
  schedules: CalendarItem[],
  focusedTimeslotIds: string[],
  onClickNewTimeslot: (CalendarDay, CalendarHour, CalendarMinute) => void,
  onClickEditTimeslot: CalendarItem => void
};

const DESKTOP_MOBILE_BREAKPOINT = 695;

function getWindowWidth() {
  return window.innerWidth;
}

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

const Calendar = (props: Props) => {
  const windowWidth = useWindowWidth();

  return windowWidth >= DESKTOP_MOBILE_BREAKPOINT ? (
    <DesktopCalendar {...props} />
  ) : (
    <MobileCalendar {...props} />
  );
};

export default Calendar;
