// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";
import Icon from "icons/Icon";
import Caption from "typography/Caption";

export const Tooltip = () => <Icon type="info-circle" color="textMid" />;

const TipBody = () => (
  <Caption textAlign="center">
    To fetch all the Ad Accounts you need to refresh your LinkedIn Company Account from the Social Media Accounts page.
  </Caption>
);

export default tooltipper(TipBody)(Tooltip);
