/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation updatePaymentMethod($paymentMethod: PaymentMethodInput!) {
    updatePaymentMethod(paymentMethod: $paymentMethod) {
      errors
    }
  }
`;
