/* @flow */
import React from "react";

import MEIcon from "components/common/images/meetedgar-icon-white.png";

import useFooter from "./use";
import type { IProps } from "./types.js";
import { Wrapper, Container, Left, Icon, Input, Send, Error } from "./styles";

const InboxContentFooter = (props: IProps) => {
  const {
    newComment,
    handleChange,
    loading,
    createComment,
    handleKeyPress,
    error
  } = useFooter(props);

  const limitComment = newComment.length > 8000;

  return (
    <Wrapper>
      <Container isLoading={loading}>
        <Left>
          <Icon>
            <img src={MEIcon} alt="Meet Edgar icon" />
          </Icon>
        </Left>
        <Input>
          <input
            placeholder="Write your comment..."
            disabled={loading}
            value={newComment}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
          />
        </Input>
        <Send onClick={createComment}>
          {!!newComment.trim().length && !limitComment ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <g data-name="1">
                <path d="M291.48 449.94A15 15 0 01278 441.5l-70.5-144.93-144.93-70.49a15 15 0 011-27.41L435.48 49.08A15 15 0 01455 68.6L305.4 440.54a15 15 0 01-13.4 9.39zm-185.38-236l119.18 58a15 15 0 016.93 6.93l58 119.18L414 90z" />
                <path d="M218.72 300.35a15 15 0 01-10.6-25.61L430.47 52.39a15 15 0 1121.21 21.22L229.33 296a15 15 0 01-10.61 4.35z" />
              </g>
            </svg>
          ) : null}
        </Send>
      </Container>
      {error ? (
        <Error>{error}</Error>
      ) : limitComment ? (
        <Error>Comments cannot be longer than 8,000 characters.</Error>
      ) : null}
    </Wrapper>
  );
};

export default InboxContentFooter;
