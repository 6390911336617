/* @flow */
import React from "react";
import BaseModal from "layout/modals/Modal";
import styles from "./index.css";

type Props = {
  url: string,
  onClickHide: () => void
};

const Modal = (props: Props) => {
  const { url, onClickHide } = props;

  return (
    <BaseModal closeOnOverlayClick onClose={onClickHide}>
      <img className={styles.image} src={url} alt={url} />
    </BaseModal>
  );
};

export default Modal;
