// @flow
import React from "react";
import HtmlizedText from "components/common/HtmlizedText";
import styles from "./index.css";
import type { RawRichTextEntityMap } from "types";

export const LINK_CHAR_COUNT = 23;
export const TRUNCATION_THRESHOLD = 1500; // px

type Props = {
  value: string,
  omitTrailingLinks?: boolean,
  mentions: string[],
  rawRichTextEntityMap: ?RawRichTextEntityMap
};

export const getTextWidth = function(text: string) {
  // re-use canvas object for better performance
  var canvas =
    getTextWidth.canvas ||
    (getTextWidth.canvas = document.createElement("canvas"));
  var context = canvas.getContext("2d");
  context.font = "normal 14px Helvetica Neue";
  var metrics = context.measureText(text);
  return metrics.width;
};

const Text = ({ value, omitTrailingLinks }: Props) => (
  <div className={styles.container}>
    <HtmlizedText
      value={value}
      provider="linkedin"
      truncateLinksTo={LINK_CHAR_COUNT}
      omitTrailingLinks={omitTrailingLinks}
      omitLinkScheme
      className={styles.root}
      newLineClassName={styles.newLine}
    />
    {getTextWidth(value) > TRUNCATION_THRESHOLD && (
      <div className={styles.overflowText}>...see more</div>
    )}
  </div>
);

export default Text;
