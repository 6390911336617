/* @flow */
import React, { type Node } from "react";

const childrenWithoutAtSymbol = (children: Node) =>
  React.Children.map(children, child => {
    const mentionText = child.props.text;

    if (mentionText && mentionText.startsWith("@")) {
      return React.cloneElement(child, {
        text: mentionText.slice(1)
      });
    }

    return child;
  });

export default childrenWithoutAtSymbol;
