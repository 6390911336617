/* @flow */
import React from "react";
import _ from "lodash";

import PulseGIF from "components/common/images/loading-pulse.svg";

import type { IProps } from "./types.js";
import { Wrapper, Empty, LoadOlder } from "./styles";

import Comment from "./Comment";

const InboxComments = (props: IProps) => {
  const { data, loadOlderComments, loadingMore, post } = props;

  return (
    <Wrapper>
      {!data.comments?.length ? (
        <Empty>No comments yet.</Empty>
      ) : (
        <>
          {!!data.cursor && (
            <LoadOlder isLoading={loadingMore}>
              <div onClick={loadOlderComments}>
                Load{loadingMore && "ing"} older comments{" "}
                {loadingMore && <img src={PulseGIF} alt="loading" />}
              </div>
            </LoadOlder>
          )}

          {_.reverse([...data.comments]).map((item, index) => (
            <Comment item={item} key={index} post={post} />
          ))}
        </>
      )}
    </Wrapper>
  );
};

export default InboxComments;
