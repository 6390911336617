/* @flow */
import React, { createRef, useState, useEffect } from "react";
import get from "lodash/get";
import buildColor from "color";
import moment from "moment";
import classnames from "classnames";
import _ from "lodash";

import { colorFromIndex } from "colors";
import AccountBadge from "icons/AccountBadge";
import Icon from "icons/Icon";

import styles from "./index.css";
import {
  Wrapper,
  Slot,
  SlotWrapper,
  TimeLabel,
  TimeLeft,
  TimeText,
  SuggestedSlot,
  SlotLabel,
  AccountBadgesWrapper,
  AccountBadgeWrapper
} from "./styles";

import type { CalendarHour, CalendarMinute, CalendarItem } from "../util.js";

type Props = {
  hour: CalendarHour,
  minute: CalendarMinute,
  schedules: CalendarItem[],
  focusedTimeslotIds: string[],
  onClickTimeslot: CalendarItem => void
};

const Timeslots = ({
  hour,
  minute,
  schedules,
  focusedTimeslotIds,
  onClickTimeslot
}: Props) => {
  if (schedules.length === 0) {
    return null;
  }

  const label = moment
    .utc()
    .set({ hour, minute })
    .format("h:mm a");

  return (
    <Wrapper>
      {schedules.map(schedule =>
        schedule.__typename === "Schedule" ? (
          <Timeslot
            focused={focusedTimeslotIds.includes(schedule.id)}
            key={schedule.id}
            schedule={schedule}
            onClick={onClickTimeslot}
            label={label}
          />
        ) : (
          <SuggestedTimeslot
            key={`${schedule.sendOn} ${schedule.sendAt}`}
            suggestedSchedule={schedule}
            onClick={onClickTimeslot}
            label={label}
          />
        )
      )}
    </Wrapper>
  );
};

const RANDOM_CATEGORY_COLOR = "rgba(0, 0, 0, 0.65)";

const Timeslot = ({ schedule, focused, onClick, label }) => {
  const colorIndex = get(schedule, "category.colorIndex");
  const backgroundColor: any = colorIndex
    ? buildColor(colorFromIndex(colorIndex))
    : buildColor(RANDOM_CATEGORY_COLOR);

  const ref = createRef();
  const [wasFocused, setWasFocused] = useState(false);

  useEffect(() => {
    if (!wasFocused && focused && ref.current) {
      // If this timeslot gained focus scroll to it
      const scrollToY = ref.current.offsetTop - 200;
      setTimeout(() => window.scrollTo(0, scrollToY), 0);
    }
    setWasFocused(focused);
  }, [focused, ref, wasFocused]);

  function handleClick(evt) {
    onClick(schedule);
    evt.stopPropagation();
  }

  const type: "content" | "category" =
    !schedule.content || _.isEmpty(schedule.content) ? "category" : "content";
  const minute = schedule.sendAt.split(":")[1].split(" ")[0];

  return (
    <Slot
      ref={ref}
      className={classnames({ [styles.focused]: focused })}
      id={`schedule-${schedule.id}`}
      minute={minute}
      onClick={handleClick}
      title={type === "content" ? "Content schedule" : "Category schedule"}
    >
      <SlotWrapper
        style={{
          color: backgroundColor,
          backgroundColor: backgroundColor.fade(0.8)
        }}
      >
        <TimeLabel>
          <TimeLeft>
            <TimeText>{label}</TimeText>
            <AccountBadges accounts={schedule.accounts} />
          </TimeLeft>
        </TimeLabel>
        <SlotLabel className={`type-${type}`}>
          {type === "content"
            ? "Individual post"
            : get(schedule, "category.name", "Random")}
        </SlotLabel>
      </SlotWrapper>
    </Slot>
  );
};

const SuggestedTimeslot = ({ suggestedSchedule, onClick, label }) => {
  function handleClick(evt) {
    onClick(suggestedSchedule);
    evt.stopPropagation();
  }

  return (
    <SuggestedSlot onClick={handleClick}>
      <TimeLabel>
        <TimeLeft>
          <TimeText>{label}</TimeText>
          <AccountBadges accounts={suggestedSchedule.accounts} />
        </TimeLeft>
      </TimeLabel>
      <SlotLabel>Suggested time</SlotLabel>
    </SuggestedSlot>
  );
};

const AccountBadges = ({ accounts }) =>
  accounts.length > 0 ? (
    <AccountBadgesWrapper>
      {accounts.map(account => (
        <AccountBadgeWrapper key={account.id}>
          <AccountBadge value={account} size="tiny" />
        </AccountBadgeWrapper>
      ))}
    </AccountBadgesWrapper>
  ) : (
    <div className={styles.noAccounts}>
      <Icon type="ban" size="lg" className={styles.noAccountsIcon} />
      No accounts
    </div>
  );

export default Timeslots;
