import React, { Component } from "react";
import { confetti } from "dom-confetti";
import styles from "./index.css";

export default class Party extends Component {
  componentDidMount() {
    if (window.location.hash.substr(1) === "party") {
      setTimeout(() => {
        confetti(this.refs.partyRef, {
          spread: 90,
          elementCount: 75
        });
        // Party over :(
        // Remove the url fragment so that a refresh doesn't restart the party.
        window.location.replace("#");
      }, 1000);
    }
  }

  render() {
    return <div className={styles.root} ref="partyRef" />;
  }
}
