/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query findRssFeed($url: String!) {
    findFeed(url: $url) {
      url
      name
    }
  }
`;
