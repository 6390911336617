// @flow
import React from "react";
import SocialMediaIcon from "icons/SocialMediaIcon";
import H4 from "typography/H4";
import Caption from "typography/Caption";
import { featureFlag } from "util";
import styles from "./index.css";
import type { getContents_contents_data_accountRelationships_account as Account } from "graphql-types/getContents";

type Props = {
  selectedAccounts: Account[]
};

const NullState = ({ selectedAccounts }: Props) => (
  <div className={styles.root}>
    {selectedAccounts.length > 0 && (
      <ul className={styles.platformIcons}>
        <li>
          <SocialMediaIcon className={styles.icon} value="facebook" />
        </li>
        <li>
          <SocialMediaIcon className={styles.icon} value="instagram" />
        </li>
        <li>
          <SocialMediaIcon className={styles.icon} value="linkedin" />
        </li>
        {featureFlag("pinterest") && (
          <li>
            <SocialMediaIcon className={styles.icon} value="pinterest" />
          </li>
        )}
        <li>
          <SocialMediaIcon className={styles.icon} value="twitter" />
        </li>
      </ul>
    )}
    <H4 className={styles.header}>You haven't selected any accounts</H4>
    <Caption className={styles.instructions} helpText>
      Please select the account(s) you would like to post with from the
      "Accounts" panel on the left.
    </Caption>
  </div>
);

export default NullState;
