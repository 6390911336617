/* @flow */
import { useState } from "react";

import type { Props } from "./types.js";

const useShopifyAuthentication = (props: Props) => {
  const { onClickHide } = props;
  const [shopifyImportModal, setShopifyImportModal] = useState(false);
  const [importSuccessFul, setImportSuccessFul] = useState(false);

  const showShopifyModal = () => setShopifyImportModal(true);

  const hideShopifyModal = () => {
    setShopifyImportModal(false);
    onClickHide();
    window.history.replaceState({}, "", "/import");
  };

  const showImportSuccessful = () => setImportSuccessFul(true);

  return {
    shopifyImportModal,
    showShopifyModal,
    hideShopifyModal,
    importSuccessFul,
    showImportSuccessful
  };
};

export default useShopifyAuthentication;
