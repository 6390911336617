// @flow
import { reportException } from "errors";
import muxjs from "mux.js";
import { VideoMetadata } from "./videoMetadata";

export type AnalyzedVideo = {
  fileSize: ?number,
  width: ?number,
  height: ?number,
  seconds: ?number,
  formats: string[]
};

export const READ_ERROR_MESSAGE =
  "An error occured while trying to read the video. Please try again.";
export const ANALYSIS_ERROR_MESSAGE =
  "Edgar had trouble parsing your video file. Run the video through a video parsing service like videosmaller.com and then try again.";

const analyzeVideo = (file: File): Promise<AnalyzedVideo> => {
  const fr = new window.FileReader();

  return new Promise((resolve, reject) => {
    const handleFileReaderLoad = ({ target: { result } }) => {
      const bytes = new Uint8Array(result);

      const parsed = muxjs.mp4.tools.inspect(bytes);
      const metadata = new VideoMetadata(parsed);

      // See http://l.web.umkc.edu/lizhu/teaching/2016sp.video-communication/ref/mp4.pdf
      // for a specification of MPEG-4 boxes and file layout.
      // See https://developer.apple.com/standards/qtff-2001.pdf
      // for a QuickTime format specification.
      const fileSize = metadata.number("mdat", "size") == 1 ? metadata.number("mdat", "byteLength") : metadata.number("mdat", "size");

      // Don't allow filenames without valid extensions.
      const extension = file.name.split(".").pop();
      const validExtensions = ["mp4", "m4v", "3gp", "3g2", "mov", "qt"];
      let formats = [];

      if (extension && validExtensions.includes(extension.toLowerCase())) {
        const majorBrand = metadata.string("ftyp", "majorBrand");
        formats = metadata.array("ftyp", "compatibleBrands");
        if (majorBrand) {
          formats.push(majorBrand);
        }
      }

      // there might be multiple tracks on the video, need to find the min/max dimensions,
      const trackCount = metadata.number("mvhd", "nextTrackId") - 1;

      let height = 0;
      let width = 0;

      for (let trackId = 0; trackId < trackCount; trackId++) {
        const thisWidth: number = metadata.number("tkhd", "width", trackId);
        const thisHeight: number = metadata.number("tkhd", "height", trackId);
        if (thisWidth && thisWidth !== 0 && thisWidth > width) {
          width = thisWidth;
        }
        if (thisHeight && thisHeight !== 0 && thisHeight > height) {
          height = thisHeight;
        }
      }

      if (bytes.length > 0 && height == 0 && width == 0) {
        reject(ANALYSIS_ERROR_MESSAGE);
      }

      const timescale = metadata.number("mvhd", "timescale");
      const unscaledDuration = metadata.number("mvhd", "duration");
      const seconds = Math.trunc(unscaledDuration / timescale);

      resolve({ fileSize, width, height, seconds, formats });
    };

    const handleFileReadError = ({ target: { error } }) => {
      reportException(error, "lib:videos:analyzeVideo:handleFileReadError");
      reject(READ_ERROR_MESSAGE);
    };

    fr.addEventListener("loadend", handleFileReaderLoad);
    fr.addEventListener("error", handleFileReadError);
    fr.readAsArrayBuffer(file);
  });
};

export default analyzeVideo;
