// @flow
import React from "react";
import { track } from "util";
import Select from "forms/Select";
import AccountBadge from "icons/AccountBadge";
import styles from "./index.css";

import type { Account } from "types";

type Props = {
  selectedAccounts: Account[],
  previewAccountId: ?string,
  onChange: (accountId: string) => void
};

function formatAccountName({ platform, name, nickname }: Account) {
  return platform !== "INSTAGRAM" ? name : `@${nickname || ""}`;
}

function formatCaption({ provider, providerDisplayName, nickname }: Account) {
  return provider === "TWITTER" ? `@${nickname || ""}` : providerDisplayName;
}

const PreviewAccountSelect = ({
  selectedAccounts,
  previewAccountId,
  onChange
}: Props) => {
  const options = selectedAccounts.map(account => ({
    label: formatAccountName(account),
    value: account.id,
    caption: formatCaption(account),
    icon: <AccountBadge value={account} tooltipDisabled />
  }));
  const value = options.find(o => o.value === previewAccountId) || null;

  const handleChange = ({ value: accountId }: { value: string }) => {
    const previewAccount = selectedAccounts.find(({ id }) => id === accountId);

    if (!previewAccount) {
      throw new Error(
        "Selected preview account was not present in selectedAccounts"
      );
    }

    // Track how frequently users preview for each network
    const { platform, provider } = previewAccount;
    track("Selected preview account", { platform, provider });

    onChange(accountId);
  };

  return (
    <Select
      options={options}
      className={styles.root}
      value={value}
      onChange={handleChange}
    />
  );
};

export default PreviewAccountSelect;
