/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: flex-start;
  border-top: 1px solid var(--inky150);
  border-radius: var(--elementBorderRadius);
  cursor: pointer;
  display: flex;
  padding: 20px;

  &.active,
  &:hover {
    background-color: var(--inky50);
  }

  &[disabled] {
    opacity: var(--disabledOpacity);

    &:hover {
      background-color: white;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  padding: 3px 0 0;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.div`
  align-items: center;
  display: flex;
`;

export const Label = styled.div`
  font-size: 14px;
  margin-left: 12.5px;
`;

export const ProviderOptions = styled.div`
  cursor: default;
  width: 100%;

  &.mt {
    margin-top: 10px;
  }

  > * {
    margin-top: var(--tinyVertGutter);
  }
`;
