import styled from "styled-components";

export const AccountsMore = styled.div`
  align-items: center;
  background-color: #d6ebee;
  border-radius: 50%;
  border: 1px solid #ffffff;
  color: #4d948f;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 26px;
  justify-content: center;
  margin-left: -5px;
  width: 26px;
`;
