/* @flow */
import React from "react";
import Information from "components/common/layout/Information";

type Props = {
  className?: string
};

const QueueInformation = ({ className }: Props) => (
  <Information name="queueInformation" className={className}>
    Welcome to your Queue! See the next two weeks of content scheduled for your
    social accounts.
  </Information>
);

export default QueueInformation;
