/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getDisplayErrors($page: Page) {
    displayErrors(page: $page) {
      data {
        id
        title
        createdAtWithTimezone
        sanitizedBody
        variation {
          text
        }
        account {
          displayName
        }
        content {
          id
        }
      }
      totalDisplayErrors
    }
    company {
      queueStatus
    }
  }
`;
