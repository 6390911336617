// @flow
import React from "react";
import InvalidAccount from "./InvalidAccount";
import InvalidMedia from "./InvalidMedia";
import InvalidContent from "./InvalidContent";

type Props = {
  rejectionModalReasons: {
    incompatibleAccountReasons: string[],
    incompatibleMediaReasons: { [key: string]: string[] },
    failedValidationReasons: string[]
  },
  onAcknowledge: () => void
};

export const RejectionModals = ({
  rejectionModalReasons: {
    incompatibleAccountReasons,
    incompatibleMediaReasons,
    failedValidationReasons
  },
  onAcknowledge
}: Props) => {
  if (incompatibleAccountReasons.length) {
    return (
      <InvalidAccount
        reasons={incompatibleAccountReasons}
        onAcknowledge={onAcknowledge}
      />
    );
  }

  if (Object.keys(incompatibleMediaReasons).length) {
    return (
      <InvalidMedia
        reasons={incompatibleMediaReasons}
        onAcknowledge={onAcknowledge}
      />
    );
  }

  if (failedValidationReasons.length) {
    return (
      <InvalidContent
        reasons={failedValidationReasons}
        onAcknowledge={onAcknowledge}
      />
    );
  }

  return null;
};

//const mapStateToProps = (state: AppState) => ({
//  incompatibleAccountReasons: selectIncompatibleAccountRejectionReasons(state),
//  incompatibleMediaReasons: selectIncompatibleMediaRejectionReasons(state),
//  failedValidationReasons: selectFailedValidationRejectionReasons(state)
//});
//
//const mapDispatchToProps = (dispatch: Dispatch) => ({
//  onAcknowledge: () => {
//    dispatch(acknowledgeRejections());
//  }
//});
//
//const connector: Connector<{}, Props> = connect(
//  mapStateToProps,
//  mapDispatchToProps
//);

export default RejectionModals;
