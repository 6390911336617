// @flow
import React from "react";
import TimeInput from "forms/TimeInput";
import styles from "./index.css";

type Props = {
  value: Object,
  className: string,
  disabled?: boolean,
  onChange: (newValue: Object) => void
};

type State = {
  fieldValue: string
};

export default class TimeField extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // FIXME
    // -----
    // This works around the fact that the prop value this component accepts must be valid at all times.
    // There is a problem here though in that any prop changes will not be reflected in this field :(.
    // One solution is to change the way that the composer form stores state. Another is to implement
    // a get DerivedStateFromProps or similar function to update the component state.
    this.state = {
      fieldValue: props.value.format("h:mm a")
    };
  }

  handleChange: (time: string) => void = time => {
    this.setState({ fieldValue: time });
  };

  handleBlur: () => void = _ => {
    const time = this.state.fieldValue;
    const { value, onChange } = this.props;
    const newValue = value.clone();
    const splitTime = time.substring(0, time.length - 3).split(":");
    const meridiem = time.substring(time.length - 3, time.length).trim();
    const minute = parseInt(splitTime[1], 10);
    let hour = parseInt(splitTime[0], 10);
    if (meridiem === "pm" && hour !== 12) {
      hour += 12;
    } else if (meridiem === "am" && hour === 12) {
      hour = 0;
    }
    newValue.set({ minute, hour });
    onChange(newValue);
  };

  render() {
    const { className, disabled } = this.props;
    return (
      <div className={`${styles.root} ${className}`} data-disabled={disabled}>
        <TimeInput
          value={this.state.fieldValue}
          disabled={disabled}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
