/* @flow */

import type { Account } from "types";

const hasInstagramAccounts = (accounts: Account[]) => {
  return accounts.some(
    account =>
      account.platform === "instagram" || account.platform === "INSTAGRAM"
  );
};

export default hasInstagramAccounts;
