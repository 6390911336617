/* @flow */
import React from "react";
import ReactTooltip from "react-tooltip";

import AccountAvatar from "images/AccountAvatar";
import AccountProviderIcon from "icons/AccountProviderIcon";

import useSelectedAccounts from "./use";
import type { Props } from "./types.js";
import { Wrapper, Title, Items, Account, ProviderWrapper } from "./styles";

import VariationAccounts from "./VariationAccounts";

const SelectedAccounts = (props: Props) => {
  const { accounts, onChangeAccountText, variation } = props;
  const {
    variationAccounts,
    handleVariationAccounts,
    removeVariationAccount
  } = useSelectedAccounts(props);

  const filteredAccounts = accounts.filter(
    account => !variationAccounts.some(item => item.id === account.id)
  );

  return accounts?.length ? (
    <Wrapper>
      {!variationAccounts?.length && (
        <Title>Click on an account to customize its content</Title>
      )}

      <Items>
        {filteredAccounts.map(item => (
          <Account
            key={item.id}
            data-for="account-tip"
            data-tip={item.name}
            onClick={() => handleVariationAccounts(item)}
          >
            <AccountAvatar src={item.image} alt={item.provider} />
            <ProviderWrapper>
              <AccountProviderIcon provider={item.provider} />
            </ProviderWrapper>
          </Account>
        ))}
      </Items>

      <ReactTooltip effect="solid" place="bottom" id="account-tip" />

      <VariationAccounts
        accounts={variationAccounts}
        removeVariationAccount={removeVariationAccount}
        onChangeAccountText={onChangeAccountText}
        variation={variation}
      />
    </Wrapper>
  ) : null;
};

export default SelectedAccounts;
