/* @flow */
import React from "react";
import Icon from "icons/Icon";
import styles from "./index.css";

type Props = {
  averageClicksPerTimesSent: number,
  mostAverageClicks: number,
  leastAverageClicks: number
};

export const SplitTestLabel = ({
  averageClicksPerTimesSent,
  mostAverageClicks,
  leastAverageClicks
}: Props) => {
  if (averageClicksPerTimesSent === mostAverageClicks) {
    return (
      <span className={styles.thumbsUp}>
        {" "}
        - <Icon type="thumbs-o-up" className={styles.clockIcon} /> Most average
        clicks!
      </span>
    );
  } else if (averageClicksPerTimesSent === leastAverageClicks) {
    const currUrlParts = window.location.href.split("/");
    const contentId = currUrlParts[currUrlParts.length - 1];
    return (
      <span className={styles.thumbsDown}>
        {" "}
        - <Icon type="thumbs-o-down" className={styles.clockIcon} /> Least
        average clicks. <a href={`/contents/${contentId}/edit`}>Edit content</a>
        .
      </span>
    );
  }

  return null;
};

export default SplitTestLabel;
