import styled from "styled-components";

export const Wrapper = styled.div`
  min-width: 180px;
  overflow-y: auto;
  padding-right: 15px;
`;

export const Title = styled.h4``;

export const Loading = styled.div`
  margin: 5px 0 0 -2px;

  img {
    height: auto;
    width: 30px;
  }
`;

export const Empty = styled.div`
  color: #aaa;
  font-size: 12px;
  margin: 3px 0 0;
`;

export const List = styled.div`
  border-left: 1px solid #d1d1d1;
  margin: 4px 0 0 3px;
  padding: 2px 0 2px 6px;
`;

export const Category = styled.div`
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin: 2px 0;
  padding: 8px;
  transition: all 0.1s ease;

  span {
    max-width: 234px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: 600px) {
      max-width: 146px;
    }
  }

  &:hover,
  &.active {
    background-color: #f0f0f0;
  }
`;

export const Circle = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  height: 16px;
  margin: 0 4px 0 0;
  width: 16px;
`;
