/* @flow */
import type { AppState } from "../reducers/root.js";

export function getResource(
  state: AppState,
  type: string,
  id: ?string,
  include: ?(string[])
): mixed {
  if (!id) {
    return null;
  }

  const resource = state[type].data.find(r => r.id === id);
  if (!resource) {
    return null;
  }
  let includedRelationships = {};
  if (include) {
    includedRelationships = Object.keys(resource.relationships)
      .filter(r => {
        if (!include) {
          return false;
        }
        return include.some(i => r === i);
      })
      .reduce((rel, k) => ({ ...rel, [k]: resource.relationships[k] }), {});
  }
  return {
    ...resource.attributes,
    id: resource.id,
    ...getRelationships(state, includedRelationships)
  };
}

function getRelationships(state: AppState, relationships: Object) {
  return Object.keys(relationships).reduce((result, k) => {
    const related = relationships[k].data;
    if (Array.isArray(related)) {
      return {
        ...result,
        [k]: related.map(r => getResource(state, r.type, r.id))
      };
    } else if (related instanceof Object) {
      return { ...result, [k]: getResource(state, related.type, related.id) };
    }
    throw new Error(`Relationship '${k}' must be an object or an array`);
  }, {});
}

export function getResourceCollection(state: AppState, type: string) {
  return state[type].data.map(r => ({
    ...r.attributes,
    id: r.id,
    ...(r.relationships ? getRelationships(state, r.relationships) : {})
  }));
}
