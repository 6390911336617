import styled from "styled-components";

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 26px;

  > span {
    margin-left: 4px;
  }
`;
