/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation skipUpcomingPost(
    $contentId: ID!
    $accountId: ID!
    $categoryId: ID!
  ) {
    skipUpcomingPost(
      contentId: $contentId
      accountId: $accountId
      categoryId: $categoryId
    )
  }
`;
