/* @flow */
import React from "react";
import menu from "../../images/menu.png";

import styles from "./index.css";

export const Menu = () => (
  <div className={styles.root}>
    <img src={menu} />
  </div>
);

export default Menu;
