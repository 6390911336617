import styled from "styled-components";

export const Wrapper = styled.div`
  border-bottom: 1px solid #e3e3e3;
  max-width: 170px;
  padding: 12.5px 8px;

  * {
    white-space: normal;
  }

  h3 {
    font-size: 13px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
  }

  &.loading {
    cursor: wait;
    opacity: 0.6;
  }
`;

export const Button = styled.div`
  color: var(--primaryTextColor);
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  margin: 10px 0 0;

  svg {
    margin: 2.5px 0 0 2px;
    position: absolute;
    stroke: var(--primaryTextColor);
  }
`;
