/* @flow */
import React from "react";
import H3 from "components/common/typography/H3";
import Clear from "./Clear";
import styles from "./index.css";

const Header = () => (
  <div className={styles.root}>
    <H3 className={styles.title}>Filters</H3>
    <Clear />
  </div>
);

export default Header;
