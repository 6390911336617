/* @flow */
import React, { type Node } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import type { AppState } from "types";
import TopNav, { type Props as TopNavProps } from "components/TopNav";
import SubscriptionStatus, {
  type Props as SubscriptionStatusProps
} from "components/SubscriptionStatus";
import {
  type OnboardingProgress,
  buildOnboardingStatus
} from "components/OnboardingBar/util.js";
import { OnboardingBar } from "components/OnboardingBar";
import Footer from "components/Footer";
import NewUISwitch from "components/NewUISwitch";
import { featureFlag } from "util";

import useApp from "./use";
import styles from "./index.css";

type LayoutType = "default" | "widget" | "signup";

type Props = {
  topNav: TopNavProps,
  subscriptionStatus: SubscriptionStatusProps,
  onboardingProgress?: OnboardingProgress,
  onDismissOnboarding?: () => void,
  children?: Node,
  header?: Node,
  messages?: Node,
  sidebar?: Node,
  loggedIn: boolean,
  admin: boolean,
  isNewHeader?: boolean,
  layout: LayoutType,
  newWidth?: boolean,
  navbarCollapsed: boolean,
  hideSidebar?: boolean
};

const App = ({
  children,
  topNav,
  header,
  sidebar,
  subscriptionStatus,
  onboardingProgress,
  messages,
  loggedIn,
  admin,
  onDismissOnboarding,
  isNewHeader = false,
  layout = "default",
  newWidth = false,
  navbarCollapsed,
  hideSidebar
}: Props) => {
  const { useNewUI } = useApp();
  const isFromShare = window.location.href.includes("share=true");

  return (
    <div
      className={classNames(styles.root, {
        [styles.widgetRoot]: layout === "widget",
        [styles.signupRoot]: layout === "signup",
        [styles.newRootUI]: !isFromShare && useNewUI && hideSidebar !== true,
        [styles.newRootUICollapsedSchedule]:
          !isFromShare &&
          useNewUI &&
          topNav.activePage &&
          topNav.activePage.includes("schedule") &&
          hideSidebar !== true,
        [styles.newRootUICollapsed]:
          !isFromShare && useNewUI && navbarCollapsed && hideSidebar !== true
      })}
      id="app"
    >
      {layout !== "widget" && (
        <>
          <TopNav {...topNav} useNewUI={useNewUI} hideSidebar={hideSidebar} />
          <SubscriptionStatus {...subscriptionStatus} />
          {!useNewUI && featureFlag("navbar_modules_ui") ? (
            <NewUISwitch toShow="new" />
          ) : null}
          {onboardingProgress && (
            <OnboardingBar
              onDismiss={onDismissOnboarding}
              onboardingStatus={buildOnboardingStatus(
                onboardingProgress,
                window.location.pathname
              )}
            />
          )}
          {isNewHeader && (
            <div className={styles.wrapper}>
              <div className={styles.container}>
                {!!messages || !useNewUI ? (
                  <div className={styles.messages2}>{messages}</div>
                ) : null}
                {header}
              </div>
            </div>
          )}
        </>
      )}
      <div
        className={classNames({
          [styles.container]: layout === "default",
          [styles.widgetContainer]: layout === "widget",
          [styles.signupContainer]: layout === "signup",
          [styles.newWidth]: newWidth === true
        })}
      >
        {!isNewHeader && layout !== "signup" ? (
          <div className={styles.header}>
            {!!messages || !useNewUI ? (
              <div className={styles.messages}>{messages}</div>
            ) : null}
            {header}
          </div>
        ) : null}
        <div
          className={classNames(styles.body, {
            [styles.signupBody]: layout === "signup"
          })}
        >
          {sidebar ? <div className={styles.sidebar}>{sidebar}</div> : null}
          <div className={styles.main}>{children}</div>
        </div>
      </div>
      <Footer
        loggedIn={loggedIn}
        admin={admin}
        className={layout === "signup" && styles.signupFooter}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  navbarCollapsed: state.layout?.data.navbarCollapsed
});

export default connect(mapStateToProps)(App);
