/* @flow */
/* eslint-disable flowtype/require-valid-file-annotation */
import React from "react";
import classNames from "classnames";
import tooltipper from "behavioral/tooltipper";
import AccountProviderIcon from "../AccountProviderIcon";
import AccountAvatar from "images/AccountAvatar";
import Caption from "typography/Caption";
import styles from "./index.css";

import type { Account } from "types";

type Props = {
  value: Account,
  tooltipPostfix?: string,
  className?: string
};

export const AccountBadge = ({
  value: { image, provider },
  className
}: Props) => {
  const rootClasses = classNames(styles.root, className);

  return (
    <div className={rootClasses}>
      <AccountAvatar className={styles.avatar} src={image} alt={provider} />
      <AccountProviderIcon
        provider={provider}
        className={styles.platformIcon}
      />
    </div>
  );
};

export const TipBody = ({ value, tooltipPostfix }: Props) => {
  if (!value) {
    return null;
  }
  const { name, nickname = "", provider = "", providerDisplayName } = value;
  const providerType = provider.toUpperCase();
  return (
    <div className={styles.tipBody}>
      <Caption className={styles.tipCaption}>
        {name && name.length > 0 ? name : `@${nickname}`}
      </Caption>
      <Caption className={styles.tipCaption}>
        {providerType === "TWITTER" || providerType === "PINTEREST"
          ? `@${nickname}`
          : providerDisplayName}
      </Caption>
      <Caption className={styles.tipCaption}>{tooltipPostfix}</Caption>
    </div>
  );
};

export default tooltipper(TipBody)(AccountBadge);
