/* @flow */
import React from "react";
import sortBy from "lodash/sortBy";

import Select from "../common/Select";
import AccountBadge from "icons/AccountBadge";

import type { loadFilters_accounts as AccountData } from "graphql-types/loadFilters";

type Props = {
  loading: boolean,
  showNoAccount: boolean,
  accounts: AccountData[]
};

export const Account = ({ loading, accounts, showNoAccount }: Props) => {
  let options = sortBy(accounts, ["provider", "name"]).map(a => ({
    value: a.id,
    label: a.displayName,
    icon: <AccountBadge value={a} tooltipDisabled />
  }));

  if (showNoAccount && accounts.length > 0) {
    options = [{ label: "No account", value: "-1" }].concat(options);
  }

  return (
    <Select
      label="Account"
      filterKey="account"
      options={options}
      loading={loading}
    />
  );
};

export default Account;
