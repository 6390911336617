import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    border-right: 1px solid #c5c5c5;
    flex-basis: 320px;
    min-width: 320px;
    overflow-y: auto;
  }
`;

export const Loading = styled.div`
  padding: 10px 20px;
`;

export const LoadMore = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15px 0;

  div {
    align-items: center;
    background-color: var(--kelp400);
    border-radius: 4px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px 14px;
    width: auto;

    &:hover {
      opacity: 0.9;
    }

    img {
      display: none;
      margin: 0.5px 0 0 3px;
      width: 20px;
    }

    &.loading {
      background-color: #f2f2f7;
      color: #999;
      cursor: not-allowed;
      font-style: italic;

      img {
        display: inline-block;
      }
    }
  }
`;
