// @flow
import React, { Component } from "react";

import SVGCalendar from "./calendar.svg";
import styles from "./index.css";

type Props = {
  value?: Object,
  placeholderText?: string,
  disabled?: boolean,
  onChange?: () => void,
  onKeyDown?: () => void,
  onFocus?: () => void,
  onBlur?: () => void
};

export default class CustomInput extends Component<Props> {
  input: ?HTMLInputElement = null;

  handleIconClick = () => {
    // Handle click / focus races
    setTimeout(this.focus, 0);
  };

  focus = () => {
    if (this.input) {
      this.input.focus();
    }
  };

  blur = () => {
    if (this.input) {
      this.input.blur();
    }
  };

  render() {
    const {
      value,
      placeholderText,
      disabled,
      onFocus,
      onBlur,
      onChange,
      onKeyDown
    } = this.props;

    return (
      <div
        className={`${styles.root} ${disabled ? styles.disabled : ""}`}
        data-disabled={disabled}
      >
        <img
          src={SVGCalendar}
          alt="calendar"
          className={styles.calendar}
          onClick={this.handleIconClick}
        />
        <input
          type="text"
          disabled={disabled}
          ref={input => (this.input = input)}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onChange={onChange}
          value={value}
          placeholder={placeholderText}
        />
      </div>
    );
  }
}
