/* @flow */
import React from "react";
import get from "lodash/get";

import { DAYS } from "constants";
import Timeslots from "../Timeslots";

import styles from "./index.css";
import { buildMobileCalendarData } from "../util.js";
import { type Props } from "../index.js";

const MobileCalendar = ({
  schedules,
  focusedTimeslotIds,
  onClickNewTimeslot,
  onClickEditTimeslot
}: Props) => {
  const data = buildMobileCalendarData(schedules);
  return (
    <ol className={styles.root}>
      {DAYS.map(day => (
        <li key={day} onClick={onClickNewTimeslot.bind(null, day, 0, 0)}>
          <div className={styles.dayHeader}>{day}</div>
          <ol>
            {Object.keys(get(data, [day], {})).map(hour =>
              Object.keys(get(data, [day, hour], {})).map(minute => (
                <li key={`${hour}:${minute}`}>
                  <Timeslots
                    schedules={get(data, [day, hour, minute], [])}
                    hour={hour}
                    minute={minute}
                    focusedTimeslotIds={focusedTimeslotIds}
                    onClickTimeslot={onClickEditTimeslot}
                  />
                </li>
              ))
            )}
          </ol>
        </li>
      ))}
    </ol>
  );
};

export default MobileCalendar;
