// @flow
import React from "react";
import { Icon } from "icons";

import styles from "./index.css";

import type { Account } from "types";

type Props = {
  value: Account
};

const ByLine = ({ value: { image, name } }: Props) => (
  <div>
    <img className={styles.image} src={image} alt="avatar" />
    <div className={styles.byLine}>
      <strong className={styles.name}>
        <a>{name}</a>
      </strong>{" "}
      <div className={styles.nickname}>Your Headline</div>
      <div>
        <span className={styles.time}>
          <a>now</a>
        </span>{" "}
        <Icon type="globe" className={styles.icon} />
      </div>
    </div>
  </div>
);

export default ByLine;
