/* @flow */
import React from "react";
import moment from "moment-timezone";
import qs from "qs";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import get from "lodash/get";
import GET_CONTENT_PERFORMANCE from "queries/getContentPerformance";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import { Button } from "buttons";
import { Link } from "links";
import { Alert } from "layout";
import Card from "layout/Card";
import ContentHistory from "./ContentHistory";
import Analytics from "components/Analytics";
import FullContent from "components/common/content/FullContent";
import PageTitle from "links/PageTitle";
import type { Location, RouterHistory } from "react-router-dom";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import App from "components/App";
import styles from "./index.css";

import type {
  getContentPerformance_content as ContentData,
  getContentPerformance_user_onboardingProgress as OnboardingProgressData
} from "graphql-types/getContentPerformance";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";

const PER_PAGE = 25;

type OwnProps = {
  topNav: TopNavProps,
  subscriptionStatus: SubscriptionStatusProps,
  location: Location,
  history: RouterHistory
};

type Props = {
  content?: ?ContentData,
  timezone?: ?string,
  error?: ?string,
  page?: number,
  pageCount?: number,
  onPageChange?: number => void,
  onboardingProgress?: OnboardingProgressData,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>
} & OwnProps;

export const ContentPerformance = ({
  subscriptionStatus,
  onboardingProgress,
  topNav,
  content,
  timezone,
  error,
  page,
  pageCount,
  onPageChange,
  dismissOnboarding
}: Props) => {
  const id = get(content, "id");
  const createdAt = get(content, "createdAt");
  const localTime =
    createdAt && timezone ? moment.tz(createdAt, timezone) : null;
  const totalPostCount = get(content, "totalPostCount");
  const variations = get(content, "variations", []);
  const posts = get(content, "posts");
  const shortLinks = variations.flatMap(v => v.shortLinks);
  return (
    <App
      loggedIn={true}
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      onboardingProgress={onboardingProgress}
      onDismissOnboarding={dismissOnboarding}
      header={
        <>
          <PageTitle>Content Performance</PageTitle>
          <Button type="primary" href={`/composer/${id}`}>
            Edit
          </Button>
        </>
      }
    >
      {error ? (
        <Alert className={styles.error} type="error">
          <div className={styles.errorBody}>
            <span className={styles.errorMessage}>{error}</span>
            <Link className={styles.retryLink}>Retry</Link>
          </div>
        </Alert>
      ) : (
        <>
          <div className={styles.addedAt}>
            {localTime ? (
              `Added: ${localTime.format(
                "ddd, MMM Do YYYY"
              )} at ${localTime.format("h:mma")}`
            ) : (
              <SkeletonTheme
                color="var(--inky100)"
                highlightColor="var(--inky50)"
              >
                <Skeleton width={200} />
              </SkeletonTheme>
            )}
          </div>
          <Card>
            <FullContent
              content={content}
              hideButtons={true}
              timezone={timezone}
            />
          </Card>
          <Analytics shortLinks={shortLinks || []} variations={variations} />
          <ContentHistory
            variations={variations}
            timezone={timezone}
            posts={posts}
            totalPostCount={totalPostCount}
            page={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
          />
        </>
      )}
    </App>
  );
};

export default (props: OwnProps) => {
  const pathElements = props.location.pathname.split("/");
  const contentId = pathElements[pathElements.length - 1];
  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const page = params.page || 1;

  // Queries
  const { loading, error, data, refetch } = useQuery(GET_CONTENT_PERFORMANCE, {
    variables: {
      id: contentId,
      postsPage: { offset: (page - 1) * PER_PAGE, limit: PER_PAGE }
    },
    notifyOnNetworkStatusChange: true
  });

  // Mutations
  const [
    dismissOnboarding,
    { loading: _dismissingOnboarding, error: _dismissOnboardingError }
  ] = useMutation(DISMISS_ONBOARDING);

  if (loading) {
    return <ContentPerformance {...props} />;
  }

  if (error) {
    console.error(error);
    return (
      <ContentPerformance
        {...props}
        error={`Failed to load content: ${error}`}
      />
    );
  }

  const handlePageChange = pageNum => {
    const nextPage = pageNum;
    const offset = pageNum * PER_PAGE;
    const limit = PER_PAGE;
    props.history.push(
      `${props.location.pathname}${qs.stringify(
        { ...params, page: nextPage },
        { addQueryPrefix: true }
      )}`
    );
    refetch({
      id: contentId,
      postsPage: { offset, limit }
    });
  };

  return (
    <ContentPerformance
      {...props}
      onboardingProgress={get(data, "user.onboardingProgress")}
      dismissOnboarding={dismissOnboarding}
      page={page}
      pageCount={get(data, "content.totalPostCount", 0) / PER_PAGE}
      content={get(data, "content")}
      timezone={get(data, "user.company.timeZone")}
      onPageChange={handlePageChange}
    />
  );
};
