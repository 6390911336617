/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getApiKey {
    user {
      id
      apiKey
    }
  }
`;
