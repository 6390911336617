// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type BubbleType = "new" | "count" | "error" | "recommended" | "comingSoon";

type Props = {
  children?: Node,
  type?: BubbleType,
  className?: string
};

const TextBubble = (props: Props) => {
  const { children, type = "new", className } = props;

  const classes = classNames(styles.root, className, {
    [styles.new]: type === "new",
    [styles.count]: type === "count",
    [styles.error]: type === "error",
    [styles.recommended]: type === "recommended",
    [styles.comingSoon]: type === "comingSoon"
  });

  return <div className={classes}>{children}</div>;
};

export default TextBubble;
