/* @flow */
import React from "react";

import { getMomentInTimeZone } from "util";

import type { getPosts_posts_data } from "graphql-types/getPosts";

type Props = {
  className?: string,
  post: getPosts_posts_data,
  timezone?: string
};

const TIME_FORMAT = "ddd, MMM D Y [at] h:mmA";

const SentAt = ({ className: _className, post, timezone }: Props) => {
  return (
    <>
      {timezone &&
        getMomentInTimeZone(post.sentAt || post.sendAt, timezone).format(
          TIME_FORMAT
        )}{" "}
      {post.url && post.videoStatus === "processed" && (
        <a href={post.url} target="_blank">
          <i className="fa fa-external-link" />
        </a>
      )}
    </>
  );
};

export default SentAt;
