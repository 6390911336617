/* @flow */
import React from "react";
import moment from "moment-timezone";
import sortBy from "lodash/sortBy";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import { colorFromIndex } from "colors";
import TooltippedIcon from "components/common/icons/TooltippedIcon";
import H4 from "components/common/typography/H4";
import DateLabelTick from "./DateLabelTick";

import styles from "./index.css";

import type { getContentPerformance_content_variations_shortLinks as ShortLinkData } from "graphql-types/getContentPerformance.js";

const RESPONSIVE_CONTAINER_HEIGHT = 300;
const LINE_CHART_MARGIN = { top: 20, right: 30, left: -20, bottom: 0 };
const LABEL_FORMAT = "M/D";
const X_AXIS_HEIGHT = 60;
const LINE_STROKE_WIDTH = 2;

const labelFormatter = value =>
  moment(value)
    .tz("UTC")
    .format(LABEL_FORMAT);

function chartData(shortLinks) {
  return sortBy(
    Object.values(
      shortLinks.reduce(
        (acc, { id: shortLinkId, clickStats }) =>
          clickStats.reduce(
            (result, { day, totalClicks }) => ({
              ...result,
              [day]: { ...result[day], [shortLinkId]: totalClicks, name: day }
            }),
            acc
          ),
        {}
      )
    ),
    "name"
  );
}

const HELP_LINK =
  "https://help.meetedgar.com/edgar-integrations/shorten-links-and-track-clicks-with-edgar/" +
  "shorten-urls-with-edgr-rebrandly-or-bitlycom";

const TOOLTIP_BODY = (
  <div>
    See click data when you shorten with ed.gr or a personalized Rebrandly link.
    It may take up to 24 hours for clicks to be reflected below.{" "}
    <a target="_blank" href={HELP_LINK}>
      Learn more
    </a>
    .
  </div>
);

type LabeledShortLink = ShortLinkData & { label: string };

type Props = {
  shortLinks: LabeledShortLink[]
};

export const TotalClicksChart = ({ shortLinks }: Props) => {
  return (
    <div className={styles.chart}>
      <H4>
        Total clicks per link <TooltippedIcon tipBody={TOOLTIP_BODY} />
      </H4>

      <ResponsiveContainer height={RESPONSIVE_CONTAINER_HEIGHT}>
        <LineChart margin={LINE_CHART_MARGIN} data={chartData(shortLinks)}>
          <XAxis
            dataKey="name"
            height={X_AXIS_HEIGHT}
            tick={<DateLabelTick labelFormatter={labelFormatter} />}
            interval={0}
          />
          <YAxis tick={{ fontSize: "11px" }} />
          <Legend />
          <Tooltip labelFormatter={labelFormatter} />

          {shortLinks.map((shortLink, i) => (
            <Line
              key={shortLink.id}
              name={shortLink.label}
              dataKey={shortLink.id}
              stroke={colorFromIndex(i)}
              dot={{
                stroke: colorFromIndex(i),
                strokeWidth: LINE_STROKE_WIDTH
              }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TotalClicksChart;
