import { useState } from "react";
import _ from "lodash";

import type { IProps } from "./types";

const useSendAt = (props: IProps, scroller: any) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const minutes = ["00", "15", "30", "45"];

  const showDropdown = () => {
    const { value } = props;
    setDropdownVisible(true);

    const itemHeight = 32;
    const hour = value.split(":")[0];
    const minute = value.split(":")[1].split(" ")[0];
    const period = value.split(" ")[1];

    const minutesHeight =
      minute >= 45
        ? itemHeight * 3
        : minute >= 30
        ? itemHeight * 2
        : minute >= 15
        ? itemHeight
        : 0;

    const offset =
      (period === "pm" ? hour * 2 : hour) * (hour > 0 ? 4 : 1) * itemHeight +
      minutesHeight;

    scroller.scrollTo("mySendAtScrollToElement", {
      duration: 0,
      delay: 0,
      smooth: true,
      containerId: "sendAtDropdown",
      offset: offset
    });
  };

  const hideDropdown = () => setDropdownVisible(false);

  const handleChange = newValue => {
    props.onChange(newValue);
  };

  return {
    dropdownVisible,
    showDropdown,
    hideDropdown,
    handleChange,
    minutes
  };
};

export default useSendAt;
