import styled from "styled-components";

export const Wrapper = styled.div``;

export const Error = styled.div`
  color: var(--dangerColor);
  font-size: 13px;
  font-style: italic;
  margin: -10px 0 20px 0;
`;
