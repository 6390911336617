import faker from "faker";
import { gql } from "apollo-boost";

const typeDefs = gql`
  extend type Variation {
    pinterestDestinationLink: String
    pinterestTitle: String
    images: [Images]
    videos: [Video]
    link: String
    pageScrape: PageScrape
  }
`;

export const clientState = {
  resolvers: {},
  typeDefs: {}
};

const randImg = () => {
  return faker.random.arrayElement([
    "https://edgar-images-staging.s3.us-west-2.amazonaws.com/users/1/f30f85d4fd9e982025f8-edgar.scraping-screenshot-05.jpg",
    "https://edgar-images-staging.s3.us-west-2.amazonaws.com/users/1/c676e8d5b55415b8ccd4-edgar.scraping-cover-photo.png",
    "https://edgar-images-staging.s3.us-west-2.amazonaws.com/users/1/030a094b0825ccfaa5a0-edgar.scraping-screenshot-03.jpg",
    "https://edgar-images-staging.s3.us-west-2.amazonaws.com/users/1/2678cd01309e4e589f35-mindblown.gif"
  ]);
};

const story_172360543_pinterest_variations = () => {
  clientState.typeDefs = typeDefs;

  clientState.resolvers.Variation = {
    pinterestDestinationLink: () => faker.internet.url(),
    pinterestTitle: () => faker.hacker.phrase(),
    images: () => {
      return [
        {
          fileSize: 44253,
          id: "i2u3iru2iu3iru2",
          url: randImg(),
          __typename: "Image"
        },
        {
          fileSize: 44253,
          id: "28372878378273",
          url: randImg(),
          __typename: "Image"
        }
      ];
    },
    link: () => {
      return null;
    },
    pageScrape: () => {
      return {
        title: "Greatest Pianists",
        url: "https://en.wikipedia.org/wiki/Vladimir_Horowitz",
        images: [
          {
            url: randImg()
          }
        ]
      };
    },
    videos: () => {
      return [];
    }
  };

  clientState.resolvers.Content = {
    // mock a state where no accounts are associated with a piece of content
    //accountRelationships: () => []
  };

  clientState.resolvers.Account = {
    // mock a specific platform for all account objects
    platform: () => {
      return "PINTEREST";
    }
  };
};

const story_172360543_pinterest_variations_video = () => {
  clientState.typeDefs = typeDefs;

  clientState.resolvers.Variation = {
    pinterestDestinationLink: () => faker.internet.url(),
    pinterestTitle: () => faker.hacker.phrase(),
    images: () => {
      return null;
    },
    videos: () => {
      return [
        {
          url:
            "https://edgar-images-staging.s3.us-west-2.amazonaws.com/users/1/896d12f548f763e8a962-demo.mp4",
          __typename: "Video"
        }
      ];
    },
    link: () => {
      return null;
    },
    pageScrape: () => {
      return null;
    }
  };
};

const story_172360543_link_variations = () => {
  clientState.typeDefs = typeDefs;

  clientState.resolvers.Variation = {
    pinterestDestinationLink: () => {
      return null;
    },
    pinterestTitle: () => {
      return null;
    },
    images: () => {
      return null;
    },
    videos: () => {
      return [];
    },
    link: () => "https://www.esm.rochester.edu/sibley/about/faq/",
    pageScrape: () => {
      return {
        title: faker.company.catchPhrase(),
        description: faker.hacker.phrase(),
        url: "https://www.sfsymphony.org",
        //url: null,
        id: 178728781,
        name: "mock page scrape name",
        images: [
          {
            id: "wuiweufiuiuwef",
            src: randImg(),
            __typename: "Image"
          }
        ],
        __typename: "PageScrape"
      };
    }
  };
};

export const clientIdx = () => {
  return {
    story_172360543_pinterest_variations: story_172360543_pinterest_variations,
    story_172360543_link_variations: story_172360543_link_variations,
    story_172360543_pinterest_variations_video: story_172360543_pinterest_variations_video
  };
};
