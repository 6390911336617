/* @flow */
import { gql } from "apollo-boost";

export default gql`
  subscription newMessageReceived($recipientId: String!) {
    newMessageReceived(recipientId: $recipientId) {
      message {
        attachments {
          mimeType
          previewUrl
        }
        createdTime
        from
        message
        to
      }
    }
  }
`;
