/* @flow */
import React from "react";
import { zipObject } from "lodash";

import DropDown from "layout/DropDown";
import { DAYS, SHORT_DAYS } from "constants";

import { Info } from "../RepeatDay/styles";

import SVGIcon from "./icon.svg";
import type { IProps } from "./types.js";
import useRepeat from "./use";

import {
  Wrapper,
  LabelIcon,
  DropDownWrapper,
  DropDownContent,
  Item,
  Days,
  Day,
  DaysError
} from "../SendAt/styles";
import { Value } from "../SendOn/styles";

const SHORT_TO_LONG_DAYS = zipObject(SHORT_DAYS, DAYS);

const ScheduleRepeat = (props: IProps) => {
  const { recurring, setRecurring, selectedDays, onChangeDay, errors } = props;
  const { dropdownVisible, showDropdown, hideDropdown } = useRepeat(props);

  return (
    <>
      <Wrapper>
        <LabelIcon>
          <img src={SVGIcon} alt="repeat icon" />
        </LabelIcon>
        <Value onClick={showDropdown}>
          {recurring ? "Repeats weekly" : "Does not repeat"}
        </Value>

        <DropDownWrapper>
          <DropDown
            open={dropdownVisible}
            onClose={hideDropdown}
            omitDefaultStyle
          >
            <DropDownContent id="sendAtDropdown">
              <Item onClick={() => setRecurring(false)} active={!recurring}>
                Does not repeat
              </Item>
              <Item onClick={() => setRecurring(true)} active={recurring}>
                Repeats weekly
              </Item>
            </DropDownContent>
          </DropDown>
        </DropDownWrapper>
      </Wrapper>

      {recurring && (
        <Days>
          {SHORT_DAYS.map(d => (
            <Day
              key={d}
              className={
                selectedDays.has(SHORT_TO_LONG_DAYS[d]) ? "active" : ""
              }
              onClick={() => {
                onChangeDay(
                  SHORT_TO_LONG_DAYS[d],
                  !selectedDays.has(SHORT_TO_LONG_DAYS[d])
                );
              }}
            >
              {d.substring(0, 1)}
            </Day>
          ))}
        </Days>
      )}

      {recurring && <Info>Days selected will be post dated from Send on.</Info>}

      {errors.sendOn && <DaysError>{errors.sendOn}</DaysError>}
    </>
  );
};

export default ScheduleRepeat;
