/* @flow */
import type { getOnboardingProgress_user_onboardingProgress as OnboardingProgressData } from "graphql-types/getOnboardingProgress";

function hasNoOnboardingProgress({ id }) {
  return !id;
}

function hasDismissedOnboarding({ dismissedAt }) {
  return !!dismissedAt;
}

function hasCompletedAllSteps({
  addAccount,
  viewCategories,
  addContent,
  addTimeslots
}) {
  return !!addAccount && !!viewCategories && !!addContent && !!addTimeslots;
}

export function onboardingLinkState(progress: OnboardingProgressData) {
  if (hasNoOnboardingProgress(progress)) {
    return { visible: true, canResume: false };
  }

  if (hasDismissedOnboarding(progress)) {
    return { visible: true, canResume: true };
  }

  if (hasCompletedAllSteps(progress)) {
    return { visible: true, canResume: false };
  }

  return { visible: false, canResume: false };
}
