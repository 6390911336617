/* @flow */
import React from "react";

import Modal from "layout/modals/Modal";
import Icon from "icons/Icon";
import Label from "typography/Label";
import Button from "buttons/Button";

import styles from "./index.css";

import type {
  bulkContentUpdate as BulkContentUpdateResult,
  bulkContentUpdateVariables,
  ContentsFilter
} from "graphql-types/bulkContentUpdate";

type Props = {
  bulkContentUpdate?: ({
    variables: bulkContentUpdateVariables
  }) => Promise<{ data: BulkContentUpdateResult }>,
  close: () => void,
  failUpdate: () => void,
  filter: ContentsFilter,
  initUpdate: () => void,
  isSelectAll: boolean,
  selected: string[],
  startUpdate: () => void,
  totalSelected: number,
  unselected: string[],
  updateState: string
};

const Delete = (props: Props) => {
  return (
    <Modal onClose={props.close} size="large">
      <div className={styles.main}>
        <Icon color="warning" size="2x" type="exclamation-triangle" />
        <div className={styles.message}>
          <Label>
            You are about to delete {props.totalSelected}{" "}
            {props.totalSelected === 1 ? "post" : "posts"} permanently.
          </Label>
          <p>
            You cannot undo this action. Are you sure you want to delete these
            posts?
          </p>
        </div>
        <Button
          className={styles.buttonCancel}
          disabled={props.updateState !== "None"}
          onClick={props.close}
          type="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={props.updateState !== "None"}
          onClick={async (): Promise<void> => {
            const bulkContentUpdate = props.bulkContentUpdate;
            if (!bulkContentUpdate) {
              return;
            }

            try {
              props.initUpdate();

              const resp = await bulkContentUpdate({
                variables: {
                  input: {
                    action: "_destroy",
                    filter: props.filter,
                    isSelectAll: props.isSelectAll,
                    selected: props.selected,
                    unselected: props.unselected
                  }
                }
              });

              const errors = resp?.data?.bulkContentUpdate?.errors || [];
              if (errors.length > 0) {
                props.failUpdate();
              } else {
                props.startUpdate();
              }
            } catch (_err) {
              props.failUpdate();
            }
          }}
          type="primary-negative"
        >
          Yes, delete
        </Button>
      </div>
    </Modal>
  );
};

export default Delete;
