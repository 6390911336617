/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getDashboard {
    user {
      id
      email
      createdAt
      hideOldViewButton
      dismissedInformationBoxes
      cardData
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
  }
`;
