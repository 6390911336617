/* @flow */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { get } from "lodash";

import LoadingIcon from "icons/LoadingIcon";
import App from "components/App";
import { useNewUICached, featureFlag } from "util";
import PageTitle from "links/PageTitle";
import NewHeader from "components/NewHeader";

import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import GET_ONBOARDING_PROGRESS from "queries/getOnboardingProgress";

import ActivityOverview from "./ActivityOverview";
import NewPageLikes from "./NewPageLikes";
import Breakdowns from "./Breakdowns";

import type { IProps, OwnProps } from "./types.js";
import useDashboard from "./use";
import { Loading } from "./styles";

const Dashboard = (props: IProps) => {
  const useNewUI = useNewUICached();
  const {
    filters,
    updateFilters,
    selectAccount,
    selectedAccount,
    minDate,
    maxDate
  } = useDashboard();

  if (!featureFlag("new_dashboard")) window.location.href = "/dashboard";

  return featureFlag("new_dashboard") ? (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      onboardingProgress={props.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      header={
        useNewUI ? (
          <NewHeader title="Dashboard" {...props.topNav} />
        ) : (
          <PageTitle>Dashboard</PageTitle>
        )
      }
      newWidth
    >
      <ActivityOverview
        filters={props.filters ? props.filters : filters}
        setFilters={updateFilters}
        selectAccount={selectAccount}
        selectedAccount={selectedAccount}
        minDate={minDate}
        maxDate={maxDate}
      />
      {!!selectedAccount && (
        <NewPageLikes selectedAccount={selectedAccount} filters={filters} />
      )}
      <Breakdowns
        filters={props.filters ? props.filters : filters}
        selectedAccount={selectedAccount}
      />
    </App>
  ) : (
    <Loading>
      <LoadingIcon />
    </Loading>
  );
};

const DashboardWrapper = (ownProps: OwnProps) => {
  const { data } = useQuery(GET_ONBOARDING_PROGRESS);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);

  const onboardingProgress = get(data, "user.onboardingProgress");

  return (
    <Dashboard
      {...ownProps}
      onboardingProgress={onboardingProgress}
      dismissOnboarding={dismissOnboarding}
    />
  );
};

export default DashboardWrapper;
