/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getUserDetails {
    teamMembers {
      id
      email
      role
      invitationStatus
    }
    user {
      id
      firstName
      lastName
      email
      phone
      sendPerformanceReport
      apiKey
      role
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
      devices {
        id
        name
      }
      company {
        id
        name
        queueStatus
        totalAccounts
        includeUtmParameters
        couponCode
        subscribed
        subscriptionEndsAt
        upgradeAvailable
        timeZone
        address1
        address2
        city
        region
        zip
        country
        shopifyOrigin
        additionalAccounts
        defaultLinkShortenerDomain {
          id
          service
          display
        }
        rebrandlyDomains {
          id
          type
          fullName
        }
        nextInvoice {
          due
          dueDate
          forcedDueDate
          coupon
        }
        lastInvoice {
          paid
          due
          dueDate
          forcedDueDate
          closed
        }
        paymentSource {
          type
          last4
          expDisplay
        }
        subscriptionPlan {
          id
          stripeId
          name
          accountLimit
          productName
          amount
          interval
          intervalCount
        }
        availableSubscriptionPlans {
          id
          stripeId
          name
          productName
          amount
          interval
          intervalCount
        }
      }
    }
  }
`;
