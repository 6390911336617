import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid #c5c5c5;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    height: 885px;
  }
`;

export const BetaText = styled.span`
  align-items: center;
  background: #e3f5f4;
  border-radius: 4px;
  border: 1px solid #2d968faa;
  color: #2d968f;
  display: flex;
  font-size: 12px;
  height: 22px;
  justify-content: center;
  margin: -37px 0 0 76px;
  opacity: 0.8;
  padding: 0 10px;
  position: absolute;
  text-transform: uppercase;
`;

export const RefreshAccount = styled.div`
  margin-bottom: 20px;
`;
