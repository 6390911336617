/* @flow */
import React from "react";

import SVGWarning from "./icons/warning.svg";
import SVGClose from "./icons/close.svg";

import useExpiredWarning from "./use";
import type { Props } from "./types.js";
import { Wrapper, Left, Warning, Text, Close } from "./styles";

const ExpiredWarning = (props: Props) => {
  const { status, hidePending, hideRejected, handleHide } = useExpiredWarning(
    props
  );

  return status === null ||
    (status === "pending" && hidePending) ||
    (status === "rejected" && hideRejected) ? null : (
    <Wrapper>
      <Left>
        <Warning>
          <img src={SVGWarning} alt="warning" />
        </Warning>
        <Text>
          {status === "pending" ? (
            <>
              Content that has been pending for more than 30 days will be moved
              into 'Rejected Content'.
            </>
          ) : (
            status === "rejected" && (
              <>
                Content that has been sitting in Rejected Content for more than
                30 days will be <b>permanently deleted</b>!
              </>
            )
          )}
        </Text>
      </Left>
      <Close onClick={() => handleHide(status)} title="Dismiss message">
        <img src={SVGClose} alt="close" />
      </Close>
    </Wrapper>
  );
};

export default ExpiredWarning;
