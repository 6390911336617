import styled from "styled-components";

export const Wrapper = styled.div`
  color: var(--fbTextDark);
  font-family: var(--fontMeta);
  width: 500px;
`;

// center the img inside Img wrapper
// and make it fit the wrapper
// max-height of 300px
// align image on center with display flex
export const Image = styled.div`
  align-items: center;
  background: #000;
  display: flex;
  justify-content: center;
  max-height: 300px;
  overflow: hidden;

  img {
    height: auto;
    max-height: 300px;
    width: auto;
  }
`;

export const Content = styled.div`
  padding: 12px;
`;
