/* @flow */
import React from "react";
import withSort from "behavioral/withSort";

import TooltippedIcon from "icons/TooltippedIcon";

import type { ContentSortableField, ContentSort } from "types";

import styles from "./index.css";

type ArrowProps = {
  field: ContentSortableField,
  sort: ContentSort
};

type TableHeaderProps = {
  className?: string,
  field: ContentSortableField,
  info?: string,
  onSortPatch: (changes: ContentSort) => void,
  sort: ContentSort,
  title: string
};

export const Arrow = ({ field, sort }: ArrowProps) => {
  if (field !== sort.field) {
    return null;
  }
  if (sort.direction === "asc") {
    return <i className={`fa fa-arrow-up ${styles.icon}`} />;
  }
  return <i className={`fa fa-arrow-down ${styles.icon}`} />;
};

export const TableHeader = ({
  className = "",
  field,
  info,
  onSortPatch,
  sort,
  title
}: TableHeaderProps) => {
  const clickHandler: EventHandler = e => {
    e.preventDefault();
    const changes: ContentSort = {
      direction:
        field === sort.field
          ? sort.direction === "asc"
            ? "desc"
            : "asc"
          : "desc",
      field
    };
    onSortPatch(changes);
  };
  return (
    <th className={`${styles.tableHeader} ${className}`}>
      <div className={styles.sortable}>
        <a className={styles.sortTrigger} href="#" onClick={clickHandler}>
          {title}
        </a>{" "}
        <Arrow field={field} sort={sort} />{" "}
        {info && (
          <div className={styles.icon}>
            <TooltippedIcon iconType="info-circle" tipBody={info} />
          </div>
        )}
      </div>
    </th>
  );
};

export default withSort()(TableHeader);
