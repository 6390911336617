// @flow
import React from "react";
import { connect } from "react-redux";
import selectAccounts from "selectors/accounts";
import styles from "./index.css";

import type { AppState, Connector, Account } from "types";

type OwnProps = { value: Account, hasLink: boolean };
type Props = OwnProps & { parentAccount: ?Account };

export const ByLine = ({
  value: { name, image, provider, parentImage },
  hasLink,
  parentAccount
}: Props) => {
  const showAttribution = provider !== "FACEBOOK PAGE";
  const showPostLocation = provider === "FACEBOOK GROUP";
  const parentName = parentAccount ? parentAccount.name : "";
  const showPostLocationAsArrow = !hasLink;
  let byLineImage = image;
  if (provider === "FACEBOOK GROUP" && !!parentImage) {
    byLineImage = parentImage;
  }

  return (
    <div className={styles.root}>
      <img className={styles.image} src={byLineImage} alt="Account's profile" />
      <div>
        <div className={styles.name}>
          {!showPostLocation && <a>{name}</a>}
          {showPostLocation && showPostLocationAsArrow && (
            <span>
              <a>{parentName}</a> <i className={styles.arrow}>►</i>{" "}
              <a>{name}</a>
            </span>
          )}
          {showPostLocation && !showPostLocationAsArrow && (
            <span>
              <a>{parentName}</a> shared a <a>link</a> to the group:{" "}
              <a>{name}</a>.
            </span>
          )}
        </div>
        <div className={styles.details}>
          <a>Just now</a>
          {showAttribution && (
            <span>
              {" "}
              · <a>MeetEdgar</a>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  { value: { parentId } }: OwnProps
) => ({
  parentAccount: selectAccounts(state).find(acct => acct.id === parentId)
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);

export default connector(ByLine);
