/* @flow */
import React, { type Node } from "react";
import Link from "links/Link";

const AutoLinkText = ({ children }: { children?: Node }) => {
  if (!children) {
    return null;
  }
  if (typeof children !== "string") {
    throw new TypeError("Child of AutoLinkText component must be a string");
  }
  const regex = /(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b[-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  const urls = children.match(regex) || [];
  const result = children.split(regex).map(c =>
    urls.includes(c) ? (
      <Link key={c} url={c} target="_blank">
        {c}
      </Link>
    ) : (
      c
    )
  );
  return <span>{result}</span>;
};
export default AutoLinkText;
