// @flow
import { createSelector, type OutputSelector } from "reselect";
import selectAsArray, { type Flash } from "../asArray";
import type { AppState } from "types";

export type { Flash };

const selectActive: OutputSelector<AppState, void, Flash[]> = createSelector(
  selectAsArray,
  (flashes: Flash[]): Flash[] => flashes.filter(it => it.dismissedAt === null)
);

export default selectActive;
