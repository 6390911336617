import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 100%;
  justify-content: space-between;
  padding: 8px 0 0;
  width: 100%;
  flex-wrap: wrap;

  &.marginBottom {
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  margin: 0 20px 0 0;
  position: relative;

  h1 {
    color: var(--inky500);
    font-size: var(--display2FontSize);
    font-weight: var(--semiBold);
    line-height: var(--maxLineHeight);
    margin: 0;
  }

  .helpLink {
    color: var(--inky300);
    font-size: var(--helpIconFontSize);
    margin: 0 4px 0 4px;

    &:hover {
      color: var(--kelp300);
      outline: none;
    }

    i {
      vertical-align: top;
    }
  }

  h4 {
    font-weight: 400;
    margin: 5px 0 0 8px;
  }
`;

export const BetaText = styled.span`
  align-items: center;
  background: #e3f5f4;
  border-radius: 4px;
  border: 1px solid #2d968faa;
  color: #2d968f;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  justify-content: center;
  margin: -8px 2px 0 5px;
  opacity: 0.8;
  padding: 0 10px;
  text-transform: uppercase;
`;

export const Right = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const AddContent = styled.div`
  margin: 10px 0 0 0;
  text-align: right;
  width: 100%;

  &.desktop {
    display: none;
    margin: 0;
  }

  @media (min-width: 768px) {
    display: none;
    width: auto;

    &.desktop {
      display: block;
    }
  }
`;

// export const PlusBtn = styled.a`
//   align-items: center;
//   background-color: rgba(77, 148, 143, 1);
//   border-radius: var(--elementBorderRadius);
//   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
//   color: #fff !important;
//   cursor: pointer;
//   display: flex;
//   height: 36px;
//   justify-content: center;
//   margin: 0 24px 0 0;
//   padding: 0 20px;
//   text-decoration: none;
//   transition: all 0.1s ease-in-out;

//   img {
//     margin-right: 7.5px;
//   }

//   span {
//     margin-top: -2px;
//   }

//   &:hover {
//     background-color: rgba(72, 143, 138, 1);
//     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
//   }
// `;

export const User = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 24px;
  width: 80px;
  .profile {
    border-radius: 50%;
    height: 50px;
    obeject-fit: cover;
    width: 50px;
  }

  .dropdown {
    margin-left: 6px;
  }

  .pictureLetter {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    display: flex;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    width: 50px;
  }
`;

export const DropdownWrapper = styled.div`
  margin: 50px 0 0 0;
  position: absolute;
  right: 0;
`;

export const Alert = styled.div`
  font-size: 20px;
  left: -2px;
  position: absolute;
  top: -10px;

  a {
    color: #a04250;

    &:hover {
      opacity: 0.7;
    }
  }
`;
