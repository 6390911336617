// @flow
import React, { type Node } from "react";
import { isNumber } from "lodash";
import classNames from "classnames";
import TextBubble from "icons/TextBubble";
import styles from "./index.css";

type Props = {
  label: string,
  index?: number,
  selected?: boolean,
  isNewFeature?: boolean,
  children: Node,
  onSelect?: (tabIndex: number) => void
};

export default class Tab extends React.Component<Props> {
  handleSelect = () => {
    const { index, onSelect } = this.props;
    if (
      !index === null ||
      typeof index === "undefined" ||
      !isNumber(index) ||
      !onSelect
    ) {
      throw new Error(
        "index or onSelect not provded. Ensure this a child of Tabs"
      );
    }
    onSelect(index);
  };

  render() {
    const { label, selected, isNewFeature } = this.props;

    return (
      <a
        onClick={this.handleSelect}
        className={classNames(styles.root, { [styles.selected]: selected })}
      >
        {label}
        {isNewFeature && <TextBubble className={styles.new}>New!</TextBubble>}
      </a>
    );
  }
}
