/* @flow */
import React from "react";
import { groupBy, map } from "lodash";
import moment from "moment-timezone";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import styles from "./index.css";
import H3 from "typography/H3";
import EmptyState from "./EmptyState";
import ResultCount from "components/common/ResultCount";
import Paginator from "components/Paginator";
import Post from "./Post";

import type { getUpcomingPosts_upcomingPosts_data as UpcomingPostData } from "graphql-types/getUpcomingPosts";

const LOADING_STATE_POST_COUNT = 25;

function groupPostsByDay(posts, timezone = "America/Los_Angeles") {
  return groupBy(posts, ({ sendAt }) =>
    sendAt ? moment.tz(sendAt, timezone).format("dddd, MMMM D") : "loading"
  );
}

type Props = {
  totalCount: number,
  page: number,
  pageCount: number,
  timezone: string,
  emptyReasons: string[],
  upcomingPosts?: UpcomingPostData[],
  onSkipUpcomingPost: (string, string, string) => void,
  onDeleteSchedule: (scheduleId: number) => void,
  onPageChange: number => void
};

const UpcomingPostList = ({
  onSkipUpcomingPost,
  onDeleteSchedule,
  upcomingPosts,
  emptyReasons,
  totalCount,
  timezone,
  page,
  onPageChange,
  pageCount
}: Props) => {
  if (upcomingPosts && upcomingPosts.length === 0) {
    return <EmptyState reasons={emptyReasons} />;
  }

  return (
    <>
      <List
        timezone={timezone}
        onSkipUpcomingPost={onSkipUpcomingPost}
        onDeleteSchedule={onDeleteSchedule}
        upcomingPosts={upcomingPosts}
        totalCount={totalCount}
      />
      <Paginator
        page={page}
        pageCount={pageCount}
        onPageChange={onPageChange}
      />
    </>
  );
};

type ListProps = {
  timezone: string,
  upcomingPosts?: UpcomingPostData[],
  onSkipUpcomingPost: (string, string, string) => void,
  onDeleteSchedule: (scheduleId: number) => void,
  totalCount: number
};

export const List = ({
  timezone,
  onSkipUpcomingPost,
  onDeleteSchedule,
  upcomingPosts,
  totalCount
}: ListProps) => (
  <div className={styles.root}>
    <ResultCount
      totalCount={totalCount}
      filteredCount={totalCount}
      className={styles.resultCount}
    />
    {upcomingPosts ? (
      map(groupPostsByDay(upcomingPosts, timezone), (postsForDay, day) => (
        <Day
          key={day}
          day={day}
          timezone={timezone}
          upcomingPosts={postsForDay}
          onSkipUpcomingPost={onSkipUpcomingPost}
          onDeleteSchedule={onDeleteSchedule}
        />
      ))
    ) : (
      <Day />
    )}
  </div>
);

type DayProps = {
  day?: string,
  timezone?: string,
  upcomingPosts?: UpcomingPostData[],
  onSkipUpcomingPost?: (string, string, string) => void,
  onDeleteSchedule?: (scheduleId: number) => void
};

const Day = ({
  day,
  upcomingPosts,
  timezone,
  onSkipUpcomingPost,
  onDeleteSchedule
}: DayProps) => (
  <>
    <H3 className={styles.day}>
      {!day ? (
        <SkeletonTheme color="var(--inky100)" highlightColor="var(--inky50)">
          <Skeleton width={250} />
        </SkeletonTheme>
      ) : (
        day
      )}
    </H3>
    {(upcomingPosts || Array.from(new Array(LOADING_STATE_POST_COUNT))).map(
      (p, idx) => (
        <Post
          key={idx}
          value={p}
          timezone={timezone}
          onSkipUpcomingPost={onSkipUpcomingPost}
          onDeleteSchedule={onDeleteSchedule}
        />
      )
    )}
  </>
);

export default UpcomingPostList;
