/* @flow */
import React, { type Node } from "react";
import { default as Base } from "typography/Label";
import classnames from "classnames";
import styles from "./index.css";

type Props = { children: Node, className?: string };

const Label = ({ children, className }: Props) => (
  <Base className={classnames(styles.root, className)}>{children}</Base>
);

export default Label;
