// @flow
import React from "react";
import ScaledImage from "components/common/ScaledImage";
import styles from "./index.css";

type Props = {
  urls: string[]
};

const PortraitFive = ({ urls }: Props) => (
  <div className={styles.container}>
    <div className={styles.primaryImage}>
      <ScaledImage value={urls[0]} targetWidth={235} targetHeight={236} />
    </div>
    <div className={styles.primaryImage}>
      <ScaledImage value={urls[1]} targetWidth={235} targetHeight={236} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[2]} targetWidth={235} targetHeight={156} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[3]} targetWidth={235} targetHeight={156} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[4]} targetWidth={235} targetHeight={156} />
      {urls.length > 5 && (
        <div data-test-overlay className={styles.overlay}>
          +{urls.length - 5 + 1}
        </div>
      )}
    </div>
  </div>
);

export default PortraitFive;
