/* @flow */
import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Label from "typography/Label";
import RadioButton from "forms/RadioButton2";

import styles from "./index.css";

import type {
  bulkContentUpdate as BulkContentUpdateResult,
  bulkContentUpdateVariables,
  ContentsFilter
} from "graphql-types/bulkContentUpdate";

type Props = {
  bulkContentUpdate?: ({
    variables: bulkContentUpdateVariables
  }) => Promise<{ data: BulkContentUpdateResult }>,
  close: () => void,
  failUpdate: () => void,
  filter: ContentsFilter,
  initUpdate: () => void,
  isSelectAll: boolean,
  selected: string[],
  startUpdate: () => void,
  unselected: string[],
  updateState: string
};

type State = "Evergreen" | "UseOnce";

const UseOnceEdit = (props: Props) => {
  const [useOnceSetting, setUseOnceSetting] = useState<State>("Evergreen");
  return (
    <Modal
      footer={
        <>
          <Button
            className={styles.buttonCancel}
            disabled={props.updateState !== "None"}
            onClick={props.close}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={
              (useOnceSetting !== "Evergreen" &&
                useOnceSetting !== "UseOnce") ||
              props.updateState !== "None"
            }
            onClick={async (): Promise<void> => {
              const bulkContentUpdate = props.bulkContentUpdate;
              if (!bulkContentUpdate) {
                return;
              }

              try {
                props.initUpdate();

                const resp = await bulkContentUpdate({
                  variables: {
                    input: {
                      action: "use_once",
                      useOnce: useOnceSetting === "UseOnce",
                      filter: props.filter,
                      isSelectAll: props.isSelectAll,
                      selected: props.selected,
                      unselected: props.unselected
                    }
                  }
                });

                const errors = resp?.data?.bulkContentUpdate?.errors || [];
                if (errors.length > 0) {
                  props.failUpdate();
                } else {
                  props.startUpdate();
                }
              } catch (_err) {
                props.failUpdate();
              }
            }}
            type="primary"
          >
            Apply
          </Button>
        </>
      }
      onClose={props.close}
      size="large"
      title="Edit use once setting"
    >
      <div className={styles.main}>
        <Label>Select option</Label>
        <small className={styles.sectionFieldDescription}>
          All selected content items will be updated.
        </small>
        <RadioButton
          className={styles.sectionFieldInput}
          onClick={setUseOnceSetting}
          selected={useOnceSetting === "Evergreen"}
          value="Evergreen"
        >
          Evergreen
        </RadioButton>
        <RadioButton
          className={styles.sectionFieldInput}
          onClick={setUseOnceSetting}
          selected={useOnceSetting === "UseOnce"}
          value="UseOnce"
        >
          Use once
        </RadioButton>
      </div>
    </Modal>
  );
};

export default UseOnceEdit;
