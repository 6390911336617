import styled from "styled-components";

export const Item = styled.div`
  margin: 15px 0 5px;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Left = styled.div`
  margin: 0 15px 0 0;
`;

export const Image = styled.div`
  position: relative;

  > img {
    border-radius: 50%;
    height: 35px !important;
    width: 35px !important;
  }
`;

export const ProviderWrapper = styled.div`
  bottom: -1px;
  position: absolute;
  right: -3px;

  img {
    border-radius: 2px;
    height: 15px;
    width: 15px;
  }
`;

export const Right = styled.div`
  flex: 1;
`;

export const Input = styled.div`
  border-radius: 6px;
  border: solid 1px #d0d7de;
  font-size: 15px;
  line-height: 24px;
  padding: 10px 15px;
`;

export const Remove = styled.div`
  margin: 5px 0 0 0;

  span {
    color: var(--dangerColor);
    cursor: pointer;

    i {
      margin: 0 4px 0 0;
    }

    &:hover > span {
      text-decoration: underline;
    }
  }
`;
