/* @flow */
import React from "react";
import Skeleton from "react-loading-skeleton";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";

import SVGArrowGreen from "../ActivityOverview/Boxes/icons/arrow-green.svg";
import SVGArrowRed from "../ActivityOverview/Boxes/icons/arrow-red.svg";

import { options } from "./utils";
import useNewPageLikes from "./use";
import type { Props } from "./types.js";
import { Container, SectionTitle } from "./styles";

import { Wrapper } from "../Breakdowns/Categories/styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const NewPageLikes = (props: Props) => {
  const { loading, graphData, netGrowth } = useNewPageLikes(props);

  return (
    <Wrapper>
      <Container>
        <SectionTitle>
          <h1>New page likes</h1>
          {!loading && netGrowth !== null ? (
            <span className={netGrowth >= 0 ? "green" : "red"}>
              {netGrowth >= 0 ? "+" : ""}
              {netGrowth} net page likes
              {netGrowth >= 0 ? (
                <img src={SVGArrowGreen} alt="arrow green" />
              ) : (
                <img src={SVGArrowRed} alt="arrow red" />
              )}
            </span>
          ) : null}
        </SectionTitle>

        {loading ? (
          <div className="no-data">
            <Skeleton height={28} />
            <Skeleton height={60} />
            <Skeleton height={28} />
          </div>
        ) : !!graphData ? (
          <div
            className="chart-container"
            style={{ position: "relative", height: 300 }}
          >
            <Line options={options} data={graphData} />
          </div>
        ) : (
          <div className="no-data">
            <p>No data available</p>
          </div>
        )}
      </Container>
    </Wrapper>
  );
};

export default connect(
  undefined,
  undefined
)(NewPageLikes);
