/* @flow */
import React, { type Node } from "react";
import { Button } from "buttons";

import type { HttpMethod } from "types";
import type { ConfirmType } from "layout/modals/Confirmation";

type Props = {
  method?: HttpMethod,
  disabled?: boolean,
  loading?: boolean,
  url?: string,
  onClick?: EventHandler,
  type?: string,
  confirm?: boolean,
  confirmType?: ConfirmType,
  confirmMessage?: string,
  style?: any,
  className?: string,
  children?: Node
};

const ActionBarButton = (props: Props) => {
  const {
    url,
    method,
    disabled,
    loading,
    onClick,
    type,
    style,
    className,
    children,
    confirm,
    confirmType,
    confirmMessage
  } = props;

  return (
    <Button
      small
      type={type}
      url={url}
      method={method}
      disabled={disabled}
      loading={loading}
      confirm={confirm}
      confirmType={confirmType}
      confirmMessage={confirmMessage}
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ActionBarButton;
