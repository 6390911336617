/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation updateFeatureFlag($input: Boolean!) {
    updateFeatureFlag(input: $input) {
      errors
    }
  }
`;
