/* @flow */
import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Label from "typography/Label";
import RadioButton from "forms/RadioButton2";

import styles from "./index.css";

import type {
  updateInvitation as UpdateInvitationResult,
  updateInvitationVariables
} from "graphql-types/updateInvitation";
import type { FlashType } from "reducers/flash";

type Props = {
  close: () => void,
  currentRole: string,
  flash: (type: FlashType, body: string) => void,
  teamMemberEmail?: string,
  teamMemberId: string,
  updateInvitation?: ({
    variables: updateInvitationVariables
  }) => Promise<{ data: UpdateInvitationResult }>,
  updateInvitationLoading?: boolean
};

const UpdateInvitation = (props: Props) => {
  const [role, setRole] = useState<string>(props.currentRole);

  return (
    <Modal
      footer={
        <>
          <Button
            className={styles.buttonCancel}
            disabled={props.updateInvitationLoading}
            onClick={props.close}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={
              props.currentRole === role || props.updateInvitationLoading
            }
            onClick={async (): Promise<void> => {
              const updateInvitation = props.updateInvitation;
              if (!updateInvitation) {
                return;
              }

              try {
                const resp = await updateInvitation({
                  variables: {
                    input: {
                      user: {
                        id: props.teamMemberId,
                        role
                      }
                    }
                  }
                });

                props.close();

                const errors = resp?.data?.updateTeamMember?.errors || [];
                if (errors.length > 0) {
                  props.flash(
                    "error",
                    "An error occurred when trying to update the invitation. Please try again."
                  );
                } else {
                  props.flash(
                    "notice",
                    "The invitation was successfully updated!"
                  );
                }
              } catch (_err) {}
            }}
            type="primary"
          >
            Save changes
          </Button>
        </>
      }
      onClose={props.close}
      size="large"
      title="Edit permission settings"
    >
      <div className={styles.main}>
        <Label>Permission settings</Label>
        <small className={styles.sectionFieldDescription}>
          Choose what permissions to grant{" "}
          {props.teamMemberEmail ? (
            <strong>{props.teamMemberEmail}</strong>
          ) : (
            <>this team member</>
          )}
          :
        </small>
        <RadioButton
          buttonClassName={styles.radioButtonOverride}
          className={styles.sectionFieldInput}
          onClick={setRole}
          selected={role === "collaborator"}
          value="collaborator"
        >
          <>
            <div>Collaborator</div>
            <div className={styles.roleDescription}>
              Provides access to all features of Edgar except billing
              information. Collaborators can add/remove social accounts,
              add/remove posts, approve draft posts from Contributors, schedule,
              and publish.
            </div>
          </>
        </RadioButton>
        <RadioButton
          buttonClassName={styles.radioButtonOverride}
          className={styles.sectionFieldInput}
          onClick={setRole}
          selected={role === "contributor"}
          value="contributor"
        >
          <>
            <div>Contributor</div>
            <div className={styles.roleDescription}>
              Member will NOT have access to billing, social accounts,
              scheduling, or publishing. Contributors may only add draft posts
              to the Pending tab of the library. All posts added by a
              Contributor must be approved by an Admin or Collaborator to
              publish.
            </div>
          </>
        </RadioButton>
      </div>
    </Modal>
  );
};

export default UpdateInvitation;
