/* @flow */
import React, { useEffect, useReducer } from "react";
import reducer, { initialState, initialStateBuilder } from "./state.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useApolloClient
} from "@apollo/react-hooks";
import { uniq, uniqBy, compact, flatMap } from "lodash";
import { produce } from "immer";
import moment from "moment-timezone";
/* $FlowFixMe */
import { DragDropContext } from "react-beautiful-dnd";

import Toggle from "forms/Toggle";
import UPDATE_FEATURE_FLAG from "mutations/updateFeatureFlag";
import { createFlash } from "actions/flash";
import { mostStrictImageLimit } from "lib/images/validateImage";
import { mostStrictVideoLimit } from "lib/videos/validateVideo";
import { hashFile, goBack, urlsEqual, useNewUICached, featureFlag } from "util";
import analyzeVideo from "lib/videos/analyzeVideo.js";
import analyzeImage from "lib/images/analyzeImage.js";
import { CenterAlignedOnboardingToolTip } from "components/common/OnboardingTooltip";
import { reportMessage } from "errors";
import getChangeset from "./getChanges.js";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Card from "layout/Card";
import Link from "links/Link";
import Icon from "icons/Icon";
import Button from "buttons/Button";
import AccountSelect from "./AccountSelect";
import CategorySelect from "./CategorySelect";
import Variation from "./Variation";
import AdvancedSettings from "./AdvancedSettings";
import Confirmation from "./Confirmation";
import SendAtNotice from "./SendAtNotice";
import Actions from "./Actions";
import RejectionModals from "./RejectionModals";
import { LoadingIcon } from "icons";
import Alert from "layout/Alert";
import Message from "layout/Message";
import NewHeader from "components/NewHeader";
import Information from "components/common/layout/Information";

import {
  remainingCharacterCounts,
  fbTextVisible,
  hasLinkAttachment
} from "./util.js";
import validateClient, {
  type ClientErrorsByField
} from "./util/clientValidation";
import validateIsSubmittable from "./util/isSubmittable";
import validateVariations, {
  type VariationWarning
} from "./util/variationWarnings";
import styles from "./index.css";

import LOAD_COMPOSER from "queries/loadComposer";
import { GET_CATEGORIES } from "queries/category";
import LOAD_CONTENT from "queries/loadContent";
import SEARCH_SOCIAL_MEDIA from "queries/searchSocialMedia";
import GET_VARIATION_SUGGESTIONS from "queries/getVariationSuggestions";
import UPDATE_CONTENT from "mutations/updateContent";
import CREATE_CONTENT from "mutations/createContent";
import SCRAPE_URL from "mutations/scrapeUrl";
import CREATE_MENTION_TAG_MAP from "mutations/createMentionTagMap";
import CREATE_IMAGE from "mutations/createImage";
import CREATE_VIDEO from "mutations/createVideo";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";

import type { ServerErrorsByField } from "./util/serverValidation";
import type { Dispatch, Connector } from "types";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type {
  loadComposer_accounts as AccountData,
  loadComposer_categories as CategoryData,
  loadComposer_user_onboardingProgress as OnboardingProgressData,
  Provider
} from "graphql-types/loadComposer";
import type {
  loadContent_content as ContentData,
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData,
  loadContent_content_variations_pageScrape as PageScrapeData,
  loadContent_content_variations_pageScrape_images as ScrapedImageData
} from "graphql-types/loadContent";
import type { searchSocialMedia_searchSocialMedia as SocialMediaSearchData } from "graphql-types/searchSocialMedia";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";

type EditorField = "text" | "fbText";

type EditorFocus = {
  field: EditorField,
  idx: number,
  hovered: ?number
};

type Props = {
  accounts: AccountData[],
  categories: CategoryData[],
  content: ?ContentData,
  mentionSuggestions: SocialMediaSearchData[],
  pageScrapesByVariation: PageScrapesByVariation,
  editorFocus: EditorFocus,
  uploadingMedia: UploadingMediaState,
  attachingImage: AttachingImageState,
  allowSuggestVariations: boolean,
  timeZone: string,
  mobileDevices: Object[],
  queuePaused: boolean,
  onboardingProgress: OnboardingProgressData,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>,
  linkShortenerDomainName: ?string,
  loadingComposer: boolean,
  loadingContent: boolean,
  loadingVariationSuggestions: boolean,
  isSetSendAtModalOpen: boolean,
  isPersisting: boolean,
  isEditing: boolean,
  isSubmittable: boolean,
  isConnectAccountsModalOpen: boolean,
  isWidget: boolean,
  loadedContent: boolean,
  linkAttachmentRemoved: { [key: string]: boolean },
  initialVariationText?: string,
  variationWarnings: VariationWarning[],
  clientErrors: ClientErrorsByField,
  serverErrors: ServerErrorsByField,
  rejectionModalReasons: RejectionModalReasons,
  suggestionsFailed: boolean,
  widgetCompletedCreate: boolean,
  onDismissSuggestionsFailed: () => void,
  onClearMentionSuggestions: () => void,
  onAddMention: (mentionTagMap: Object) => void,
  onFocusEditor: (idx: number, field: EditorField) => void,
  onBlurEditor: () => void,
  onMouseMoveEditor: (idx: number) => void,
  onMouseLeaveEditor: (idx: number) => void,
  onChangeCategory: ({ id: string, isNew: boolean }) => Promise<void>,
  onSelectAccounts: (string[]) => void,
  onDeselectAccounts: (string[]) => void,
  onChangeVariationText: (clientId: string, text: string) => void,
  onChangeVariationFbText: (clientId: string, fbText: string) => void,
  onEntityMapChange: (clientId: string, entityMap: Object) => void,
  onAddVariation: () => void,
  onDeleteVariation: (clientId: string) => void,
  onSearchSocialMedia: (provider: Provider, query: string) => void,
  onMentionSuggestionWarning: (value: string) => void,
  onPersist: () => void,

  onPersistAndSendNow: () => void,
  onOpenSetSendAtModal: () => void,
  onCloseSetSendAtModal: () => void,
  onFinishSetSendAtModal: (sendAt: string) => void,
  onApprovePersistAndSendNow: () => void,
  onApproveAndPersist: () => void,
  onApproveAndOpenSetSendAtModal: () => void,

  onAttachImage: (variationClientId: string, image: ImageData) => Promise<void>,
  onAttachScrapedImage: (
    variationClientId: string,
    scrapedImage: ScrapedImageData
  ) => Promise<void>,
  onImageUploadStart: (variationClientId: string, file: File) => void,
  onImageUploadProgress: (
    variationClientId: string,
    progress: ?number,
    status: string,
    file: File
  ) => void,
  onImageUploadError: (
    variationClientId: string,
    message: string,
    file: File
  ) => void,
  onImageUploadValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void,
  onImageUploadFinish: (
    variationClientId: string,
    url: string,
    file: File
  ) => Promise<void>,
  onImagesOrderChange: (variationClientId: string, images: []) => void,
  onRemoveImage: (variationClientId: string, imageId: string) => void,
  onAttachVideo: (variationClientId: string, Video: VideoData) => Promise<void>,
  onVideoUploadStart: (variationClientId: string, file: File) => void,
  onVideoUploadProgress: (
    variationClientId: string,
    progress: ?number,
    status: string,
    file: File
  ) => void,
  onVideoUploadError: (
    variationClientId: string,
    message: string,
    file: File
  ) => void,
  onVideoUploadValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void,
  onVideoUploadFinish: (
    variationClientId: string,
    url: string,
    file: File
  ) => Promise<void>,
  onRemoveVideo: (variationClientId: string, imageId: string) => void,
  onEnableLinkPreview: (variationClientId: string) => void,
  onDisableLinkPreview: (variationClientId: string) => void,
  onChangePinterestDestinationLink: (
    variationClientId: string,
    link: string
  ) => void,
  onChangePinterestTitle: (variationClientId: string, title: string) => void,
  onSuggestVariations: () => void,
  onChangeExpiresAt: (expiresAt: ?string) => void,
  onChangeSendAt: (sendAt: ?string) => void,
  onChangeUseOnce: (val: boolean) => void,
  onChangeUseShortLinks: (val: boolean) => void,
  onSelectPinterestBoard: (accountId: string, pinterestBoardId: string) => void,
  onChangeInstagramBusinessSetting: (
    sendMobileReminder: boolean,
    instaReels?: boolean
  ) => void,
  onAcknowledgeRejectionModal: () => void,

  completeOnboardingTooltipStep: (step: OnboardingTooltipStep) => void,
  showAddVariationOnboardingTooltip: boolean,
  showTextFieldOnboardingTooltip: boolean,
  showAccountsSelectOnboardingTooltip: boolean,
  showCategorySelectOnboardingTooltip: boolean,
  showSaveContentOnboardingTooltip: boolean
} & TopbarProps;

type TopbarProps = {
  topNav: TopNavProps,
  subscriptionStatus: SubscriptionStatusProps
};

const COMPOSER_HELP_URL =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/add-content-to-edgar/add-new-content-features-overview";

const Composer = ({
  topNav,
  subscriptionStatus,
  accounts,
  categories,
  content,
  mentionSuggestions,
  pageScrapesByVariation,
  uploadingMedia,
  attachingImage,
  allowSuggestVariations,
  timeZone,
  mobileDevices,
  queuePaused,
  onboardingProgress,
  linkShortenerDomainName,
  editorFocus,
  loadingComposer,
  loadingContent,
  loadingVariationSuggestions,
  isSetSendAtModalOpen,
  isPersisting,
  isEditing,
  isSubmittable,
  isConnectAccountsModalOpen,
  isWidget = false,
  loadedContent,
  linkAttachmentRemoved,
  widgetCompletedCreate,
  variationWarnings,
  clientErrors,
  serverErrors,
  rejectionModalReasons,
  dismissOnboarding,
  suggestionsFailed,
  onDismissSuggestionsFailed,
  onClearMentionSuggestions,
  onAddMention,
  onFocusEditor,
  onBlurEditor,
  onMouseMoveEditor,
  onMouseLeaveEditor,
  onChangeCategory,
  onSelectAccounts,
  onDeselectAccounts,
  onChangeVariationText,
  onChangeVariationFbText,
  onEntityMapChange,
  onAddVariation,
  onDeleteVariation,
  onSearchSocialMedia,
  onMentionSuggestionWarning,
  onPersist,
  onPersistAndSendNow,
  onOpenSetSendAtModal,
  onCloseSetSendAtModal,
  onFinishSetSendAtModal,
  onApprovePersistAndSendNow,
  onApproveAndPersist,
  onApproveAndOpenSetSendAtModal,
  onAttachImage,
  onAttachScrapedImage,
  onImageUploadStart,
  onImageUploadProgress,
  onImageUploadError,
  onImageUploadValidationError,
  onImageUploadFinish,
  onImagesOrderChange,
  onRemoveImage,
  onAttachVideo,
  onVideoUploadStart,
  onVideoUploadProgress,
  onVideoUploadError,
  onVideoUploadValidationError,
  onVideoUploadFinish,
  onRemoveVideo,
  onEnableLinkPreview,
  onDisableLinkPreview,
  onChangePinterestDestinationLink,
  onChangePinterestTitle,
  onChangeUseOnce,
  onChangeUseShortLinks,
  onChangeExpiresAt,
  onChangeSendAt,
  onSuggestVariations,
  onSelectPinterestBoard,
  onChangeInstagramBusinessSetting,
  onAcknowledgeRejectionModal,

  completeOnboardingTooltipStep,
  showAddVariationOnboardingTooltip,
  showTextFieldOnboardingTooltip,
  showAccountsSelectOnboardingTooltip,
  showCategorySelectOnboardingTooltip,
  showSaveContentOnboardingTooltip
}: Props) => {
  const useNewUI = useNewUICached();

  const selectedAccounts = (content?.accountRelationships ?? []).map(
    rel => rel.account
  );
  const selectedPlatforms = uniq(selectedAccounts.map(a => a.platform));
  const selectedProviders = uniq(selectedAccounts.map(a => a.provider));
  const selectedPinterestBoardsByAccount = (
    content?.accountRelationships ?? []
  ).reduce((acc, rel) => {
    acc[rel.account.id] = (rel.pinterestBoards ?? []).map(p => p.id);
    return acc;
  }, {});
  const variations = content?.variations ?? [];
  const allAttachedImages = uniqBy(
    flatMap(variations, v => v.images),
    i => i.clientProvidedSha256
  );
  const allAttachedVideos = uniqBy(
    flatMap(variations, v => v.videos),
    v => v.clientProvidedSha256
  );

  if (widgetCompletedCreate) {
    return <Confirmation />;
  }

  const formFullyLoaded = isEditing
    ? !loadingComposer && loadedContent
    : !loadingComposer;

  let getOnDragEnd = (result: any, provided: any): void => {
    console.log(result);
    console.log(provided);
  };

  const setOnDragEndMethod = newMethod => {
    getOnDragEnd = newMethod;
  };

  const [updateData] = useMutation(UPDATE_FEATURE_FLAG, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true
  });

  function onChangeToggle() {
    const promise = updateData({
      variables: {
        input: true
      }
    });
    if (promise) {
      promise.then(
        (response): void => {
          const errors = response?.data?.updateFeatureFlag?.errors || [];
          if (errors.length == 0) {
            window.location.reload();
          }
        }
      );
    }
  }

  return (
    <App
      loggedIn
      layout={isWidget ? "widget" : "default"}
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      onboardingProgress={onboardingProgress}
      onDismissOnboarding={dismissOnboarding}
      header={
        useNewUI ? (
          <NewHeader
            title={isEditing ? "Edit content" : "Add content to library"}
            helpUrl={COMPOSER_HELP_URL}
            mb
            hideAddNew
            {...topNav}
          />
        ) : (
          <PageTitle helpUrl={COMPOSER_HELP_URL}>
            {isEditing ? "Edit content" : "Add content to library"}
          </PageTitle>
        )
      }
      messages={
        queuePaused ? (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting in{" "}
            <Link href="/queue">your Queue</Link>.
          </Message>
        ) : null
      }
    >
      {featureFlag("new_composer_existing_user") && (
        <>
          <div className={styles.wrapper}>
            <strong>Meet Inky, my new AI sidekick!</strong> <br />
            Switch to the new composer to get access to Inky.
          </div>
          <div className={styles.toggle_area}>
            <div>
              <p>Try our new composer screen</p>
            </div>
            <div>
              <Toggle
                value={featureFlag("new_composer")}
                className={styles.toggleNewUi}
                onChange={onChangeToggle}
              />
            </div>
          </div>
        </>
      )}

      {loadingComposer || loadingContent ? (
        <LoadingIcon className={styles.loading} />
      ) : (
        <div className={styles.contentForm}>
          <div className={styles.sidebar}>
            <Card>
              <AccountSelect
                accounts={accounts}
                selectedAccountIds={selectedAccounts.map(({ id }) => id)}
                selectedPinterestBoardsByAccount={
                  selectedPinterestBoardsByAccount
                }
                postedVariationsCount={{
                  twitterPostedVariationsCount:
                    content?.twitterPostedVariationsCount || 0,
                  variationsCount: variations.length
                }}
                lastPostedTo={content?.lastPostedTo}
                onNextOnboardingStep={() =>
                  completeOnboardingTooltipStep("accounts")
                }
                showOnboardingTooltip={showAccountsSelectOnboardingTooltip}
                sendMobileReminder={content?.sendMobileReminder}
                instaReels={content?.instaReels}
                hasMobileDevices={!!mobileDevices.length}
                onSelect={onSelectAccounts}
                onDeselect={onDeselectAccounts}
                onSelectPinterestBoard={onSelectPinterestBoard}
                onChangeInstagramBusinessSetting={
                  onChangeInstagramBusinessSetting
                }
              />
            </Card>
          </div>
          <div className={styles.main}>
            <Card>
              <CategorySelect
                onNextOnboardingStep={() =>
                  completeOnboardingTooltipStep("categories")
                }
                showOnboardingTooltip={showCategorySelectOnboardingTooltip}
                value={content?.category}
                categories={categories}
                serverErrors={serverErrors?.category_id}
                onChange={onChangeCategory}
              />
            </Card>

            {variations.map((variation, index) => {
              const remainingChars = remainingCharacterCounts(
                selectedAccounts,
                variation.text || ""
              );

              const title =
                variations.length > 1 ? `Variation ${index + 1}` : "Post";

              return (
                <Card key={variation.clientId} padded={false}>
                  <DragDropContext
                    onDragStart={() => { }}
                    onDragUpdate={() => { }}
                    onDragEnd={(result: any, provided: any) =>
                      getOnDragEnd(result, provided)
                    }
                  >
                    <Variation
                      setOnDragEndMethod={setOnDragEndMethod}
                      index={index}
                      value={variation}
                      content={content}
                      title={title}
                      isConnectAccountsModalOpen={isConnectAccountsModalOpen}
                      selectedPlatforms={selectedPlatforms}
                      selectedProviders={selectedProviders}
                      selectedAccounts={selectedAccounts}
                      selectedPinterestBoardsByAccount={
                        selectedPinterestBoardsByAccount
                      }
                      mentionSuggestions={mentionSuggestions}
                      uploadingMedia={uploadingMedia[variation.clientId] ?? []}
                      allAttachedVideos={allAttachedVideos}
                      allAttachedImages={allAttachedImages}
                      attachingImage={
                        attachingImage[variation.clientId] ?? false
                      }
                      pageScrape={pageScrapesByVariation[variation.clientId]}
                      remainingCharacterCounts={remainingChars}
                      focused={editorFocus.idx === index}
                      focusedField={editorFocus.field}
                      hovered={editorFocus.hovered === index}
                      deleteVisible={variations.length > 1}
                      fbTextVisible={fbTextVisible(
                        variation,
                        selectedPlatforms
                      )}
                      linkAttachmentRemoved={
                        linkAttachmentRemoved[variation.clientId]
                      }
                      warnings={variationWarnings?.[index]?.warnings ?? []}
                      requiredFieldErrorVisible={
                        variationWarnings?.[index]?.requiredFieldErrorVisible ??
                        false
                      }
                      clientErrors={
                        clientErrors?.variations?.[variation.clientId]
                      }
                      serverErrors={
                        serverErrors?.variations?.[variation.clientId]
                      }
                      onNextOnboardingStep={() =>
                        completeOnboardingTooltipStep("textfield")
                      }
                      showOnboardingTooltip={showTextFieldOnboardingTooltip}
                      onClearMentionSuggestions={onClearMentionSuggestions}
                      onAddMention={onAddMention}
                      onMouseMove={onMouseMoveEditor.bind(null, index)}
                      onMouseLeave={onMouseLeaveEditor.bind(null, index)}
                      onFocusText={onFocusEditor.bind(null, index, "text")}
                      onFocusFbText={onFocusEditor.bind(null, index, "fbText")}
                      onFocusVariation={onFocusEditor.bind(null, index, "text")}
                      onBlurField={onBlurEditor}
                      onChangeText={onChangeVariationText}
                      onChangeFbText={onChangeVariationFbText}
                      onEntityMapChange={onEntityMapChange}
                      onSearchSocialMedia={onSearchSocialMedia}
                      onMentionSuggestionWarning={onMentionSuggestionWarning}
                      onDelete={onDeleteVariation.bind(
                        null,
                        variation.clientId
                      )}
                      onAttachImage={onAttachImage}
                      onAttachScrapedImage={onAttachScrapedImage}
                      onImageUploadStart={onImageUploadStart}
                      onImageUploadProgress={onImageUploadProgress}
                      onImageUploadError={onImageUploadError}
                      onImageUploadValidationError={
                        onImageUploadValidationError
                      }
                      onImageUploadFinish={onImageUploadFinish}
                      onImagesOrderChange={onImagesOrderChange}
                      onRemoveImage={onRemoveImage}
                      onAttachVideo={onAttachVideo}
                      onVideoUploadStart={onVideoUploadStart}
                      onVideoUploadProgress={onVideoUploadProgress}
                      onVideoUploadError={onVideoUploadError}
                      onVideoUploadValidationError={
                        onVideoUploadValidationError
                      }
                      onVideoUploadFinish={onVideoUploadFinish}
                      onRemoveVideo={onRemoveVideo}
                      onEnableLinkPreview={onEnableLinkPreview}
                      onDisableLinkPreview={onDisableLinkPreview}
                      onChangePinterestDestinationLink={onChangePinterestDestinationLink.bind(
                        null,
                        variation.clientId
                      )}
                      onChangePinterestTitle={onChangePinterestTitle.bind(
                        null,
                        variation.clientId
                      )}
                    />
                  </DragDropContext>
                </Card>
              );
            })}

            {loadingVariationSuggestions && (
              <Card padding={false}>
                <div className={styles.loadingIconContainer}>
                  <LoadingIcon />
                </div>
              </Card>
            )}

            {suggestionsFailed && (
              <Alert
                type="info"
                className={styles.messageContainer}
                onDismiss={onDismissSuggestionsFailed}
              >
                Edgar was unable to suggest variations for that URL, but you can
                create your own by clicking "Add variation" below! (
                <Link
                  target="_blank"
                  url={
                    "https://help.meetedgar.com/edgar-s-features-and-best-practices/the-add-new-content-button/variations"
                  }
                >
                  Why didn’t this URL work?
                </Link>
                )
              </Alert>
            )}

            <Card padded={false} className={styles.addVariationCard}>
              {showAddVariationOnboardingTooltip && (
                <CenterAlignedOnboardingToolTip
                  tooltipBody={
                    "Add additional versions of this content to create more variety for your Library."
                  }
                />
              )}
              {allowSuggestVariations ? (
                <>
                  <div className={styles.suggestVariationsButtonContainer}>
                    <Button
                      className={styles.suggestVariationsButton}
                      small
                      type="alt-primary"
                      onClick={onSuggestVariations}
                    >
                      <Icon type="magic" /> Suggest variations
                    </Button>
                  </div>
                  <div className={styles.suggestVariationsButtonContainer}>
                    <Link
                      onClick={onAddVariation}
                      className={styles.addVariationButton}
                    >
                      Manually add variation
                    </Link>
                  </div>
                </>
              ) : (
                <Link
                  onClick={onAddVariation}
                  className={styles.addVariationButton}
                >
                  <Icon type="plus" /> Add variation
                </Link>
              )}
            </Card>

            {content && formFullyLoaded && (
              <Card>
                <AdvancedSettings
                  timeZone={timeZone}
                  useOnce={content.useOnce ?? false}
                  expiresAt={content.expiresAt}
                  useShortLinks={content.useShortLinks}
                  linkShortenerDomainName={linkShortenerDomainName}
                  openAtMount={content.expiresAt || content.useOnce}
                  onChangeExpiresAt={onChangeExpiresAt}
                  onChangeUseOnce={onChangeUseOnce}
                  onChangeUseShortLinks={onChangeUseShortLinks}
                />
                {content.sendAt && (
                  <SendAtNotice
                    sendAt={content.sendAt}
                    timeZone={timeZone}
                    showWarning={false}
                    onChangeSendAt={onChangeSendAt}
                  />
                )}
                <Actions
                  content={content}
                  timeZone={timeZone}
                  isSubmittable={isSubmittable}
                  isPersisting={isPersisting}
                  isSetSendAtModalOpen={isSetSendAtModalOpen}
                  isConnectAccountsModalOpen={isConnectAccountsModalOpen}
                  onNextOnboardingStep={() =>
                    completeOnboardingTooltipStep("save")
                  }
                  showOnboardingTooltip={showSaveContentOnboardingTooltip}
                  showImportContentModal={
                    //TODO: The import modal during onboarding is heavily tied into RSS Feed
                    //      data stored in Redux. That data isn't there now :\.
                    /*
                  onboardingProgress.importContent == null &&
                  pullAll(uniq(accounts.map(({ platform }) => platform)), [
                    "INSTAGRAM",
                    "PINTEREST"
                  ]).length > 0
                  */
                    false
                  }
                  onChangeSendAt={onChangeSendAt}
                  onPersist={onPersist}
                  onPersistAndSendNow={onPersistAndSendNow}
                  onOpenSetSendAtModal={onOpenSetSendAtModal}
                  onCloseSetSendAtModal={onCloseSetSendAtModal}
                  onFinishSetSendAtModal={onFinishSetSendAtModal}
                  onApprovePersistAndSendNow={onApprovePersistAndSendNow}
                  onApproveAndPersist={onApproveAndPersist}
                  onApproveAndOpenSetSendAtModal={
                    onApproveAndOpenSetSendAtModal
                  }
                />
                <RejectionModals
                  rejectionModalReasons={rejectionModalReasons}
                  onAcknowledge={onAcknowledgeRejectionModal}
                />
              </Card>
            )}
          </div>
        </div>
      )}
    </App>
  );
};

type MediaUpload = { fileName: string, progress: ?number };
type UploadingMediaState = { [key: string]: MediaUpload[] };
type AttachingImageState = { [key: string]: boolean };
type PageScrapesByVariation = {
  [key: string]: ?PageScrapeData
};
type RejectionModalReasons = {
  incompatibleAccountReasons: string[],
  incompatibleMediaReasons: { [key: string]: string[] },
  failedValidationReasons: string[]
};
type OnboardingTooltipStep =
  | "accounts"
  | "categories"
  | "textfield"
  | "add-variation"
  | "save";

type OwnProps = {
  contentId: string,
  onSetFlash: (message: string) => void
} & Props;

const ComposerWithState = (props: OwnProps) => {
  const apolloClient = useApolloClient();
  const { contentId, onSetFlash, isWidget, initialVariationText } = props;
  const isEditing = !!contentId;

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    initialStateBuilder(isWidget, initialVariationText)
  );
  window.STATE = state;

  const {
    content,
    uploadingMedia,
    attachingImage,
    editorFocus,
    rejectionModalReasons,
    mentionSuggestions,
    variationSuggestions,
    accounts,
    categories,
    serverErrors,
    persistResult,
    onboardingTooltipStep,
    isSetSendAtModalOpen,
    scrapes,
    linkAttachmentRemoved,
    hasModifiedVariations,
    loadedContent,
    hasAccountWithoutSchedule
  } = state;

  // Handle the side-effects of content create/update
  useEffect(() => {
    switch (persistResult) {
      case "CREATED": {
        if (isWidget) {
          // The widget displays the success component forever after content is created
          return;
        }
        const flashMessage = hasAccountWithoutSchedule
          ? 'Content was successfully added to your Library. However, one or more of your social accounts does not have a schedule! <a href="/schedule">Click here</a> to schedule.'
          : "Content was successfully added to your Library";
        onSetFlash(flashMessage);
        dispatch({ type: "FINISH_PERSIST" });
        return;
      }
      case "UPDATED": {
        goBack();
        dispatch({ type: "FINISH_PERSIST" });
        return;
      }
      case "ERROR": {
        if (serverErrors.category_id) {
          window.scrollTo(0, 0);
        }
        dispatch({ type: "FINISH_PERSIST" });
        return;
      }
    }
  }, [
    persistResult,
    serverErrors,
    isWidget,
    onSetFlash,
    hasAccountWithoutSchedule
  ]);

  const [scrapeUrl] = useMutation(SCRAPE_URL);
  // Handle the side-effects of page scraper
  useEffect(() => {
    Object.keys(scrapes).forEach(variationClientId => {
      const scrape = scrapes[variationClientId];
      const { status, timeoutId: currentTimeoutId } = scrape;
      switch (status) {
        case "QUEUED": {
          if (currentTimeoutId) {
            clearTimeout(currentTimeoutId);
          }
          dispatch({ type: "START_SCRAPE", variationClientId });
          break;
        }
        case "STARTED": {
          const timeoutId = setTimeout(
            pollScrapeUrl.bind(null, variationClientId),
            1000
          );
          dispatch({ type: "POLL_SCRAPE", variationClientId, timeoutId });
          break;
        }
      }
    });

    async function pollScrapeUrl(variationClientId) {
      const { url } = scrapes[variationClientId];
      const {
        data: {
          scrapeUrl: { pageScrape, errors }
        }
      } = await scrapeUrl({
        variables: {
          url
        }
      });

      const currentUrl = scrapes[variationClientId]?.pageScrape?.url;
      if (pageScrape?.url && currentUrl && !urlsEqual(pageScrape?.url, url)) {
        return;
      }

      if (errors && errors.length > 0) {
        dispatch({
          type: "SCRAPE_FAILED",
          variationClientId,
          pageScrape: pageScrape || { status: "error" },
          errors
        });
        return;
      }

      if (pageScrape?.status === "ok") {
        dispatch({
          type: "SCRAPE_SUCCEEDED",
          variationClientId,
          pageScrape
        });
        return;
      }

      if (pageScrape?.status === "waiting") {
        const timeoutId = setTimeout(
          pollScrapeUrl.bind(null, variationClientId),
          1000
        );
        dispatch({ type: "POLL_SCRAPE", variationClientId, timeoutId });
        return;
      }
    }
  }, [scrapes, content, scrapeUrl, state.scrapes]);

  const suggestionsFailed = variationSuggestions.status === "FAILED";
  const usedSuggestVariations = variationSuggestions.status === "COMPLETE";
  const allScrapedUrls = compact(
    uniq(
      Object.keys(scrapes).reduce(
        (acc, variationClientId) => [
          ...acc,
          scrapes[variationClientId]?.pageScrape?.url
        ],
        []
      )
    )
  );
  const platforms = uniq(
    (content?.accountRelationships ?? []).map(rel => rel.account?.platform)
  );
  const providers = uniq(
    (content?.accountRelationships ?? []).map(rel => rel.account?.provider)
  );

  // Queries
  const { loading: loadingComposer, error, data } = useQuery(LOAD_COMPOSER, {
    onCompleted: data => {
      dispatch({
        type: "LOAD_COMPOSER",
        categories: data?.categories,
        accounts: data?.accounts
      });
      if (isWidget) {
        dispatch({ type: "LOAD_WIDGET" });
      }
    }
  });

  const [
    loadContent,
    { loading: loadingContent, error: _contentError, data: contentData }
  ] = useLazyQuery(LOAD_CONTENT, {
    variables: {
      contentId
    },
    onCompleted: data => {
      dispatch({ type: "LOAD_CONTENT", content: data?.content });
    }
  });
  if (!loadingContent && !contentData && contentId) {
    loadContent();
  }
  const [searchSocialMedia] = useLazyQuery(SEARCH_SOCIAL_MEDIA, {
    onCompleted: data =>
      dispatch({
        type: "SET_MENTION_SUGGESTIONS",
        suggestions: data.searchSocialMedia
      }),
    fetchPolicy: "network-only"
  });

  // Mutations
  const [updateContent, { loading: isUpdatingContent }] = useMutation(
    UPDATE_CONTENT,
    {
      onError(err) {
        let errorMsg;
        if (err?.networkError?.result?.error?.message) {
          errorMsg = err.networkError.result.error.message;
        } else {
          errorMsg = err.message;
        }

        dispatch({
          type: "UPDATE_CONTENT_FINISH",
          content: null,
          errors: [{ field: "base", messages: [errorMsg] }]
        });
      },

      update(_cache, { data }) {
        const {
          updateContent: { content, errors }
        } = data;
        dispatch({ type: "UPDATE_CONTENT_FINISH", content, errors });
      }
    }
  );
  const [createContent, { loading: isCreatingContent }] = useMutation(
    CREATE_CONTENT,
    {
      onError(err) {
        dispatch({
          type: "CREATE_CONTENT_FINISH",
          content: null,
          errors: [{ field: "base", messages: [err.message] }],
          hasAccountWithoutSchedule: false
        });
      },

      update(_cache, { data }) {
        const {
          createContent: { content, errors, hasAccountWithoutSchedule }
        } = data;
        dispatch({
          type: "CREATE_CONTENT_FINISH",
          content,
          errors,
          hasAccountWithoutSchedule
        });
      }
    }
  );
  const [createMentionTagMap] = useMutation(CREATE_MENTION_TAG_MAP);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);
  const [createImage] = useMutation(CREATE_IMAGE);
  const [createVideo] = useMutation(CREATE_VIDEO);

  const timeZone = data?.company?.timeZone;
  const queuePaused = data?.company?.queueStatus === "PAUSED";
  const onboardingProgress = data?.user?.onboardingProgress;
  const mobileDevices = data?.user?.devices || [];
  const linkShortenerDomainName =
    data?.company?.defaultLinkShortenerDomain?.name;

  const pageScrapesByVariation = (
    content?.variations ?? []
  ).reduce < PageScrapesByVariation > ((acc, variation) => {
    const { clientId } = variation;
    acc[clientId] = scrapes[clientId]?.pageScrape;
    return acc;
  }, {});

  const [
    getVariationSuggestions,
    { loading: loadingVariationSuggestions }
  ] = useLazyQuery(GET_VARIATION_SUGGESTIONS, {
    onCompleted: ({ variationSuggestions: suggestions }) => {
      dispatch({ type: "ADD_SUGGESTED_VARIATIONS", suggestions });
      completeOnboardingTooltipStep("add-variation");
    },
    onError: () =>
      dispatch({ type: "ADD_SUGGESTED_VARIATIONS", suggestions: [] }),
    fetchPolicy: "network-only"
  });

  //#####################################################################################################
  // Validation and errors
  //#####################################################################################################
  const variationWarnings = validateVariations(
    content?.variations ?? [],
    hasModifiedVariations,
    platforms
  );
  const clientErrors = validateClient(content, pageScrapesByVariation);

  const isSubmittable = (function () {
    const selectedPinterestBoardsByAccount = (
      content?.accountRelationships ?? []
    )
      .filter(({ account }) => account.platform === "PINTEREST")
      .reduce((acc, rel) => {
        acc[rel.account.id] = (rel.pinterestBoards ?? []).map(p => p.id);
        return acc;
      }, {});
    const uploadsInProgress = Object.keys(uploadingMedia).reduce(
      (acc, variationClientId) => [
        ...acc,
        ...uploadingMedia[variationClientId]
      ],
      []
    );
    const pageScrapesInProgress = Object.keys(pageScrapesByVariation).some(
      v => pageScrapesByVariation[v]?.status === "waiting"
    );

    return validateIsSubmittable(
      selectedPinterestBoardsByAccount,
      uploadsInProgress,
      pageScrapesInProgress,
      clientErrors
    );
  })();

  //#############################
  // Image attach
  //#############################
  async function handleAttachImage(
    variationClientId: string,
    image: ImageData
  ) {
    const imageCountLimit = mostStrictImageLimit(
      platforms,
      providers,
      content?.sendMobileReminder
    );
    const variation = (content?.variations ?? []).find(
      v => v.clientId === variationClientId
    );
    if (!variation) {
      return;
    }
    if (
      imageCountLimit.count - variation.images.length <= 0 ||
      variation.videos.length > 0
    ) {
      return;
    }

    dispatch({ type: "ATTACH_IMAGE", variationClientId, image });
  }

  //#############################
  // Scraped image attach
  //#############################
  async function handleAttachScrapedImage(
    variationClientId: string,
    scrapedImage: ScrapedImageData
  ) {
    const imageCountLimit = mostStrictImageLimit(
      platforms,
      providers,
      content?.sendMobileReminder
    );
    const variation = (content?.variations ?? []).find(
      v => v.clientId === variationClientId
    );
    if (!variation) {
      return;
    }
    if (
      imageCountLimit.count - variation.images.length <= 0 ||
      variation.videos.length > 0
    ) {
      return;
    }

    dispatch({ type: "ATTACH_SCRAPED_IMAGE_START", variationClientId });
    const {
      src: url,
      size,
      type: format,
      width,
      height,
      sha256
    } = scrapedImage;
    // TODO: Handle error case
    const {
      data: {
        createImage: { image }
      }
    } = await createImage({
      variables: {
        input: {
          url,
          format,
          fileSize: size,
          width,
          height,
          clientProvidedSha256: sha256
        }
      }
    });
    dispatch({ type: "ATTACH_SCRAPED_IMAGE_FINISH", variationClientId, image });
  }

  //#############################
  // Image upload
  //#############################

  function handleImageUploadStart(_variationClientId, _file) { }

  function handleImageUploadProgress(
    variationClientId,
    progress,
    status,
    file
  ) {
    const fileName = file.name;
    dispatch({
      type: "IMAGE_UPLOAD_PROGRESS",
      variationClientId,
      progress,
      fileName
    });
  }

  function handleImageUploadError(variationClientId, message, file) {
    reportMessage("S3MediaUploaderFailed", message);
    _handleImageUploadError(variationClientId, [message], file);
  }

  function handleImageUploadValidationError(variationClientId, errors, file) {
    _handleImageUploadError(variationClientId, errors, file);
  }

  function _handleImageUploadError(variationClientId, messages, file) {
    const fileName = file.name;
    dispatch({
      type: "IMAGE_UPLOAD_ERROR",
      variationClientId,
      fileName,
      messages
    });
  }

  async function handleImageUploadFinish(variationClientId, url, file) {
    const clientProvidedSha256 = await hashFile(file);
    const metadata = await analyzeImage(file);
    const fileName = file.name;
    // TODO: Handle error case
    const {
      data: {
        createImage: { image }
      }
    } = await createImage({
      variables: {
        input: {
          fileSize: metadata.size,
          format: metadata.type,
          width: metadata.width,
          height: metadata.height,
          url,
          clientProvidedSha256
        }
      }
    });
    dispatch({
      type: "IMAGE_UPLOAD_FINISH",
      variationClientId,
      fileName,
      image
    });
  }

  function handleImagesOrder(variationClientId, images) {
    dispatch({
      type: "CHANGE_IMAGE_ORDER_ACTION",
      variationClientId,
      images
    });
  }

  function handleRemoveImage(variationClientId, imageId) {
    dispatch({ type: "REMOVE_IMAGE", variationClientId, imageId });
  }

  //#############################
  // Video attach
  //#############################
  async function handleAttachVideo(
    variationClientId: string,
    video: VideoData
  ) {
    const videoCountLimit = mostStrictVideoLimit(platforms);
    const variation = (content?.variations ?? []).find(
      v => v.clientId === variationClientId
    );
    if (!variation) {
      return;
    }
    if (
      videoCountLimit.count - variation.videos.length <= 0 ||
      variation.images.length > 0
    ) {
      return;
    }

    dispatch({ type: "ATTACH_VIDEO", variationClientId, video });
  }

  //#############################
  // Video upload
  //#############################

  function handleVideoUploadStart(_variationClientId, _file) { }

  function handleVideoUploadProgress(
    variationClientId,
    progress,
    status,
    file
  ) {
    dispatch({
      type: "VIDEO_UPLOAD_PROGRESS",
      variationClientId,
      progress,
      fileName: file.name
    });
  }

  function handleVideoUploadError(variationClientId, message, file) {
    reportMessage("S3MediaUploaderFailed", message);
    _handleVideoUploadError(variationClientId, [message], file);
  }

  function handleVideoUploadValidationError(variationClientId, errors, file) {
    _handleVideoUploadError(variationClientId, errors, file);
  }

  function _handleVideoUploadError(variationClientId, messages, file) {
    dispatch({
      type: "VIDEO_UPLOAD_ERROR",
      variationClientId,
      fileName: file.name,
      messages
    });
  }

  async function handleVideoUploadFinish(variationClientId, url, file) {
    const clientProvidedSha256 = await hashFile(file);
    const metadata = await analyzeVideo(file);
    // TODO: Handle error case
    const {
      data: {
        createVideo: { video }
      }
    } = await createVideo({
      variables: {
        input: {
          ...metadata,
          url,
          clientProvidedSha256
        }
      }
    });
    dispatch({
      type: "VIDEO_UPLOAD_FINISH",
      variationClientId,
      fileName: file.name,
      video
    });
  }

  function handleRemoveVideo(variationClientId, videoId) {
    dispatch({ type: "REMOVE_VIDEO", variationClientId, videoId });
  }

  //#############################
  // Editor focus
  //#############################

  function handleFocusEditor(idx, field) {
    dispatch({ type: "FOCUS_EDITOR", idx, field });
  }

  function handleBlurEditor() {
    dispatch({ type: "BLUR_EDITOR" });
  }

  function handleMouseMoveEditor(idx) {
    dispatch({ type: "MOUSE_MOVE_EDITOR", idx });
  }

  function handleMouseLeaveEditor(idx) {
    dispatch({ type: "MOUSE_LEAVE_EDITOR", idx });
  }

  //#############################
  // Content
  //#############################

  function handleSelectAccounts(accountIds) {
    accountIds.forEach(accountId =>
      dispatch({ type: "SELECT_ACCOUNT", accountId })
    );
  }

  function handleDeselectAccounts(accountIds) {
    accountIds.forEach(accountId =>
      dispatch({ type: "DESELECT_ACCOUNT", accountId })
    );
  }

  async function handleChangeSelectedCategory({ id: categoryId, isNew }) {
    if (isNew) {
      const { data } = await apolloClient.query({
        query: GET_CATEGORIES
      });

      dispatch({
        type: "LOAD_CATEGORIES",
        categories: data?.categories
      });
    }

    dispatch({ type: "SELECT_CATEGORY", categoryId });
  }

  function handleChangeUseOnce(useOnce: boolean) {
    dispatch({ type: "CHANGE_USE_ONCE", useOnce });
  }

  function handleChangeUseShortLinks(useShortLinks: boolean) {
    dispatch({ type: "CHANGE_USE_SHORT_LINKS", useShortLinks });
  }

  function handleChangeExpiresAt(expiresAt: ?string) {
    dispatch({ type: "CHANGE_EXPIRES_AT", expiresAt });
  }

  function handleChangeSendAt(sendAt: ?string) {
    dispatch({ type: "CHANGE_SEND_AT", sendAt });
  }

  function handleChangeVariationText(variationClientId, text) {
    dispatch({ type: "CHANGE_TEXT", variationClientId, text });
  }

  function handleChangeVariationFbText(variationClientId, fbText) {
    dispatch({ type: "CHANGE_FB_TEXT", variationClientId, fbText });
  }

  function handleAddVariation() {
    dispatch({ type: "ADD_VARIATION" });
    completeOnboardingTooltipStep("add-variation");
  }

  function handleDeleteVariation(variationClientId) {
    dispatch({ type: "DELETE_VARIATION", variationClientId });
  }

  function handleSuggestVariations() {
    const urls = allScrapedUrls ?? [];
    if (urls.length > 0) {
      getVariationSuggestions({
        variables: {
          urls
        }
      });
    }
  }

  function handleDismissSuggestionsFailed() {
    dispatch({ type: "DISMISS_SUGGESTED_VARIATIONS_FAILED" });
  }

  //#############################
  // Mentions
  //#############################

  function handleEntityMapChange(variationClientId, rawRichTextEntityMap) {
    dispatch({
      type: "CHANGE_ENTITY_MAP",
      variationClientId,
      rawRichTextEntityMap
    });
  }

  function handleClearMentionSuggestions() {
    dispatch({ type: "SET_MENTION_SUGGESTIONS", suggestions: [] });
  }

  function handleMentionSuggestionWarning(message: string) {
    console.log("handleMentionSuggestionWarning", message);
  }

  function handleAddMention(mentionTagMap: Object) {
    createMentionTagMap({
      variables: {
        input: mentionTagMap
      }
    });
  }

  function handleSearchSocialMedia(provider: Provider, query: string) {
    searchSocialMedia({
      variables: {
        provider,
        query
      }
    });
  }

  //#############################
  // Link preview
  //#############################
  function handleEnableLinkPreview(variationClientId: string) {
    dispatch({ type: "ATTACH_LINK", variationClientId });
    //TODO: track("Composer link enabled");
  }

  function handleDisableLinkPreview(variationClientId: string) {
    dispatch({ type: "REMOVE_LINK", variationClientId });
    //TODO: track("Composer link disabled");
  }

  //#############################
  // Pinterest settings
  //#############################

  function handleChangePinterestDestinationLink(
    variationClientId: string,
    link: string
  ) {
    dispatch({
      type: "CHANGE_PINTEREST_DESTINATION_LINK",
      variationClientId,
      link
    });
    // TODO: Track manually set?
  }

  function handleChangePinterestTitle(
    variationClientId: string,
    title: string
  ) {
    dispatch({ type: "CHANGE_PINTEREST_TITLE", variationClientId, title });
  }

  function handleSelectPinterestBoard(accountId, pinterestBoardId) {
    dispatch({ type: "SELECT_PINTEREST_BOARD", accountId, pinterestBoardId });
  }

  function handleChangeInstagramBusinessSetting(
    sendMobileReminder,
    instaReels
  ) {
    dispatch({
      type: "CHANGE_INSTAGRAM_BUSINESS_SETTING",
      sendMobileReminder,
      instaReels: instaReels ?? false
    });
  }

  //#############################
  // Persistence
  //#############################

  function handlePersist() {
    persist(content);
  }

  function handlePersistAndSendNow() {
    persist(
      produce(content, draft => {
        draft.sendAt = moment()
          .tz(timeZone)
          .add(59, "seconds")
          .format();
      })
    );
  }

  function handleOpenSetSendAtModal() {
    dispatch({ type: "OPEN_SEND_AT_MODAL" });
  }

  function handleCloseSetSendAtModal() {
    dispatch({ type: "CLOSE_SEND_AT_MODAL" });
  }

  function handleFinishSetSendAtModal(sendAt: string) {
    dispatch({ type: "CLOSE_SEND_AT_MODAL" });
    persist(
      produce(content, draft => {
        draft.sendAt = sendAt;
      })
    );
  }

  function handleApproveAndPersist() {
    persist(
      produce(content, draft => {
        draft.status = "APPROVED";
      })
    );
  }

  function handleApprovePersistAndSendNow() {
    persist(
      produce(content, draft => {
        draft.sendAt = moment()
          .tz(timeZone)
          .add(59, "seconds")
          .format();
        draft.status = "APPROVED";
      })
    );
  }

  function handleApproveAndOpenSetSendAtModal() {
    dispatch({ type: "APPROVE" });
    dispatch({ type: "OPEN_SEND_AT_MODAL" });
  }

  function persist(modifiedContent) {
    const selectedPlatforms = uniq(
      (content?.accountRelationships ?? [])
        .map(rel => rel.account)
        .map(a => a.platform)
    );

    let changeset =
      modifiedContent && getChangeset(contentData?.content, modifiedContent);

    // Rules for setting links are a bit complicated. They depend on state outside
    // of the content object (specifically scrapes). The changeset function is
    // (mostly) a simple diff so it's unaware when the link should be set or unset.
    // This takes a second look at the variations on the content and determines if
    // the link has changed and should be sent along to the server. This weirdness
    // is probably an indication that our data model is a bit off on links/scrapes.
    const linkChanges = (content?.variations ?? []).reduce((acc, variation) => {
      const pageScrape = pageScrapesByVariation[variation.clientId];
      const linkAttached = hasLinkAttachment(
        variation,
        selectedPlatforms,
        pageScrape,
        uploadingMedia[variation.clientId],
        linkAttachmentRemoved[variation.clientId]
      );
      const link = linkAttached ? pageScrape?.url : null;
      if (variation.link !== link) {
        acc[variation.clientId] = { link, id: variation.id };
      }
      return acc;
    }, {});

    if (Object.values(linkChanges).length > 0) {
      if (!changeset) {
        changeset = { id: content?.id, variations: [] };
      }
      if (!changeset.variations) {
        changeset.variations = [];
      }
      Object.keys(linkChanges).forEach(clientId => {
        const { link, id } = linkChanges[clientId];
        const existingChanges = (changeset?.variations || []).find(
          v => v.clientId === clientId
        );
        if (existingChanges) {
          existingChanges.link = link;
        } else {
          // Suppress the Flow error here :( The null check above guarantees the
          // changeset and the variations array on it will exist but Flow isn't
          // able to see that
          // $FlowFixMe
          changeset.variations.push({ id, clientId, link });
        }
      });
    }

    const pinterestLinkChanges = (content?.variations ?? []).reduce(
      (acc, variation) => {
        const pageScrape = pageScrapesByVariation[variation.clientId];
        if (!selectedPlatforms.includes("PINTEREST")) {
          if (variation.pinterestDestinationLink !== null) {
            acc[variation.clientId] = {
              id: variation.id,
              pinterestDestinationLink: null
            };
          }
        } else {
          const pinterestDestinationLink =
            variation.pinterestDestinationLink ?? (pageScrape?.url || null);
          if (variation.pinterestDestinationLink !== pinterestDestinationLink) {
            acc[variation.clientId] = {
              pinterestDestinationLink,
              id: variation.id
            };
          }
        }
        return acc;
      },
      {}
    );

    if (Object.values(pinterestLinkChanges).length > 0) {
      if (!changeset) {
        changeset = { id: content?.id, variations: [] };
      }
      if (!changeset.variations) {
        changeset.variations = [];
      }
      Object.keys(pinterestLinkChanges).forEach(clientId => {
        const { pinterestDestinationLink, id } = pinterestLinkChanges[clientId];
        const existingChanges = (changeset?.variations || []).find(
          v => v.clientId === clientId
        );
        if (existingChanges) {
          existingChanges.pinterestDestinationLink = pinterestDestinationLink;
        } else {
          // Suppress the Flow error here :( The null check above guarantees the
          // changeset and the variations array on it will exist but Flow isn't
          // able to see that
          // $FlowFixMe
          changeset.variations.push({ id, clientId, pinterestDestinationLink });
        }
      });
    }

    // No changes were made to this content, no need to let the server know. Just
    // pretend that they were and do the normal flash messages and/or redirects.
    if (!changeset) {
      if (contentId) {
        return dispatch({ type: "UPDATE_CONTENT_FINISH", content, errors: [] });
      }
      return dispatch({
        type: "CREATE_CONTENT_FINISH",
        content,
        errors: [],
        hasAccountWithoutSchedule: false
      });
    }

    // The api expects the raw rich text entity map in string form. This is
    // easier because GraphQL generally requires you to describe the shape of
    // objects. This is a complex object that for our purposes can be treated
    // like an opaque data type that gets passed along to the editor field.
    if (changeset.variations) {
      changeset = produce(changeset, draft => {
        draft.variations.forEach(v => {
          if (v.rawRichTextEntityMap) {
            v.rawRichTextEntityMap = JSON.stringify(v.rawRichTextEntityMap);
          }
        });
      });
    }

    if (contentId) {
      updateContent({
        variables: { input: changeset }
      });
    } else {
      createContent({
        variables: { input: changeset }
      });
    }
  }

  //#############################
  // Onboarding
  //#############################
  const isConnectAccountsModalOpen =
    onboardingProgress?.addAccount == null && accounts.length === 0;

  function completeOnboardingTooltipStep(step: OnboardingTooltipStep) {
    dispatch({ type: "COMPLETE_ONBOARDING_TOOLTIP_STEP", step });
  }

  function showOnboardingTooltipStep(step: OnboardingTooltipStep) {
    return (
      onboardingProgress?.dismissedAt == null &&
      !isConnectAccountsModalOpen &&
      onboardingProgress?.addContent == null &&
      onboardingTooltipStep === step
    );
  }

  const showAddVariationOnboardingTooltip = showOnboardingTooltipStep(
    "add-variation"
  );
  const showTextFieldOnboardingTooltip = showOnboardingTooltipStep("textfield");
  const showAccountsSelectOnboardingTooltip = showOnboardingTooltipStep(
    "accounts"
  );
  const showCategorySelectOnboardingTooltip = showOnboardingTooltipStep(
    "categories"
  );
  const showSaveContentOnboardingTooltip = showOnboardingTooltipStep("save");

  //#############################
  // Errors and validation
  //#############################
  function handleAcknowledgeRejectionModal() {
    dispatch({ type: "DISMISS_REJECTION_MODAL" });
  }

  //#############################

  const composerProps = {
    ...props,
    accounts,
    categories,
    content,
    mentionSuggestions,
    editorFocus,
    uploadingMedia,
    attachingImage,
    pageScrapesByVariation,
    allowSuggestVariations: allScrapedUrls.length > 0 && !usedSuggestVariations,
    timeZone,
    mobileDevices,
    queuePaused,
    onboardingProgress,
    linkShortenerDomainName,
    loadingComposer,
    loadingContent,
    loadingVariationSuggestions,
    isSetSendAtModalOpen,
    isPersisting: isCreatingContent || isUpdatingContent,
    isEditing,
    isSubmittable,
    isConnectAccountsModalOpen,
    loadedContent,
    variationWarnings,
    serverErrors,
    clientErrors,
    rejectionModalReasons,
    dismissOnboarding,
    suggestionsFailed,
    linkAttachmentRemoved,
    widgetCompletedCreate: isWidget && persistResult === "CREATED",
    onDismissSuggestionsFailed: handleDismissSuggestionsFailed,
    onClearMentionSuggestions: handleClearMentionSuggestions,
    onFocusEditor: handleFocusEditor,
    onBlurEditor: handleBlurEditor,
    onMouseMoveEditor: handleMouseMoveEditor,
    onMouseLeaveEditor: handleMouseLeaveEditor,
    onSelectAccounts: handleSelectAccounts,
    onDeselectAccounts: handleDeselectAccounts,
    onChangeCategory: handleChangeSelectedCategory,
    onChangeVariationText: handleChangeVariationText,
    onChangeVariationFbText: handleChangeVariationFbText,
    onEntityMapChange: handleEntityMapChange,
    onAddVariation: handleAddVariation,
    onDeleteVariation: handleDeleteVariation,
    onAddMention: handleAddMention,
    onSearchSocialMedia: handleSearchSocialMedia,
    onMentionSuggestionWarning: handleMentionSuggestionWarning,
    onPersist: handlePersist,
    onPersistAndSendNow: handlePersistAndSendNow,
    onOpenSetSendAtModal: handleOpenSetSendAtModal,
    onCloseSetSendAtModal: handleCloseSetSendAtModal,
    onFinishSetSendAtModal: handleFinishSetSendAtModal,
    onApprovePersistAndSendNow: handleApprovePersistAndSendNow,
    onApproveAndPersist: handleApproveAndPersist,
    onApproveAndOpenSetSendAtModal: handleApproveAndOpenSetSendAtModal,
    onAttachImage: handleAttachImage,
    onAttachScrapedImage: handleAttachScrapedImage,
    onImageUploadStart: handleImageUploadStart,
    onImageUploadProgress: handleImageUploadProgress,
    onImageUploadError: handleImageUploadError,
    onImageUploadValidationError: handleImageUploadValidationError,
    onImageUploadFinish: handleImageUploadFinish,
    onImagesOrderChange: handleImagesOrder,
    onRemoveImage: handleRemoveImage,
    onAttachVideo: handleAttachVideo,
    onVideoUploadStart: handleVideoUploadStart,
    onVideoUploadProgress: handleVideoUploadProgress,
    onVideoUploadError: handleVideoUploadError,
    onVideoUploadValidationError: handleVideoUploadValidationError,
    onVideoUploadFinish: handleVideoUploadFinish,
    onRemoveVideo: handleRemoveVideo,
    onEnableLinkPreview: handleEnableLinkPreview,
    onDisableLinkPreview: handleDisableLinkPreview,
    onChangePinterestDestinationLink: handleChangePinterestDestinationLink,
    onChangePinterestTitle: handleChangePinterestTitle,
    onChangeUseOnce: handleChangeUseOnce,
    onChangeUseShortLinks: handleChangeUseShortLinks,
    onChangeExpiresAt: handleChangeExpiresAt,
    onChangeSendAt: handleChangeSendAt,
    onSuggestVariations: handleSuggestVariations,
    onSelectPinterestBoard: handleSelectPinterestBoard,
    onChangeInstagramBusinessSetting: handleChangeInstagramBusinessSetting,
    onAcknowledgeRejectionModal: handleAcknowledgeRejectionModal,
    completeOnboardingTooltipStep,
    showAddVariationOnboardingTooltip,
    showTextFieldOnboardingTooltip,
    showAccountsSelectOnboardingTooltip,
    showCategorySelectOnboardingTooltip,
    showSaveContentOnboardingTooltip
  };

  if (loadingComposer) {
    return <Composer {...composerProps} />;
  }

  if (error) {
    return (
      <Composer {...composerProps} error="Uh-oh something went wrong 😿" />
    );
  }

  return <Composer {...composerProps} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetFlash: body => {
    dispatch(createFlash("notice", body));
  }
});

const connector: Connector<{}, Props> = connect(
  () => ({}),
  mapDispatchToProps
);

export const Widget = withRouter(
  connector(
    ({
      location: _l,
      history: _h,
      dispatch: _d,
      match: { params },
      ...restProps
    }) => (
      <ComposerWithState {...restProps} isWidget contentId={params.contentId} />
    )
  )
);

export default withRouter(
  connector(
    ({
      location: _l,
      history: _h,
      dispatch: _d,
      match: { params },
      ...restProps
    }) => <ComposerWithState {...restProps} contentId={params.contentId} />
  )
);
