/* @flow */
import React from "react";

import { colorFromIndex } from "colors";

import SVGPlusIcon from "./icon.svg";

import type { IProps } from "./types.js";
import { Wrapper, Left, Right } from "./styles";

import {
  Post,
  Content,
  Picture,
  Description,
  Category,
  Circle
} from "../PostsModal/Posts/styles";

function colorWithRandom(idx?: number) {
  if (!idx) return null;
  return idx == -1 ? "var(--inky400)" : colorFromIndex(idx);
}

const PostSelectSelector = (props: IProps) => {
  const { showModal, selectedPost, error, readOnly } = props;

  const variation = selectedPost ? selectedPost?.variations[0] : null;
  const category: any = selectedPost ? selectedPost.category : null;
  const image = variation?.images?.length ? variation?.images[0].url : null;

  return selectedPost && variation ? (
    <Post
      title={readOnly ? undefined : "Click to select another content"}
      className={readOnly ? "readOnly" : error ? `error` : `selected`}
      onClick={showModal}
    >
      <Content>
        {image && (
          <Picture>
            <img src={image} alt="post image" />
          </Picture>
        )}
        {variation.displayText && (
          <Description>{variation.displayText}</Description>
        )}
      </Content>
      <Category>
        <Circle color={colorWithRandom(category.colorIndex)} />
        {category.name}
      </Category>
    </Post>
  ) : (
    <Wrapper onClick={showModal}>
      <Left>
        <img src={SVGPlusIcon} alt="Plus icon" />
      </Left>
      <Right>
        <h3>Select a post</h3>
        <p>Schedule an individual post from your library!</p>
      </Right>
    </Wrapper>
  );
};

export default PostSelectSelector;
