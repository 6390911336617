/* @flow */
import csrfToken from "./csrf.js";
import { ApiError } from "./errors";

export { ApiError };

const request = (path: string, options = {}): Promise<*> => {
  const basePath = "/api";
  const opts = {
    method: options.method || "GET",
    credentials: "same-origin",
    ...(options.body ? { body: JSON.stringify(options.body) } : {}),
    headers: {
      Accept: "application/vnd.meetedgar.v2+json",
      "Content-Type": "application/vnd.meetedgar.v2+json",
      "X-CSRF-Token": csrfToken(),
      "X-Authorization": `Bearer ${window.API_KEY}`,
      ...(options.headers ? options.headers : {})
    }
  };

  return new Promise((resolve, reject) => {
    fetch(`${basePath}${path}`, opts).then(response => {
      if (response.status >= 200 && response.status < 300) {
        response
          .json()
          .then(json => resolve(json))
          .catch(err => resolve(err));
      } else {
        response
          .json()
          .then(json =>
            reject(new ApiError(response.statusText, response.status, json))
          )
          .catch(() =>
            reject(new ApiError(response.statusText, response.status, {}))
          );
      }
    });
  });
};

export const findFeed = (feedQueryUrl: string): Promise<*> =>
  request("/rss_finder", {
    method: "POST",
    body: {
      data: {
        type: "rssFinders",
        attributes: { feedQueryUrl }
      }
    }
  });

export const closeRssFeedFoundPrompt = (userId: string): Promise<*> =>
  request("/user", {
    method: "PATCH",
    body: {
      data: {
        type: "users",
        id: userId,
        attributes: {
          showFeedFoundPrompt: false
        }
      }
    }
  });

export const updateSubscription = (id: string): Promise<*> =>
  request("/company/relationships/subscription_plan", {
    method: "PATCH",
    body: {
      data: {
        type: "subscriptionPlans",
        id
      }
    }
  });

export const validateCoupon = (
  couponCode: string,
  planId: string
): Promise<*> =>
  request("/coupon_validation", {
    method: "POST",
    body: {
      data: {
        type: "couponValidations",
        attributes: { couponCode, planId }
      }
    }
  });

export const subscribeCompany = (
  subscriptionPlanId: string,
  paymentToken: string,
  couponCode: ?string
): Promise<*> =>
  request("/company", {
    method: "PATCH",
    body: {
      data: {
        type: "companies",
        attributes: {
          subscriptionPlanId
        }
      },
      meta: {
        paymentToken,
        couponCode
      }
    }
  });

export const updatePaymentToken = async (paymentToken: string): Promise<*> =>
  await request("/company", {
    method: "PATCH",
    body: {
      data: {},
      meta: {
        paymentToken
      }
    }
  });

export const dismissInformationBox = (name: string): Promise<*> =>
  request(`/information_boxes/${name}`, {
    method: "DELETE"
  });
