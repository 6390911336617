/* @flow */
import { get } from "lodash";
import type { Action } from "types";

export type OnboardingFlag = null | string;

export type OnboardingStep =
  | "addAccount"
  | "viewCategories"
  | "addContent"
  | "addTimeslots"
  | "importContent";

export type OnboardingData = {
  id: ?string,
  attributes: {
    addAccount: OnboardingFlag,
    viewCategories: OnboardingFlag,
    addContent: OnboardingFlag,
    addTimeslots: OnboardingFlag,
    importContent: OnboardingFlag,
    dismissedAt: OnboardingFlag
  }
};

export type State = {
  data: OnboardingData
};

const onboarding: State = get(window, "INITIAL_STORE_STATE.onboarding");
export const initialState = onboarding || {
  data: {
    attributes: {
      addAccount: null,
      viewCategories: null,
      addContent: null,
      addTimeslots: null,
      importContent: null,
      // This is set in initial state to prevent display for users without an onboarding_progress
      dismissedAt: Date.now()
    }
  }
};

const onboardingReducer = (
  state: State = initialState,
  action: Action
): State => {
  switch (action.type) {
    default:
      return state;
  }
};

export default onboardingReducer;
