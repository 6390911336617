/* @flow */
import React from "react";
import VideoThumbnailModal from "components/VideoThumbnail/VideoThumbnailModal";
import { Link } from "links";
import Icon from "icons/Icon";
import styles from "./index.css";

export type VideoData = {
  url: string
};

type Props = {
  value: VideoData,
  videos: VideoData[],
  showModal: boolean,
  onClickShowModal: () => void,
  onClickHideModal: () => void,
  size: number,
  imagesLength?: number
};

const Video = (props: Props) => {
  const {
    value: { url },
    showModal,
    onClickShowModal,
    onClickHideModal,
    size,
    videos,
    imagesLength
  } = props;

  return (
    <div
      className={styles.root}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      <Link onClick={onClickShowModal}>
        <div
          className={styles.overlay}
          style={{ height: `${size}px`, width: `${size}px` }}
        >
          <Icon
            type="play-circle-o"
            className={styles.playIcon}
            style={{ lineHeight: `${size}px` }}
          />
        </div>
        <video
          className={styles.video}
          style={{ height: `${size}px`, width: `${size}px` }}
        >
          <source src={url} />
        </video>
      </Link>
      {videos.length > 1 && (
        <span className={styles.count}>
          +{videos.length + (imagesLength || 0) - 1}
        </span>
      )}
      {showModal && (
        <VideoThumbnailModal videoUrl={url} onClose={onClickHideModal} />
      )}
    </div>
  );
};

export default Video;
