import styled from "styled-components";

export const CheckboxLine = styled.div`
  background: #cccccc;
  height: 100%;
  margin: -6px 0px 0 9.5px;
  position: absolute;
  width: 1px;
  z-index: -1;

  &.readOnly {
    opacity: 0.4;
  }
`;
