import React from "react";

import tooltipper from "behavioral/tooltipper";
import Link from "components/common/links/Link";

import styles from "./index.css";
import SVGCanva from "./canva.svg";
import { Label } from "./styles";

export const TooltipBody = () => (
  <div>
    <strong>How it works:</strong>
    <ul>
      <li>Click to open Canva</li>
      <li>Create/open your design</li>
      <li>Click at the ... on the upper right corner</li>
      <li>Search for "Edgar"</li>
      <li>Connect your account</li>
      <li>Publish your Design</li>
      <li>Manage all your content through Meet Edgar</li>
    </ul>
  </div>
);

const Subtitle = () => (
  <span>
    <br />
    (With our Publish extension)
  </span>
);

const CanvaButton = () => {
  return (
    <Link url="https://canva.com" target="_blank" className={styles.link}>
      <Label htmlFor="canvaButton">
        <img src={SVGCanva} alt="use Canva" role="button" />
        <span>Create with canva</span>
      </Label>
    </Link>
  );
};

export default tooltipper(TooltipBody, {
  title: "Create from Canva",
  Subtitle
})(CanvaButton);
