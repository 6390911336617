/* @flow */
import React from "react";

import CloseButton from "buttons/CloseButton";

import IMGLeft from "./img/left-image.png";
import SVGArrowRight from "./img/arrow-right.svg";

import useSwitchToNew from "./use";
import {
  Wrapper,
  LeftImage,
  RightBlock,
  Container,
  Left,
  Button
} from "./styles";

import AreYouSure from "./AreYouSure";

const SwitchToNew = () => {
  const {
    modalVisible,
    showModal,
    hideModal,
    loading,
    handleNewUI,
    hideNewUIBanner,
    bannerVisible
  } = useSwitchToNew();

  return bannerVisible ? (
    <>
      <Wrapper>
        <CloseButton onClick={showModal} />
        <LeftImage>
          <img src={IMGLeft} alt="left image" />
        </LeftImage>
        <Container>
          <Left>
            <h2>New layout available!</h2>
            <p>
              MeetEdgar is getting a makeover. Check it out, you can always
              switch back.
            </p>
          </Left>
          <Button onClick={handleNewUI} className={loading ? "disabled" : ""}>
            Try out the new MeetEdgar view!
            <img src={SVGArrowRight} alt="arrow right" />
          </Button>
        </Container>
        <RightBlock />
      </Wrapper>

      {modalVisible && (
        <AreYouSure hideModal={hideModal} hideNewUIBanner={hideNewUIBanner} />
      )}
    </>
  ) : null;
};

export default SwitchToNew;
