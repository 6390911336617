// @flow
import React, { Component } from "react";
import moment from "moment-timezone";

import { getFutureDateInTimeZone } from "util";
import { NewDateTimeField, Toggle } from "forms";

import styles from "./index.css";

type Props = {
  value: ?string,
  timeZone: string,
  isEditing: boolean,
  onChange: (expiresAt: ?string) => void
};

// eslint-disable-next-line max-len

export default class ExpiresAtField extends Component<Props> {
  handleToggleCheckbox = (value: boolean): void => {
    this.props.onChange(value ? this.getDefaultExpiresAt() : null);
  };

  getDefaultExpiresAt = () =>
    getFutureDateInTimeZone({ months: 3 }, this.props.timeZone);

  render() {
    const { value, timeZone, isEditing, onChange } = this.props;
    const timeZoneAbbr = moment()
      .tz(timeZone)
      .zoneAbbr();
    const captionText = `All times are in ${timeZoneAbbr}. Edgar will no longer post this content (including variations) after the selected time. It will remain in your library if you decide to reuse or edit it in the future.`;

    return (
      <div>
        <Toggle
          value={!!value}
          label={<b>Expire content at a specific time and date</b>}
          onChange={this.handleToggleCheckbox}
        />
        <div className={styles.timeInputFieldContainer}>
          <p>{captionText}</p>
          <NewDateTimeField
            value={value}
            defaultValue={this.getDefaultExpiresAt()}
            timeZone={timeZone}
            enableResetLink={isEditing}
            onChange={onChange}
            textLeft="Expires at"
            textCenter="on"
          />
        </div>
      </div>
    );
  }
}
