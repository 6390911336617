/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation updateUtmParameters($isEnabled: Boolean!) {
    updateUtmParameters(isEnabled: $isEnabled) {
      errors
    }
  }
`;
