/* eslint-disable flowtype/require-valid-file-annotation */
import React from "react";
import { SCRAPE_STATUSES } from "constants";
import LoadingIcon from "icons/LoadingIcon";
import ByLine from "./ByLine";
import Text from "./Text";
import Media from "./Media";
import LinkPreview from "./LinkPreview";
import styles from "./index.css";

import type { Account } from "types";

import type { loadContent_content_variations as VariationData } from "graphql-types/loadContent";
import type { scrapeUrl_scrapeUrl_pageScrape as PageScrapeData } from "graphql-types/scrapeUrl";

type Props = {
  account: Account,
  variation: VariationData,
  pageScrape: PageScrapeData
};

const renderLinkPreview = (variation, pageScrape) => {
  let image;

  if (pageScrape.url && pageScrape.status === SCRAPE_STATUSES.OK) {
    if (pageScrape?.images?.length > 0) {
      image = pageScrape.images[0].src;
    }
    return (
      <LinkPreview
        title={pageScrape.title}
        description={pageScrape.description}
        url={pageScrape.url}
        destinationUrl={pageScrape.url}
        image={image}
      />
    );
  } else if (pageScrape.status === SCRAPE_STATUSES.WAITING) {
    return (
      <div className={styles.root}>
        <LoadingIcon /> Checking for LinkedIn link preview…
      </div>
    );
  }

  return null;
};

const LinkedinPost = ({ account, pageScrape, variation }: Props) => {
  let omitTrailingLinks = false;

  const hasAttachment =
    variation.images.length > 0 || variation.videos.length > 0;

  return (
    <div className={styles.root}>
      <ByLine value={account} />
      <Text
        value={variation.text}
        omitTrailingLinks={omitTrailingLinks}
        rawRichTextEntityMap={variation.rawRichTextEntityMap}
      />
      {hasAttachment && (
        <Media images={variation.images} videos={variation.videos} />
      )}
      {!hasAttachment && pageScrape && renderLinkPreview(variation, pageScrape)}
    </div>
  );
};

export default LinkedinPost;
