/* @flow */
import React from "react";
import Information from "components/common/layout/Information";
import Link from "components/common/links/Link";

import styles from "./index.css";

type Props = {
  canAddCategory: boolean
};

const CanAddCategories = () => (
  <>
    Categories connect your content to your schedule. Create new categories to
    add your content to, or use the default options!
    <br />
    Need some inspiration?{" "}
    <Link
      url="https://help.meetedgar.com/edgar-s-features-and-best-practices/organize-your-content-with-edgar-s-categories/categories-best-practices-custom-categories-for-custom-posting"
      target="_blank"
    >
      See how Edgar uses custom categories
    </Link>
    .
  </>
);

const CannotAddCategories = () => (
  <>
    Edgar provides 4 default categories that connect your content to your
    schedule and keep you organized.
    <br />
    Want to create custom categories for more options?{" "}
    <Link url="/settings">Upgrade</Link>.
  </>
);

const CategoryInformation = ({ canAddCategory }: Props) => {
  return (
    <Information
      className={styles.categoryInformation}
      name="categoryInformation"
    >
      {canAddCategory ? <CanAddCategories /> : <CannotAddCategories />}
    </Information>
  );
};

export default CategoryInformation;
