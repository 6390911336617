//
// Instagram appears to have a surprisingly complex algorithm
// for displaying its pagination dots - one guy tried to dissect
// it here (for a UX prototyping app) if you're interested:
//
// https://blog.prototypr.io/using-conditions-in-framer-to-replicate-instagrams-pagination-dots-898100b976d1
//
// Among other complexities, their algorithm takes into account which
// direction you're swiping. Anyway, we're not going to try to do all that.
// There are a maximum of 10 images, and all of this is only applicable
// if there are two or more images, so it turns out there are 59 different
// ways the dots can be rendered depending on the number of images
// and which one is active. So instead of concocting a clever algorithm
// to simulate Instagram, we're gonna keep one giant array and eyeball
// what looks approximately like what Instagram does. Good enough! You'll
// note that building these well usually results in a "symmetry" as you
// progress through the active images, so at the expense of some additional
// complexity you could cut these tables in half and make the table smaller,
// but meh.
//
// ac = "active" dot (implies large)
// lg = large dot
// md = medium dot
// sm = small dot
//
// The array is dots[total_number_of_images][which_image_is_active] = list of each dot type
//
// - md 2018.08.23
//
export const dots = [
  // 0 - pointless
  [],

  // 1 - pointless
  [],

  // 2
  [["ac", "lg"], ["lg", "ac"]],

  // 3
  [["ac", "lg", "lg"], ["lg", "ac", "lg"], ["lg", "lg", "ac"]],

  // 4
  [
    ["ac", "lg", "lg", "lg"],
    ["lg", "ac", "lg", "lg"],
    ["lg", "lg", "ac", "lg"],
    ["lg", "lg", "lg", "ac"]
  ],

  // 5
  [
    ["ac", "lg", "lg", "lg", "lg"],
    ["lg", "ac", "lg", "lg", "lg"],
    ["lg", "lg", "ac", "lg", "lg"],
    ["lg", "lg", "lg", "ac", "lg"],
    ["lg", "lg", "lg", "lg", "ac"]
  ],

  // 6
  [
    ["ac", "lg", "lg", "lg", "lg", "md"],
    ["lg", "ac", "lg", "lg", "lg", "md"],
    ["lg", "lg", "ac", "lg", "lg", "lg"],
    ["lg", "lg", "lg", "ac", "lg", "lg"],
    ["md", "lg", "lg", "lg", "ac", "lg"],
    ["md", "lg", "lg", "lg", "lg", "ac"]
  ],

  // 7
  [
    ["ac", "lg", "lg", "lg", "md", "sm"],
    ["lg", "ac", "lg", "lg", "lg", "md"],
    ["lg", "lg", "ac", "lg", "lg", "md"],
    ["md", "lg", "lg", "ac", "lg", "md"],
    ["md", "lg", "lg", "lg", "ac", "lg"],
    ["sm", "md", "lg", "lg", "ac", "lg"],
    ["sm", "md", "lg", "lg", "lg", "ac"]
  ],

  // 8
  [
    ["ac", "lg", "lg", "lg", "md", "sm"],
    ["lg", "ac", "lg", "lg", "lg", "md"],
    ["lg", "lg", "ac", "lg", "lg", "md"],
    ["md", "lg", "ac", "lg", "lg", "md"],
    ["md", "lg", "lg", "ac", "lg", "md"],
    ["md", "lg", "lg", "ac", "lg", "lg"],
    ["md", "lg", "lg", "lg", "ac", "lg"],
    ["sm", "md", "lg", "lg", "lg", "ac"]
  ],

  // 9
  [
    ["ac", "lg", "lg", "lg", "md", "md", "sm"],
    ["lg", "ac", "lg", "lg", "md", "md", "sm"],
    ["md", "ac", "lg", "lg", "lg", "md", "sm"],
    ["sm", "md", "ac", "lg", "lg", "md", "sm"],
    ["sm", "md", "lg", "ac", "lg", "md", "sm"],
    ["sm", "md", "lg", "lg", "ac", "md", "sm"],
    ["sm", "md", "lg", "lg", "lg", "ac", "md"],
    ["sm", "md", "md", "lg", "lg", "ac", "lg"],
    ["sm", "md", "md", "lg", "lg", "lg", "ac"]
  ],

  // 10
  [
    ["ac", "lg", "lg", "lg", "md", "md", "sm"],
    ["lg", "ac", "lg", "lg", "md", "md", "sm"],
    ["md", "ac", "lg", "lg", "lg", "md", "sm"],
    ["md", "md", "ac", "lg", "lg", "md", "sm"],
    ["sm", "md", "lg", "ac", "md", "md", "sm"],
    ["sm", "md", "md", "ac", "lg", "md", "sm"],
    ["sm", "md", "lg", "lg", "ac", "md", "md"],
    ["sm", "md", "lg", "lg", "lg", "ac", "md"],
    ["sm", "md", "md", "lg", "lg", "ac", "lg"],
    ["sm", "md", "md", "lg", "lg", "lg", "ac"]
  ]
];
