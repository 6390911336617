/* @flow */
import React from "react";
import withFilter from "behavioral/withFilter";
import Base, { type Props as SelectProps, type OptionData } from "forms/Select";

import type { ContentFilter } from "types";

export type Props = SelectProps & {
  filter: ContentFilter,
  filterKey: string,
  onFilterPatch: (filterChange: ContentFilter) => void
};

export const FilterSelect = ({
  filter,
  filterKey,
  onFilterPatch,
  options,
  placeholder = "Choose",
  ...rest
}: Props) => {
  const handleChange = ({ value }: OptionData) => {
    onFilterPatch({ [filterKey]: value });
  };
  const value = filter[filterKey];

  if (value) {
    options = [{ label: "Clear filter", value: null }].concat(options);
  }

  const disabled = options.length === 0;
  const selectedOption = options.find(o => o.value === value) || null;

  return (
    <Base
      {...rest}
      disabled={disabled}
      options={options}
      value={selectedOption}
      placeholder={placeholder}
      onChange={handleChange}
    />
  );
};

export default withFilter()(FilterSelect);
