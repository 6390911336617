/* @flow */
export const csrfParam = (): string => {
  const meta = (document.querySelectorAll("meta[name=csrf-param]")[0]: any);
  if (meta) {
    return meta.content;
  }
  return "";
};

export const csrfToken = (): string => {
  const meta = (document.querySelectorAll("meta[name=csrf-token]")[0]: any);
  if (meta) {
    return meta.content;
  }
  return "";
};

export default csrfToken;
