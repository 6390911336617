/* @flow */
import * as React from "react";

// TODO: Needs specs. If it gets used again that should definitely happen.
const useScrollToLastChild = (
  children: React.Node,
  disableAutoScroll: boolean
) => {
  const [childCount, setChildCount] = React.useState(0);
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const lastChildRef = React.useRef<HTMLDivElement | null>(null);
  const nextChildCount = React.Children.count(children);

  React.useEffect(() => {
    setChildCount(nextChildCount);
    if (nextChildCount <= childCount || disableAutoScroll) {
      return;
    }

    if (!containerRef.current || !lastChildRef.current) {
      return;
    }

    const container = containerRef.current;
    const lastChild = lastChildRef.current;

    const lastChildEnd = lastChild.offsetLeft + lastChild.scrollWidth;
    const containerMaxVisible = container.scrollLeft + container.offsetWidth;
    // If its right most edge is not visible
    if (lastChildEnd > containerMaxVisible) {
      // Scroll the container to the point where that edge is just visible
      container.scrollLeft = lastChildEnd - container.offsetWidth;
    }
  }, [nextChildCount, childCount, disableAutoScroll]);

  return { containerRef, lastChildRef };
};

export default useScrollToLastChild;
