/* @flow */
import React from "react";

import useUISwitch from "./use";
import { Wrapper, Button } from "./styles";

const UISwitch = () => {
  const { loading, toggleNewUI } = useUISwitch();

  return (
    <Wrapper className={loading ? "loading" : ""} onClick={toggleNewUI}>
      <h3>You're using the original MeetEdgar view</h3>
      <Button>
        Try out the new MeetEdgar view
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M3.333 8h9.334M8 3.333L12.667 8 8 12.667"
          />
        </svg>
      </Button>
    </Wrapper>
  );
};

export default UISwitch;
