/* @flow */
import React from "react";

import AccountProviderIcon from "icons/AccountProviderIcon";

import type { getPosts_posts_data } from "graphql-types/getPosts";

import styles from "./index.css";

type Props = {
  className?: string,
  post: getPosts_posts_data
};

const Account = ({ className = "", post }: Props) => {
  const provider = post.account.provider;
  const showInstagramBusinessCaption = provider === "INSTAGRAM_BUSINESS";

  return (
    <>
      <div className={`${styles.account} ${className}`}>
        <div className={styles.accountIcon}>
          <AccountProviderIcon provider={provider} small />{" "}
        </div>
        <div className={styles.accountName}>{post.account.name}</div>
      </div>
      {showInstagramBusinessCaption && (
        <div className={styles.instagramBusinessCaption}>
          {post.content.sendMobileReminder
            ? "Mobile reminder"
            : "Direct to feed"}
        </div>
      )}
    </>
  );
};

export default Account;
