// @flow
import React from "react";
import RejectionModal from "../common/RejectionModal";

type MediaRejectionReasons = { [key: string]: string[] };

type Props = {
  reasons: MediaRejectionReasons,
  onAcknowledge: () => void
};

const SUB_TITLE = `
  Please remove the incompatible account(s) or optimize the media to meet
  the following requirements:
`;

const InvalidMedia = ({ reasons, onAcknowledge }: Props) => (
  <RejectionModal
    title="The media cannot be added because it is incompatible with one or more accounts."
    subTitle={SUB_TITLE}
    groupedReasons={reasons}
    groupedReasonsLabels={["File name", "Requirements"]}
    onAcknowledge={onAcknowledge}
  />
);

export default InvalidMedia;
