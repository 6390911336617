/* @flow */
import { get } from "lodash";
import { FINISH_DISMISS_INFORMATION_BOX } from "actions/informationBoxes";
import type { Action } from "types";

type CompanyAttributes = {
  timeZone: string
};

type Attributes = {
  firstName: string,
  lastName: string,
  email: string,
  showFeedFoundPrompt: boolean,
  isAdmin: boolean,
  dismissedInformationBoxes: { [string]: string },
  company: CompanyAttributes
};

export type UserData = {
  id: string,
  type: "users",
  attributes: Attributes
};

export type State = {
  data: UserData[]
};

export const initialState: State = {
  data: get(window, "INITIAL_STORE_STATE.users.data", [])
};

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case FINISH_DISMISS_INFORMATION_BOX: {
      return {
        data: [
          {
            ...state.data[0],
            attributes: {
              ...state.data[0].attributes,
              dismissedInformationBoxes:
                action.payload.attributes.dismissedInformationBoxes
            }
          }
        ]
      };
    }

    default: {
      return state;
    }
  }
};
