// @flow
import React from "react";
import styles from "./index.css";

import type { Account } from "types";

type Props = {
  value: Account
};

const ByLine = ({ value: { image, name, nickname } }: Props) => (
  <div>
    <img className={styles.image} src={image} alt="avatar" />
    <div className={styles.byLine}>
      <strong className={styles.name}>
        <a>{name}</a>
      </strong>{" "}
      <small className={styles.nickname}>@{nickname}</small>{" "}
      <small className={styles.time}>
        {" "}
        <a>now</a>
      </small>
    </div>
  </div>
);

export default ByLine;
