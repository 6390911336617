import React from "react";

import { useQuery } from "@apollo/react-hooks";

import Button from "buttons/Button";
import IconPLUS from "components/common/images/btn-plus-icon.png";

import ModalAddAccounts from "./ModalAddAccounts";
import GET_USER_DETAILS from "queries/getUserDetails";

import useAccountsUsage from "./use";
import { Title, Info, Bar, Fill, PlusIcon } from "./styles";
import LoadingIcon from "icons/LoadingIcon";
import styles from "./index.css";

export const AccountsUsage = () => {
  const { modal, closeModal, openAddAccounts } = useAccountsUsage();
  const { data, loading } = useQuery(GET_USER_DETAILS);
  const subscriptionPlan = data?.user?.company?.subscriptionPlan;
  const percentage = Math.round(
    (data?.user?.company?.totalAccounts /
      (subscriptionPlan?.accountLimit +
        data?.user?.company?.additionalAccounts)) *
      100
  );

  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }

  return (
    <>
      <div>
        <Title>
          <span>Social Media Accounts</span>
          <Info>
            Currently Using {data?.user?.company?.totalAccounts} of{" "}
            {subscriptionPlan?.accountLimit +
              data?.user?.company?.additionalAccounts}{" "}
            Accounts
          </Info>
        </Title>
        <Bar>
          <Fill percentage={percentage} />
        </Bar>

        {!data?.user?.company?.shopifyOrigin && (
          <Button type="light" onClick={openAddAccounts}>
            <PlusIcon src={IconPLUS} />
            Social Accounts
          </Button>
        )}
      </div>

      {modal === "add-accounts" ? (
        <ModalAddAccounts close={closeModal} />
      ) : null}
    </>
  );
};

export default AccountsUsage;
