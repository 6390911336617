/* @flow */
import React from "react";

import usePostSelect from "./use";
import type { IProps } from "./types.js";
import { Error } from "./styles";

import Selector from "./Selector";
import PostsModal from "./PostsModal";

const PostSelect = (props: IProps) => {
  const { selectedPost, errors } = props;
  const { modalVisible, showModal, hideModal, handlePost } = usePostSelect(
    props
  );

  const readOnly = errors.previousDate || errors.nonrecurringPastDate;

  return (
    <>
      <Selector
        showModal={readOnly ? undefined : showModal}
        selectedPost={selectedPost}
        error={!!errors?.postId}
        readOnly={readOnly}
      />

      {!readOnly && modalVisible ? (
        <PostsModal hideModal={hideModal} selectPost={handlePost} />
      ) : null}

      {errors?.postId && (
        <Error>
          You need to select a content to schedule an individual post.
        </Error>
      )}
    </>
  );
};

export default PostSelect;
