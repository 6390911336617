/* @flow */
/* global EventHandler */
import React from "react";
import Icon from "icons/Icon";
import styles from "./index.css";

const RemoveButton = ({ onClick }: { onClick?: EventHandler }) => (
  <Icon type="times" className={styles.removeButton} onClick={onClick} />
);

export default RemoveButton;
