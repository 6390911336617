/* @flow */
import React from "react";

import type { IProps } from "./types.js";

import { Wrapper, Reply } from "./styles";

const CommentActions = ({ showWriteReply, toggleWriteReply }: IProps) => {
  return (
    <Wrapper>
      <Reply onClick={toggleWriteReply}>
        <span>{showWriteReply ? "Cancel reply" : "Reply"}</span>
      </Reply>
    </Wrapper>
  );
};

export default CommentActions;
