import styled from "styled-components";

export const Wrapper = styled.div`
  flex: 1;
  margin: 15px 0 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const Container = styled.div`
  @media (min-width: 768px) {
    height: 807px;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  justify-content: center;
  height: 61px;
  padding: 25px 10px 40px;

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 10px;
  }
`;

export const HButton = styled.div`
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 43px;
  justify-content: center;
  margin-right: 10px;
  padding: 0 10px;

  &.active,
  &:hover&:not(.disabled) {
    background-color: rgba(130, 197, 203, 0.25);
    color: #4d948f;
    cursor: default;
  }

  &.disabled {
    cursor: not-allowed;
    font-style: italic;
    opacity: 0.4;
  }
`;

export const EmbedWrapper = styled.div`
  margin: 15px auto -60px;
  max-width: 440px:
  padding: 10px;
  position: relative;
  text-align: center;

  * {
    margin-left: auto;
    margin-right: auto;
  }

  iframe {
    border: none !important;
    margin: 0 auto !important;
  }

  &.loading {
    margin: 0 auto;

    > * {
      display: none;
    }
  }
`;

export const WhiteBlock = styled.div`
  background: #fff;
  bottom: 10px;
  height: 72px;
  position: absolute;
  width: 100%;
  z-index: 1;
`;

export const Loading = styled.div`
  align-items: center;
  display: flex;
  padding: 10px;

  img {
    height: 18px;
    margin: 0 0 0 5px;
  }
`;

export const LoadingEmbed = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100%;

  img {
    height: 18px;
  }
`;
