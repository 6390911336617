// @flow
import React from "react";
import { Modal } from "layout/modals";
import { Button } from "buttons";
import SendAtField from "components/common/SendAtField";
import styles from "./index.css";

type Props = {
  sendAt: ?string,
  timeZone: string,
  showWarning: boolean,
  onChangeSendAt: (sendAt: ?string) => void,
  onCancel: () => void,
  onRemoveSendAt: () => void,
  onUpdateSendAt: () => void
};

const UpdateSendAtModal = ({
  sendAt,
  timeZone,
  showWarning,
  onChangeSendAt,
  onCancel,
  onRemoveSendAt,
  onUpdateSendAt
}: Props) => (
  <Modal
    title="Update scheduled send"
    footer={
      <div className={styles.footer}>
        <div className={styles.secondaryActions}>
          <Button type="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="secondary-negative" onClick={onRemoveSendAt}>
            Remove
          </Button>
        </div>
        <Button type="primary" loading={false} onClick={onUpdateSendAt}>
          Update
        </Button>
      </div>
    }
    onClose={onCancel}
  >
    <SendAtField
      value={sendAt}
      timeZone={timeZone}
      showWarning={showWarning}
      isEditing
      onChange={onChangeSendAt}
    />
  </Modal>
);

export default UpdateSendAtModal;
