/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation createAiCharge($quantity: Int!) {
    createAiCharge(quantity: $quantity) {
      response {
        message
        success
        credits
      }
    }
  }
`;
