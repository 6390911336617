import { useQuery } from "@apollo/react-hooks";
import GET_USER_DETAILS from "queries/getUserDetails";

const ExtraPriceForPLan = () => {
  const { data } = useQuery(GET_USER_DETAILS);
  const planName = data?.user?.company?.subscriptionPlan?.stripeId;

  const newEddieMonth =
    planName === "eddie_lite_monthly_plan_nt" ||
    planName === "eddie_lite_monthly_plan" ||
    planName === "eddie" ||
    planName === "eddie_nt";

  const newEddieAnnual =
    planName === "eddie_lite_annual_plan" ||
    planName === "eddie_lite_annual_plan_nt" ||
    planName === "eddie_annual" ||
    planName === "eddie_annual_nt";

  const edgarMonth =
    planName === "edgar_monthly_plan" ||
    planName === "edgar_monthly_plan_nt" ||
    planName === "edgar_49s" ||
    planName === "edgar_49s_nt";

  const edgarAnnual =
    planName === "edgar_annual_plan" ||
    planName === "edgar_annual_plan_nt" ||
    planName === "edgar_annual_539" ||
    planName === "edgar_annual_539_nt";

  let additionalPrice = 0;

  if (newEddieMonth) {
    additionalPrice = 4.99;
  } else if (newEddieAnnual) {
    additionalPrice = 47.88;
  } else if (edgarMonth) {
    additionalPrice = 2.99;
  } else if (edgarAnnual) {
    additionalPrice = 23.88;
  }

  return { additionalPrice };
};

export default ExtraPriceForPLan;
