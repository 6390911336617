/* @flow */
import * as React from "react";
import tooltipper from "behavioral/tooltipper";
import { Button } from "buttons";
import { Caption } from "typography";
import styles from "./index.css";

type Props = {
  width: number,
  height: number,
  disabled: boolean,
  disabledMessage: string,
  onAttach: () => void
};

export const AddButton = ({
  onClick,
  disabled
}: {
  onClick: () => void,
  disabled: boolean
}) => (
  <Button
    type="primary"
    small
    disabled={disabled}
    enforceMinWidth={false}
    onClick={onClick}
  >
    Add
  </Button>
);

const tipBody = (disabledMessage: string) => () => (
  <div>{disabledMessage || "Remove attached media to add."}</div>
);

export const disabledButton = (disabledMessage: string) =>
  tooltipper(tipBody(disabledMessage))(AddButton);

const MediaOverlay = React.memo<Props>(
  ({ width, height, disabled, disabledMessage, onAttach }: Props) => (
    <div className={styles.root}>
      {disabled ? (
        React.createElement(disabledButton(disabledMessage), {
          disabled,
          onClick: onAttach
        })
      ) : (
        <AddButton disabled={disabled} onClick={onAttach} />
      )}
      <Caption className={styles.dimensions}>
        {width} x {height}px
      </Caption>
    </div>
  )
);

export default MediaOverlay;
