import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 35px 0;

  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ContentSentWrapper = styled.div`
  @media (min-width: 768px) {
    width: 49%;
  }

  @media (min-width: 1024px) {
    flex: 1;
    width: auto;
  }
`;

export const TotalClicksWrapper = styled.div`
  margin: 20px 0;

  @media (min-width: 768px) {
    margin: 0 0 0 2%;
    width: 49%;
  }

  @media (min-width: 1024px) {
    flex: 1;
    margin: 0 20px;
    width: auto;
  }
`;

export const ImpressionsWrapper = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    margin: 20px 0 0;
  }

  @media (min-width: 1024px) {
    flex: 1;
    margin: 0;
    width: auto;
  }
`;

export const Item = styled.div`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  padding: 15px;

  @media (min-width: 768px) {
    padding: 20px;
  }
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  justify-content: space-between;

  div {
    align-items: center;
    display: flex;

    img {
      margin-right: 5px;
    }
  }

  > img {
    cursor: help;
  }
`;

export const Content = styled.div`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 26px 0 0;
`;

export const Data = styled.div``;

export const Value = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
`;

export const Increase = styled.div`
  align-items: center;
  background: #eaf7e8;
  border-radius: 20px;
  color: #28b116;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  padding: 4px 8px;

  img {
    margin-left: 2px;
  }
`;

export const Decrease = styled(Increase)`
  background: #fef7f6;
  color: #f04438;
`;

export const LastWeek = styled.div`
  color: #667085;
  font-size: 13px;
  margin-left: 4px;
`;

export const Graph = styled.div`
  margin-left: 2.5px;
  text-align: right;
`;

export const Error = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  height: 52px;
  justify-content: center;
  margin: 0 0 22px;
  opacity: 0.4;
`;
