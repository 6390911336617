/* @flow */
import { useState } from "react";
import invariant from "invariant";

import type { Props } from "./types.js";

const useImage = (props: Props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleRemove = () => {
    const { onRemove } = props;
    invariant(typeof onRemove === "function", "Expect onRemove to be function");
    onRemove();
  };

  const handleEdit = () => {
    const { onEdit } = props;
    invariant(typeof onEdit === "function", "Expect onEdit to be function");
    onEdit();
  };

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  return { handleRemove, handleEdit, modalVisible, showModal, hideModal };
};

export default useImage;
