import React from "react";
import { Modal } from "components/common/layout/modals";
import { Button } from "components/common/buttons";
import { Body } from "typography";
import styles from "./index.css";

type Props = {
  onClose: () => void
};

const OnboardingCompleteDialog = ({ onClose }: Props) => (
  <Modal className={styles.root} onClose={onClose}>
    <h3>
      Congrats!
      <br />
      Edgar's ready to go!
    </h3>
    <Body>
      Check out your Queue to see what Edgar plans to publish. Come back to the
      Schedule to add or edit your time slots as you continue to fill your
      Library.{" "}
    </Body>
    <Button
      type="primary"
      href="/upcoming_posts#party"
      className={styles.button}
    >
      See your queue!
    </Button>
  </Modal>
);

export default OnboardingCompleteDialog;
