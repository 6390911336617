/* @flow */
import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import useImpressions from "./use";
import type { IProps } from "./types.js";

import SVGImpressions from "../icons/impressions.svg";
import SVGInfo from "../icons/info.svg";
import SVGArrowGreen from "../icons/arrow-green.svg";
import SVGArrowRed from "../icons/arrow-red.svg";
import SVGRedChart from "../icons/red-chart.svg";
import SVGGreenChart from "../icons/green-chart.svg";

import {
  Item,
  Title,
  Content,
  Data,
  Value,
  Footer,
  Increase,
  Decrease,
  LastWeek,
  Graph,
  Error
} from "../styles";

const Impressions = (props: IProps) => {
  const { selectedAccount } = props;
  const { loading, data } = useImpressions(props);

  const tooltipAccount = !selectedAccount
    ? " <b>Note:</b> this data is currently only available for Facebook."
    : "";

  return (
    <Item>
      {loading ? (
        <>
          <Skeleton height={28} />
          <SkeletonTheme color="#fff" highlightColor="#fff">
            <Skeleton height={10} />
          </SkeletonTheme>
          <Skeleton height={60} />
          <Skeleton height={28} />
        </>
      ) : (
        <>
          <Title>
            <div>
              <img src={SVGImpressions} alt="icon" />
              Impressions
            </div>
            <img
              src={SVGInfo}
              alt="info"
              data-html={true}
              data-for="dashboard-tooltip"
              data-tip={`Impressions is the total number of times your content was displayed during the selected date range.${tooltipAccount}`}
            />
          </Title>

          <Content>
            {data ? (
              <>
                <Data>
                  <Value>{data.cardValue}</Value>
                  <Footer>
                    {parseFloat(data.lastPeriodTrend) >= 0 ? (
                      <Increase>
                        {data.lastPeriodTrend}%{" "}
                        <img src={SVGArrowGreen} alt="increase" />
                      </Increase>
                    ) : (
                      <Decrease>
                        {data.lastPeriodTrend}%{" "}
                        <img src={SVGArrowRed} alt="decrease" />
                      </Decrease>
                    )}
                    <LastWeek>vs previous period</LastWeek>
                  </Footer>
                </Data>
                <Graph>
                  {parseFloat(data.lastPeriodTrend) >= 0 ? (
                    <img src={SVGGreenChart} alt="green chart" />
                  ) : (
                    <img src={SVGRedChart} alt="red chart" />
                  )}
                </Graph>
              </>
            ) : (
              <Error>Data could not be loaded. Try again later.</Error>
            )}
          </Content>
        </>
      )}
    </Item>
  );
};

export default Impressions;
