/* @flow */
import React from "react";
import Indicator from "./Indicator";
import { dots } from "./dots";
import styles from "./index.css";

type Props = {
  itemCount: number,
  activeItem: number
};

const Paginator = ({ itemCount, activeItem }: Props) => (
  <div className={styles.root}>
    {dots[itemCount][activeItem].map((dot, index) => (
      <Indicator key={index} type={dot} />
    ))}
  </div>
);

export default Paginator;
