/* @flow */
/* global EventHandler */
import React from "react";
import { includes } from "lodash";
import { ActionBarButton } from "buttons";
import CategoryBar from "../CategoryBar";
import Approved from "./common/Approved";
import Pending from "./common/Pending";
import Rejected from "./common/Rejected";
import type { Category as CategoryType } from "graphql-types/category";
import type { ContentStatus } from "graphql-types/getContents";
import styles from "./index.css";

type Props = {
  contentId: ?string,
  status: ?ContentStatus,
  category: ?CategoryType
};

const ActionBar = ({ contentId, status, category }: Props) => (
  <CategoryBar category={category} className={styles.root}>
    {includes(["APPROVED", "EXPIRED"], status) && (
      <Approved contentId={contentId} />
    )}
    {status === "PENDING" && <Pending contentId={contentId} />}
    {status === "REJECTED" && <Rejected contentId={contentId} />}
    {!status && (
      <div className={styles.loading}>
        <ActionBarButton type="secondary" disabled={true}>
          &nbsp;
        </ActionBarButton>
        <ActionBarButton type="secondary" disabled={true}>
          &nbsp;
        </ActionBarButton>
        <ActionBarButton type="secondary" disabled={true}>
          &nbsp;
        </ActionBarButton>
      </div>
    )}
  </CategoryBar>
);

export default ActionBar;
