// @flow
import React from "react";
import { Icon } from "icons";
import tooltipper from "behavioral/tooltipper";
import styles from "./index.css";
import classNames from "classnames";

type Props = {
  handlePreviewButtonClick: () => void,
  className: any
};

export const TooltipBody = ({  }: Props) => {
  return (
    <p className={styles.previewButtonTooltip}>
      Preview how this post will look on your social networks.
    </p>
  );
};

export const PostPreviewButton = ({
  handlePreviewButtonClick,
  className
}: Props) => {
  return (
    <Icon
      className={classNames(className, styles.root)}
      type="eye"
      size="2x"
      onClick={handlePreviewButtonClick}
    />
  );
};

export default tooltipper(TooltipBody, {
  targetClassName: styles.postPreviewButtonTooltipTether
})(PostPreviewButton);
