/* @flow */
import React, { useState, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location } from "react-router-dom";

import pluralize from "pluralize";
import sortBy from "lodash/sortBy";
import moment from "moment-timezone";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Button from "buttons/Button";
import LoadingIcon from "icons/LoadingIcon";
import Link from "links/Link";
import Message from "layout/Message";
import EmptyState from "./EmptyState";
import ScheduleInformation from "./ScheduleInformation";
import ScheduleModal from "./ScheduleModal";
import Confirmation from "layout/modals/Confirmation";
import UpgradeModal from "components/UpgradeModal";
import Calendar from "./Calendar";
import FilterSelect from "components/common/FilterSelect";
import AccountBadge from "icons/AccountBadge";
import ErrorWithRetry from "components/ErrorWithRetry";
import withFilter, { parseFilterParams } from "behavioral/withFilter";
import { colorFromIndex } from "colors";
import { featureFlag } from "util";

import GET_SCHEDULE from "queries/getSchedule";
import CREATE_SCHEDULE from "mutations/createSchedule";
import UPDATE_SCHEDULE from "mutations/updateSchedule";
import DELETE_SCHEDULE from "mutations/deleteSchedule";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import DISMISS_SCHEDULE_SUGGESTION from "mutations/dismissScheduleSuggestion";

import styles from "./index.css";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type {
  ProductName,
  getSchedule_accounts as AccountData,
  getSchedule_schedules as ScheduleData,
  getSchedule_scheduleSuggestions as ScheduleSuggestionData,
  getSchedule_user_onboardingProgress as OnboardingProgressData
} from "graphql-types/getSchedule";
import type {
  createScheduleVariables as CreateScheduleVariables,
  createSchedule as CreateScheduleResult
} from "graphql-types/createSchedule";
import type {
  updateScheduleVariables as UpdateScheduleVariables,
  updateSchedule as UpdateScheduleResult
} from "graphql-types/updateSchedule";
import type {
  deleteScheduleVariables as DeleteScheduleVariables,
  deleteSchedule as DeleteScheduleResult
} from "graphql-types/deleteSchedule";
import type {
  dismissScheduleSuggestionVariables as DismissScheduleSuggestionVariables,
  dismissScheduleSuggestion as DismissScheduleSuggestionResult
} from "graphql-types/dismissScheduleSuggestion";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";
import { type CategoryOption } from "components/common/CategorySelect";
import type { ContentFilter } from "types";

import { Loading } from "./styles";

type OwnProps = {
  location: Location,
  match: Match
} & TopbarProps;

type TopbarProps = {
  topNav: TopNavProps,
  subscriptionStatus: SubscriptionStatusProps,
  onboardingProgress: OnboardingProgressData,
  dismissOnboarding: () => Promise<DismissOnboardingResult>
};

type Mutation<V, R> = ({ variables: V }) => Promise<R>;

type Props = {
  product: ProductName,
  accounts: AccountData[],
  categories: CategoryOption[],
  schedules: ScheduleData[],
  scheduleLimitReached: boolean,
  scheduleSuggestions: ScheduleSuggestionData[],
  totalApprovedContents: number,
  timezone: string,
  filter: ContentFilter,
  createSchedule: Mutation<CreateScheduleVariables, CreateScheduleResult>,
  updateSchedule: Mutation<UpdateScheduleVariables, UpdateScheduleResult>,
  deleteSchedule: Mutation<DeleteScheduleVariables, DeleteScheduleResult>,
  dismissScheduleSuggestion: Mutation<
    DismissScheduleSuggestionVariables,
    DismissScheduleSuggestionResult
  >,
  fetching: boolean,
  creating: boolean,
  deleting: boolean,
  updating: boolean,
  dismissingScheduleSuggestion: boolean,
  paused: boolean,
  createdScheduleIds: string[],
  mutationError: ?string,
  error: ?string,
  createErrors: string[],
  onClearCreateErrors: () => void
} & TopbarProps;

const Schedule = ({
  topNav,
  subscriptionStatus,
  onboardingProgress,
  timezone,
  paused,
  product,
  filter,
  accounts,
  categories,
  schedules,
  scheduleLimitReached,
  scheduleSuggestions,
  createdScheduleIds,
  totalApprovedContents,
  createSchedule,
  updateSchedule,
  deleteSchedule,
  dismissOnboarding,
  dismissScheduleSuggestion,
  fetching,
  creating,
  deleting,
  updating,
  dismissingScheduleSuggestion,
  createErrors,
  onClearCreateErrors,
  error,
  mutationError
}: Props) => {
  const [showAddSchedule, setShowAddSchedule] = useState(null);
  const [showLimitReached, setShowLimitReached] = useState(false);

  if (!featureFlag("schedule_old")) window.location.href = "/schedule";

  const handleSubmit = useCallback(
    async input => {
      if (!input.id) {
        await createSchedule({ variables: { input } });
        setShowAddSchedule(null);
      } else {
        await updateSchedule({ variables: { input } });
        setShowAddSchedule(null);
      }
    },
    [updateSchedule, createSchedule]
  );

  const handleDelete = useCallback(
    async scheduleId => {
      await deleteSchedule({
        variables: { id: scheduleId }
      });
      setShowAddSchedule(null);
    },
    [deleteSchedule]
  );

  const handleDismissScheduleSuggestion = useCallback(
    async (offset, platforms) => {
      await dismissScheduleSuggestion({
        variables: { offset, platforms }
      });
      setShowAddSchedule(null);
    },
    [dismissScheduleSuggestion]
  );

  const handleClickEditTimeslot = useCallback(calendarItem => {
    setShowAddSchedule(calendarItem);
  }, []);

  const handleClickNewTimeslot = useCallback(
    (day, hour, minutes) => {
      if (scheduleLimitReached) {
        setShowLimitReached(true);
        return;
      }

      const sendAt = moment
        .utc()
        .set({ hour, minutes })
        .format("h:mm a");
      setShowAddSchedule({
        __typename: "Schedule",
        sendOn: day,
        sendAt,
        accounts: [],
        category: null
      });
    },
    [setShowLimitReached, scheduleLimitReached]
  );

  const handleCloseLimitReachedModal = () => {
    onClearCreateErrors();
    setShowLimitReached(false);
  };

  const accountOptions = sortBy(accounts, ["provider", "name", "nickname"]).map(
    a => ({
      value: a.id,
      label: a.name || (a.nickname ? `@${a.nickname}` : null),
      icon: <AccountBadge value={a} />
    })
  );

  const RANDOM_CATEGORY_OPTION = {
    value: "0",
    label: "Random",
    color: "var(--inky400)"
  };

  const categoryOptions = [RANDOM_CATEGORY_OPTION].concat(
    sortBy(categories, ["name"]).map(c => ({
      value: c.id,
      label: c.name,
      color: colorFromIndex(c.colorIndex)
    }))
  );

  const displayCalendar = accounts.length > 0 && totalApprovedContents > 0;
  const timezoneAbbr = timezone && moment.tz.zone(timezone).abbr(moment());

  return featureFlag("schedule_old") ? (
    <App
      loggedIn
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      onboardingProgress={onboardingProgress}
      onDismissOnboarding={dismissOnboarding}
      header={
        <div className={styles.header}>
          <div className={styles.toolbar}>
            <PageTitle
              className={styles.pageTitle}
              subtitle={timezoneAbbr && `All times in ${timezoneAbbr}`}
            >
              Recurring automations
            </PageTitle>
            {displayCalendar ? (
              <>
                <span className={styles.filterByLabel}>Filter by</span>
                <FilterSelect
                  className={styles.categoryFilter}
                  options={categoryOptions}
                  placeholder="All categories"
                  maxMenuWidth="200%"
                  filterKey="category"
                />
                <FilterSelect
                  className={styles.accountFilter}
                  options={accountOptions}
                  placeholder="All accounts"
                  maxMenuWidth="200%"
                  filterKey="account"
                />
                <Button
                  className={styles.addTimeslotButton}
                  onClick={handleClickNewTimeslot.bind(null, null, 12, 0)}
                  type="primary"
                >
                  Add time slot
                </Button>
              </>
            ) : null}
          </div>
          <div className={styles.timeslotsCount}>
            {schedules.length} {pluralize("time slot", schedules.length)}{" "}
            {!!Object.keys(filter).length && "showing"}
          </div>
        </div>
      }
      messages={
        paused ? (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting in{" "}
            <Link href="/queue">your Queue</Link>.
          </Message>
        ) : null
      }
    >
      {scheduleSuggestions.length > 0 && displayCalendar ? (
        <ScheduleInformation />
      ) : null}
      {showAddSchedule ? (
        <ScheduleModal
          onClose={() => setShowAddSchedule(null)}
          accounts={accounts}
          categories={categories}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          onDismissScheduleSuggestion={handleDismissScheduleSuggestion}
          calendarItem={showAddSchedule}
          creating={creating}
          deleting={deleting || dismissingScheduleSuggestion}
          updating={updating}
          error={mutationError}
        />
      ) : null}
      {showLimitReached || createErrors.includes("limit_reached") ? (
        <LimitReachedModal
          product={product}
          onClose={handleCloseLimitReachedModal}
        />
      ) : null}
      {fetching ? (
        <div className={styles.loadingContainer}>
          <LoadingIcon className={styles.loading} />
        </div>
      ) : error ? (
        <ErrorWithRetry>{error}</ErrorWithRetry>
      ) : accounts.length < 1 ? (
        <div className={styles.loadingContainer}>
          <EmptyState>
            <h3>You have no accounts!</h3>
            <p>Connect a social media account before creating your schedule.</p>
            <Button type="primary" href="/accounts">
              Add account
            </Button>
          </EmptyState>
        </div>
      ) : totalApprovedContents < 1 ? (
        <div className={styles.loadingContainer}>
          <EmptyState>
            <h3>Your library is empty!</h3>
            <p>Add content before creating your schedule.</p>
            <Button type="primary" href="/composer">
              Add content
            </Button>
          </EmptyState>
        </div>
      ) : (
        <Calendar
          schedules={schedules.concat(scheduleSuggestions)}
          focusedTimeslotIds={createdScheduleIds}
          onClickNewTimeslot={handleClickNewTimeslot}
          onClickEditTimeslot={handleClickEditTimeslot}
        />
      )}
    </App>
  ) : (
    <Loading>
      <LoadingIcon />
    </Loading>
  );
};

const LimitReachedModal = ({ product, onClose }) => {
  return product === "edgar_lite" ? (
    <UpgradeModal
      name="timeSlotLimitReached"
      onClose={onClose}
      title="Upgrade now for more time slots!"
      subTitle="Your Edgar Lite plan allows for 10 time slots. Need more? Upgrade to the Edgar plan and get 1000 time slots, plus 25 social media accounts and unlimited categories."
    />
  ) : (
    <Confirmation
      title="Upgrade now for more time slots!"
      subTitle="The maximum number of time slots that can be added to an Edgar account is 1,000. For more time slots, an additional Edgar account is required. Contact us about a discount for multiple Edgar accounts."
      type="error"
    >
      <div className={styles.upgradeLink}>
        <Link tabIndex="-1" href="mailto:support@meetedgar.com">
          Contact us to upgrade
        </Link>
      </div>
    </Confirmation>
  );
};

export default withFilter()(
  ({ match, location, topNav, subscriptionStatus }: OwnProps) => {
    const { filter } = parseFilterParams(match, location);

    // FIXME: `status` is set by default when parsing filters
    //        but status doesn't make sense here. `withFilter`
    //        and the filter parser should probably be a bit
    //        more general.
    delete filter.status;

    // Queries
    const { loading: fetching, error, data, refetch } = useQuery(GET_SCHEDULE, {
      variables: { filter },
      fetchPolicy: "network-only"
    });

    // Mutations
    const [
      createSchedule,
      { loading: creating, error: createError }
    ] = useMutation(CREATE_SCHEDULE, {
      onCompleted(result) {
        const errors = result.createSchedule.errors;
        if (errors.length > 0) {
          setCreateErrors(errors);
        }
      }
    });
    const [
      updateSchedule,
      { loading: updating, error: updateError }
    ] = useMutation(UPDATE_SCHEDULE);
    const [
      deleteSchedule,
      { loading: deleting, error: deleteError }
    ] = useMutation(DELETE_SCHEDULE);
    const [
      dismissOnboarding,
      { loading: _dismissingOnboarding, error: _dismissOnboardingError }
    ] = useMutation(DISMISS_ONBOARDING);
    const [
      dismissScheduleSuggestion,
      { loading: dismissingScheduleSuggestion }
    ] = useMutation(DISMISS_SCHEDULE_SUGGESTION);

    // State
    const [focusedScheduleIds, setFocusedScheduleIds] = useState([]);
    const [createErrors, setCreateErrors] = useState([]);

    async function createScheduleAndRefetch(vars) {
      const result = await createSchedule(vars);
      await refetch();
      // After a schedule/timeslot is created add it to the focus list.
      // This is used to scroll to the timeslot and display an animation.
      // Then, after a few seconds remove the schedule/timeslot from the
      // focus list.
      setFocusedScheduleIds(
        (result?.data?.createSchedule?.schedules ?? []).map(s => s.id)
      );
      setTimeout(() => setFocusedScheduleIds([]), 3000);
      return result;
    }

    async function updateScheduleAndRefetch(vars) {
      const result = await updateSchedule(vars);
      await refetch();
      return result;
    }

    async function deleteScheduleAndRefetch(vars) {
      const result = await deleteSchedule(vars);
      await refetch();
      return result;
    }

    async function dismissScheduleSuggestionAndRefetch(vars) {
      const result = await dismissScheduleSuggestion(vars);
      await refetch();
      return result;
    }

    const handleClearCreateErrors = useCallback(() => setCreateErrors([]), [
      setCreateErrors
    ]);

    return (
      <Schedule
        topNav={topNav}
        product={data?.company?.subscriptionPlan?.productName}
        subscriptionStatus={subscriptionStatus}
        onboardingProgress={data?.user?.onboardingProgress}
        timezone={data?.company?.timeZone}
        dismissOnboarding={dismissOnboarding}
        dismissScheduleSuggestion={dismissScheduleSuggestionAndRefetch}
        paused={data?.company?.queueStatus === "PAUSED"}
        schedules={data?.schedules ?? []}
        scheduleLimitReached={!!data?.company?.scheduleLimitReached}
        scheduleSuggestions={data?.scheduleSuggestions ?? []}
        createdScheduleIds={focusedScheduleIds}
        filter={filter}
        accounts={data?.accounts ?? []}
        categories={data?.categories ?? []}
        totalApprovedContents={data?.company?.totalApprovedContents ?? 0}
        createSchedule={createScheduleAndRefetch}
        updateSchedule={updateScheduleAndRefetch}
        deleteSchedule={deleteScheduleAndRefetch}
        fetching={fetching}
        creating={creating}
        updating={updating}
        deleting={deleting}
        dismissingScheduleSuggestion={dismissingScheduleSuggestion}
        createErrors={createErrors}
        onClearCreateErrors={handleClearCreateErrors}
        mutationError={createError || updateError || deleteError}
        error={error ? `Uh-oh something went wrong 😿 : ${error}` : null}
      />
    );
  }
);
