/* @flow */
import React from "react";
import Button from "buttons/Button";
import { goBack } from "util";

const noop = () => null;

const CancelLink = ({ disabled }: { disabled: boolean }) => (
  <Button
    type="secondary"
    disabled={disabled}
    onClick={disabled ? noop : goBack}
  >
    Cancel
  </Button>
);

export default CancelLink;
