/* @flow */
import React from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  className?: string,
  loggedIn: boolean,
  admin: boolean
};

export const Footer = ({ loggedIn, admin, className }: Props) => {
  const year = new Date().getFullYear();
  return (
    <footer className={classNames(styles.root, className)}>
      <p className={styles.footer}>
        <a href="https://help.meetedgar.com" target="_support">
          Help Center
        </a>
        {loggedIn && (
          <span>
            |
            <a
              href="https://meetedgar.com/apps-and-integrations/"
              target="plugin"
            >
              Browser Plugin and Bookmarklet
            </a>
          </span>
        )}
        {admin && (
          <span>
            |<a href="/admin">Admin</a>
          </span>
        )}
      </p>
      <p className={styles.footer}>
        &copy; {year} SureSwift Capital, Inc |
        <a href="http://meetedgar.com/terms-conditions/">
          Terms &amp; Conditions
        </a>
        |
        <a href="http://meetedgar.com/privacy/" target="_support">
          Privacy Policy
        </a>
      </p>
    </footer>
  );
};

export default Footer;
