/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation importRssFeedNow($rssFeedId: Int!) {
    importRssFeedNow(rssFeedId: $rssFeedId) {
      rssFeedId
    }
  }
`;
