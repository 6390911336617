import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px 0;
`;

export const Picture = styled.div`
  img {
    border-radius: 50%;
    height: 35px;
    object-fit: contain;
    width: 35px;
  }
`;

export const Message = styled.div`
  color: #aaa;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  margin: 5px 0 0;

  ${({ active }) => active && `color: #777;`}
`;

export const Unread = styled.div`
  background: var(--kelp400);
  border-radius: 10px;
  color: #fff;
  font-size: 11px;
  height: 18px;
  margin: 0 0 0 2px;
  padding: 1px 8px;
`;
