/* @flow */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { sortBy } from "lodash";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import { GET_CATEGORIES_WITH_TIMESLOT_COUNT } from "queries/category";
import { CREATE_CATEGORY } from "mutations/category";
import Container from "components/common/layout/Container";

import { useNewUICached } from "util";
import Button from "buttons/Button";
import { Icon } from "icons";
import { Link } from "links";
import { H1 } from "typography";
import CategoryInformation from "./CategoryInformation";
import CategoryList from "./CategoryList";
import CategoryActionModal from "./CategoryActionModal";
import UpgradeModal from "components/UpgradeModal";
import NewHeader from "components/NewHeader";

import type { Props as TopNavProps } from "components/TopNav";

import { createFlash } from "actions/flash";
import styles from "./index.css";

export const HELP_URL =
  "https://help.meetedgar.com/introduction-to-edgar-and-faq/categories";

import type { Dispatch } from "types";

type Props = {
  dispatch: Dispatch,
  topNav: TopNavProps
};

export function Categories(props: Props) {
  const useNewUI = useNewUICached();
  const { dispatch } = props;
  const { loading, error, data } = useQuery(GET_CATEGORIES_WITH_TIMESLOT_COUNT);
  const [showAddModal, setShowAddModal] = useState(false);
  const [addModalErrors, setAddModalErrors] = useState([]);
  const categoryLimit = data?.company?.categoryLimit || Infinity;
  let categories = [];
  let categoryAtLimit;

  const [createCategory] = useMutation(CREATE_CATEGORY, {
    async update(
      cache,
      {
        data: {
          createCategory: { category, errors }
        }
      }
    ) {
      setAddModalErrors(errors);

      if (errors.length) {
        return;
      }

      const { categories } = cache.readQuery({
        query: GET_CATEGORIES_WITH_TIMESLOT_COUNT
      });

      categories.push(category);

      await cache.writeQuery({
        query: GET_CATEGORIES_WITH_TIMESLOT_COUNT,
        data: {
          categories: sortBy(categories, ({ name }) => name.toLowerCase())
        }
      });

      handleOnClickCloseAddCategoryModal();
      dispatch(createFlash("notice", "Category was successfully created."));
    },
    refetchQueries: [{ query: GET_CATEGORIES_WITH_TIMESLOT_COUNT }]
  });

  function handleOnAddCategory() {
    setShowAddModal(true);
  }

  function handleOnClickCloseAddCategoryModal() {
    setShowAddModal(false);
    setAddModalErrors([]);
  }

  async function handleOnClickAddCategory(_categoryId, name, useForRandom) {
    await createCategory({
      variables: {
        input: {
          name,
          useForRandom
        }
      }
    });
  }

  if (!loading && !error && data) {
    categories = data?.categories;
    categoryAtLimit = categoryLimit <= categories.length;
  }

  return (
    <Container>
      <div className={styles.container}>
        {useNewUI ? (
          <>
            <NewHeader
              title="Categories"
              helpUrl={HELP_URL}
              mb
              {...props.topNav}
            />
            <div className={styles.newHeader}>
              <div className={styles.newCategoryCountText}>
                Total: {categories.length}
              </div>
              <Button onClick={handleOnAddCategory} type="primary">
                Add category
              </Button>
            </div>
          </>
        ) : (
          <div className={styles.header}>
            <div className={styles.headerTitle}>
              <H1 className={styles.headerTitleText}>Categories</H1>

              <div className={styles.categoryCountText}>
                {categories.length || (
                  <SkeletonTheme
                    color="var(--inky100)"
                    highlightColor="var(--inky50)"
                  >
                    <Skeleton width={15} />
                  </SkeletonTheme>
                )}
              </div>

              <Link className={styles.helpLink} url={HELP_URL} target="_blank">
                <Icon
                  className={styles.questionCircleIcon}
                  type="question-circle"
                />
              </Link>
            </div>

            <Button onClick={handleOnAddCategory} type="primary">
              Add category
            </Button>
          </div>
        )}

        <CategoryInformation canAddCategory={!categoryAtLimit} />

        <CategoryList categories={categories} />

        {showAddModal && (
          <CategoryModal
            categoryAtLimit={categoryAtLimit}
            errors={addModalErrors}
            onClose={handleOnClickCloseAddCategoryModal}
            onSubmit={handleOnClickAddCategory}
          />
        )}
      </div>
    </Container>
  );
}

const CategoryModal = ({ categoryAtLimit, errors, onClose, onSubmit }) => {
  return categoryAtLimit ? (
    <UpgradeModal
      name="categoryLimitReached"
      onClose={onClose}
      title="Upgrade now to add a new category!"
      subTitle="Your Eddie plan allows for 4 categories. Need more? Upgrade to Edgar plan and get unlimited categories, plus 25 social media accounts and 1000 time slots."
    />
  ) : (
    <CategoryActionModal
      errors={errors}
      handleClose={onClose}
      handleSubmit={onSubmit}
    />
  );
};

export default connect()(Categories);
