/* @flow */
import React from "react";
import classNames from "classnames";
import Link, { type Props as LinkProps } from "links/Link";
import Icon from "icons/Icon";
import styles from "./index.css";

type Props = LinkProps & { staticPositioned?: boolean };

const CloseButton = ({
  className,
  staticPositioned,
  onClick,
  ...others
}: Props) => (
  <Link
    tabIndex="-1"
    onClick={onClick}
    {...others}
    className={classNames(styles.root, {
      [styles.staticPositioned]: !!staticPositioned
    })}
  >
    <Icon type="times" className={`${styles.icon} ${className || ""}`} />
  </Link>
);

export default CloseButton;
