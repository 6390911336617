/* @flow */
import React from "react";
import { uniqBy } from "lodash";
import classNames from "classnames";
import Icon from "icons/Icon";
import HtmlizedText from "components/common/HtmlizedText";
import styles from "./index.css";

import type {
  getContents_contents_data_variations as VariationData,
  getContents_contents_data_accountRelationships_account as AccountData
} from "graphql-types/getContents";

export type Props = {
  variation: VariationData,
  accounts: AccountData[]
};

const VariationText = ({ variation, accounts }: Props) => {
  const {
    origin,
    rawRichTextEntityMap,
    displayText,
    displayFbText,
    mentionNames
  } = variation;

  const mentionProps = {
    mentions: mentionNames,
    rawRichTextEntityMap
  };

  const uniquePlatformsWithoutFacebook = uniqBy(
    accounts.filter(account => account.platform.toUpperCase() !== "FACEBOOK"),
    "platform"
  );

  const hasFbAccount = accounts.some(
    account => account.platform.toUpperCase() === "FACEBOOK"
  );
  const wasAutoGenerated =
    origin && origin.toLowerCase().match(/rss|widget|autogenerated/);
  const hasNonFacebookAccounts = uniquePlatformsWithoutFacebook.length > 0;
  const splitTextRequired =
    hasFbAccount && wasAutoGenerated && hasNonFacebookAccounts && displayFbText;
  const hasOnlyFbSpecificText =
    hasFbAccount && wasAutoGenerated && !hasNonFacebookAccounts;
  const fbText = displayFbText || displayText || "";
  const text = displayText || "";

  return (
    <div>
      <div className={styles.baseText}>
        {splitTextRequired &&
          uniquePlatformsWithoutFacebook.map(account => (
            <div
              key={account.id}
              className={classNames(
                styles.platformIconContainer,
                styles[account.platform.toLowerCase()]
              )}
            >
              <Icon type={`${account.platform.toLowerCase()}-square`} />
            </div>
          ))}

        {splitTextRequired && <span className={styles.textLabel}>text:</span>}

        <HtmlizedText
          className={styles.text}
          value={hasOnlyFbSpecificText ? fbText : text}
          {...mentionProps}
        />
      </div>

      {splitTextRequired && (
        <div className={styles.facebookSplitSpecificText}>
          <div
            className={classNames(
              styles.platformIconContainer,
              styles.facebook
            )}
          >
            <Icon type="facebook-square" />
          </div>

          <span className={styles.textLabel}>text:</span>

          <HtmlizedText value={fbText} {...mentionProps} />
        </div>
      )}
    </div>
  );
};

export default VariationText;
