import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  cursor: pointer;
  display: flex;
  padding: 10px 12px;

  &:hover {
    background-color: #fefefe;
    border-color: #ddd;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.07);
  }
`;

export const Left = styled.div`
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 4px;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 56px;
`;

export const Right = styled.div`
  margin-left: 10px;

  h3 {
    font-size: 14px;
    font-weight: 600;
  }

  p {
    font-size: 12px;
  }
`;
