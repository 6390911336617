import styled from "styled-components";

export const Wrapper = styled.div`
  margin: var(--midHorzGutter) var(--midHorzGutter);

  > div {
    height: 100%;
    padding: var(--largeVertGutter) var(--largeHorzGutter);
  }
`;

export const Title = styled.div`
  font-weight: bolder;
  margin-bottom: var(--tinyVertGutter);
  margin-top: var(--largeVertGutter);
`;

export const CSVModalContainer = styled.div`
  left: 0;
  position: absolute;
  top: 0;
`;
