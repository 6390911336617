/* @flow */
import React from "react";
import Information from "components/common/layout/Information";
import Link from "links/Link";
import styles from "./index.css";

const BEST_TIMES_LINK =
  "https://meetedgar.com/blog/best-times-to-post-on-social-media/";
const SCHEDULING_LINK =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/schedule-posts-with-edgar/schedule-overview";

export const ScheduleInformation = () => (
  <Information name="scheduleInformation" className={styles.root}>
    <p>
      We've done the research for you and found some of the best times to post!
      Click on the suggested time slots in the grid below to add them to your
      weekly automation schedule. Learn more about finding the{" "}
      <Link href={BEST_TIMES_LINK} target="_blank">
        best times to post
      </Link>{" "}
      or dig deeper into{" "}
      <Link href={SCHEDULING_LINK} target="_blank">
        scheduling with Edgar
      </Link>
      .
    </p>
  </Information>
);

export default ScheduleInformation;
