import React from "react";
import { Field, ErrorMessage } from "formik";

import Label from "typography/Label";

import styles, {
  Wrapper,
  FormGroup,
  Row,
  Required,
  Input,
  ErrorWrapper
} from "./styles";

const InvitationForm = ({ formRef }) => {
  const { values } = formRef;

  return (
    <Wrapper>
      <Row>
        <FormGroup col>
          <Label>
            First name<Required>*</Required>
          </Label>
          <Field name="firstName" style={styles.input} />
          <ErrorMessage name="firstName" component={ErrorWrapper} />
        </FormGroup>

        <FormGroup col>
          <Label>
            Last name<Required>*</Required>
          </Label>
          <Field name="lastName" style={styles.input} />
          <ErrorMessage name="lastName" component={ErrorWrapper} />
        </FormGroup>
      </Row>

      <FormGroup>
        <Label>Email</Label>
        <Field
          name="email"
          type="email"
          disabled
          component={inputProps => (
            <Input
              {...inputProps}
              defaultValue={values.email}
              style={{ opacity: 0.5 }}
            />
          )}
        />
        <ErrorMessage name="email" component={ErrorWrapper} />
      </FormGroup>

      <Row>
        <FormGroup col>
          <Label>
            Password<Required>*</Required>
          </Label>
          <Field name="password" type="password" style={styles.input} />
          <ErrorMessage name="password" component={ErrorWrapper} />
        </FormGroup>

        <FormGroup col>
          <Label>
            Confirm password<Required>*</Required>
          </Label>
          <Field
            name="passwordConfirmation"
            type="password"
            style={styles.input}
          />
          <ErrorMessage name="passwordConfirmation" component={ErrorWrapper} />
        </FormGroup>
      </Row>
    </Wrapper>
  );
};

export default InvitationForm;
