/* @flow */
/* global EventHandler, SyntheticKeyboardEvent */
import React, { Component } from "react";
import classNames from "classnames";
import withFilter from "behavioral/withFilter";
import TextInput from "forms/TextInput";
import SpyGlass from "./SpyGlass";
import Clear from "./Clear";
import styles from "./index.css";

import type { ContentFilter } from "types";

type Props = {
  totalCount: number,
  filter: ContentFilter,
  onFilterPatch: (filter: ContentFilter) => void
};

type State = { value: ?string };

export class Search extends Component<Props, State> {
  state = { value: "" };

  componentWillMount() {
    this.setState({ value: this.getKeywords() });
  }

  componentWillReceiveProps({ filter: { keywords: nextKeywords } }: Props) {
    if (nextKeywords !== this.state.value) {
      this.setState({ value: nextKeywords });
    }
  }

  handleEnter: () => void = () => {
    if (this.isDisabled()) return;
    this.handleKeywordsSubmit(this.state.value);
  };

  handleChange: (value: string) => void = value => {
    this.setState({ value });
  };

  handleClickSubmit: (e: Event) => void = e => {
    e.preventDefault();
    if (this.isDisabled()) return;
    this.handleKeywordsSubmit(this.state.value);
  };

  handleClickClear: (e: Event) => void = e => {
    e.preventDefault();
    this.setState({ value: "" });
    this.handleKeywordsSubmit(null);
  };

  handleKeywordsSubmit = (value: ?string): void => {
    this.props.onFilterPatch({ ["keywords"]: value });
  };

  isDisabled(): boolean {
    const { totalCount } = this.props;
    return totalCount === 0;
  }

  getKeywords = () => {
    const { filter } = this.props;
    return filter.keywords || "";
  };

  render() {
    const activeKeywords = this.getKeywords();
    const { value } = this.state;

    const inputClasses = classNames(styles.field, {
      [styles.disabledField]: this.isDisabled()
    });

    const spyGlassClasses = classNames(styles.iconAction, {
      [styles.disabledAction]: this.isDisabled()
    });

    return (
      <div className={styles.root}>
        <SpyGlass
          className={spyGlassClasses}
          onClick={this.handleClickSubmit}
        />

        <TextInput
          className={inputClasses}
          value={value || ""}
          placeholder="Search…"
          disabled={this.isDisabled()}
          data-test="searchBar"
          onChange={this.handleChange}
          onEnter={this.handleEnter}
        />

        {activeKeywords && (
          <Clear
            className={styles.iconAction}
            onClick={this.handleClickClear}
          />
        )}
      </div>
    );
  }
}

export default withFilter()(Search);
