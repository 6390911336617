/* @flow */
import React from "react";

import { Button } from "buttons";
import { Modal } from "layout/modals";

import SVGSuccess from "./icons/success.svg";
import SVGWarning from "./icons/warning.svg";

import type { Props } from "./types.js";
import styles from "./index.css";
import { Wrapper, Icon, Title, Text, Buttons } from "./styles";

const CustomModal = (props: Props) => {
  const { onClose, type, title, text, primaryButton, secondaryButton } = props;

  return (
    <Modal onClose={onClose} className={styles.root}>
      <Wrapper>
        {type && (
          <Icon>
            <img
              src={type === "warning" ? SVGWarning : SVGSuccess}
              alt={(type === "warning" ? "warning" : "success") + " icon"}
            />
          </Icon>
        )}
        {title && <Title>{title}</Title>}
        <Text>{text}</Text>

        {primaryButton || secondaryButton ? (
          <Buttons>
            {secondaryButton && (
              <Button
                type="secondary"
                onClick={secondaryButton.onClick}
                className={
                  primaryButton && secondaryButton ? "two-buttons" : ""
                }
              >
                {secondaryButton.label}
              </Button>
            )}
            {primaryButton && (
              <Button type="primary" onClick={primaryButton.onClick}>
                {primaryButton.label}
              </Button>
            )}
          </Buttons>
        ) : null}
      </Wrapper>
    </Modal>
  );
};

export default CustomModal;
