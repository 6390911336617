// @flow
import { createSelector, type OutputSelector } from "reselect";
import selectRawState, { type UsersState } from "../raw";
import type { AppState } from "types";

export type UserForReporting = { id: string, email: string };

const selectUserForReporting: OutputSelector<
  AppState,
  void,
  UserForReporting
> = createSelector(
  selectRawState,
  (usersState: UsersState): UserForReporting => ({
    id: usersState.data[0].id,
    email: usersState.data[0].attributes.email
  })
);

export default selectUserForReporting;
