/* @flow */
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import TOGGLE_NEW_UI from "mutations/toggleNewUI";
import GET_DASHBOARD from "queries/getDashboard";

const getWindowWidth = () => {
  return window.innerWidth;
};

export const useSwitchToOld = () => {
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());
  const [showOldViewButton, setShowOldViewButton] = useState(false);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const handleHideOldViewButton = async () => {
      try {
        const res = await apolloClient.query({
          query: GET_DASHBOARD
        });

        setShowOldViewButton(!res.data.user?.hideOldViewButton);
      } catch (err) {
        // console.log(err);
      }
    };

    handleHideOldViewButton();
  });

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOldUI = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const res = await apolloClient.mutate({
        mutation: TOGGLE_NEW_UI,
        variables: { enable: false }
      });

      if (res.data) {
        localStorage.removeItem("navbar_modules_ui");
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return { loading, handleOldUI, windowWidth, showOldViewButton };
};

export default useSwitchToOld;
