/* @flow */
/* global EventHandler */
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import withFilter, {
  parseFilterParams,
  buildPaginator
} from "behavioral/withFilter";
import { ActionBarButton } from "buttons";

import REJECT_CONTENT from "mutations/rejectContent";
import GET_CONTENTS from "queries/getContents";

import type { ContentFilter } from "types";
import type { Match, Location } from "react-router-dom";

type OwnProps = {
  contentId: string,
  disabled: boolean
};
type Props = OwnProps & {
  filter: ContentFilter,
  onReject: () => void,
  location: Location,
  match: Match,
  loading: boolean
};

export const Reject = ({ disabled, loading, contentId, onReject }: Props) => (
  <ActionBarButton
    disabled={disabled || loading || !contentId}
    loading={loading}
    onClick={() => onReject()}
    type="secondary-negative"
  >
    Reject
  </ActionBarButton>
);

export default withFilter()((props: Props) => {
  const PER_PAGE = 25;
  const { contentId } = props;
  const { filter, page: pageNum } = parseFilterParams(
    props.match,
    props.location
  );

  const [rejectContent, { loading }] = useMutation(REJECT_CONTENT, {
    variables: { id: contentId },
    refetchQueries: _ => [
      {
        query: GET_CONTENTS,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
        variables: {
          filter,
          page: buildPaginator(pageNum, PER_PAGE)
        }
      }
    ],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true
  });

  return (
    <Reject
      {...props}
      loading={loading}
      onReject={() => rejectContent(contentId)}
    />
  );
});
