/* @flow */
import React from "react";
import RadioButton2 from "components/common/forms/RadioButton2";
import styles from "./index.css";

type Props = {
  sendMobileReminder: boolean,
  hasMobileDevices: Boolean,
  onChange: (sendMobileReminder: boolean) => void
};

export const InstagramBusinessSettings = ({
  sendMobileReminder,
  hasMobileDevices,
  onChange
}: Props) => {
  const handleChange = value => {
    onChange(value);
  };

  const mobileReminderOptionDisabled = !hasMobileDevices;

  return (
    <>
      <div>
        <RadioButton2
          className={styles.radioButton}
          value={false}
          selected={!sendMobileReminder}
          onClick={handleChange}
        >
          <span className={styles.title}>Direct to feed</span>
        </RadioButton2>
        <small className={styles.description}>
          For single image or video posts to business accounts
        </small>
      </div>
      <div
        style={{
          pointerEvents: mobileReminderOptionDisabled ? "none" : null,
          opacity: mobileReminderOptionDisabled ? "0.5" : null
        }}
      >
        <RadioButton2
          className={styles.radioButton}
          value={true}
          selected={sendMobileReminder}
          onClick={handleChange}
        >
          <span className={styles.title}>Mobile reminder</span>
        </RadioButton2>
        <small className={styles.description}>
          Use for stories, multi-image posts, video posts, and non-business
          accounts{" "}
        </small>
      </div>

      {mobileReminderOptionDisabled && (
        <div className={styles.description}>
          <a
            href="https://help.meetedgar.com/en/articles/2480839-connecting-instagram-accounts-to-edgar"
            target="_blank"
          >
            Learn how
          </a>{" "}
          to set up mobile reminders
        </div>
      )}
    </>
  );
};

export default InstagramBusinessSettings;
