/* @flow */
import React from "react";
import { Body, Label } from "components/common/typography";
import { Card } from "components/common/layout";
import ImportModal from "../ImportModal";
import ConnectionFooter from "../ConnectionFooter";
import ConnectionFooterZapier from "../ConnectionFooterZapier";
import ImportCSVModal from "components/common/ImportCSVModal";
import ShopifyAuthentication from "../../ShopifyAuthentication";

import styles from "./index.css";
import type { Props } from "./types.js";
import useConnectionImportCard from "./use";
import { Wrapper, Title, CSVModalContainer } from "./styles";

export const ConnectionImportCard = (props: Props) => {
  const { connection } = props;
  const {
    importModalVisible,
    handleHideImportModal,
    shopifyModalVisible,
    handleHideShopifyModal,
    csvImportModalVisible,
    handleHideCsvImportModal,
    clickHandler,
    shopifyToken,
    infoLinkClickHandler
  } = useConnectionImportCard(props);

  const Footer = () =>
    connection.type === "ZAPIER" ? (
      <ConnectionFooterZapier
        description={connection.description}
        addtlInfoUrl={connection.addtlInfoUrl}
        addtlInfoText={connection.addtlInfoText}
        handleInfoLinkClick={infoLinkClickHandler}
      />
    ) : (
      <ConnectionFooter
        description={connection.description}
        addtlInfoUrl={connection.addtlInfoUrl}
        addtlInfoText={connection.addtlInfoText}
        handleInfoLinkClick={infoLinkClickHandler}
      />
    );

  return (
    <Wrapper>
      <Card
        isRecommended={connection.isRecommended}
        isLink
        padded={false}
        onClick={clickHandler}
      >
        {connection.isRecommended && connection.type !== "SHOPIFY" && (
          <Label bold small>
            Recommended
          </Label>
        )}

        {connection.type == "SHOPIFY" && (
          <Label bold small className={shopifyToken ? styles.greenColor : ""}>
            {shopifyToken ? "Connected" : "New!"}
          </Label>
        )}

        <Body textAlign="center">
          <img src={connection.imageSource} />
          <Title>{connection.title}</Title>
          <Footer />
        </Body>
      </Card>

      {shopifyModalVisible && shopifyToken && (
        <ShopifyAuthentication
          authorizationSuccess="true"
          onClickHide={handleHideShopifyModal}
        />
      )}

      {importModalVisible && (
        <CSVModalContainer>
          <ImportModal
            onClickHide={handleHideImportModal}
            connection={connection}
            onClickHide={handleHideImportModal}
          />
        </CSVModalContainer>
      )}

      {csvImportModalVisible && (
        <CSVModalContainer>
          <ImportCSVModal
            onClickHide={handleHideCsvImportModal}
            connection={connection}
          />
        </CSVModalContainer>
      )}
    </Wrapper>
  );
};

export default ConnectionImportCard;
