/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query dashboardCategories(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $accountId: Int
  ) {
    dashboardCategories(
      input: { startDate: $startDate, endDate: $endDate, accountId: $accountId }
    ) {
      category {
        name
        company {
          name
        }
        colorIndex
      }
      accounts {
        id
        uid
        name
        image
        provider
        platform
        description
      }
      impressionCount
      sentCount
      totalClicks
    }
  }
`;
