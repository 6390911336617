import React from "react";
import { useState, useEffect } from "react";
import styles from "./index.css";
import H3 from "typography/H3";
import Button from "buttons/Button";
import { createFlash } from "../../actions/flash";
import { connect } from "react-redux";
import CREATE_AI_CREDITS from "../../graphql/mutations/createAICredits";
import { useMutation } from "@apollo/react-hooks";
import Icon from "icons/Icon";

const AICredits = ({ flash, company }) => {
  const [aiCredits] = useMutation(CREATE_AI_CREDITS);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [price, setPrice] = useState(0);
  const [avaialbleCredits, setAvailableCredits] = useState(company.credits);
  useEffect(() => {
    setPrice(quantity * 2.5);
  }, [quantity]);
  const handleChange = value => {
    setQuantity(parseInt(value, 10)); // This will set the new quantity
  };

  const addCredits = async () => {
    if (quantity <= 0) {
      setError("Quantity must be greater than 0"); // Set an error message
      return;
    }

    setLoading(true);
    setError(null); // Reset error state
    try {
      const response = await aiCredits({
        variables: {
          quantity: quantity
        }
      });
      if (response) {
        const status = response.data.createAiCharge.response.success;
        if (status) {
          setAvailableCredits(response.data.createAiCharge.response.credits);
          setQuantity(0);
          flash("notice", "You added AI credits successfully.");
        } else {
          flash("error", "Something went wrong. Please try again.");
        }
      }
    } catch (error) {
      console.error("Error generating AI content", error);
      setError(error.message); // Set error message from catch block
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.manager}>
      <div className={styles.header}>
        <H3>AI Credits</H3>
        <p>Remaining Credits: {avaialbleCredits}</p>
      </div>
      {company?.subscriptionPlan?.amount &&
      company?.subscriptionPlan?.amount > 18 &&
      company?.subscriptionPlan?.amount < 20 ? (
        <div>
          <p>
            You are currently on a plan that does not support AI credits. Please
            upgrade your plan to add AI credits.
          </p>
        </div>
      ) : (
        <form>
          <div className={styles.form}>
            <div>
              <div>Quantity</div>
              <div className={styles.priceDetails}>
                <input
                  className={styles.forminput}
                  type="number"
                  step={1}
                  min={0}
                  onChange={e => handleChange(e.target.value)}
                  value={quantity}
                />
                <div>X</div>
                <div>$2.5</div>
                <div>=</div>
                <div className={styles.total}>
                  ${price} ({quantity * 100} Credits)
                </div>
              </div>
              <div> 1 Quantity = 100 Credits</div>
              <div className={styles.warning}>
                <Icon color="warning" type="exclamation-triangle" />
                Additional Inky credits expire monthly  
              </div>
              {error && <div className={styles.error}>{error}</div>}
            </div>
            <Button
              className={styles.button}
              disabled={loading}
              onClick={addCredits}
              type="primary"
              loading={loading}
            >
              Buy Credits
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AICredits);
