/* @flow */
import React, { useState } from "react";
import classNames from "classnames";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Label from "typography/Label";
import TextInput from "forms/TextInput";
import RadioButton from "forms/RadioButton2";

import styles from "./index.css";

import type {
  createInvitation as CreateInvitationResult,
  createInvitationVariables
} from "graphql-types/createInvitation";
import type { FlashType } from "reducers/flash";

type Props = {
  close: () => void,
  createInvitation?: ({
    variables: createInvitationVariables
  }) => Promise<{ data: CreateInvitationResult }>,
  createInvitationLoading?: boolean,
  flash: (type: FlashType, body: string) => void
};

type Role = "collaborator" | "contributor";

const CreateInvitation = (props: Props) => {
  const [email, setEmail] = useState<string>("");
  const [emailTouched, setEmailTouched] = useState<boolean>(false);
  const [role, setRole] = useState<Role>("collaborator");

  return (
    <Modal
      footer={
        <>
          <Button
            className={styles.buttonCancel}
            disabled={props.createInvitationLoading}
            onClick={props.close}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={email.trim() === "" || props.createInvitationLoading}
            onClick={async (): Promise<void> => {
              const createInvitation = props.createInvitation;
              if (!createInvitation) {
                return;
              }

              try {
                const resp = await createInvitation({
                  variables: {
                    input: {
                      user: {
                        email,
                        role
                      }
                    }
                  }
                });

                props.close();

                const errors = resp?.data?.createTeamMember?.errors || [];
                if (errors.length > 0) {
                  props.flash(
                    "error",
                    "An error occurred when trying to send the invitation. Please try again."
                  );
                } else {
                  props.flash(
                    "notice",
                    "The invitation was successfully sent!"
                  );
                }
              } catch (_err) {}
            }}
            type="primary"
          >
            Send invite
          </Button>
        </>
      }
      onClose={props.close}
      size="large"
      title="Invite team member"
    >
      <div className={styles.main}>
        <div className={styles.sectionField}>
          <Label>Email address</Label>
          <small className={styles.sectionFieldDescription}>
            Enter the email address of the person you’d like to invite. They’ll
            receive an email with instructions on how to activate their account.
          </small>
          <TextInput
            className={classNames(styles.email, {
              [styles.invalidEmail]: emailTouched && email.trim() === ""
            })}
            onChange={(newEmail: string): void => {
              setEmail(newEmail);
              setEmailTouched(true);
            }}
            placeholder="email@yourcompany.com"
            type="email"
            value={email}
          />
        </div>
        <div className={styles.sectionField}>
          <Label>Permission settings</Label>
          <small className={styles.sectionFieldDescription}>
            Choose what permissions to grant this team member:
          </small>
          <RadioButton
            buttonClassName={styles.radioButtonOverride}
            className={styles.sectionFieldInput}
            onClick={setRole}
            selected={role === "collaborator"}
            value="collaborator"
          >
            <>
              <div>Collaborator</div>
              <div className={styles.roleDescription}>
                Provides access to all features of Edgar except billing
                information. Collaborators can add/remove social accounts,
                add/remove posts, approve draft posts from Contributors,
                schedule, and publish.
              </div>
            </>
          </RadioButton>
          {/* <RadioButton
            buttonClassName={styles.radioButtonOverride}
            className={styles.sectionFieldInput}
            onClick={setRole}
            selected={role === "contributor"}
            value="contributor"
          >
            <>
              <div>Contributor</div>
              <div className={styles.roleDescription}>
                Member will NOT have access to billing, social accounts,
                scheduling, or publishing. Contributors may only add draft posts
                to the Pending tab of the library. All posts added by a
                Contributor must be approved by an Admin or Collaborator to
                publish.
              </div>
            </>
          </RadioButton>  */}
        </div>
      </div>
    </Modal>
  );
};

export default CreateInvitation;
