/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation updateRssFeed($input: UpdateRssFeedInput!) {
    updateRssFeed(input: $input) {
      rssFeed {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
