// @flow
import React from "react";
import { Icon } from "icons";
import tooltipper from "behavioral/tooltipper";

type Props = {
  value: string[],
  className: string
};

export const TooltipBody = ({ value }: Props) =>
  value.length === 1 ? (
    <div dangerouslySetInnerHTML={{ __html: value[0] }} />
  ) : (
    <ul>
      {value.map(warning => (
        <li key={warning} dangerouslySetInnerHTML={{ __html: warning }} />
      ))}
    </ul>
  );

const Warnings = ({ className }: Props) => (
  <Icon type="exclamation-triangle" color="warning" className={className} />
);

const closeDelay = 500;

export default tooltipper(TooltipBody, { closeDelay })(Warnings);
