/* @flow */
import { useRef, useState } from "react";

import type { Props, EditingType } from "./types.js";

const useEditImageModal = (props: Props) => {
  const cropperRef = useRef<HTMLImageElement | null>(null);
  const [editing, setEditing] = useState<EditingType>("Crop");

  const handleCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    if (props.format === "image/jpeg") {
      props.onEdit(cropper.getCroppedCanvas().toDataURL("image/jpeg"));
    } else {
      props.onEdit(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleSelectOption = (option: EditingType) => {
    setEditing(option);
    // const imageElement: any = cropperRef?.current;
    // const cropper: any = imageElement?.cropper;
    // if (newOption === "Crop") {
    //   cropper.enable();
    // } else {
    //   cropper.disable();
    // }
  };

  return { cropperRef, handleCrop, editing, handleSelectOption };
};

export default useEditImageModal;
