/* @flow */
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import GET_CATEGORY_CONTENTS from "queries/getCategoryContents";
import type { getContents_contents_data } from "graphql-types/getContents";

import type { IProps } from "./types.js";
import { buildPaginator } from "behavioral/withFilter";

const PER_PAGE = 25;
const usePosts = (props: IProps) => {
  const { selectedCategory, searchTerm, page: initialPage } = props;
  const [currentCategory, setCurrentCategory] = useState(selectedCategory);
  const [loadingContents, setLoadingContents] = useState(true);
  const [contents, setContents] = useState<getContents_contents_data[]>([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const apolloClient = useApolloClient();

  const fetchCategoryContents = async (category, page, searchTerm) => {
    setLoadingContents(true);

    try {
      const res: Promise<any> = await apolloClient.query({
        query: GET_CATEGORY_CONTENTS,
        variables: {
          filter: {
            status: "APPROVED",
            keywords: searchTerm,
            category
          },
          page: buildPaginator(page, PER_PAGE)
        },
        fetchPolicy: "no-cache"
      });
      const fetchedContents = get(res, "data.contents.data", []);
      const contentLength = get(res, "data.contents.totalFilteredContents", []);
      setContents(fetchedContents);
      setPageCount(Math.ceil(contentLength / PER_PAGE));
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingContents(false);
    }
    setCurrentCategory(category);
  };

  useEffect(() => {
    fetchCategoryContents(selectedCategory, page, searchTerm);
  }, [page, selectedCategory, searchTerm]);

  const handlePageChange = newPage => {
    setPage(newPage);
  };

  return { loadingContents, contents, page, pageCount, handlePageChange };
};

export default usePosts;
