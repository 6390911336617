/* @flow */
import React from "react";
import { connect } from "react-redux";

import Button from "buttons/Button";
import type { Dispatch } from "types";
import { Modal } from "layout/modals";
import { preventDanglingWords } from "util";
import { createFlash } from "actions/flash";
import PlanSelector from "components/SubscriptionManager/PlanSelector";

import SVGUpgrade from "./upgrade.svg";
import PulseGIF from "components/common/images/loading-pulse.svg";

import useUpgradeModal from "./use";
import type { Props } from "./types.js";
import {
  LoadingWrapper,
  UpgradeIcon,
  Title,
  Subtitle,
  PlanWrapper,
  ButtonWrapper
} from "./styles";

export const UpgradeModal = (props: Props) => {
  const { onClose, title, subTitle, flash, children, settingsButton } = props;
  const {
    loading,
    company,
    handleSelectPlan,
    selectedPlan,
    updateSubscription,
    updateSubscriptionLoading
  } = useUpgradeModal(props);

  return (
    <Modal size="large" onClose={onClose}>
      {children}

      {loading ? (
        <LoadingWrapper>
          <img src={PulseGIF} alt="Loading..." width="30" />
        </LoadingWrapper>
      ) : (
        <>
          <UpgradeIcon src={SVGUpgrade} />
          {title && <Title>{preventDanglingWords(title)}</Title>}
          {subTitle && (
            <Subtitle>
              {typeof subTitle === "string"
                ? preventDanglingWords(subTitle)
                : subTitle}
            </Subtitle>
          )}

          {children}

          <PlanWrapper>
            <PlanSelector
              displayProducts={false}
              alignment="center"
              company={company}
              flash={flash}
              selectPlan={handleSelectPlan}
              selectedPlan={selectedPlan}
              updateSubscription={updateSubscription}
              updateSubscriptionLoading={updateSubscriptionLoading}
              isPlanSelectorOpen={true}
              closePlanSelector={onClose}
            />
          </PlanWrapper>

          {settingsButton && (
            <ButtonWrapper>
              <Button href="/settings" type="primary">
                Continue
              </Button>
            </ButtonWrapper>
          )}
        </>
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  flash(type, body) {
    dispatch(createFlash(type, body));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(UpgradeModal);
