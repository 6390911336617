// @flow
import { reportException } from "errors";

export type ImageAnalysis = {
  size: number,
  type: string,
  width: number,
  height: number
};

export const ANALYSIS_ERROR_MESSAGE =
  "An error occured while analyzing the image.";

const analyzeImage = (file: File): Promise<ImageAnalysis> => {
  const { type, size } = file;
  const fr = new window.FileReader();
  const img = new window.Image();

  return new Promise((resolve, reject) => {
    const handleImageLoad = () => {
      resolve({
        size,
        type,
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    };

    const handleFileReaderLoad = ({ target: { result } }) => {
      img.src = result;
    };

    const handleError = ({ target: { error } }) => {
      reportException(error, "lib:images:analyzeImage");
      reject(ANALYSIS_ERROR_MESSAGE);
    };

    img.addEventListener("load", handleImageLoad);
    img.addEventListener("error", handleError);
    fr.addEventListener("load", handleFileReaderLoad);
    fr.addEventListener("error", handleError);
    fr.readAsDataURL(file);
  });
};

export default analyzeImage;
