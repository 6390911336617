/* @flow */
import React from "react";
import { withRouter } from "react-router-dom";
import isNumber from "lodash/isNumber";
import Nav from "./Nav";
import BulkActions from "./BulkActions";
import PendingContentInformation from "components/PendingContentInformation";
import ResultCount from "components/common/ResultCount";

import type { Location } from "react-router-dom";
import styles from "./index.css";

const isPendingActive = (pathname: string): boolean =>
  /contents\/pending/.test(pathname);

type Props = {
  location: Location,
  pendingCount: number,
  loading: boolean,
  totalCount: number,
  filteredCount: number,
  onUpdateAll: () => void,
  onDeleteAll: () => void
};

export const Header = ({
  location,
  totalCount,
  filteredCount,
  pendingCount
}: Props) => (
  <header className={styles.root}>
    <Nav className={styles.navTabs} pendingCount={pendingCount} />
    {isPendingActive(location.pathname) && (
      <PendingContentInformation className={styles.informationBox} />
    )}
    <BulkActions
      className={styles.bulkActions}
      loading={!isNumber(totalCount)}
    />
    <ResultCount
      className={styles.resultCount}
      totalCount={totalCount}
      filteredCount={filteredCount}
    />
  </header>
);

export default withRouter(Header);
