/* @flow */
import React from "react";
import pluralize from "pluralize";
import classNames from "classnames";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import styles from "./index.css";
import Caption from "typography/Caption";

type Props = {
  className?: string,
  totalCount: number,
  filteredCount: number
};

const ResultCount = (props: Props) => {
  const { className = "", totalCount, filteredCount } = props;
  const classes = classNames(styles.root, { [className]: !!className });

  if (typeof totalCount !== "number") {
    return (
      <Caption className={classes}>
        <SkeletonTheme color="var(--inky100)" highlightColor="var(--inky50)">
          <Skeleton width={100} />
        </SkeletonTheme>
      </Caption>
    );
  }

  if (totalCount === 0) {
    return null;
  }

  let text;

  if (totalCount === filteredCount) {
    text = `${totalCount} content ${pluralize("item", totalCount)}`;
  } else {
    text = `Showing ${filteredCount} of ${totalCount} content ${pluralize(
      "item",
      filteredCount
    )}`;
  }

  return <Caption className={classes}>{text}</Caption>;
};

export default ResultCount;
