/* @flow */
import React, { type Node } from "react";
import Category from "./Category";
import type { Category as CategoryType } from "graphql-types/category";
import styles from "./index.css";

type Props = {
  category: ?CategoryType,
  children?: Node
};

const CategoryBar = (props: Props) => {
  const { category, children } = props;
  return (
    <div className={styles.root}>
      <Category category={category} />
      {children}
    </div>
  );
};

export default CategoryBar;
