// @flow
import filesize from "filesize";
import { intersection } from "lodash";
import {
  PLATFORM_MAX_VIDEO_COUNT,
  VIDEO_REQUIREMENTS_BY_PROVIDER,
  PLATFORM_DISPLAY_NAME_MAP,
  PROVIDER_DISPLAY_NAME_MAP
} from "constants";
import { type AnalyzedVideo } from "lib/videos/analyzeVideo";
import type { Platform, Provider } from "graphql-types/loadContent";

export type VideoValidationRuleSet = {
  supported?: boolean,
  fileSize?: number,
  types?: string[],
  maxDimensions?: [number, number],
  minDimensions?: [number, number],
  maxDuration?: number | null,
  minDuration?: number,
  minAspectRatio?: number,
  maxAspectRatio?: number
};

export type VideoValidationResponse = {
  valid: boolean,
  errors: string[]
};

export type VideoValidationProvider =
  | Provider
  | "leastRestrictive"
  | "INSTAGRAM_BUSINESS_REELS";

export const videoRequirements = (
  provider: VideoValidationProvider
): VideoValidationRuleSet => {
  const providerParams = VIDEO_REQUIREMENTS_BY_PROVIDER[provider];

  return {
    supported: providerParams.SUPPORTS_VIDEO,
    fileSize: providerParams.MAX_UPLOADED_VIDEO_BYTES,
    types: providerParams.UPLOADED_VIDEO_SUPPORTED_FORMATS,
    minDimensions: providerParams.MIN_UPLOADED_VIDEO_DIMENSIONS,
    maxDimensions: providerParams.MAX_UPLOADED_VIDEO_DIMENSIONS,
    maxDuration: providerParams.MAX_UPLOADED_VIDEO_SECONDS,
    minDuration: providerParams.MIN_UPLOADED_VIDEO_SECONDS,
    minAspectRatio: providerParams.MIN_UPLOADED_VIDEO_ASPECT_RATIO,
    maxAspectRatio: providerParams.MAX_UPLOADED_VIDEO_ASPECT_RATIO
  };
};

export function mostStrictVideoLimit(platforms: Platform[]) {
  const leastStrictVideoLimit = {
    count: PLATFORM_MAX_VIDEO_COUNT.FACEBOOK,
    platformName: "Edgar"
  };

  return platforms.reduce(
    (limit, platform) =>
      PLATFORM_MAX_VIDEO_COUNT[platform] < limit.count
        ? {
          platformName: PLATFORM_DISPLAY_NAME_MAP[platform],
          count: PLATFORM_MAX_VIDEO_COUNT[platform]
        }
        : limit,
    leastStrictVideoLimit
  );
}

const validateVideo = (
  content: object,
  video: AnalyzedVideo,
  accountProviders: Provider[],
  instaReels?: boolean
): VideoValidationResponse => {
  const tiktokAccount = content.accountRelationships?.find(acc => acc?.account?.platform === 'TIKTOK');
  let maxVideoDuration = null;
  if (tiktokAccount) {
    maxVideoDuration = tiktokAccount.account.tiktokMeta.maxVideoPostDurationSec
  }
  const { fileSize, width, height, seconds, formats } = video;

  const errors = [];
  const providers =
    accountProviders.length > 0
      ? accountProviders
      : (["leastRestrictive"]: any);

  providers.forEach(provider => {
    const validations = videoRequirements(
      provider === "INSTAGRAM_BUSINESS" && instaReels
        ? "INSTAGRAM_BUSINESS_REELS"
        : provider
    );
    const providerName =
      provider === "leastRestrictive"
        ? "Edgar"
        : provider === "INSTAGRAM_BUSINESS" && instaReels
          ? "Instagram Reels"
          : PROVIDER_DISPLAY_NAME_MAP[provider];

    if (!validations.supported) {
      // if the provider doesn't even do video bail immediately.
      errors.push(`${providerName} does not support video uploads.`);
    } else {
      if (
        validations.types &&
        intersection(validations.types, formats).length === 0
      ) {
        // Don't bother trying to analyze anything else if it's not valid MPEG-4 or Quicktime.
        if (providerName === "Twitter") {
          errors.push(
            `${providerName} does not support this video format. Twitter only supports 16:9 or 1:1 aspect ratios.`
          );
        } else {
          errors.push(`${providerName} does not support this video format.`);
        }
      } else {
        if (validations.fileSize && (fileSize ?? 0) >= validations.fileSize) {
          errors.push(
            `${providerName} videos must be less than ${filesize(
              validations.fileSize
            )}.`
          );
        }

        if (validations.maxDimensions) {
          const [maxWidth, maxHeight] = validations.maxDimensions;
          if (
            maxWidth &&
            maxHeight &&
            ((width ?? 0) > maxWidth || (height ?? 0) > maxHeight)
          ) {
            const resolution = `${maxWidth}x${maxHeight}`;
            errors.push(
              `Video resolution higher than ${resolution} pixels not supported by ${providerName}`
            );
          }
        }

        if (validations.minDimensions) {
          const [minWidth, minHeight] = validations.minDimensions;
          if (
            minWidth &&
            minHeight &&
            ((width ?? 0) < minWidth || (height ?? 0) < minHeight)
          ) {
            const resolution = `${minWidth}x${minHeight}`;
            errors.push(
              `Video resolution lower than ${resolution} pixels not supported by ${providerName}`
            );
          }
        }
        if (maxVideoDuration && provider === 'TIKTOK') {
          if ((seconds ?? 0) >= maxVideoDuration) {
            errors.push(
              `${providerName} videos must be less than ${maxVideoDuration
              } seconds long.`
            );
          }
        }
        else if (
          validations.maxDuration &&
          (seconds ?? 0) >= validations.maxDuration
        ) {
          errors.push(
            `${providerName} videos must be less than ${validations.maxDuration
            } seconds long.`
          );
        }

        if (
          validations.minDuration &&
          (seconds ?? 0) < validations.minDuration
        ) {
          errors.push(
            `${providerName} videos must be at least ${validations.minDuration
            } seconds long.`
          );
        }

        if (
          provider === "INSTAGRAM_BUSINESS" &&
          validations.minAspectRatio &&
          validations.maxAspectRatio
        ) {
          const aspectRatio = (width ?? 0) / (height ?? 0);

          if (
            aspectRatio < validations.minAspectRatio ||
            aspectRatio > validations.maxAspectRatio
          ) {
            errors.push(
              `${providerName} must have an aspect ratio between ${validations.minAspectRatio
              } (9:16) and ${validations.maxAspectRatio} (16:9).`
            );
          }
        }
      }
    }
  });

  if (errors.length) {
    return { valid: false, errors };
  }
  return { valid: true, errors };
};

export default validateVideo;
