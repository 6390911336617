export const TOGGLE_NAVBAR_COLLAPSE = "TOGGLE_NAVBAR_COLLAPSE";

type ToggleNavbarCollapseAction = {
  type: "TOGGLE_NAVBAR_COLLAPSE",
  payload: boolean
};

export const toggleNavbarCollapseAction = (collapsed: boolean) =>
  ({
    type: TOGGLE_NAVBAR_COLLAPSE,
    payload: collapsed
  }: ToggleNavbarCollapseAction);

export type LayoutAction = ToggleNavbarCollapseAction;
