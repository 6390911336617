// @flow
import React from "react";
import tokenizeText from "lib/text/tokenizeText";
import getMentionTagAccountProvider from "lib/content/mentions/getMentionTagAccountProvider";
import getSocialMediaProfileUrl from "lib/content/mentions/getSocialMediaProfileUrl";
import { truncateTextTo, removeSchemeFromUrl } from "util";
import styles from "./index.css";
import type { Provider } from "graphql-types/loadComposer";
import type { RawRichTextEntityMap } from "types";

type Props = {
  value: string,
  mentions: string[],
  provider?: Provider,
  className?: string,
  omitTrailingLinks?: boolean,
  otherTextRequiredForLinkOmission?: boolean,
  truncateLinksTo?: number,
  omitLinkScheme?: boolean,
  newLineClassName?: string,
  rawRichTextEntityMap?: ?RawRichTextEntityMap
};

const renderToken = ({
  token,
  index,
  truncateLinksTo,
  omitLinkScheme,
  provider,
  rawRichTextEntityMap,
  newLineClassName = ""
}) => {
  switch (token.type) {
    case "phrase": {
      return <span key={index}>{token.value}</span>;
    }
    case "link": {
      const url = token.value.startsWith("http")
        ? token.value
        : `https://${removeSchemeFromUrl(token.value)}`;
      let displayUrl = token.value;
      displayUrl = omitLinkScheme
        ? removeSchemeFromUrl(displayUrl)
        : displayUrl;
      displayUrl = truncateLinksTo
        ? truncateTextTo(displayUrl, truncateLinksTo)
        : displayUrl;

      return (
        <a href={url} target="_blank" key={index}>
          {displayUrl}
        </a>
      );
    }
    case "newLine": {
      return <br className={newLineClassName} key={index} />;
    }
    case "mention": {
      return (
        <a
          key={index}
          href={getSocialMediaProfileUrl(
            token.value,
            provider,
            rawRichTextEntityMap
          )}
          target="_blank"
          className={provider === "TWITTER" ? styles.twitterMention : ""}
        >
          {token.value}
        </a>
      );
    }
    default:
      throw new Error("Unknown token type");
  }
};

const HtmlizedText = ({
  value,
  className,
  omitTrailingLinks,
  otherTextRequiredForLinkOmission,
  truncateLinksTo,
  omitLinkScheme,
  newLineClassName,
  mentions,
  rawRichTextEntityMap
}: Props) => (
  <div className={className}>
    {tokenizeText(value, {
      omitTrailingLinks,
      otherTextRequiredForLinkOmission,
      mentions,
      provider: getMentionTagAccountProvider(rawRichTextEntityMap)
    }).map((token, index) =>
      renderToken({
        token,
        index,
        truncateLinksTo,
        omitLinkScheme,
        newLineClassName,
        rawRichTextEntityMap,
        provider: getMentionTagAccountProvider(rawRichTextEntityMap)
      })
    )}
  </div>
);

export default HtmlizedText;
