import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 45px 0 0;
`;

export const Tabs = styled.div`
  display: flex;
  margin: 0 0 20px;
`;

export const Tab = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 14px;
  justify-content: center;
  padding: 10px;
  transition: all 0.2s ease-in-out;

  @media (min-width: 500px) {
    flex: 0 auto;
    font-size: 15px;
    padding: 10px 16px;
  }

  &:hover,
  &.active {
    background: rgba(130, 197, 203, 0.25);
    border-color: rgba(130, 197, 203, 0.25);
    color: #4d948f;
  }

  &:nth-child(1) {
    margin: 0 10px 0 0;
  }
`;
