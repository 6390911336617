// @flow
import React from "react";
import styles from "./index.css";

type Props = {
  label: string,
  value: number,
  bound: number
};

const ProgressBar = ({ label, value, bound }: Props) => (
  <div className={styles.root}>
    <span className={styles.label}>{label}</span>
    <span>
      <span className={styles.value}>{value}</span>
      <span className={styles.bound}> of {bound}</span>
    </span>
    <div className={styles.meterContainer}>
      <div
        className={styles.meter}
        style={{ width: `${(value / bound) * 100}%` }}
      />
    </div>
  </div>
);

export default ProgressBar;
