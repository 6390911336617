/* @flow */
import React, { type Node } from "react";
import DropDown from "layout/DropDown";
import Link from "links/Link";
import Icon from "icons/Icon";
import Badge from "typography/Badge";
import TextBubble from "icons/TextBubble";
import ResumeOnboardingLink from "./ResumeOnboardingLink";

import { featureFlag, useNewUICached } from "util";

import type { CompanyStatus } from "types";

import styles from "./index.css";
import UISwitch from "./UISwitch";

type MenuItemProps = {
  label: string,
  url: string,
  activePage: string,
  children?: any
};

type Props = {
  isAdmin: boolean,
  pendingCount: number,
  open: boolean,
  companyStatus: CompanyStatus,
  userName: string,
  activePage: string,
  mobileView: boolean,
  onCloseMenu: () => void
};

export function MenuItem({ url, activePage, label, children }: MenuItemProps) {
  const isActive = (activePage ?? "").includes(url);

  return (
    <li className={styles.item}>
      <Link
        className={isActive ? styles.activeLink : styles.link}
        url={url}
        key={url}
      >
        {label}
      </Link>
      {children}
    </li>
  );
}

export function Alert({ url }: { url: string }) {
  return (
    <Link url={url} className={styles.alert}>
      <Icon type="exclamation-circle" />
    </Link>
  );
}

export const MainNav = (props: Props) => {
  const [dropDownOpen, setDropDownOpen] = React.useState(false);
  const useNewUI = useNewUICached();

  const onToggleDropDown = (): void => {
    setDropDownOpen(!dropDownOpen);
  };

  const onCloseDropDown = (): void => {
    setDropDownOpen(false);
  };

  const renderLinks = () => {
    const {
      companyStatus: { hasAccess, hasDisabledAccounts },
      pendingCount
    } = props;

    let items: Node[] = [];
    if (hasAccess && props.activePage != '/payment?payment=true') {
      items = items.concat([
        <MenuItem
          label="Library"
          url="/contents"
          key="library"
          activePage={props.activePage}
        >
          {pendingCount > 0 && (
            <TextBubble type="count" className={styles.pendingCount}>
              {pendingCount}
            </TextBubble>
          )}
        </MenuItem>,
        <MenuItem
          label="Queue"
          url="/queue"
          key="queue"
          activePage={props.activePage}
        />,
        <MenuItem
          label="Schedule"
          url="/schedule"
          key="schedule"
          activePage={props.activePage}
        />,
        featureFlag("fb_comments") && (
          <MenuItem
            label="Inbox"
            url="/inbox"
            key="inbox"
            activePage={props.activePage}
          >
            <TextBubble type="count" className={styles.newBadge}>
              <span>new</span>
            </TextBubble>
          </MenuItem>
        ),
        <MenuItem
          label="Import"
          url="/import"
          key="import"
          activePage={props.activePage}
        />,
        <MenuItem
          label="Categories"
          url="/categories"
          key="categories"
          activePage={props.activePage}
        />,
        <MenuItem
          label="Accounts"
          url="/accounts"
          key="accounts"
          activePage={props.activePage}
        >
          {hasDisabledAccounts && <Alert url="/accounts" />}
        </MenuItem>,
        <MenuItem
          label="History"
          url="/history"
          key="history"
          activePage={props.activePage}
        />
      ]);
    }
    return items;
  };

  const renderUser = () => {
    if (props.mobileView) {
      return null;
    }
    return (
      <span>
        {props.companyStatus.errorCount > 0 ? <Alert url="/errors" /> : null}
        <Link className={styles.link} onClick={onToggleDropDown}>
          <div className={styles.username}>
            <span>{props.userName}</span>
            <Icon type="caret-down" />
          </div>
        </Link>
      </span>
    );
  };

  const renderUserMenu = () => {
    return [
      featureFlag("navbar_modules_ui") && (
        <UISwitch useNewUI={useNewUI} key="ui-switch" />
      ),
      props.companyStatus.errorCount > 0 ? (
        <Link menuLink url="/errors" key="errors">
          Notifications &nbsp;
          <Badge type="danger">{props.companyStatus.errorCount}</Badge>
        </Link>
      ) : null,
      <Link menuLink url="/settings" key="user">
        Settings
      </Link>,
      <Link
        menuLink
        url="https://help.meetedgar.com/"
        target="_blank"
        key="help"
      >
        Help center
      </Link>,
      <Link
        menuLink
        url="https://affiliates.meetedgar.com/"
        target="_blank"
        key="share"
      >
        Share Edgar 💰
      </Link>,
      <ResumeOnboardingLink key="resumeOnboaringLink" />,
      renderAdminLink(),
      <Link menuLink url="/users/sign_out" method="DELETE" key="sign_out">
        Sign out
      </Link>
    ];
  };

  const renderAdminLink = () => {
    const { isAdmin } = props;

    if (!isAdmin) {
      return null;
    }

    return (
      <Link menuLink url="/admin/dashboard" key="admin">
        Admin
      </Link>
    );
  };

  return (
    <div className={props.open ? styles.open : styles.root} role="navigation">
      <a className={styles.close} href="#" onClick={props.onCloseMenu}>
        {" "}
        x{" "}
      </a>
      <ul className={styles.list}>
        {renderLinks()}
        <li className={styles.item}>
          {renderUser()}
          {props.mobileView ? (
            <div className={styles.mobileUserMenu}>{renderUserMenu()}</div>
          ) : (
            <DropDown open={dropDownOpen} onClose={onCloseDropDown}>
              {renderUserMenu()}
            </DropDown>
          )}
        </li>
      </ul>
    </div>
  );
};

export default MainNav;
