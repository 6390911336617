/* @flow */
import React from "react";
import tooltipper from "behavioral/tooltipper";
import Icon from "icons/Icon";
import Caption from "typography/Caption";

import styles from "./index.css";

export const InstagramPersonalCaption = () => {
  return (
    <>
      <a
        href="https://help.meetedgar.com/en/articles/2480839-connecting-instagram-accounts-to-edgar"
        target="_blank"
        className={styles.link}
      >
        Mobile Reminder Manual Posting Only
      </a>
      <Tooltip className={styles.tooltip} />
    </>
  );
};

const TooltipIcon = () => <Icon type="info-circle" color="textMid" />;

const TipBody = () => (
  <Caption textAlign="center">
    Personal Instagram profiles can publish with manual mobile reminders only.
    To activate direct posting, convert this account to an Instagram business
    profile on the Accounts page.
  </Caption>
);

const Tooltip = tooltipper(TipBody)(TooltipIcon);

export default InstagramPersonalCaption;
