/* @flow */
import React, { useState } from "react";
import { connect } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { remove } from "lodash";
import { preventDanglingWords } from "util";

import { GET_CATEGORIES_WITH_TIMESLOT_COUNT } from "queries/category";
import {
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
  SHUFFLE_CATEGORY
} from "mutations/category";
import { createFlash } from "actions/flash";

import Confirmation from "layout/modals/Confirmation";
import { Caption } from "typography";
import CategoryCard from "./CategoryCard";
import CategoryActionModal from "../CategoryActionModal";
import styles from "./index.css";

import type { Dispatch } from "types";
import type { Category } from "graphql-types/category";

type Props = {
  dispatch: Dispatch,
  categories: Category[]
};

export function CategoryList({ dispatch, categories = [] }: Props) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editModalErrors, setEditModalErrors] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({});

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    update(
      cache,
      {
        data: {
          deleteCategory: { deletedId }
        }
      }
    ) {
      handleOnClickCloseDeleteCategoryConfirmation();

      const { categories } = cache.readQuery({
        query: GET_CATEGORIES_WITH_TIMESLOT_COUNT
      });

      remove(categories, { id: String(deletedId) });

      cache.writeQuery({
        query: GET_CATEGORIES_WITH_TIMESLOT_COUNT,
        data: { categories }
      });

      dispatch(createFlash("notice", "Category was deleted."));
    },
    refetchQueries: [{ query: GET_CATEGORIES_WITH_TIMESLOT_COUNT }]
  });

  const [updateCategory] = useMutation(UPDATE_CATEGORY, {
    update(
      cache,
      {
        data: {
          updateCategory: { errors }
        }
      }
    ) {
      setEditModalErrors(errors);

      if (errors.length) {
        return;
      }

      handleOnClickCloseEditCategoryModal();
      dispatch(createFlash("notice", "Category was successfully updated."));
    }
  });

  const [shuffleCategory] = useMutation(SHUFFLE_CATEGORY);

  function handleOnClickDelete() {
    deleteCategory({ variables: { id: currentCategory.id } });
  }

  function handleOnClickEdit(category) {
    setCurrentCategory(category);
    setShowEditModal(true);
  }

  function handleOnClickCloseEditCategoryModal() {
    setShowEditModal(false);
    setCurrentCategory({});
    setEditModalErrors([]);
  }

  async function handleOnClickSaveCategory(categoryId, name, useForRandom) {
    await updateCategory({
      variables: {
        input: {
          id: categoryId,
          name,
          useForRandom
        }
      }
    });
  }

  function handleOnClickDeleteCategory(category) {
    setCurrentCategory(category);
    setShowDeleteConfirmation(true);
  }

  function handleOnClickCloseDeleteCategoryConfirmation() {
    setCurrentCategory({});
    setShowDeleteConfirmation(false);
  }

  async function handleOnClickShuffle(category) {
    await shuffleCategory({ variables: { id: category.id } });

    dispatch(
      createFlash(
        "notice",
        `Edgar has begun shuffling content in ${category.name}`
      )
    );
  }

  function renderCategories() {
    return categories.length ? (
      categories.map(category => (
        <CategoryCard
          key={category.id}
          {...category}
          handleOnClickEdit={handleOnClickEdit.bind(null, category)}
          handleOnClickDelete={handleOnClickDeleteCategory.bind(null, category)}
          handleOnClickShuffle={handleOnClickShuffle.bind(null, category)}
        />
      ))
    ) : (
      <SkeletonTheme color="var(--inky100)" highlightColor="var(--inky50)">
        <div className={styles.skeleton}>
          <Skeleton
            width={470}
            height={125}
            wrapper={({ children }) => (
              <div className={styles.skeletonWrapper}>{children}</div>
            )}
            count={8}
          />
        </div>
      </SkeletonTheme>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.categoriesContainer}>{renderCategories()}</div>

      {showEditModal && (
        <CategoryActionModal
          errors={editModalErrors}
          category={currentCategory}
          handleClose={handleOnClickCloseEditCategoryModal}
          handleSubmit={handleOnClickSaveCategory}
        />
      )}

      {showDeleteConfirmation && (
        <Confirmation
          type="error"
          title={`
            Warning! All content in this category, as well as any RSS feeds, posting history, and scheduled time slots, will be deleted.
          `}
          confirmLabel="Yes, delete"
          confirmType="primary-negative"
          onConfirm={handleOnClickDelete}
          cancelLabel="Cancel"
          onCancel={handleOnClickCloseDeleteCategoryConfirmation}
        >
          <Caption helpText className={styles.subtitle}>
            <span className={styles.subtitleWarning}>
              You can't undo this action!{" "}
            </span>
            {preventDanglingWords("Edgar cannot recover deleted content.")}
          </Caption>
        </Confirmation>
      )}
    </div>
  );
}

export default connect()(CategoryList);
