import styled from "styled-components";

export const Wrapper = styled.div`
  background: #20648b;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 992px;
  margin: 0 auto;
  width: 100%;

  img {
    width: 120px;
  }

  a {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex: 1;
  margin: 30px 0;
`;

export const WhiteBox = styled.div`
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  max-width: 768px;
  padding: 30px;
  width: 100%;
`;

export const Title = styled.h3`
  border-bottom: 1px solid #e6e6e6;
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 20px;
  padding: 0 0 7.5px;
`;

export const FeedbackText = styled.div`
  font-style: italic;
  margin-top: 3px;
`;

export const ErrorSending = styled.div`
  color: var(--coral500);
  text-align: right;
  font-style: italic;
  margin: 5px 0 0 0;
`;
