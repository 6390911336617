/* @flow */
import { uniq, flatMap } from "lodash";
import pluralize from "pluralize";
import { isValidUrl } from "util";
import {
  TWITTER_DM_ERROR_MSG,
  PLATFORMS_REQUIRING_MEDIA,
  PLATFORM_DISPLAY_NAME_MAP,
  VIDEO_REQUIREMENTS_BY_PLATFORM
} from "constants";
import { remainingCharacterCounts } from "../../util.js";

import type {
  loadContent_content as ContentData,
  loadContent_content_variations as VariationData,
  loadContent_content_accountRelationships_account as AccountData,
  loadContent_content_variations_pageScrape as PageScrapeData,
  Platform,
  Provider
} from "graphql-types/loadContent";

export type ClientErrorsByField = {
  variations: VariationErrors
};
type VariationTextUsage = {
  [key: string]: number[]
};
type PageScrapesByVariation = {
  [key: string]: ?PageScrapeData
};

type VariationErrors = {
  [key: string]: {
    [key: string]: string[]
  }
};

const LINK_ATTACHMENT_PLATFORMS = ["FACEBOOK", "LINKEDIN"];

function validate(
  content: ?ContentData,
  pageScrapesByVariation: PageScrapesByVariation
): ClientErrorsByField {
  const variations = content?.variations ?? [];
  const accounts = uniq(
    (content?.accountRelationships ?? []).map(({ account }) => account)
  );
  const platforms = uniq(accounts.map(({ platform }) => platform));
  const providers = uniq(accounts.map(({ provider }) => provider));

  return {
    variations: mergeVariationErrors([
      duplicateTextErrors(variations),
      fbTextErrors(variations, platforms),
      invalidPinterestDestinationLinkErrors(
        variations,
        pageScrapesByVariation,
        platforms
      ),
      twitterDMErrors(variations, providers),
      overLimitErrors(variations, accounts),
      mediaRequiredErrors(variations, platforms)
    ])
  };
}

function mergeVariationErrors(errorObjs: VariationErrors[]): VariationErrors {
  const clientIds = uniq(flatMap(errorObjs, errorObj => Object.keys(errorObj)));
  return clientIds.reduce((result, clientId) => {
    result[clientId] = result[clientId] || {};
    errorObjs.forEach(errorObj => {
      const fields = errorObj[clientId];
      if (typeof fields !== "object") {
        return;
      }
      Object.keys(fields).forEach(field => {
        const errors = errorObj[clientId][field];
        if (Array.isArray(errors)) {
          result[clientId][field] = (result[clientId][field] || []).concat(
            errors
          );
        }
      });
    });
    return result;
  }, {});
}

function mediaRequiredErrors(
  variations: VariationData[],
  platforms: Platform[]
): VariationErrors {
  return variations.reduce((result, { clientId, link, videos, images }) => {
    if (images.length > 0) {
      return result;
    }

    let selectedPlatformsRequiringMedia = platforms.filter(p =>
      PLATFORMS_REQUIRING_MEDIA.includes(p)
    );

    if (videos.length > 0) {
      selectedPlatformsRequiringMedia = selectedPlatformsRequiringMedia.filter(
        platform => !VIDEO_REQUIREMENTS_BY_PLATFORM[platform].SUPPORTS_VIDEO
      );
    }

    if (selectedPlatformsRequiringMedia.length === 0) {
      return result;
    }

    const hasLinkPreviewAttachment =
      !!link && platforms.some(p => LINK_ATTACHMENT_PLATFORMS.includes(p));

    return {
      [clientId]: {
        media: selectedPlatformsRequiringMedia.map(
          platform =>
            `${PLATFORM_DISPLAY_NAME_MAP[platform]} requires an image${
              VIDEO_REQUIREMENTS_BY_PLATFORM[platform].SUPPORTS_VIDEO
                ? " or a video"
                : ""
            }${
              hasLinkPreviewAttachment
                ? ", you can remove the link preview to attach one"
                : ""
            }.`
        )
      }
    };
  }, {});
}

function overLimitErrors(
  variations: VariationData[],
  accounts: AccountData[]
): VariationErrors {
  return variations.reduce((result, { clientId, text }) => {
    const countsByProvider = remainingCharacterCounts(accounts, text ?? "");
    const countExceeded = Object.values(countsByProvider).some(
      c => typeof c == "number" && c < 0
    );

    if (!countExceeded) {
      return result;
    }

    return {
      ...result,
      [clientId]: {
        text: ["Character count exceeded"]
      }
    };
  }, {});
}

function variationFormattedAsDM(providers, variationText) {
  const TWITTER_DM_REGEX = new RegExp(/^(D|M|DM)\s+/i);
  const hasTwitter = providers.some(p => p === "TWITTER");
  const formattedAsDM = (variationText ?? "").match(TWITTER_DM_REGEX);
  return hasTwitter && formattedAsDM;
}

function twitterDMErrors(
  variations: VariationData[],
  providers: Provider[]
): VariationErrors {
  return variations.reduce((result, { clientId, text }) => {
    if (variationFormattedAsDM(providers, text)) {
      return {
        ...result,
        [clientId]: {
          text: [TWITTER_DM_ERROR_MSG]
        }
      };
    }
    return result;
  }, {});
}

function invalidPinterestDestinationLinkErrors(
  variations: VariationData[],
  pageScrapesByVariation: PageScrapesByVariation,
  platforms: Platform[]
): VariationErrors {
  if (!platforms.includes("PINTEREST")) {
    return {};
  }

  return variations.reduce((result, { clientId, pinterestDestinationLink }) => {
    const destinationLink =
      pinterestDestinationLink || pageScrapesByVariation?.[clientId]?.url;

    return destinationLink &&
      (!isValidUrl(destinationLink) ||
        destinationLink.match(/(bit\.ly|tiny\.url|snip\.ly|rebrand\.ly)/))
      ? {
          ...result,
          [clientId]: {
            pinterestDestinationLink: [
              "Pinterest destination link must be a valid url and may not contain short links."
            ]
          }
        }
      : result;
  }, {});
}

function fbTextErrors(
  variations: VariationData[],
  platforms: Platform[]
): VariationErrors {
  if (!platforms.includes("FACEBOOK")) {
    return {};
  }

  // If no variations have an auto origin there shouldn't be any fbText to validate
  const variationsRequiringFbText = variations.filter(
    v => !!v.origin.match("RSS|WIDGET|AUTOGENERATED")
  );
  if (variationsRequiringFbText.length === 0) {
    return {};
  }

  // If a variation has fbText it also needs to have text
  return variations
    .filter(
      ({ fbText, text }) =>
        (fbText ?? "").trim() !== "" && (text ?? "").trim() === ""
    )
    .reduce(
      (result, { clientId }) => ({
        ...result,
        [clientId]: {
          text: ["Text is required for variations that have facebook text."]
        }
      }),
      {}
    );
}

function duplicateTextErrors(variations: VariationData[]): VariationErrors {
  const textUsage = variationTextUsage(variations);

  return variations.reduce((result, { clientId, text, images, videos }) => {
    const trimmedText = (text ?? "").trim();
    const memoKey = textUsageKey(trimmedText, images, videos);
    const usage = textUsage[memoKey] || [];

    if (usage.length > 1) {
      return {
        ...result,
        [clientId]: {
          text: [duplicateMessage(usage)]
        }
      };
    }
    return result;
  }, {});
}

function duplicateMessage(indexes) {
  return [
    "This variation is the same as",
    pluralize("variation", indexes.length),
    indexes.map(index => index + 1).join(", ")
  ].join(" ");
}

function variationTextUsage(variations: VariationData[]): VariationTextUsage {
  return variations.reduce((memo, { text, images, videos }, idx) => {
    const trimmedText = (text ?? "").trim();
    const memoKey = textUsageKey(trimmedText, images, videos);

    // If variation text is a blank string don't consider as a usage since blank
    // variations will be ignored when persisting
    if (trimmedText.length === 0) {
      return memo;
    }

    if (memo[memoKey]) {
      memo[memoKey].push(idx);
    } else {
      memo[memoKey] = [idx];
    }

    return memo;
  }, {});
}

function textUsageKey(trimmedText, images, videos) {
  const imageIds = images.map(({ id }) => id).sort();
  const videoIds = videos.map(({ id }) => id).sort();

  return [trimmedText, imageIds, videoIds].toString();
}

export default validate;
