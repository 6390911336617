/* @flow */
import React from "react";

import { Icon } from "icons";
import AccountAvatar from "images/AccountAvatar";
import AccountProviderIcon from "icons/AccountProviderIcon";
import TextInput from "forms/TextInput";

import useAccount from "./use";
import type { Props } from "./types.js";
import {
  Item,
  Left,
  Image,
  ProviderWrapper,
  Right,
  Input,
  Remove
} from "./styles";

const VariationAccounts = (props: Props) => {
  const { item, onChangeAccountText, removeVariationAccount } = props;
  const { customText } = useAccount(props);
  return (
    <Item key={item.id}>
      <Left>
        <Image>
          <AccountAvatar src={item.image} alt={item.provider} />
          <ProviderWrapper>
            <AccountProviderIcon provider={item.provider} />
          </ProviderWrapper>
        </Image>
      </Left>
      <Right>
        <Input className="textareaInput">
          <TextInput
            id={`variation-account-${item.id}`}
            value={customText}
            multiline
            autogrow
            monitorExternalChanges
            clear
            rows={5}
            onChange={text => onChangeAccountText(item.id, text)}
            onBlur={() => { }}
          />
        </Input>
        <Remove>
          <span onClick={() => removeVariationAccount(item.id)}>
            <Icon id="deleteAccountButton" type="trash" onClick={() => { }} />
            <span>Remove</span>
          </span>{" "}
          content for {item.providerDisplayName}
        </Remove>
      </Right>
    </Item>
  );
};

export default VariationAccounts;
