import styled from "styled-components";

export const Wrapper = styled.div``;

export const Current = styled.div`
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  margin: 8px 0 17.5px;
  padding: 6px 10px;

  svg {
    margin-left: 10px;
  }

  &:hover,
  &.active {
    background-color: #efefef;
  }
`;

export const Help = styled.div`
  cursor: pointer;
  font-size: 13px;
  margin-top: 10px;
`;

export const Loading = styled.div`
  align-items: center;
  display: flex;

  span {
    color: #555;
    font-style: italic;
    margin-left: 7.5px;
  }
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
`;

export const Icon = styled.div`
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;

  &.facebook {
    background-color: var(--facebook);
  }

  &.instagram {
    background-color: var(--instagram);
  }

  img {
    height: 20px;
    width: 20px;
  }
`;

export const AccountNames = styled.div`
  line-height: normal;
  margin-left: 5px;

  ${({ isEmpty }) => isEmpty && `margin-left: 0;`}
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const ProviderName = styled.div`
  color: #999;
  font-size: 12px;
`;

export const DropdownWrapper = styled.div`
  margin: -10px 0 0 10px;
  position: absolute;
`;

export const DropdownAccount = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
`;
