/* @flow */
import { useState, useEffect } from "react";

import type { Props } from "./types.js";

const useVariationAccounts = (props: Props) => {
  const { item: account, variation } = props;
  const [variationText, setVariationText] = useState("");
  const customText = getCustomText();
  function getCustomText() {
    if (variation) {
      const { accountsData } = variation;
      if (accountsData) {
        const accountData = accountsData.find(
          item => item.accountId === account.id
        );
        if (accountData) {
          return accountData.text;
        }
      }
    }
    return "";
  }
  useEffect(() => {
    if (variation) {
      const { accountsData } = variation;
      if (accountsData) {
        const accountData = accountsData.find(
          item => item.accountId === account.id
        );
        if (accountData) {
          setVariationText(accountData.text);
        }
      }
    }
  }, [account.id, props.variation, variation]);

  return { variationText, customText };
};

export default useVariationAccounts;
