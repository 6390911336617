/* @flow */
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import type { AccountProvider } from "types";
import defaultAvatar from "./default_avatar.png";
import styles from "./index.css";

type Props = {
  src: string,
  provider: AccountProvider,
  className: string
};

const AccountAvatar = ({ src, provider, className }: Props) => {
  const [displaySrc, setDisplaySrc] = useState(src);
  useEffect(() => setDisplaySrc(src), [src]);

  // Fall back to the blank avatar image if the src avatar is broken
  function handleError() {
    setDisplaySrc(defaultAvatar);
  }

  return (
    <img
      src={displaySrc}
      alt={provider}
      className={classnames(styles.root, className)}
      onError={handleError}
    />
  );
};

export default AccountAvatar;
