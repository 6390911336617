import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0;
  text-align: center;
`;

export const Title = styled.div`
  font-size: 18px;
  margin: 0 0 15px;
  font-weight: 600;
`;

export const Description = styled.div`
  font-size: 14px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px auto 0;
  max-width: 210px;
`;
