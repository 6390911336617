/* @flow */
/* global EventHandler */
import React from "react";
import Approve from "../buttons/Approve";
import Edit from "../buttons/Edit";
import Reject from "../buttons/Reject";
import styles from "./index.css";

type Props = { contentId: string };

const Pending = ({ contentId }: Props) => (
  <div className={styles.root}>
    <Edit contentId={contentId} type="secondary" />
    <Reject contentId={contentId} />
    <Approve contentId={contentId} />
  </div>
);

export default Pending;
