import styled from "styled-components";

export const breakpointWidth = 701;

export const Wrapper = styled.div``;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  display: flex;
  margin: 0 0 7px;
  padding: 17.5px 0 15px;

  h3 {
    flex: 1;
    font-size: 18px;
    font-weight: 600;
    max-width: 280px;
  }

  @media (min-width: ${breakpointWidth}px) {
    border-bottom: none;
    margin: 0;
    padding: 25px 0;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpointWidth}px) {
    flex-direction: row;
  }
`;

export const LeftWrapper = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const RightWrapper = styled.div`
  flex: 4;
  overflow: hidden;
  margin: 30px 0 0;
  padding-top: var(--midVertGutter);

  > * {
    margin-bottom: var(--midVertGutter);
  }

  @media (min-width: ${breakpointWidth}px) {
    margin: 0 0 0 var(--midHorzGutter);
    padding-bottom: 70px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const HeaderTabs = styled.div`
  display: none;

  @media (min-width: ${breakpointWidth}px) {
    display: block;
  }
`;

export const MobileTabs = styled.div`
  display: block;

  @media (min-width: ${breakpointWidth}px) {
    display: none;
  }
`;
