// @flow
import React from "react";
import camelize from "camelize";
import { getUrlDomain, openWindowToUrl } from "util";
import ScaledImage from "components/common/ScaledImage";
import noImageSvg from "./images/noImage.svg";
import playButtonSvg from "./images/playButton.svg";
import styles from "./index.css";

import type { scrapeUrl_scrapeUrl_pageScrape as PageScrapeData } from "graphql-types/scrapeUrl";

type Props = {
  pageScrape: PageScrapeData
};

export const IMAGE_SIZE_BY_TYPE = {
  player: { width: 125, height: 125 },
  summary: { width: 125, height: 125 },
  summary_large_image: { width: 506, height: 254 }
};

export default class Card extends React.Component<Props> {
  handleClick = () => {
    const {
      pageScrape: { url }
    } = this.props;
    if (url) {
      openWindowToUrl(url);
    }
  };

  handleLinkValidatorClick = (evt: Event) => {
    openWindowToUrl("https://cards-dev.twitter.com/validator");
    evt.stopPropagation();
  };

  render() {
    const { pageScrape } = this.props;
    const { status, destinationUrl, twitterCardType: type } = pageScrape;

    const title = pageScrape.twitterTitle || pageScrape.title;
    const description = pageScrape.twitterDescription || pageScrape.description;
    const image = pageScrape.twitterImage || pageScrape.images?.[0]?.src;

    return (
      <div
        onClick={this.handleClick}
        className={`${styles.root} ${styles[camelize(type)]}`}
      >
        <div className={styles.wrap}>
          <div className={styles.imageContainer}>
            <div className={styles.imageWrap}>
              {type === "player" && (
                <img
                  src={playButtonSvg}
                  className={styles.playButton}
                  alt="play button"
                />
              )}
              {image && type ? (
                <ScaledImage
                  value={image}
                  targetWidth={IMAGE_SIZE_BY_TYPE[type].width}
                  targetHeight={IMAGE_SIZE_BY_TYPE[type].height}
                  className={styles.image}
                />
              ) : (
                <div className={styles.noImage}>
                  <img src={noImageSvg} alt="Generic document icon" />
                </div>
              )}
            </div>
          </div>
          <div className={styles.content}>
            {status !== "unavailable" ? (
              <div>
                <h2>{title}</h2>
                <p>{description}</p>
              </div>
            ) : (
              <div>
                <h2>
                  Twitter will generate a Twitter Card for this link when the
                  post is published.
                </h2>
                <p>
                  To check out the card, head over to{" "}
                  <a onClick={this.handleLinkValidatorClick}>
                    Twitter's link validator
                  </a>
                  .
                </p>
              </div>
            )}
            <span data-test="destination">
              {destinationUrl &&
                getUrlDomain(destinationUrl).replace("www.", "")}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
