import { useMutation, useQuery, useApolloClient } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import GENERATE_AI_CONTENT from "../../../../../graphql/mutations/generateAiContent";
import AI_CONVERSATIONS from "../../../../../graphql/queries/aiConversations";

const useAIGenerator = selectedProviders => {
  const [aiContent, setAiContent] = useState({});
  const [credits, setCredits] = useState(0);
  const { data, refetch } = useQuery(AI_CONVERSATIONS);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    tone: "happy",
    prompt: "",
    hasTag: true
  });

  // Define the mutation outside of generateContent
  const [generateAiContent] = useMutation(GENERATE_AI_CONTENT);

  useEffect(() => {
    if (data && data.aiConversations) {
      setAiContent(data.aiConversations.conversations);
      setCredits(data.aiConversations.contentLimit);
    }
  }, [data]);

  const handleUpdateParams = (field, value) => {
    setParams(prevParams => ({
      ...prevParams,
      [field]: value
    }));
  };

  const generateContent = async () => {
    setLoading(true);
    try {
      const response = await generateAiContent({
        variables: {
          input: { ...params }
        }
      });

      if (response && response.data) {
        const oldAiContent = [...aiContent];
        oldAiContent.push(response.data.generateContent.conversation);
        setAiContent(oldAiContent);
        setCredits(response.data.generateContent.credits);
        handleUpdateParams("prompt", "");
      }
    } catch (error) {
      console.error("Error generating AI content", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    aiContent,
    generateContent,
    handleUpdateParams,
    params,
    credits,
    loading
  };
};

export default useAIGenerator;
