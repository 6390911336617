/* @flow */
import React from "react";
import { Element } from "react-scroll";

import { DAYS } from "constants";
import { Label } from "typography";
import DropDown from "layout/DropDown";

import SVGIcon from "./icon.svg";

import {
  Wrapper,
  LabelIcon,
  DropDownWrapper,
  DropDownContent,
  Item
} from "../SendAt/styles";
import { Value } from "../SendOn/styles";

import useRepeatDay from "./use";
import type { IProps } from "./types.js";

const DAY_OPTIONS = DAYS.map(day => ({ value: day, label: day }));

const ScheduleRepeatDay = (props: IProps) => {
  const {
    selectedDay,
    setSelectedDay,
    recurring,
    setRecurring,
    readOnly
  } = props;
  const { dropdownVisible, hideDropdown, showDropdown } = useRepeatDay();

  return (
    <>
      <Wrapper>
        <LabelIcon>
          <img src={SVGIcon} alt="repeat day icon" />
        </LabelIcon>
        <Label htmlFor="repeatsOn">Repeats on</Label>
        <Value
          onClick={readOnly ? undefined : showDropdown}
          className={readOnly ? "readOnly" : ""}
        >
          {!recurring ? "Does not repeat" : selectedDay}
        </Value>

        <DropDownWrapper>
          <DropDown
            open={dropdownVisible}
            onClose={hideDropdown}
            omitDefaultStyle
          >
            <Element name="mySendAtScrollToElement">
              <DropDownContent id="repeatDayDropdown" type="repeatDay">
                <Item key={"recurring"} onClick={() => setRecurring(false)}>
                  Does not repeat
                </Item>
                {DAY_OPTIONS.map((item, index) => (
                  <Item
                    key={index}
                    onClick={() => {
                      setRecurring(true);
                      setSelectedDay(item.value);
                    }}
                  >
                    {item.label}
                  </Item>
                ))}
              </DropDownContent>
            </Element>
          </DropDown>
        </DropDownWrapper>
      </Wrapper>
    </>
  );
};

export default ScheduleRepeatDay;
