import styled from "styled-components";

export const LoadingIconContainer = styled.div`
  align-items: center;
  color: var(--inky200);
  display: flex;
  font-size: 30px;
  justify-content: center;
  padding: var(--midVertGutter);
`;
