/* @flow */
import { without } from "lodash";
import { type ClientErrorsByField } from "./clientValidation";

type MediaUpload = { fileName: string, progress: ?number };

export function isSubmittable(
  pinterestBoardsByAccount: { [key: string]: string },
  mediaUploadProgress: MediaUpload[],
  isScrapeInProgress: boolean,
  clientErrorsByField: ClientErrorsByField
): boolean {
  const hasUnsubmittableClientErrors = Object.keys(
    clientErrorsByField.variations
  ).some(clientId => {
    const fields = clientErrorsByField.variations[clientId];
    // Still submittable if there are media errors
    return without(Object.keys(fields), "media").length > 0;
  });

  const hasPinterestAccountsLackingBoards =
    Object.keys(pinterestBoardsByAccount).some(
      accountId => !pinterestBoardsByAccount[accountId].length
    ) ?? false;

  return (
    !hasUnsubmittableClientErrors &&
    mediaUploadProgress.length === 0 &&
    !isScrapeInProgress &&
    !hasPinterestAccountsLackingBoards
  );
}

export default isSubmittable;
