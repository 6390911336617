/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation subscribe(
    $planId: String!
    $paymentToken: String!
    $couponCode: String
    $total: Int
  ) {
    subscribe(
      planId: $planId
      paymentToken: $paymentToken
      couponCode: $couponCode
      total: $total
    ) {
      errors
    }
  }
`;
