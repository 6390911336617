/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query dashboardMainGraph(
    $startDate: ISO8601Date!
    $endDate: ISO8601Date!
    $accountId: Int!
  ) {
    dashboardMainGraph(
      input: { startDate: $startDate, endDate: $endDate, accountId: $accountId }
    ) {
      netGrowth
      graphData
      graphType
    }
  }
`;
