/* @flow */
import qs from "qs";
import { isValidUrl as baseIsValidUrl } from "twitter-text";
import ApolloClient from "apollo-boost";
import moment from "moment-timezone";
import invariant from "invariant";
import {
  clientState,
  clientIdx
} from "fixtures/apolloClient/apolloClientGenerator";
import csrfToken from "../csrf.js";
import TRACK from "mutations/track";
import { useMutation } from "@apollo/react-hooks";

export function truncateTextTo(text: string, length: number): string {
  let concatenatedText = "";
  if (text.length > length) {
    concatenatedText = `${text.slice(0, length)}…`;
  } else {
    concatenatedText = text;
  }

  return concatenatedText;
}

export const formatCurrency = (value: number) => `$${(value / 100).toFixed(2)}`;

// https://ar.al/2018/08/26/get-unicode-aware-length-of-string-in-javascript/
export const lengthInUtf8Bytes = (str: string) => {
  let match;

  try {
    match = encodeURIComponent(str).match(/%[89ABab]/g);
  } catch {
    return str.length;
  }

  return match ? [...str].length : str.length;
};

export const gotoPath = (path: string) => {
  window.location.href = path;
};

export const goBack = () => {
  // If the referrer was in app go back to previous page, otherwise go home
  if (document.referrer.match("heroku|localhost|meetedgar|0.0.0.0|127.0.0.1")) {
    // Use window.location = here instead of window.history to ensure page reload
    window.location = getReferrerLocation();
  } else {
    window.location = "/";
  }
};

// We want to make sure that any query params that were added to the current location get added to the referrer
// (e.g., the query param we use to show <SeeMorePendingContentDialog />)
const getReferrerLocation = () => {
  const [path, query] = document.referrer.split("?");
  const parsedLocationSearch = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });
  const newQueryParams = qs.stringify({
    ...qs.parse(query),
    ...parsedLocationSearch
  });

  return newQueryParams.length ? path + "?" + newQueryParams : path;
};

export const reloadPage = (force: boolean = true) => {
  window.location.reload(force);
};

export const capitalize = (str: ?string): string =>
  str ? str.charAt(0).toUpperCase() + str.substr(1) : "";

export const getQsAsObject = () =>
  qs.parse(window.location.search.substring(1));

export const getUrlDomain = (url: string) => {
  const a = document.createElement("a");
  a.setAttribute("href", url.indexOf("://") < 0 ? `http://${url}` : url);
  return a.hostname;
};

export const removeSchemeFromUrl = (url: string) =>
  url.replace(/(^\w+:|^)\/\//, "");

export const urlsEqual = (url1: string, url2: string) => {
  try {
    const normalizedUrl1 = new URL(
      `https://${decodeURIComponent(removeSchemeFromUrl(url1))}`
    );
    const normalizedUrl2 = new URL(
      `https://${decodeURIComponent(removeSchemeFromUrl(url2))}`
    );
    return normalizedUrl1.toString() === normalizedUrl2.toString();
  } catch (_) {
    // One or both of the urls weren't valid
    return false;
  }
};

export const track = (message: string, attributes: ?Object): void => {
  if (window && window.analytics) {
    window.analytics.track(message, attributes);
  }
};

export const useTrack = () => {
  const [doTrack] = useMutation(TRACK);
  return async (message: string, attributes: Object) => {
    const result = await doTrack({
      variables: { message, attributes: JSON.stringify(attributes) }
    });
    return !!result?.data?.success;
  };
};

export const featureFlag = (flagName: string): boolean => {
  if (
    window.FEATURE_FLAGS &&
    window.FEATURE_FLAGS[flagName] &&
    window.FEATURE_FLAGS[flagName] === true
  ) {
    return true;
  }

  return false;
};

export const openWindowToUrl = (url: string) => {
  window.open(`http://${removeSchemeFromUrl(url)}`);
};

// Can't use &nbsp; here as React will render it as a literal string
export const preventDanglingWords = (text: string) =>
  text.replace(/ (?=[^ ]*$)/, String.fromCharCode(160));

export const joinSentences = (sentences: Array<?string>) =>
  sentences.filter(x => !!x).join(". ");

export const getLocation = (): Object => window.location;

type IsValidUrlOptions = { requireScheme?: boolean };
export const isValidUrl = (
  url: ?string,
  options: IsValidUrlOptions = {}
): boolean => baseIsValidUrl(url, null, options.requireScheme || false);

export const getFutureDateInTimeZone = (
  period: Object,
  timeZone: string
): string =>
  moment()
    .tz(timeZone)
    .add(period)
    .seconds(0)
    .milliseconds(0)
    .format();

export const getMomentInTimeZone = (
  date: string | number | null | void,
  timeZone: string
): Object => moment(date).tz(timeZone);

const FIRST_EPOCH_SUNDAY = 259200;
export const getMomentForLocalWeekOffset = (offset: number): Object =>
  moment.unix(FIRST_EPOCH_SUNDAY + offset).utc();

export function ensureEnum<E>(value: string, enums: Array<E>): E {
  const literal = enums.find(candidate => candidate === value);
  invariant(literal, "Invalid enum value!");
  return literal;
}

export async function fileFromUrl(url: string) {
  const response = await fetch(url);
  const type = response.headers.get("Content-Type");
  const blob = await response.blob();
  const filename = new URL(url).pathname.split("/").pop();
  const options = {};
  if (type) {
    options.type = type;
  }
  return new File([blob], filename, options);
}

export async function hashFile(file: File) {
  let fileBuffer = await new Response(file).arrayBuffer();
  let hashBuffer = await window.crypto.subtle.digest("SHA-256", fileBuffer);
  return [...new Uint8Array(hashBuffer)]
    .map(b => b.toString(16).padStart(2, "0"))
    .join("");
}

export type ImageMetadata = {
  width: number,
  height: number,
  size: number,
  type: string
};
export async function getImageMetadata(
  image_file: File
): Promise<ImageMetadata> {
  return new Promise(resolve => {
    const img = document.createElement("img");
    img.src = URL.createObjectURL(image_file);
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height,
        size: image_file.size,
        type: image_file.type
      });
    };
  });
}

export type VideoMetadata = {
  width: number,
  height: number,
  size: number,
  duration: number,
  type: string
};
export async function getVideoMetadata(
  video_file: File
): Promise<VideoMetadata> {
  return new Promise(resolve => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.src = URL.createObjectURL(video_file);
    video.onloadedmetadata = function() {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
        size: video_file.size,
        type: video_file.type
      });
    };
  });
}
const generateClient = () => {
  const client = new ApolloClient({
    headers: { "X-CSRF-Token": csrfToken() },
    clientState: clientState
  });
  return client;
};

const setClientState = clientId => {
  try {
    if (!clientId) {
      // make no changes to client state; this is expected behavior for production
      return;
    }
    let _clientIdx = clientIdx();
    _clientIdx[clientId]();
  } catch (e) {
    throw ("could not set client state with id", clientId, e);
  }
};

export const getClient = (id?: String) => {
  setClientState(id);
  return generateClient();
};

export const useNewUICached = () => {
  const dataFromLocal = localStorage.getItem("navbar_modules_ui");
  return dataFromLocal && dataFromLocal === "true";
};
