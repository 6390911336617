import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import GET_SHOPIFY_ACCOUNT from "queries/getShopifyAccount";

import { IProps } from "./types.js";

const useConnectionImportCard = (props: IProps) => {
  const { connection } = props;

  const [importModalVisible, setImportModalVisible] = useState(false);
  const [shopifyModalVisible, setShopifyModalVisible] = useState(false);
  const [csvImportModalVisible, setCsvImportModalVisible] = useState(false);

  const handleShowImportModal = () => setImportModalVisible(true);
  const handleHideImportModal = () => setImportModalVisible(false);

  const handleShowShopifyModal = () => setShopifyModalVisible(true);
  const handleHideShopifyModal = () => setShopifyModalVisible(false);

  const handleShowCsvImportModal = () => setCsvImportModalVisible(true);
  const handleHideCsvImportModal = () => setCsvImportModalVisible(false);

  const { data: shopifyAccountData } = useQuery(GET_SHOPIFY_ACCOUNT);

  const shopifyToken = shopifyAccountData?.shopifyAccount?.accessToken;

  const openNewTab = url => {
    window.open(url, "_blank");
  };

  const clickHandler = () => {
    if (connection.targetUrl) {
      window.open(connection.targetUrl, "_blank");
      return;
    } else if (connection.type === "CSV") {
      return handleShowCsvImportModal();
    } else if (shopifyToken && connection.type == "SHOPIFY") {
      return handleShowShopifyModal();
    } else {
      return handleShowImportModal();
    }
  };

  const infoLinkClickHandler = e => {
    // open the help link, and prevent bubbling to this component's clickHandler
    if (connection.addtlInfoUrl) {
      openNewTab(connection.addtlInfoUrl);
    }
    e.stopPropagation();
  };

  return {
    importModalVisible,
    shopifyModalVisible,
    csvImportModalVisible,
    handleHideImportModal,
    handleHideShopifyModal,
    handleHideCsvImportModal,
    clickHandler,
    shopifyToken,
    infoLinkClickHandler
  };
};

export default useConnectionImportCard;
