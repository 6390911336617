/* @flow */
import React from "react";

import type { IProps } from "./types.js";
import { Wrapper, Tab } from "./styles";

const ScheduleTabs = (props: IProps) => {
  const { disabled, singlePostType, setSinglePostType } = props;

  return (
    <Wrapper>
      <Tab
        onClick={() => setSinglePostType(false)}
        className={!singlePostType ? "active" : ""}
        disabled={disabled}
      >
        Category
      </Tab>
      <Tab
        onClick={() => setSinglePostType(true)}
        className={singlePostType ? "active" : ""}
        disabled={disabled}
      >
        Individual Post
      </Tab>
    </Wrapper>
  );
};

export default ScheduleTabs;
