/* @flow */
import React from "react";
import Overlay from "behavioral/Overlay";
import UtilityNav from "./UtilityNav";
import HamburgerButton from "./HamburgerButton";
import MainNav from "./MainNav";
import Logo from "./Logo";
import Flasher from "./Flasher";
import styles from "./index.css";
import { truncateTextTo } from "../../util/util";

import type { CompanyStatus } from "types";

import SidebarNav from "components/SidebarNav";
import { featureFlag } from "util";

export type Props = {
  isAdmin: boolean,
  pendingCount: number,
  logoUrl: string,
  wide: boolean,
  featureFlags: { [name: string]: boolean },
  activePage: string,
  companyStatus: CompanyStatus,
  userName: string,
  useNewUI?: boolean,
  hideSidebar?: boolean
};

type State = {
  mobileView: boolean,
  mobileMenuOpen: boolean,
  useNewUI: boolean,
  oldNavbar: boolean
};

export default class TopNav extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      mobileView: this.mobileBreakPoint(),
      mobileMenuOpen: false,
      useNewUI: false,
      oldNavbar: false
    };
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.fetchGetNewUI();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = (): void => {
    this.setState({ mobileView: this.mobileBreakPoint() });
    this.setState({ oldNavbar: this.oldNavBreakPoint() });
  };

  onClickHamburgerButton = (): void => {
    this.setState({ mobileMenuOpen: !this.state.mobileMenuOpen });
  };

  mobileBreakPoint(): boolean {
    return window.innerWidth <= 600;
  }

  oldNavBreakPoint(): boolean {
    return (
      window.innerWidth <= 1099 || window.location.pathname.includes("/payment")
    );
  }

  throwError() {
    throw new Error("test that errors are being reported in prod!");
  }

  renderNav() {
    return [
      <HamburgerButton
        onClick={this.onClickHamburgerButton}
        open={this.state.mobileMenuOpen}
        key="hamburger"
      />,
      <Logo
        url={this.props.logoUrl}
        activePage={this.props.activePage}
        key="logo"
      />,
      <MainNav
        isAdmin={this.props.isAdmin}
        pendingCount={this.props.pendingCount}
        companyStatus={this.props.companyStatus}
        open={this.state.mobileMenuOpen}
        userName={truncateTextTo(this.props.userName, 10)}
        activePage={this.props.activePage}
        onCloseMenu={this.onClickHamburgerButton}
        mobileView={this.state.mobileView}
        key="mainNav"
      />
    ];
  }

  fetchGetNewUI() {
    if (!featureFlag("navbar_modules_ui")) {
      this.setState({ useNewUI: false });
      localStorage.setItem("navbar_modules_ui", "false");
    } else {
      if (featureFlag("navbar_modules_ui")) {
        this.setState({ useNewUI: true });
      }
    }
  }

  renderMobileNav() {
    return (
      <div>
        {this.state.mobileMenuOpen && (
          <Overlay onClose={this.onClickHamburgerButton} />
        )}
        {this.renderNav()}
      </div>
    );
  }

  renderErrorThrower() {
    return (
      <div
        style={{
          position: "absolute",
          top: "100px",
          left: "-5000px",
          backgroundColor: "green"
        }}
        onClick={this.throwError}
      >
        click me to throw error
      </div>
    );
  }

  render() {
    return !this.state.oldNavbar &&
      (this.props.useNewUI || this.state.useNewUI) ? (
      <>
        <Flasher />
        {this.props.hideSidebar !== true && <SidebarNav {...this.props} />}
      </>
    ) : (
      <div className={this.state.mobileMenuOpen ? styles.open : styles.root}>
        <div className={styles.container}>
          <UtilityNav companyStatus={this.props.companyStatus} />
          {this.state.mobileView ? this.renderMobileNav() : this.renderNav()}
        </div>
        <Flasher />
        {this.renderErrorThrower()}
      </div>
    );
  }
}
