import styled from "styled-components";

export const Wrapper = styled.div`
  background: #fff;
  border: 1px solid #e0e4ea;
  border-radius: 8px;
  margin: 10px 0 20px;
`;

export const Header = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 24px;
  justify-content: space-between;

  .left {
    align-items: center;
    display: flex;
  }
`;

export const Number = styled.div`
  align-items: center;
  border-radius: 50%;
  border: 2px solid #000;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 22px;
  justify-content: center;
  padding-left: 1.5px;
  padding-bottom: 0.5px;
  width: 22px;
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin-left: 16px;

  span {
    font-weight: 300;
    opacity: 0.9;
  }
`;

export const Content = styled.div`
  padding: 0 24px 24px;

  &.hidden {
    display: none;
  }
`;
