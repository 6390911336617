import styled from "styled-components";

const hScrollbarWidth = 1074;
const hScrollbarWidthNewUI = hScrollbarWidth + 88;

export const ScrollbarWrapper = styled.div`
  &.new-ui {
    @media (max-width: ${hScrollbarWidthNewUI - 1}px) {
      max-width: 1346px;
      width: 100%;
      overflow-x: auto;
    }
  }

  &.old-ui {
    @media (max-width: ${hScrollbarWidth - 1}px) {
      max-width: 1346px;
      width: 100%;
      overflow-x: auto;
    }
  }
`;

export const Wrapper = styled.div`
  --timeColumnWidth: 4rem;
  border-bottom: 1px solid #bec1c8;
  border-radius: 6px;
  width: 100%;

  &.new-ui {
    @media (max-width: ${hScrollbarWidthNewUI - 1}px) {
      min-width: ${hScrollbarWidth - 30}px;
    }
  }

  &.old-ui {
    @media (max-width: ${hScrollbarWidth - 1}px) {
      min-width: ${hScrollbarWidth - 30}px;
    }
  }
`;

export const DaysHeader = styled.div`
  background-color: #202f39;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: row;
  font-weight: 600;

  &.new-ui {
    @media (min-width: ${hScrollbarWidthNewUI}px) {
      position: sticky;
      top: -2.5px;
      z-index: 3;
    }
  }

  &.old-ui {
    @media (min-width: ${hScrollbarWidth}px) {
      position: sticky;
      top: 55px;
      z-index: 3;
    }
  }
`;

export const HeaderItem = styled.div`
  border-left: 1px solid #bec1c8;
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  min-width: 140px;
  padding: 0.9rem;
  text-align: center;
  text-transform: uppercase;

  &:first-child {
    align-items: center;
    border: none;
    display: flex;
    flex: 0 0 var(--timeColumnWidth);
    justify-content: center;
    min-width: auto;
  }

  span {
    color: #bec1c8;
    display: block;
    font-size: 11px;
    text-transform: capitalize;
  }

  ${props =>
    props.status === "current" &&
    `
    background: #202f39;
    border-radius: 8px 8px 0 0;
    margin-top: -0.5rem;
    padding-top: 1.25rem;
  `}

  div {
    ${props => props.status === "disabled" && `opacity: .4;`}
  }
`;

export const ScheduleWrapper = styled.div`
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  border-left: 1px solid #bec1c8;
  border-right: 1px solid #bec1c8;
  position: relative;
`;

export const HourRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
`;

export const HourHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 0 0 var(--timeColumnWidth);
  justify-content: space-evenly;
  line-height: var(--midLineHeight);
  margin-bottom: -1px;
  min-height: ${({ hourHeight }) => hourHeight}px;
  text-align: center;
  text-transform: uppercase;

  span {
    align-items: center;
    color: #202f3999;
    display: flex;
    flex: 1;
    font-size: 11px;
    font-weight: 600;
    max-width: 80%;
  }
`;

export const HourChildren = styled.div`
  cursor: cell;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 0 -1px;
  position: relative;
`;

export const HourBorder = styled.div`
  background: #bec1c833;
  height: 1px;
  margin: 0 0 0 -70px;
  position: absolute;
  top: 0;
  width: 120%;
  z-index: 1;
`;

export const DayWrapper = styled.div`
  border-left: 1px solid #bec1c8;
  flex: 1;
  min-width: 140px;
  padding: 0;

  ${({ current }) => current && `background-color: #F9F9FB;`}
`;

export const MinuteRow = styled.div`
  display: flex;
  flex-basis: auto;
  flex: 1;
`;
