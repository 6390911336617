/* @flow */
import React, { useState } from "react";
import classNames from "classnames";
import Modal from "./Modal";
import { Link } from "links";
import styles from "./index.css";

export type ImageData = {
  url: string
};

type Props = {
  images: ImageData[],
  videosLength?: number,
  queueable: boolean,
  showModal: boolean,
  onClickShowModal: () => void,
  onClickHideModal: () => void,
  displayTotalCount?: boolean,
  size: number
};

const Image = (props: Props) => {
  const {
    images,
    videosLength,
    queueable,
    showModal,
    onClickShowModal,
    onClickHideModal,
    displayTotalCount = false,
    size
  } = props;

  const [imageIndex, setImageIndex] = useState(0);

  const cover = imageIndex < images.length ? images[imageIndex] : null;
  const countClasses = classNames(styles.count, {
    [styles.unqueueable]: !queueable
  });

  return (
    <div
      className={styles.root}
      style={{ height: `${size}px`, width: `${size}px` }}
    >
      <Link
        className={styles.link}
        disabled={!Boolean(cover)}
        onClick={cover && onClickShowModal}
      >
        {cover ? (
          <img
            className={styles.image}
            onError={(): void => {
              setImageIndex(imageIndex + 1);
            }}
            src={cover.url}
            alt={cover.url}
            style={{ height: `${size}px`, width: `${size}px` }}
          />
        ) : (
          <span
            className={styles.failedImage}
            style={{
              fontSize: `${size / 2}px`,
              height: `${size}px`,
              width: `${size}px`
            }}
            title="Unable to load image"
          >
            <i className="fa fa-unlink" />
          </span>
        )}
        {images.length > 1 && (
          <span className={countClasses}>
            {displayTotalCount
              ? images.length
              : `+${images.length - 1 + (videosLength || 0)}`}
          </span>
        )}
      </Link>
      {cover && showModal && (
        <Modal url={cover.url} onClickHide={onClickHideModal} />
      )}
    </div>
  );
};

export default Image;
