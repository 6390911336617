// @flow
import React from "react";
import { PlusCircleIcon } from "icons";
import styles from "./index.css";

type Props = {
  onClick: () => void
};

const AddCategoryOption = ({ onClick }: Props) => (
  <div className={styles.root} role="option" onClick={onClick}>
    <PlusCircleIcon />
    Add category
  </div>
);

export default AddCategoryOption;
