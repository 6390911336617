/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getLatestBulkUpdateDisplayErrors {
    latestBulkUpdateDisplayErrors {
      data {
        id
        sanitizedBody
        content {
          id
        }
      }
      totalDisplayErrors
    }
  }
`;
