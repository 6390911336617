/* @flow */
import React from "react";
import classNames from "classnames";
import Link from "links/Link";
import Icon from "icons/Icon";
import styles from "./index.css";
import type { ScrapedPage, AccountPlatform } from "types";

type Props = {
  pageScrape: ScrapedPage,
  unstyled?: boolean,
  platforms?: AccountPlatform[],
  omitDestination?: boolean
};

const NO_TITLE_TEXT = "A title will be chosen when your link preview is posted";

const LinkPreview = (props: Props) => {
  const {
    pageScrape: { images, title, description, destinationUrl },
    unstyled = false,
    omitDestination = false,
    platforms = []
  } = props;

  const imageUrl = images && images.length ? images[0].src : null;
  const className = title ? styles.title : styles.noTitleText;

  return (
    <div className={classNames(styles.root, { [styles.unstyled]: unstyled })}>
      <div className={styles.previewContainer}>
        {imageUrl && (
          <div className={styles.imageContainer}>
            <img className={styles.image} src={imageUrl} alt={imageUrl} />
          </div>
        )}
        <div className={styles.text}>
          {title && destinationUrl ? (
            <Link className={className} url={destinationUrl} target="_blank">
              {title}
            </Link>
          ) : (
            <span className={className}>{title || NO_TITLE_TEXT}</span>
          )}
          <span className={styles.description}>{description}</span>

          {destinationUrl && !omitDestination && (
            <div className={styles.footer}>
              {platforms.map(p => p.toUpperCase()).includes("FACEBOOK") && (
                <span className={styles.fbIconContainer}>
                  <Icon type="facebook-square" />
                </span>
              )}
              {platforms.map(p => p.toUpperCase()).includes("LINKEDIN") && (
                <span className={styles.linkedinIconContainer}>
                  <Icon type="linkedin-square" />
                </span>
              )}
              <span>link preview for </span>
              <Link
                url={destinationUrl}
                target="_blank"
                className={styles.footerLink}
              >
                {destinationUrl}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkPreview;
