/* @flow */
import React from "react";
import { useState, useEffect } from "react";
import { useQuery, useLazyQuery, useMutation } from "@apollo/react-hooks";

import Modal from "components/common/layout/modals/Modal";
import styles from "./index.css";
import { H2 } from "components/common/typography";
import { TextInput } from "components/common/forms";
import { Button } from "components/common/buttons";

import RssFeedForm from "../../RssFeedForm";
import Link from "links/Link";

import type { BrandedImportConnection } from "types";

import FIND_RSS_FEED from "queries/findRssFeed";
import CREATE_RSS_FEED from "mutations/createRssFeed";
import GET_ACCOUNTS from "queries/getAccounts";
import { GET_CATEGORIES } from "queries/category";

type Props = {
  connection: BrandedImportConnection,
  onClickHide: () => void
};

export const ImportModal = (props: Props) => {
  const { connection, onClickHide } = props;

  // grab the accounts and categories objects; we pass these into the RssFeedForm component
  const {
    loading: accountsLoading,
    error: accountsLoadingError,
    data: accountsData
  } = useQuery(GET_ACCOUNTS);
  const {
    loading: categoriesLoading,
    error: categoriesLoadingError,
    data: categoriesData
  } = useQuery(GET_CATEGORIES);

  const [userSuppliedRssValue, setUserSuppliedRssValue] = useState<string>("");
  const [vimeoChannelTypeSelection, setVimeoChannelTypeSelection] = useState(
    ""
  );
  const [pinterestBoardInput, setPinterestBoardInput] = useState("");
  const [pinterestUsernameInput, setPinterestUsernameInput] = useState("");
  const [
    disablePinterestUsernameInput,
    setDisablePinterestUsernameInput
  ] = useState(false);
  const [disablePinterestBoardInput, setDisablePinterestBoardInput] = useState(
    false
  );
  const [erroredPinterestFeedFinder, setErroredPinterestFeedFinder] = useState(
    false
  );

  const [
    findRssFeed,
    {
      loading: loadingFindRssFeed,
      error: _findRssFeedError,
      data: findRssFeedData
    }
  ] = useLazyQuery(FIND_RSS_FEED);

  useEffect(() => {
    // we need to set a state value that indicates if an error was returned by the FIND_RSS_FEED query
    //  so that we can properly clear error values for pinterest feed queries when the user erases
    //  input in order to correct their entry
    if (
      connection.type === "PINTEREST" &&
      findRssFeedData &&
      !findRssFeedData.findFeed
    ) {
      setErroredPinterestFeedFinder(true);
    }
  }, [connection.type, findRssFeedData]);

  const handleFindFeedOnEnter = (evt: Event) => {
    if (connection.type === "VIMEO") {
      // for vimeo, user is required to select between two possible options
      //  in order to advance
      return;
    }

    evt.preventDefault();
    handleOnFindFeed();
  };

  const handleOnFindFeed = (evt: ?EventHandler): void => {
    if (loadingFindRssFeed) {
      return;
    }

    // pinterest, medium and vimeo require special handling
    let url = "";
    let vimeoLikesVideos = "";
    if (connection.type === "MEDIUM") {
      // in medium's case, the data provided by the user is a username, not a
      //  true url
      url = "https://medium.com/feed/" + userSuppliedRssValue;
    } else if (connection.type === "VIMEO" && evt) {
      // in vimeo's case, the data provided by the user is a channel name, and
      //  the user must select whether they want to follow 'likes' or 'videos'
      //  on that channel
      // the Button component affords a 'data-test' attribute that we use here to
      //  track which button was pressed
      if (evt.currentTarget.getAttribute("data-test") === "VIMEO_LIKES") {
        vimeoLikesVideos = "likes";
        setVimeoChannelTypeSelection("Channel likes"); // the RssFeedForm component uses this
      } else if (
        evt.currentTarget.getAttribute("data-test") === "VIMEO_VIDEOS"
      ) {
        vimeoLikesVideos = "videos";
        setVimeoChannelTypeSelection("Channel feed"); // the RssFeedForm component uses this
      }
      url =
        userSuppliedRssValue.includes("https://vimeo.com/") ?
          (userSuppliedRssValue +
          "/" +
          vimeoLikesVideos +
          "/rss")
        :
            "https://vimeo.com/" +
          userSuppliedRssValue +
          "/" +
          vimeoLikesVideos +
          "/rss"

    } else if (connection.type === "PINTEREST") {
      if (pinterestUsernameInput.length > 0) {
        url =
          "https://www.pinterest.com/" + pinterestUsernameInput + "/feed.rss";
      } else {
        url = pinterestBoardInput + ".rss";
      }
    } else {
      url = userSuppliedRssValue;
    }

    findRssFeed({ variables: { url: url } });
  };

  const rssFeedFound = findRssFeedData?.findFeed?.url;
  const erroredRssFinder =
    connection.type !== "PINTEREST" &&
    findRssFeedData &&
    !findRssFeedData.findFeed;
  const showErroredPinterestUsernameFinder =
    pinterestUsernameInput.length > 0 && erroredPinterestFeedFinder;
  const showErroredPinterestBoardFinder =
    pinterestBoardInput.length > 0 && erroredPinterestFeedFinder;

  const handleOnUrlChange = (value: string): void => {
    setUserSuppliedRssValue(value);
  };

  const shopifyUrlHandler = (): void => {
    const shopifyRegex = /^(https|http)\:\/\/[a-zA-Z0-9][a-zA-Z0-9\-]*\.myshopify\.com/;
    if (shopifyRegex.test(String(userSuppliedRssValue))) {
      const value = userSuppliedRssValue.split("://")[1];
      setUserSuppliedRssValue(value);
    }
  };

  const handleOnPinterestBoardChange = (value: string): void => {
    if (value.length > 0) {
      setDisablePinterestUsernameInput(true);
    } else {
      setDisablePinterestUsernameInput(false);
      setErroredPinterestFeedFinder(false);
    }
    setPinterestBoardInput(value);
  };

  const handleOnPinterestUsernameChange = (value: string): void => {
    if (value.length > 0) {
      setDisablePinterestBoardInput(true);
    } else {
      setDisablePinterestBoardInput(false);
      setErroredPinterestFeedFinder(false);
    }
    setPinterestUsernameInput(value);
  };

  const [shopifyInputValid, setshopifyInputValid] = useState(false);
  const shopifyStoreUrl = (shopifyLink: string): boolean => {
    if (shopifyLink && shopifyLink.endsWith(".myshopify.com")) {
      return true;
    } else {
      setshopifyInputValid(true);
      return false;
    }
  };

  const [
    createRssFeed,
    {
      data: createRssFeedData,
      loading: createRssFeedLoading,
      error: createRssFeedError
    }
  ] = useMutation(CREATE_RSS_FEED);
  const handleCreateRssFeed = async rssFeed => {
    const result = await createRssFeed({
      variables: {
        input: {
          accountIds: rssFeed.accountIds,
          categoryId: rssFeed.categoryId,
          requiresApproval: rssFeed.requiresApproval,
          useOnce: rssFeed.useOnce,
          name: rssFeed.name,
          url: rssFeed.url
        }
      }
    });
    if (result?.data?.createRssFeed?.rssFeed?.id) {
      // this is kinda hacky, and represents a TODO in which we convert
      //  the  /import/manage page over to graphql
      // for now, this forces a page reload which ensures that the user sees
      //  feeds that were just added
      window.location = "/import/manage";
    }
  };

  const renderPinterestInputBlock = () => {
    // pinterest must have two text inputs, with unique copy for each
    return (
      <div className={styles.header}>
        <H2>{connection.connectModalCopy[0].heading}</H2>

        {(accountsLoadingError || categoriesLoadingError) && (
          <p>
            Edgar was unable to retrieve required data. Please contact our
            technical team for assistance.
          </p>
        )}

        {!accountsLoadingError && !categoriesLoadingError && (
          <div>
            <p>{connection.connectModalCopy[0].description} </p>
            <div className={styles.textInputContainer}>
              {showErroredPinterestUsernameFinder && (
                <p className={styles.finderErrorMsg}>
                  {connection.connectModalCopy[0].errorText}{" "}
                  <Link
                    url={connection.connectModalCopy[0].errorAddtlInfoLink}
                    target="_blank"
                  >
                    {connection.connectModalCopy[0].errorAddtlInfoText}
                  </Link>{" "}
                </p>
              )}
              <TextInput
                className={
                  showErroredPinterestUsernameFinder
                    ? styles.inputErrorIsPresent
                    : ""
                }
                value={pinterestUsernameInput}
                onChange={handleOnPinterestUsernameChange}
                placeholder={connection.connectModalCopy[0].placeholder}
                onEnter={handleFindFeedOnEnter}
                autoFocus
                disabled={disablePinterestUsernameInput}
              />
            </div>
            <p className={styles.pinterestFeedTypeDivider}>OR</p>
            <p>{connection.connectModalCopy[1].description} </p>
            <div className={styles.textInputContainer}>
              {showErroredPinterestBoardFinder && (
                <p className={styles.finderErrorMsg}>
                  {connection.connectModalCopy[1].errorText}{" "}
                  <Link
                    url={connection.connectModalCopy[1].errorAddtlInfoLink}
                    target="_blank"
                  >
                    {connection.connectModalCopy[1].errorAddtlInfoText}
                  </Link>{" "}
                </p>
              )}
              <TextInput
                className={
                  showErroredPinterestBoardFinder
                    ? styles.inputErrorIsPresent
                    : ""
                }
                value={pinterestBoardInput}
                onChange={handleOnPinterestBoardChange}
                placeholder={connection.connectModalCopy[1].placeholder}
                onEnter={handleFindFeedOnEnter}
                disabled={disablePinterestBoardInput}
              />
            </div>
            <p>
              {connection.connectModalCopy[1].helpLink &&
                connection.connectModalCopy[1].helpLinkText && (
                  <Link
                    url={connection.connectModalCopy[1].helpLink}
                    target="_blank"
                  >
                    {connection.connectModalCopy[1].helpLinkText}
                  </Link>
                )}
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderInputBlock = () => {
    if (connection.type == "SHOPIFY") {
      return (
        <div className={styles.header}>
          <H2>{connection.connectModalCopy[0].heading}</H2>

          {(accountsLoadingError || categoriesLoadingError) && (
            <p>
              Edgar was unable to retrieve required data. Please contact our
              technical team for assistance.
            </p>
          )}

          {!accountsLoadingError && !categoriesLoadingError && (
            <p>
              {connection.connectModalCopy[0].description}{" "}
              {connection.connectModalCopy[0].helpLink &&
                connection.connectModalCopy[0].helpLinkText && (
                  <Link
                    url={connection.connectModalCopy[0].helpLink}
                    target="_blank"
                  >
                    {connection.connectModalCopy[0].helpLinkText}
                  </Link>
                )}
            </p>
          )}

          {erroredRssFinder && (
            <p className={styles.finderErrorMsg}>
              {connection.connectModalCopy[0].errorText}{" "}
              <Link
                url={connection.connectModalCopy[0].errorAddtlInfoLink}
                target="_blank"
              >
                {connection.connectModalCopy[0].errorAddtlInfoText}
              </Link>{" "}
            </p>
          )}

          <div className={styles.textInputContainer}>
            <form
              onSubmit={e => {
                e.preventDefault();
                const response = shopifyStoreUrl(userSuppliedRssValue);

                if (response) {
                  window.location = `/shopify_oauth_redirect?shop=${userSuppliedRssValue}&meetedgarApp=true`;
                }
              }}
            >
              <TextInput
                className={shopifyInputValid ? styles.inputErrorIsPresent : ""}
                value={userSuppliedRssValue}
                name="shop"
                onChange={handleOnUrlChange}
                placeholder={connection.connectModalCopy[0].placeholder}
                autoFocus
              />
              {shopifyInputValid && (
                <p className={styles.finderErrorMsg}>
                  This is not a valid Shopify store, please try again!
                </p>
              )}

              <div className={styles.footer}>
                <div className={styles.shopifyActions}>
                  <div className={styles.confirmButtonContainer}>
                    <Button
                      onClick={onClickHide}
                      type="secondary"
                      disabled={
                        loadingFindRssFeed ||
                        accountsLoading ||
                        categoriesLoading
                      }
                    >
                      Cancel
                    </Button>

                    <Button
                      submit
                      type="primary"
                      disabled={
                        loadingFindRssFeed ||
                        accountsLoading ||
                        categoriesLoading
                      }
                      loading={loadingFindRssFeed}
                      onClick={shopifyUrlHandler}
                      disabled={
                        (connection.type !== "PINTEREST" &&
                          userSuppliedRssValue.length === 0) ||
                        (connection.type === "PINTEREST" &&
                          pinterestBoardInput.length === 0 &&
                          pinterestUsernameInput.length === 0)
                      }
                    >
                      Connect
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.header}>
        <H2>{connection.connectModalCopy[0].heading}</H2>

        {(accountsLoadingError || categoriesLoadingError) && (
          <p>
            Edgar was unable to retrieve required data. Please contact our
            technical team for assistance.
          </p>
        )}

        {!accountsLoadingError && !categoriesLoadingError && (
          <p>
            {connection.connectModalCopy[0].description}{" "}
            {connection.connectModalCopy[0].helpLink &&
              connection.connectModalCopy[0].helpLinkText && (
                <Link
                  url={connection.connectModalCopy[0].helpLink}
                  target="_blank"
                >
                  {connection.connectModalCopy[0].helpLinkText}
                </Link>
              )}
          </p>
        )}

        {erroredRssFinder && (
          <p className={styles.finderErrorMsg}>
            {connection.connectModalCopy[0].errorText}{" "}
            <Link
              url={connection.connectModalCopy[0].errorAddtlInfoLink}
              target="_blank"
            >
              {connection.connectModalCopy[0].errorAddtlInfoText}
            </Link>{" "}
          </p>
        )}

        <div>
          <div className={styles.textInputContainer}>
            <TextInput
              className={erroredRssFinder ? styles.inputErrorIsPresent : ""}
              value={userSuppliedRssValue}
              onChange={handleOnUrlChange}
              placeholder={connection.connectModalCopy[0].placeholder}
              onEnter={handleFindFeedOnEnter}
              autoFocus
              hasError={erroredRssFinder}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* rss feed configuration state; this component manages its own modal */}
      {rssFeedFound && (
        <RssFeedForm
          isNewFeed
          rssFeed={findRssFeedData?.findFeed}
          categories={categoriesData.categories}
          accounts={accountsData.accounts}
          onSubmit={handleCreateRssFeed}
          onCancel={onClickHide}
          loading={createRssFeedLoading || createRssFeedData?.rssFeed?.id}
          error={createRssFeedError}
          configConnectionCopy={connection.configConnectionCopy}
          vimeoChannelTypeSelection={vimeoChannelTypeSelection}
        />
      )}

      {/* initial or error state */}
      {!rssFeedFound && (
        <Modal onClose={onClickHide} className={styles.rootModal}>
          {connection.type === "PINTEREST"
            ? renderPinterestInputBlock()
            : renderInputBlock()}

          {connection.type !== "SHOPIFY" && (
            <div className={styles.divider}>
              <div className={styles.footer}>
                <div className={styles.actions}>
                  <div className={styles.confirmButtonContainer}>
                    {connection.type === "VIMEO" ? (
                      <div>
                        <Button
                          submit
                          type="primary"
                          disabled={
                            loadingFindRssFeed ||
                            accountsLoading ||
                            categoriesLoading
                          }
                          onClick={handleOnFindFeed}
                          loading={loadingFindRssFeed}
                          dataTest={"VIMEO_VIDEOS"}
                        >
                          Add Channel Feed
                        </Button>
                        <Button
                          submit
                          type="primary"
                          disabled={
                            loadingFindRssFeed ||
                            accountsLoading ||
                            categoriesLoading
                          }
                          onClick={handleOnFindFeed}
                          loading={loadingFindRssFeed}
                          dataTest={"VIMEO_LIKES"}
                        >
                          Add Likes Feed
                        </Button>
                      </div>
                    ) : (
                      <Button
                        submit
                        type="primary"
                        disabled={
                          loadingFindRssFeed ||
                          accountsLoading ||
                          categoriesLoading
                        }
                        onClick={handleOnFindFeed}
                        loading={loadingFindRssFeed}
                        disabled={
                          (connection.type !== "PINTEREST" &&
                            userSuppliedRssValue.length === 0) ||
                          (connection.type === "PINTEREST" &&
                            pinterestBoardInput.length === 0 &&
                            pinterestUsernameInput.length === 0)
                        }
                      >
                        Next
                      </Button>
                    )}
                  </div>
                  <Button
                    onClick={onClickHide}
                    type="secondary"
                    disabled={
                      loadingFindRssFeed || accountsLoading || categoriesLoading
                    }
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default ImportModal;
