/* @flow */
import React from "react";
import { Label } from "typography";
import { AccountProviderIcon } from "icons";
import CharacterCountNotice from "./CharacterCountNotice";
import styles from "./index.css";

import type { AccountPlatform } from "types";
type RemainingCharacterCounts = { [key: string]: number };

type Props = {
  fieldName: string,
  selectedPlatforms: AccountPlatform[],
  remainingCharacterCounts: ?RemainingCharacterCounts
};

const TopSection = ({
  fieldName,
  selectedPlatforms,
  remainingCharacterCounts
}: Props) => {
  const nonFbPlatforms = selectedPlatforms.filter(
    platform => platform !== "FACEBOOK"
  );

  return (
    <div className={styles.root}>
      <Label className={styles.label} small htmlFor={fieldName}>
        {nonFbPlatforms.length ? "Text" : "Suggested text"}
        {nonFbPlatforms.map(platform => (
          <AccountProviderIcon small key={platform} provider={platform} />
        ))}
      </Label>
      {!!remainingCharacterCounts && (
        <CharacterCountNotice value={remainingCharacterCounts} />
      )}
    </div>
  );
};

export default TopSection;
