// @flow
import React from "react";
import { DefaultPlayer as VideoPlayer } from "react-html5video";
import ImageCollage from "./ImageCollage";
import styles from "./index.css";

import type {
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData
} from "graphql-types/loadContent";

type Props = {
  images: ImageData[],
  videos: VideoData[]
};

const Media = ({ images, videos }: Props) => (
  <div className={styles.root}>
    {videos.length > 0 ? (
      <VideoPlayer controls={[]} autoPlay className={styles.video}>
        <source src={videos[0].url} type="video/mp4" />
      </VideoPlayer>
    ) : images.length > 1 ? (
      <ImageCollage urls={images.map(({ url }) => url)} />
    ) : (
      <img
        className={styles.singleImage}
        src={images[0].url}
        role="presentation"
      />
    )}
  </div>
);

export default Media;
