// @flow
import { uniq, flatMap, compact } from "lodash";
import pluralize from "pluralize";
import invariant from "invariant";
import { validateAspectRatio } from "lib/images/validateImage";
import validateVideo from "lib/videos/validateVideo";
import validateMentions from "./validateMentions";
import {
  COMPOSER_MAX_IMAGE_COUNT,
  IMAGE_VALIDATIONS,
  PROVIDER_DISPLAY_NAME_MAP
} from "constants";

import type {
  Provider,
  loadContent_content as ContentData
} from "graphql-types/loadContent";

// TODO there are now 3 ValidationResponse classes as well as the Validation system
// in errors.js. Need to consolidate this stuff...
export type ContentValidationResponse = {
  valid: boolean,
  errors?: string[]
};

const getImageRejections = (
  images,
  platforms,
  providers,
  sendMobileReminder,
  videos
) =>
  compact(
    platforms.map(platform => {
      const platformName =
        platform === "INSTAGRAM"
          ? !providers.includes("INSTAGRAM_BUSINESS") || sendMobileReminder
            ? "INSTAGRAM_MOBILE"
            : "INSTAGRAM_DIRECT"
          : platform;

      const max = COMPOSER_MAX_IMAGE_COUNT[platformName];

      if (max === 1 && images.length > 1) {
        return `${
          PROVIDER_DISPLAY_NAME_MAP[platformName]
        } does not support multiple images.`;
      } else if (images.length > max) {
        return `${
          PROVIDER_DISPLAY_NAME_MAP[platformName]
        } allows a maximum of ${max} ${pluralize("image", max)}.`;
      }

      if (platformName == "INSTAGRAM_DIRECT" && images.length) {
        const image = images[0];
        const errors = [];

        if (image.width && image.height) {
          validateAspectRatio(
            image.width,
            image.height,
            IMAGE_VALIDATIONS,
            errors
          );
        }

        if (errors.length) return errors[0];
      }

      if (platformName === "FACEBOOK" && videos.length && images.length) {
        return "Facebook does not support both a video and an image.";
      }

      return null;
    })
  );

const getVideoRejections = (video, providers) => {
  return validateVideo(video, providers).errors;
};

const getUserMentionRejections = (
  rawRichTextEntityMap: Object,
  providers: Provider[]
): string[] => validateMentions(rawRichTextEntityMap, providers);

export default (content: ContentData): { errors: string[], valid: boolean } => {
  let errors: string[] = [];
  const providers = uniq(
    (content?.accountRelationships ?? []).map(r => r?.account?.provider)
  );
  const platforms = uniq(
    (content?.accountRelationships ?? [])
      .map(r => r?.account?.platform)
      .map(p => p)
  );

  const entityMaps: Object[] = content.variations
    .filter(({ rawRichTextEntityMap }) => !!rawRichTextEntityMap)
    .map(({ rawRichTextEntityMap }) => {
      invariant(!!rawRichTextEntityMap, "Falsey maps should be filtered");
      return rawRichTextEntityMap;
    })
    .filter(entityMap => !!entityMap && !!Object.keys(entityMap).length);

  content.variations.forEach(({ videos, images }) => {
    if (videos.length > 0) {
      const video = videos[0];
      errors = errors.concat(getVideoRejections(video, providers));
    }

    if (images.length > 0) {
      errors = errors.concat(
        getImageRejections(
          images,
          platforms,
          providers,
          content.sendMobileReminder,
          videos
        )
      );
    }
  });

  const userMentionRejections: string[] = uniq(
    flatMap(entityMaps, (entityMap: Object) =>
      getUserMentionRejections(entityMap, providers)
    )
  );
  errors = errors.concat(userMentionRejections);

  return {
    valid: !errors.length,
    errors
  };
};
