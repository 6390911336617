/* @flow */
import React, { useState, useCallback } from "react";
import Button from "buttons/Button";
import { Modal } from "layout/modals";
import ModalErrorImage from "components/common/images/shopifyError.png";
import styles from "./index.css";
import { Content, Title, Info, Icon } from "./styles";

const LITE_PLAN_ID = "eddie_lite_monthly_plan";

type Props = {
  limit: number,
  planId: string
};

const PlanModal = ({ limit, planId }: Props) => {
  const [show, setShow] = useState(true);
  const handleClose = useCallback(() => setShow(false), [setShow]);

  if (!show) {
    return null;
  }
  return (
    <div>
      <Modal className={styles.paymentModal} size="large" onClose={handleClose}>
        <Icon>
          <span className="error">
            <img src={ModalErrorImage} />
          </span>
        </Icon>
        <h2 className={styles.modalText}>
          <b>Your 7 day Free Trial has ended.</b>
          <br />
          <br />
          Please add your payment details to continue using Edgar.
        </h2>
        <div className={styles.customModal}>
          <Button href="/payment?payment=true" type="primary">
            Add Payment
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default PlanModal;
