/* @flow */
import React from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import { createFlash } from "actions/flash";

import {
  parseFilterParams,
  buildPaginator,
  handlePageChange
} from "behavioral/withFilter";

import GET_DISPLAY_ERRORS from "queries/getDisplayErrors";
import RESOLVE_DISPLAY_ERRORS from "mutations/resolveDisplayErrors";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Message from "layout/Message";
import Link from "links/Link";
import Icon from "icons/Icon";
import H4 from "typography/H4";
import TextBubble from "icons/TextBubble";
import Button from "buttons/Button";
import LoadingIcon from "icons/LoadingIcon";
import ErrorWithRetry from "components/ErrorWithRetry";
import Paginator from "components/Paginator";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { getDisplayErrors as DisplayErrors } from "graphql-types/getDisplayErrors";
import type { ContentFilter } from "behavioral/withFilter";
import type { resolveDisplayErrors as ResolveDisplayErrorsResult } from "graphql-types/resolveDisplayErrors";
import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";

import styles from "./index.css";

const ERROR_INFO_URL =
  "https://help.meetedgar.com/troubleshooting/common-errors";
const PER_PAGE = 50;

type OwnProps = {
  flash: (type: FlashType, body: string) => void,
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  data?: DisplayErrors,
  error?: string,
  loading?: boolean,
  page?: number,
  refetch?: ({
    filter: ContentFilter,
    page: { limit: number, offset: number }
  }) => void,
  resolveDisplayErrors?: () => Promise<ResolveDisplayErrorsResult>,
  resolveDisplayErrorsLoading?: boolean
} & OwnProps;

const ErrorsMain = ({
  data,
  error,
  history,
  loading,
  location,
  match,
  page,
  refetch = () => {}
}: Props) => {
  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (error) {
    return <ErrorWithRetry>{error}</ErrorWithRetry>;
  }
  const totalDisplayErrors = data?.displayErrors?.totalDisplayErrors || 0;
  if (totalDisplayErrors < 1) {
    return <p>You don't have any notifications!</p>;
  }
  const displayErrors = data?.displayErrors?.data || [];
  return (
    <>
      {displayErrors.map(displayError => {
        return (
          <div className={styles.errorWrapper} key={displayError.id}>
            <Icon color="danger" size="3x" type="ban" />
            <div className={styles.error}>
              <H4>{displayError.title}</H4>
              <p>{displayError.createdAtWithTimezone}</p>
              <p
                dangerouslySetInnerHTML={{ __html: displayError.sanitizedBody }}
              />
              {displayError.variation?.text && (
                <>
                  Original Variation Text: {displayError.variation?.text}
                  <br />
                </>
              )}
              {displayError.account?.displayName && (
                <>
                  Account: {displayError.account?.displayName}
                  <br /> <a href="/accounts">Manage accounts</a>
                  <br />
                </>
              )}
              {displayError.content?.id && (
                <>
                  <a href={`/contents/${displayError.content?.id || "0"}/edit`}>
                    Edit content
                  </a>
                  <br />
                </>
              )}
              {/* Application does not have permission for this action */}
              {displayError.sanitizedBody &&
              displayError.sanitizedBody.includes("code: 10") &&
              displayError.account &&
              displayError.account.displayName &&
              displayError.account.displayName.includes("Instagram") ? (
                <div className={styles.withoutPermission}>
                  <h4>
                    You need to reauthorize your IG connection so Edgar can
                    resume publishing. Please follow the instructions below to
                    reauthorize your IG connection through FB:
                  </h4>
                  <ul>
                    <li>
                      Go to{" "}
                      <a
                        href="https://www.facebook.com/settings?tab=business_tools"
                        target="_blank"
                      >
                        https://www.facebook.com/settings?tab=business_tools
                      </a>
                    </li>
                    <li>
                      Find MeetEdgar on the list, and remove it. Note: You do
                      not need to check "Delete posts, videos or events
                      MeetEdgar posted on your timeline" to remove the tool
                    </li>
                    <li>
                      Go back to{" "}
                      <a
                        href="https://app.meetedgar.com/accounts"
                        target="_blank"
                      >
                        https://app.meetedgar.com/accounts
                      </a>
                    </li>
                    <li>
                      Click the refresh icon next to your personal profile
                    </li>
                    <li>
                      Follow all the steps to give MeetEdgar permission to post
                      to and manage your pages
                    </li>
                    <li>You'll be brought back to the Accounts page</li>
                    <li>
                      Under Connect another account, click on Facebook
                      Page/Group
                    </li>
                    <li>
                      Click on each of the Facebook Pages you want to connect—
                      even if it's already grey! What you want here is for every
                      Page you want to connect to have a teal/turquoise outline
                    </li>
                    <li>
                      Then, click on Connect Selected Pages & Groups at the
                      bottom
                    </li>
                    <li>
                      Reconnect your Instagram profiles by clicking on "Add
                      Instagram" then "Add Business" or "Add Personal"
                    </li>
                    <li>
                      Follow the steps and give full authorization for Edgar to
                      publish
                    </li>
                    <li>
                      Click the Instagram profile even if it says "Already
                      connected"
                    </li>
                    <li>Press "Add Selected Business Profiles"</li>
                  </ul>
                </div>
              ) : null}
              {/* MEDEV-1247 Aspect ratio or audio bit error */}
              {displayError.sanitizedBody &&
              displayError.sanitizedBody.includes("code: 352") &&
              displayError.sanitizedBody.includes("error_subcode: 2207026") &&
              displayError.account &&
              displayError.account.displayName &&
              displayError.account.displayName.includes("Instagram") ? (
                <div className={styles.withoutPermission}>
                  <h4>
                    This video doesn't fit the audio bitrate requirements for
                    publishing to IG. The limit is 128kpbs.
                  </h4>
                </div>
              ) : null}
              {/* MEDEV-1247 Aspect ratio or audio bit error */}
              {displayError.sanitizedBody &&
              displayError.sanitizedBody.includes("code: 352") &&
              displayError.account &&
              displayError.account.displayName &&
              displayError.account.displayName.includes("Instagram") ? (
                <div className={styles.withoutPermission}>
                  <h4>
                    You may want to compress the video so it fits Instagram's
                    limitations for videos published automatically:
                  </h4>
                  <ul>
                    <li>
                      Container: MOV or MP4 (MPEG-4 Part 14), no edit lists,
                      moov atom at the front of the file.
                    </li>
                    <li>
                      Audio codec: AAC, 48khz sample rate maximum, 1 or 2
                      channels (mono or stereo).
                    </li>
                    <li>
                      Video codec: HEVC or H264, progressive scan, closed GOP,
                      4:2:0 chroma subsampling.
                    </li>
                    <li>Frame rate: 23-60 FPS.</li>
                    <li>
                      Picture size:
                      <ol>
                        <li>Maximum columns (horizontal pixels): 1920</li>
                        <li>Minimum aspect ratio [cols / rows]: 4 / 5</li>
                        <li>Maximum aspect ratio [cols / rows]: 16 / 9</li>
                      </ol>
                    </li>
                    <li>Video bitrate: VBR, 5Mbps maximum</li>
                    <li>Audio bitrate: 128kbps</li>
                    <li>Duration: 60 seconds maximum, 3 seconds minimum</li>
                    <li>File size: 100MB maximum</li>
                  </ul>
                </div>
              ) : null}
              <br />
              For more information, please see the{" "}
              <a href={ERROR_INFO_URL}>Common Notifications</a> page in our Help
              Center.
            </div>
          </div>
        );
      })}
      <Paginator
        page={page}
        pageCount={
          totalDisplayErrors ? Math.ceil(totalDisplayErrors / PER_PAGE) : null
        }
        onPageChange={handlePageChange.bind(
          null,
          match,
          location,
          history,
          refetch,
          PER_PAGE
        )}
      />
    </>
  );
};

const ErrorsTitle = ({
  data,
  error,
  loading,
  resolveDisplayErrors,
  resolveDisplayErrorsLoading
}: Props) => {
  if (loading || error) {
    return <PageTitle>Notifications</PageTitle>;
  }
  const totalDisplayErrors = data?.displayErrors?.totalDisplayErrors || 0;
  if (totalDisplayErrors < 1) {
    return (
      <PageTitle>
        Notifications <TextBubble type="count">{totalDisplayErrors}</TextBubble>
      </PageTitle>
    );
  }
  return (
    <div className={styles.titleWrapper}>
      <PageTitle>
        Notifications <TextBubble type="count">{totalDisplayErrors}</TextBubble>
      </PageTitle>
      <Button
        disabled={resolveDisplayErrorsLoading}
        onClick={resolveDisplayErrors}
        type="primary-negative"
      >
        Clear Notifications
      </Button>
    </div>
  );
};

const Errors = (props: Props) => {
  const { topNav } = props;
  const { companyStatus } = topNav;
  return (
    <App
      loggedIn
      topNav={
        props.loading || props.error
          ? topNav
          : {
              ...topNav,
              companyStatus: {
                ...companyStatus,
                errorCount: props.data?.displayErrors?.totalDisplayErrors || 0
              }
            }
      }
      subscriptionStatus={props.subscriptionStatus}
      messages={
        props.data?.company?.queueStatus === "PAUSED" && (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from the{" "}
            <Link href="/queue">Queue</Link> section.
          </Message>
        )
      }
      isNewHeader
      header={<ErrorsTitle {...props} />}
    >
      <ErrorsMain {...props} />
    </App>
  );
};

const ErrorsWrapper = (ownProps: OwnProps) => {
  const { page } = parseFilterParams(ownProps.match, ownProps.location);
  const variables = {
    page: buildPaginator(page, PER_PAGE)
  };
  const { loading, error, data, refetch } = useQuery(GET_DISPLAY_ERRORS, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });
  const [
    resolveDisplayErrors,
    { loading: resolveDisplayErrorsLoading }
  ] = useMutation(RESOLVE_DISPLAY_ERRORS, {
    refetchQueries: _ => [
      {
        query: GET_DISPLAY_ERRORS,
        variables
      }
    ],
    onCompleted(): void {
      ownProps.flash("notice", "Notifications successfully cleared!");
    },
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred when attempting to clear notifications!"
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  if (loading) {
    return <Errors {...ownProps} loading />;
  }
  if (error) {
    return <Errors {...ownProps} error="Uh-oh something went wrong 😿" />;
  }
  return (
    <Errors
      {...ownProps}
      data={data}
      page={page}
      refetch={refetch}
      resolveDisplayErrors={resolveDisplayErrors}
      resolveDisplayErrorsLoading={resolveDisplayErrorsLoading}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    flash(type: FlashType, body: string) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ErrorsWrapper);
