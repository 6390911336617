import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;

  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0 0;
  }
`;

export const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  h4 {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.1s ease;

    img {
      margin: 0 4px 0 0;
      transition: all 0.1s ease;
    }

    &:hover {
      opacity: 0.8;

      img {
        margin-right: 3.5px;
      }
    }
  }
`;

export const Close = styled.div`
  cursor: pointer;
  transition: all 0.1s ease;

  &:hover {
    transform: scale(1.1);
  }
`;
