/* @flow */
import React, { useState } from "react";
import { useQuery, useApolloClient, useMutation } from "@apollo/react-hooks";

import NewHeader from "components/NewHeader";
import { groupBy } from "lodash";
import { useNewUICached } from "util";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Container from "components/common/layout/Container";

import GET_ACCOUNTS from "queries/getAccounts";
import GET_USER_DEVICES from "queries/getUserDevices";
import GET_OAUTH_PATH from "queries/getOAuthPath";
import DELETE_ACCOUNT from "mutations/deleteAccount";

import { Body, H1 } from "typography";
import AccountCard from "./AccountCard";
import DeleteConfirmation from "./DeleteConfirmation";
import ProviderList from "./ProviderList";

import type { Props as TopNavProps } from "components/TopNav";

import styles from "./index.css";

type Props = { topNav: TopNavProps };

function accountCardCssGridArea(account) {
  return `${account.provider}-${account.id}`;
}

export function Accounts(props: Props) {
  const apolloClient = useApolloClient();
  const useNewUI = useNewUICached();
  const { loading, _error, data } = useQuery(GET_ACCOUNTS);
  const { _loading, _getDevicesError, data: devicesData } = useQuery(
    GET_USER_DEVICES
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [currentAccountId, setCurrentAccountId] = useState(null);

  const [deleteAccount] = useMutation(DELETE_ACCOUNT, {
    refetchQueries: [{ query: GET_ACCOUNTS }]
  });

  const accounts = data?.accounts ?? [];
  const devices = devicesData?.user?.devices ?? [];
  const accountsGroupedByParent = groupBy(accounts, account =>
    account.parent ? account.parent.id : "parents"
  );

  async function generateOAuthUri(provider, uid) {
    const result = await apolloClient.query({
      query: GET_OAUTH_PATH,
      variables: { provider, uid }
    });

    return result.data.oauthPath;
  }

  function handleOnClickDelete() {
    deleteAccount({ variables: { id: currentAccountId } });
    setShowDeleteConfirmation(false);
  }

  function handleOnClickCloseDeleteAccountConfirmation() {
    setCurrentAccountId(null);
    setShowDeleteConfirmation(false);
  }

  function handleOnClickDeleteAction(accountId) {
    setCurrentAccountId(accountId);
    setShowDeleteConfirmation(true);
  }

  function gridTemplateAreas() {
    if (!Object.keys(accountsGroupedByParent).length) {
      return null;
    }

    const leftColumnIndex = 0;
    const rightColumnIndex = 1;
    let grid = [[], []];

    accountsGroupedByParent["parents"].map(account => {
      const gridArea = accountCardCssGridArea(account);
      const children = accountsGroupedByParent[account.id];
      const cellCount = 1 + (!!children ? children.length : 0);

      if (grid[leftColumnIndex].length > grid[rightColumnIndex].length) {
        grid[rightColumnIndex].push(...Array(cellCount).fill(gridArea));
      } else {
        grid[leftColumnIndex].push(...Array(cellCount).fill(gridArea));
      }
    });

    const rowCount = Math.max(
      grid[leftColumnIndex].length,
      grid[rightColumnIndex].length
    );
    let gridTemplate = [];

    for (let index = 0; index < rowCount; index++) {
      let leftCell = grid[leftColumnIndex][index] || ".";
      let rightCell = grid[rightColumnIndex][index] || ".";

      gridTemplate.push(`'${leftCell} ${rightCell}'`);
    }

    return gridTemplate.join(" ");
  }

  const currentAccountChildren =
    (currentAccountId && accountsGroupedByParent[currentAccountId]) || [];

  return (
    <Container>
      {useNewUI && <NewHeader title="Accounts" mb {...props.topNav} />}
      <div className={styles.header}>
        {!useNewUI && <H1 className={styles.headerTitleText}>Accounts</H1>}
        <Body>
          Need a little help connecting your social accounts? Check our
          explainer videos! How to connect to a{" "}
          <a
            href="https://help.meetedgar.com/en/articles/422797-connecting-facebook-pages-to-edgar"
            target="_blank"
          >
            FB page
          </a>
          ,{" "}
          <a
            href="https://help.meetedgar.com/en/articles/2146216-connecting-facebook-groups-to-edgar"
            target="_blank"
          >
            FB Group
          </a>
          ,{" "}
          <a
            href="https://help.meetedgar.com/en/articles/2480839-connecting-instagram-accounts-to-edgar#connect-a-personal-instagram-profile"
            target="_blank"
          >
            IG Personal account
          </a>
          ,{" "}
          <a
            href="http://help.meetedgar.com/en/articles/2480839-connecting-instagram-accounts-to-edgar#connect-a-business-instagram-profile"
            target="_blank"
          >
            IG Business account
          </a>
          ,{" "}
          <a
            href="https://help.meetedgar.com/en/articles/1307015-connecting-linkedin-to-edgar"
            target="_blank"
          >
            LinkedIn & LI Company
          </a>
          ,{" "}
          <a
            href="https://help.meetedgar.com/en/articles/4332817-connecting-pinterest-accounts-to-edgar"
            target="_blank"
          >
            Pinterest
          </a>
          ,{" "}
          <a
            href="https://help.meetedgar.com/en/articles/1306921-connecting-twitter-accounts-to-edgar"
            target="_blank"
          >
            Twitter
          </a>{" "}
          or{" "}
          <a
            href="https://help.meetedgar.com/en/articles/5905984-connecting-google-business-profile-to-edgar"
            target="_blank"
          >
            Google Business Profile
          </a>
          .
        </Body>
      </div>

      <div className={styles.listContainer}>
        <ProviderList generateOAuthUri={generateOAuthUri} />
        <div
          className={styles.accountsList}
          style={{ gridTemplateAreas: gridTemplateAreas() }}
        >
          <AccountsList
            loading={loading}
            hasDevices={!!devices.length}
            accountsGroupedByParent={accountsGroupedByParent}
            handleOnClickDeleteAction={handleOnClickDeleteAction}
            generateOAuthUri={generateOAuthUri}
          />
        </div>
      </div>

      {showDeleteConfirmation && (
        <DeleteConfirmation
          onConfirm={handleOnClickDelete}
          onCancel={handleOnClickCloseDeleteAccountConfirmation}
          children={currentAccountChildren}
        />
      )}
    </Container>
  );
}

const AccountsList = ({
  loading,
  hasDevices,
  accountsGroupedByParent,
  handleOnClickDeleteAction,
  generateOAuthUri
}) => {
  if (Object.keys(accountsGroupedByParent).length) {
    return accountsGroupedByParent["parents"].map(account => (
      <AccountCard
        onClickDelete={handleOnClickDeleteAction}
        key={account.id}
        cssGridArea={accountCardCssGridArea(account)}
        {...account}
        generateOAuthUri={generateOAuthUri}
        hasDevices={hasDevices}
        children={accountsGroupedByParent[account.id]}
      />
    ));
  }

  if (loading) {
    return (
      <SkeletonTheme color="var(--inky100)" highlightColor="var(--inky50)">
        <Skeleton width={267} height={74} count={1} />
      </SkeletonTheme>
    );
  }

  return null;
};

export default Accounts;
