// @flow
import React from "react";
import Alert from "layout/Alert";
import DateTimeField from "forms/DateTimeField";

type Props = {
  value: ?string,
  timeZone: string,
  showWarning: boolean,
  isEditing: boolean,
  onChange: (sendAt: ?string) => void
};

// eslint-disable-next-line max-len
const captionText =
  'Edgar will start posting your content on the date/time you specify, and will wait until after that time to start posting any variations you’ve saved. Content posted to Twitter or set to "Use Once" will be marked as expired in your library, while Facebook and LinkedIn content will be used again in the future.';

const captionLink = {
  text: "Why not Twitter?",
  href:
    "https://help.meetedgar.com/edgar-integrations/edgars-authentic-automation-support#twitter"
};

const SendAtField = ({
  value,
  timeZone,
  showWarning,
  isEditing,
  onChange
}: Props) => (
  <DateTimeField
    value={value}
    timeZone={timeZone}
    captionText={captionText}
    captionLink={captionLink}
    notice={
      showWarning && (
        <Alert type="alert" small>
          This content has been sent. Make sure there are publishable variations
          on this content before scheduling a new time.{" "}
          <a
            href="https://help.meetedgar.com/edgar-s-features-and-best-practices/the-add-new-content-button/schedule-settings-when-adding-a-new-post"
            target="_blank"
          >
            Learn More
          </a>
        </Alert>
      )
    }
    popperPlacement="top-start"
    enableResetLink={isEditing}
    onChange={onChange}
  />
);

export default SendAtField;
