/* @flow */
import type {
  loadContent_content_variations as VariationData,
  Platform
} from "graphql-types/loadContent";

const WHY_URL =
  "https://help.meetedgar.com/edgar-integrations/import-content-with-rss-feeds/" +
  "edgars-rss-feed-faqs#what-is-the-facebook-text-box-on-posts-imported-from-rss";

export type VariationWarning = {
  warnings: string[],
  requiredFieldErrorVisible: boolean
};

export function validate(
  variations: VariationData[],
  hasModifiedVariations: boolean,
  platforms: Platform[]
): VariationWarning[] {
  return variations.map(variation => {
    let warnings = [];
    let requiredFieldErrorVisible = false;
    if (hasModifiedVariations) {
      if ((variation.text ?? "").trim() === "") {
        if (variation.images.length > 0 || variation.videos.length > 0) {
          warnings.push("No text, only media will be posted");
        }
        if (variation.images.length === 0 && variation.videos.length === 0) {
          warnings.push("Text or media required to be posted");
          requiredFieldErrorVisible = true;
        }
      }
    }
    if (
      (variation.fbText ?? "").trim() === "" &&
      variation.origin.match(/RSS|AUTOGENERATED/) &&
      platforms.includes("FACEBOOK")
    ) {
      warnings.push(`
        Facebook requires manual input of a post's text for imported content.
        You can copy and paste the text provided or write something different.
        Leaving it blank will post only the attached link or media.
        <a href=${WHY_URL} target="_blank">Read more here</a>.
      `);
    }
    return {
      warnings,
      requiredFieldErrorVisible
    };
  });
}

export default validate;
