/* @flow */
import React from "react";
import { connect } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

import { createFlash } from "actions/flash";

import {
  parseFilterParams,
  buildPaginator,
  handlePageChange
} from "behavioral/withFilter";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Message from "layout/Message";
import Link from "links/Link";
import Button from "buttons/Button";
import Filters from "components/Filters";
import ErrorWithRetry from "components/ErrorWithRetry";
import Search from "components/Search";
import BulkEditTable from "components/BulkEditTable";

import DIFF_BULK_CONTENT_IDS from "queries/diffBulkContentIds";
import GET_ACCOUNTS from "queries/getAccounts";
import GET_CONTENTS from "queries/getContents";
import GET_LATEST_BULK_UPDATE_DISPLAY_ERRORS from "queries/getLatestBulkUpdateDisplayErrors";
import GET_LIBRARY_STATUS from "queries/getLibraryStatus";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import BULK_CONTENT_UPDATE from "mutations/bulkContentUpdate";

import styles from "./index.css";

import type { Match, Location, RouterHistory } from "react-router-dom";
import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";
import type { diffBulkContentIdsVariables } from "graphql-types/diffBulkContentIds";
import type { getAccounts as AccountsData } from "graphql-types/getAccounts";
import type {
  getContents_contents_data as ContentData,
  getContents_user_onboardingProgress as OnboardingProgress
} from "graphql-types/getContents";
import type {
  bulkContentUpdate as BulkContentUpdateResult,
  bulkContentUpdateVariables,
  ContentsFilter
} from "graphql-types/bulkContentUpdate";
import type { getLatestBulkUpdateDisplayErrors as GetLatestBulkUpdateDisplayErrorsData } from "graphql-types/getLatestBulkUpdateDisplayErrors";

type OwnProps = {
  flash: (type: FlashType, body: string) => void,
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  accountsData?: AccountsData,
  accountsError?: boolean,
  accountsLoading?: boolean,
  bulkContentUpdate?: ({
    variables: bulkContentUpdateVariables
  }) => Promise<{ data: BulkContentUpdateResult }>,
  contents?: ContentData[],
  diffBulkContentIds?: ({
    variables: diffBulkContentIdsVariables
  }) => void,
  diffBulkContentIdsError?: string,
  diffBulkContentIdsLoading?: boolean,
  diffedBulkContentIds?: string[],
  dismissOnboarding?: () => Promise<{ data: DismissOnboardingResult }>,
  displayErrorsData?: GetLatestBulkUpdateDisplayErrorsData,
  displayErrorsError?: string,
  displayErrorsLoading?: boolean,
  encodedFilter: string,
  error?: string,
  filter: ContentsFilter,
  getLatestBulkUpdateDisplayErrors?: () => void,
  getLibraryStatus: () => void,
  isQueuePaused?: boolean,
  libraryStatus?: string,
  libraryStatusError?: string,
  libraryStatusLoading?: boolean,
  loading?: boolean,
  onPageChange?: number => void,
  onboardingProgress?: OnboardingProgress,
  page?: number,
  refetchContents: () => void,
  totalFilteredContents?: number,
  totalContentsForStatus?: number
} & OwnProps;

const PER_PAGE = 50;

const BulkEditMain = ({
  accountsData,
  accountsError = false,
  accountsLoading = true,
  bulkContentUpdate,
  contents = [],
  diffBulkContentIds,
  diffBulkContentIdsError,
  diffBulkContentIdsLoading,
  diffedBulkContentIds,
  displayErrorsData,
  displayErrorsLoading,
  encodedFilter,
  filter,
  flash,
  getLatestBulkUpdateDisplayErrors,
  getLibraryStatus,
  libraryStatus,
  libraryStatusError,
  libraryStatusLoading,
  loading = false,
  onPageChange,
  page = 1,
  refetchContents,
  totalFilteredContents = 0,
  totalContentsForStatus = 0
}: Props) => {
  return (
    <>
      <Search totalCount={totalContentsForStatus} />
      <BulkEditTable
        accounts={accountsData?.accounts || []}
        accountsError={accountsError}
        accountsLoading={accountsLoading}
        bulkContentUpdate={bulkContentUpdate}
        contents={contents}
        diffBulkContentIds={diffBulkContentIds}
        diffBulkContentIdsError={diffBulkContentIdsError}
        diffBulkContentIdsLoading={diffBulkContentIdsLoading}
        diffedBulkContentIds={diffedBulkContentIds}
        displayErrorsData={displayErrorsData}
        displayErrorsLoading={displayErrorsLoading}
        encodedFilter={encodedFilter}
        filter={filter}
        flash={flash}
        getLatestBulkUpdateDisplayErrors={getLatestBulkUpdateDisplayErrors}
        getLibraryStatus={getLibraryStatus}
        loading={loading}
        libraryStatus={libraryStatus}
        libraryStatusError={libraryStatusError}
        libraryStatusLoading={libraryStatusLoading}
        onPageChange={onPageChange}
        page={page}
        perPage={PER_PAGE}
        refetchContents={refetchContents}
        totalFilteredContents={totalFilteredContents}
      />
    </>
  );
};

const CheckErrors = (props: Props) => {
  if (props.error) {
    return <ErrorWithRetry>{props.error}</ErrorWithRetry>;
  }

  if (props.diffBulkContentIdsError) {
    return <ErrorWithRetry>{props.diffBulkContentIdsError}</ErrorWithRetry>;
  }

  if (props.displayErrorsError) {
    return <ErrorWithRetry>{props.displayErrorsError}</ErrorWithRetry>;
  }

  return <BulkEditMain {...props} />;
};

const BulkEdit = (props: Props) => {
  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      onboardingProgress={props.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      messages={
        props.isQueuePaused && (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from the{" "}
            <Link href="/queue">Queue</Link> section.
          </Message>
        )
      }
      isNewHeader
      header={
        <div className={styles.titleWrapper}>
          <PageTitle>Bulk edit</PageTitle>
          <Button href={`/contents${location.search}`} type="primary">
            Back to library
          </Button>
        </div>
      }
      sidebar={<Filters location={props.location} showNoAccount />}
    >
      <CheckErrors {...props} />
    </App>
  );
};

const BulkEditWrapper = (ownProps: OwnProps) => {
  const { filter, page } = parseFilterParams(ownProps.match, ownProps.location);

  const [
    diffBulkContentIds,
    {
      loading: diffBulkContentIdsLoading,
      error: diffBulkContentIdsError,
      data: diffedBulkContentIds
    }
  ] = useLazyQuery(DIFF_BULK_CONTENT_IDS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const { loading, error, data, refetch } = useQuery(GET_CONTENTS, {
    variables: {
      filter,
      page: buildPaginator(page, PER_PAGE)
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const {
    loading: accountsLoading,
    error: accountsError,
    data: accountsData
  } = useQuery(GET_ACCOUNTS, {
    variables: {
      onlyPostable: true
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [
    getLatestBulkUpdateDisplayErrors,
    {
      loading: displayErrorsLoading,
      error: displayErrorsError,
      data: displayErrorsData
    }
  ] = useLazyQuery(GET_LATEST_BULK_UPDATE_DISPLAY_ERRORS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [
    getLibraryStatus,
    {
      loading: libraryStatusLoading,
      error: libraryStatusError,
      data: libraryStatusData
    }
  ] = useLazyQuery(GET_LIBRARY_STATUS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);

  const [bulkContentUpdate] = useMutation(BULK_CONTENT_UPDATE);

  return (
    <BulkEdit
      {...ownProps}
      accountsData={accountsData}
      accountsError={accountsError}
      accountsLoading={accountsLoading}
      bulkContentUpdate={bulkContentUpdate}
      contents={data?.contents?.data}
      diffBulkContentIds={diffBulkContentIds}
      diffBulkContentIdsError={diffBulkContentIdsError}
      diffBulkContentIdsLoading={diffBulkContentIdsLoading}
      diffedBulkContentIds={diffedBulkContentIds?.diffBulkContentIds || []}
      dismissOnboarding={dismissOnboarding}
      displayErrorsData={displayErrorsData}
      displayErrorsError={displayErrorsError}
      displayErrorsLoading={displayErrorsLoading}
      // This is a little weird, but we're using the filter exclusively to decide whether to
      // reset selected content items. E.g., if page changes, we want to keep the selected items;
      // if a filter changes, like category, we want to reset since the set of content items
      // has potentially changed.
      encodedFilter={JSON.stringify(
        Object.entries(filter).sort(
          (a, b): number => {
            if (a[0] === b[0]) {
              return 0;
            }
            return a[0] > b[0] ? 1 : -1;
          }
        )
      )}
      error={error && "Uh-oh something went wrong 😿"}
      filter={filter}
      getLatestBulkUpdateDisplayErrors={getLatestBulkUpdateDisplayErrors}
      getLibraryStatus={getLibraryStatus}
      isQueuePaused={data?.company?.queueStatus === "PAUSED"}
      libraryStatus={libraryStatusData?.user?.company?.libraryStatus}
      libraryStatusError={libraryStatusError}
      libraryStatusLoading={libraryStatusLoading}
      loading={loading}
      onPageChange={handlePageChange.bind(
        null,
        ownProps.match,
        ownProps.location,
        ownProps.history,
        refetch,
        PER_PAGE
      )}
      page={page}
      refetchContents={refetch}
      totalFilteredContents={data?.contents?.totalFilteredContents}
      totalContentsForStatus={data?.contents?.totalContentsForStatus}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(BulkEditWrapper);
