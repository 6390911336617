/* @flow */
import React from "react";
import get from "lodash/get";
import moment from "moment";
import { LoadingIcon } from "icons";

import { DAYS } from "constants";
import Timeslots from "../Timeslots";

import styles from "./index.css";
// All hours of the day using 0-indexed 24 hour time (0-23)
// ordered such that the day begins at 5am and ends it at 4am
const HOURS = Array.from({ length: 24 }, (_, i) => (i + 5) % 24);

import { buildDesktopCalendarData } from "../util.js";
import { type Props } from "../index.js";

const DesktopCalendar = ({
  schedules,
  onClickNewTimeslot,
  onClickEditTimeslot,
  focusedTimeslotIds
}: Props) => {
  const data = buildDesktopCalendarData(schedules);

  return (
    <div className={styles.root}>
      <DaysHeader />
      <Body>
        {data ? (
          HOURS.map(hour => (
            <HourRow key={hour} hour={hour}>
              {Object.keys(get(data, hour, [null])).map(minute => (
                <MinuteRow key={`${hour}-${minute}`}>
                  {DAYS.map(day => (
                    <Day
                      key={`${hour}-${minute}-${day}`}
                      day={day}
                      hour={hour}
                      minute={minute}
                      onClick={onClickNewTimeslot.bind(null, day, hour, minute)}
                    >
                      <Timeslots
                        hour={hour}
                        minute={minute}
                        schedules={get(data, [hour, minute, day], [])}
                        focusedTimeslotIds={focusedTimeslotIds}
                        onClickTimeslot={onClickEditTimeslot}
                      />
                    </Day>
                  ))}
                </MinuteRow>
              ))}
            </HourRow>
          ))
        ) : (
          <div className={styles.loading}>
            <LoadingIcon />
          </div>
        )}
      </Body>
    </div>
  );
};

const DaysHeader = () => (
  <div className={styles.daysHeader}>
    <div>&nbsp;</div>
    {DAYS.map(d => (
      <div key={d}>{d}</div>
    ))}
  </div>
);

const Body = ({ children }) => <div className={styles.body}>{children}</div>;

const HourRow = ({ hour, children }) => (
  <div className={styles.hourRow}>
    <div className={styles.hourHeader}>
      {moment
        .utc()
        .set({ hour })
        .format("ha")}
    </div>
    <div className={styles.hour}>{children}</div>
  </div>
);

const MinuteRow = ({ children }) => (
  <div className={styles.minuteRow}>{children}</div>
);

const Day = ({ children, onClick, day, hour, minute }) => (
  <div
    className={styles.day}
    onClick={onClick}
    data-day={day}
    data-hour={hour}
    data-minute={minute}
  >
    {children}
  </div>
);

export default DesktopCalendar;
