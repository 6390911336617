/* @flow */
import { connect } from "react-redux";
import { createFlash } from "actions/flash";

import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";

type Props = {
  dispatch: Dispatch,
  body?: string,
  type?: FlashType
};

const FlashMessage = ({ dispatch, body = "", type = "info" }: Props) => {
  if (body && body != "") {
    dispatch(createFlash(type, body));
  }
  return null;
};

export default connect()(FlashMessage);
