/* @flow */
import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import { useNewUICached } from "util";
import { createFlash } from "actions/flash";

import GET_USER_SETTINGS from "queries/getUserSettings";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import UPDATE_LINK_SHORTENER from "mutations/updateLinkShortener";
import UPDATE_DEFAULT_LINK_SHORTENER_DOMAIN from "mutations/updateDefaultLinkShortenerDomain";
import UPDATE_UTM_PARAMETERS from "mutations/updateUtmParameters";
import REMOVE_DEVICE from "mutations/removeDevice";
import REGENERATE_API_KEY from "mutations/regenerateApiKey";
import UPDATE_PAYMENT_METHOD from "mutations/updatePaymentMethod";
import UPDATE_SUBSCRIPTION from "mutations/updateSubscription";
import REACTIVATE_SUBSCRIPTION from "mutations/reactivateSubscription";
import CREATE_INVITATION from "mutations/createInvitation";
import UPDATE_INVITATION from "mutations/updateInvitation";
import DELETE_INVITATION from "mutations/deleteInvitation";
import DELETE_TEAM_MEMBER from "mutations/deleteTeamMember";

import App from "components/App";
import Message from "layout/Message";
import Link from "links/Link";
import LoadingIcon from "icons/LoadingIcon";
import ErrorWithRetry from "components/ErrorWithRetry";
import H3 from "typography/H3";
import Button from "buttons/Button";
import Label from "typography/Label";
import LinkShortenerSettings from "components/LinkShortenerSettings";
import LinkShortenerDomain from "components/LinkShortenerDomain";
import UtmParametersSettings from "components/UtmParametersSettings";
import UpdatePaymentMethod from "components/UpdatePaymentMethod";
import SubscriptionManager from "components/SubscriptionManager";
import AICredits from "../../components/AICredits";
import TeamManager from "components/TeamManager";
import PageTitle from "links/PageTitle";
import NewHeader from "components/NewHeader";

import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";

import type {
  getUserSettings_teamMembers as TeamMember,
  getUserSettings_user as User,
  getUserSettings_user_onboardingProgress as OnboardingProgress
} from "graphql-types/getUserSettings";

import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";

import type {
  updateDefaultLinkShortenerDomain as UpdateDefaultLinkShortenerDomainResult,
  updateDefaultLinkShortenerDomainVariables
} from "graphql-types/updateDefaultLinkShortenerDomain";

import type {
  updateLinkShortener as UpdateLinkShortenerResult,
  updateLinkShortenerVariables
} from "graphql-types/updateLinkShortener";

import type {
  updateUtmParameters as UpdateUtmParametersResult,
  updateUtmParametersVariables
} from "graphql-types/updateUtmParameters";

import type {
  removeDevice as RemoveDeviceResult,
  removeDeviceVariables
} from "graphql-types/removeDevice";

import type { regenerateApiKey as RegenerateApiKeyResult } from "graphql-types/regenerateApiKey";

import type {
  updatePaymentMethod as UpdatePaymentMethodResult,
  updatePaymentMethodVariables
} from "graphql-types/updatePaymentMethod";

import type {
  updateSubscription as UpdateSubscriptionResult,
  updateSubscriptionVariables
} from "graphql-types/updateSubscription";

import type { reactivateSubscription as ReactivateSubscriptionResult } from "graphql-types/reactivateSubscription";

import type {
  createInvitation as CreateInvitationResult,
  createInvitationVariables
} from "graphql-types/createInvitation";

import type {
  updateInvitation as UpdateInvitationResult,
  updateInvitationVariables
} from "graphql-types/updateInvitation";

import type {
  deleteInvitation as DeleteInvitationResult,
  deleteInvitationVariables
} from "graphql-types/deleteInvitation";

import type {
  deleteTeamMember as DeleteTeamMemberResult,
  deleteTeamMemberVariables
} from "graphql-types/deleteTeamMember";

import styles from "./index.css";

type OwnProps = {
  flash: (type: FlashType, body: string) => void,
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  createInvitation?: ({
    variables: createInvitationVariables
  }) => Promise<{ data: CreateInvitationResult }>,
  createInvitationLoading?: boolean,
  deleteInvitation?: ({
    variables: deleteInvitationVariables
  }) => Promise<{ data: DeleteInvitationResult }>,
  deleteInvitationLoading?: boolean,
  deleteTeamMember?: ({
    variables: deleteTeamMemberVariables
  }) => Promise<{ data: DeleteTeamMemberResult }>,
  deleteTeamMemberLoading?: boolean,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>,
  error?: string,
  loading?: boolean,
  onClosePaymentModal: () => void,
  onOpenPaymentModal: () => void,
  onboardingProgress?: OnboardingProgress,
  reactivateSubscription?: () => Promise<{
    data: ReactivateSubscriptionResult
  }>,
  reactivateSubscriptionLoading?: boolean,
  regenerateApiKey?: () => Promise<{ data: RegenerateApiKeyResult }>,
  regenerateApiKeyError?: boolean,
  regenerateApiKeyLoading?: boolean,
  removeDevice?: ({
    variables: removeDeviceVariables
  }) => Promise<{ data: RemoveDeviceResult }>,
  removeDeviceError?: boolean,
  removeDeviceLoading?: boolean,
  showPaymentModal: boolean,
  teamMembers?: TeamMember[],
  updateDefaultLinkShortenerDomain?: ({
    variables: updateDefaultLinkShortenerDomainVariables
  }) => Promise<{ data: UpdateDefaultLinkShortenerDomainResult }>,
  updateDefaultLinkShortenerDomainLoading?: boolean,
  updateInvitation?: ({
    variables: updateInvitationVariables
  }) => Promise<{ data: UpdateInvitationResult }>,
  updateInvitationLoading?: boolean,
  updateLinkShortener?: ({
    variables: updateLinkShortenerVariables
  }) => Promise<{ data: UpdateLinkShortenerResult }>,
  updateLinkShortenerLoading?: boolean,
  updatePaymentMethod?: ({
    variables: updatePaymentMethodVariables
  }) => Promise<{ data: UpdatePaymentMethodResult }>,
  updatePaymentMethodLoading?: boolean,
  updateSubscription?: ({
    variables: updateSubscriptionVariables
  }) => Promise<{ data: UpdateSubscriptionResult }>,
  updateSubscriptionLoading?: boolean,
  updateUtmParameters?: ({
    variables: updateUtmParametersVariables
  }) => Promise<{ data: UpdateUtmParametersResult }>,
  updateUtmParametersLoading?: boolean,
  user?: User
} & OwnProps;

type SectionProps = {
  user?: User
};

const COLLAB_ROLE = "collaborator";
const OWNER_ROLE = "owner";

const REBRANDLY_SERVICE = "rebrandly";

export const getUserFieldValue = (user: ?User, field: string): string => {
  return user?.[field] || "";
};

export const getUserAdminEmail = (teamMembers: ?TeamMember[]): string => {  
  return teamMembers?.find(x => x.role === OWNER_ROLE)?.email || "";
};

export const getUserFullName = (user: ?User): string => {
  return `${getUserFieldValue(user, "firstName")} ${getUserFieldValue(
    user,
    "lastName"
  )}`.trim();
};

export const getUserFullAddress = (user: ?User): string => {
  return [
    user?.company?.address1,
    user?.company?.address2,
    user?.company?.city,
    [user?.company?.region, user?.company?.zip].filter(x => !!x).join(" "),
    user?.company?.country
  ]
    .map(x => x && x.trim())
    .filter(x => !!x)
    .join(", ")
    .trim();
};

export const getUserSendPerformanceReport = (user: ?User): string => {
  return user?.sendPerformanceReport === false ? "No" : "Yes";
};

export const getUserCardNumber = (user: ?User): string => {
  const last4 = user?.company?.paymentSource?.last4;
  return last4 ? `xxxx xxxx xxxx ${last4}` : "N/A";
};

export const NextPaymentSection = ({ user }: SectionProps) => {
  if (user?.company?.lastInvoice?.paid === false && user?.company?.lastInvoice?.subtotal > 0) {
    const lastInvoice = user?.company?.lastInvoice;
    return (
      <div className={styles.sectionField}>
        <Label className={styles.sectionFieldLabel}>Next payment</Label>
        <div>
          <span>
            We were unable to process {lastInvoice?.due} due on{" "}
            {lastInvoice?.forcedDueDate}.
          </span>
          {lastInvoice?.closed ? (
            <span>
              Please <a href="mailto:support@meetedgar.com">contact support</a>{" "}
              to resolve your billing problem.
            </span>
          ) : (
            <span>We will try again on {lastInvoice?.dueDate}</span>
          )}
        </div>
      </div>
    );
  }
  if (user?.company?.nextInvoice) {
    const nextInvoice = user?.company?.nextInvoice;
    return (
      <>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Next payment</Label>
          <span>
            {nextInvoice?.due}{" "}
            {user?.company?.paymentSource ? "will be charged" : "is due"} on{" "}
            {nextInvoice?.dueDate}
          </span>
        </div>
        {nextInvoice?.coupon ? (
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabel}>Coupon applied</Label>
            <span>{nextInvoice?.coupon}</span>
          </div>
        ) : null}
      </>
    );
  }
  return (
    <div className={styles.sectionField}>
      <Label className={styles.sectionFieldLabel}>Next payment</Label>
      <span>No upcoming Bills</span>
    </div>
  );
};

export const UserSettingsMain = ({
  createInvitation,
  createInvitationLoading,
  deleteInvitation,
  deleteInvitationLoading,
  deleteTeamMember,
  deleteTeamMemberLoading,
  error,
  flash,
  loading,
  onClosePaymentModal,
  onOpenPaymentModal,
  reactivateSubscription,
  reactivateSubscriptionLoading,
  regenerateApiKey,
  regenerateApiKeyLoading,
  removeDevice,
  removeDeviceLoading,
  showPaymentModal,
  teamMembers,
  updateDefaultLinkShortenerDomain,
  updateDefaultLinkShortenerDomainLoading,
  updateInvitation,
  updateInvitationLoading,
  updateLinkShortener,
  updateLinkShortenerLoading,
  updatePaymentMethod,
  updatePaymentMethodLoading,
  updateSubscription,
  updateSubscriptionLoading,
  updateUtmParameters,
  updateUtmParametersLoading,
  user
}: Props) => {
  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (error) {
    return <ErrorWithRetry>{error}</ErrorWithRetry>;
  }
  return (
    <>
      <div className={styles.section}>
        <div className={styles.sectionHeader}>
          <H3>Profile</H3>
          <Button
            href="/profile"
            className={styles.sectionButton}
            type="primary"
          >
            Edit profile
          </Button>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Name</Label>
          <span>{getUserFullName(user)}</span>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Email address</Label>
          <span>{getUserFieldValue(user, "email")}</span>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Time zone</Label>
          <span>{user?.company?.timeZone || ""}</span>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Company name</Label>
          <span>{user?.company?.name || ""}</span>
        </div>
        {user?.role !== OWNER_ROLE && (
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabel}>Company Admin Email</Label>
            <span>{getUserAdminEmail(teamMembers)}</span>
          </div>
        )}
        {user?.role === OWNER_ROLE && (
          <>
            <div className={styles.sectionField}>
              <Label className={styles.sectionFieldLabel}>Address</Label>
              <span>{getUserFullAddress(user)}</span>
            </div>
            <div className={styles.sectionField}>
              <Label className={styles.sectionFieldLabel}>Phone number</Label>
              <span>{getUserFieldValue(user, "phone")}</span>
            </div>
          </>
        )}
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Link shortener</Label>
          <LinkShortenerSettings
            display={user?.company?.defaultLinkShortenerDomain?.display}
            flash={flash}
            service={user?.company?.defaultLinkShortenerDomain?.service}
            updateLinkShortener={updateLinkShortener}
            updateLinkShortenerLoading={updateLinkShortenerLoading}
            user={user}
            defaultOpened={location.hash === "#link_shortener"}
          />
        </div>
        {user?.company?.defaultLinkShortenerDomain?.service ===
          REBRANDLY_SERVICE && (
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabel}>
              Rebrand.ly domain
            </Label>
            <LinkShortenerDomain
              defaultIsModalOpen={location.hash === "#link_shortener_rebrandly"}
              defaultLinkShortenerDomainId={
                user?.company?.defaultLinkShortenerDomain?.id
              }
              flash={flash}
              rebrandlyDomains={user?.company?.rebrandlyDomains}
              updateDefaultLinkShortenerDomain={
                updateDefaultLinkShortenerDomain
              }
              updateDefaultLinkShortenerDomainLoading={
                updateDefaultLinkShortenerDomainLoading
              }
            />{" "}
          </div>
        )}
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>UTM</Label>
          <UtmParametersSettings
            flash={flash}
            isEnabled={user?.company?.includeUtmParameters}
            updateUtmParameters={updateUtmParameters}
            updateUtmParametersLoading={updateUtmParametersLoading}
          />
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Reports</Label>
          <span>{getUserSendPerformanceReport(user)}</span>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Mobile devices</Label>
          <div className={styles.sectionFieldItems}>
            {(user?.devices || []).map(device => {
              return (
                <div className={styles.sectionFieldItem} key={device.id}>
                  <span>{device.name}</span>
                  <Button
                    className={styles.sectionButton}
                    disabled={removeDeviceLoading}
                    onClick={(): void => {
                      if (window.confirm("Are you sure you want to remove this device?")) {
                        const promise = removeDevice?.({
                          variables: { deviceId: parseInt(device.id) }
                        });
                        if (promise) {
                          promise.then(response => {
                            const errors =
                              response?.data?.removeDevice?.errors || [];
                            if (errors.length > 0) {
                              flash("error", errors[0]);
                            } else {
                              flash("notice", "Mobile device removed!");
                            }
                          });
                        }
                      }
                    }}
                    type="light"
                  >
                    Remove device
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        {user?.role === OWNER_ROLE && (
          <div className={styles.sectionField}>
            <Label className={styles.sectionFieldLabel}>API key</Label>
            <div className={styles.sectionFieldItems}>
              <div className={styles.sectionFieldItem}>
                <span>
                  {getUserFieldValue(user, "apiKey")} (
                  <a
                    href="#"
                    onClick={async (evt: Event) => {
                      evt.preventDefault();
                      try {
                        await navigator.clipboard.writeText(
                          getUserFieldValue(user, "apiKey")
                        );
                        flash("notice", "API key copied!");
                      } catch (_err) {
                        flash("error", "Unable to copy.");
                      }
                    }}
                  >
                    Copy
                  </a>
                  )
                </span>
                <Button
                  className={styles.sectionButton}
                  disabled={regenerateApiKeyLoading}
                  onClick={(): void => {
                    const promise = regenerateApiKey?.();
                    if (promise) {
                      promise.then(response => {
                        const errors =
                          response?.data?.regenerateApiKey?.errors || [];
                        if (errors.length > 0) {
                          flash("error", errors[0]);
                        } else {
                          flash("notice", "API key regenerated!");
                        }
                      });
                    }
                  }}
                  type="light"
                >
                  Regenerate key
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {user?.role === COLLAB_ROLE ||
        (user?.role === OWNER_ROLE && (
          <TeamManager
            createInvitation={createInvitation}
            createInvitationLoading={createInvitationLoading}
            deleteInvitation={deleteInvitation}
            deleteTeamMember={deleteTeamMember}
            deleteTeamMemberLoading={deleteTeamMemberLoading}
            deleteInvitationLoading={deleteInvitationLoading}
            flash={flash}
            teamMembers={teamMembers}
            updateInvitation={updateInvitation}
            updateInvitationLoading={updateInvitationLoading}
            user={user}
          />
        ))}
      {user?.role === OWNER_ROLE && (
        <>
          {!user?.company?.shopifyOrigin && (
            <div className={styles.section}>
              <div className={styles.sectionHeader}>
                <H3>Payment</H3>
                <UpdatePaymentMethod
                  flash={flash}
                  open={showPaymentModal}
                  onClose={onClosePaymentModal}
                  onOpen={onOpenPaymentModal}
                  updatePaymentMethod={updatePaymentMethod}
                  updatePaymentMethodLoading={updatePaymentMethodLoading}
                />
              </div>
              <NextPaymentSection user={user} />
              <div className={styles.sectionField}>
                <Label className={styles.sectionFieldLabel}>Card type</Label>
                <span>{user?.company?.paymentSource?.type || "N/A"}</span>
              </div>
              <div className={styles.sectionField}>
                <Label className={styles.sectionFieldLabel}>Card number</Label>
                <span>{getUserCardNumber(user)}</span>
              </div>
              <div className={styles.sectionField}>
                <Label className={styles.sectionFieldLabel}>Card expiry</Label>
                <span>{user?.company?.paymentSource?.expDisplay || "N/A"}</span>
              </div>
              <div className={styles.sectionField}>
                <Button
                  className={`${styles.sectionButton} ${styles.pushRight}`}
                  href="/user/invoices"
                  type="light"
                >
                  Printable invoices
                </Button>
              </div>
            </div>
          )}
          <SubscriptionManager
            company={user?.company}
            flash={flash}
            reactivateSubscription={reactivateSubscription}
            reactivateSubscriptionLoading={reactivateSubscriptionLoading}
            updateSubscription={updateSubscription}
            updateSubscriptionLoading={updateSubscriptionLoading}
          />
          <AICredits company={user?.company}/>
        </>
      )}
    </>
  );
};

export const UserSettings = (props: Props) => {
  const useNewUI = useNewUICached();

  useEffect((): void => {
    switch (location.hash) {
      case "#profile_updated":
        props.flash("notice", "You updated your account successfully!");
        break;
      case "#link_shortener_rebrandly":
      case "#link_shortener_updated":
        props.flash("notice", "Link shortener has been successfully updated!");
        break;
      case "#account_limit_updated":
        props.flash(
          "notice",
          "You have updated you accounts limit successfully!"
        );
      default:
      // Pass.
    }
    if (location.hash === "#update-payment") {
      props.onOpenPaymentModal();
    }
    // ESLint was automatically adding `props` as a dependency.
  }, [location.hash]); // eslint-disable-line

  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      messages={
        props.user?.company?.queueStatus === "PAUSED" && (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from the{" "}
            <Link href="/queue">Queue</Link> section.
          </Message>
        )
      }
      onboardingProgress={props.user?.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      isNewHeader={!useNewUI}
      header={
        useNewUI ? (
          <NewHeader title="Settings" {...props.topNav} />
        ) : (
          <PageTitle>Settings</PageTitle>
        )
      }
    >
      <UserSettingsMain {...props} />
    </App>
  );
};

export const UserSettingsWrapper = (ownProps: OwnProps) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const handleClosePaymentModal = useCallback(
    () => setShowPaymentModal(false),
    [setShowPaymentModal]
  );
  const handleOpenPaymentModal = useCallback(() => setShowPaymentModal(true), [
    setShowPaymentModal
  ]);

  const { loading, error, data } = useQuery(GET_USER_SETTINGS);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);
  const [
    removeDevice,
    { loading: removeDeviceLoading, error: removeDeviceError }
  ] = useMutation(REMOVE_DEVICE, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred while attempting to remove the mobile device!"
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  const [
    updateLinkShortener,
    { loading: updateLinkShortenerLoading }
  ] = useMutation(UPDATE_LINK_SHORTENER, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred while attempting to update the link shortener."
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  const [
    updateDefaultLinkShortenerDomain,
    { loading: updateDefaultLinkShortenerDomainLoading }
  ] = useMutation(UPDATE_DEFAULT_LINK_SHORTENER_DOMAIN, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred while attempting to update the Rebrand.ly domain."
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  const [
    updateUtmParameters,
    { loading: updateUtmParametersLoading }
  ] = useMutation(UPDATE_UTM_PARAMETERS, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred while attempting to update UTM parameters."
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  const [
    regenerateApiKey,
    { loading: regenerateApiKeyLoading, error: regenerateApiKeyError }
  ] = useMutation(REGENERATE_API_KEY, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred while attempting to regenerate the API key!"
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  const [
    updatePaymentMethod,
    { loading: updatePaymentMethodLoading }
  ] = useMutation(UPDATE_PAYMENT_METHOD, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred while attempting to update your payment method!"
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  const [
    updateSubscription,
    { loading: updateSubscriptionLoading }
  ] = useMutation(UPDATE_SUBSCRIPTION, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred when attempting to update your subscription."
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });
  const [
    reactivateSubscription,
    { loading: reactivateSubscriptionLoading }
  ] = useMutation(REACTIVATE_SUBSCRIPTION, {
    refetchQueries: _ => [
      {
        query: GET_USER_SETTINGS
      }
    ],
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred when attempting to reactivate your subscription."
      );
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });

  const [createInvitation, { loading: createInvitationLoading }] = useMutation(
    CREATE_INVITATION,
    {
      refetchQueries: _ => [
        {
          query: GET_USER_SETTINGS
        }
      ],
      onError(): void {
        ownProps.flash(
          "error",
          "An error occurred when attempting to send the invitation."
        );
      },
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true
    }
  );

  const [updateInvitation, { loading: updateInvitationLoading }] = useMutation(
    UPDATE_INVITATION,
    {
      refetchQueries: _ => [
        {
          query: GET_USER_SETTINGS
        }
      ],
      onError(): void {
        ownProps.flash(
          "error",
          "An error occurred when attempting to update the invitation."
        );
      },
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true
    }
  );

  const [deleteInvitation, { loading: deleteInvitationLoading }] = useMutation(
    DELETE_INVITATION,
    {
      refetchQueries: _ => [
        {
          query: GET_USER_SETTINGS
        }
      ],
      onError(): void {
        ownProps.flash(
          "error",
          "An error occurred when attempting to delete the invitation."
        );
      },
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true
    }
  );

  const [deleteTeamMember, { loading: deleteTeamMemberLoading }] = useMutation(
    DELETE_TEAM_MEMBER,
    {
      refetchQueries: _ => [
        {
          query: GET_USER_SETTINGS
        }
      ],
      onError(): void {
        ownProps.flash(
          "error",
          "An error occurred when attempting to delete the team member."
        );
      },
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true
    }
  );

  if (loading) {
    return (
      <UserSettings
        {...ownProps}
        showPaymentModal={showPaymentModal}
        onOpenPaymentModal={handleOpenPaymentModal}
        onClosePaymentModal={handleClosePaymentModal}
        loading
      />
    );
  }
  if (error) {
    return (
      <UserSettings
        {...ownProps}
        showPaymentModal={showPaymentModal}
        onOpenPaymentModal={handleOpenPaymentModal}
        onClosePaymentModal={handleClosePaymentModal}
        error="Uh-oh something went wrong 😿"
      />
    );
  }
  return (
    <UserSettings
      {...ownProps}
      createInvitation={createInvitation}
      createInvitationLoading={createInvitationLoading}
      deleteInvitation={deleteInvitation}
      deleteInvitationLoading={deleteInvitationLoading}
      deleteTeamMember={deleteTeamMember}
      deleteTeamMemberLoading={deleteTeamMemberLoading}
      dismissOnboarding={dismissOnboarding}
      reactivateSubscription={reactivateSubscription}
      reactivateSubscriptionLoading={reactivateSubscriptionLoading}
      regenerateApiKey={regenerateApiKey}
      regenerateApiKeyError={regenerateApiKeyError}
      regenerateApiKeyLoading={regenerateApiKeyLoading}
      removeDevice={removeDevice}
      removeDeviceError={removeDeviceError}
      removeDeviceLoading={removeDeviceLoading}
      updateDefaultLinkShortenerDomain={updateDefaultLinkShortenerDomain}
      updateDefaultLinkShortenerDomainLoading={
        updateDefaultLinkShortenerDomainLoading
      }
      showPaymentModal={showPaymentModal}
      teamMembers={data?.teamMembers}
      updateInvitation={updateInvitation}
      updateInvitationLoading={updateInvitationLoading}
      updateLinkShortener={updateLinkShortener}
      updateLinkShortenerLoading={updateLinkShortenerLoading}
      updatePaymentMethod={updatePaymentMethod}
      updatePaymentMethodLoading={updatePaymentMethodLoading}
      updateSubscription={updateSubscription}
      updateSubscriptionLoading={updateSubscriptionLoading}
      updateUtmParameters={updateUtmParameters}
      updateUtmParametersLoading={updateUtmParametersLoading}
      user={data?.user}
      onOpenPaymentModal={handleOpenPaymentModal}
      onClosePaymentModal={handleClosePaymentModal}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UserSettingsWrapper);
