/* @flow */
import React from "react";

import TooltippedIcon from "icons/TooltippedIcon";
import type { getPosts_posts_data as Post } from "graphql-types/getPosts";

import styles from "./index.css";

type TextProps = {
  post: Post
};

type ContentProps = {
  className?: string,
  post: Post
};

const Text = ({ post }: TextProps) => {
  if (!post.variation) {
    return <i>This variation has been deleted</i>;
  }
  const text =
    post.account.provider.toUpperCase() === "PINTEREST"
      ? post.variation.text
      : post.variation.displayText;
  return <span title={text}>{text}</span>;
};

const Content = ({ className = "", post }: ContentProps) => {
  const numImages = post.variation.images.length;
  const numVideos = post.variation.videos.length;
  return (
    <div className={`${styles.content} ${className}`}>
      <div className={styles.contentText}>
        {numImages > 0 && (
          <>
            <TooltippedIcon
              iconColor="textDark"
              iconType="file-image-o"
              tipBody="Image(s) Attached"
            />
            {numImages > 1 && (
              <>
                {" "}
                <i>{numImages}</i>
              </>
            )}
          </>
        )}{" "}
        {numVideos > 0 && (
          <>
            <TooltippedIcon
              iconColor="textDark"
              iconType="file-video-o"
              tipBody="Video Attached"
            />
            {numVideos > 1 && (
              <>
                {" "}
                <i>{numVideos}</i>
              </>
            )}
          </>
        )}{" "}
        <Text post={post} />
      </div>
      {post.content.variations.length > 1 && (
        <div>
          <i className="fa fa-clone" /> 1 of {post.content.variations.length}{" "}
          variations
        </div>
      )}
      <div>
        <a
          className={styles.contentPerformance}
          href={`/content-performance/${post.content.id}`}
        >
          View performance history
        </a>
      </div>
    </div>
  );
};

export default Content;
