/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation dismissInformationBox($informationBoxName: String!) {
    dismissInformationBox(informationBoxName: $informationBoxName) {
      errors
    }
  }
`;
