/* @flow */
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import qs from "qs";
import classnames from "classnames";
import withFilter, { parseFilterParams } from "behavioral/withFilter";
import Button from "buttons/Button";
import styles from "./index.css";

import APPROVE_ALL_CONTENT from "mutations/approveAllContent";
import REJECT_ALL_CONTENT from "mutations/rejectAllContent";
import DELETE_ALL_CONTENT from "mutations/deleteAllContent";

import type { ContentFilter } from "types";
import type { Match, Location } from "react-router-dom";

type OwnProps = {
  hasNoContent: boolean,
  loading: boolean,
  className?: string,
  match: Match,
  location: Location
};

type Props = {
  filter: ContentFilter,
  loadingApproveAll: boolean,
  loadingRejectAll: boolean,
  loadingDeleteAll: boolean,
  onApproveAll: () => void,
  onRejectAll: () => void,
  onDeleteAll: () => void
} & OwnProps;

function bulkEditUrl(filter) {
  const params = { filter: { ...filter } };
  delete params.filter.status;
  return `/contents/edit${qs.stringify(params, {
    addQueryPrefix: true
  })}`;
}

export const BulkActions = ({
  className,
  loading,
  filter,
  hasNoContent,
  loadingApproveAll,
  loadingRejectAll,
  loadingDeleteAll,
  onApproveAll,
  onRejectAll,
  onDeleteAll
}: Props) => (
  <div className={classnames(styles.root, className)}>
    {filter.status == "PENDING" && (
      <>
        <Button
          type="secondary-negative"
          disabled={hasNoContent || loading}
          loading={loadingRejectAll}
          onClick={onRejectAll}
        >
          Reject all
        </Button>
        <Button
          type={"primary"}
          disabled={hasNoContent || loading}
          loading={loadingApproveAll}
          onClick={onApproveAll}
        >
          Approve all
        </Button>
      </>
    )}
    {filter.status == "APPROVED" && (
      <Button type="primary" href={bulkEditUrl(filter)} disabled={loading}>
        Bulk edit
      </Button>
    )}
    {filter.status == "REJECTED" && (
      <>
        <Button
          type={"primary"}
          disabled={hasNoContent || loading}
          loading={loadingApproveAll}
          onClick={onApproveAll}
        >
          Approve all
        </Button>
        <Button
          type="primary-negative"
          disabled={hasNoContent || loading}
          loading={loadingDeleteAll}
          confirm={true}
          confirmType="warning"
          confirmMessage="Are you sure you want to delete all rejected content?"
          onClick={onDeleteAll}
        >
          Delete all
        </Button>
      </>
    )}
  </div>
);

export default withFilter()((props: OwnProps) => {
  const { filter } = parseFilterParams(props.match, props.location);

  const mutationConfig = {
    variables: { filter },
    refetchQueries: _ => ["getContents"]
  };

  const [
    approveAllContent,
    { loading: loadingApproveAllContent }
  ] = useMutation(APPROVE_ALL_CONTENT, mutationConfig);
  const [rejectAllContent, { loading: loadingRejectAllContent }] = useMutation(
    REJECT_ALL_CONTENT,
    mutationConfig
  );
  const [deleteAllContent, { loading: loadingDeleteAllContent }] = useMutation(
    DELETE_ALL_CONTENT,
    mutationConfig
  );

  return (
    <BulkActions
      {...props}
      filter={filter}
      loadingApproveAll={loadingApproveAllContent}
      loadingRejectAll={loadingRejectAllContent}
      loadingDeleteAll={loadingDeleteAllContent}
      onApproveAll={approveAllContent}
      onRejectAll={rejectAllContent}
      onDeleteAll={deleteAllContent}
    />
  );
});
