// @flow
import React from "react";
import LoadingIcon from "icons/LoadingIcon";
import ByLine from "./ByLine";
import Text from "./Text";
import Media from "./Media";
import Card from "./Card";
import styles from "./index.css";

import type { Account } from "types";

import type { loadContent_content_variations as VariationData } from "graphql-types/loadContent";
import type { scrapeUrl_scrapeUrl_pageScrape as PageScrapeData } from "graphql-types/scrapeUrl";

type Props = {
  account: Account,
  mentions: string[],
  pageScrape: ?PageScrapeData,
  variation: VariationData
};

export const TwitterPost = ({
  account,
  mentions,
  pageScrape,
  variation
}: Props) => {
  let attachment;
  let omitTrailingLinks = false;

  if (variation.videos.length > 0 || variation.images.length > 0) {
    attachment = <Media images={variation.images} videos={variation.videos} />;
  } else if (
    pageScrape &&
    (pageScrape.status === "ok" || pageScrape.status === "unavailable")
  ) {
    attachment = <Card pageScrape={pageScrape} />;
    omitTrailingLinks = pageScrape.status === "ok";
  } else if (pageScrape && pageScrape.status === "loading") {
    return (
      <div className={styles.loading}>
        <LoadingIcon /> Checking for Twitter Card…
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <ByLine value={account} />
      <Text
        value={variation.text}
        omitTrailingLinks={omitTrailingLinks}
        mentions={mentions}
        rawRichTextEntityMap={variation.rawRichTextEntityMap}
      />
      {attachment}
    </div>
  );
};

export default TwitterPost;
