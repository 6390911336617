// @flow
import { uniq } from "lodash";
import getMentionTagAccountProvider from "./mentions/getMentionTagAccountProvider";
import type { Provider } from "graphql-types/loadComposer";

export default (
  rawRichTextEntityMap: Object,
  selectedProviders: Provider[]
): string[] => {
  if (
    rawRichTextEntityMap &&
    !Object.keys(rawRichTextEntityMap.entityMap).length
  ) {
    return [];
  }

  const mentionTagAccountProvider =
    getMentionTagAccountProvider(rawRichTextEntityMap) || [];
  const providers = selectedProviders.concat(mentionTagAccountProvider);
  const rejections = [];

  if (providers.includes("FACEBOOK_GROUP")) {
    rejections.push(`
      Facebook groups do not support tagging. Facebook only allows tagging
      from a Facebook page.
    `);
  }

  if (
    providers.includes("LINKEDIN") ||
    providers.includes("LINKEDIN_COMPANY")
  ) {
    rejections.push("LinkedIn does not support tagging a user.");
  }

  if (!rejections.length) {
    if (uniq(providers).length > 1) {
      rejections.push(`
        Mentions and tags are network specific and can only be sent to that network.
        To send content to multiple networks, please remove the mention tag.
      `);
    } else if (!selectedProviders.length) {
      rejections.push(
        "Please remove any existing mention tags before deselecting a network."
      );
    }
  }

  return rejections;
};
