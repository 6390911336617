/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation approveContent($id: ID!) {
    approveContent(id: $id) {
      content {
        id
      }
      errors
    }
  }
`;
