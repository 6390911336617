/* @flow */
import styled from "styled-components";

const widthBreakpoint = 500;

export const MediaButtons = styled.div`
  margin: 20px 0 0 0px;
  /* position: absolute; */

  @media (min-width: ${widthBreakpoint}px) {
    align-items: end;
    justify-content: space-between;
    display: flex;
  }
`;

export const PostsLimitReached = styled.div`
    font-family: Arial, sans-serif;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width:100%;
    margin-top:20px;
    padding: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: red;
`