/* @flow */
import React from "react";
import Icon from "icons/Icon";
import { colorFromIndex } from "colors";
import styles from "./index.css";

import type { Category } from "graphql-types/category";

const CategoryName = ({ category }: { category: Category }) => {
  const style = { color: colorFromIndex(category.colorIndex) };
  return (
    <span className={styles.categoryName} style={style}>
      <Icon type="folder" />
      &nbsp;{category.name}
    </span>
  );
};

export default CategoryName;
