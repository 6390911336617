// @flow
import React from "react";

import ExpiresAtField from "./ExpiresAtField";
import UseOnceField from "./UseOnceField";
import LinkShorteningOption from "./LinkShorteningOption";

import type { Props } from "./types.js";
import { Wrapper, Field } from "./styles";

const AdvancedSettings = ({
  useOnce,
  expiresAt,
  useShortLinks,
  linkShortenerDomainName,
  timeZone,
  isEditing,
  onChangeUseOnce,
  onChangeUseShortLinks,
  onChangeExpiresAt
}: Props) => (
  <Wrapper>
    {timeZone && (
      <>
        <Field>
          <LinkShorteningOption
            useShortLinks={useShortLinks}
            domainName={linkShortenerDomainName}
            onChangeUseShortLinks={onChangeUseShortLinks}
          />
        </Field>

        <Field>
          <UseOnceField value={useOnce} onChange={onChangeUseOnce} />
        </Field>

        <Field>
          <ExpiresAtField
            value={expiresAt}
            timeZone={timeZone}
            isEditing={isEditing}
            onChange={onChangeExpiresAt}
          />
        </Field>
      </>
    )}
  </Wrapper>
);

export default AdvancedSettings;
