/* @flow */
import React, { type Node } from "react";
import classNames from "classnames";

import styles from "./index.css";

type Props = {
  buttonClassName?: string,
  children?: Node,
  className?: string,
  onClick?: (value: string) => void,
  selected?: boolean,
  value: string
};

const RadioButton2 = ({
  buttonClassName,
  children,
  className,
  onClick,
  selected,
  value
}: Props) => {
  return (
    <div
      aria-checked={selected}
      className={classNames(styles.radioButtonWrapper, {
        [className || ""]: Boolean(className)
      })}
      onClick={(): void => {
        onClick?.(value);
      }}
    >
      <span
        className={classNames(styles.radioButton, {
          [buttonClassName || ""]: Boolean(buttonClassName),
          [styles.radioButtonSelected]: selected
        })}
      />
      {children && <label className={styles.radioLabel}>{children}</label>}
      <input type="radio" value={value} selected={selected} />
    </div>
  );
};

export default RadioButton2;
