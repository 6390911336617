/* @flow */
import React, { type Node } from "react";
import classNames from "classnames";
import { uniq } from "lodash";
import LinkPreview from "links/LinkPreview";
import SocialMediaIcon from "icons/SocialMediaIcon";
import LinkPreviewHint from "../../common/LinkPreviewHint";
import Thumbnail from "components/Thumbnail";
import { Body } from "components/common/typography";
import Accounts from "../../common/Accounts";
import MoreVariations from "./MoreVariations";
import AdvancedSettingsDescription from "../../common/AdvancedSettingsDescription";
import styles from "./index.css";

import type {
  getContents_contents_data as ContentData,
  getContents_contents_data_variations as ContentDataVariation
} from "graphql-types/getContents";

export type Props = {
  content: ?ContentData,
  timezone: ?string,
  children?: Node
};

const Layout = ({ content, timezone, children }: Props) => {
  const id = content?.id;
  const queueable = content?.queueable;
  const accounts = (content?.accountRelationships ?? []).map(
    ({ account }) => account
  );
  const sendAt = content?.sendAt;
  const expiresAt = content?.expiresAt;
  const useOnce = content?.useOnce;
  const variations = content?.variations;

  const rootClasses = classNames(styles.root, {
    [styles.unqueueable]: !queueable
  });

  const platforms = uniq(accounts.map(a => a.platform));

  let firstVariation: ContentDataVariation = {};
  if (variations && variations.length > 0) {
    firstVariation = variations[0];
  }

  const pinterestAccountIsPresent = !!platforms.filter(p => {
    return p === "PINTEREST";
  }).length;

  const facebookAccountIsPresent = !!platforms.filter(p => {
    return p === "FACEBOOK";
  }).length;

  const linkedInAccountIsPresent = !!platforms.filter(p => {
    return p === "LINKEDIN";
  }).length;

  return (
    <div className={rootClasses}>
      {firstVariation ? (
        <div className={styles.primary}>
          <div className={styles.thumbnailAndTextContainer}>
            <Thumbnail
              contentId={id}
              images={firstVariation.images}
              videos={firstVariation.videos}
              video={
                firstVariation.videos && firstVariation.videos.length > 0
                  ? firstVariation.videos[0]
                  : null
              }
              queueable={queueable}
            />

            <div className={styles.text}>{children}</div>
          </div>

          <div className={styles.moreVariationsContainer}>
            {content ? (
              <MoreVariations content={content} timezone={timezone} />
            ) : null}
          </div>
        </div>
      ) : null}

      {!!accounts.length &&
      (firstVariation?.pinterestDestinationLink ||
        firstVariation?.pinterestTitle) &&
      pinterestAccountIsPresent ? (
        <div className={styles.pinterestDetailContainer}>
          <div className={styles.destinationLink}>
            <SocialMediaIcon
              className={styles.pinterestIcon}
              value="pinterest"
              tiny
            />
          </div>
          <div className={styles.pinterestTitleAndLink}>
            {firstVariation?.pinterestTitle && (
              <Body>Pin title: {firstVariation.pinterestTitle}</Body>
            )}
            {firstVariation.pinterestDestinationLink && (
              <Body>
                Destination link:{" "}
                <a
                  href={firstVariation.pinterestDestinationLink}
                  target="_blank"
                >
                  {firstVariation.pinterestDestinationLink}
                </a>
              </Body>
            )}
          </div>
        </div>
      ) : null}

      {/* LinkPreview is currently only supported by LI and FB */}
      {!!accounts.length &&
      (facebookAccountIsPresent || linkedInAccountIsPresent) &&
      firstVariation?.pageScrape?.url ? (
        <div className={styles.linkPreviewContainer}>
          <LinkPreview
            pageScrape={firstVariation.pageScrape}
            platforms={platforms}
          />
        </div>
      ) : null}

      {/* LinkPreviewHint appears to be specific only to Facebook; only show if an fb
            account is present */}
      {!!accounts.length &&
      facebookAccountIsPresent &&
      firstVariation.pageScrape &&
      !firstVariation.pageScrape.url ? (
        <LinkPreviewHint link={firstVariation.link || ""} />
      ) : null}

      <div className={styles.accountsContainer}>
        <Accounts content={content} />
      </div>

      <AdvancedSettingsDescription
        timezone={timezone}
        sendAt={sendAt}
        expiresAt={expiresAt}
        useOnce={useOnce}
      />
    </div>
  );
};

export default Layout;
