// @flow
import React from "react";
import HelpText from "./HelpText";
import FacebookPost from "./FacebookPost";
import TwitterPost from "./TwitterPost";
import LinkedinPost from "./LinkedinPost";
import InstagramPost from "./InstagramPost";
import PinterestPost from "./PinterestPost";
import GooglePost from "./GooglePost";
import TikTokPost from "./TikTokPost";
import ThreadsPost from "./ThreadsPost";
import MultiImageHelpText from "./MultiImageHelpText";
import styles from "./index.css";

import type {
  loadContent_content as ContentData,
  loadContent_content_variations as VariationData
} from "graphql-types/loadContent";
import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";
import type { scrapeUrl_scrapeUrl_pageScrape as PageScrapeData } from "graphql-types/scrapeUrl";

type Props = {
  content: ContentData,
  variation: VariationData,
  account: AccountData,
  pageScrape: PageScrapeData,
  selectedPinterestBoardId?: ?string
};

const renderPlatformPost = props => {
  const {
    account: { platform }
  } = props;
  switch (platform) {
    case "FACEBOOK":
      return <FacebookPost {...props} />;
    case "TWITTER":
      return <TwitterPost {...props} />;
    case "LINKEDIN":
      return <LinkedinPost {...props} />;
    case "INSTAGRAM":
      return <InstagramPost {...props} />;
    case "PINTEREST":
      return <PinterestPost {...props} />;
    case "GOOGLE":
      return <GooglePost {...props} />;
    case "TIKTOK":
      return <TikTokPost {...props} />;
    case "THREADS":
      return <ThreadsPost {...props} />;
    default:
      throw Error("Unknown account platform");
  }
};

const showFacebookDebuggerLink = (props): boolean => {
  const {
    account: { platform },
    variation: { link }
  } = props;
  return platform === "FACEBOOK" && !!link;
};

export const Post = (props: Props) => (
  <div>
    <HelpText
      useShortLinks={
        props.account.platform !== "PINTEREST" && props.content.useShortLinks
      }
      showFacebookDebuggerLink={showFacebookDebuggerLink(props)}
      link={props.variation.link}
    />
    <div
      className={`${styles.postWrap} ${
        styles[props.account.platform.toLowerCase()]
      }`}
    >
      {renderPlatformPost(props)}
    </div>
    {props.variation.images.length > 1 && <MultiImageHelpText />}
  </div>
);

export default Post;
