// @flow
import React, { Component } from "react";

import H3 from "typography/H3";
import Icon from "icons/Icon";
import Expander from "layout/Expander";
import ExpiresAtField from "./ExpiresAtField";
import UseOnceField from "./UseOnceField";
import LinkShorteningOption from "./LinkShorteningOption";
import styles from "./index.css";

type Props = {
  expiresAt: ?string,
  useOnce: boolean,
  useShortLinks: boolean,
  linkShortenerDomainName: ?string,
  timeZone: string,
  isEditing: boolean,
  openAtMount: boolean,
  onChangeExpiresAt: (expiresAt: ?string) => void,
  onChangeUseOnce: (val: boolean) => void,
  onChangeUseShortLinks: (val: boolean) => void
};

type State = { open: boolean };

export class AdvancedSettings extends Component<Props, State> {
  state = { open: false };

  componentWillMount = () => {
    this.setState({ open: this.props.openAtMount });
  };

  handleHeaderClick = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;
    const {
      useOnce,
      expiresAt,
      useShortLinks,
      linkShortenerDomainName,
      timeZone,
      isEditing,
      onChangeUseOnce,
      onChangeUseShortLinks,
      onChangeExpiresAt
    } = this.props;

    return (
      <div className={styles.root}>
        <H3 className={styles.header} onClick={this.handleHeaderClick}>
          Advanced settings
          <Icon
            type={open ? "chevron-up" : "chevron-down"}
            className={styles.arrowIcon}
          />
        </H3>
        <Expander expanded={open} className={styles.settings}>
          {timeZone && (
            <>
              <div className={styles.field}>
                <LinkShorteningOption
                  useShortLinks={useShortLinks}
                  domainName={linkShortenerDomainName}
                  onChangeUseShortLinks={onChangeUseShortLinks}
                />
              </div>

              <div className={styles.field}>
                <UseOnceField value={useOnce} onChange={onChangeUseOnce} />
              </div>

              <div className={styles.field}>
                <ExpiresAtField
                  value={expiresAt}
                  timeZone={timeZone}
                  isEditing={isEditing}
                  onChange={onChangeExpiresAt}
                />
              </div>
            </>
          )}
        </Expander>
      </div>
    );
  }
}

export default AdvancedSettings;
