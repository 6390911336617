/* @flow */
import React from "react";

import HtmlizedText from "components/common/HtmlizedText";

import ByLine from "./ByLine";

import type { Props } from "./types.js";
import { Wrapper, Image, Content } from "./styles";

const GooglePost = ({ variation, account, mentions, pageScrape }: Props) => {
  const image = variation.images?.length > 0 ? variation.images[0].url : null;

  return (
    <Wrapper>
      <ByLine value={account} hasLink={!!pageScrape?.url} />
      {image && (
        <Image>
          <img src={image} alt="preview image" />
        </Image>
      )}
      {variation.text && (
        <Content>
          <HtmlizedText
            value={variation.text}
            otherTextRequiredForLinkOmission
            mentions={mentions}
            provider={account.provider}
          />
        </Content>
      )}
    </Wrapper>
  );
};

export default GooglePost;
