// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  children: Node,
  className?: string
};

const H3 = ({ children, className, ...others }: Props) => (
  <h3
    className={classNames(styles.root, { [className || ""]: !!className })}
    {...others}
  >
    {children}
  </h3>
);

export default H3;
