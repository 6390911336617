/* @flow */
import React from "react";
import moment from "moment";

import LoadingSVG from "components/common/images/loading-pulse.svg";
import IGDefaultImg from "components/common/images/default-profile-pic.png";
import useComment from "./use";
import type { IProps } from "./types.js";
import {
  Wrapper,
  Content,
  Picture,
  Body,
  Reply,
  Top,
  Name,
  CreatedAt,
  Text,
  Attachment,
  WriteReply,
  WriteReplyContainer,
  ReplyInput,
  RepliesOrder,
  Error
} from "./styles";

import { Send } from "../../Footer/styles";
import Actions from "./Actions";

const InboxPostComment = (props: IProps) => {
  const {
    showWriteReply,
    toggleWriteReply,
    newReply,
    createReply,
    creatingReply,
    handleReplyChange,
    newReplies,
    repliesOrder,
    toggleRepliesOrder,
    handleKeyPress,
    error
  } = useComment(props);

  const { item } = props;

  const allReplies = [
    ...(item.replies && item.replies.length ? item.replies : []),
    ...newReplies
  ];

  const limitReply = newReply.length > 8000;

  return (
    <Wrapper>
      <Content>
        <Picture src={IGDefaultImg} />
        <Body>
          <Top>
            <Name>{item.from.username}</Name>
            <CreatedAt>{moment(item.timestamp).fromNow()}</CreatedAt>
          </Top>
          {!!item.attachment &&
          (item.attachment.attachmentUrl || item.attachment.thumbnail) ? (
            <Attachment>
              <img
                src={item.attachment.attachmentUrl || item.attachment.thumbnail}
                alt="comment attachment"
              />
            </Attachment>
          ) : null}
          <Text>{item.text}</Text>
          <Actions
            showWriteReply={showWriteReply}
            comment={item}
            createReply={createReply}
            toggleWriteReply={toggleWriteReply}
          />
        </Body>
      </Content>

      {showWriteReply && (
        <WriteReply>
          <WriteReplyContainer isLoading={creatingReply}>
            {creatingReply && (
              <img src={LoadingSVG} alt="loading" className="loading-pulse" />
            )}
            <ReplyInput>
              <input
                placeholder="Write your reply..."
                disabled={creatingReply}
                value={newReply}
                onChange={handleReplyChange}
                onKeyPress={handleKeyPress}
              />
            </ReplyInput>
            {!limitReply && (
              <Send onClick={createReply} active={!!newReply.trim().length}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <g data-name="1">
                    <path d="M291.48 449.94A15 15 0 01278 441.5l-70.5-144.93-144.93-70.49a15 15 0 011-27.41L435.48 49.08A15 15 0 01455 68.6L305.4 440.54a15 15 0 01-13.4 9.39zm-185.38-236l119.18 58a15 15 0 016.93 6.93l58 119.18L414 90z" />
                    <path d="M218.72 300.35a15 15 0 01-10.6-25.61L430.47 52.39a15 15 0 1121.21 21.22L229.33 296a15 15 0 01-10.61 4.35z" />
                  </g>
                </svg>
              </Send>
            )}
          </WriteReplyContainer>
          {error ? (
            <Error>{error}</Error>
          ) : limitReply ? (
            <Error>Replies cannot be longer than 8,000 characters.</Error>
          ) : null}
        </WriteReply>
      )}

      {allReplies.length > 0 && (
        <>
          <RepliesOrder>
            <span title="Click to change" onClick={toggleRepliesOrder}>
              {repliesOrder === "ASC"
                ? "Oldest to newest "
                : "Newest to oldest "}
              <i className="fa fa-sort-alt" />
            </span>
          </RepliesOrder>

          {(repliesOrder === "ASC" ? allReplies : allReplies.reverse()).map(
            reply => (
              <Reply key={reply.id}>
                <Top>
                  <Name>{reply.from.username}</Name>
                  <CreatedAt>{moment(reply.timestamp).fromNow()}</CreatedAt>
                </Top>
                {!!reply.attachment &&
                (reply.attachment.attachmentUrl ||
                  reply.attachment.thumbnail) ? (
                  <Attachment>
                    <img
                      src={
                        reply.attachment.attachmentUrl ||
                        reply.attachment.thumbnail
                      }
                      alt="reply attachment"
                    />
                  </Attachment>
                ) : null}
                <Text>{reply.text}</Text>
              </Reply>
            )
          )}
        </>
      )}
    </Wrapper>
  );
};

export default InboxPostComment;
