/* @flow */
import groupBy from "lodash/groupBy";
import reduce from "lodash/reduce";
import moment from "moment";
import type {
  getSchedule_schedules as ScheduleData,
  getSchedule_scheduleSuggestions as ScheduleSuggestionData
} from "graphql-types/getSchedule";

import type {
  getScheduleV2_schedulesV2 as ScheduleDataV2,
  getScheduleV2_scheduleSuggestions as ScheduleSuggestionDataV2
} from "graphql-types/getScheduleV2";

export type CalendarHour = number;
export type CalendarMinute = number;
export type CalendarDay = string;
export type CalendarItem =
  | ScheduleData
  | ScheduleDataV2
  | ScheduleSuggestionData
  | ScheduleSuggestionDataV2;

function formatSchedule(schedule) {
  return {
    ...schedule,
    sendAt: moment.utc(schedule.sendAt).format("h:mm a")
  };
}

function groupSchedulesByMinute(schedules) {
  return groupBy(schedules, s => +moment.utc(s.sendAt).format("m"));
}

function groupSchedulesByHour(schedules) {
  return groupBy(schedules, s => +moment.utc(s.sendAt).format("H"));
}

function groupSchedulesByDay(schedules) {
  return groupBy(schedules, s => s.sendOn);
}

export type DesktopCalendarData = {
  [CalendarHour]: {
    [CalendarMinute]: {
      [CalendarDay]: CalendarItem[]
    }
  }
};
export function buildDesktopCalendarData(
  schedules: CalendarItem[]
): DesktopCalendarData {
  return reduce(
    // Hour
    groupSchedulesByHour(schedules),
    (result, schedulesInHour, hour) => ({
      ...result,
      [hour]: reduce(
        //Hour -> Minute
        groupSchedulesByMinute(schedulesInHour),
        (resultForHour, schedulesInMinute, minute) => ({
          ...resultForHour,
          [minute]: reduce(
            // Hour -> Minute -> Day
            groupSchedulesByDay(schedulesInMinute),
            (resultForDay, schedulesInDay, day) => ({
              ...resultForDay,
              [day]: schedulesInDay.map(formatSchedule)
            }),
            {}
          )
        }),
        {}
      )
    }),
    {}
  );
}

export type MobileCalendarData = {
  [CalendarDay]: {
    [CalendarHour]: {
      [CalendarMinute]: CalendarItem[]
    }
  }
};
export function buildMobileCalendarData(
  schedules: CalendarItem[]
): MobileCalendarData {
  return reduce(
    // Day
    groupSchedulesByDay(schedules),
    (result, schedulesInDay, day) => ({
      ...result,
      [day]: reduce(
        // Day -> Hour
        groupSchedulesByHour(schedulesInDay),
        (resultForDay, schedulesInHour, hour) => ({
          ...resultForDay,
          [hour]: reduce(
            // Day -> Hour -> Minute
            groupSchedulesByMinute(schedulesInHour),
            (resultForHour, schedulesInMinute, minute) => ({
              ...resultForHour,
              [minute]: schedulesInMinute.map(formatSchedule)
            }),
            {}
          )
        }),
        {}
      )
    }),
    {}
  );
}
