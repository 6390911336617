/* @flow */
import React from "react";
import Avatars from "./Avatars";
import Caption from "typography/Caption";
import Icon from "icons/Icon";
import styles from "./index.css";

import type { getContents_contents_data as ContentData } from "graphql-types/getContents";

type Props = {
  className?: string,
  content: ?ContentData,
  exhaustedDisplay?: "Alert" | "Inline",
  shouldDisplayNoAccountWarning?: boolean
};

const Accounts = ({
  className = "",
  content,
  exhaustedDisplay = "Alert",
  shouldDisplayNoAccountWarning = true
}: Props) => {
  if (!content) {
    return <Avatars exhaustedDisplay={exhaustedDisplay} />;
  }

  if (content.accountRelationships.length > 0) {
    return (
      <Avatars
        className={className}
        content={content}
        exhaustedDisplay={exhaustedDisplay}
      />
    );
  }

  if (!shouldDisplayNoAccountWarning) {
    return null;
  }

  return (
    <Caption>
      <Icon type="exclamation-triangle" className={styles.icon} />
      No Account
    </Caption>
  );
};

export default Accounts;
