/* @flow */
import React from "react";
import sortBy from "lodash/sortBy";
import { LoadingIcon } from "icons";
import { Caption } from "typography";
import Select, { type OptionData } from "forms/Select";
import styles from "./index.css";

import type { loadComposer_linkedinAd_accounts as LinkedinAdAccountData } from "graphql-types/loadComposer";

type Props = {
  adAccounts: LinkedinAdAccountData[],
  selectedLiadAccountId: ?string,
  isLoading: boolean,
  error: ?Error,
  onSelect: (liAdAccountId: string) => void
};

export const LiAdAccountSelect = ({
  adAccounts,
  selectedLiadAccountId,
  isLoading,
  error,
  onSelect
}: Props) => {
  if (isLoading) {
    return <LoadingIcon />;
  }

  if (error) {
    return <Caption error>{error.message}</Caption>;
  }

  if (adAccounts.length === 0) {
    return (
      <Caption error>This linkedin account doesn't have any ad accounts.</Caption>
    );
  }

  const handleSelect = ({ value: adAccountId }: OptionData) => {
    onSelect(adAccountId);
  };
  const options = sortBy(adAccounts, ({ name }) => name.toLowerCase()).map(
    ({ id, name }) => ({
      value: id,
      label: name
    })
  );
  const selectedOption = options.find(o => o.value === selectedLiadAccountId) ?? null;

  return (
    <>
      <Select
        className={styles.select}
        placeholder="Select Ad Account…"
        value={selectedOption}
        options={options}
        hasError={!selectedLiadAccountId}
        onChange={handleSelect}
      />
      {!selectedLiadAccountId && (
        <Caption error textAlign="right">
          Required
        </Caption>
      )}
    </>
  );
};

export default LiAdAccountSelect;
