import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0 0 10px;
  border-bottom: 1px solid #fafafa;
  padding: 0 0 10px;

  &:last-child {
    border-bottom: none;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Picture = styled.img`
  border-radius: 50%;
  height: 40px;
  width: 40px;
`;

export const Body = styled.div`
  background-color: #f2f3f4;
  border-radius: 8px;
  flex: 1;
  margin: 0 0 0 8px;
  padding: 12px 12px 8px;
`;

export const Reply = styled.div`
  background-color: #f9fafb;
  border-radius: 8px;
  flex: 1;
  margin: 10px 0 0 auto;
  padding: 12px;
  max-width: 80%;
  width: 420px;
`;

export const Top = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Name = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
`;

export const CreatedAt = styled.div`
  color: #8e8e8e;
  display: none;
  font-weight: 300;
  font-size: 12px;
  text-align: right;

  @media (min-width: 600px) {
    display: block;
  }
`;

export const Text = styled.div``;

export const Attachment = styled.div`
  margin: 10px 0 5px;

  img {
    max-width: 50%;
    height: auto;
  }
`;

export const WriteReply = styled.div``;

export const WriteReplyContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 10px -4px 0 auto;

  @media (min-width: 860px) {
    max-width: 80%;
  }

  .loading-pulse {
    margin: 0 10px 0 0;
    width: 20px;
  }

  ${({ isLoading }) => isLoading && "opacity: 0.7;"}
`;

export const Error = styled.div`
  color: #f44336;
  flex: 1;
  font-size: 12px;
  font-style: italic;
  margin: 0 0 0 auto;
  padding: 8px 0 0 8px;
  width: 100%;

  @media (min-width: 768px) {
    height: 18px;
    margin: -5px 0 0 auto;
  }

  @media (min-width: 860px) {
    margin: -5px 0 0 auto;
    max-width: 80%;
  }
`;

export const ReplyInput = styled.div`
  flex: 2;
  margin: 0 -3px 0 0;

  input {
    background-color: #f9fafb;
    border: 1px solid #efefef;
    border-radius: 30px;
    height: 38px;
    padding: 10px 20px;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: 0 0 12px rgba(77, 148, 143, 0.15);
      border-color: #ececec;
    }
  }
`;

export const RepliesOrder = styled.div`
  cursor: pointer;
  font-size: 12px;
  opacity: 0.6;
  padding: 15px 0 0;
  text-align: right;
`;
