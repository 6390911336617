/*
@flow

Usage -
+ Styles currently assume this will be the first child of a material component.

*/
import React, { type Element, type ChildrenArray } from "react";
import Tab from "./Tab";
import styles from "./index.css";

export { Tab };

type Props = {
  children: ChildrenArray<Element<typeof Tab>>,
  selectedIndex: number,
  onSelect: (activeIndex: number) => void
};

const getTabChildren = <T: Element<typeof Tab>>(children: any): Array<T> =>
  React.Children.toArray(children);

export default class Tabs extends React.Component<Props> {
  handleSelect = (selectedIndex: number) => {
    const { children, onSelect } = this.props;
    const tabs = getTabChildren(children);
    const { onSelect: childOnSelect } = tabs[selectedIndex].props;

    onSelect(selectedIndex);

    if (childOnSelect) {
      childOnSelect(selectedIndex);
    }
  };

  render() {
    const {
      props: { children, selectedIndex }
    } = this;
    const tabs = getTabChildren(children);
    const selectedChildren = tabs[selectedIndex].props.children;

    return (
      <div className={styles.root}>
        <div className={styles.tabs}>
          {React.Children.map(children, (child, i) =>
            React.cloneElement(child, {
              selected: i === selectedIndex,
              key: i,
              index: i,
              children: null,
              onSelect: this.handleSelect
            })
          )}
        </div>
        {selectedChildren}
      </div>
    );
  }
}
