// @flow
// TODO refactor uniqueId to be generated in action rather than by reducer
// When I change the way it is imported to "import { uniq } from 'lodash'" it causes the generated
// id to be unpredictable
import get from "lodash/get";
import uniqueId from "lodash/uniqueId";
import { ensureEnum } from "util";
import * as actions from "actions/flash";
import { ALERT_TYPE_MAP, type AlertType } from "components/common/layout/Alert";

import type { Action } from "types";

const FLASH_TYPE_MAP = {
  ...ALERT_TYPE_MAP,
  noticePersistent: "",
  errorPersistent: "",
  alertPersistent: ""
};

export type FlashType = $Keys<typeof FLASH_TYPE_MAP>;
const FLASH_TYPES: Array<FlashType> = Object.keys(FLASH_TYPE_MAP);

export type Flash = {
  id: string,
  body: string,
  type: AlertType,
  persistent: boolean,
  receivedAt: number,
  dismissedAt: ?number
};

export type State = {
  flashes: { [key: string]: Flash }
};

const buildFlash = (type: FlashType, body: string) => ({
  id: uniqueId("flash-"),
  body,
  type: String(type).replace("Persistent", ""),
  persistent: /Persistent/.test(type),
  receivedAt: Date.now(),
  dismissedAt: null
});

export const initialState: State = (() => {
  const raw = get(window, "INITIAL_STORE_STATE.flash", {});
  const flashes = Object.keys(raw).map(key => {
    const flashType: FlashType = ensureEnum(key, FLASH_TYPES);
    return buildFlash(flashType, raw[key]);
  });
  const mappedFlashes = flashes.reduce((map, curr) => {
    const nextMap = { ...map };
    nextMap[curr.id] = curr;
    return nextMap;
  }, {});
  return { flashes: mappedFlashes };
})();

const flashReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case actions.DISMISS_FLASH: {
      const { id } = action.payload;
      return {
        ...state,
        flashes: {
          ...state.flashes,
          [id]: { ...state.flashes[id], dismissedAt: Date.now() }
        }
      };
    }

    case actions.CREATE_FLASH: {
      const { type, body } = action.payload;
      const flash = buildFlash(type, body);
      return {
        ...state,
        flashes: {
          ...state.flashes,
          [flash.id]: flash
        }
      };
    }

    default:
      return state;
  }
};

export default flashReducer;
