/* @flow */
import React from "react";
import classNames from "classnames";
import Label from "typography/Label";
import Button from "buttons/Button";

import styles from "./index.css";

type Props = {
  couponCode: string,
  onClickShowCouponModal: () => void
};

export default ({ couponCode, onClickShowCouponModal }: Props) => (
  <>
    <Label className={styles.subheader}>Discount</Label>
    <div
      className={classNames(
        styles.pushRight,
        couponCode ? null : styles.defaultCouponText
      )}
    >
      {couponCode || "No discount applied"}
    </div>
    <Button
      className={`${styles.button} ${styles.pushRight}`}
      onClick={onClickShowCouponModal}
      type="light"
    >
      Enter coupon code
    </Button>
  </>
);
