/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
`;

export const Icon = styled.div`
  margin-bottom: 18px;
  text-align: center;
`;

export const Title = styled.div`
  color: #2c2c2c;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const Text = styled.div`
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 400;
`;

export const Buttons = styled.div`
  margin-top: 35px;

  .two-buttons {
    margin-right: 8px;
  }
`;
