import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(197, 197, 197, 1);
  display: flex;
  height: 40px;
  justify-content: space-around;
  min-width: 290px;
  padding: 0 10px;
  width: 100%;

  .calendarIcon {
    margin-right: 5px;
  }

  .dropdownIcon {
    margin: 0 0 0 2px;
  }

  .arrowIcon {
    margin: 0 6px;
  }
`;

export const StartDate = styled.div`
  align-items: center;
  display: flex;
`;

export const EndDate = styled.div`
  align-items: center;
  display: flex;
`;

export const CustomInput = styled.div`
  cursor: pointer;
`;
