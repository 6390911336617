/* @flow */
/* global EventHandler */
import React from "react";
import Link from "links/Link";
import Icon from "icons/Icon";
import styles from "./index.css";

const HamburgerButton = ({
  open,
  onClick
}: {
  open: boolean,
  onClick: EventHandler
}) => (
  <div className={styles.root}>
    <Link className={open ? styles.open : styles.closed} onClick={onClick}>
      <Icon type="bars" />
    </Link>
  </div>
);

export default HamburgerButton;
