/* @flow */
import React, { type Element } from "react";
import ReactSelect, { components, type SelectProps } from "react-select";
import Caption from "typography/Caption";
import get from "lodash/get";
import Icon from "icons/Icon";
import LoadingIcon from "icons/LoadingIcon";
import styles from "./index.css";

export type Props = { hasError?: boolean, loading?: boolean } & SelectProps;
export type OptionData = {
  label: string,
  value: ?string,
  color?: string,
  caption?: string,
  maxMenuWidth: string,
  icon?: Element<any>
};

const Value = ({ color, icon, caption, label }) => (
  <div id={`select-option`} className={styles.option} title={label}>
    {color && (
      <div>
        <div
          style={{ backgroundColor: color }}
          className={styles.colorSwatch}
        />
      </div>
    )}
    {icon && <div className={styles.icon}>{icon}</div>}
    <div className={styles.label}>
      {label}
      {caption && <Caption helpText>{caption}</Caption>}
    </div>
  </div>
);

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon type="caret-down" />
  </components.DropdownIndicator>
);

const Option = props => (
  <components.Option
    {...props}
    innerProps={{ ...props.innerProps, "data-test": "selectOptionLabel" }}
  >
    <Value
      color={get(props, "data.color")}
      icon={get(props, "data.icon")}
      label={get(props, "data.label")}
      caption={get(props, "data.caption")}
    />
  </components.Option>
);

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <div data-test="select">
      <Value
        color={get(props.getValue(), "[0].color")}
        icon={get(props.getValue(), "[0].icon")}
        label={children}
        caption={null}
      />
    </div>
  </components.ValueContainer>
);

const Menu = props => {
  const {
    children,
    selectProps: { footer }
  } = props;
  return (
    <components.Menu {...props}>
      <div className={styles.menuListContainer}>
        {children}
        {footer ? <div className={styles.menuFooter}>{footer}</div> : null}
      </div>
    </components.Menu>
  );
};

const LoadingMenu = props => {
  return (
    <components.Menu {...props}>
      <div className={styles.loadingMenuListContainer}>
        <div>
          <LoadingIcon className={styles.loadingIcon} />
        </div>
      </div>
    </components.Menu>
  );
};

export default function({
  loading = false,
  hasError,
  components,
  maxMenuWidth = "100%",
  id,
  ...remainingProps
}: Props) {
  return (
    <ReactSelect
      {...remainingProps}
      components={{
        ...components,
        DropdownIndicator,
        Option,
        ValueContainer,
        Menu: loading ? LoadingMenu : Menu
      }}
      id={`select-${id}`}
      inputId={id}
      styles={{
        control: provided => ({
          ...provided,
          backgroundColor: "white",
          minHeight: "48px",
          boxShadow: "0px 2px 10px -3px var(--inky100)",
          border: hasError
            ? "1px solid var(--dangerColor)"
            : "1px solid var(--inky100)"
        }),
        menuPortal: provided => ({
          ...provided,
          zIndex: 5000
        }),
        menu: provided => ({
          ...provided,
          backgroundColor: "white",
          width: "auto",
          maxWidth: maxMenuWidth,
          minWidth: "100%"
        }),
        menuList: provided => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0
        }),
        option: provided => ({
          ...provided,
          display: "flex"
        })
      }}
      theme={theme => ({
        ...theme,
        colors: {
          primary25: "var(--inky100)",
          primary: "var(--inky200)"
        }
      })}
      menuPortalTarget={document.getElementById("app") || document.body}
    />
  );
}
