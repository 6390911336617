/* @flow */
import React from "react";

import LikeSVG from "components/common/images/like.svg";

import type { IProps } from "./types.js";

import { Wrapper, Like, Reply } from "./styles";

const CommentActions = ({
  comment,
  showWriteReply,
  toggleWriteReply
}: IProps) => {
  return (
    <Wrapper>
      <Like title="You can't like comments from within MeetEdgar">
        <span>{comment.likeCount}</span>
        <img src={LikeSVG} alt="like icon" />
      </Like>
      <Reply onClick={toggleWriteReply}>
        <span>{showWriteReply ? "Cancel reply" : "Reply"}</span>
      </Reply>
    </Wrapper>
  );
};

export default CommentActions;
