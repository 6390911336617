/* @flow */
export const ORDERED_STEPS = [
  "addAccount",
  "viewCategories",
  "addContent",
  "addTimeslots"
];

export type OnboardingStep =
  | "addAccount"
  | "viewCategories"
  | "addContent"
  | "addTimeslots"
  | "importContent";

export type OnboardingFlag = null | string;

export const STEP_URLS: { [stepName: string]: Array<string | RegExp> } = {
  addAccount: ["/accounts"],
  viewCategories: ["/categories"],
  addContent: ["/composer", /\/composer\/[0-9]+/, "/contents"],
  addTimeslots: ["/schedule"]
};

export const STEP_COPY = {
  addAccount: "Connect your social accounts",
  viewCategories: "View your default categories",
  addContent: "Add your social content",
  addTimeslots: "Create your weekly automation schedule"
};
