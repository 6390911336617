/* @flow */

import type { Account } from "types";

const hasPinterestAccounts = (accounts: Account[]) => {
  return accounts.some(
    account =>
      account.platform === "pinterest" || account.platform === "PINTEREST"
  );
};

export default hasPinterestAccounts;
