/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query fbPostComments($postId: Int!, $cursor: String) {
    fbPostComments(postId: $postId, cursor: $cursor) {
      comments {
        attachment {
          width
          height
          attachmentUrl
          thumbnail
        }
        comments {
          id
          message
          createdTime
          from {
            id
            name
            picture
          }
          attachment {
            width
            thumbnail
            height
          }
          userLikes
          likeCount
        }
        createdTime
        cursor
        from {
          id
          name
          picture
        }
        id
        likeCount
        message
        userLikes
      }
      cursor
    }
  }
`;
