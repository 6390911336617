// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  children: Node,
  className?: string
};

const H1 = ({ children, className, ...others }: Props) => (
  <h1 className={classNames(styles.root, className)} {...others}>
    {children}
  </h1>
);

export default H1;
