// @flow

export type MP4Meta = {
  type: string,
  boxes: ?Array<MP4Meta>
};

// See http://l.web.umkc.edu/lizhu/teaching/2016sp.video-communication/ref/mp4.pdf
// for a specification of MPEG-4 boxes and file layout. At a high level, MP4 Metadata
// is a hierarchical collection of "boxes," each of which has a type, some fields
// containing properties, and optionally more boxes within it. We sniff out the property
// we're looking for by recursively hunting through all the boxes for the sought boxType,
// and returning the desired property from that box. This won't work very well if we ever
// need to support, e.g., movies with multiple tracks, where the tracks are all recorded
// w/ different dimensions, because it will return the first match. That seems like a
// pretty weird edge case, though (famous last words).
export class VideoMetadata {
  parsed: Array<MP4Meta>;

  constructor(parsed: Array<MP4Meta>) {
    this.parsed = parsed;
  }

  find = (
    boxes: Array<MP4Meta>,
    boxType: string,
    soughtIndex: number = 0
  ): ?MP4Meta => {
    let index = 0;
    let result = null;

    function scanBoxes(innerBoxes: Array<MP4Meta>) {
      for (let i = 0, len = innerBoxes.length; i < len; i++) {
        const box = innerBoxes[i];
        if (box.type.toLowerCase() === boxType) {
          if (index === soughtIndex) {
            result = box;
            break;
          } else {
            index++;
          }
        } else if (box.boxes) {
          scanBoxes(box.boxes);
          if (result) {
            break;
          }
        }
      }
    }

    scanBoxes(boxes);
    return result;
  };

  // Strips elements from a string array whose values contain non-ASCII characters
  asciiOnly = (values: string[]): string[] =>
    values.filter(e => /^[ -~]+$/.test(e));

  // returned undefined if we can't find the box/property.
  value = (
    boxName: string,
    property: string,
    index: number = 0
  ): ?string | ?number => {
    const box = this.find(this.parsed, boxName, index);
    return box ? box[property] : undefined;
  };

  // returns 0 if we can't find the box/property.
  number = (boxName: string, property: string, index: number = 0): number => {
    const box = this.find(this.parsed, boxName, index);
    return box && box[property] ? (box && box[property]) || 0 : 0;
  };

  // returns '' if we can't find the box/property.
  string = (boxName: string, property: string, index: number = 0): string => {
    const box = this.find(this.parsed, boxName, index);
    return box && box[property] ? box[property].trim() || "" : "";
  };

  // returns [] if we can't find the box/property.
  array = (boxName: string, property: string, index: number = 0): string[] => {
    const box = this.find(this.parsed, boxName, index);
    if (box && box[property]) {
      // Some MP4s pad their arrays with null values for some reason (for example, see
      // https://app.bugsnag.com/lkr/edgar/errors/592fbb3eceef900018a6c6af). This causes
      // Postgres to get angry when we try to persist it, so strip any elements that contain
      // any non-ASCII characters here.
      return this.asciiOnly(box[property]);
    }
    return [];
  };
}
