/* @flow */
import React from "react";

import Alert from "layout/Alert";

export const OneTwitterAccountPerContentAlert = () => (
  <Alert type="twitter" clear>
    Twitter's terms of service allow only one Twitter account to be selected per
    post.{" "}
    <a
      href="https://meetedgar.com/blog/what-twitters-new-rules-mean-for-social-media-scheduling/"
      target="_blank"
    >
      Read more here
    </a>
    .
  </Alert>
);

export default OneTwitterAccountPerContentAlert;
