/* @flow */
import React from "react";
import InstagramEmbed from "react-instagram-embed";

import PulseGIF from "components/common/images/loading-pulse.svg";

import type { IProps } from "./types.js";
import useContent from "./use";
import {
  Wrapper,
  Container,
  Header,
  HButton,
  EmbedWrapper,
  WhiteBlock,
  Loading,
  LoadingEmbed
} from "./styles";

import Comments from "./Comments";
import Footer from "./Footer";

// Facebook appID for production: 534740913303527
// Facebook appID for development: 716053645172252

const Content = (props: IProps) => {
  const { post } = props;
  const {
    loading,
    comments,
    fetchComments,
    loadingMore,
    loadOlderComments,
    afterCommentCreate,
    containerRef,
    loadingEmbed,
    dissmissLoadingEmbed
  } = useContent(props);

  return (
    <Wrapper>
      <Container ref={containerRef}>
        <Header>
          {/* <HButton className="disabled test">Direct messages</HButton> */}
          <HButton className="active">Comments</HButton>
        </Header>

        {!post ? (
          <Loading>You need to select an account and a post.</Loading>
        ) : (
          <>
            {loadingEmbed && (
              <LoadingEmbed>
                <img src={PulseGIF} alt="loading" />
              </LoadingEmbed>
            )}

            <EmbedWrapper className={loadingEmbed ? "loading" : ""}>
              <InstagramEmbed
                url={post.url}
                clientAccessToken="534740913303527|a3b2a197b0d6322a1105321ce0245a6f"
                maxWidth={440}
                hideCaption={false}
                protocol=""
                injectScript
                onLoading={() => {}}
                onSuccess={() => {}}
                onAfterRender={dissmissLoadingEmbed}
                onFailure={() => {}}
              />
              <WhiteBlock />
            </EmbedWrapper>

            {loading ? (
              <Loading>
                Loading post comments <img src={PulseGIF} alt="loading" />
              </Loading>
            ) : (
              <Comments
                data={comments}
                loadOlderComments={loadOlderComments}
                loadingMore={loadingMore}
                post={post}
              />
            )}
          </>
        )}
      </Container>

      {!!post && (
        <Footer
          post={post}
          triggerReloadComments={fetchComments}
          afterCommentCreate={afterCommentCreate}
        />
      )}
    </Wrapper>
  );
};

export default Content;
