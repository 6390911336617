/* @flow */
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { unstable_batchedUpdates as batchedUpdates } from "react-dom";
import { batchedSubscribe } from "redux-batched-subscribe";
import rootReducer from "../reducers/root.js";
import type { Store } from "types";

// NOTE - batchedSubscribe store enhancer can probably be removed
// once we upgrade to react 16 and/or redux 4. Without this enhancer
// react-redux can end up triggering renders for connected child components
// before their parents re-render causing an inconsistent state. If this
// is removed test that that is no longer the case by going to library
// and applying a key word filter which has no results. If there are errors
// the console the bug is still present.
// https://stackoverflow.com/questions/38099512/redux-child-receiving-props-before-parent

const store = (): Store =>
  createStore(
    rootReducer,
    compose(
      applyMiddleware(thunkMiddleware),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f,
      batchedSubscribe(batchedUpdates)
    )
  );

export default store;
