// @flow
import React, { Component } from "react";
import ReactS3Uploader from "react-s3-uploader";
import validateVideo from "lib/videos/validateVideo";
import analyzeVideo, {
  ANALYSIS_ERROR_MESSAGE,
  type AnalyzedVideo
} from "lib/videos/analyzeVideo";

import type { Provider } from "graphql-types/loadComposer";

type Props = {
  content?: object,
  inputId: string,
  selectedProviders: Provider[],
  existingImageCount: number,
  existingVideoCount: number,
  onStart: (file: File) => void,
  onProgress: (progress: ?number, status: string, file: File) => void,
  onUploadError: (message: string, file: File) => void,
  onValidationError: (errors: string[], file: File) => void,
  onFinish: (url: string, file: File) => void,
  onVideoAnalysisComplete: (analysis: AnalyzedVideo) => void,
  instaReels: boolean
};

export class VideoUploader extends Component<Props> {
  input: ?HTMLInputElement;

  handlePreprocessVideo = (file: File, next: (file: File) => void) => {
    const {
      onStart,
      onValidationError,
      onVideoAnalysisComplete,
      selectedProviders,
      instaReels,
      content
    } = this.props;
    onStart(file);
    analyzeVideo(file)
      .then((analysis: AnalyzedVideo) => {
        onVideoAnalysisComplete(analysis);
        const { valid, errors } = validateVideo(
          content,
          analysis,
          selectedProviders,
          instaReels
        );
        if (valid) {
          next(file);
        } else {
          onValidationError(errors, file);
        }
      })
      .catch(() => {
        onValidationError([ANALYSIS_ERROR_MESSAGE], file);
      });
  };

  handleFinish = (opt: Object, file: File): void => {
    const { onFinish } = this.props;
    const strippedUrl = opt.signedUrl.replace(/\?.*$/, "");
    onFinish(strippedUrl, file);
    if (this.input) {
      this.input.value = "";
    }
  };

  render() {
    const {
      inputId,
      onProgress,
      onUploadError,
      selectedProviders,
      existingImageCount,
      existingVideoCount,
      instaReels
    } = this.props;

    const remainingLimit =
      selectedProviders?.length === 1 &&
        selectedProviders[0] === "INSTAGRAM_BUSINESS"
        ? 10 - (existingImageCount + existingVideoCount)
        : 1;

    return (
      <ReactS3Uploader
        style={{ display: "none" }}
        id={inputId}
        signingUrl="/api/s3_signed_urls"
        signingUrlHeaders={{
          Accept: "application/vnd.meetedgar.v2+json",
          "Content-Type": "application/vnd.meetedgar.v2+json",
          "X-Authorization": `Bearer ${window.API_KEY}`
        }}
        uploadRequestHeaders={{}}
        accept="video/mp4,video/quicktime"
        multiple={remainingLimit > 1 && !instaReels}
        inputRef={input => {
          this.input = input;
        }}
        preprocess={this.handlePreprocessVideo}
        onProgress={onProgress}
        onError={onUploadError}
        onFinish={this.handleFinish}
      />
    );
  }
}

export default VideoUploader;
