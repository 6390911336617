/* @flow */
import styled from "styled-components";

export const Wrapper = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 0 15px 0 0;

  img {
    margin-right: 4px;
  }

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: var(--lighterDisabledOpacity);
  }
`;
