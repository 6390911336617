/* @flow */

function getInitialData(type: string): any[] {
  if (!window.INITIAL_STORE_STATE) {
    return [];
  }
  if (!window.INITIAL_STORE_STATE[type]) {
    return [];
  }
  if (!window.INITIAL_STORE_STATE[type].data) {
    return [];
  }
  return window.INITIAL_STORE_STATE[type].data;
}

export default getInitialData;
