/* @flow */
import React from "react";

import Icon from "icons/Icon";
import Link from "links/Link";
import Card from "layout/Card";
import Alert from "layout/Alert";
import Button from "buttons/Button";
import { LoadingIcon } from "icons";

import styles from "./index.css";
import type { Props } from "./types.js";
import { LoadingIconContainer } from "./styles";

import Header from "./Header";

const CraftYourContent = (props: Props) => {
  const {
    variations,
    idx,
    loadingVariationSuggestions,
    suggestionsFailed,
    onDismissSuggestionsFailed,
    showAddVariationOnboardingTooltip,
    allowSuggestVariations,
    onAddVariation,
    onSuggestVariations,
    onFocusEditor
  } = props;

  return (
    <>
      <Header
        variations={variations}
        idx={idx}
        onAddVariation={onAddVariation}
        onFocusEditor={onFocusEditor}
        showAddVariationOnboardingTooltip={showAddVariationOnboardingTooltip}
      />

      {props.children}

      {loadingVariationSuggestions && (
        <Card padding={false}>
          <LoadingIconContainer>
            <LoadingIcon />
          </LoadingIconContainer>
        </Card>
      )}

      {suggestionsFailed && (
        <Alert
          type="info"
          className={styles.messageContainer}
          onDismiss={onDismissSuggestionsFailed}
        >
          Edgar was unable to suggest variations for that URL, but you can
          create your own by clicking "Add variation" below! (
          <Link
            target="_blank"
            url={
              "https://help.meetedgar.com/edgar-s-features-and-best-practices/the-add-new-content-button/variations"
            }
          >
            Why didn’t this URL work?
          </Link>
          )
        </Alert>
      )}

      <Card padded={false} className={styles.addVariationCard}>
        {allowSuggestVariations && (
          <>
            <div className={styles.suggestVariationsButtonContainer}>
              <Button
                className={styles.suggestVariationsButton}
                small
                type="alt-primary"
                onClick={onSuggestVariations}
              >
                <Icon type="magic" /> Suggest variations
              </Button>
            </div>
            <div className={styles.suggestVariationsButtonContainer}>
              <Link
                onClick={onAddVariation}
                className={styles.addVariationButton}
              >
                Manually add variation
              </Link>
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default CraftYourContent;
