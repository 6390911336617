// @flow
import React from "react";
import Alert from "layout/Alert";

const HELP_URL =
  "https://help.meetedgar.com/articles/1623082-why-are-my-twitter-posts-expired";

const TwitterExhaustedAlert = () => (
  <Alert type="twitter" clear>
    Once an update has been sent to Twitter, you can no longer select any
    Twitter account (including the original).&nbsp;
    <a href={HELP_URL} target="_blank">
      Read more here.
    </a>
  </Alert>
);

export default TwitterExhaustedAlert;
