/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation dismissScheduleSuggestion($offset: Int!, $platforms: [Platform!]!) {
    dismissScheduleSuggestion(offset: $offset, platforms: $platforms) {
      errors
    }
  }
`;
