import React from "react";

import tooltipper from "behavioral/tooltipper";

import Caption from "typography/Caption";

import styles from "./index.css";

const BitlyTip = () => {
  return (
    <Caption>
      Bit.ly may limit the number of links you can shorten within a certain
      timeframe. If Bit.ly fails to shorten a link, Edgar will post your update
      with the original, unshortened version, and notify you of the error.
    </Caption>
  );
};

const BitlyText = () => {
  return <span className={styles.bitlyText}>what's this?</span>;
};

export default tooltipper(BitlyTip)(BitlyText);
