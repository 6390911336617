// @flow
import React from "react";

import Toggle from "forms/Toggle";

import styles from "./index.css";

type Props = {
  value: boolean,
  onChange: (val: boolean) => void
};

const HELP_LINK =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/organize-your-content-with-edgar-s-categories/use-once";

const UseOnceField = ({ value, onChange }: Props) => (
  <div>
    <Toggle value={value} label={<b>Use Once</b>} onChange={onChange} />
    <p className={styles.helpText}>
      Selecting Use Once means this post will only be published once. If the
      post contains variations, only the last variation will be published and
      then the entire post will be expired. Use Once should not be used in
      combination with variations and it is best to use either feature
      individually.{" "}
      <a href={HELP_LINK} target="_blank">
        Learn more about Use Once.
      </a>
    </p>
  </div>
);

export default UseOnceField;
