/* eslint-disable flowtype/require-valid-file-annotation */
import React, { Component } from "react";
import { connect } from "react-redux";

import difference from "lodash/difference";
import without from "lodash/without";
import { dismissFlash } from "actions/flash";
import { selectActiveFlashes } from "selectors/flash";
import { useNewUICached } from "util";

import type { AppState, Dispatch, Connector } from "types";

import Flash from "./Flash";
import styles from "./index.css";
import { Wrapper, Item } from "./styles";

type Props = {
  flashes: Flash[],
  onDismissFlash: (id: string) => void
};

type State = {
  mounted: boolean,
  disappearingFlashes: Flash[]
};

// How long to wait until initial flashes are displayed
const INITIAL_REVEAL_DELAY = 1500;

// Should match animation speed in CSS
const DISAPPEARANCE_DELAY = 1000;

export class Flasher extends Component {
  props: Props;

  state: State = { mounted: false, disappearingFlashes: [] };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true });
    }, INITIAL_REVEAL_DELAY);
  }

  componentWillReceiveProps(newProps) {
    const { flashes } = this.props;
    let { disappearingFlashes } = this.state;
    const diff = difference(flashes, newProps.flashes);

    // If any flashes have been dismissed store a copy in state until the animated
    // collpasing completes.
    if (diff.length) {
      this.setState({
        disappearingFlashes: disappearingFlashes.concat(diff)
      });
      setTimeout(() => {
        disappearingFlashes = this.state.disappearingFlashes;
        this.setState({
          disappearingFlashes: without(disappearingFlashes, ...diff)
        });
      }, DISAPPEARANCE_DELAY);
    }
  }

  render() {
    const useNewUI = useNewUICached();

    const {
      props: { flashes, onDismissFlash },
      state: { mounted, disappearingFlashes }
    } = this;

    const visibilityClass =
      flashes.length && mounted ? styles.show : styles.hide;

    return useNewUI ? (
      <Wrapper className={visibilityClass}>
        {flashes.concat(disappearingFlashes).map((it, index) => (
          <Item key={index}>
            <Flash value={it} key={it.id} onDismiss={onDismissFlash} useNewUI />
          </Item>
        ))}
      </Wrapper>
    ) : (
      <div className={`${styles.root} ${visibilityClass}`}>
        <div>
          {flashes.concat(disappearingFlashes).map(it => (
            <Flash value={it} key={it.id} onDismiss={onDismissFlash} />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  flashes: selectActiveFlashes(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDismissFlash: id => {
    dispatch(dismissFlash(id));
  }
});

const connector: Connector<{}, Props> = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(Flasher);
