/* @flow */
import React, { Component } from "react";

import { getMomentInTimeZone } from "util";
import Link from "links/Link";
import Caption from "typography/Caption";

import type { Props, State } from "./types.js";
import styles from "./index.css";

import DateInput from "./DateInput";
import TimeField from "./TimeField";

export default class DateTimeField extends Component<Props, State> {
  state = { originalValue: this.props.value };

  handleChange: (value: Object) => void = value => {
    this.props.onChange(value ? value.format() : this.props.value);
  };

  handleReset = () => {
    this.handleChange(
      getMomentInTimeZone(this.state.originalValue, this.props.timeZone)
    );
  };

  render() {
    const { originalValue } = this.state;
    const {
      value,
      defaultValue,
      disabled,
      timeZone,
      popperPlacement,
      enableResetLink = false,
      textLeft,
      textCenter,
      textRight
    } = this.props;
    const valueMoment = value ? getMomentInTimeZone(value, timeZone) : null;
    const defaultValueMoment = getMomentInTimeZone(defaultValue, timeZone);
    const showReset =
      originalValue &&
      enableResetLink &&
      !getMomentInTimeZone(originalValue, timeZone).isSame(valueMoment);

    const areInputsDisabled = disabled || !value;

    return (
      <div className={styles.root}>
        {this.props.notice}
        <Caption className={styles.helpText} helpText>
          {this.props.captionText}
          {this.props.captionLink && (
            <span>
              {" "}
              (
              <a
                href={this.props.captionLink.href}
                tabIndex="-1"
                target="_blank"
              >
                {this.props.captionLink.text}
              </a>
              )
            </span>
          )}
        </Caption>
        <div className={styles.inputs}>
          {textLeft && <div className={styles.textLeft}>{textLeft}</div>}
          <TimeField
            className={styles.timeInput}
            value={valueMoment || defaultValueMoment}
            disabled={areInputsDisabled}
            onChange={this.handleChange}
          />
          {textCenter && <div className={styles.textCenter}>{textCenter}</div>}
          <DateInput
            className={styles.dateInput}
            value={valueMoment || defaultValueMoment}
            disabled={areInputsDisabled}
            minValue={getMomentInTimeZone(Date.now(), timeZone)}
            popperPlacement={popperPlacement}
            onChange={this.handleChange}
          />
          {textRight && <div className={styles.textRight}>{textRight}</div>}
        </div>
        {showReset && (
          <Link className={styles.resetLink} onClick={this.handleReset}>
            Reset to orignally scheduled time
          </Link>
        )}
      </div>
    );
  }
}
