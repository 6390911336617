/* @flow */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Location, RouterHistory } from "react-router-dom";
import qs from "qs";
import get from "lodash/get";
import moment from "moment-timezone";

import GET_UPCOMING_POSTS from "queries/getUpcomingPosts";
import SKIP_UPCOMING_POST from "mutations/skipUpcomingPost";
import DELETE_SCHEDULE from "mutations/deleteSchedule";
import PAUSE_QUEUE from "mutations/pauseQueue";
import UNPAUSE_QUEUE from "mutations/unpauseQueue";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
// import ScheduleViewToggle from "components/ScheduleViewToggle";

import { useNewUICached } from "util";
import PageTitle from "links/PageTitle";
import NewHeader from "components/NewHeader";
import Message from "layout/Message";
import { Party } from "components/common/behavioral";
import ErrorWithRetry from "components/ErrorWithRetry";
import App from "components/App";
import Filters from "components/Filters";
import Search from "components/Search";
import QueueInformation from "./QueueInformation";
import PauseButton from "./PauseButton";
import UpcomingPostList from "./UpcomingPostList";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type {
  getUpcomingPosts_upcomingPosts_data,
  getUpcomingPosts_user_onboardingProgress as OnboardingProgressData
} from "graphql-types/getUpcomingPosts";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";

import styles from "./index.css";

const PER_PAGE = 25;
const QUEUE_HELP_URL =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/edgar-s-queue/queue-overview";

type OwnProps = {
  topNav: TopNavProps,
  subscriptionStatus: SubscriptionStatusProps,
  location: Location,
  history: RouterHistory
};

type Props = {
  error?: string,
  upcomingPosts?: getUpcomingPosts_upcomingPosts_data[],
  emptyReasons?: string[],
  totalCount?: number,
  page?: number,
  timezone?: string,
  paused?: boolean,
  loadingPauseButton?: boolean,
  onSkipUpcomingPost?: (string, string, string) => void,
  onDeleteSchedule?: (scheduleId: number) => void,
  onPauseQueue?: () => void,
  onUnpauseQueue?: () => void,
  onPageChange?: number => void,
  onboardingProgress?: OnboardingProgressData,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>
} & OwnProps;

export const Queue = ({
  error,
  topNav,
  subscriptionStatus,
  onboardingProgress,
  upcomingPosts,
  emptyReasons,
  totalCount,
  timezone,
  page,
  paused,
  loadingPauseButton,
  location,
  onSkipUpcomingPost,
  onDeleteSchedule,
  onPauseQueue,
  onUnpauseQueue,
  onPageChange,
  dismissOnboarding
}: Props) => {
  const useNewUI = useNewUICached();

  return (
    <App
      loggedIn={true}
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      queuePaused={paused}
      onboardingProgress={onboardingProgress}
      onDismissOnboarding={dismissOnboarding}
      header={
        useNewUI ? (
          <>
            <NewHeader
              title="Queue"
              helpUrl={QUEUE_HELP_URL}
              mb
              // titleContent={<ScheduleViewToggle />}
              subtitle={
                timezone &&
                `All times in ${moment.tz.zone(timezone).abbr(moment())}`
              }
              {...topNav}
            />
            <div className={styles.titleButtonWrapper}>
              <Search totalCount={totalCount} />
              <div className={styles.titleButton}>
                <PauseButton
                  paused={paused}
                  loading={loadingPauseButton}
                  onUnpauseQueue={onUnpauseQueue}
                  onPauseQueue={onPauseQueue}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <PageTitle
              helpUrl={QUEUE_HELP_URL}
              subtitle={
                timezone &&
                `All times in ${moment.tz.zone(timezone).abbr(moment())}`
              }
            >
              Queue
            </PageTitle>
            <PauseButton
              paused={paused}
              loading={loadingPauseButton}
              onUnpauseQueue={onUnpauseQueue}
              onPauseQueue={onPauseQueue}
            />
          </>
        )
      }
      messages={
        paused ? (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from below.
          </Message>
        ) : null
      }
      sidebar={<Filters location={location} />}
    >
      <Party />
      {!useNewUI ? <Search /> : null}
      <QueueInformation className={styles.information} />
      {error ? (
        <ErrorWithRetry>{error}</ErrorWithRetry>
      ) : (
        <UpcomingPostList
          onSkipUpcomingPost={onSkipUpcomingPost}
          onDeleteSchedule={onDeleteSchedule}
          timezone={timezone}
          upcomingPosts={upcomingPosts}
          totalCount={totalCount}
          onPageChange={onPageChange}
          pageCount={(totalCount || 0) / PER_PAGE}
          page={page}
          emptyReasons={emptyReasons || []}
        />
      )}
    </App>
  );
};

export default (props: OwnProps) => {
  const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
  const page = params.page || 1;
  const filter = params.filter || {};

  if (filter.useOnce) {
    filter.useOnce = JSON.parse(filter.useOnce);
  }

  if (filter.platform) {
    filter.platform = filter.platform.toUpperCase();
  }

  const variables = {
    filter,
    page: { offset: (page - 1) * PER_PAGE, limit: PER_PAGE }
  };

  // Mutations
  const [pauseQueue, { loading: loadingPause }] = useMutation(PAUSE_QUEUE);
  const [unpauseQueue, { loading: loadingUnpause }] = useMutation(
    UNPAUSE_QUEUE
  );
  const [skipUpcomingPost, { loading: loadingSkip }] = useMutation(
    SKIP_UPCOMING_POST,
    {
      refetchQueries: _ => [
        {
          query: GET_UPCOMING_POSTS,
          variables
        }
      ],
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true
    }
  );
  const [deleteSchedule, { loading: loadingDeleteSchedule }] = useMutation(
    DELETE_SCHEDULE,
    {
      refetchQueries: _ => [
        {
          query: GET_UPCOMING_POSTS,
          variables
        }
      ],
      awaitRefetchQueries: true,
      notifyOnNetworkStatusChange: true
    }
  );
  const [
    dismissOnboarding,
    { loading: _dismissingOnboarding, error: _dismissOnboardingError }
  ] = useMutation(DISMISS_ONBOARDING);

  // Queries
  const { loading, error, data, refetch } = useQuery(GET_UPCOMING_POSTS, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  if (loading || loadingSkip || loadingDeleteSchedule) {
    return <Queue {...props} />;
  }
  if (error) {
    return <Queue {...props} error="Uh-oh something went wrong 😿" />;
  }

  const handleSkipUpcomingPost = (contentId, accountId, categoryId) => {
    skipUpcomingPost({ variables: { contentId, accountId, categoryId } });
  };

  const handleDeleteSchedule = scheduleId => {
    console.log(`deleting schedule: ${scheduleId}`);
    deleteSchedule({ variables: { id: scheduleId } });
  };

  const handlePauseQueue = () => {
    pauseQueue();
  };
  const handleUnpauseQueue = () => {
    unpauseQueue();
  };

  const handlePageChange = pageNum => {
    const nextPage = pageNum;
    const offset = pageNum * PER_PAGE;
    const limit = PER_PAGE;
    window.scrollTo(0, 0);
    props.history.push(
      `${props.location.pathname}${qs.stringify(
        { ...params, page: nextPage },
        { addQueryPrefix: true }
      )}`
    );
    refetch({
      filter,
      page: { offset, limit }
    });
  };

  return (
    <Queue
      {...props}
      loadingPauseButton={loadingPause || loadingUnpause}
      upcomingPosts={get(data, "upcomingPosts.data")}
      emptyReasons={get(data, "upcomingPosts.emptyReasons")}
      totalCount={get(data, "upcomingPosts.totalCount")}
      timezone={get(data, "company.timeZone")}
      page={page}
      paused={get(data, "company.queueStatus") === "PAUSED"}
      onboardingProgress={get(data, "user.onboardingProgress")}
      dismissOnboarding={dismissOnboarding}
      onPauseQueue={handlePauseQueue}
      onUnpauseQueue={handleUnpauseQueue}
      onPageChange={handlePageChange}
      onSkipUpcomingPost={handleSkipUpcomingPost}
      onDeleteSchedule={handleDeleteSchedule}
    />
  );
};
