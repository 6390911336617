import React from "react";
import { connect } from "react-redux";

import { createFlash } from "actions/flash";
import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";

import { Wrapper, GrayBox, HowMany, Form, FormText } from "./styles";

type Props = {
  close: () => void,
  flash: (type: FlashType, body: string) => void,
  selectedPlan: ?string,
  total: ?number,
  handleChange: boolean,
  additionalPrice: ?number
};

export const AdditionalAccounts = ({
  selectedPlan,
  total,
  handleChange,
  additionalPrice
}: Props) => {
  const month_or_year =
    additionalPrice === 2.99 || additionalPrice === 4.99 ? "month" : "year";

  let accountCount = selectedPlan?.productName == "edgar_lite" ? 5 : 25;

  const accountDetails = `Currently you can connect ${accountCount} social accounts. If you want to connect more social accounts then please add additional accounts from here:`;

  return (
    <Wrapper>
      <div id="errors" />

      <GrayBox>
        <HowMany>{accountDetails}</HowMany>

        <Form>
          <input type="number" step={1} onChange={handleChange} value={total} />
          <FormText>
            @&nbsp; ${additionalPrice} /{" "}
            {month_or_year === "month" ? "mo" : "yr"}. &nbsp;=&nbsp;
            <span>
              {(total * additionalPrice).toLocaleString("en-US", {
                style: "currency",
                currency: "USD"
              })}
              / {month_or_year === "month" ? "mo" : "yr"}.
            </span>
          </FormText>
        </Form>
      </GrayBox>
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AdditionalAccounts);
