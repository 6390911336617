// @flow
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import pluralize from "pluralize";

import { hasLinkAttachment } from "../util.js";
import Icon from "icons/Icon";
import Title from "./Title";
import TextField from "./TextField";
import FbTextField from "./FbTextField";
import Attachments from "./Attachments";
import ImageAttachmentButton from "./ImageAttachmentButton";
import VideoAttachmentButton from "./VideoAttachmentButton";
import CanvaButton from "./CanvaButton";
import PostPreview from "./PostPreview";
import styles from "./index.css";

import type {
  loadContent_content as ContentData,
  loadContent_content_accountRelationships_account as AccountData,
  loadContent_content_variations as VariationData,
  loadContent_content_variations_videos as VideoData,
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_pageScrape_images as ScrapedImageData,
  Platform,
  Provider
} from "graphql-types/loadContent";
import type { createMentionTagMap_createMentionTagMap_mentionTagMap as MentionTagMapData } from "graphql-types/createMentionTagMap";
import type { scrapeUrl_scrapeUrl_pageScrape as PageScrapeData } from "graphql-types/scrapeUrl";

type RemainingCharacterCounts = { [key: string]: number };
type VariationField = "text" | "fbText";

type MediaUpload = { fileName: string, progress: ?number };
type Props = {
  index: number,
  content: ContentData,
  value: VariationData,
  title: string,
  isWidget: boolean,
  fbTextVisible: boolean,
  deleteVisible: boolean,
  focused: boolean,
  focusedField: ?VariationField,
  hovered: boolean,
  isConnectAccountsModalOpen: boolean,
  remainingCharacterCounts: RemainingCharacterCounts,
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  selectedPinterestBoardsByAccount: { [key: string]: string[] },
  mentionSuggestions: MentionTagMapData[],
  uploadingMedia: MediaUpload[],
  allAttachedVideos: VideoData[],
  allAttachedImages: ImageData[],
  attachingImage: boolean,
  pageScrape: PageScrapeData,
  hasModifiedVariations: boolean,
  requiredFieldErrorVisible: boolean,
  linkAttachmentRemoved: boolean,
  warnings: string[],
  clientErrors: { text: string[], fbText: string[] },
  serverErrors: { text: string[], fbText: string[] },
  onNextOnboardingStep: () => void,
  showOnboardingTooltip: boolean,
  selectedAccounts: AccountData[],
  onClearMentionSuggestions: () => void,
  onAddMention: (mentionTagMap: Object) => void,
  onAdd: () => void,
  onFocusText: () => void,
  onFocusFbText: () => void,
  onFocusVariation: () => void,
  onBlurField: () => void,
  onMouseMove: () => void,
  onMouseLeave: () => void,
  onDelete: () => void,
  onChangeText: (clientId: string, text: string) => void,
  onChangeFbText: (text: string) => void,
  onEntityMapChange: (entityMap: Object) => void,
  onSearchSocialMedia: (provider: Provider, query: string) => void,
  onMentionSuggestionWarning: (value: string) => void,
  onAttachImage: (variationClientId: string, image: ImageData) => void,
  onAttachScrapedImage: (
    variationClientId: string,
    scrapedImage: ScrapedImageData
  ) => void,
  onImageUploadStart: (variationClientId: string, file: File) => void,
  onImageUploadProgress: (
    variationClientId: string,
    progress: ?number,
    status: string,
    file: File
  ) => void,
  onImageUploadError: (
    variationClientId: string,
    message: string,
    file: File
  ) => void,
  onImageUploadValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void,
  onImageUploadFinish: (
    variationClientId: string,
    url: string,
    file: File
  ) => void,
  onImagesOrderChange: (images: []) => void,
  onRemoveImage: (variationClientId: string, imageId: string) => void,
  onAttachVideo: (variationClientId: string, video: VideoData) => void,
  onVideoUploadStart: (variationClientId: string, file: File) => void,
  onVideoUploadProgress: (
    variationClientId: string,
    progress: ?number,
    status: string,
    file: File
  ) => void,
  onVideoUploadError: (
    variationClientId: string,
    message: string,
    file: File
  ) => void,
  onVideoUploadValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void,
  onVideoUploadFinish: (
    variationClientId: string,
    url: string,
    file: File
  ) => void,
  onRemoveVideo: (variationClientId: string, imageId: string) => void,
  onEnableLinkPreview: (variationClientId: string) => void,
  onDisableLinkPreview: (variationClientId: string) => void,
  onChangePinterestDestinationLink: (link: string) => void,
  onChangePinterestTitle: (title: string) => void,
  setOnDragEndMethod: any
};

export const Variation = ({
  content,
  value,
  title,
  isWidget,
  focused,
  focusedField,
  hovered,
  deleteVisible,
  fbTextVisible,
  isConnectAccountsModalOpen,
  remainingCharacterCounts,
  selectedPlatforms,
  selectedProviders,
  selectedAccounts,
  selectedPinterestBoardsByAccount,
  mentionSuggestions,
  uploadingMedia,
  allAttachedVideos,
  allAttachedImages,
  attachingImage,
  pageScrape,
  requiredFieldErrorVisible,
  linkAttachmentRemoved,
  warnings,
  clientErrors,
  serverErrors,
  onNextOnboardingStep,
  showOnboardingTooltip,
  onClearMentionSuggestions,
  onAddMention,
  onMouseMove,
  onMouseLeave,
  onFocusText,
  onFocusFbText,
  onFocusVariation,
  onBlurField,
  onChangeText,
  onChangeFbText,
  onEntityMapChange,
  onSearchSocialMedia,
  onMentionSuggestionWarning,
  onDelete,
  onAttachImage,
  onAttachScrapedImage,
  onImageUploadStart,
  onImageUploadProgress,
  onImageUploadError,
  onImageUploadValidationError,
  onImageUploadFinish,
  onImagesOrderChange,
  onRemoveImage,
  onAttachVideo,
  onVideoUploadStart,
  onVideoUploadProgress,
  onVideoUploadError,
  onVideoUploadValidationError,
  onVideoUploadFinish,
  onRemoveVideo,
  onEnableLinkPreview,
  onDisableLinkPreview,
  onChangePinterestDestinationLink,
  onChangePinterestTitle,
  setOnDragEndMethod
}: Props) => {
  const lastField = fbTextVisible ? "fbText" : "text";
  const displayAsHovered = hovered && !focused;
  const rootClasses = classNames(styles.root, {
    [styles.hovered]: displayAsHovered
  });
  const textClasses = classNames(styles.field, styles.firstField, {
    [styles.lastField]: lastField === "text"
  });
  const variationTextFieldName = `variationText${value.clientId}`;
  const variationFbTextFieldName = `variationFbText${value.clientId}`;

  const textErrors = (clientErrors?.text || []).concat(
    serverErrors?.text ?? []
  );
  const fbTextErrors = (clientErrors?.fbText || []).concat(
    serverErrors?.fbText ?? []
  );

  const hasErrors = !!textErrors.length || !!fbTextErrors.length;
  const hasWarnings = !!warnings.length;

  const linkAttached = hasLinkAttachment(
    value,
    selectedPlatforms,
    pageScrape,
    uploadingMedia,
    linkAttachmentRemoved
  );
  const [previewModalIsShown, setPreviewModalIsShown] = useState(false);
  const handlePreviewButtonClick = () => {
    previewModalIsShown
      ? setPreviewModalIsShown(false)
      : setPreviewModalIsShown(true);
  };

  let handleOnDragEndMethod = () => {};

  useEffect(() => {
    setOnDragEndMethod(handleOnDragEndMethod);
  }, [setOnDragEndMethod]);

  const setHandleOnDragEndMethod = newMethod => {
    handleOnDragEndMethod = newMethod;
  };

  const itHasTikTok = selectedAccounts.some(
    account => account.provider === "TIKTOK"
  );

  return (
    <div
      onClick={onFocusVariation}
      className={rootClasses}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
    >
      <Title
        htmlFor={variationTextFieldName}
        remainingCharacterCounts={
          !fbTextVisible ? remainingCharacterCounts : null
        }
        warnings={warnings}
        hasErrors={hasErrors}
        hasRequiredFieldError={requiredFieldErrorVisible}
        deleteVisible={deleteVisible}
        focused={focused}
        hovered={displayAsHovered}
        fbTextVisible={fbTextVisible}
        remainingCharacterCounts={remainingCharacterCounts}
        twitterPosted={!!value?.lastPostedTo?.twitter}
        onDelete={onDelete}
        previewButtonVisible={deleteVisible} // we want the behavior to match that of the delete button
        handlePreviewButtonClick={handlePreviewButtonClick}
      >
        {title}
      </Title>
      <div className={styles.fields}>
        <TextField
          value={value}
          className={textClasses}
          fieldName={variationTextFieldName}
          focused={focused}
          fieldFocused={focusedField === "text"}
          fbTextVisible={fbTextVisible}
          isWidget={isWidget}
          hasErrors={hasErrors}
          hasWarnings={hasWarnings}
          isConnectAccountsModalOpen={isConnectAccountsModalOpen}
          selectedPlatforms={selectedPlatforms}
          selectedProviders={selectedProviders}
          mentionSuggestions={mentionSuggestions}
          remainingCharacterCounts={
            fbTextVisible ? remainingCharacterCounts : null
          }
          errors={textErrors}
          onNextOnboardingStep={onNextOnboardingStep}
          showOnboardingTooltip={showOnboardingTooltip}
          onAddMention={onAddMention}
          onClearMentionSuggestions={onClearMentionSuggestions}
          onChange={onChangeText.bind(null, value.clientId)}
          onEntityMapChange={onEntityMapChange.bind(null, value.clientId)}
          onSearchSocialMedia={onSearchSocialMedia}
          onMentionSuggestionWarning={onMentionSuggestionWarning}
          onFocus={onFocusText}
          onBlur={onBlurField}
        />
        <FbTextField
          className={classNames(styles.field, styles.lastField)}
          fieldName={variationFbTextFieldName}
          selectedProviders={selectedProviders}
          mentionSuggestions={mentionSuggestions}
          visible={fbTextVisible}
          isWidget={isWidget}
          hasErrors={hasErrors}
          hasWarnings={hasWarnings}
          focused={focused}
          fieldFocused={focusedField === "fbText"}
          value={value}
          errors={fbTextErrors}
          onClearMentionSuggestions={onClearMentionSuggestions}
          onAddMention={onAddMention}
          onChange={onChangeFbText.bind(null, value.clientId)}
          onEntityMapChange={onEntityMapChange.bind(null, value.clientId)}
          onSearchSocialMedia={onSearchSocialMedia}
          onMentionSuggestionWarning={onMentionSuggestionWarning}
          onFocus={onFocusFbText}
          onBlur={onBlurField}
        />
      </div>
      {itHasTikTok && (
        <div className={styles.tiktokHelper}>
          Use the TikTok app to write text for scheduled TikTok.
        </div>
      )}
      {focused ? (
        <div>
          <Attachments
            setOnDragEndMethod={setHandleOnDragEndMethod}
            images={value.images}
            videos={value.videos}
            sendMobileReminder={content.sendMobileReminder}
            instaReels={content.instaReels}
            pinterestTitle={value.pinterestTitle}
            pinterestDestinationLink={value.pinterestDestinationLink}
            linkAttached={linkAttached}
            pageScrape={pageScrape}
            selectedPlatforms={selectedPlatforms}
            selectedProviders={selectedProviders}
            uploadingMedia={uploadingMedia}
            allAttachedVideos={allAttachedVideos}
            allAttachedImages={allAttachedImages}
            attachingImage={attachingImage}
            clientErrors={clientErrors}
            onAttachImage={onAttachImage.bind(null, value.clientId)}
            onImagesOrderChange={onImagesOrderChange.bind(null, value.clientId)}
            onAttachScrapedImage={onAttachScrapedImage.bind(
              null,
              value.clientId
            )}
            onRemoveImage={onRemoveImage.bind(null, value.clientId)}
            onRemoveVideo={onRemoveVideo.bind(null, value.clientId)}
            onAttachVideo={onAttachVideo.bind(null, value.clientId)}
            onEnableLinkPreview={onEnableLinkPreview.bind(null, value.clientId)}
            onDisableLinkPreview={onDisableLinkPreview.bind(
              null,
              value.clientId
            )}
            onChangePinterestDestinationLink={onChangePinterestDestinationLink}
            onChangePinterestTitle={onChangePinterestTitle}
            variationId={value.clientId}
            onImageUploadValidationError={onImageUploadValidationError}
          />
          <div className={styles.mediaButtons}>
            <ImageAttachmentButton
              variation={value}
              sendMobileReminder={content.sendMobileReminder}
              instaReels={content.instaReels}
              selectedPlatforms={selectedPlatforms}
              selectedProviders={selectedProviders}
              linkAttached={linkAttached}
              onStart={onImageUploadStart}
              onProgress={onImageUploadProgress}
              onUploadError={onImageUploadError}
              onValidationError={onImageUploadValidationError}
              onFinish={onImageUploadFinish}
              onImagesOrderChange={onImagesOrderChange}
            />
            <VideoAttachmentButton
              variation={value}
              selectedPlatforms={selectedPlatforms}
              selectedProviders={selectedProviders}
              linkAttached={linkAttached}
              uploadingMedia={uploadingMedia.length > 0}
              onStart={onVideoUploadStart}
              onProgress={onVideoUploadProgress}
              onUploadError={onVideoUploadError}
              onValidationError={onVideoUploadValidationError}
              onFinish={onVideoUploadFinish}
              instaReels={content.instaReels}
            />
            <CanvaButton />
          </div>
        </div>
      ) : (
        <div className={styles.mediaCounts}>
          {value.images.length > 0 && (
            <span>
              <Icon type="image" /> {value.images.length}{" "}
              {pluralize("image", value.images.length)} attached
            </span>
          )}
          {value.videos.length > 0 && (
            <span>
              <Icon type="film" /> {value.videos.length}{" "}
              {pluralize("video", value.videos.length)} attached
            </span>
          )}
          {linkAttached && (
            <span>
              <Icon type="link" /> link preview attached
            </span>
          )}
        </div>
      )}
      {previewModalIsShown && (
        <PostPreview
          closeButtonClicked={handlePreviewButtonClick}
          selectedAccounts={selectedAccounts}
          selectedPinterestBoardsByAccount={selectedPinterestBoardsByAccount}
          content={content}
          variationData={value}
          title
          mentionSuggestions={mentionSuggestions}
          uploadingMedia={uploadingMedia.length > 0}
          attachingImage={attachingImage}
          pageScrape={pageScrape}
          selectedPlatforms={selectedPlatforms}
        />
      )}
    </div>
  );
};

export default Variation;
