/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query loadSubscriptionManager {
    user {
      company {
        subscribed
        subscriptionEndsAt
        upgradeAvailable
        subscriptionPlan {
          id
          stripeId
          name
          productName
          amount
          interval
          intervalCount
        }
        availableSubscriptionPlans {
          id
          stripeId
          name
          productName
          amount
          interval
          intervalCount
        }
      }
    }
  }
`;
