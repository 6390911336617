/* @flow */
/* global EventHandler */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import get from "lodash/get";
import {
  parseFilterParams,
  buildPaginator,
  handlePageChange
} from "behavioral/withFilter";
import type { Match, Location, RouterHistory } from "react-router-dom";

import PageTitle from "links/PageTitle";
import NewHeader from "components/NewHeader";
import GET_CONTENTS from "queries/getContents";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";

import { useNewUICached } from "util";
import App from "components/App";
import ErrorWithRetry from "components/ErrorWithRetry";
import { Alert } from "layout";
import { Link } from "links";
import Message from "layout/Message";
import Search from "components/Search";
import Filters from "components/Filters";
import Header from "./Header";
import List from "./List";
import styles from "./index.css";

import ExpiredWarning from "./ExpiredWarning";

import type { ContentFilter } from "types";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type {
  getContents_contents_data as ContentData,
  getContents_user_onboardingProgress as OnboardingProgressData
} from "graphql-types/getContents";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";

const PER_PAGE = 25;
const LIBRARY_HELP_URL =
  "https://help.meetedgar.com/introduction-to-edgar-and-faq/library";

type OwnProps = {
  libraryStatus: string,
  topNav: TopNavProps,
  subscriptionStatus: SubscriptionStatusProps,
  location: Location,
  history: RouterHistory,
  match: Match
};

type Props = {
  error?: string,
  filter?: ContentFilter,
  page?: number,
  onPageChange?: number => void,
  contents?: ContentData[],
  timezone?: string,
  totalContentsForStatus?: number,
  totalFilteredContents?: number,
  pendingCount?: number,
  hasFeeds?: boolean,
  paused?: boolean,
  onboardingProgress?: OnboardingProgressData,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>
} & OwnProps;

export const Library = ({
  error,
  page,
  filter,
  contents,
  timezone,
  topNav,
  paused,
  hasFeeds,
  libraryStatus,
  subscriptionStatus,
  onboardingProgress,
  totalContentsForStatus,
  totalFilteredContents,
  pendingCount,
  onPageChange,
  dismissOnboarding,
  location
}: Props) => (
  <App
    loggedIn={true}
    topNav={topNav}
    subscriptionStatus={subscriptionStatus}
    onboardingProgress={onboardingProgress}
    onDismissOnboarding={dismissOnboarding}
    header={
      <>
        {useNewUICached() ? (
          <NewHeader
            title="Library"
            helpUrl={LIBRARY_HELP_URL}
            mb
            {...topNav}
          />
        ) : (
          <PageTitle helpUrl={LIBRARY_HELP_URL}>Library</PageTitle>
        )}
        <Search totalCount={totalContentsForStatus} />
        <ExpiredWarning location={location} />
      </>
    }
    messages={
      paused ? (
        <Message>
          Edgar is currently paused. Edgar will not post anything to your social
          accounts until you unpause posting in{" "}
          <Link href="/queue">your Queue</Link>.
        </Message>
      ) : null
    }
    sidebar={
      <Filters showNoAccount showPosted dateFilterTitle="Date created" />
    }
  >
    <>
      {libraryStatus !== "ok" ? (
        <Alert className={styles.alert} type="info">
          <div className={styles.alertBody}>
            <span className={styles.message}>
              Edgar is loading your {libraryStatus}.{" "}
              <Link>Refresh this page</Link> to see your new content. If any
              errors occur, they will show in the{" "}
              <Link href="/errors">Notifications</Link> section under your
              username.
            </span>
          </div>
        </Alert>
      ) : null}
      {error ? <ErrorWithRetry>{error}</ErrorWithRetry> : null}
      {!error && libraryStatus === "ok" ? (
        <>
          <Header
            pendingCount={pendingCount}
            totalCount={totalContentsForStatus}
            filteredCount={totalFilteredContents}
          />
          <List
            filterStatus={get(filter, "status")}
            hasFeeds={hasFeeds}
            contents={contents}
            timezone={timezone}
            totalCount={totalContentsForStatus}
            filteredCount={totalFilteredContents}
            page={page}
            pageCount={
              totalFilteredContents
                ? Math.ceil(totalFilteredContents / PER_PAGE)
                : null
            }
            onPageChange={onPageChange}
          />
        </>
      ) : null}
    </>
  </App>
);

export default (props: OwnProps) => {
  const { filter, page: pageNum } = parseFilterParams(
    props.match,
    props.location
  );

  // Queries
  const { loading, error, data, refetch } = useQuery(GET_CONTENTS, {
    variables: {
      filter,
      page: buildPaginator(pageNum, PER_PAGE)
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  // Mutations
  const [
    dismissOnboarding,
    { loading: _dismissingOnboarding, error: _dismissOnboardingError }
  ] = useMutation(DISMISS_ONBOARDING);

  if (loading) {
    return <Library {...props} />;
  }

  if (error) {
    return <Library {...props} error="Uh-oh something went wrong 😿" />;
  }

  return (
    <Library
      {...props}
      filter={filter}
      page={pageNum}
      onPageChange={handlePageChange.bind(
        null,
        props.match,
        props.location,
        props.history,
        refetch,
        PER_PAGE
      )}
      onboardingProgress={get(data, "user.onboardingProgress")}
      dismissOnboarding={dismissOnboarding}
      contents={get(data, "contents.data")}
      timezone={get(data, "company.timeZone")}
      hasFeeds={!!get(data, "rssFeeds.length")}
      paused={get(data, "company.queueStatus") === "PAUSED"}
      totalContentsForStatus={get(data, "contents.totalContentsForStatus")}
      totalFilteredContents={get(data, "contents.totalFilteredContents")}
      pendingCount={get(data, "contents.pendingCount")}
    />
  );
};
