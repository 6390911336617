import styled from "styled-components";

import SVGBackground from "./img/background.svg";

export const Wrapper = styled.div`
  align-items: center;
  background-image: url(${SVGBackground});
  background-size: cover;
  background-position: center;
  border-bottom: solid 1px var(--inky100);
  display: flex;
  justify-content: space-between;
  margin-top: -11px;
  position: relative;

  @media (min-width: 600px) {
    margin-top: 48px;
  }

  @media (min-width: 995px) {
    margin-top: 0;
  }
`;

export const LeftImage = styled.div`
  align-items: flex-end;
  display: none;
  flex: 1;
  justify-content: center;
  margin: 6px 0 0;

  img {
    height: 115px;
    width: 153px;
  }

  @media (min-width: 1330px) {
    display: flex;
  }
`;

export const RightBlock = styled.div`
  display: none;
  flex: 1;

  @media (min-width: 1330px) {
    display: block;
  }
`;

export const Container = styled.div`
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 970px;
  padding: 27.5px 15px;
  width: 100%;

  @media (min-width: 1000px) {
    padding: 30px 0;
  }

  @media (min-width: 1110px) {
    display: flex;
  }

  @media (min-width: 1330px) {
    flex: 1 0 970px;
  }
`;

export const Left = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 5px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const Button = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-size: 15px;
  justify-content: space-evenly;
  margin: 20px 0 0;
  padding: 16px 8px 16px 14px;

  img {
    margin-left: 7.5px;
  }

  &.disabled {
    cursor: wait;
    font-style: italic;
    opacity: 0.5;
  }

  &:hover {
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 500px) {
    font-size: 16px;
    min-width: 298px;
    padding: 18px 10px 18px 14px;
  }

  @media (min-width: 1110px) {
    margin: 0;
  }
`;
