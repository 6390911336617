/* @flow */
import { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import TOGGLE_NEW_UI from "mutations/toggleNewUI";

const useSwitchToNew = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bannerVisible, setBannerVisible] = useState(
    localStorage.getItem("hide_new_ui_banner") !== "true"
  );
  const apolloClient = useApolloClient();

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const handleNewUI = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const res = await apolloClient.mutate({
        mutation: TOGGLE_NEW_UI,
        variables: { enable: true }
      });

      if (res.data) {
        localStorage.setItem("navbar_modules_ui", "true");
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const hideNewUIBanner = () => {
    localStorage.setItem("hide_new_ui_banner", "true");
    setBannerVisible(false);
    hideModal();
  };

  return {
    modalVisible,
    showModal,
    hideModal,
    loading,
    handleNewUI,
    bannerVisible,
    hideNewUIBanner
  };
};

export default useSwitchToNew;
