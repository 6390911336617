// @flow
import React, { useState } from "react";
import { getFutureDateInTimeZone } from "util";
import { Modal } from "layout/modals";
import { Button } from "buttons";
import SendAtField from "components/common/SendAtField";

type Props = {
  timeZone: string,
  showWarning: boolean,
  isEditing: boolean,
  onCancelSetSendAtModal: () => void,
  onFinish: (sendAt: string) => void
};

export const SetSendAtModal = (props: Props) => {
  const [sendAt, setSendAt] = useState(
    getFutureDateInTimeZone({ minutes: 15 }, props.timeZone)
  );

  const handleChange = (sendAt: ?string) => {
    setSendAt(sendAt);
  };

  const {
    timeZone,
    showWarning,
    isEditing,
    onCancelSetSendAtModal,
    onFinish
  } = props;

  return (
    <Modal
      title="Schedule send"
      footer={
        <div>
          <Button type="secondary" onClick={onCancelSetSendAtModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={false}
            onClick={onFinish.bind(null, sendAt)}
          >
            Finish
          </Button>
        </div>
      }
      onClose={onCancelSetSendAtModal}
    >
      <SendAtField
        value={sendAt}
        timeZone={timeZone}
        showWarning={showWarning}
        isEditing={isEditing}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default SetSendAtModal;
