import { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import GET_USER_DETAILS from "queries/getUserDetails";

const useModalAddAccounts = () => {
  const { data } = useQuery(GET_USER_DETAILS);
  let accounts = data?.user?.company?.additionalAccounts;
  const [total, setTotal] = useState(accounts);

  const handleChange = e => {
    if (e.target.value >= 0) setTotal(e.target.value);
  };

  return { total, handleChange };
};

export default useModalAddAccounts;
