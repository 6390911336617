/* @flow */
import React from "react";

import PulseGIF from "components/common/images/loading-pulse.svg";

import type { Props } from "./types.js";
import Message from "./Message";

import { Wrapper } from "./styles";
import { Loading } from "../styles";
import { LoadOlder } from "../../FacebookComments/Comments/styles";

const InboxMessages = (props: Props) => {
  const {
    selectedAccount,
    conversationThreads,
    loadingMore,
    loadOlderReplies
  } = props;

  return (
    <Wrapper>
      {!conversationThreads || !conversationThreads.threads ? (
        <Loading>There are no messages on this conversation to show.</Loading>
      ) : (
        <>
          {conversationThreads.cursor && (
            <LoadOlder isLoading={loadingMore}>
              <div onClick={loadOlderReplies}>
                Load{loadingMore && "ing"} older replies{" "}
                {loadingMore && <img src={PulseGIF} alt="loading" />}
              </div>
            </LoadOlder>
          )}

          {conversationThreads.threads.map((thread, index) => (
            <Message
              key={index}
              owner={thread.from?.id === selectedAccount?.uid}
              message={thread?.message || ""}
              attachments={thread?.attachments || []}
              isInstagram={selectedAccount?.platform === "INSTAGRAM"}
            />
          ))}
        </>
      )}
    </Wrapper>
  );
};

export default InboxMessages;
