/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation updateSubscription($planId: Int!, $couponCode: String) {
    updateSubscription(planId: $planId, couponCode: $couponCode) {
      result
      company {
        id
        categoryLimit
      }
      errors
    }
  }
`;
