/* @flow */
/* global EventHandler */
import React from "react";
import Approve from "../buttons/Approve";
import Delete from "../buttons/Delete";
import Edit from "../buttons/Edit";
import styles from "./index.css";

type Props = { contentId: string };

const Rejected = ({ contentId }: Props) => (
  <div className={styles.root}>
    <Edit contentId={contentId} type="secondary" />
    <Approve contentId={contentId} />
    <Delete contentId={contentId} />
  </div>
);

export default Rejected;
