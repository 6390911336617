// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";
import Icon from "icons/Icon";
import Caption from "typography/Caption";

export const Tooltip = () => <Icon type="info-circle" color="textMid" />;

const TipBody = () => (
  <Caption textAlign="center">
    Reconnect your personal Instagram account as a business account to publish
    single image posts and videos to Instagram automatically without mobile
    reminder notifications.
  </Caption>
);

export default tooltipper(TipBody)(Tooltip);
