// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";

const TooltippedRestoreLink = ({ children }) => children;

const TipBody = () => (
  <div>Remove media attachments to attach a link preview.</div>
);

export default tooltipper(TipBody)(TooltippedRestoreLink);
