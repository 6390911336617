// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";
import Icon from "icons/Icon";

import styles from "./index.css";

const TooltippedIcon = ({
  iconType = "info-circle",
  iconColor = "textMid"
}) => <Icon type={iconType} color={iconColor} />;

const TipBody = ({ tipBody }) => (
  <div className={styles.tipBody}>{tipBody}</div>
);

const closeDelay = 700;
export default tooltipper(TipBody, { closeDelay })(TooltippedIcon);
