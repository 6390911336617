/* @flow */
import { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import moment from "moment";

import CREATE_COMMENT from "mutations/createComment";

import type { IProps } from "./types.js";

const useComment = (props: IProps) => {
  const apolloClient = useApolloClient();
  const [showWriteReply, setShowWriteReply] = useState(false);
  const [newReply, setNewReply] = useState("");
  const [creatingReply, setCreatingReply] = useState(false);
  const [newReplies, setNewReplies] = useState([]);
  const [repliesOrder, setRepliesOrder] = useState("ASC");
  const [error, setError] = useState<string>("");

  const toggleWriteReply = () => setShowWriteReply(!showWriteReply);

  const toggleRepliesOrder = () =>
    setRepliesOrder("ASC" === repliesOrder ? "DESC" : "ASC");

  const handleReplyChange = (e: any) => {
    setError("");
    setNewReply(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key == "Enter" || e.keyCode == 13) {
      createReply();
    }
  };

  const createReply = async () => {
    setError("");
    if (newReply.length > 8000) return false;
    if (!newReply.trim().length || creatingReply) return false;

    const { item, post } = props;
    setCreatingReply(true);

    try {
      const res: any = await apolloClient.mutate({
        mutation: CREATE_COMMENT,
        variables: {
          postId: parseInt(post.id),
          commentBody: newReply,
          commentId: item.id
        }
      });

      if (res.data.createComment.id) {
        const mountedReply = {
          id: res.data.createComment.id,
          message: newReply,
          createdTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          attachment: null,
          likeCount: 0,
          userLikes: false,
          from: {
            id: post.account.id,
            name: post.account.name,
            picture: post.account.image
          }
        };
        setNewReplies([...newReplies, mountedReply]);
        setShowWriteReply(false);
        setNewReply("");
      } else if (res.data.createComment.error) {
        setError(res.data.createComment.error);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } catch (e) {
    } finally {
      setCreatingReply(false);
    }
  };

  return {
    showWriteReply,
    toggleWriteReply,
    newReply,
    handleReplyChange,
    creatingReply,
    createReply,
    newReplies,
    repliesOrder,
    toggleRepliesOrder,
    handleKeyPress,
    error
  };
};

export default useComment;
