/* @flow */
import React from "react";
import { withRouter } from "react-router-dom";
import Body from "typography/Body";
import Label from "components/common/typography/Label";
import Link from "links/Link";
import feedManagerIcon from "../../images/feed-manager-icon.svg";
import styles from "./index.css";

type Prop = { history: any };
export const NoFeeds = ({ history }: Prop) => (
  <div className={styles.noFeeds}>
    <img src={feedManagerIcon} alt="RSS Feed Manager" />
    <div className={styles.noFeedsMessage}>
      <Label>No RSS feeds connected.</Label>
      <br />
      <Body className={styles.body}>
        Add an RSS feed from the{" "}
        <Link onClick={() => history.push("/import")}>Add new</Link> tab. When
        you add an RSS feed, Edgar will go ahead and import content from that
        feed for you. You can have Edgar automatically add all of them into your
        library as he goes, or tell him to set them aside on{" "}
        <Link url="/contents/pending">your Pending Content page</Link>. (From
        your Pending Content page, you can choose which content to add to your
        library and which ones you’d rather reject!) Once you’ve added an RSS
        feed, Edgar will automatically check it for new stuff every day, so you
        can just kick back and watch your library of content grow!
      </Body>
    </div>
  </div>
);

export default withRouter(NoFeeds);
