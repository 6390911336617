/* @flow */
import React from "react";
import ReactTooltip from "react-tooltip";

import type { IProps } from "./types.js";
import styles from "./index.css";
import {
  Wrapper,
  ContentSentWrapper,
  TotalClicksWrapper,
  ImpressionsWrapper
} from "./styles";

import ContentSent from "./ContentSent";
import TotalClicks from "./TotalClicks";
import Impressions from "./Impressions";

const BoxesComponent = (props: IProps) => {
  const { filters, selectedAccount } = props;

  return (
    <Wrapper>
      <ContentSentWrapper>
        <ContentSent filters={filters} selectedAccount={selectedAccount} />
      </ContentSentWrapper>

      <TotalClicksWrapper>
        <TotalClicks filters={filters} selectedAccount={selectedAccount} />
      </TotalClicksWrapper>

      <ImpressionsWrapper>
        <Impressions filters={filters} selectedAccount={selectedAccount} />
      </ImpressionsWrapper>

      <ReactTooltip
        effect="float"
        place="left"
        type="light"
        backgroundColor="#fff"
        className={styles.tooltip}
        id="dashboard-tooltip"
      />
    </Wrapper>
  );
};

export default BoxesComponent;
