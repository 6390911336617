/* @flow */
import React from "react";
import { groupBy } from "lodash";
import Link from "links/Link";
import Icon from "icons/Icon";
import FeedUrl from "components/common/FeedUrl";
import CategoryName from "components/common/CategoryName";
import DropDownButton from "buttons/DropDownButton";
import colors from "colors";
import styles from "./index.css";

import type {
  getRssFeeds_rssFeeds as RssFeed,
  getRssFeeds_rssFeeds_accounts as Account
} from "graphql-types/getRssFeeds";

type Props = {
  rssFeed: RssFeed,
  onClickEdit: (id: string) => void,
  onClickDelete: (id: string) => void,
  onClickImport: (id: string) => void
};

export const Row = (props: Props) => {
  function onClickEdit() {
    const {
      rssFeed: { id }
    } = props;
    if (!id) {
      return;
    }
    props.onClickEdit(id);
  }

  function onClickDelete() {
    const {
      rssFeed: { id }
    } = props;
    if (!id) {
      return;
    }
    props.onClickDelete(id);
  }

  function onClickImport() {
    const {
      rssFeed: { id }
    } = props;
    if (!id) {
      return;
    }
    props.onClickImport(id);
  }

  const { rssFeed } = props;
  return (
    <tr className={styles.feedRow}>
      <td>
        <FeedName name={rssFeed.name} />
        <FeedUrl url={rssFeed.url} />
      </td>
      <td className={styles.platformAccounts}>
        <Platforms accounts={rssFeed.accounts ?? []} />
      </td>
      <td className={styles.importTo}>
        <ImportTo requiresApproval={rssFeed.requiresApproval ?? false} />
      </td>
      <td>
        <CategoryName category={rssFeed.category} />
      </td>
      <td className={styles.actions}>
        <DropDownButton loading={false}>
          <Link menuLink onClick={onClickEdit}>
            <Icon type="pencil" /> Edit
          </Link>
          <Link menuLink onClick={onClickDelete}>
            <Icon type="trash" /> Delete
          </Link>
          <Link menuLink onClick={onClickImport}>
            <Icon type="download" /> Import Now
          </Link>
        </DropDownButton>
      </td>
    </tr>
  );
};

export default Row;

export const FeedName = ({ name }: { name: ?string }) =>
  name ? <span className={styles.feedName}>{name}</span> : null;

export const ImportTo = ({
  requiresApproval
}: {
  requiresApproval: boolean
}) => <span>{requiresApproval ? "Pending Content" : "Library"}</span>;

export const PlatformAccount = ({
  platform,
  accounts
}: {
  platform: string,
  accounts: Account[]
}) => (
  <span className={styles.platformAccount}>
    <span
      style={{ color: colors[platform.toLowerCase()] }}
      className={styles.platformIcon}
    >
      <Icon type={`${platform.toLowerCase()}-square`} />
    </span>
    <span className={styles.accountCount}>
      &nbsp;{accounts.length}&nbsp;
      {accounts.length === 1 ? "Account" : "Accounts"}
    </span>
  </span>
);

export const Platforms = ({ accounts }: { accounts: Account[] }) => {
  const accountsByPlatform = groupBy(
    accounts.filter(acct => !!acct),
    a => a.platform
  );
  return (
    <span>
      {Object.keys(accountsByPlatform).map(p => (
        <PlatformAccount
          key={p}
          platform={p}
          accounts={accountsByPlatform[p]}
        />
      ))}
    </span>
  );
};
