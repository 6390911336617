/* @flow */
import React from "react";
import get from "lodash/get";
import Icon from "../Icon";
import { colorFromIndex } from "colors";
import type { Category as CategoryType } from "graphql-types/category";

type Props = {
  category?: CategoryType
};

const Category = (props: Props) => {
  const { category } = props;
  const colorIndex = get(category, "colorIndex");
  const style = colorIndex ? { color: colorFromIndex(colorIndex) } : null;

  return <Icon type="circle" style={style} />;
};

export default Category;
