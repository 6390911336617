/* @flow */
import { useState, useEffect, useCallback, useRef } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import { client } from "lib/subscriptions";

import GET_FACEBOOK_CONVERSATION_THREAD from "../../../graphql/queries/getFacebookConversationThread";
import GET_INSTAGRAM_CONVERSATION_THREAD from "../../../graphql/queries/getInstagramConversationThread";
import NEW_MESSAGE_RECEIVED from "../../../graphql/subscriptions/newMessageReceived";

import type { getFacebookConversationThread_fetchFacebookConversationThreads as ConversationThread } from "../../../graphql/types/getFacebookConversationThread.js";
import type {
  Props,
  ConsationTypes,
  SubscriptionAttachments,
  SubscriptionMessage
} from "./types.js";

const useConversationThread = (props: Props) => {
  const { selectedThread, selectedAccount } = props;

  const containerRef = useRef<any>();

  const [lastThread, setLastThread] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(true);
  const [
    conversationThreads,
    setConversationThreads
  ] = useState<ConversationThread | null>(null);

  const apolloClient = useApolloClient();

  const scrollToBottom = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: "smooth"
        });
      }
    }, 100);
  };

  const fetchThreadConversation = useCallback(
    async (selectedThread: ConsationTypes, cursor: string | null = null) => {
      if (!selectedAccount || !selectedThread) return;

      try {
        if (cursor) setLoadingMore(true);
        else setLoading(true);

        const isInstagram = selectedAccount.platform === "INSTAGRAM";

        const res = await apolloClient.query({
          query: isInstagram
            ? GET_INSTAGRAM_CONVERSATION_THREAD
            : GET_FACEBOOK_CONVERSATION_THREAD,
          variables: {
            accountId: parseInt(selectedAccount.id),
            threadId: selectedThread.id,
            cursor: !!cursor ? cursor : ""
          },
          fetchPolicy: "network-only"
        });

        const loadedConversation = isInstagram
          ? res.data.fetchInstagramConversationThreads
          : res.data.fetchFacebookConversationThreads;

        const loadedCursor = isInstagram
          ? res.data.fetchInstagramConversationThreads.cursor
          : res.data.fetchFacebookConversationThreads.cursor;

        if (cursor) {
          setConversationThreads({
            ...conversationThreads,
            threads: [
              ...loadedConversation.threads,
              ...(conversationThreads?.threads || [])
            ],
            cursor: loadedCursor
          });
        } else {
          setConversationThreads(loadedConversation);
        }

        scrollToBottom();
      } catch (err) {
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    },
    [apolloClient, conversationThreads, selectedAccount]
  );

  // on select a new thread
  useEffect(() => {
    if (selectedThread && selectedThread.id !== lastThread) {
      fetchThreadConversation(selectedThread);
      setLastThread(selectedThread.id);
    }
  }, [
    apolloClient,
    fetchThreadConversation,
    lastThread,
    selectedAccount,
    selectedThread
  ]);

  const afterReplyCreate = useCallback(
    (
      messageId: string,
      message: ?string,
      sender: ?string,
      attachments?: SubscriptionAttachments
    ) => {
      if (!selectedThread || !selectedAccount) return;

      const verifyFromSender = sender || selectedThread.receiver.id;

      const from =
        verifyFromSender === selectedAccount.uid
          ? selectedThread.receiver
          : selectedThread.sender;

      const to =
        verifyFromSender === selectedAccount.uid
          ? selectedThread.sender
          : selectedThread.receiver;

      const newReply = {
        id: messageId,
        createdTime: moment().format(),
        message,
        from,
        to: [to],
        attachments: attachments
          ? attachments.map(a => ({
              name: "image from subscription",
              previewUrl: a.previewUrl,
              fileUrl: null,
              __typename: "ThreadAttachment"
            }))
          : null,
        shares: null,
        __typename: "Thread"
      };

      setConversationThreads({
        ...conversationThreads,
        threads: [
          // $FlowFixMe
          ...(conversationThreads?.threads ? conversationThreads.threads : []),
          { ...newReply }
        ]
      });

      scrollToBottom();
    }
  );

  // subscription for new messages
  useEffect(() => {
    if (!selectedAccount || !selectedThread) return;

    // $FlowFixMe
    const recipientId = selectedAccount.uid + "_" + selectedThread?.sender.id;

    const observable = client.subscribe({
      query: NEW_MESSAGE_RECEIVED,
      variables: { recipientId }
    });

    const subscription = observable.subscribe({
      next: ({ data }: { data: SubscriptionMessage }) => {
        console.log(data);
        if (data?.newMessageReceived?.message) {
          const { message } = data.newMessageReceived;
          afterReplyCreate(
            uuidv4(),
            message.message,
            message.from,
            message.attachments
          );
        }
      },
      error: err => console.error(err)
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [selectedThread, afterReplyCreate]);

  const loadOlderReplies = async () => {
    if (
      loadingMore ||
      !selectedThread ||
      !selectedAccount ||
      !conversationThreads
    )
      return;

    const cursor = conversationThreads.cursor;
    fetchThreadConversation(selectedThread, cursor);
    scrollToBottom();
  };

  return {
    loading,
    conversationThreads,
    afterReplyCreate,
    loadingMore,
    loadOlderReplies,
    containerRef
  };
};

export default useConversationThread;
