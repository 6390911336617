// @flow
import React, { Component } from "react";
import { omit } from "lodash";
import classNames from "classnames";
import getRectScalingValues from "lib/images/getRectScalingValues";
import styles from "./index.css";

export const DISPLAY_SIZE = 120;

type Props = {
  value: string,
  targetWidth: number,
  targetHeight: number,
  alt?: string,
  className?: string,
  forceScaleDimension?: "x" | "y",
  onLoad?: (width: number, height: number) => void
};

type State = {
  loaded: boolean,
  errorLoading: boolean,
  bgWidth: number,
  bgHeight: number,
  offsetX: ?number,
  offsetY: ?number
};

export default class ScaledImage extends Component<Props, State> {
  static defaultProps = {
    targetWidth: DISPLAY_SIZE,
    targetHeight: DISPLAY_SIZE
  };

  state = {
    loaded: false,
    errorLoading: false,
    bgWidth: -1,
    bgHeight: -1,
    offsetX: null,
    offsetY: null
  };

  handleLoad = ({ target }: Event) => {
    const {
      targetWidth,
      targetHeight,
      forceScaleDimension,
      onLoad
    } = this.props;
    if (target instanceof HTMLElement) {
      const { offsetWidth, offsetHeight } = target;
      const {
        width: bgWidth,
        height: bgHeight,
        offsetX,
        offsetY
      } = getRectScalingValues(
        offsetWidth,
        offsetHeight,
        targetWidth,
        targetHeight,
        forceScaleDimension
      );

      this.setState({ loaded: true, bgWidth, bgHeight, offsetX, offsetY });
      if (onLoad) {
        onLoad(offsetWidth, offsetHeight);
      }
    }
  };

  handleError = ({ _target }: Event) => {
    this.setState({ errorLoading: true });
  };

  render() {
    const {
      props: {
        value,
        targetWidth,
        targetHeight,
        forceScaleDimension,
        className,
        alt
      },
      state: { loaded, errorLoading, bgWidth, bgHeight, offsetX, offsetY }
    } = this;

    // If forcing the scale dimension and the other dimension is less than its target
    // once scaled, use the scaled value as the images size in that dimension. Otherwise
    // use its target which will act as the max in that dimension.
    const width =
      forceScaleDimension === "y" && bgWidth < targetWidth
        ? bgWidth
        : targetWidth;

    const height =
      forceScaleDimension === "x" && bgHeight < targetHeight
        ? bgHeight
        : targetHeight;

    let style = {
      width: `${width}px`,
      height: `${height}px`,
      backgroundImage: undefined,
      backgroundSize: undefined,
      backgroundPosition: undefined
    };

    if (loaded) {
      // || 0s are for flow
      style = Object.assign(style, {
        backgroundImage: `url('${value}')`,
        backgroundSize: `${bgWidth || 0}px ${bgHeight || 0}px`,
        backgroundPosition: `${offsetX || 0}px ${offsetY || 0}px`
      });
    }

    return (
      <div
        className={classNames(styles.root, { [className || ""]: !!className })}
        style={
          loaded
            ? style
            : omit(
                style,
                "backgroundImage",
                "backgroundSize",
                "backgroundPosition"
              )
        }
      >
        <img
          src={value}
          alt={alt}
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-5000px"
          }}
          role="presentation"
          onLoad={this.handleLoad}
          onError={this.handleError}
        />
        {errorLoading && <div className={styles.altText}>{alt}</div>}
      </div>
    );
  }
}
