/* @flow */
import React from "react";
import Paginator from "./Paginator";
import heart from "../images/heart.png";
import speechBubble from "../images/speech-bubble.png";
import paperPlane from "../images/paper-plane.png";
import bookmark from "../images/bookmark.png";

import styles from "./index.css";

type Props = {
  itemCount: number,
  activeItem?: number
};

export const Actions = ({ itemCount, activeItem = 0 }: Props) => (
  <div className={styles.root}>
    <img className={styles.instaButton} src={heart} />
    <img className={styles.instaButton} src={speechBubble} />
    <img className={styles.instaButton} src={paperPlane} />
    {itemCount > 1 && (
      <Paginator itemCount={itemCount} activeItem={activeItem} />
    )}
    <div className={styles.bookmark}>
      <img className={styles.instaButton} src={bookmark} />
    </div>
  </div>
);

export default Actions;
