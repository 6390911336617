// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";
import Icon from "icons/Icon";
import Caption from "typography/Caption";

export const Tooltip = () => <Icon type="info-circle" color="textMid" />;

const TipBody = () => (
  <Caption textAlign="center">
    Edgar’s schedule is based on how you categorize your content, so you can
    always post the right types of content at the right times. You can add your
    own category below or manage them all on the Categories page!
  </Caption>
);

export default tooltipper(TipBody)(Tooltip);
