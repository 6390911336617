/* @flow */
import { useEffect, useState, useCallback } from "react";
import type { loadComposer_categories as CategoryData } from "../../graphql/types/loadComposer.js";

import type { Props } from "./types.js";

const useComposer = (
  props: Props,
  onChangeVariationAccountText: (
    clientId: string,
    accountId: string,
    text?: string,
    removeFromUpdate?: boolean
  ) => void
) => {
  const { content } = props;

  const variations = content?.variations ?? [];
  const selectedAccounts = (content?.accountRelationships ?? []).map(
    rel => rel.account
  );

  const [visibleSteps, setVisibleSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false
  });

  const [step1Subtitle, setStep1Subtitle] = useState(null);
  const [step2Subtitle, setStep2Subtitle] = useState(null);
  const [step3Subtitle, setStep3Subtitle] = useState(null);
  const [step4Subtitle, setStep4Subtitle] = useState(null);

  const [step1Completed, setStep1Completed] = useState(false);
  const [step2Completed, setStep2Completed] = useState(false);
  const [step3Completed, setStep3Completed] = useState(false);
  const [step4Completed, setStep4Completed] = useState(false);

  const [hasEditing, setHasEditing] = useState(false);

  useEffect(() => {
    if (!hasEditing) return;
    // window.onbeforeunload = () => hasEditing;
  }, [hasEditing]);

  const toggleStep = (stepNumber: number) => {
    const newVisibleSteps = { ...visibleSteps };
    newVisibleSteps[`step${stepNumber}`] = !newVisibleSteps[
      `step${stepNumber}`
    ];
    setVisibleSteps(newVisibleSteps);
  };

  const handleNextStep = (currentStep: number) => {
    const newVisibleSteps = { ...visibleSteps };
    Object.keys(newVisibleSteps).forEach(key => {
      newVisibleSteps[key] = false;
    });
    newVisibleSteps[`step${currentStep + 1}`] = true;
    setVisibleSteps(newVisibleSteps);

    if (currentStep === 4) {
      setStep4Completed(true);
    }
  };

  const handleCategoryStep = useCallback(async (selection?: CategoryData) => {
    if (selection) {
      setHasEditing(true);
      setStep1Completed(true);
      setStep1Subtitle(selection.name);
      setVisibleSteps({
        ...visibleSteps,
        step1: false,
        step2: true,
        step3: false,
        step4: false
      });
      return await props.onChangeCategory(selection);
    } else {
      if (content?.category) setStep1Completed(true);
    }
  });

  useEffect(() => {
    handleCategoryStep();
  }, [content.category, handleCategoryStep]);

  const handleAccountsStep = useCallback(
    (type: "select" | "deselect" | null, accounts: string[]) => {
      const selectedAccounts = (content?.accountRelationships ?? []).map(
        rel => rel.account
      );

      if (type === "select") {
        setHasEditing(true);
        setStep2Completed(true);
        props.onSelectAccounts(accounts);
      } else if (type === "deselect") {
        // get the account ids that are being deselected
        // remove all of them from variationAccountTexts
        const deselectedAccountIds = accounts.map(accountId => {
          const account = selectedAccounts.find(
            account => account.id === accountId
          );
          return account.id;
        });
        if (deselectedAccountIds.length > 0) {
          (content?.variations ?? []).forEach(variation => {
            deselectedAccountIds.forEach(accountId => {
              onChangeVariationAccountText(
                variation.clientId,
                accountId,
                undefined,
                true
              );
            });
          });
        }

        if (
          selectedAccounts.length <= 1 ||
          accounts.length === selectedAccounts.length
        ) {
          setStep2Completed(false);
        }
        props.onDeselectAccounts(accounts);
        setHasEditing(true);
      } else {
        if (selectedAccounts.length > 0) {
          setStep2Completed(true);
        }
      }

      const numberOfAccounts =
        type === "select"
          ? selectedAccounts.length + accounts.length
          : selectedAccounts.length - accounts.length;
      const totalAccounts = props.accounts?.length || 0;
      setStep2Subtitle(
        `${numberOfAccounts}/${totalAccounts} account${
          totalAccounts > 1 ? "s" : ""
        } selected`
      );
    }
  );

  useEffect(() => {
    if (content?.accountRelationships?.length > 0) {
      handleAccountsStep(null, []);
    }
  }, [content, handleAccountsStep]);

  const handleVariations = () => {
    const variations = content?.variations ?? [];
    const numberOfVariations = variations.length;

    if (numberOfVariations > 0) {
      setStep3Subtitle(
        `${numberOfVariations} variation${numberOfVariations > 1 ? "s" : ""}`
      );

      let stepCompleted = true;
      variations.forEach(variation => {
        if (
          !variation.text &&
          variation.images.length === 0 &&
          variation.videos.length === 0
        ) {
          stepCompleted = false;
        }
      });
      setStep3Completed(stepCompleted);
      if (step1Completed) setHasEditing(true);
    }
  };

  useEffect(() => {
    handleVariations();
  }, [variations, handleVariations]);

  useEffect(() => {
    const openAtMount = content.expiresAt || content.useOnce;
    const useShortLinks = content.useShortLinks;
    const expiresAt = content.expiresAt;

    if (openAtMount || expiresAt != null || !useShortLinks || props.isEditing) {
      setStep4Completed(true);
      setHasEditing(true);
    }

    let newStep4Subtitle = "";
    if (useShortLinks) newStep4Subtitle += "Shorten links, ";
    if (expiresAt != null) newStep4Subtitle += "Expire content, ";
    if (content.useOnce) newStep4Subtitle += "Use once, ";

    if (newStep4Subtitle.length > 0) {
      setStep4Subtitle(newStep4Subtitle.slice(0, -2));
    }
  }, [
    content.expiresAt,
    content.useOnce,
    content.useShortLinks,
    props.isEditing
  ]);

  const clearScreen = useCallback(async () => {
    setStep1Subtitle(null);
    setStep1Completed(false);
    await props.onChangeCategory(null);

    handleAccountsStep("deselect", selectedAccounts.map(acc => acc.id));
    setStep2Subtitle(null);
    setStep2Completed(false);

    variations.map((variation, index) => {
      if (index === 0) {
        // First variation: do nothing
      } else {
        console.log("delete variation", variation.clientId);
        props.onDeleteVariation(variation.clientId);
      }
    });
    props.onFocusEditor(0, "text");
    setStep3Subtitle(null);
    setStep3Completed(false);

    setStep4Subtitle(null);
    setStep4Completed(false);

    setHasEditing(false);

    setVisibleSteps({ step1: true, step2: false, step3: false, step4: false });
  }, [handleAccountsStep, props, selectedAccounts, variations]);

  return {
    visibleSteps,
    toggleStep,
    step1Subtitle,
    step2Subtitle,
    step3Subtitle,
    step4Subtitle,
    step1Completed,
    step2Completed,
    step3Completed,
    step4Completed,
    handleCategoryStep,
    handleAccountsStep,
    handleNextStep,
    clearScreen
  };
};

export default useComposer;
