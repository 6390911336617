import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { get } from "lodash";

import Icon from "icons/Icon";
import App from "components/App";
import PageTitle from "links/PageTitle";
import NewHeader from "components/NewHeader";

import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import GET_ONBOARDING_PROGRESS from "queries/getOnboardingProgress";

import { useNewUICached, featureFlag } from "util";

import type { IProps, OwnProps } from "./types.js";
import { Wrapper, Box, AlertIcon } from "./styles";

import SVGUser from "./icons/account-details.svg";
import SVGImport from "./icons/import.svg";
import SVGCategories from "./icons/categories.svg";
import SVGAccounts from "./icons/accounts.svg";
import SVGHistory from "./icons/history.svg";

const SettingsBundle = (props: IProps) => {
  const useNewUI = useNewUICached();

  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      onboardingProgress={props.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      newWidth
      header={
        <>
          {useNewUI ? (
            <NewHeader title="Settings" {...props.topNav} />
          ) : (
            <PageTitle>Settings</PageTitle>
          )}
        </>
      }
      newWidth
    >
      <Wrapper>
        <Box href="/settings" title="Go to page">
          <img src={SVGUser} alt="user" />
          <h3>Account details</h3>
          <p>
            Update your personal details, account preferences, and subscription
            plan here.
          </p>
        </Box>

        <Box href="/import" title="Go to page">
          <img src={SVGImport} alt="import" />
          <h3>Import</h3>
          <p>
            Import content from blogs, RSS feeds,{" "}
            {featureFlag("shopify_ui") ? "Shopify stores, " : ""}and more.
          </p>
        </Box>

        <Box href="/categories" title="Go to page">
          <img src={SVGCategories} alt="categories" />
          <h3>Categories</h3>
          <p>
            Create new categories, edit existing categories, add categories into
            the 'Random' posting rotation, or shuffle a category's content.
          </p>
        </Box>

        <Box href="/accounts" title="Go to page">
          {props?.topNav?.companyStatus?.hasDisabledAccounts && (
            <AlertIcon>
              <Icon type="exclamation-circle" />
            </AlertIcon>
          )}
          <img src={SVGAccounts} alt="social-accounts" />
          <h3>Social media accounts</h3>
          <p>Connect or delete social media accounts.</p>
        </Box>

        <Box href="/history" title="Go to page">
          <img src={SVGHistory} alt="history" />
          <h3>History</h3>
          <p>
            View a history of posts Edgar has published and view individual
            content performance for each post.
          </p>
        </Box>
      </Wrapper>
    </App>
  );
};

const SettingsBundleWrapper = (ownProps: OwnProps) => {
  const { data } = useQuery(GET_ONBOARDING_PROGRESS);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);

  const onboardingProgress = get(data, "user.onboardingProgress");

  return (
    <SettingsBundle
      {...ownProps}
      onboardingProgress={onboardingProgress}
      dismissOnboarding={dismissOnboarding}
    />
  );
};

export default SettingsBundleWrapper;
