// @flow
import React from "react";
import styles from "./index.css";

import type { Account } from "types";

type Props = { value: Account };

export const ByLine = ({ value: { name, image } }: Props) => {
  return (
    <div className={styles.root}>
      <img className={styles.image} src={image} alt="avatar" />
      <div className={styles.byLine}>
        <strong className={styles.name}>{name}</strong>{" "}
        <span className={styles.followers}>many followers</span>
      </div>
      <div className={styles.followButton}>Follow</div>
    </div>
  );
};

export default ByLine;
