/* @flow */

import type { Account } from "types";

const hasMultipleTwitterAccounts = (accounts: Account[]) => {
  if (!accounts) {
    return false;
  }

  const twitterAccounts = accounts.filter(
    account => account.platform === "twitter" || account.platform === "TWITTER"
  );

  return twitterAccounts.length > 1;
};

export default hasMultipleTwitterAccounts;
