/* @flow */
import React, { useState, useCallback } from "react";
import Confirmation from "layout/modals/Confirmation";
import UpgradeModal from "components/UpgradeModal";

const LITE_PLAN_ID = "eddie_lite_monthly_plan";

type Props = {
  limit: number,
  planId: string
};

const AccountLimitModal = ({ limit, planId }: Props) => {
  const [show, setShow] = useState(true);
  const handleClose = useCallback(() => setShow(false), [setShow]);

  if (!show) {
    return null;
  }

  return planId === LITE_PLAN_ID ? (
    <UpgradeModal
      name="accountLimitReached"
      onClose={handleClose}
      title="Upgrade now to add a new account!"
      subTitle="Your Eddie plan allows for 5 social accounts. Need more? Upgrade to the Edgar plan and get 25 social accounts, plus unlimited categories and 1000 time slots."
    />
  ) : (
    <ContactSupportModal onClose={handleClose} limit={limit} />
  );
};

type ContactSupportProps = {
  limit: number,
  onClose: () => void
};

const ContactSupportModal = ({ onClose, limit }: ContactSupportProps) => {
  const handleConfirm = useCallback(() => {
    window.location.href = "/settings";
    onClose();
  }, [onClose]);

  return (
    <Confirmation
      autoFocus={false}
      title="You're out of accounts!"
      subTitle={`A single Edgar account can have a maximum of ${limit.toLocaleString()} social accounts. Please go to settings page and add additional accounts.`}
      type="info"
      confirmLabel="Go to settings page"
      onConfirm={handleConfirm}
      onClose={onClose}
    />
  );
};

export default AccountLimitModal;
