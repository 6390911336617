/* eslint-disable flowtype/require-valid-file-annotation */
import React from "react";
import Row from "./Row";
import styles from "./index.css";

import type { getRssFeeds_rssFeeds as RssFeed } from "graphql-types/getRssFeeds";

const Table = ({
  rssFeeds,
  onClickEditFeed,
  onClickDeleteFeed,
  onClickImportFeed
}: {
  rssFeeds: RssFeed[],
  onClickEditFeed: (id: string) => void,
  onClickDeleteFeed: (id: string) => void,
  onClickImportFeed: (id: string) => void
}) => (
  <table className={styles.root}>
    <thead>
      <tr>
        <th>Feed</th>
        <th>Accounts</th>
        <th>Import To</th>
        <th>Category</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {rssFeeds.map(f => (
        <Row
          key={f.id}
          rssFeed={f}
          onClickEdit={onClickEditFeed}
          onClickDelete={onClickDeleteFeed}
          onClickImport={onClickImportFeed}
        />
      ))}
    </tbody>
  </table>
);

export default Table;
