/* @flow */
import React, { useState } from "react";
import classNames from "classnames";
import { featureFlag } from "util";

import H3 from "typography/H3";
import Button from "buttons/Button";
import Label from "typography/Label";
import Icon from "icons/Icon";
import CreateInvitation from "./CreateInvitation";
import UpdateInvitation from "./UpdateInvitation";
import DeleteInvitation from "./DeleteInvitation";
import DeleteTeamMember from "./DeleteTeamMember";

import styles from "./index.css";

import type {
  createInvitation as CreateInvitationResult,
  createInvitationVariables
} from "graphql-types/createInvitation";

import type {
  deleteInvitation as DeleteInvitationResult,
  deleteInvitationVariables
} from "graphql-types/deleteInvitation";

import type {
  deleteTeamMember as DeleteTeamMemberResult,
  deleteTeamMemberVariables
} from "graphql-types/deleteTeamMember";

import type {
  getUserSettings_teamMembers as TeamMember,
  getUserSettings_user as User
} from "graphql-types/getUserSettings";

import type { FlashType } from "reducers/flash";

import type {
  updateInvitation as UpdateInvitationResult,
  updateInvitationVariables
} from "graphql-types/updateInvitation";

type Props = {
  createInvitation?: ({
    variables: createInvitationVariables
  }) => Promise<{ data: CreateInvitationResult }>,
  createInvitationLoading?: boolean,
  deleteInvitation?: ({
    variables: deleteInvitationVariables
  }) => Promise<{ data: DeleteInvitationResult }>,
  deleteInvitationLoading?: boolean,
  deleteTeamMember?: ({
    variables: deleteTeamMemberVariables
  }) => Promise<{ data: DeleteTeamMemberResult }>,
  deleteTeamMemberLoading?: boolean,
  flash: (type: FlashType, body: string) => void,
  teamMembers?: TeamMember[],
  updateInvitation?: ({
    variables: updateInvitationVariables
  }) => Promise<{ data: UpdateInvitationResult }>,
  updateInvitationLoading?: boolean,
  user?: User
};

type ActionType =
  | "None"
  | "CreateInvitation"
  | "UpdateInvitation"
  | "DeleteInvitation"
  | "DeleteTeamMember";

type ActionProps = {
  action: ActionType,
  close: () => void,
  createInvitation?: ({
    variables: createInvitationVariables
  }) => Promise<{ data: CreateInvitationResult }>,
  createInvitationLoading?: boolean,
  currentRole: ?string,
  deleteInvitation?: ({
    variables: deleteInvitationVariables
  }) => Promise<{ data: DeleteInvitationResult }>,
  deleteInvitationLoading?: boolean,
  deleteTeamMember?: ({
    variables: deleteTeamMemberVariables
  }) => Promise<{ data: DeleteTeamMemberResult }>,
  deleteTeamMemberLoading?: boolean,
  flash: (type: FlashType, body: string) => void,
  teamMemberId: ?string,
  teamMemberEmail: ?string,
  teamMemberInvitationStatus: ?string,
  updateInvitation?: ({
    variables: updateInvitationVariables
  }) => Promise<{ data: UpdateInvitationResult }>,
  updateInvitationLoading?: boolean
};

const INVITATION_SENT = "sent";
const INVITATION_ACCEPTED = "accepted";
const MAX_SPOTS_AVAILABLE = 20; // TODO: Get max seats from the backend based on the subscription plan
const OWNER_ROLE = "owner";

export const Action = (props: ActionProps) => {
  switch (props.action) {
    case "CreateInvitation":
      return (
        <CreateInvitation
          close={props.close}
          createInvitation={props.createInvitation}
          createInvitationLoading={props.createInvitationLoading}
          flash={props.flash}
        />
      );
    case "UpdateInvitation":
      return props.currentRole && props.teamMemberId ? (
        <UpdateInvitation
          close={props.close}
          currentRole={props.currentRole}
          flash={props.flash}
          teamMemberEmail={props.teamMemberEmail}
          teamMemberId={props.teamMemberId}
          updateInvitation={props.updateInvitation}
          updateInvitationLoading={props.updateInvitationLoading}
        />
      ) : null;
    case "DeleteInvitation":
      return props.teamMemberId ? (
        <DeleteInvitation
          close={props.close}
          deleteInvitation={props.deleteInvitation}
          deleteInvitationLoading={props.deleteInvitationLoading}
          flash={props.flash}
          teamMemberEmail={props.teamMemberEmail}
          teamMemberId={props.teamMemberId}
        />
      ) : null;
    case "DeleteTeamMember":
      return props.teamMemberId &&
        props.teamMemberInvitationStatus === INVITATION_ACCEPTED ? (
        <DeleteTeamMember
          close={props.close}
          deleteTeamMember={props.deleteTeamMember}
          deleteTeamMemberLoading={props.deleteTeamMemberLoading}
          flash={props.flash}
          teamMemberEmail={props.teamMemberEmail}
          teamMemberId={props.teamMemberId}
        />
      ) : null;
    default:
      return null;
  }
};

const TeamManager = (props: Props) => {
  const [action, setAction] = useState<ActionType>("None");
  const [selectedTeamMember, setSelectedTeamMember] = useState<?TeamMember>(
    null
  );

  const close = (): void => {
    setAction("None");
    setSelectedTeamMember(null);
  };

  const renderActions = teamMember => {
    if (teamMember.role === OWNER_ROLE) {
      return null;
    }

    const entity =
      teamMember.invitationStatus === INVITATION_ACCEPTED
        ? { name: "TeamMember", action: "DeleteTeamMember" }
        : { name: "Invitation", action: "DeleteInvitation" };

    return (
      <ul className={styles.actionsUponTeamMember}>
        <li
          className={styles.deleteInvitationWrapper}
          title={`Delete ${entity.name}`}
        >
          <Icon
            id={`delete-${entity.name}`}
            color="danger"
            onClick={(): void => {
              setAction(entity.action);
              setSelectedTeamMember(teamMember);
            }}
            type="trash"
          />
        </li>
        {/* <li
          className={styles.updateInvitationWrapper}
          title={`Update ${entity}`}
        >
          <Icon
            color="danger"
            onClick={(): void => {
              setAction(`Update${entity}`);
              setSelectedTeamMember(teamMember);
            }}
            type="edit"
          />
        </li> */}
      </ul>
    );
  };

  if (!featureFlag("multi_user")) {
    return null;
  }

  return (
    <>
      <Action
        action={action}
        close={close}
        createInvitation={props.createInvitation}
        createInvitationLoading={props.createInvitationLoading}
        currentRole={selectedTeamMember?.role}
        deleteInvitation={props.deleteInvitation}
        deleteInvitationLoading={props.deleteInvitationLoading}
        deleteTeamMember={props.deleteTeamMember}
        deleteTeamMemberLoading={props.deleteTeamMemberLoading}
        flash={props.flash}
        teamMemberEmail={selectedTeamMember?.email}
        teamMemberId={selectedTeamMember?.id}
        teamMemberInvitationStatus={selectedTeamMember?.invitationStatus}
        updateInvitation={props.updateInvitation}
        updateInvitationLoading={props.updateInvitationLoading}
      />
      <div id="team-manager" className={styles.teamManager}>
        <div className={styles.header}>
          <H3>
            Team{" "}
            {props.teamMembers && (
              <span
                className={classNames(styles.spotsAvailable, {
                  [styles.noSpotsAvailable]:
                    props.teamMembers.length >= MAX_SPOTS_AVAILABLE
                })}
              >
                (
                {props.teamMembers.length < MAX_SPOTS_AVAILABLE ? (
                  <>
                    {MAX_SPOTS_AVAILABLE - props.teamMembers.length} of{" "}
                    {MAX_SPOTS_AVAILABLE} spots available
                  </>
                ) : (
                  <>no spots available</>
                )}
                )
              </span>
            )}
          </H3>
          <Button
            className={styles.inviteButton}
            disabled={
              !props.teamMembers ||
              props.teamMembers.length >= MAX_SPOTS_AVAILABLE
            }
            onClick={(): void => {
              setAction("CreateInvitation");
              setSelectedTeamMember(null);
            }}
            type="primary"
          >
            Invite team member
          </Button>
        </div>
        {props.teamMembers &&
          [...props.teamMembers]
            .sort(
              (teamMemberA: TeamMember, teamMemberB: TeamMember): number => {
                // Teams should only have a single "owner" I believe, but just in case we
                // always want to push the owners to the front. Then we'll sort by email
                // address.
                if (
                  teamMemberA.role === OWNER_ROLE &&
                  teamMemberB.role !== OWNER_ROLE
                ) {
                  return -1;
                }

                if (
                  teamMemberA.role !== OWNER_ROLE &&
                  teamMemberB.role === OWNER_ROLE
                ) {
                  return 1;
                }

                // Different team member's shouldn't share the same email, so skipping
                // the `0` case.
                return teamMemberA.email < teamMemberB.email ? -1 : 1;
              }
            )
            .map(teamMember => {
              return (
                <div className={styles.teamMember} key={teamMember.id}>
                  <Label
                    className={classNames(styles.teamMemberEmail, {
                      [styles.teamMemberPending]:
                        teamMember.invitationStatus === INVITATION_SENT
                    })}
                    title={teamMember.email}
                  >
                    {teamMember.email}
                  </Label>
                  <div
                    className={classNames({
                      [styles.teamMemberPending]:
                        teamMember.invitationStatus === INVITATION_SENT
                    })}
                  >
                    <span className={styles.teamMemberRole}>
                      {teamMember.role}
                    </span>{" "}
                    {teamMember.invitationStatus === INVITATION_SENT && (
                      <>(pending)</>
                    )}
                  </div>

                  {renderActions(teamMember)}
                </div>
              );
            })}
      </div>
    </>
  );
};

export default TeamManager;
