/* @flow */
import React, { useState } from "react";
import styles from "./index.css";
import Modal from "components/common/layout/modals/Modal";
import { Button, CloseButton } from "components/common/buttons";
import PreviewAccountSelect from "./PreviewAccountSelect";
import Post from "./Post";
import NullState from "./NullState";
import type {
  loadContent_content as ContentData,
  loadContent_content_variations as VariationData,
  Platform
} from "graphql-types/loadContent";
import type { scrapeUrl_scrapeUrl_pageScrape as PageScrapeData } from "graphql-types/scrapeUrl";
import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";
import { H4 } from "components/common/typography";
import { last } from "lodash";

type Props = {
  className: string,
  title: string,
  selectedAccounts: AccountData[],
  selectedPlatforms: Platform[],
  selectedPinterestBoardsByAccount: { [key: string]: string[] },
  content: ContentData,
  variationData: VariationData,
  onClick: () => void,
  closeButtonClicked: () => void,
  staticPositioned?: boolean,
  attachingImage: boolean,
  pageScrape: PageScrapeData
};

const PostPreview = ({
  closeButtonClicked,
  selectedAccounts,
  selectedPlatforms,
  selectedPinterestBoardsByAccount,
  variationData,
  content,
  pageScrape
}: Props) => {
  const [
    selectedAccountIdForPreview,
    setSelectedAccountIdForPreview
  ] = useState(() => {
    const mostRecentSelectedAccount = last(selectedAccounts);
    if (mostRecentSelectedAccount && mostRecentSelectedAccount.id) {
      return mostRecentSelectedAccount.id;
    }
    return null;
  });

  return (
    <Modal
      className={styles.root}
      title="Post Preview"
      footer={
        <Button type="primary" onClick={closeButtonClicked}>
          Close
        </Button>
      }
      autoFocus
    >
      <CloseButton onClick={closeButtonClicked} />

      <div className={styles.accountSelectContainer}>
        <H4 className={styles.accountSelectContainerHeader}>Select account</H4>
        <PreviewAccountSelect
          className={styles.previewAccountSelect}
          selectedAccounts={selectedAccounts}
          selectedPlatforms={selectedPlatforms}
          previewAccountId={selectedAccountIdForPreview}
          onChange={setSelectedAccountIdForPreview}
        />
      </div>

      {selectedAccountIdForPreview ? (
        <div className={styles.postContainer}>
          <Post
            account={selectedAccounts.find(acc => {
              return acc.id === selectedAccountIdForPreview;
            })}
            variation={variationData}
            content={content}
            pageScrape={pageScrape}
            selectedPinterestBoardId={
              selectedPinterestBoardsByAccount?.[
                selectedAccountIdForPreview
              ]?.[0]
            }
            //mentions={this.props.mentionNames}
            // mentions are derived from rawRichTextEntityMap values, see the old test here: assets/selectors/contentComposer/composerMentionNames/index.spec.js
            // we should calculate these here and pass them in; the previous method used redux state via this selector code: assets/selectors/contentComposer/composerMentionNames/index.js
          />
        </div>
      ) : (
        <div className={styles.nullStateContainer}>
          <NullState selectedAccounts={selectedAccounts} />
        </div>
      )}
    </Modal>
  );
};

export default PostPreview;
