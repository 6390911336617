/* @flow */
import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";

import CategoryActionModal from "components/Categories/CategoryActionModal";

import { CATEGORIES_SELECTOR } from "queries/category";
import { CREATE_CATEGORY } from "mutations/category";

import type { Props } from "./types.js";

const AddCategoryModal = ({ hideModal, onAddedCategory }: Props) => {
  const [addModalErrors, setAddModalErrors] = useState([]);

  const [createCategory] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [{ query: CATEGORIES_SELECTOR }],
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true,
    onCompleted: ({ createCategory: { category, errors } }) => {
      setAddModalErrors(errors);

      if (errors.length) {
        return;
      }
      hideModal();
      onAddedCategory(category);
    }
  });

  const handleCreateCategory = (_categoryId, name, useForRandom) => {
    createCategory({
      variables: {
        input: {
          name,
          useForRandom
        }
      }
    });
  };

  return (
    <CategoryActionModal
      errors={addModalErrors}
      handleClose={hideModal}
      handleSubmit={handleCreateCategory}
    />
  );
};

export default AddCategoryModal;
