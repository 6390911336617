import styled from "styled-components";

export const WeekSelect = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 36px;
`;

export const LeftIcon = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px 0 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  width: 36px;

  &:hover {
    background: white;
    border-color: rgba(0, 0, 0, 0.25);
  }
`;

export const RightIcon = styled.div`
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px 0 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.18);
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  width: 36px;

  &:hover {
    background: white;
    border-color: rgba(0, 0, 0, 0.25);
  }

  transform: rotate(180deg);
`;

export const WeekCenter = styled.div`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  text-transform: uppercase;
  width: 84px;
`;
