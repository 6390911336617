/* @flow */
import React from "react";

import type { IProps } from "./types.js";

import SwitchToNew from "./SwitchToNew";
import SwitchToOld from "./SwitchToOld";

const NewUISwitch = (props: IProps) => {
  const { toShow } = props;

  return toShow === "new" ? (
    <SwitchToNew />
  ) : toShow === "old" ? (
    <SwitchToOld />
  ) : null;
};

export default NewUISwitch;
