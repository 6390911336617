/* @flow */
import React from "react";
import type { Account } from "types";

import styles from "./index.css";

type Props = {
  account: Account
};

export const HeaderText = ({ account }: Props) => (
  <div className={styles.root}>{account.nickname}</div>
);

export default HeaderText;
