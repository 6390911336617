/* @flow */
import React from "react";
import classNames from "classnames";

import styles from "./index.css";

type Props = {
  className?: string,
  isAuto: boolean
};

const LoadingBar = ({ className = "", isAuto }: Props) => {
  return (
    <div
      className={classNames(styles.container, {
        [className]: Boolean(className)
      })}
    >
      <div
        className={classNames(styles.bar, {
          [styles.barAuto]: isAuto
        })}
      />
    </div>
  );
};

export default LoadingBar;
