/* @flow */
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import { CATEGORIES_SELECTOR } from "queries/category";

import type { loadComposer_categories as CategoryData } from "graphql-types/loadComposer";

import type { Props } from "./types.js";

const useCategorySelect = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState(null);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      try {
        const res = await apolloClient.query({
          query: CATEGORIES_SELECTOR
        });

        setCategories(get(res, "data.categories", []));
      } catch (err) {
        setCategories([]);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [apolloClient]);

  const onAddedCategory = (category: CategoryData) => {
    setCategories([...categories, category]);
    setNewCategory(category);
  };

  useEffect(() => {
    if (!newCategory) return;
    props.onChange({ ...newCategory, isNew: true });
    setNewCategory(null);
  }, [newCategory, props]);

  return { loading, categories, onAddedCategory };
};

export default useCategorySelect;
