/* @flow */
import { useState, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { useTrack } from "util";

import LOAD_SUBSCRIPTION_MANAGER from "queries/loadSubscriptionManager";
import UPDATE_SUBSCRIPTION from "mutations/updateSubscription";

import type { Props } from "./types.js";

const DEFAULT_UPGRADE_PLAN = "edgar_monthly_plan";
// edgar_49s

const useUpgradeModal = (props: Props) => {
  const { flash, onUpgradeSuccessful, name } = props;
  const track = useTrack();

  const [selectedStripeId, setSelectedStripeId] = useState<string>(
    DEFAULT_UPGRADE_PLAN
  );

  const { loading, data } = useQuery(LOAD_SUBSCRIPTION_MANAGER);
  const company = data?.user?.company;

  const [
    updateSubscription,
    { loading: updateSubscriptionLoading }
  ] = useMutation(UPDATE_SUBSCRIPTION, {
    onError() {
      flash(
        "error",
        "An error occurred when attempting to update your subscription."
      );
    },
    onCompleted(res) {
      if (res?.updateSubscription?.errors?.length === 0) {
        track("Upgraded from modal", { modal: name });
        onUpgradeSuccessful?.();
      }
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });

  const handleSelectPlan = useCallback(
    (plan: any) => {
      console.log("remove this line", plan);
      setSelectedStripeId(plan.stripeId);
    },
    [setSelectedStripeId]
  );

  const selectedPlan = (company?.availableSubscriptionPlans || []).find(
    p => p.stripeId === selectedStripeId
  );

  return {
    loading,
    company,
    handleSelectPlan,
    selectedPlan,
    updateSubscription,
    updateSubscriptionLoading
  };
};

export default useUpgradeModal;
