/* @flow */
import color from "color";

// The original Ruby helper that the colorFromIndex method in this
// module corresponds to (ApplicationHelper.color_from_index) has
// some unusual output due to an off-by-one wonkiness in how it indexes
// things - for the Ruby version the last element in its HTML_COLORS
// array is returned for color index parameters 0. So contrary to
// what you might expect, here we put the *last* color in the
// corresponding Ruby array first to maintain consistent behavior.
//
const categories: string[] = [
  "#646769",
  "#8480be",
  "#258baf",
  "#7bb979",
  "#dd4b6f",
  "#ffa000",
  "#461c68",
  "#003079",
  "#1f7772",
  "#850128",
  "#ff792d",
  "#3a3c3e",
  "#00796b",
  "#9e9d24",
  "#e64a19"
].map(c => color(c).rgb());

export const colors = {
  categories,
  facebook: color("rgb(59, 89, 152)").rgb(),
  twitter: color("#55acee").rgb(),
  linkedin: color("#0077b5").rgb(),
  inky100: color("rgba(0, 0, 0, 0.06)").rgb(),
  inky200: color("rgba(0, 0, 0, 0.18)").rgb(),
  inky300: color("rgba(0, 0, 0, 0.32)").rgb(),
  inky400: color("rgba(0, 0, 0, 0.65)").rgb(),
  inky500: color("rgba(0, 0, 0, 0.87)").rgb(),
  inky600: color("rgb(0, 0, 0)").rgb(),
  kelp100: color("#BAD5D3").rgb(),
  kelp200: color("#97CCCE").rgb(),
  kelp300: color("#6CBDC5").rgb(),
  kelp400: color("#479D9D").rgb(),
  kelp500: color("#398180").rgb(),
  whale100: color("#D9E6F1").rgb(),
  whale200: color("#A3C5DF").rgb(),
  whale300: color("#65A8C7").rgb(),
  whale400: color("#268BAF").rgb(),
  whale500: color("#226F98").rgb(),
  algae200: color("#D1F7D0").rgb(),
  algae300: color("#7BB979").rgb(),
  algae400: color("#3E703C").rgb(),
  starfish300: color("#FCF8E3").rgb(),
  starfish400: color("#F6CC50").rgb(),
  urchin300: color("#8480BE").rgb(),
  urchin400: color("#574980").rgb(),
  coral200: color("#F2DEDE").rgb(),
  coral300: color("#DC6767").rgb(),
  coral400: color("#DD4B6F").rgb(),
  coral500: color("#CA4263").rgb(),
  coral600: color("#A42A3D").rgb()
};

export const colorFromIndex = (i: number): string =>
  categories[i % categories.length];

export default colors;
