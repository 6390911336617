// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  children: Node,
  className?: string
};

const H5 = ({ children, className }: Props) => (
  <h5 className={classNames(styles.root, { [className || ""]: !!className })}>
    {children}
  </h5>
);

export default H5;
