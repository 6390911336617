import styled from "styled-components";

export const mobileBreakpoint = 500;
export const tabletBreakpoint = 680;

export const Topbar = styled.div`
  align-items: center;
  margin: 25px 0 0;

  @media (min-width: ${tabletBreakpoint}px) {
    display: flex;
    justfiy-content: space-between;
  }
`;

export const Title = styled.div`
  border-bottom: 2px solid rgba(200, 200, 204, 1);
  display: none;
  font-size: 20px;
  font-weight: 600;

  @media (min-width: ${tabletBreakpoint}px) {
    display: block;
  }
`;

export const Filters = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;

  @media (min-width: ${mobileBreakpoint}px) {
    flex-direction: row;
  }
`;

export const DateRangeWrapper = styled.div`
  width: 100%;

  @media (min-width: ${tabletBreakpoint}px) {
    width: auto;
  }
`;
