/* @flow */
import React from "react";
import { parseFilterParams, handlePageChange } from "behavioral/withFilter";

import tooltipper from "behavioral/tooltipper";
import { LoadingIcon } from "icons";
import H2 from "typography/H2";
import Paginator from "components/Paginator";
import Caption from "typography/Caption";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import Category from "./Category";
import SentAt from "./SentAt";
import Account from "./Account";
import Content from "./Content";

import type { Match, Location, RouterHistory } from "react-router-dom";
import type { getPosts_posts_data as PostData } from "graphql-types/getPosts";

import styles from "./index.css";

const CLICKS_INFO =
  "See click data when you shorten with ed.gr or a personalized Rebrandly link. " +
  "It may take up to 24 hours for clicks to be reflected below.";

type Props = {
  history: RouterHistory,
  location: Location,
  match: Match,
  perPage?: number,
  loadingPosts: boolean,
  postError?: any,
  posts: PostData[],
  totalFilteredPosts?: number,
  totalPosts?: number,
  timezone?: string,
  refetchPosts: () => void
};

const PinterestCountTooltipBody = () => {
  return (
    <Caption textAlign="center">
      Edgar is unable to track clicks for Pinterest due to their rules
      prohibiting short links.
    </Caption>
  );
};

const PinterestCountTooltipTarget = () => {
  return <span>N/A</span>;
};

const PinterestCountTooltip = tooltipper(PinterestCountTooltipBody)(
  PinterestCountTooltipTarget
);

const PostsTable = ({
  history,
  location,
  match,
  perPage = 50,
  totalFilteredPosts = 0,
  totalPosts = 0,
  posts,
  postError,
  loadingPosts,
  refetchPosts,
  timezone
}: Props) => {
  const { page } = parseFilterParams(match, location);

  if (loadingPosts) {
    return (
      <>
        <LoadingIcon className={styles.loading} />
      </>
    );
  }

  if (postError) {
    return null;
  }

  if (totalFilteredPosts < 1) {
    return (
      <H2 className={styles.emptyState}>
        {totalPosts > 0
          ? "No content matching filters"
          : "Your history is empty right now."}
      </H2>
    );
  }

  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeaderRow}>
            <TableHeader
              className={styles.categoryColumn}
              field="category"
              title="Category"
            />
            <TableHeader field="sent_at" title="Sent at" />
            <TableHeader field="account" title="Account" />
            <TableHeader field="content" title="Content" />
            <TableHeader
              className={`${styles.statsColumn} ${styles.statsHeaderColumn}`}
              field="clicks"
              info={CLICKS_INFO}
              title="All time clicks"
            />
            <TableHeader
              className={`${styles.statsColumn} ${styles.statsHeaderColumn}`}
              field="times_sent"
              title="Times sent"
            />
            <TableHeader
              className={`${styles.statsColumn} ${styles.statsHeaderColumn}`}
              field="avg_clicks"
              title="Average clicks"
            />
          </tr>
        </thead>
        <tbody>
          {posts.map((post, idx) => {
            const numClicks = post.variation.shortLinks.reduce(
              (sum, { totalClicks }) => sum + totalClicks,
              0
            );
            return (
              <tr className={styles.tableBodyRow} key={idx}>
                <TableBody>
                  <Category post={post} />
                </TableBody>
                <TableBody className={styles.sentAtColumn}>
                  <SentAt post={post} timezone={timezone} />
                </TableBody>
                <TableBody>
                  <Account post={post} />
                </TableBody>
                <TableBody>
                  <Content post={post} />
                </TableBody>
                {post.variation ? (
                  <>
                    <TableBody className={styles.statsColumn}>
                      {post.account.provider.toUpperCase() === "PINTEREST" ? (
                        <PinterestCountTooltip />
                      ) : (
                        numClicks
                      )}
                    </TableBody>
                    <TableBody className={styles.statsColumn}>
                      {post.variation.timesSent}
                    </TableBody>
                    <TableBody className={styles.statsColumn}>
                      {post.variation.timesSent > 0
                        ? (numClicks / post.variation.timesSent).toFixed(1)
                        : null}
                    </TableBody>
                  </>
                ) : (
                  <td className={styles.statsColumn} colSpan="3" />
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Paginator
        page={page}
        pageCount={
          totalFilteredPosts ? Math.ceil(totalFilteredPosts / perPage) : null
        }
        onPageChange={handlePageChange.bind(
          null,
          match,
          location,
          history,
          refetchPosts,
          perPage
        )}
      />
    </>
  );
};

export default PostsTable;
