/* @flow */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gotoPath } from "util";
import { capitalize } from "lodash";
import Skeleton from "react-loading-skeleton";

import App from "components/App";
import Icon from "icons/Icon";
import Loading from "icons/LoadingIcon";
import ErrorWithRetry from "components/ErrorWithRetry";
import PlanSelector from "./PlanSelector";
import PromoCode from "./PromoCode";
import Testimonials from "./Testimonials";
import PaymentMethod from "components/PaymentMethod";
import AdditionalAccounts from "./AdditionalAccounts";
import useModalAddAccounts from "./AdditionalAccounts/use";
import Button from "buttons/Button";
import { H1, H2, Body } from "typography";

import VALIDATE_COUPON from "mutations/validateCoupon";
import SUBSCRIBE from "mutations/subscribe";
import LOAD_PAYMENT from "queries/loadPayment";
import GET_UPCOMING_INVOICE from "queries/getUpcomingInvoice";

import styles from "./index.css";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { validateCoupon_validateCoupon_coupon as CouponData } from "graphql-types/validateCoupon";
import type { loadPayment_company_availableSubscriptionPlans as SubscriptionPlanData } from "graphql-types/loadPayment";
import ExtraPriceForPLan from "./AdditionalAccounts/extra_account_price";

type OwnProps = {
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  coupon: ?CouponData,
  couponCode: ?string,
  amountDue: ?string,
  firstDueDate: ?string,
  loadingAmountDue: boolean,
  availablePlans: SubscriptionPlanData[],
  discountText: string,
  isCouponValid: boolean,
  isSaving: boolean,
  isValidatingCoupon: boolean,
  errors: string[],
  displayDiscount: boolean,
  selectedPlan: ?SubscriptionPlanData,
  loggedIn: boolean,
  admin: boolean,
  loading: boolean,
  loadError: boolean,
  validateCouponError: ?string,
  onApplyCoupon: (couponCode: string, planId: string) => Promise<void>,
  onRemoveCoupon: () => Promise<void>,
  onChangeCouponCode: (couponCode: string) => void,
  onComplete: (
    planId: string,
    token: Object,
    couponCode: ?string,
    total: number
  ) => Promise<void>,
  onChoosePlan: (plan: SubscriptionPlanData) => Promise<void>
} & OwnProps;

export const Payment = ({
  topNav,
  loading,
  loadError,
  coupon,
  couponCode,
  amountDue,
  firstDueDate,
  loadingAmountDue,
  subscriptionStatus,
  validateCouponError,
  availablePlans,
  isSaving,
  isValidatingCoupon,
  errors = [],
  displayDiscount,
  loggedIn,
  selectedPlan,
  isCouponValid,
  admin,
  onApplyCoupon,
  onRemoveCoupon,
  onChoosePlan,
  onChangeCouponCode,
  onComplete
}: Props) => {
  const paymentMethodRef = useRef(null);
  const [paymentMethodComplete, setPaymentMethodComplete] = useState(false);
  const handleComplete = useCallback(() => setPaymentMethodComplete(true), [
    setPaymentMethodComplete
  ]);
  const handleIncomplete = useCallback(() => setPaymentMethodComplete(false), [
    setPaymentMethodComplete
  ]);

  const { total, handleChange } = useModalAddAccounts();
  const { additionalPrice } = ExtraPriceForPLan(selectedPlan);

  const extraAccounts = additionalPrice * (parseFloat(total) || 0);

  const totalPrice = Number((parseFloat(amountDue)).toFixed(2));
  const onSubmit = useCallback(
    async evt => {
      evt.preventDefault();

      if (!paymentMethodComplete) {
        return;
      }

      if (!paymentMethodRef.current) {
        console.error("paymentMethodRef expectedto be present");
        return;
      }

      const stripeId = selectedPlan?.stripeId;
      if (!stripeId) {
        console.error("No plan selected!");
        return;
      }

      onComplete(
        stripeId,
        await paymentMethodRef.current.getToken(),
        isCouponValid ? couponCode : null,
        parseInt(total)
      );
    },
    [
      onComplete,
      paymentMethodRef,
      paymentMethodComplete,
      selectedPlan,
      isCouponValid,
      couponCode,
      total
    ]
  );
  const planInterval = selectedPlan?.interval;

  return (
    <App
      admin={admin}
      loggedIn={loggedIn}
      layout={"signup"}
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      hideSidebar
    >
      <div className={styles.root}>
        <div className={styles.testimonials} role="complementary">
          <Testimonials />
        </div>
        <div className={styles.testimonialsOffset} role="main">
          {loading ? (
            <Loading className={styles.loading} />
          ) : loadError ? (
            <ErrorWithRetry>Uh-oh something went wrong 😿</ErrorWithRetry>
          ) : (
            <div className={styles.formContainer}>
              <form onSubmit={onSubmit}>
                <div className={styles.header}>
                  <H1>Put Edgar to work for you</H1>
                  <Body>Choose the plan that is best for your business</Body>
                </div>
                <section className={styles.section}>
                  <PlanSelector
                    availablePlans={availablePlans}
                    selectedPlan={selectedPlan}
                    onChoosePlan={onChoosePlan}
                  />
                  
                  {displayDiscount && (
                    <PromoCode
                      value={couponCode}
                      coupon={coupon}
                      selectedPlan={selectedPlan}
                      loading={isValidatingCoupon}
                      error={validateCouponError}
                      onUpdateCouponCode={onChangeCouponCode}
                      onApply={onApplyCoupon}
                      onRemove={onRemoveCoupon}
                    />
                  )}
                  <PaymentMethod
                    ref={paymentMethodRef}
                    errors={errors}
                    onComplete={handleComplete}
                    onIncomplete={handleIncomplete}
                  />
                  {selectedPlan?.hasTrial && (
                    <small>
                      <Icon type="info-circle" /> Your credit card won't be
                      charged until after your free trial ends
                    </small>
                  )}
                </section>
                <section className={styles.section}>
                  <H2>Order summary</H2>
                  <div className={styles.orderSummary}>
                    <div className={styles.orderSummaryInterval}>
                      {!loadingAmountDue && planInterval ? (
                        `${capitalize(planInterval)}ly subscription`
                      ) : (
                        <Skeleton width={150} />
                      )}
                    </div>
                    <div className={styles.orderSummaryAmount}>
                      {!loadingAmountDue && amountDue ? (
                        `$${
                          selectedPlan?.hasTrial ? 0 : totalPrice
                        } due today`
                      ) : (
                        <Skeleton width={100} />
                      )}
                    </div>
                  </div>
                </section>
                <div className={styles.trialTextAmount}>
                  {!loadingAmountDue && selectedPlan?.hasTrial && (
                    `Your credit card will be charged $${totalPrice} on ${firstDueDate}`
                  )}
                </div>
                <section className={styles.section}>
                  <div className={styles.actions}>
                    <span className={styles.security}>
                      <Icon type="lock" size="2x" />
                      Secure 128-bit <br /> encryption
                    </span>
                    <Button
                      submit
                      type="primary"
                      loading={isSaving}
                      disabled={isSaving || !paymentMethodComplete}
                    >
                      Get started!
                    </Button>
                  </div>
                </section>
              </form>
            </div>
          )}
        </div>
      </div>
    </App>
  );
};

export default (ownProps: OwnProps) => {
  const displayDiscount = true;
  const loggedIn = true;
  const admin = false;

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const { loading, error: loadError, data } = useQuery(LOAD_PAYMENT);

  const [
    subscribe,
    { loading: isSaving, error: subscribeError, data: subscribeData }
  ] = useMutation(SUBSCRIBE);
  const [
    validateCoupon,
    {
      loading: isValidatingCoupon,
      error: validateCouponError,
      data: validateCouponData
    }
  ] = useMutation(VALIDATE_COUPON);

  const company = data?.company;
  const selectedStripePlanId = selectedPlan?.stripeId;
  const appliedCouponId = appliedCoupon?.id;

  const {
    loading: loadingUpcomingInvoice,
    data: upcomingInvoiceData,
    refetch: refetchUpcomingInvoice
  } = useQuery(GET_UPCOMING_INVOICE, {
    variables: {
      stripePlanId: selectedStripePlanId,
      couponCode: appliedCouponId
    },
    notifyOnNetworkStatusChange: true
  });

  const errors =
    (subscribeError && [
      "Unknown error, please try again or contact support - support@meetedgar.com"
    ]) ||
    subscribeData?.subscribe?.errors ||
    [];

  // Default selectedPlan to the company's subscription plan
  const startingSubscriptionPlan = company?.subscriptionPlan;
  useEffect(() => {
    if (!selectedPlan) {
      setSelectedPlan(startingSubscriptionPlan);
    }
  }, [startingSubscriptionPlan, selectedPlan]);

  const handleChangeCouponCode = useCallback(
    code => {
      setCouponCode(code);
    },
    [setCouponCode]
  );

  const handleApplyCoupon = useCallback(
    async (couponCode, stripePlanId) => {
      const { data } = await validateCoupon({
        variables: { couponCode, stripePlanId }
      });
      await setAppliedCoupon(data?.validateCoupon?.coupon);
      await refetchUpcomingInvoice({
        stripePlanId,
        couponCode
      });
    },
    [validateCoupon, refetchUpcomingInvoice]
  );

  const handleRemoveCoupon = useCallback(async () => {
    await setCouponCode("");
    await setAppliedCoupon(null);
    await refetchUpcomingInvoice({
      stripePlanId: selectedStripePlanId,
      couponCode: null
    });
  }, [
    selectedStripePlanId,
    setAppliedCoupon,
    setCouponCode,
    refetchUpcomingInvoice
  ]);

  const handleChoosePlan = useCallback(
    async plan => {
      let validatedCoupon = null;
      if (couponCode) {
        const { data } = await validateCoupon({
          variables: { couponCode, stripePlanId: plan.stripeId }
        });
        validatedCoupon = data?.validateCoupon?.coupon;
      }
      await setAppliedCoupon(validatedCoupon);
      await setCouponCode(validatedCoupon?.id || "");
      await setSelectedPlan(plan);
      await refetchUpcomingInvoice({
        stripePlanId: plan?.stripeId,
        couponCode: appliedCouponId
      });
    },
    [
      validateCoupon,
      couponCode,
      appliedCouponId,
      setSelectedPlan,
      refetchUpcomingInvoice,
      setCouponCode,
      setAppliedCoupon
    ]
  );

  const handleComplete = useCallback(
    async (planId, paymentToken, couponCode, total) => {
      let { data } = await subscribe({
        variables: { planId, paymentToken: paymentToken?.id, couponCode, total }
      });
      const errors = data?.subscribe?.errors;
      if (errors?.length === 0) {
        const coupon = couponCode ? `&coupon=${couponCode}` : ``;
        gotoPath(`/dashboard?success=true&plan_id=${planId}${coupon}`);
      }
    },
    [subscribe]
  );
  const invoiceAmount = upcomingInvoiceData?.upcomingInvoice?.due;
  const amountDue = (invoiceAmount || "").replace("$", "");
  const firstDueDate = data?.company?.firstDueDate;

  return (
    <Payment
      {...ownProps}
      loading={loading || !selectedPlan}
      loadError={loadError}
      coupon={appliedCoupon}
      couponCode={couponCode}
      amountDue={amountDue}
      loadingAmountDue={loadingUpcomingInvoice}
      availablePlans={company?.availableSubscriptionPlans || []}
      discountText={validateCouponData?.coupon?.description}
      isCouponValid={!!validateCouponData?.validateCoupon?.coupon?.id}
      isSaving={isSaving}
      isValidatingCoupon={isValidatingCoupon}
      validateCouponError={
        validateCouponError || validateCouponData?.validateCoupon?.errors?.[0]
      }
      errors={errors}
      firstDueDate={firstDueDate}
      displayDiscount={displayDiscount}
      selectedPlan={selectedPlan}
      loggedIn={loggedIn}
      admin={admin}
      onApplyCoupon={handleApplyCoupon}
      onRemoveCoupon={handleRemoveCoupon}
      onComplete={handleComplete}
      onChoosePlan={handleChoosePlan}
      onChangeCouponCode={handleChangeCouponCode}
    />
  );
};
