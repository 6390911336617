import styled from "styled-components";

export const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  overflow-x: auto;
`;

export const BaseItem = styled.div`
  align-items: center;
  display: flex;
  padding: 12.5px 20px;

  &.label {
    min-width: 300px;
    flex: 2;

    .cat-color {
      border-radius: 50%;
      display: block;
      height: 12px;
      margin: 0 7.5px 0 0;
      width: 12px;
    }
  }

  &.span-col-4 {
    display: flex;
    flex: 1;
    justify-content: center;
    min-width: 200px;
  }

  &.span-col-3 {
    min-width: 200px;
    flex: 1;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: space-between;
`;

export const HeaderItem = styled(BaseItem)`
  font-size: 12px;
  opacity: 0.7;

  img {
    margin-left: 7.5px;
  }
`;

export const Body = styled.div``;

export const BodyRow = styled(Header)`
  > div {
    border-top: 1px solid #f5f6f7;
  }

  &:nth-child(odd) > div {
    background-color: #f9fafb;
  }
`;

export const BodyItem = styled(BaseItem)`
  &.skeleton span {
    flex: 1;
  }
`;

export const Empty = styled(BodyRow)`
  display: flex;
  font-size: 13px;
  justify-content: center;
  opacity: 0.4;
  padding: 25px 10px;
`;

export const Accounts = styled.div`
  display: flex;
  justify-content: center;
`;

export const AvatarWrapper = styled.div`
  height: 26px;
  margin: 0 -5px 0;
  position: relative;
  width: 26px;

  img {
    border-radius: 50%;
    border: 1px solid #fff;
  }

  &.accounts {
    margin: 0 8px 0 0;
  }

  .providerIcon {
    border-radius: 2px;
    bottom: -1px;
    height: 13px;
    position: absolute;
    right: -2px;
    width: 13px;
  }
`;
