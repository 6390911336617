/* @flow */
import React from "react";
import classNames from "classnames";
import { featureFlag } from "util";
import { Label, H4, Body } from "typography";
import { Icon } from "icons";
import styles from "./index.css";

const EmptyState = () => (
  <div>
    <div className={styles.noAccountsHeader}>
      <Label>Accounts (0/0)</Label>
    </div>
    <div>
      <Icon
        type="facebook-square"
        size="2x"
        className={classNames(styles.facebook, styles.socialIcon)}
      />
      <Icon
        type="linkedin-square"
        size="2x"
        className={classNames(styles.linkedin, styles.socialIcon)}
      />
      <Icon
        type="twitter-square"
        size="2x"
        className={classNames(styles.twitter, styles.socialIcon)}
      />
      <Icon
        type="instagram"
        size="2x"
        className={classNames(styles.instagram, styles.socialIcon)}
      />
      {featureFlag("pinterest") && (
        <Icon
          type="pinterest"
          size="2x"
          className={classNames(styles.pinterest, styles.socialIcon)}
        />
      )}
    </div>
    <H4>There are no accounts attached.</H4>
    <Body>
      Add your accounts in the{" "}
      <a href="/accounts" target="_blank">
        Accounts
      </a>{" "}
      section.
    </Body>
  </div>
);

export default EmptyState;
