import React from "react";

import { IProps } from "./types.js";
import { Wrapper, Left, Checkbox } from "./styles";

const FormActions: React.FC<IProps> = props => {
  return (
    <Wrapper>
      <Left htmlFor="user_agree_to_terms">
        <Checkbox
          type="checkbox"
          value="1"
          name="user[agree_to_terms]"
          id="user_agree_to_terms"
          onChange={e => props.setHasAgreed(e.target.checked)}
        />
        <span>
          I agree to the{" "}
          <a href="http://meetedgar.com/privacy/" target="_blank">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="http://meetedgar.com/terms-conditions/" target="_blank">
            Terms and Conditions
          </a>
        </span>
      </Left>
      <div>
        <input
          type="submit"
          name="commit"
          value="Let's Go!"
          className="button btn submit_btn btn-solo pinkbtn sign_up"
          data-disable-with="Let's Go!"
          disabled={!props.hasAgreed || props.sending}
        />
        {props.sending && <span>sending...</span>}
      </div>
    </Wrapper>
  );
};

export default FormActions;
