/* @flow */
import React, { useState } from "react";

import type { IProps } from "./types.js";
import usePostsModal from "./use.js";
import { Wrapper, Body, Input } from "./styles";

import Header from "./Header";
import Categories from "./Categories";
import Posts from "./Posts";
import {useDebounce} from "./useDebouncing"

const PostsModal = (props: IProps) => {
  const { hideModal, selectPost } = props;
  const { selectedCategory, handleCategory } = usePostsModal();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncingSearch = useDebounce(searchTerm, 600)
  const handleSearch = (event: SyntheticEvent<HTMLInputElement>) => {
      
    setSearchTerm(event.currentTarget.value);
  };

  return (
    <Wrapper>
      <Header hideModal={hideModal} />
      <input
        type="text"
        placeholder="Search posts..."
        value={searchTerm}
        onChange={handleSearch}
        style={Input}
      />
      <Body>
        <Categories
          selectedCategory={selectedCategory}
          handleCategory={handleCategory}
        />
        <Posts
          selectedCategory={selectedCategory}
          selectPost={selectPost}
          searchTerm={debouncingSearch}
        />
      </Body>
    </Wrapper>
  );
};

export default PostsModal;
