// @flow
import { inRange } from "lodash";
import invariant from "invariant";

import type { RawRichTextEntityMap } from "types";

type Token = {
  value: string,
  type: null | "mention",
  mentionKey?: ?string
};

type TokenBuilder = {
  tokens: Token[],
  currentToken: Token
};

const MIN_ENTITY_LENGTH = 2;

const getMentionKeyAtIndex = (index, entityMap) =>
  entityMap.blocks.reduce((memo, block) => {
    if (memo) return memo;

    const mentionRange = block.entityRanges.find(
      ({ offset, length }) =>
        length >= MIN_ENTITY_LENGTH && inRange(index, offset, offset + length)
    );
    return !!mentionRange ? mentionRange.key : null;
  }, null);

const tokenizeText = (
  text: string,
  rawRichTextEntityMap: ?RawRichTextEntityMap
): Token[] => {
  if (!rawRichTextEntityMap) {
    return [{ value: text, type: null }];
  }
  const tokenBuilder = text.split("").reduce(
    ({ currentToken, tokens }, current, i): TokenBuilder => {
      invariant(!!rawRichTextEntityMap, "RawRichTextEntityMap required");
      const keyAtIndex = getMentionKeyAtIndex(i, rawRichTextEntityMap);
      return currentToken.mentionKey !== keyAtIndex
        ? {
            tokens: currentToken.value ? tokens.concat([currentToken]) : tokens,
            currentToken: {
              value: current,
              type: keyAtIndex !== null ? "mention" : null,
              mentionKey: keyAtIndex
            }
          }
        : {
            tokens,
            currentToken: {
              ...currentToken,
              value: `${currentToken.value}${current}`
            }
          };
    },
    { currentToken: { type: null, value: "" }, tokens: [] }
  );
  return tokenBuilder.tokens.concat([tokenBuilder.currentToken]);
};

export default tokenizeText;
