/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation refreshApiKey {
    refreshApiKey {
      user {
        id
        apiKey
      }
    }
  }
`;
