/* @flow */
import React from "react";
import { useState } from "react";
import classNames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { get } from "lodash";
import { useQuery, useMutation } from "@apollo/react-hooks";
import GET_API_KEY from "queries/getApiKey";
import REFRESH_API_KEY from "mutations/refreshApiKey";
import { Button } from "components/common/buttons";
import { Caption } from "components/common/typography";
import { Icon } from "icons";
import { Link } from "components/common/links";
import styles from "./index.css";

const API_KEY_REFRESH_SUCCESS_DISPLAY_TIME_MS = 1000;
const API_KEY_REFRESH_ERROR_DISPLAY_TIME_MS = 2000;

type Props = {
  description: String,
  addtlInfoUrl: String,
  addtlInfoText: String,
  handleInfoLinkClick: EventHandler
};

export const ConnectionFooterZapier = ({
  description,
  addtlInfoUrl,
  addtlInfoText,
  handleInfoLinkClick
}: Props) => {
  // queries the backend via graphql to fetch the user's api key
  const {
    loading: apiKeyLoading,
    error: apiKeyError,
    data: apiKeyData
  } = useQuery(GET_API_KEY);

  // uses a mutation to generate a new api key
  // we don't use the error or data properties returned from this query
  const [refreshApiKey, { loading: apiKeyRefreshLoading }] = useMutation(
    REFRESH_API_KEY
  );

  const [refreshApiKeyError, setRefreshApiKeyError] = useState(null);
  const [apiKeyCopyError, setApiKeyCopyError] = useState(null);

  const apiKeyCopyButtonState = () => {
    if (refreshApiKeyError === null && apiKeyCopyError === null) {
      return { success: null, msg: "Copy API key to clipboard" };
    } else if (apiKeyCopyError) {
      return { success: false, msg: "API key copy failed!" };
    } else if (refreshApiKeyError) {
      return { success: false, msg: "API key refresh failed!" };
    } else if (!apiKeyCopyError && refreshApiKeyError === null) {
      return { success: true, msg: "Copied API key!" };
    } else if (apiKeyCopyError === null && !refreshApiKeyError) {
      return { success: true, msg: "Refreshed API key!" };
    }
    return { success: null, msg: "Copy API key to clipboard" };
  };

  const apiKeyCopyButtonIcon = () => {
    const cpyButtonState = apiKeyCopyButtonState();
    if (cpyButtonState.success === true) {
      return "check";
    }
    if (cpyButtonState.success === null) {
      return "copy";
    }
    if (cpyButtonState.success === false) {
      return "ban";
    }
  };

  const handleApiKeyCopied = () => {
    setApiKeyCopyError(false);
    setRefreshApiKeyError(null);
  };

  const handleApiKeyRefreshed = () => {
    setApiKeyCopyError(null);
    setRefreshApiKeyError(false);
    setTimeout(() => {
      setRefreshApiKeyError(null);
    }, API_KEY_REFRESH_SUCCESS_DISPLAY_TIME_MS);
  };

  const handleRefreshApiKey = async e => {
    e.stopPropagation();

    try {
      await refreshApiKey();
      handleApiKeyRefreshed();
    } catch (error) {
      // handle a failure to refresh
      setRefreshApiKeyError(error);
      setTimeout(() => {
        setRefreshApiKeyError(null);
      }, API_KEY_REFRESH_ERROR_DISPLAY_TIME_MS);
    }
  };

  const handleApiKeyCopyButtonClicked = e => {
    // we must stop the click event here or else it will bubble up to the
    //  click handler on the card component
    e.stopPropagation();
  };

  if (apiKeyLoading) {
    return (
      <div>
        <Caption>
          {description}{" "}
          {addtlInfoUrl && (
            <Link href={addtlInfoUrl} onClick={handleInfoLinkClick}>
              {addtlInfoText}
            </Link>
          )}
        </Caption>
        <br />
        <div className={styles.apiKeyControlsContainer}>
          <Button small thin disabled type="tint">
            <Icon type="loading" className={styles.apiKeyCopyIcon} />
            Loading API key...
          </Button>

          <Button
            small
            thin
            loading
            type="tint"
            enforceMinWidth={false}
            className={styles.apiKeyRefreshIcon}
          >
            <Icon type="refresh" />
          </Button>
        </div>
      </div>
    );
  }

  if (apiKeyError) {
    return (
      <div>
        <Caption>
          {description}{" "}
          {addtlInfoUrl && (
            <Link href={addtlInfoUrl} onClick={handleInfoLinkClick}>
              {addtlInfoText}
            </Link>
          )}
        </Caption>
        <br />
        <div className={styles.apiKeyControlsContainer}>
          <Button small thin disabled type="tint">
            <Icon type="loading" className={styles.apiKeyCopyIcon} />
            Error loading API key!
          </Button>

          <Button
            small
            thin
            disabled
            type="tint"
            enforceMinWidth={false}
            className={styles.apiKeyRefreshIcon}
          >
            <Icon type="refresh" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Caption>
      {description}{" "}
      {addtlInfoUrl && (
        <Link href={addtlInfoUrl} onClick={handleInfoLinkClick}>
          <br />
          {addtlInfoText}
        </Link>
      )}
      <br />
      <div className={styles.apiKeyControlsContainer}>
        {/* CopyToClipboard overwrites the className prop of its child, so we must
          add our classes for the Button element at the CopyToClipboard level
      */}
        <CopyToClipboard
          text={get(apiKeyData, "user.apiKey")}
          onCopy={handleApiKeyCopied}
          className={classNames(styles.apiKeyCopy, styles.apiKeyControls, {
            [styles.apiKeyCopySuccess]: apiKeyCopyButtonState().success
          })}
        >
          <Button
            small
            thin
            type="tint"
            disabled={apiKeyRefreshLoading}
            onClick={handleApiKeyCopyButtonClicked}
          >
            <Icon
              type={apiKeyCopyButtonIcon()}
              className={styles.apiKeyCopyIcon}
            />
            {apiKeyCopyButtonState().msg}
          </Button>
        </CopyToClipboard>

        <Button
          small
          thin
          loading={apiKeyRefreshLoading}
          type="tint"
          enforceMinWidth={false}
          className={styles.apiKeyRefreshIcon}
          onClick={handleRefreshApiKey}
        >
          <Icon type="refresh" />
        </Button>
      </div>
    </Caption>
  );
};

export default ConnectionFooterZapier;
