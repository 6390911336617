/* @flow */
import React, { useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import { useNewUICached } from "util";
import NewHeader from "components/NewHeader";

import GET_DASHBOARD from "queries/getDashboard";
import DISMISS_INFORMATION_BOX from "mutations/dismissInformationBox";
import App from "components/App";
import PageTitle from "links/PageTitle";
import PageSubtitle from "links/PageSubtitle";
import { LoadingIcon } from "icons";
import ErrorWithRetry from "components/ErrorWithRetry";
import {
  type OnboardingProgress,
  allStepsCompleted,
  buildOnboardingStatus
} from "components/OnboardingBar/util.js";
import ArticleCard from "components/ArticleCard";
import H4 from "typography/H4";
import H3 from "typography/H3";
import Button from "buttons/Button";
import SocialMediaIcon from "icons/SocialMediaIcon";
import FlashMessage from "./FlashMessage";
import Grid from "./Grid";
// import KickstarterChallenge from "./KickstarterChallenge";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type {
  dismissInformationBox,
  dismissInformationBoxVariables
} from "graphql-types/dismissInformationBox";

import styles from "./index.css";
import onboardingImage from "./images/onboarding.jpg";
import edgarUniversityImage from "./images/edgar-university.jpg";
import upcyclingContentImage from "./images/upcycling-content.svg";
import bestTimesToPostImage from "./images/best-times-to-post.jpg";
import shareWorthyImage from "./images/how-to-create-shareable-social-media-content.jpg";
import userSpotlightImage from "./images/user-spotlights.jpg";

import IMGResourcesRealEstate from "./images/resources-real-estate.png";

type OwnProps = {
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  dismissInformationBox?: ({
    variables: dismissInformationBoxVariables
  }) => Promise<dismissInformationBox>,
  dismissInformationBoxError?: boolean,
  dismissedInformationBoxes?: { [string]: string },
  error?: string,
  loading?: boolean,
  onboardingProgress?: OnboardingProgress
} & OwnProps;

type FlashProps = {
  dismissInformationBoxError?: boolean
};

export const FlashWrapper = ({ dismissInformationBoxError }: FlashProps) => {
  if (dismissInformationBoxError) {
    return (
      <FlashMessage
        body="An error occurred when attempting to dismiss."
        type="errorPersistent"
      />
    );
  }
  return null;
};

export const DashboardMain = ({
  // dismissInformationBox,
  dismissInformationBoxError,
  // dismissedInformationBoxes,
  error,
  loading,
  location,
  onboardingProgress
}: Props) => {
  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (error) {
    return <ErrorWithRetry>{error}</ErrorWithRetry>;
  }
  let isOnboardingCompleted = false;
  let isOnboardingDismissed = false;
  if (onboardingProgress) {
    const { completedSteps } = buildOnboardingStatus(
      onboardingProgress,
      location.pathname
    );
    isOnboardingCompleted = allStepsCompleted(completedSteps);
    isOnboardingDismissed = Boolean(onboardingProgress.dismissedAt);
  }
  return (
    <>
      <FlashWrapper dismissInformationBoxError={dismissInformationBoxError} />
      {isOnboardingCompleted || isOnboardingDismissed ? (
        <>
          <H4 className={styles.sectionHeader}>MeetEdgar resources:</H4>
          <Grid
            items={[
              <a
                className={styles.articleWrap}
                href="https://edgar-images-production.s3.us-west-2.amazonaws.com/edgar-university/52-quotes-real-estate.csv"
                target="_blank"
              >
                <ArticleCard
                  downloadable
                  top={
                    <img
                      className={styles.articleImage}
                      src={IMGResourcesRealEstate}
                      alt="CSV real estate"
                    />
                  }
                  title="Content Ideas for Real Estate Agents"
                  text={
                    <>
                      Coming up with content ideas is hard. So, we designed
                      these pre-made content ideas to help you generate more
                      content for your social media channels.
                    </>
                  }
                />
              </a>
            ]}
          />
          <H4 className={styles.sectionHeader}>
            Boost your social media strategy:
          </H4>
          <Grid
            items={[
              <a
                className={styles.articleWrap}
                href="https://help.meetedgar.com/en/articles/3155881-edgar-university"
                target="_blank"
              >
                <ArticleCard
                  top={
                    <img
                      className={styles.articleImage}
                      src={edgarUniversityImage}
                      alt="Classroom"
                    />
                  }
                  title="Edgar University"
                  text={
                    <>
                      Learn Edgar's flagship social media marketing strategy,
                      Social Brilliant, to grow your business, plus the Edgar
                      Expert track. It's a one stop hub for the best social
                      media marketing courses!
                    </>
                  }
                />
              </a>,
              <a
                className={styles.articleWrap}
                href="https://www.youtube.com/watch?v=k8TRF1oar-I"
                target="_blank"
              >
                <ArticleCard
                  top={
                    <div className={styles.articleCenterTop}>
                      <img
                        className={styles.articleIconImage}
                        src={upcyclingContentImage}
                        alt="Edgar icon"
                      />
                    </div>
                  }
                  title="Upcycle Your Content"
                  text={
                    <>
                      You created the content. Now make it work harder for you
                      with Edgar's powerful upcycling strategy.
                    </>
                  }
                />
              </a>,
              <a
                className={styles.articleWrap}
                href="https://www.youtube.com/watch?v=mcc0gIe-BPs&list=PLuMwmBzJb6-8Xn2qS2prN3XR2uGKrfyWt&index=2&t=0s"
                target="_blank"
              >
                <ArticleCard
                  top={
                    <img
                      className={styles.articleImage}
                      src={bestTimesToPostImage}
                      alt="Title frame from YouTube video"
                    />
                  }
                  title="When should you post on social media?"
                  text={
                    <>
                      Watch Edgar's webinar to find out when you (yes, YOU!)
                      should be posting on social media&hellip;hint, it's
                      specific to your audience ;)
                    </>
                  }
                />
              </a>,
              <a
                className={styles.articleWrap}
                href="https://www.youtube.com/watch?v=QqwbCx9NCjM&list=PLuMwmBzJb6-8Xn2qS2prN3XR2uGKrfyWt&index=10"
                target="_blank"
              >
                <ArticleCard
                  top={
                    <img
                      className={styles.articleImage}
                      src={shareWorthyImage}
                      alt="Phone and computer with social media like bubbles"
                    />
                  }
                  title="Is your content share-worthy?"
                  text={
                    <>
                      Having great content doesn't necessarily mean people will
                      share it. Learn how to encourage more shares and
                      engagement to increase your reach.
                    </>
                  }
                />
              </a>,
              <a
                className={styles.articleWrap}
                href="https://www.youtube.com/playlist?list=PLuMwmBzJb6-9eCH7xl5Qfp3CG9FhrVqKY"
                target="_blank"
              >
                <ArticleCard
                  top={
                    <img
                      className={styles.articleImage}
                      src={userSpotlightImage}
                      alt="Title frame from YouTube video"
                    />
                  }
                  title="Edgar's User Spotlights"
                  text={
                    <>
                      Want to see how other Edgar customers get the most out of
                      all eight tentacles? Check out our User Spotlight Videos.
                    </>
                  }
                />
              </a>,
              <ArticleCard
                title="Follow Edgar"
                text={
                  <>
                    Edgar not only helps you publish to social media, but he
                    loves it, too! Follow Edgar for live videos, social tips,
                    challenges, and more:
                    <ul className={styles.socialMediaIcons}>
                      <li className={styles.socialMediaIcon}>
                        <a
                          href="https://www.facebook.com/MeetEdgar/"
                          target="_blank"
                        >
                          <SocialMediaIcon
                            className={styles.socialIconImage}
                            value="facebook"
                          />
                        </a>
                      </li>
                      <li className={styles.socialMediaIcon}>
                        <a href="https://twitter.com/meetedgar" target="_blank">
                          <SocialMediaIcon
                            className={styles.socialIconImage}
                            value="twitter"
                          />
                        </a>
                      </li>
                      <li className={styles.socialMediaIcon}>
                        <a
                          href="https://www.instagram.com/meetedgar/"
                          target="_blank"
                        >
                          <SocialMediaIcon
                            className={styles.socialIconImage}
                            value="instagram"
                          />
                        </a>
                      </li>
                      <li className={styles.socialMediaIcon}>
                        <a
                          href="https://www.pinterest.com/meetedgar/"
                          target="_blank"
                        >
                          <SocialMediaIcon
                            className={styles.socialIconImage}
                            value="pinterest"
                          />
                        </a>
                      </li>
                      <li className={styles.socialMediaIcon}>
                        <a
                          href="https://www.linkedin.com/company/meetedgar/"
                          target="_blank"
                        >
                          <SocialMediaIcon
                            className={styles.socialIconImage}
                            value="linkedin"
                          />
                        </a>
                      </li>
                    </ul>
                  </>
                }
              />
            ]}
          />
        </>
      ) : (
        <>
          <H4 className={styles.sectionHeader}>Quick start guide:</H4>
          <div className={[styles.box, styles.quickStart].join(" ")}>
            <H3>Set up your Edgar account in just 4 easy steps</H3>
            <p className={styles.quickStartBody}>
              Edgar will guide you through adding your accounts, social content,
              and setting up your schedule. Start posting in minutes!
            </p>
            <div className={styles.startButtonContainer}>
              <Button href="/accounts" type="primary">
                Start now!
              </Button>
            </div>
          </div>
        </>
      )}
      <H4 className={styles.sectionHeader}>Make friends with Edgar:</H4>
      <Grid
        items={[
          <a
            className={styles.articleWrap}
            href="https://www.youtube.com/playlist?list=PLuMwmBzJb6-9yQtWvbPoH6JNzXqeN0F1L"
            target="_blank"
          >
            <ArticleCard
              top={
                <img
                  className={styles.articleImage}
                  src={onboardingImage}
                  alt="Title frame from YouTube video"
                />
              }
              title="Onboarding Video Guide"
              text={
                <>
                  Check out Edgar's playlist of quick tutorials to get your
                  Edgar account up and running fast. Learn all of Edgar's best
                  features!
                </>
              }
            />
          </a>
        ]}
      />
      {/* <KickstarterChallenge
        boxClassName={styles.box}
        dismissInformationBox={dismissInformationBox}
        dismissedInformationBoxes={dismissedInformationBoxes}
        informationBoxName="dashboardKickstarterChallenge"
        sectionHeaderClassName={styles.sectionHeader}
      /> */}
    </>
  );
};

export const Dashboard = (props: Props) => {
  const useNewUI = useNewUICached();
  const isUniversity = window.location.pathname.includes("university");

  const pageTitle = isUniversity ? " University" : "";

  const pageSubtitle = `
    You'll find all Edgar's best resources here at the
    ${isUniversity ? "university" : "dashboard"}. Learn social
    media marketing strategy, understand what all of Edgar's tentacles
    do, and more!
  `;

  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      isNewHeader={!useNewUI}
      header={
        useNewUI ? (
          <>
            <NewHeader
              title={`Welcome to Edgar${pageTitle}!`}
              mb
              {...props.topNav}
            />
            <PageSubtitle>{pageSubtitle}</PageSubtitle>
          </>
        ) : (
          <>
            <PageTitle>Welcome to Edgar{pageTitle}!</PageTitle>
            <PageSubtitle>{pageSubtitle}</PageSubtitle>
          </>
        )
      }
    >
      <DashboardMain {...props} />
    </App>
  );
};

export default (props: OwnProps) => {
  const { location } = props;

  // Queries
  const { loading, error, data } = useQuery(GET_DASHBOARD);
  // Mutations
  const [
    dismissInformationBox,
    { error: dismissInformationBoxError }
  ] = useMutation(DISMISS_INFORMATION_BOX, {
    refetchQueries: _ => [
      {
        query: GET_DASHBOARD
      }
    ],
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });

  // Fire FirstPromoter after a successful signup
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (!data || !window.$FPROM || queryParams.get("success") !== "true") {
      return;
    }
    window.$FPROM.trackSignup({
      email: data?.user?.email
    });
  }, [location, data]);

  if (loading) {
    return <Dashboard {...props} />;
  }
  if (error) {
    return <Dashboard {...props} error="Uh-oh something went wrong 😿" />;
  }
  return (
    <Dashboard
      {...props}
      dismissInformationBox={dismissInformationBox}
      dismissInformationBoxError={dismissInformationBoxError}
      dismissedInformationBoxes={data?.user?.dismissedInformationBoxes}
      onboardingProgress={data?.user?.onboardingProgress}
    />
  );
};
