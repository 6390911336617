/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation createContent($input: CreateContentInput!) {
    createContent(input: $input) {
      content {
        id
        status
        queueable
        twitterPostedVariationsCount
        sendAt
        createdAt
        expiresAt
        sendAt
        useOnce
        useShortLinks
        totalPostCount
        sendMobileReminder
        instaReels
        accountRelationships {
          id
          account {
            id
            name
            provider
            providerDisplayName
            platform
            pinterestBoards {
              id
              name
            }
          }
          pinterestBoards {
            id
            name
          }
        }
        category {
          id
          name
          colorIndex
        }
        variations {
          id
          accountsData {
            text
            account {
              id
            }
          }
          clientId @client
          text
          fbText
          tiktokMeta {
            privacyStatus
            commercialType
            showCommercialContent
            commentDisabled
            duetDisabled
            stitchDisabled
            maxVideoPostDurationSec
          }
          origin
          rawRichTextEntityMap
          pinterestTitle
          pinterestDestinationLink
          pageScrape {
            id
            url
            status
            images {
              id
              src
              alt
              size
              type
              width
              height
              sha256
            }
          }
          images {
            id
            url
            fileSize
            format
            width
            height
            clientProvidedSha256
          }
          videos {
            id
            url
            fileSize
            formats
            width
            height
            seconds
            clientProvidedSha256
          }
          lastPostedTo {
            twitter {
              sentAt
              account {
                id
                name
                nickname
                providerDisplayName
                platform
                provider
                image
              }
            }
          }
        }
      }
      errors {
        field
        messages
      }
      hasAccountWithoutSchedule
    }
  }
`;
