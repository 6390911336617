import styled from "styled-components";

const mobileViewport = 580;

export const Wrapper = styled.div`
  cursor: default;
`;

export const Header = styled.div`
  align-items: center;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export const IconWrapper = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 36px;
  transition: opacity 0.2s ease-in-out;

  i {
    color: #555;
  }

  &:hover,
  &.active {
    opacity: 0.8;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

export const MobileViewport = styled.div`
  padding: 22.5px;
  text-align: center;

  @media (min-width: ${mobileViewport}px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: none;

  @media (min-width: ${mobileViewport}px) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 850px) {
    align-items: flex-start;
    flex-direction: row;
  }
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  max-width: 280px;
  padding: 8px 10px;

  @media (min-width: 1150px) {
    max-width: 320px;
  }

  @media (min-width: 1400px) {
    max-width: 320px;
  }

  ul {
    border-radius: 4px;
    list-style: none;
    margin: 0;
    padding: 0;
    transition: background 0.2s ease-in-out;

    li {
      align-items: center;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      margin: 2.5px 0;
      padding: 8px 6px;

      .left {
        width: 40px;
      }

      &:hover,
      &.active {
        background: rgba(0, 0, 0, 0.05);
      }
    }

    &.disabled li {
      cursor: not-allowed;
      opacity: 0.8;

      &:hover {
        background: transparent;
      }
    }
  }
`;

export const LiTitle = styled.div`
  margin-left: 10px;

  div {
    font-size: 16px;
    font-weight: 600;
  }

  span {
    font-size: 12px;
    opacity: 0.6;
  }
`;

export const Buttons = styled.div`
  margin-top: 40px;
  padding: 8px;
`;

export const Loading = styled.div`
  align-items: center;
  display: flex;
  height: 52px;
  justify-content: center;
  margin-top: 40px;

  img {
    width: 32px;
  }
`;

export const ImageWrapper = styled.div`
  align-items: center;
  background: #000;
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  padding: 60px 40px;
  position: relative;

  .background {
    -webkit-filter: blur(15px);
    filter: blur(15px);
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    opacity: 0.6;
    top: 0;
    width: 100%;
  }

  .image {
    max-height: calc(65vh - 60px);
    max-width: calc(65vw - 360px - 160px);
    height: 100%;
    position: relative;
    z-index: 1;
  }

  .crop {
    height: 350px;
    position: relative;
    width: 100%;
    z-index: 2;

    @media (min-height: 720px) and (min-width: 1100px) {
      height: 400px;
    }
  }
`;
