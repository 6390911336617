// @flow
import React from "react";
import LinkPreviewAttachment from "./LinkPreviewAttachment";
import RestoreLinkPreviewAttachment from "./RestoreLinkPreviewAttachment";

import type {
  loadContent_content_variations_pageScrape as PageScrapeData,
  Platform
} from "graphql-types/loadContent";

type Props = {
  linkAttached: boolean,
  pageScrape: PageScrapeData,
  selectedPlatforms: Platform[],
  hasAttachedMedia: boolean,
  onEnableLinkPreview: () => void,
  onDisableLinkPreview: () => void
};

export const LinkPreviewAttachmentToggle = ({
  linkAttached,
  pageScrape,
  selectedPlatforms,
  hasAttachedMedia,
  onEnableLinkPreview,
  onDisableLinkPreview
}: Props) =>
  linkAttached ? (
    <LinkPreviewAttachment
      pageScrape={pageScrape}
      platforms={selectedPlatforms}
      onClose={onDisableLinkPreview}
    />
  ) : (
    <RestoreLinkPreviewAttachment
      hasAttachedMedia={hasAttachedMedia}
      onClick={onEnableLinkPreview}
    />
  );

export default LinkPreviewAttachmentToggle;
