import styled from "styled-components";

export const Title = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  justify-content: space-between;

  @media (min-width: 530px) {
    align-items: center;
    flex-direction: row;
  }
`;

export const Info = styled.span`
  font-size: 13px;
  font-style: italic;
  font-weight: normal;
  opacity: 0.5;
`;

export const Bar = styled.div`
  background-color: #f0f0f0;
  border-radius: 50px;
  height: 20px;
  margin: 10px 0;
`;

export const Fill = styled.div`
  background: #299791;
  border-radius: 50px;
  height: 20px;
  width: ${props => props.percentage}%;
`;

export const PlusIcon = styled.img`
  float: left;
  margin: 10px 4px 0 0;
`;
