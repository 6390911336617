/* @flow */
import styled from "styled-components";

export const Container = styled.div`
  padding: 16px;

  .no-data {
    margin-bottom: 5px;
    margin-top: -7.5px;
  }
`;

export const SectionTitle = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 20px;

  h1 {
    color: #101828;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  span {
    align-items: center;
    background: #eaf7e8;
    border-radius: 20px;
    color: #28b116;
    display: flex;
    font-size: 11px;
    font-weight: 600;
    justify-content: center;
    margin: 0 0 0 10px;
    padding: 8px 12px;

    img {
      margin-left: 2px;
    }

    &.red {
      background: #ffe8e8;
      color: #f04538;
    }
  }
`;
