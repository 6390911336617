import React from "react";

import RadioButton from "forms/RadioButton2";
import Label from "typography/Label";
import Caption from "typography/Caption";

import styles from "./index.css";

type Props = {
  caption: Node,
  isEnabled: boolean,
  label: string,
  option: boolean,
  selectOption: (option: boolean) => void
};

const Option = ({ caption, isEnabled, label, option, selectOption }: Props) => {
  return (
    <div className={styles.service}>
      <RadioButton
        className={styles.radioButton}
        onClick={selectOption}
        selected={isEnabled === option}
        value={option}
      />
      <div>
        <Label
          onClick={_evt => {
            selectOption(option);
          }}
        >
          {label}
        </Label>
        <Caption className={styles.caption}>{caption}</Caption>
      </div>
    </div>
  );
};

export default Option;
