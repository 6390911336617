import React from "react";

import Modal from "layout/modals/Modal";
import { Button } from "components/common/buttons";
import PulseGIF from "components/common/images/loading-pulse.svg";

import useShopifyProductForm from "./use";
import type { Props } from "./types.js";
import styles from "./index.css";
import { Wrapper, Left, Right, Title, BtnNext, Error } from "./styles";

import ShopifyFormAccounts from "./Accounts";
import ShopifyFormCategory from "./Category";
import ShopifyFormAttributes from "./Attributes";
import ShopifyFormPreview from "./Preview";
import ShopifyFormImportOption from "./ImportOption";

const ShopifyProductForm = (props: Props) => {
  const { onClickHide } = props;
  const {
    loading,
    accountIds,
    handleAccounts,
    categoryId,
    handleCategory,
    errors,
    importAttributes,
    handleAttribute,
    importContentAsPending,
    handleImportContentAsPending,
    handleSubmit,
    selectedPinterestBoards,
    onSelectPinterestBoard,
    setAccounts
  } = useShopifyProductForm(props);

  return (
    <Modal
      onClose={onClickHide}
      closeOnOverlayClick
      className={styles.rootModal}
    >
      <Wrapper>
        <Left>
          <Title>Import content from Shopify</Title>

          <ShopifyFormAccounts
            accountIds={accountIds}
            handleAccounts={handleAccounts}
            setAccounts={setAccounts}
            errors={errors}
            onSelectPinterestBoard={onSelectPinterestBoard}
            selectedPinterestBoards={selectedPinterestBoards}
          />

          <ShopifyFormCategory
            categoryId={categoryId}
            handleCategory={handleCategory}
            errors={errors}
          />

          <ShopifyFormAttributes
            importAttributes={importAttributes}
            handleAttribute={handleAttribute}
          />

          <ShopifyFormImportOption
            importContentAsPending={importContentAsPending}
            handleImportContentAsPending={handleImportContentAsPending}
          />
        </Left>

        <Right>
          <ShopifyFormPreview importAttributes={importAttributes} />

          <BtnNext>
            {loading && <img src={PulseGIF} alt="loading" width="26" />}
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={
                !categoryId ||
                !accountIds.length ||
                loading ||
                errors.pinterestBoards
              }
            >
              Next
            </Button>
          </BtnNext>

          {errors?.pinterestBoards ? (
            <Error>
              <div>
                Please select at least one Pinterest board for each account.
              </div>
            </Error>
          ) : errors?.submit ? (
            <Error>
              <div>
                There was an error importing your content. Please try again.
              </div>
            </Error>
          ) : null}
        </Right>
      </Wrapper>
    </Modal>
  );
};

export default ShopifyProductForm;
