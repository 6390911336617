/* @flow */
import React from "react";
import ReactTooltip from "react-tooltip";
import Tooltip from "./Tooltip";

import RadioButton2 from "components/common/forms/RadioButton2";
import styles from "./index.css";
import LiAdAccountSelect from "./LiAdAccountSelect";

type Props = {
  account: [],
  sendMobileReminder: boolean,
  linkedinCarousel: boolean,
  hasMobileDevices: Boolean,
  accountRelationship: {},
  onChange: (linkedinCarousel?: boolean, accountRelationship?: any) => void
};

export const LinkedInPostSelect = ({
  account,
  sendMobileReminder,
  linkedinCarousel,
  accountRelationship,
  onSelectLiAdAccount,
  hasMobileDevices,
  selectedLiadAccountId,
  onChange
}: Props) => {
  const handleChange = (value: boolean | "liCarousal") => {
    if (value === "liCarousal") {
      onChange(true, accountRelationship);
    } else {
      onChange(false, accountRelationship);
    }
  };

  const hasCarousel = accountRelationship?.hasCarousel || selectedLiadAccountId;
  return (
    <>
      <div
        data-for="direct-to-feed-tooltip"
      // data-tip="Instagram will crop all images according to the first image"
      >
        <RadioButton2
          className={styles.radioButton}
          value={""}
          selected={!hasCarousel}
          onClick={handleChange}
        >
          <span className={styles.title}>Direct Post</span>
        </RadioButton2>
        <small className={styles.description}>
          For direct posts to Linked In Company accounts
        </small>
      </div>
      <div
        data-for="direct-to-feed-tooltip"
        data-tip="Linked In Carousal Ads Posts "
      >
        <RadioButton2
          className={styles.radioButton}
          value="liCarousal"
          selected={hasCarousel}
          onClick={handleChange}
        >
          <span className={styles.title}>Carousal Ads Post</span> <Tooltip />
        </RadioButton2>
        <small className={styles.description}>
          For carousal ads post on Linked In ad accounts
        </small>
      </div>

      {hasCarousel && (
        <LiAdAccountSelect
          adAccounts={account.linkedinAdAccounts}
          selectedLiadAccountId={selectedLiadAccountId}
          onSelect={
            onSelectLiAdAccount && onSelectLiAdAccount.bind(null, account.id)
          }
        />
      )}

      <ReactTooltip effect="solid" place="bottom" id="direct-to-feed-tooltip" />
    </>
  );
};

export default LinkedInPostSelect;
