/* @flow */
import React from "react";
import moment from "moment";

import AccountAvatar from "images/AccountAvatar";

import type { Props } from "./types.js";
import { Wrapper, Container } from "../FacebookComments/styles";
import { Loading, Header } from "./styles";
import useFacebookThread from "./use.js";

import Messages from "./Messages";
import Footer from "./Footer";

const ConversationThread = (props: Props) => {
  const { selectedAccount, selectedThread } = props;

  const {
    loading,
    conversationThreads,
    afterReplyCreate,
    loadingMore,
    loadOlderReplies,
    containerRef
  } = useFacebookThread(props);

  return (
    <Wrapper>
      <Container ref={containerRef}>
        {!selectedThread ? (
          <Loading>Please, select a conversation on the left panel.</Loading>
        ) : loading ? (
          <Loading>Loading conversation thread...</Loading>
        ) : (
          <>
            <Header>
              <AccountAvatar
                src={selectedThread.sender.profilePic}
                alt="profile picture"
              />
              <div>
                <h4>
                  {selectedThread?.name
                    ? selectedThread?.name
                    : selectedThread.sender.name}
                </h4>
                <span>{moment(selectedThread.updatedTime).fromNow()}</span>
              </div>
            </Header>

            <Messages
              conversationThreads={conversationThreads}
              selectedAccount={selectedAccount}
              loadingMore={loadingMore}
              loadOlderReplies={loadOlderReplies}
            />
          </>
        )}
      </Container>

      {selectedAccount && selectedThread && !loading ? (
        <Footer
          selectedAccount={selectedAccount}
          selectedThread={selectedThread}
          afterReplyCreate={afterReplyCreate}
        />
      ) : null}
    </Wrapper>
  );
};

export default ConversationThread;
