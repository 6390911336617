import React from "react";
import { Modal } from "components/common/layout/modals";
import { Button } from "components/common/buttons";
import styles from "./index.css";

type Props = {
  onClose: () => void
};

const SeeMorePendingContentDialog = ({ onClose }: Props) => (
  <Modal className={styles.root} onClose={onClose}>
    <h3 className={styles.title}>You saved your first post to your library!</h3>
    <span className={styles.message}>
      The rest of the content Edgar imported from your website is waiting for
      your approval on the Pending tab of your Library.
    </span>
    <Button type="primary" href="/contents/pending" className={styles.button}>
      View Pending Content
    </Button>
  </Modal>
);

export default SeeMorePendingContentDialog;
