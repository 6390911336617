/* @flow */
import React, { useEffect, useState, useCallback } from "react";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { useApolloClient } from "@apollo/react-hooks";

import { hashFile } from "util";
import Expander from "layout/Expander";
import CREATE_IMAGE from "mutations/createImage";
import analyzeImage from "lib/images/analyzeImage.js";
import validateImage from "lib/images/validateImage";
import { IMAGE_VALIDATIONS } from "constants";

import AttachmentContainer from "../common/AttachmentContainer";
import MediaList from "../common/MediaList";
import Caption from "./Caption";

import type {
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData,
  Platform,
  Provider
} from "graphql-types/loadContent";

type MediaUpload = { fileName: string, progress: ?number };

type Props = {
  images: ImageData[],
  imagesTextVariations: ?(any[]),
  videos: VideoData[],
  sendMobileReminder: boolean,
  instaReels: boolean,
  pendingMedia: MediaUpload[],
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  visible: boolean,
  onRemoveImage: (id: string) => void,
  onRemoveVideo: (id: string) => void,
  onImagesOrderChange: (images: ImageData[]) => void,
  onImagesTitleChange: (images: ImageData[]) => void,
  setOnDragEndMethod: any,
  onAttachImage: (variationClientId: string, image: ImageData) => void,
  onImageUploadValidationError: (
    variationClientId: string,
    errors: string[],
    file: File
  ) => void,
  showLinkedInCarouselProps?: Boolean
};

export const MediaAttachments = ({
  visible,
  images,
  imagesTextVariations,
  videos,
  sendMobileReminder,
  instaReels,
  pendingMedia,
  selectedPlatforms,
  selectedProviders,
  onRemoveImage,
  onRemoveVideo,
  onImagesOrderChange,
  onImagesTitleChange,
  setOnDragEndMethod,
  onAttachImage,
  onImageUploadValidationError,
  showLinkedInCarouselProps
}: Props) => {
  const [imagesOrdered, setImagesOrdered] = useState(images);
  const [updatingImage, setUpdatingImage] = useState(false);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (images.length > imagesOrdered.length) {
      setImagesOrdered([...imagesOrdered, images[images.length - 1]]);
    } else if (images.length < imagesOrdered.length) {
      setImagesOrdered(imagesOrdered.filter(image => images.includes(image)));
    }
  }, [images, imagesOrdered]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = useCallback(async result => {
    const reordered = reorder(
      imagesOrdered,
      result.source.index - 1,
      result.destination.index - 1
    );

    const data = reordered.filter(o => o !== undefined);

    setImagesOrdered(data);
    onImagesOrderChange(data);
  });

  const handleRemoveImage = (id: string) => {
    setImagesOrdered(imagesOrdered.filter(image => image.id !== id));

    if (showLinkedInCarouselProps) {
      onImagesTitleChange(imagesOrdered.filter(image => image.id !== id));
    } else {
      onRemoveImage(id);
    }
  };

  const handleImageEditUploadFinish = async (oldImageId, url, file) => {
    setTimeout(async () => {
      setUpdatingImage(false);

      const strippedUrl = url.replace(/\?.*$/, "");

      const clientProvidedSha256 = await hashFile(file);
      const metadata = await analyzeImage(file);
      // const fileName = file.name;

      const res = await apolloClient.mutate({
        mutation: CREATE_IMAGE,
        variables: {
          input: {
            fileSize: metadata.size,
            format: metadata.type,
            width: metadata.width,
            height: metadata.height,
            url: strippedUrl,
            clientProvidedSha256
          }
        }
      });

      const image = res.data.createImage.image;

      const oldImageIndex = imagesOrdered.findIndex(
        image => image.id === oldImageId
      );

      onAttachImage.bind(null, image)();
      onRemoveImage(oldImageId);

      const newImages = [...imagesOrdered];
      newImages[oldImageIndex] = image;
      setImagesOrdered(newImages);
    }, 1000);
  };

  const handleEditImage = async (oldImageId: string, data: string) => {
    const blob = dataURLtoBlob(data);
    const file = new File([blob], `${new Date().getTime()}.png`, {
      type: "image/png"
    });

    const validations = new Promise(resolve =>
      validateImage(
        file,
        IMAGE_VALIDATIONS,
        selectedPlatforms,
        selectedProviders,
        sendMobileReminder
      )
        .then(() => {
          resolve({ file, errors: [] });
        })
        .catch(({ errors }) => {
          resolve({ file, errors });
        })
    );

    const { file: validatedFile, errors } = await validations;
    if (errors.length > 0) {
      onImageUploadValidationError(oldImageId, errors, validatedFile);
      return;
    }

    try {
      setUpdatingImage(true);
      const signedUrl = await fetch(
        `/api/s3_signed_urls?objectName=${file.name}&contentType=${file.type}`,
        {
          method: "GET",
          headers: {
            Accept: "application/vnd.meetedgar.v2+json",
            "Content-Type": "application/vnd.meetedgar.v2+json",
            "X-Authorization": `Bearer ${window.API_KEY}`
          }
        }
      ).then(res => res.json());

      const response = await fetch(signedUrl.signedUrl, {
        method: "PUT",
        body: file
      });

      if (response.status !== 200) {
        throw new Error("Error uploading image");
      }

      handleImageEditUploadFinish(oldImageId, response.url, file);
    } catch (err) {
      console.log(err);
      setUpdatingImage(false);
    }
  };

  let order = 0;
  const imageItems = imagesOrdered
    .filter(o => o?.url !== "" && o !== undefined)
    .map(i => {
      return {
        src: i.url,
        id: i.id,
        order: ++order,
        text: i.text || ""
      };
    });

  const videoItems = videos.map(v => ({
    src: v.url,
    id: v.id
  }));

  useEffect(() => {
    setOnDragEndMethod(onDragEnd);
  }, [onDragEnd, setOnDragEndMethod]);

  const handleUpdateTitleImage = async (oldImageId, textData) => {
    const newImages = [];
    imagesOrdered.forEach(image => {
      if (image.id === oldImageId) {
        newImages.push({ ...image, text: textData });
      } else {
        newImages.push(image);
      }
    });
    setImagesOrdered(newImages);
    onImagesTitleChange(newImages);
  };
  return (
    <Expander expanded={visible}>
      <AttachmentContainer
        hasClearBackground={images.length + pendingMedia.length <= 1}
      >
        <MediaList
          showLinkedInCarouselProps={showLinkedInCarouselProps}
          images={imageItems}
          imagesTextVariations={imagesTextVariations}
          videos={videoItems}
          caption={
            <Caption
              images={images}
              video={videos[0]}
              videos={videos}
              sendMobileReminder={sendMobileReminder}
              instaReels={instaReels}
              pendingMedia={pendingMedia}
              selectedPlatforms={selectedPlatforms}
              selectedProviders={selectedProviders}
            />
          }
          pendingMedia={pendingMedia}
          onRemoveImage={handleRemoveImage}
          onRemoveVideo={onRemoveVideo}
          onEditImage={handleEditImage}
          updatingImage={updatingImage}
          onDragEnd={onDragEnd}
          handleUpdateTitleImage={handleUpdateTitleImage}
        />
      </AttachmentContainer>
    </Expander>
  );
};

export default MediaAttachments;
