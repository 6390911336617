/* @flow */
import React, { type Node } from "react";
import classNames from "classnames";
import Icon from "icons/Icon";
import Link from "links/Link";
import Caption from "typography/Caption";
import H1 from "typography/H1";
import styles from "./index.css";

type Props = {
  children: Node,
  helpUrl?: string,
  subtitle?: string,
  className?: string,
  noFlexBasis?: boolean,
  extras?: Node
};

const PageTitle = ({
  children,
  helpUrl,
  subtitle,
  className,
  noFlexBasis,
  extras
}: Props) => (
  <span
    className={classNames(styles.root, className, {
      [styles.noFlexBasis]: noFlexBasis
    })}
  >
    <div className={styles.left}>
      <H1 className={styles.title}>{children}</H1>
      {helpUrl ? (
        <Link className={styles.link} url={helpUrl} target="blank">
          <Icon type="question-circle" />
        </Link>
      ) : null}
      {extras}
    </div>
    {subtitle ? (
      <div className={styles.right}>
        <Caption>{subtitle}</Caption>
      </div>
    ) : null}
  </span>
);

export default PageTitle;
