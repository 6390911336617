/* @flow */
import React from "react";
import Skeleton from "react-loading-skeleton";

import { BodyRow, BodyItem } from "../styles";

const BodySkeleton = () => {
  return (
    <>
      {Array(5)
        .fill()
        .map((item, index) => (
          <BodyRow key={index}>
            <BodyItem className="skeleton label">
              <Skeleton height={20} width="100%" />
            </BodyItem>
            <BodyItem className="skeleton span-col-4">
              <Skeleton height={20} />
            </BodyItem>
            <BodyItem className="skeleton span-col-4">
              <Skeleton height={20} />
            </BodyItem>
            <BodyItem className="skeleton span-col-4">
              <Skeleton height={20} />
            </BodyItem>
            <BodyItem className="skeleton span-col-4">
              <Skeleton height={20} />
            </BodyItem>
          </BodyRow>
        ))}
    </>
  );
};

export default BodySkeleton;
