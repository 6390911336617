/* @flow */
import React, { Component } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { get } from "lodash";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { type OnboardingStatus } from "./selectOnboardingStatus";
import { H3, Caption } from "typography";
import { CloseButton } from "buttons";
import AddMoreContentDialog from "./AddMoreContentDialog";
import OnboardingCompleteDialog from "./OnboardingCompleteDialog";
import StepBubble from "./StepBubble";
import StepInstruction from "./StepInstruction";
import { ORDERED_STEPS } from "./constants.js";
import { buildOnboardingStatus, allStepsCompleted } from "./util.js";
import styles from "./index.css";

import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import GET_ONBOARDING_PROGRESS from "queries/getOnboardingProgress";

import { useNewUICached } from "util";

type Props = {
  onboardingStatus: OnboardingStatus,
  onDismiss: () => void
};

type State = {
  closing: boolean,
  finished: boolean,
  askAddMoreContent: boolean,
  completeDialogHidden: boolean
};

export class OnboardingBar extends Component<Props, State> {
  state = {
    closing: false,
    finished: false,
    askAddMoreContent: false,
    completeDialogHidden: false
  };

  onCloseAddMoreContent = () => {
    this.setState({ askAddMoreContent: false });
  };

  onCloseCompleteDialog = () => {
    this.setState({ completeDialogHidden: true });
  };

  componentWillReceiveProps(nextProps: Props) {
    const { hidden, completedSteps } = this.props.onboardingStatus;
    const {
      hidden: nextHidden,
      completedSteps: nextCompletedSteps
    } = nextProps.onboardingStatus;
    if (!hidden && nextHidden) {
      this.setState({ closing: true });
    }
    if (
      !allStepsCompleted(completedSteps) &&
      allStepsCompleted(nextCompletedSteps)
    ) {
      this.setState({ finished: true });
    }

    if (
      this.props.onboardingStatus.hidden &&
      !nextProps.onboardingStatus.hidden
    ) {
      this.setState({ closing: false });
    }

    if (
      !this.props.onboardingStatus.completedSteps.includes("addContent") &&
      nextProps.onboardingStatus.completedSteps.includes("addContent")
    ) {
      this.setState({ askAddMoreContent: true });
    }
  }

  render() {
    const useNewUI = useNewUICached();
    const currentPath = window.location.pathname;
    const pathsToCheck = ["/categories", "/accounts", "/import", "/statistics"];

    const {
      onboardingStatus: {
        currentStep,
        currentStepIndex,
        completedSteps,
        locationStatus,
        hidden
      },
      onboardingStatus,
      onDismiss
    } = this.props;

    const {
      closing,
      finished,
      askAddMoreContent,
      completeDialogHidden
    } = this.state;

    if (hidden && !closing) return null;

    const stepCount =
      locationStatus === "onLastCompletedStep"
        ? currentStepIndex
        : currentStepIndex + 1;

    return (
      <div className={classNames("", {})}>
        {askAddMoreContent && (
          <AddMoreContentDialog onClose={this.onCloseAddMoreContent} />
        )}
        {finished && !completeDialogHidden && (
          <OnboardingCompleteDialog onClose={this.onCloseCompleteDialog} />
        )}
        <CSSTransition
          classNames={{
            exit: styles.exit,
            exitActive: styles.exitActive,
            exitDone: styles.exitDone,
            enter: styles.enter,
            enterActive: styles.enterActive,
            enterDone: styles.enterDone
          }}
          timeout={600}
          in={!closing}
        >
          <div style={{ position: "relative" }}>
            <CloseButton
              confirm
              confirmType="info"
              confirmMessage="You're about to dismiss Edgar's getting started guide. You can resume the guide again from the top drop down menu if you change your mind."
              onClick={onDismiss}
            />
            <div
              className={classNames(styles.root, {
                [styles.newRootUI]:
                  useNewUI && pathsToCheck.some(v => currentPath.includes(v))
              })}
            >
              <div className={styles.wrapper}>
                <div className={styles.stepsWrapper}>
                  <H3>Welcome, let’s get started!</H3>
                  <div>
                    <Caption
                      className={styles.stepsLabel}
                    >{`Step ${stepCount} of 4`}</Caption>
                    <div className={styles.bubbles}>
                      {ORDERED_STEPS.map(step => (
                        <StepBubble
                          key={step}
                          isCurrent={step === currentStep}
                          isCompleted={completedSteps.includes(step)}
                          step={step}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className={styles.instructionsWrapper}>
                  <StepInstruction onboardingStatus={onboardingStatus} />
                </div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default () => {
  // Queries
  const { data } = useQuery(GET_ONBOARDING_PROGRESS);

  // Mutations
  const [dismissOnboarding, {}] = useMutation(DISMISS_ONBOARDING);

  const onboardingProgress = get(data, "user.onboardingProgress");

  if (!onboardingProgress) {
    return null;
  }

  return (
    <OnboardingBar
      onboardingStatus={buildOnboardingStatus(
        onboardingProgress,
        window.location.pathname
      )}
      onDismiss={dismissOnboarding}
    />
  );
};
