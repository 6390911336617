/* @flow */
import React from "react";
import styles from "./index.css";
import ReportLink from "./ReportLink";
import { H4 } from "typography";

type Report = {
  endDate: string
};

type Props = {
  reports: Report[]
};

export const ReportList = (props: Props) => {
  const { reports } = props;
  if (reports.length === 0) {
    return null;
  }
  return (
    <div className={styles.root}>
      <H4 className={styles.heading}>View Reports</H4>
      <ul className={styles.listContainer}>
        {reports.map(report => (
          <ReportLink key={report.endDate} endDate={report.endDate} />
        ))}
      </ul>
    </div>
  );
};

export default ReportList;
