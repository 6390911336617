/* @flow */
import React from "react";
import ReactTooltip from "react-tooltip";

import RadioButton2 from "components/common/forms/RadioButton2";
import styles from "./index.css";

type Props = {
  sendMobileReminder: boolean,
  instaReels: boolean,
  hasMobileDevices: Boolean,
  onChange: (sendMobileReminder: boolean, instaReels?: boolean) => void
};

export const InstagramBusinessSettings = ({
  sendMobileReminder,
  instaReels,
  hasMobileDevices,
  onChange
}: Props) => {
  const handleChange = (value: boolean | "instaReels") => {
    if (value === "instaReels") {
      onChange(false, true);
    } else {
      onChange(value);
    }
  };

  const mobileReminderOptionDisabled = !hasMobileDevices;

  return (
    <>
      <div
        data-for="direct-to-feed-tooltip"
        data-tip="Instagram will crop all images according to the first image"
      >
        <RadioButton2
          className={styles.radioButton}
          value={false}
          selected={!sendMobileReminder && !instaReels}
          onClick={handleChange}
        >
          <span className={styles.title}>Direct to feed</span>
        </RadioButton2>
        <small className={styles.description}>
          For posts with a maximum of 10 images and/or videos to business
          accounts
        </small>
      </div>
      <div
        data-for="direct-to-feed-tooltip"
        data-tip="Instagram videos are now Reels"
      >
        <RadioButton2
          className={styles.radioButton}
          value="instaReels"
          selected={instaReels}
          onClick={handleChange}
        >
          <span className={styles.title}>IG Reels</span>
        </RadioButton2>
        <small className={styles.description}>
          For a single video post to business accounts
        </small>
      </div>
      <div
        style={{
          pointerEvents: mobileReminderOptionDisabled ? "none" : null,
          opacity: mobileReminderOptionDisabled ? "0.5" : null
        }}
      >
        <RadioButton2
          className={styles.radioButton}
          value={true}
          selected={sendMobileReminder}
          onClick={handleChange}
        >
          <span className={styles.title}>Mobile reminder</span>
        </RadioButton2>
        <small className={styles.description}>
          Use for stories, multi-image posts, video posts, and non-business
          accounts{" "}
        </small>
      </div>

      {mobileReminderOptionDisabled && (
        <div className={styles.description}>
          <a
            href="https://help.meetedgar.com/en/articles/2480839-connecting-instagram-accounts-to-edgar"
            target="_blank"
          >
            Learn how
          </a>{" "}
          to set up mobile reminders
        </div>
      )}

      <ReactTooltip effect="solid" place="bottom" id="direct-to-feed-tooltip" />
    </>
  );
};

export default InstagramBusinessSettings;
