import { gql } from "apollo-boost";

export const GET_INVITATION = gql`
  query retrieveInvitation($token: String!) {
    invitation(token: $token) {
      id
      email
    }
  }
`;
