/* @flow */
import React from "react";
import classNames from "classnames";
import { capitalize } from "util";
import styles from "./index.css";

// TODO add tests
export type AnimationType = "spin" | "pulse";
type Size = "lg" | "2x" | "3x" | "4x" | "5x" | null;
type Props = {
  base: "fa" | "fas" | "far" | "fal" | "fad" | null,
  type: string,
  animation?: AnimationType,
  size?: Size,
  color?: "primary" | "textMid" | "textDark" | "danger" | "warning",
  className?: string
};

const ICON_TYPE_OVERRIDES = {
  "instagram-square": "instagram",
  "tiktok-square": "tiktok"
};

const Icon = ({
  base,
  type,
  animation,
  size,
  className,
  color,
  ...others
}: Props) => {
  const baseClass =
    base || ICON_TYPE_OVERRIDES[type] == "tiktok" ? "fab" : "fa";
  const animClass = animation ? `fa-${animation}` : "";
  const sizeClass = size ? `fa-${size}` : "";
  const typeClass = `fa-${ICON_TYPE_OVERRIDES[type] || type}`;
  const classes = classNames(baseClass, typeClass, sizeClass, animClass, {
    [className || ""]: !!className,
    [styles[`color${capitalize(color)}`] || ""]: !!color
  });
  return <i className={classes} {...others} />;
};

export default Icon;
