// @flow
import { createSelector, type OutputSelector } from "reselect";
import selectRawState, { type FlashState } from "../raw";

import type { AppState, Flash } from "types";
export type { Flash };

const selectAsArray: OutputSelector<AppState, void, Flash[]> = createSelector(
  selectRawState,
  ({ flashes }: FlashState): Flash[] =>
    Object.keys(flashes).map(key => flashes[key])
);

export default selectAsArray;
