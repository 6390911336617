// @flow

import React, { Component, createElement } from "react";

import SquareTwo from "./SquareTwo";
import SquareThree from "./SquareThree";
import SquareFour from "./SquareFour";
import PortraitTwo from "./PortraitTwo";
import PortraitThree from "./PortraitThree";
import PortraitFour from "./PortraitFour";
import PortraitFive from "./PortraitFive";
import LandscapeTwo from "./LandscapeTwo";
import LandscapeThree from "./LandscapeThree";
import LandscapeFour from "./LandscapeFour";
import LandscapeFive from "./LandscapeFive";

export const ORIENTATION_SQUARE = "ORIENTATION_SQUARE";
export const ORIENTATION_PORTRAIT = "ORIENTATION_PORTRAIT";
export const ORIENTATION_LANDSCAPE = "ORIENTATION_LANDSCAPE";
export const ORIENTATION_UNKNOWN = "ORIENTATION_UNKNOWN";

const SQUARE_WIDTH_HEIGHT_FUDGE_FACTOR = 0.02;
const SQUARE_MIN_RATIO = 1.0 - SQUARE_WIDTH_HEIGHT_FUDGE_FACTOR;
const SQUARE_MAX_RATIO = 1.0 + SQUARE_WIDTH_HEIGHT_FUDGE_FACTOR;

// Silly linter, they're quoted 'cuz they're numbers!
/* eslint quote-props: 0 */
const LAYOUTS = {
  ORIENTATION_PORTRAIT: {
    "2": PortraitTwo,
    "3": PortraitThree,
    "4": PortraitFour,
    "5": PortraitFive
  },
  ORIENTATION_LANDSCAPE: {
    "2": LandscapeTwo,
    "3": LandscapeThree,
    "4": LandscapeFour,
    "5": LandscapeFive
  },
  ORIENTATION_SQUARE: {
    "2": SquareTwo,
    "3": SquareThree,
    "4": SquareFour,
    "5": SquareFour
  },
  ORIENTATION_UNKNOWN: {} // keeps flow happy in chooseLayout() :-/
};

type Props = {
  urls: string[]
};

type Orientation =
  | "ORIENTATION_SQUARE"
  | "ORIENTATION_LANDSCAPE"
  | "ORIENTATION_PORTRAIT"
  | "ORIENTATION_UNKNOWN";

type State = {
  primaryImage: ?HTMLImageElement,
  orientation: Orientation
};

export default class ImageCollage extends Component<Props, State> {
  state = { primaryImage: null, orientation: ORIENTATION_UNKNOWN };

  componentDidMount() {
    const { urls } = this.props;
    const primaryImageUrl = urls[0];

    const image = new window.Image();
    image.addEventListener("load", this.onPrimaryImageLoad.bind(this));
    image.src = primaryImageUrl;
  }

  componentWillUnmount() {
    if (this.state.primaryImage) {
      this.state.primaryImage.removeEventListener(
        "load",
        this.onPrimaryImageLoad
      );
    }
  }

  onPrimaryImageLoad({ target }: Event) {
    if (target instanceof HTMLImageElement) {
      const { width, height } = target;

      let orientation = ORIENTATION_SQUARE;
      if (height !== 0) {
        const ratio = width / height;
        if (ratio < SQUARE_MIN_RATIO) {
          orientation = ORIENTATION_PORTRAIT;
        } else if (ratio > SQUARE_MAX_RATIO) {
          orientation = ORIENTATION_LANDSCAPE;
        }
      }
      this.setState({ orientation });
    }
  }

  chooseLayout() {
    const { urls } = this.props;
    const { orientation } = this.state;
    if (orientation === ORIENTATION_UNKNOWN) {
      return null;
    }

    const lengthIndex = (urls.length > 5 ? 5 : urls.length).toString();
    return createElement(LAYOUTS[orientation][lengthIndex], { urls });
  }

  render() {
    return <div>{this.chooseLayout()}</div>;
  }
}
