/* @flow */
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import GET_ACCOUNTS from "queries/getAccounts";

import type { getAccounts_accounts } from "../../../../graphql/types/getAccounts";
import type { Props } from "./types.js";

const useAccountSelect = (props: Props) => {
  const { selectAccount } = props;

  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);
      try {
        const res = await apolloClient.query({
          query: GET_ACCOUNTS,
          variables: { withDashboardSupport: true }
        });

        setAccounts(get(res, "data.accounts", []));
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [apolloClient]);

  const showDropdown = () => setDropdownVisible(true);
  const hideDropdown = () => setDropdownVisible(false);

  const handleAccount = (account: getAccounts_accounts) => {
    selectAccount(account);
    setTimeout(() => {
      setDropdownVisible(false);
    }, 100);
  };

  return {
    loading,
    accounts,
    dropdownVisible,
    showDropdown,
    hideDropdown,
    handleAccount
  };
};

export default useAccountSelect;
