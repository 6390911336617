/* @flow */
import React, { Component } from "react";
import truncate from "lodash/truncate";

import type {
  loadContent_content_accountRelationships_account as Account,
  loadContent_content_variations as VariationData
} from "graphql-types/loadContent";

import styles from "./index.css";

type Props = {
  variation: VariationData,
  account: Account
};

class Caption extends Component<Props> {
  displayText() {
    const { variation } = this.props;
    // we use the mark element here because applying a CSS module
    // mangled classname makes the string itself exceed the maximum
    // length of the truncated text, which seems to make the
    // truncate function barf. "Mark" doesn't seem too far off
    // semantically. Instagram appears to clip this server-side (at
    // least on the web app), so the length value is just a SWAG.
    const shortened = truncate(variation.text, {
      length: 120,
      omission: "<mark>&nbsp;more</mark>",
      separator: " "
    });
    return {
      __html: shortened.replace(
        /([\#|\@][a-zA-Z0-9\-\_]+)/g,
        `<span class="${styles.hashtag}">$1</span>`
      )
    };
  }

  render() {
    const { account } = this.props;
    return (
      <div className={styles.root}>
        <span dangerouslySetInnerHTML={this.displayText()} />
      </div>
    );
  }
}

export default Caption;
