// @flow
import React from "react";
import { Caption } from "typography";
import Icon from "icons/Icon";
import styles from "./index.css";

type Props = {
  useShortLinks: boolean,
  showFacebookDebuggerLink: boolean,
  link: ?string
};

const postPreviewHelpUrl =
  "http://help.meetedgar.com/content-and-library/how-do-i-preview-content-before-posting";
const fbSharingDebuggerUrl = (link: ?string): string => {
  if (link) {
    return `https://developers.facebook.com/tools/debug/sharing/?q=${link}`;
  }
  return "";
};

const HelpText = ({ useShortLinks, showFacebookDebuggerLink, link }: Props) => (
  <Caption helpText className={styles.root}>
    <Icon type="info-circle" className={styles.infoIcon} />
    <div>
      <span>
        This preview is an estimation of how your content should appear. Updates
        or tests made by the social network may change the result. Read more{" "}
        <a href={postPreviewHelpUrl} target="_blank">
          here
        </a>
        .
        {useShortLinks && (
          <span> Your links will be shortened prior to publishing.</span>
        )}
        {showFacebookDebuggerLink && (
          <span>
            {" "}
            Not quite what you were expecting to see? Try viewing your link in{" "}
            <a target="_blank" href={fbSharingDebuggerUrl(link)}>
              Facebook's Sharing Debugger
            </a>
            .
          </span>
        )}
      </span>
    </div>
  </Caption>
);

export default HelpText;
