import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 4px 0;
  position: relative;
  z-index: 2;
`;

export const Slot = styled.div`
  animation-duration: 2s;
  animation-fill-mode: both;
  background-color: #fff;
  border-radius: 7.5px;
  border: 1px solid white;
  color: var(--sanddollar500);
  cursor: pointer;
  margin: 0 2.5px;
  overflow: hidden;
  word-break: break-word;

  &:hover {
    box-shadow: 0 2px 7px -2px rgba(0, 0, 0, 0.1);
  }
`;

export const SlotWrapper = styled.div`
  padding: 5px;
`;

export const SuggestedSlot = styled(Slot)`
  background: repeating-linear-gradient(
    -55deg,
    #f7f7f7,
    #f7f7f7 10px,
    #fff 10px,
    #fff 20px
  );
  color: black;
  padding: 5px;
`;

export const TimeLabel = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: space-between;

  svg {
    width: 13px;
  }
`;

export const TimeLeft = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const TimeText = styled.div`
  @media (min-width: 900px) {
    width: 53px;
  }
`;

export const SlotLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin: 1.5px 0 0;
`;

export const AccountBadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;

export const AccountBadgeWrapper = styled.div`
  padding: 0 1.5px;
`;
