/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.div`
  align-items: center;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  margin: 0 16px 16px 0;
  padding: 4px;

  &.active,
  &:hover {
    color: #4d948f;
    border-color: #4d948f;
  }

  &.active {
    cursor: default;
  }
`;

export const AddVariation = styled.div`
  div {
    align-items: center;
    background: #f0f0f0;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    height: 32px;
    justify-content: center;
    padding: 4px 8px;

    &:hover {
      opacity: 0.8;
    }

    img {
      margin-right: 8px;
    }
  }
`;
