import { gql } from "apollo-boost";
import { ALL_CATEGORY_FIELDS_FRAGMENT } from "../fragments/category";

export const GET_CATEGORIES = gql`
  ${ALL_CATEGORY_FIELDS_FRAGMENT}

  query GetCategories {
    categories {
      ...allCategoryFields
      approvedContentsCount
    }
    company {
      id
      categoryLimit
    }
  }
`;

export const CATEGORIES_SELECTOR = gql`
  ${ALL_CATEGORY_FIELDS_FRAGMENT}

  query CategorySelector {
    categories {
      ...allCategoryFields
    }
    company {
      id
      categoryLimit
    }
  }
`;

export const GET_CATEGORIES_WITH_TIMESLOT_COUNT = gql`
  ${ALL_CATEGORY_FIELDS_FRAGMENT}

  query GetCategoriesWithTimeslotCount {
    categories {
      ...allCategoryFields
      approvedContentsCount
      timeslotCount
      randomTimeslotCount
    }
    company {
      id
      categoryLimit
    }
  }
`;
