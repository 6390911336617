/* @flow */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";

import { Link } from "links";
import Icon from "icons/Icon";

import { featureFlag } from "util";
import NewUISwitch from "components/NewUISwitch";
import type { AppState, Dispatch } from "types";
import UpgradeModal from "components/UpgradeModal";
import { toggleNavbarCollapseAction } from "actions/layout";
import MEIcon from "components/common/images/meetedgar-icon-white.png";
import InkyImage from "../../pages/NewComposer/Variation/TextField/AIGenerator/Images/inky.png";

import MELetters from "./icons/meetedgar-letters.png";

import useSidebarNav from "./use";
import type { IProps, ItemWrapperProps } from "./types.js";
import {
  Wrapper,
  Sidebar,
  Header,
  Body,
  Item,
  Footer,
  Collapse,
  AlertIcon,
  LitePlanUpgrade
} from "./styles";

import IconCollapse from "./icons/collapse.svg";
import IconMEUniversity from "./icons/edgar-university.svg";
import IconDashboard from "./icons/dashboard.svg";
import IconInbox from "./icons/inbox.svg";
import IconLibrary from "./icons/library.svg";
import IconQueue from "./icons/queue.svg";
import IconSchedule from "./icons/schedule.svg";
import IconSettings from "./icons/settings.svg";
import IconMoney from "./icons/money.svg";
// import IconImport from "./icons/import.svg";
// import IconCategories from "./icons/categories.svg";
// import IconAccounts from "./icons/accounts.svg";
// import IconHistory from "./icons/history.svg";

function getWindowWidth() {
  return window.innerWidth;
}

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

export let ItemWrapperExport;

const SidebarNav = (props: IProps) => {
  const windowWidth = useWindowWidth();
  const {
    toggleCollapsed,
    showInboxModal,
    handleInboxModal,
    hideInboxModal
  } = useSidebarNav(props, ReactTooltip, windowWidth);
  const { activePage, navbarCollapsed: collapsed } = props;

  const ItemWrapper = ({
    slug,
    icon,
    text,
    error,
    action,
    litePlanUpgrade
  }: ItemWrapperProps) => (
    <Item
      href={slug}
      className={`${activePage === slug ? "active " : ""}${collapsed ? "collapsed" : ""
        }`}
      data-for="sidebar-tooltip"
      data-tip={collapsed || windowWidth < 1300 ? text : ""}
      onClick={action}
    >
      <img src={icon} alt={`${text} icon`} />
      {!collapsed && <span>{text}</span>}
      {error ? (
        <AlertIcon>
          <div className="background" />
          <Icon type="exclamation-circle" />
        </AlertIcon>
      ) : litePlanUpgrade && !collapsed ? (
        <LitePlanUpgrade>EDGAR PLAN</LitePlanUpgrade>
      ) : null}
    </Item>
  );

  ItemWrapperExport = ItemWrapper;

  return (
    <>
      <Wrapper>
        <Sidebar className={collapsed ? "collapsed" : ""}>
          <Link url="/dashboard">
            <Header>
              <img src={MEIcon} alt="MeetEdgar Icon" className="icon" />
              {!collapsed && (
                <img
                  src={MELetters}
                  alt="MeetEdgar Logo Letters"
                  className="letters"
                />
              )}
            </Header>
          </Link>

          <Body>
            <ItemWrapper
              slug="/dashboard"
              icon={IconDashboard}
              text="Dashboard"
            />
            <ItemWrapper
              slug={featureFlag("fb_comments") ? "/inbox" : null}
              icon={IconInbox}
              text="Inbox"
              litePlanUpgrade={!featureFlag("fb_comments")}
              action={
                !featureFlag("fb_comments") ? () => handleInboxModal() : null
              }
            />
            <ItemWrapper slug="/contents" icon={IconLibrary} text="Library" />
            <ItemWrapper slug="/queue" icon={IconQueue} text="Queue" />
            <ItemWrapper slug="/schedule" icon={IconSchedule} text="Schedule" />
            {/* <ItemWrapper slug="/import" icon={IconImport} text="Import" /> */}
            {/* <ItemWrapper slug="/categories" icon={IconCategories} text="Categories" /> */}
            {/* <ItemWrapper slug="/accounts" icon={IconAccounts} text="Accounts" /> */}
            {/* <ItemWrapper slug="/history" icon={IconHistory} text="History" /> */}
            <ItemWrapper
              slug="/settings-bundle"
              icon={IconSettings}
              text="Settings"
              error={props.companyStatus?.hasDisabledAccounts}
            />
          </Body>

          <Footer>
            <NewUISwitch toShow="old" />
            {collapsed ? (
              <>
                <p className="new">New!!</p>
                <img className={"inky-collapsed"} src={InkyImage} alt="inky" />
              </>
            ) : (
              <>
                <img className={"inky"} src={InkyImage} alt="inky" />
                <p className={"inky-text"}>
                  "Introducing Inky, your AI content generation assistant.
                  Switch to the new composer to use Inky."
                </p>
              </>
            )}
            <Item
              href="https://affiliates.meetedgar.com/"
              target="_blank"
              className={collapsed ? "collapsed" : ""}
            >
              <img src={IconMoney} alt="Share Edgar" />
              {!collapsed && <span>Share Edgar</span>}
            </Item>
          </Footer>
        </Sidebar>

        <Collapse
          title="Toggle navbar"
          className={`nav-collapse-button ${collapsed ? "collapsed" : ""}`}
          onClick={toggleCollapsed}
        >
          <img src={IconCollapse} alt="Toggle navbar" />
        </Collapse>
      </Wrapper>

      <ReactTooltip
        effect="solid"
        place="right"
        type="info"
        backgroundColor="#000"
        id="sidebar-tooltip"
      />
      {showInboxModal && (
        <UpgradeModal
          name="accountLimitReached"
          onClose={hideInboxModal}
          title="Upgrade your plan"
          settingsButton
          subTitle={
            <>
              The feature is only available with the Edgar Standard Plan.
              <br />
              <a
                href="https://help.meetedgar.com/en/articles/6213008-the-inbox-feature"
                target="_blank"
              >
                Learn more about this feature.
              </a>
            </>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  navbarCollapsed: state.layout?.data.navbarCollapsed
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toggleCollapsed: (toggle: boolean) => {
    dispatch(toggleNavbarCollapseAction(toggle));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarNav);
