// @flow
import React, { type Node, Component } from "react";
import { DefaultPlayer } from "react-html5video";
// $FlowFixMe
import "react-html5video/dist/styles.css";
import RemoveButton from "components/common/buttons/RemoveButton";
import styles from "./index.css";

type Props = {
  src: string,
  overlay?: Node,
  onRemove?: () => void
};

export default class Video extends Component<Props> {
  handleRemove = () => {
    const { onRemove } = this.props;
    onRemove && onRemove();
  };

  render() {
    const { src, overlay, onRemove } = this.props;
    return (
      <div className={styles.root}>
        <DefaultPlayer controls={[]} className={styles.video}>
          <source src={src} type="video/mp4" />
        </DefaultPlayer>
        {!!overlay && <div className={styles.overlay}>{overlay}</div>}
        {!!onRemove && <RemoveButton onClick={this.handleRemove} />}
      </div>
    );
  }
}
