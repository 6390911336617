/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation DeleteAccount($id: ID!) {
    deleteAccount(id: $id) {
      deletedId
      errors
    }
  }
`;
