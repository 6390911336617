/* @flow */
import React, { useEffect } from "react";
import Overlay from "behavioral/Overlay";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  open: boolean,
  anchor?: "bottomRight" | "bottomLeft",
  className?: string,
  includeOverlay?: boolean,
  omitDefaultStyle?: boolean,
  onClose: () => void,
  children?: any,
  handleEscapeKeyPress?: (evt: KeyboardEvent) => void
};

const ESC_KEY_CODE = 27;

const DropDown = (props: Props) => {
  const {
    open,
    anchor = "bottomLeft",
    className,
    omitDefaultStyle,
    includeOverlay = true,
    onClose,
    children,
    handleEscapeKeyPress
  } = props;
  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent): void => {
      if (open && handleEscapeKeyPress && evt.keyCode === ESC_KEY_CODE) {
        handleEscapeKeyPress(evt);
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [handleEscapeKeyPress, open]);

  const dropShadowedClasses = classNames(styles.dropShadowed, {
    [className || ""]: !!className,
    [styles.anchoredBottomLeft]: anchor === "bottomLeft",
    [styles.anchoredBottomRight]: anchor === "bottomRight",
    [styles.omitDefaultStyle]: !!omitDefaultStyle
  });

  return (
    <div
      className={styles.root}
      style={{ display: open ? "block" : "none" }}
      onClick={onClose}
    >
      {includeOverlay && <Overlay onClose={onClose} />}
      <div className={dropShadowedClasses}>{children}</div>
    </div>
  );
};

export default DropDown;
