// @flow
import { get, omit } from "lodash";
import qs from "qs";

import type { Location, Match } from "react-router-dom";
import type { ContentFilter } from "types";

const CONTENT_STATUSES = ["pending", "approved", "rejected", "expired"];

export const extractFilter = (
  match: Match,
  location: Location
): ContentFilter => {
  const status = get(match, "params.status", "approved");
  if (!CONTENT_STATUSES.includes(status)) {
    throw new Error("Unknown content status in path");
  }

  const qsAsHash = qs.parse(location.search.replace(/^\?/, ""));

  return {
    status,
    ...qsAsHash.filter
  };
};

export const patchNextSearchFilter = (
  search: string,
  nextFilter: ContentFilter
): string => {
  let qsAsHash = qs.parse(search.replace(/^\?/, ""));
  qsAsHash.filter = qsAsHash.filter || {};
  Object.keys(nextFilter).forEach(filterKey => {
    if (nextFilter[filterKey]) {
      qsAsHash.filter[filterKey] = nextFilter[filterKey];
    } else {
      qsAsHash.filter = omit(qsAsHash.filter, filterKey);
    }
  });
  qsAsHash = omit(qsAsHash, "page");
  return qs.stringify(qsAsHash);
};
