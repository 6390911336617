import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Link from "links/Link";
import Service from "./Service";
import BitlyTooltip from "./BitlyTooltip";

import type { FlashType } from "reducers/flash";
import type {
  updateLinkShortener as UpdateLinkShortenerResult,
  updateLinkShortenerVariables
} from "graphql-types/updateLinkShortener";

import { featureFlag } from "util";
import styles from "./index.css";

type Props = {
  display?: string,
  flash: (type: FlashType, body: string) => void,
  service?: string,
  updateLinkShortener?: ({
    variables: updateLinkShortenerVariables
  }) => Promise<{ data: UpdateLinkShortenerResult }>,
  updateLinkShortenerLoading?: boolean,
  user?: any
};

const BITLY_SERVICE = "bitly";
const NO_SERVICE = "do_not_shorten";
const REBRANDLY_SERVICE = "rebrandly";

const LinkShortenerSettings = ({
  display = "None",
  flash,
  service = NO_SERVICE,
  updateLinkShortener,
  updateLinkShortenerLoading,
  user,
  defaultOpened = false
}: Props) => {
  const [state, updateState] = useState({
    isModalOpen: defaultOpened,
    selectedService: service
  });
  const closeModal = _evt => {
    updateState({ ...state, isModalOpen: false });
  };
  const selectService = (selectedService: string) => {
    updateState({ ...state, selectedService });
  };
  return (
    <>
      {state.isModalOpen && (
        <Modal
          footer={
            <>
              <Button
                className={styles.buttonCancel}
                onClick={closeModal}
                type="secondary"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  state.selectedService === service ||
                  updateLinkShortenerLoading
                }
                onClick={(): void => {
                  // These services require OAuth, so we will bypass the mutation.
                  if (state.selectedService === BITLY_SERVICE) {
                    location.href = `https://bitly.com/oauth/authorize?client_id=${process.env.BITLY_CLIENT_ID}&redirect_uri=${process.env.BITLY_REDIRECT_URL}&response_type=code`;
                    return;
                  } else if(state.selectedService === REBRANDLY_SERVICE){
                    location.href = `/auth/${state.selectedService}`;
                    return;
                  }

                  const promise = updateLinkShortener?.({
                    variables: { service: state.selectedService }
                  });
                  if (promise) {
                    promise.then(response => {
                      const errors =
                        response?.data?.updateLinkShortener?.errors || [];
                      if (errors.length > 0) {
                        flash("error", errors[0]);
                      } else {
                        closeModal();
                        flash(
                          "notice",
                          "Link shortener has been successfully updated!"
                        );
                      }
                    });
                  }
                }}
                type="primary"
              >
                Apply
              </Button>
            </>
          }
          onClose={closeModal}
          title="Link Shortening Options"
        >
          <div className={styles.main}>
            <Service
              caption={
                <>Your links will appear exactly the way you enter them.</>
              }
              label="Don't shorten any links"
              selectService={selectService}
              selectedService={state.selectedService}
              service={NO_SERVICE}
            />
            {user &&
            (!!user?.hideEdGrShortenLinks ||
              featureFlag("hide_edgr")) ? null : (
              <Service
                caption={
                  <>
                    Get click statistics. No setup, always free with no limits..
                  </>
                }
                label="Use ed.gr to shorten links"
                selectService={selectService}
                selectedService={state.selectedService}
                service="edgr"
              />
            )}
            <Service
              caption={
                <>
                  <a href="https://www.rebrandly.com" target="_blank">
                    Get a free Rebrandly account
                  </a>
                  &nbsp;, use your own domain name, and get click statistics.
                </>
              }
              label="Use Rebrand.ly to shorten links"
              selectService={selectService}
              selectedService={state.selectedService}
              service={REBRANDLY_SERVICE}
            />
            <Service
              caption={
                <>
                  Connect Edgar to your existing Bitly account. (Subject to
                  Bitly rate limiting &ndash; <BitlyTooltip />)
                </>
              }
              label="Use Bit.ly to shorten links"
              selectService={selectService}
              selectedService={state.selectedService}
              service={BITLY_SERVICE}
            />
          </div>
        </Modal>
      )}
      <span>
        {display} (
        <Link
          onClick={(): void => {
            updateState({ ...state, isModalOpen: true });
          }}
        >
          Change
        </Link>
        )
      </span>
    </>
  );
};

export default LinkShortenerSettings;
