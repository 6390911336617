// @flow
import { createSelector, type OutputSelector } from "reselect";
import invariant from "invariant";
import selectRawState, { type UsersState } from "../raw";
import type { AppState } from "types";

const selectDismissedInformationBoxes: OutputSelector<
  AppState,
  void,
  { [string]: string }
> = createSelector(
  selectRawState,
  (usersState: UsersState): { [string]: string } => {
    invariant(usersState.data.length > 0, "Expected a user to be present");
    return usersState.data[0].attributes.dismissedInformationBoxes;
  }
);

export default selectDismissedInformationBoxes;
