/* @flow */
import React from "react";
import Header from "./Header";
import Media from "./Media";
import Caption from "./Caption";
import styles from "./index.css";

import type { loadContent_content_variations as VariationData } from "graphql-types/loadContent";
import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";

type Props = {
  variation: VariationData,
  account: AccountData
};

const InstagramPost = ({ account, variation }: Props) => (
  <div className={styles.root}>
    <Header account={account} />
    <Caption account={account} variation={variation} />
    <Media variation={variation} />
  </div>
);

export default InstagramPost;
