/* @flow */
import React from "react";

import useNewCategory from "./use";
import type { Props } from "./types.js";

import AddCategoryOption from "./AddCategoryOption";
import AddCategoryModal from "./AddCategoryModal";

const NewCategory = ({ onAddedCategory }: Props) => {
  const { isModalVisible, showModal, hideModal } = useNewCategory();

  return (
    <>
      <AddCategoryOption showModal={showModal} />
      {isModalVisible && (
        <AddCategoryModal
          hideModal={hideModal}
          onAddedCategory={onAddedCategory}
        />
      )}
    </>
  );
};

export default NewCategory;
