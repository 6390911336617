/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getPosts($page: Page, $filter: ContentsFilter, $sort: ContentsSort) {
    posts(page: $page, filter: $filter, sort: $sort) {
      data {
        sendAt
        sentAt
        url
        videoStatus
        account {
          name
          provider
        }
        content {
          id
          sendMobileReminder
          category {
            id
            name
            colorIndex
          }
          variations {
            id
          }
        }
        variation {
          id
          displayText
          text
          timesSent
          images {
            id
          }
          videos {
            id
          }
          shortLinks {
            totalClicks
          }
        }
      }
      totalFilteredPosts
      totalPosts
    }
    company {
      timeZone
    }
  }
`;
