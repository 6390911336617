/* @flow */
import React, { useState } from "react";
import Button from "buttons/Button";
import Confirmation from "layout/modals/Confirmation";

import { Caption } from "typography";
import { goBack, gotoPath } from "util";
import { useMutation } from "@apollo/react-hooks";
import DELETE_CONTENT from "mutations/deleteContent";

import styles from "./index.css";

type Props = {
  contentId: string,
  disabled: boolean,
  loading: boolean
};

const DeleteButton = ({ disabled, contentId, ...props }: Props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteContent, deleteResult] = useMutation(DELETE_CONTENT, {
    variables: { id: contentId }
  });

  const loading = props.loading || deleteResult.loading;

  const toggleConfirmation = () => {
    setShowConfirmation(!showConfirmation);
  };

  const handleDelete = async () => {
    await deleteContent();

    if (document.referrer.match(`/content-performance/${contentId}`)) {
      gotoPath("/contents");
    } else {
      goBack();
    }
  };

  return (
    <>
      <Button
        type="secondary-negative"
        disabled={disabled}
        loading={loading}
        onClick={disabled ? () => {} : toggleConfirmation}
      >
        Delete
      </Button>

      {showConfirmation ? (
        <Confirmation
          title="Are you sure you want to delete this post?"
          confirmLabel="Yes, Delete"
          onConfirm={handleDelete}
          onCancel={toggleConfirmation}
        >
          <Caption helpText className={styles.subtitle}>
            You won't be able to undo this action
          </Caption>
        </Confirmation>
      ) : null}
    </>
  );
};

export default DeleteButton;
