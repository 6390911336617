/* @flow */
import React from "react";
import classNames from "classnames";
import { tooltipper } from "behavioral";
import { STEP_COPY } from "../constants.js";
import styles from "./index.css";

import type { OnboardingStep } from "../constants.js";

type Props = {
  isCompleted: boolean,
  isCurrent: boolean,
  step: OnboardingStep
};

const StepBubble = ({ isCompleted, isCurrent }: Props) => (
  <div
    className={classNames(styles.root, {
      [styles.isCurrent]: !!isCurrent,
      [styles.isCompleted]: !!isCompleted
    })}
  />
);

const Tooltip = ({ step }: Props) =>
  step !== "importContent" && !!step ? <div>{STEP_COPY[step]}</div> : null;

export default tooltipper(Tooltip)(StepBubble);
