// @flow
import React from "react";
import { colorFromIndex } from "colors";

import { CenterAlignedOnboardingToolTip } from "components/common/OnboardingTooltip";

import useCategorySelect from "./use";
import type { Props } from "./types.js";
import { Wrapper, Circle, Items, Item } from "./styles";

import NewCategory from "./NewCategory";

const TOOLTIP_BODY =
  "Your schedule is category-based. Select the category this post belongs in.";

function colorWithRandom(idx) {
  if (!idx) {
    return null;
  }
  return idx == -1 ? "var(--inky400)" : colorFromIndex(idx);
}

const CategorySelect = (props: Props) => {
  const {
    value,
    clientErrors,
    serverErrors,
    showOnboardingTooltip,
    onChange,
    onNextOnboardingStep
  } = props;
  const { loading, categories, onAddedCategory } = useCategorySelect(props);

  const hasError = (clientErrors || []).concat(serverErrors || []).length > 0;

  return (
    <Wrapper>
      {showOnboardingTooltip && (
        <CenterAlignedOnboardingToolTip tooltipBody={TOOLTIP_BODY} />
      )}

      {loading ? (
        <p>Loading</p>
      ) : !categories || categories.length === 0 ? (
        <p>There are no categories.</p>
      ) : (
        <Items>
          {categories.map(category => (
            <Item
              key={category.id}
              onClick={() => {
                onChange(category);
                onNextOnboardingStep();
              }}
              htmlFor={`categoryId-` + category.id}
              className={value && value.id === category.id ? "active" : ""}
            >
              <div>
                <Circle color={colorWithRandom(category.colorIndex)} />
                <span>{category.name}</span>
              </div>
              <input
                type="radio"
                name="category"
                value={category.id}
                id={`categoryId-` + category.id}
                checked={value && value.id === category.id}
                onChange={() => {}}
              />
            </Item>
          ))}
        </Items>
      )}

      <NewCategory onAddedCategory={onAddedCategory} />

      {hasError && <span>Category is required</span>}
    </Wrapper>
  );
};

export default CategorySelect;
