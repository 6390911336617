/* @flow */
import React from "react";
import { withRouter } from "react-router-dom";
import qs from "qs";
import ReactTooltip from "react-tooltip";
import type { Location } from "react-router-dom";

import NavTabs, { Tab } from "layout/NavTabs";
import TextBubble from "icons/TextBubble";

import styles from "./index.css";

type Props = {
  location: Location,
  className?: string,
  pendingCount: number
};

// NOTE - Default isActive fails when a search is present so need to hand implement
const isContentActive = (_match, { pathname }) =>
  !/contents\/(pending|rejected)/.test(pathname);
const isPendingActive = (_match, { pathname }) =>
  /contents\/pending/.test(pathname);
const isRejectedActive = (_match, { pathname }) =>
  /contents\/rejected/.test(pathname);

export const Nav = (props: Props) => {
  const {
    className,
    pendingCount,
    location: { search }
  } = props;

  const params = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  if (params.page) {
    delete params.page;
  }

  if (params.filter) {
    delete params.filter.status;
  }

  const queryString = qs.stringify(params, {
    addQueryPrefix: true
  });

  return (
    <>
      <NavTabs className={className}>
        <Tab to={`/contents${queryString}`} isActive={isContentActive}>
          Content
        </Tab>
        <Tab to={`/contents/pending${queryString}`} isActive={isPendingActive}>
          <span
            data-for="contents-tab-tooltip"
            data-html
            data-tip="
              <p style='font-weight: bold; margin: 0 0 7.5px;'>
                Don’t forget to approve your content!</p>
              <p style='margin: 0;'>Content that has been pending for
                more than 30 days will be moved into ‘Rejected Content’.</p>"
          >
            Pending Content
            {pendingCount > 0 && (
              <TextBubble type="count" className={styles.pendingCount}>
                {pendingCount}
              </TextBubble>
            )}
          </span>
        </Tab>
        <Tab
          to={`/contents/rejected${queryString}`}
          isActive={isRejectedActive}
        >
          <span
            data-for="contents-tab-tooltip"
            data-html
            data-tip="
              <p style='font-weight: bold; margin: 0 0 7.5px;'>
                Don’t forget to approve your content!</p>
              <p style='margin: 0;'>Content that has been sitting in Rejected Content
                for more than 30 days will be <b>permanently deleted</b>!</p>"
          >
            Rejected Content
          </span>
        </Tab>
      </NavTabs>

      <ReactTooltip
        effect="solid"
        place="top"
        type="info"
        className={styles.tooltip}
        backgroundColor="#000"
        id="contents-tab-tooltip"
      />
    </>
  );
};

export default withRouter(Nav);
