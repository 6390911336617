/* @flow */
import { type Reducer } from "redux";
import accounts from "./accounts";
import flash from "./flash";
import onboarding from "./onboarding";
import platforms from "./platforms";
import users from "./users";
/* $FlowFixMe */
import layout from "./layout";

import type { Action } from "types";
import type { AppState } from "./root.flow";
export type { AppState };

const reducers: Reducer<AppState, Action> = (
  state: AppState = {},
  action: Action
) => {
  return {
    accounts: accounts(state.accounts, action),
    flash: flash(state.flash, action),
    onboarding: onboarding(state.onboarding, action),
    platforms: platforms(state.platforms, action),
    users: users(state.users, action),
    layout: layout(state.layout, action)
  };
};

export default reducers;
