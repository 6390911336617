import React, { type Node } from "react";

import RadioButton from "forms/RadioButton2";
import Label from "typography/Label";
import Caption from "typography/Caption";

import styles from "./index.css";

type Props = {
  caption: Node,
  label: string,
  selectService: (service: string) => void,
  selectedService: string,
  service: string
};

const Service = ({
  caption,
  label,
  selectService,
  selectedService,
  service
}: Props) => {
  return (
    <div className={styles.service}>
      <RadioButton
        className={styles.radioButton}
        onClick={selectService}
        selected={selectedService === service}
        value={service}
      />
      <div>
        <Label
          onClick={_evt => {
            selectService(service);
          }}
        >
          {label}
        </Label>
        <Caption className={styles.caption}>{caption}</Caption>
      </div>
    </div>
  );
};

export default Service;
