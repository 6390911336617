/* @flow */
import * as React from "react";
import useScrollToLastChild from "./useScrollToLastChild";
import styles from "./index.css";

type Props = {
  children: React.Node,
  disableAutoScroll?: boolean
};

const AutoScrollContainer = ({
  children: childrenWithNulls,
  disableAutoScroll = false
}: Props) => {
  const { containerRef, lastChildRef } = useScrollToLastChild(
    childrenWithNulls,
    disableAutoScroll
  );
  const children = React.Children.toArray(childrenWithNulls).filter(
    child => child
  );
  const childCount = children.length;

  return (
    <div className={styles.root} ref={containerRef}>
      {children.map((child, i) =>
        React.cloneElement(child, {
          ref: i === childCount - 1 ? lastChildRef : null
        })
      )}
    </div>
  );
};

export default AutoScrollContainer;
