/* @flow */
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import { createFlash } from "actions/flash";
import DASHBOARD_ACCOUNTS from "queries/dashboardAccounts";
import type { dashboardAccount_dashboardAccount } from "../../../../graphql/types/dashboardAccount";

import type { IProps } from "./types.js";

const useAccounts = (props: IProps) => {
  const { filters } = props;

  const [lastFilters, setLastFilters] = useState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<[dashboardAccount_dashboardAccount] | null>(
    null
  );

  const apolloClient = useApolloClient();

  useEffect(() => {
    const handleData = async () => {
      try {
        setLoading(true);

        const res = await apolloClient.query({
          query: DASHBOARD_ACCOUNTS,
          variables: {
            startDate: filters.startDate,
            endDate: filters.endDate
          }
        });

        setData(get(res, "data.dashboardAccount", null));
      } catch (err) {
        setData(null);
        props.dispatch(
          createFlash("alert", "Could not load data for Account Breakdown.")
        );
      } finally {
        setLoading(false);
      }
    };

    if (
      (filters && filters.startDate !== lastFilters?.startDate) ||
      (filters && filters.endDate !== lastFilters?.endDate)
    ) {
      setLastFilters(filters);
      handleData();
    }
  }, [apolloClient, setData, filters, lastFilters, props]);

  return { loading, data };
};

export default useAccounts;
