/* @flow */
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import { createFlash } from "actions/flash";
import DASHBOARD_CATEGORIES from "queries/dashboardCategories";

import type { IProps } from "./types.js";

const useCategories = (props: IProps) => {
  const { filters, selectedAccount } = props;

  const [lastFilters, setLastFilters] = useState();
  const [lastAccountId, setLastAccountId] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const apolloClient = useApolloClient();

  useEffect(() => {
    const handleData = async () => {
      try {
        setLoading(true);
        const accountId = selectedAccount ? parseInt(selectedAccount.id) : null;

        const res = await apolloClient.query({
          query: DASHBOARD_CATEGORIES,
          variables: {
            startDate: filters.startDate,
            endDate: filters.endDate,
            accountId
          },
          fetchPolicy: "network-only"
        });

        setData(get(res, "data.dashboardCategories", null));
      } catch (err) {
        setData(null);
        props.dispatch(
          createFlash("alert", "Could not load data for Category Breakdown.")
        );
      } finally {
        setLoading(false);
      }
    };

    if (
      (filters && filters.startDate !== lastFilters?.startDate) ||
      (filters && filters.endDate !== lastFilters?.endDate) ||
      selectedAccount?.id !== lastAccountId
    ) {
      setLastFilters(filters);
      setLastAccountId(get(selectedAccount, "id", undefined));
      handleData();
    }
  }, [
    apolloClient,
    setData,
    filters,
    lastFilters,
    selectedAccount,
    props,
    lastAccountId
  ]);

  return { loading, data };
};

export default useCategories;
