/* @flow */
import React from "react";
import classNames from "classnames";
import styles from "./index.css";

import facebookLogo from "../../images/facebook-inverted.svg";
import linkedinLogo from "../../images/linkedin-inverted.svg";
import xLogo from "../../images/x-logo.png";
import instagramLogo from "../../images/instagram-inverted.svg";
import pinterestLogo from "../../images/pinterest-logo.svg";
import tiktokLogo from "../../images/tiktok-logo.svg";
import googleMyBusinessLogo from "../../images/google-my-business.svg";
import threadsLogo from "../../images/threads.png";

type Props = {
  value: string,
  className?: string,
  tiny?: boolean
};

type PlatformAttributes = {
  imageUrl?: string,
  platformStyles?: string
};

const SocialMediaIcon = ({ value, className = "", tiny = false }: Props) => {
  const { imageUrl, platformStyles } = getPlatformAttributes(value);
  if (!imageUrl && !platformStyles) {
    return null;
  }

  const classes = classNames(platformStyles, {
    [className]: !!className,
    [styles.tiny]: tiny
  });

  return <img className={classes} src={imageUrl} alt={value} />;
};

function getPlatformAttributes(value: ?string): PlatformAttributes {
  switch (value) {
    case "facebook":
      return { imageUrl: facebookLogo, platformStyles: styles.facebookIcon };
    case "twitter":
      return { imageUrl: xLogo, platformStyles: styles.xIcon };
    //return { imageUrl: twitterLogo, platformStyles: styles.twitterIcon };
    case "linkedin":
      return { imageUrl: linkedinLogo, platformStyles: styles.linkedinIcon };
    case "instagram":
      return { imageUrl: instagramLogo, platformStyles: styles.instagramIcon };
    case "pinterest":
      return { imageUrl: pinterestLogo, platformStyles: styles.pinterestIcon };
    case "tiktok":
      return { imageUrl: tiktokLogo, platformStyles: styles.tiktokIcon };
    case "google":
      return {
        imageUrl: googleMyBusinessLogo,
        platformStyles: styles.googleIcon
      };
    case "threads":
      return { imageUrl: threadsLogo, platformStyles: styles.threadsIcon };
    default:
      return {};
  }
}

export default SocialMediaIcon;
