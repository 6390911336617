/* @flow */
import { useState } from "react";

const useBreakdowns = () => {
  const [currentTab, setCurrentTab] = useState("categories");

  const showCategories = () => setCurrentTab("categories");
  const showAccounts = () => setCurrentTab("accounts");

  return { currentTab, showCategories, showAccounts };
};

export default useBreakdowns;
