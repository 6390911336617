// @flow
import React, { Component } from "react";
import { get, without } from "lodash";
import pluralize from "pluralize";
import ReactS3Uploader from "react-s3-uploader";
import { mostStrictImageLimit } from "lib/images/validateImage";

import { IMAGE_VALIDATIONS } from "constants";
import validateImage from "lib/images/validateImage";

import type { Platform, Provider } from "graphql-types/loadComposer";

type PreprocessTask = { file: File, next: (file: File) => void };

type Props = {
  inputId: string,
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  existingImageCount: number,
  existingVideoCount: number,
  sendMobileReminder: boolean,
  onStart: (file: File) => void,
  onProgress: (progress: ?number, status: string, file: File) => void,
  onUploadError: (message: string, file: File) => void,
  onValidationError: (errors: string[], file: File) => void,
  onFinish: (url: string, file: File) => void
};

type State = {
  preprocessBatch: PreprocessTask[]
};

export default class ImageUploader extends Component<Props, State> {
  state = { preprocessBatch: [] };

  //FIXME: I don't think this the correct type for `this.input`
  input: ?HTMLInputElement;
  fileInput: ?HTMLInputElement;

  getFileCountFromInput() {
    // I didn't name it myUploader. Maybe we should fork ReactS3Uploader LOL
    return get(this.input, "myUploader.fileElement.files.length");
  }

  handlePreprocessImage = (file: File, next: (file: File) => void) => {
    // Use functional form of setState to prevent batched setStates from stepping on eachother
    this.setState(
      prevState => ({
        preprocessBatch: prevState.preprocessBatch.concat({ file, next })
      }),
      () => {
        const { preprocessBatch } = this.state;
        const fileCount = this.getFileCountFromInput();
        const allTracked = preprocessBatch.length === fileCount;
        const currentIndex = preprocessBatch.findIndex(
          task => task.file === file
        );
        const isLast = currentIndex === fileCount - 1;

        this.props.onProgress(null, "preprocessing", file);

        if (allTracked && isLast) {
          this.runPreprocessBatch();
        }
      }
    );
  };

  runPreprocessBatch = () => {
    const {
      selectedPlatforms,
      selectedProviders,
      existingImageCount,
      existingVideoCount,
      sendMobileReminder,
      onStart,
      onValidationError
    } = this.props;

    const imageCountLimit = mostStrictImageLimit(
      selectedPlatforms,
      selectedProviders,
      sendMobileReminder
    );

    // Convert preprocess tasks into validation promises
    const validations = this.state.preprocessBatch.map(
      ({ file, next }) =>
        new Promise(resolve =>
          validateImage(
            file,
            IMAGE_VALIDATIONS,
            selectedPlatforms,
            selectedProviders,
            sendMobileReminder
          )
            .then(() => {
              resolve({ file, next, errors: [] });
            })
            .catch(({ errors }) => {
              resolve({ file, next, errors });
            })
        )
    );

    // Run all validations then seperate successes from failures
    Promise.all(validations).then(results => {
      const validImages = results.filter(image => !image.errors.length);
      const invalidImages = without(results, ...validImages);

      validImages.forEach((image, i) => {
        // Process successes and treat over limit as invalid
        if (
          i < imageCountLimit.count - existingImageCount ||
          (selectedProviders.length === 1 &&
            selectedProviders[0] === "INSTAGRAM_BUSINESS" &&
            i < existingImageCount - existingVideoCount - 1)
        ) {
          onStart(image.file);
          image.next(image.file);
        } else {
          const pluralizedImageCount = pluralize(
            "image",
            imageCountLimit.count,
            true
          );
          invalidImages.push({
            ...image,
            errors: [
              `${
                imageCountLimit.platformName
              } only allows ${pluralizedImageCount}.`
            ]
          });
        }
      });

      invalidImages.forEach(({ file, errors }) => {
        onValidationError(errors, file);
        if (this.fileInput) {
          this.fileInput.value = "";
        }
      });

      this.setState({ preprocessBatch: [] });
    });
  };

  handleFinishMedia = (opt: Object, file: File): void => {
    const strippedUrl = opt.signedUrl.replace(/\?.*$/, "");
    this.props.onFinish(strippedUrl, file);
    if (this.fileInput) {
      this.fileInput.value = "";
    }
  };

  render() {
    const {
      inputId,
      existingImageCount,
      existingVideoCount,
      sendMobileReminder,
      selectedPlatforms,
      selectedProviders,
      onProgress,
      onUploadError
    } = this.props;
    const imageCountLimit = mostStrictImageLimit(
      selectedPlatforms,
      selectedProviders,
      sendMobileReminder
    );
    const remainingLimit =
      selectedProviders.length === 1 &&
      selectedProviders[0] === "INSTAGRAM_BUSINESS"
        ? 10 - (existingImageCount + existingVideoCount)
        : imageCountLimit.count - existingImageCount;

    return (
      <ReactS3Uploader
        style={{ display: "none" }}
        id={inputId}
        signingUrl="/api/s3_signed_urls"
        signingUrlHeaders={{
          Accept: "application/vnd.meetedgar.v2+json",
          "Content-Type": "application/vnd.meetedgar.v2+json",
          "X-Authorization": `Bearer ${window.API_KEY}`
        }}
        uploadRequestHeaders={{}}
        accept="image/*"
        multiple={remainingLimit > 1}
        ref={input => {
          this.input = input;
        }}
        inputRef={i => {
          this.fileInput = i;
        }}
        preprocess={this.handlePreprocessImage}
        onProgress={onProgress}
        onError={onUploadError}
        onFinish={this.handleFinishMedia}
      />
    );
  }
}
