// @flow
import React, { type ChildrenArray, type Element } from "react";
import classNames from "classnames";
import { connect } from "react-redux";

import type { AppState } from "reducers/root";
import useContainer from "./use";
import styles from "./index.css";

import Col from "./Col";

export { Col };

type Props = {
  children?: ChildrenArray<Element<typeof Col>>,
  className?: string,
  navbarCollapsed: boolean
};

const Container = ({ children, className, navbarCollapsed }: Props) => {
  const { useNewUI } = useContainer();

  return (
    <div
      className={classNames("", {
        [styles.newRootUIWrapper]: useNewUI,
        [styles.newRootUICollapsed]: useNewUI && navbarCollapsed
      })}
    >
      <div className={classNames(styles.rootUI, {})}>
        <div
          className={classNames(styles.root, {
            [className || ""]: className
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  navbarCollapsed: state.layout?.data.navbarCollapsed
});

export default connect(mapStateToProps)(Container);
