// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";
import styles from "./index.css";
import SVGExclamation from "./exclamation.svg";
type Props = {
  className: any
};

export const TooltipBody = ({  }: Props) => {
  return (
    <p className={styles.previewButtonTooltip}>
      1 credit = 1 question <br />
      Each time you generate a suggestion, 1 credit will be consumed
    </p>
  );
};

export const CreditRemainingTooltip = ({  }: Props) => {
  return <img className={styles.root} src={SVGExclamation} alt="plus icon" />;
};

export default tooltipper(TooltipBody, {
  targetClassName: styles.postPreviewButtonTooltipTether
})(CreditRemainingTooltip);
