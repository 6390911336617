/* @flow */
import { useState, useEffect } from "react";

import type { Props } from "./types.js";

const useExpiredWarning = (props: Props) => {
  const hidePendingFromLocal = localStorage.getItem("hidePending");
  const hideRejectedFromLocal = localStorage.getItem("hideRejected");

  const [status, setStatus] = useState<"pending" | "rejected" | null>(null);
  const [hidePending, setHidePending] = useState(
    hidePendingFromLocal && hidePendingFromLocal === "true"
  );
  const [hideRejected, setHideRejected] = useState(
    hideRejectedFromLocal && hideRejectedFromLocal === "true"
  );

  useEffect(() => {
    if (props.location.pathname.includes("pending")) {
      setStatus("pending");
    } else if (props.location.pathname.includes("rejected")) {
      setStatus("rejected");
    } else {
      setStatus(null);
    }
  }, [props.location]);

  const handleHide = (status: "pending" | "rejected") => {
    if (status === "pending") {
      setHidePending(true);
      localStorage.setItem("hidePending", "true");
    } else {
      setHideRejected(true);
      localStorage.setItem("hideRejected", "true");
    }
  };

  return {
    status,
    hidePending,
    hideRejected,
    handleHide
  };
};

export default useExpiredWarning;
