/* @flow */
import * as React from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  className?: string,
  hasClearBackground?: boolean,
  children: React.Node
};

const AttachmentContainer = ({
  className = "",
  hasClearBackground = false,
  children
}: Props) => (
  <div
    className={classNames(styles.root, {
      [styles.hasClearBackground]: hasClearBackground,
      [className]: !!className
    })}
  >
    {children}
  </div>
);

export default AttachmentContainer;
