/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;

  & + & {
    margin-top: 10px;
  }

  div.message {
    background-color: #f0f0f0;
    border-radius: 20px;
    padding: 15px;
    max-width: 80%;

    a {
      color: #333;
    }

    .warning {
      font-style: italic;
      opacity: 0.45;
    }
  }

  img.attachment {
    border-radius: 20px;
    max-width: 50%;
  }

  &.owner {
    justify-content: flex-end;
    text-align: right;

    div.message {
      background-color: #4d948f;
      color: #fff;

      a {
        color: #fff;
        display: flex;

        img {
          height: 16px;
          margin: 2px 5px 0 0;
          width: 16px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    div.message {
      max-width: 60%;
    }
  }
`;

export const Attachments = styled.div``;
