/* @flow */
import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./index.css";

type Props = {
  activeImage: number,
  imageCount: number
};

type MotionType = "moving" | "steady" | "initializing";

type State = {
  motion: MotionType
};

class ImageXofYIndicator extends Component<Props, State> {
  state: State = { motion: "initializing" };
  FADE_OUT_TIME = 1750;

  componentWillReceiveProps({ activeImage }: Props) {
    // Yeah, this will need to change when we upgrade to React 16. :/
    if (this.state.motion !== "initializing") {
      if (activeImage !== this.props.activeImage) {
        this.setState({
          motion: "moving"
        });
      } else {
        this.setState({
          motion: "steady"
        });
      }
    }
  }

  handleDoneFading = () => {
    this.setState({
      motion: "steady"
    });
  };

  render() {
    const { activeImage, imageCount } = this.props;
    const { motion } = this.state;

    return (
      <CSSTransition
        appear={true}
        classNames={{
          appearActive: styles.appearActive,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone
        }}
        in={motion !== "steady"}
        timeout={this.FADE_OUT_TIME}
        onEntered={this.handleDoneFading}
      >
        <div className={styles.root}>
          {activeImage + 1}/{imageCount}
        </div>
      </CSSTransition>
    );
  }
}

export default ImageXofYIndicator;
