/* @flow */
import React, { useState } from "react";
import Image from "./Image";
import Video from "./Video";

import type { VideoData } from "./Video";
import type { ImageData } from "./Image";

type Props = {
  contentId?: string,
  images?: ImageData[],
  videos?: VideoData[],
  video?: ?VideoData,
  queueable?: boolean,
  displayTotalCount?: boolean,
  size?: number
};

const Thumbnail = ({
  contentId,
  images,
  videos,
  video,
  queueable,
  displayTotalCount,
  size = 80
}: Props) => {
  const [modalContentId, setModalContentId] = useState(null);
  const showModal = modalContentId === contentId;

  if (video && (!images || images.length === 0)) {
    return (
      <Video
        value={video}
        videos={videos}
        imagesLength={images && images?.length ? images.length : 0}
        showModal={showModal}
        onClickShowModal={() => setModalContentId(contentId)}
        onClickHideModal={() => setModalContentId(null)}
        size={size}
      />
    );
  }

  if (images && images.length) {
    return (
      <Image
        images={images}
        videosLength={videos && videos.length ? videos.length : 0}
        queueable={queueable}
        showModal={showModal}
        onClickShowModal={() => setModalContentId(contentId)}
        onClickHideModal={() => setModalContentId(null)}
        displayTotalCount={displayTotalCount}
        size={size}
      />
    );
  }

  return null;
};

export default Thumbnail;
