/* @flow */
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import GET_ACCOUNTS from "queries/getAccounts";

import type { Props } from "./types.js";

const useShopifyFormAccounts = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoading(true);

      try {
        const res = await apolloClient.query({
          query: GET_ACCOUNTS,
          variables: { onlyPostable: true, withInboxSupport: false }
        });
        setAccounts(get(res, "data.accounts", []));
        props.setAccounts(get(res, "data.accounts", []));
      } catch (err) {
        setAccounts([]);
        if (props.setAccounts) props.setAccounts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [apolloClient, props]);

  return { loading, accounts };
};

export default useShopifyFormAccounts;
