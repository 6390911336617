/* @flow */
import React from "react";
import classNames from "classnames";
import styles from "./index.css";

import type { EndorsementData } from "../Endorsement";

type Props = {
  activeIndex: number,
  endorsements: EndorsementData[],
  onClick: (index: number) => void
};

export const Pager = (props: Props) => {
  const { endorsements, activeIndex, onClick } = props;
  return (
    <div className={styles.root}>
      {endorsements.map((endorsement, index) => {
        const active = index === activeIndex;
        return (
          <span
            key={`pager-${index}`}
            className={classNames(styles.dot, { [styles.active]: active })}
            onClick={() => onClick(index)}
          >
            &bull;
          </span>
        );
      })}
    </div>
  );
};

export default Pager;
