/* @flow */
import React, { useState } from "react";
import Card from "layout/Card";
import Confirmation from "layout/modals/Confirmation";
import RssFeedForm from "../RssFeedForm";
import Table from "./Table";
import NoFeeds from "./NoFeeds";
import { useQuery, useMutation } from "@apollo/react-hooks";
import GET_ACCOUNTS from "queries/getAccounts";
import { GET_CATEGORIES } from "queries/category";
import GET_RSS_FEEDS from "queries/getRssFeeds";
import DELETE_RSS_FEED from "mutations/deleteRssFeed";
import UPDATE_RSS_FEED from "mutations/updateRssFeed";
import IMPORT_RSS_FEED_NOW from "mutations/importRssFeedNow";

export const Manage = () => {
  // grab the accounts and categories objects; we pass these into the RssFeedForm component
  const {
    //loading: accountsLoading,
    //error: accountsLoadingError,
    data: accountsData
  } = useQuery(GET_ACCOUNTS);

  const {
    //loading: categoriesLoading,
    //error: categoriesLoadingError,
    data: categoriesData
  } = useQuery(GET_CATEGORIES);

  const {
    //loading: rssFeedsLoading,
    //error: rssFeedsLoadingError,
    data: rssFeedsData
  } = useQuery(GET_RSS_FEEDS);

  const [deleteRssFeed] = useMutation(DELETE_RSS_FEED, {
    refetchQueries: [
      {
        query: GET_RSS_FEEDS
      }
    ]
  });

  const [updateRssFeed] = useMutation(UPDATE_RSS_FEED, {
    onCompleted: () => {
      setShowRssFeedEditForm(false);
    },
    refetchQueries: [
      {
        query: GET_RSS_FEEDS
      }
    ]
  });

  const [importRssFeedNow] = useMutation(IMPORT_RSS_FEED_NOW, {});

  const handleDeleteRssFeed = (rssFeedId: string) => {
    deleteRssFeed({
      variables: { id: rssFeedId }
    });
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [handleOnConfirm, setHandleOnConfirm] = useState();
  const [showRssFeedEditForm, setShowRssFeedEditForm] = useState(false);
  const [currentRssFeed, setCurrentRssFeed] = useState(null);

  const onClickEditFeed = (id: string) => {
    // pull out the correct rss feed from the array of feeds that was passed in
    const rssFeedTarget = rssFeedsData.rssFeeds.find(r => {
      return r.id === id;
    });
    setCurrentRssFeed(rssFeedTarget);
    setShowRssFeedEditForm(true);
  };

  const handleOnClickDeleteFeedFromEdit = (id: string) => {
    handleDeleteRssFeed(id);
    setShowRssFeedEditForm(false);
  };

  const handleOnClickDeleteFeed = (id: string) => {
    setShowConfirmationModal(true);
    setHandleOnConfirm(() => handleDeleteRssFeed.bind(null, id));
  };

  const handleOnClickHide = () => {
    setShowRssFeedEditForm(false);
  };

  const handleOnClickImportFeed = rssFeed => {
    updateRssFeed({
      variables: { input: rssFeed }
    });
  };

  const handleOnRowClickImportFeed = (id: string) => {
    const targ = rssFeedsData.rssFeeds.find(a => {
      return a.id === id;
    });
    importRssFeedNow({
      variables: { rssFeedId: parseInt(targ.id) }
    });
  };

  return (
    <Card>
      {showConfirmationModal && (
        <Confirmation
          type="error"
          confirmLabel="Yes, delete"
          confirmType="primary-negative"
          onConfirm={() => {
            if (handleOnConfirm) {
              handleOnConfirm();
            }
            setShowConfirmationModal(false);
          }}
          cancelLabel="Cancel"
          onCancel={() => setShowConfirmationModal(false)}
        />
      )}

      {showRssFeedEditForm && (
        <RssFeedForm
          rssFeed={currentRssFeed}
          categories={categoriesData.categories}
          accounts={accountsData.accounts}
          onCancel={handleOnClickHide}
          onSubmit={handleOnClickImportFeed}
          onDelete={handleOnClickDeleteFeedFromEdit}
          /*
          loading={createRssFeedLoading || createRssFeedData?.rssFeed?.id}
          error={createRssFeedError}
          configConnectionCopy={connection.configConnectionCopy}
          vimeoChannelTypeSelection={vimeoChannelTypeSelection}
          */
        />
      )}

      {rssFeedsData?.rssFeeds && rssFeedsData.rssFeeds.length > 0 ? (
        <Table
          rssFeeds={rssFeedsData.rssFeeds}
          onClickEditFeed={onClickEditFeed}
          onClickImportFeed={handleOnRowClickImportFeed}
          onClickDeleteFeed={handleOnClickDeleteFeed}
        />
      ) : (
        <NoFeeds />
      )}
    </Card>
  );
};

export default Manage;
