/* @flow */
import React from "react";

import { featureFlag } from "util";

import type { Props } from "./types.js";
import { Wrapper, Button } from "./styles";

const SidebarButtons = (props: Props) => {
  const { contentType, handleMessages, handleComments } = props;

  return (
    <Wrapper>
      {featureFlag("direct_messages") && (
        <Button
          onClick={handleMessages}
          className={contentType === "messages" ? "active" : ""}
        >
          Direct Messages
        </Button>
      )}

      <Button
        onClick={featureFlag("direct_messages") ? handleComments : () => {}}
        className={
          !featureFlag("direct_messages")
            ? " only-comments"
            : contentType === "comments"
            ? "active"
            : ""
        }
        fullWidth
      >
        Comments
      </Button>
    </Wrapper>
  );
};

export default SidebarButtons;
