/* @flow */
import React from "react";
import { maxBy, minBy, uniqBy } from "lodash";
import SplitTestLabel from "./SplitTestLabel";
import Icon from "icons/Icon";
import H3 from "components/common/typography/H3";
import Caption from "components/common/typography/Caption";
import styles from "./index.css";

import type { getContentPerformance_content_variations_shortLinks as ShortLinkData } from "graphql-types/getContentPerformance";

type Props = {
  shortLinks: (ShortLinkData & { label: string })[]
};

export const ShortLinksTable = ({ shortLinks }: Props) => {
  const totalVariations = uniqBy(shortLinks, "variation.id").length;
  const hasNotBeenSent = shortLinks.some(shortLink => !shortLink.timesSent);
  const displaySplitTest = totalVariations > 1 && !hasNotBeenSent;

  let mostAverageClicks, leastAverageClicks;

  if (displaySplitTest) {
    mostAverageClicks = maxBy(shortLinks, "averageClicksPerTimesSent")
      .averageClicksPerTimesSent;

    leastAverageClicks = minBy(shortLinks, "averageClicksPerTimesSent")
      .averageClicksPerTimesSent;
  }

  return (
    <div className={styles.root}>
      <H3>Content performance</H3>

      {!displaySplitTest && (
        <Caption>
          <Icon type="clock-o" className={styles.clockIcon} />
          No split test results, check back when{" "}
          {hasNotBeenSent
            ? "all variations have been sent"
            : "more than one variation has been sent"}
          .
        </Caption>
      )}

      <table className={styles.table}>
        <tbody>
          {shortLinks.map(
            (
              {
                url,
                destination,
                totalClicks,
                label,
                timesSent,
                averageClicksPerTimesSent
              },
              i
            ) => (
              <tr className={styles.row} key={i}>
                <td className={styles.tableData}>
                  <div className={styles.value}>
                    {displaySplitTest &&
                      mostAverageClicks !== leastAverageClicks && (
                        <SplitTestLabel
                          averageClicksPerTimesSent={averageClicksPerTimesSent}
                          mostAverageClicks={mostAverageClicks}
                          leastAverageClicks={leastAverageClicks}
                        />
                      )}
                  </div>
                  <div className={styles.caption}>
                    {label}
                    <span className={styles.link}>{url}</span> (
                    <a href={destination} target="_blank">
                      {destination}
                    </a>
                    )
                  </div>
                </td>
                <td>
                  <div className={styles.value}>{timesSent}</div>
                  <div className={styles.caption}>Number of times sent</div>
                </td>
                <td>
                  <div className={styles.value}>{totalClicks}</div>
                  <div className={styles.caption}>Total all time clicks</div>
                </td>
                <td>
                  <div className={styles.value}>
                    {averageClicksPerTimesSent}
                  </div>
                  <div className={styles.caption}>
                    Avg clicks per times sent
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShortLinksTable;
