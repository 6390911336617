/* @flow */
import React from "react";
import leftChevron from "../../../images/left-chevron.png";
import rightChevron from "../../../images/right-chevron.png";

import styles from "./index.css";

type Props = {
  imageCount: number,
  activeImage: number,
  onClickPrevious: () => void,
  onClickNext: () => void
};

const Scroller = ({
  imageCount,
  activeImage,
  onClickPrevious,
  onClickNext
}: Props) => (
  <div className={styles.root}>
    {activeImage !== 0 && (
      <img
        src={leftChevron}
        className={styles.leftChevron}
        onClick={onClickPrevious}
      />
    )}
    {activeImage !== imageCount - 1 && (
      <img
        src={rightChevron}
        className={styles.rightChevron}
        onClick={onClickNext}
      />
    )}
  </div>
);

export default Scroller;
