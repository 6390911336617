// @flow
import React, { Component, type Node } from "react";
import classNames from "classnames";
import { Label } from "typography";
import { Link } from "links";
import { Icon } from "icons";
import { Confirmation } from "layout/modals";
import CharacterCountNotice from "components/common/CharacterCountNotice";
import Warnings from "./Warnings";
import PostPreviewButton from "../PostPreviewButton";
import styles from "./index.css";

type RemainingCharacterCounts = { [key: string]: number };
type Props = {
  children: Node,
  htmlFor: string,
  hasRequiredFieldError: boolean,
  warnings: string[],
  hasErrors: boolean,
  deleteVisible: boolean,
  focused: boolean,
  hovered: boolean,
  fbTextVisible: boolean,
  remainingCharacterCounts: RemainingCharacterCounts,
  twitterPosted: boolean,
  previewButtonVisible: boolean,
  onDelete: () => void,
  handlePreviewButtonClick: () => void
};

type State = { deleteConfirmationOpen: boolean };

export class Title extends Component<Props, State> {
  state = { deleteConfirmationOpen: false };

  handleClickDelete = (evt: Event) => {
    // Prevent click from re-focusing this variation
    evt.stopPropagation();
    this.setState({ deleteConfirmationOpen: true });
  };

  handleConfirmDelete = (evt: Event) => {
    // Prevent click from re-focusing this variation
    evt.stopPropagation();
    this.setState({ deleteConfirmationOpen: false }, () => {
      this.props.onDelete();
    });
  };

  handleCancelDelete = (evt: Event) => {
    // Prevent click from re-focusing this variation
    evt.stopPropagation();
    this.setState({ deleteConfirmationOpen: false });
  };

  renderDeleteConfirmation = () => (
    <Confirmation
      type="warning"
      title="Are you sure you want to delete this variation?"
      subTitle="You won't be able to undo this action."
      confirmLabel="Yes, Delete"
      onConfirm={this.handleConfirmDelete}
      onCancel={this.handleCancelDelete}
    />
  );

  render() {
    const {
      // children,
      htmlFor,
      hasRequiredFieldError,
      hasErrors,
      warnings,
      deleteVisible,
      focused,
      hovered,
      fbTextVisible,
      remainingCharacterCounts,
      twitterPosted,
      handlePreviewButtonClick
    } = this.props;

    const rootClasses = classNames(styles.root, {
      [styles.blurred]: !focused,
      [styles.hovered]: hovered
    });

    return (
      <div className={rootClasses}>
        <Label className={styles.label} htmlFor={htmlFor}>
          {/* {children} */}
          {hasErrors && (
            <Icon className={styles.icon} type="ban" color="danger" />
          )}
          {!!warnings.length && (
            <Warnings className={styles.icon} value={warnings} />
          )}
          {hasRequiredFieldError && (
            <span className={styles.required}> (required field)</span>
          )}
          {twitterPosted && (
            <span className={styles.twitterPostedNotice}>
              expired for Twitter
            </span>
          )}
        </Label>
        {focused && !fbTextVisible && (
          <div className={styles.remainingCharacterCountsContainer}>
            <CharacterCountNotice value={remainingCharacterCounts} />
          </div>
        )}
        {deleteVisible && (
          <Link
            small
            className={styles.deleteLink}
            onClick={this.handleClickDelete}
          >
            Delete
          </Link>
        )}

        <PostPreviewButton
          className={styles.previewButton}
          handlePreviewButtonClick={handlePreviewButtonClick}
        />

        {this.state.deleteConfirmationOpen && this.renderDeleteConfirmation()}
      </div>
    );
  }
}

export default Title;
