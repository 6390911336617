/* @flow */
import React from "react";
import Button from "buttons/Button";
import styles from "./index.css";

import type { CompanyStatus } from "types";

export const AddContentButton = () => (
  <Button url="/composer" type="primary">
    Add new content
  </Button>
);

const UtilityNav = ({ companyStatus }: { companyStatus: CompanyStatus }) => (
  <div className={styles.root}>
    {(companyStatus.hasAccess && !window.location.href.includes('payment=true')) && <AddContentButton />}
  </div>
);

export default UtilityNav;
