/* @flow */
import { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import CREATE_FACEBOOK_MESSAGE from "mutations/createFacebookMessage";
import CREATE_INSTAGRAM_MESSAGE from "mutations/createInstagramMessage";

import type { Props } from "./types.js";

const useThreadFooter = (props: Props, inputRef: any) => {
  const { selectedAccount, selectedThread } = props;
  const apolloClient = useApolloClient();

  const [newReply, setNewReply] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleChange = (e: any) => {
    setError("");
    setNewReply(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key == "Enter" || e.keyCode == 13) {
      createReply();
    }
  };

  const createReply = async () => {
    if (loading) return false;
    setLoading(true);

    const isInstagram = selectedAccount.platform === "INSTAGRAM";

    try {
      const accountUid = selectedAccount.uid;

      const params = {
        accountId: parseInt(selectedAccount.id),
        recipientId:
          accountUid !== selectedThread.sender.id
            ? selectedThread.sender.id
            : selectedThread.receiver.id,
        message: newReply
      };

      const res: any = await apolloClient.mutate({
        mutation: isInstagram
          ? CREATE_INSTAGRAM_MESSAGE
          : CREATE_FACEBOOK_MESSAGE,
        variables: params
      });

      const createdMessage = isInstagram
        ? res.data.instagramSendMessage
        : res.data.facebookSendMessage;

      if (createdMessage.messageId) {
        props.afterReplyCreate(createdMessage.messageId, newReply);
        setNewReply("");
        setTimeout(() => {
          inputRef.current ? inputRef.current.focus() : null;
        }, 1000);
      } else if (createdMessage.error) {
        if (createdMessage.error.includes("2018278")) {
          setError(
            "You can only reply to messages outside Facebook " +
              "within 24 hours of receiving them."
          );
        } else {
          setError(createdMessage.error);
        }
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return {
    newReply,
    loading,
    handleChange,
    handleKeyPress,
    createReply,
    error
  };
};

export default useThreadFooter;
