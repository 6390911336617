/* @flow */
import React from "react";
import { SocialMediaIcon } from "icons";
import { TextInput } from "forms";
import { Caption } from "typography";
import AttachmentContainer from "../common/AttachmentContainer";
import CharacterCountNotice from "components/common/CharacterCountNotice";
import styles from "./index.css";

const TITLE_LIMIT = 100;
const TITLE_PLACEHOLDER = "Pin title";
const LINK_PLACEHOLDER = "Destination link";

type Props = {
  title: ?string,
  link: ?string,
  errors: { pinterestDestinationLink: string[] },
  onChangeLink: (value: string) => void,
  onChangeTitle: (value: string) => void
};

export const PinterestSettings = ({
  title,
  link,
  errors,
  onChangeLink,
  onChangeTitle
}: Props) => {
  const titleRemainingChars = TITLE_LIMIT - (title?.length ?? 0);
  const pinterestDestinationLinkErrors = errors?.pinterestDestinationLink;

  return (
    <AttachmentContainer className={styles.root}>
      <div className={styles.header}>
        <SocialMediaIcon value="pinterest" tiny />
        <span>Pinterest settings (optional)</span>
        <span className={styles.titleLength}>
          <CharacterCountNotice value={{ Pinterest: titleRemainingChars }} />
        </span>
      </div>
      <TextInput
        small
        multiline
        className={styles.input}
        hasError={false}
        value={title ?? ""}
        onChange={onChangeTitle}
        placeholder={TITLE_PLACEHOLDER}
      />
      <TextInput
        small
        className={styles.input}
        hasError={!!pinterestDestinationLinkErrors?.length}
        value={link ?? ""}
        onChange={onChangeLink}
        placeholder={LINK_PLACEHOLDER}
      />
      {!!pinterestDestinationLinkErrors && (
        <Caption className={styles.errorCaption} error>
          {pinterestDestinationLinkErrors[0]}
        </Caption>
      )}
    </AttachmentContainer>
  );
};

export default PinterestSettings;
