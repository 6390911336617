/* @flow */
import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

import type { loadComposer_accounts as AccountData } from "../../../../graphql/types/loadComposer.js";

import type { Props } from "./types.js";

const useSelectedAccounts = (props: Props) => {
  const [accountsLength, setAccountsLength] = useState(0);
  const [loadedSelectedAccounts, setLoadedSelectedAccounts] = useState(false);
  const [variationAccounts, setVariationAccounts] = useState<AccountData[]>([]);

  //Load data on editing
  useEffect(() => {
    if (!props.editing || loadedSelectedAccounts || (props.editing && !props.variation?.id)) return;
    if (!props.variation || !props.variation?.accountsData) return;

    const { accountsData } = props.variation;
    setLoadedSelectedAccounts(true);
    if (accountsData.length) {
      setVariationAccounts(
        props.accounts.filter(account =>
          accountsData.some(item => item.accountId === account.id)
        )
      );
    }
  }, [props.editing, props.accounts, props.variation, loadedSelectedAccounts]);

  // Remove from variation accounts if deleted from selected accounts
  useEffect(() => {
    if (props.accounts.length >= accountsLength) {
      setAccountsLength(props.accounts.length);
      return;
    }

    if (!props.variation || !props.variation?.accountsData) return;
    const { accountsData } = props.variation;

    const newVariationAccounts = variationAccounts.filter(account =>
      accountsData.some(item => item.accountId === account.id && !item.remove)
    );
    setVariationAccounts(newVariationAccounts);
  }, [props.accounts]);

  useEffect(() => {
    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
  }, [props.accounts, variationAccounts]);

  const handleVariationAccounts = (account: AccountData) => {
    const isAccountInArray = variationAccounts.some(
      item => item.id === account.id
    );
    if (isAccountInArray) {
      const newVariationAccounts = variationAccounts.filter(
        item => item.id !== account.id
      );
      setVariationAccounts(newVariationAccounts);
      return;
    } else {
      setVariationAccounts([...variationAccounts, account]);

      // get account data from variation if editing
      if (props.editing) {
        const accountData = props.variation
          ? props.variation.accountsData.find(
              item => item.accountId === account.id
            )
          : null;
        if (accountData) {
          props.onChangeAccountText(account.id, accountData.text);
          return;
        }
      }

      props.onChangeAccountText(account.id, "");
    }
  };

  const removeVariationAccount = (accountId: string) => {
    const newVariationAccounts = variationAccounts.filter(
      item => item.id !== accountId
    );
    setVariationAccounts(newVariationAccounts);

    const accountData = props.variation
      ? props.variation.accountsData.find(item => item.accountId === accountId)
      : null;
    if (props.editing && accountData?.id) {
      props.onChangeAccountText(accountId, undefined, true);
      return;
    }

    props.onChangeAccountText(accountId, undefined);
  };

  return { variationAccounts, handleVariationAccounts, removeVariationAccount };
};

export default useSelectedAccounts;
