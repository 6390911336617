import React from "react";

import { Caption } from "typography";
import Confirmation from "layout/modals/Confirmation";

import styles from "./index.css";

const DeleteConfirmation = ({ onConfirm, onCancel, children }) => (
  <Confirmation
    autoFocus={false}
    type="error"
    title={"Are you sure you want to remove this account?"}
    confirmLabel="Yes, remove"
    confirmType="negative"
    onConfirm={onConfirm}
    cancelLabel="Cancel"
    onCancel={onCancel}
  >
    <Caption helpText className={styles.subtitle}>
      {children.length ? (
        <>
          This account is the parent account for the following page(s) and/or
          group(s). Deleting this account will also delete:
          <br />
          <br />
          {children.map(childAccount => (
            <p key={childAccount.id}>{childAccount.name}</p>
          ))}
          <br />
          All schedules and content associations for these accounts will also be
          deleted.
          <br />
          <br />
          Are you sure you want to delete these {children.length + 1} accounts?
        </>
      ) : (
        <>
          Deleting an account will also delete its schedule and remove content
          associations.
        </>
      )}
    </Caption>
  </Confirmation>
);

export default DeleteConfirmation;
