// @flow
import React from "react";
import styles from "./index.css";

type Props = {
  value: string
};

const Text = ({ value }: Props) => {
  return <div className={styles.root}>{value}</div>;
};

export default Text;
