/* @flow */
import React from "react";
import moment from "moment";
import { reportMessage } from "errors";
import AccountAvatarWithBadge from "icons/AccountAvatarWithBadge";
import Caption from "typography/Caption";
import styles from "./index.css";

import type { Account } from "types";

type Post =
  | {
      id: string,
      sentAt: string,
      account: ?Account
    }
  | "DELETED_POST";

type Props = {
  account: ?Account,
  exhaustedMessage?: string,
  exhaustedType: "PINTEREST" | "TWITTER" | "TWITTER_DELETED_POST"
};

export const TWITTER_DELETED_POST =
  "Expired for Twitter: sent to a deleted twitter account";

export const getPinterestLimitMessage = (
  variationsCount: number,
  pinterestExpiredVariationsCount: number
): string => {
  return (
    (variationsCount > 1
      ? `${pinterestExpiredVariationsCount} of ${variationsCount} variations expired`
      : "Expired") +
    " for Pinterest this month. Will resume posting on the 1st."
  );
};

export const getTwitterExhaustedMessage = (
  account: ?Account,
  post: Post,
  variationsCount: number,
  twitterPostedVariationsCount: number
): string => {
  if (!post || !post.sentAt) {
    reportMessage(
      "Post undefined in <Exausted />, likely due to inconsistent last_posted_to data",
      { accountId: account && account.id }
    );
  }

  const sentAt = !post || typeof post === "string" ? "" : post.sentAt;
  return (
    (account ? "" : "Twitter account removed; ") +
    (variationsCount > 1
      ? `${twitterPostedVariationsCount} of ${variationsCount} variations expired `
      : "Expired ") +
    "for Twitter: sent " +
    (sentAt ? moment(sentAt).format("MMM. D, YYYY") : "at an unknown time")
  );
};

const Exhausted = ({ account, exhaustedMessage, exhaustedType }: Props) => {
  const isTwitterDeletedPost = exhaustedType === "TWITTER_DELETED_POST";
  return (
    <div className={styles.root}>
      {!isTwitterDeletedPost && account && (
        <AccountAvatarWithBadge
          key={account.id}
          value={account}
          className={styles.avatar}
        />
      )}
      <Caption helpText className={isTwitterDeletedPost ? "" : styles.caption}>
        {exhaustedMessage}
      </Caption>
    </div>
  );
};

export default Exhausted;
