/* @flow */
import React from "react";
import styles from "./index.css";

const parseUrl = (url: string): { host: string, path: string } => {
  const parser = document.createElement("a");
  parser.href = url;
  return {
    host: parser.host,
    path: `${parser.pathname}${parser.search}${parser.hash}`
  };
};

const FeedUrl = ({ url }: { url: string }) => {
  const { host, path } = parseUrl(url);
  return (
    <span>
      <span className={styles.host}>{host}</span>
      <span className={styles.path}>{path}</span>
    </span>
  );
};

export default FeedUrl;
