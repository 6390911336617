/* @flow */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import PageTitle from "links/PageTitle";
import { useNewUICached } from "util";
import NewHeader from "components/NewHeader";
import { parseFilterParams, buildPaginator } from "behavioral/withFilter";
import { parseSortParams } from "behavioral/withSort";
import GET_POSTS from "queries/getPosts";
import GET_REPORTS from "queries/getReports";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import App from "components/App";
import Search from "components/Search";
import Filters from "components/Filters";
import ReportList from "components/ReportList";
import ErrorWithRetry from "components/ErrorWithRetry";
import PostsTable from "components/PostsTable";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { getReports_reports_data } from "graphql-types/getReports";
import type { getContents_user_onboardingProgress as OnboardingProgressData } from "graphql-types/getContents";
import type { getPosts_posts_data as PostData } from "graphql-types/getPosts";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";

import styles from "./index.css";

export const HISTORY_HELP_URL =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/check-out-your-posting-history/edgars-post-history-page";
const PER_PAGE = 20;

type OwnProps = {
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  error?: string,
  reports?: getReports_reports_data[],
  dismissOnboarding?: () => Promise<DismissOnboardingResult>,
  onboardingProgress?: OnboardingProgressData,
  loadingPosts: boolean,
  postError?: any,
  posts: PostData[],
  totalFilteredPosts?: number,
  totalPosts?: number,
  timezone?: string,
  refetchPosts?: () => void
} & OwnProps;

export const History = ({
  dismissOnboarding,
  error,
  history,
  location,
  match,
  onboardingProgress,
  reports = [],
  subscriptionStatus,
  timezone,
  totalPosts,
  loadingPosts,
  postError,
  posts,
  totalFilteredPosts,
  refetchPosts,
  topNav
}: Props) => {
  const useNewUI = useNewUICached();

  return (
    <App
      loggedIn
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      onboardingProgress={onboardingProgress}
      onDismissOnboarding={dismissOnboarding}
      header={
        useNewUI ? (
          <NewHeader
            title="History"
            helpUrl={HISTORY_HELP_URL}
            mb
            {...topNav}
          />
        ) : (
          <PageTitle helpUrl={HISTORY_HELP_URL}>History</PageTitle>
        )
      }
      sidebar={
        <>
          <Filters
            location={location}
            showNoAccount
            dateFilterTitle="Date sent"
          />
          <ReportList reports={reports} />
        </>
      }
    >
      {error ? (
        <ErrorWithRetry>{error}</ErrorWithRetry>
      ) : (
        <>
          <div className={styles.searchWrapper}>
            <Search totalCount={totalPosts} />
          </div>
          <PostsTable
            loadingPosts={loadingPosts}
            postError={postError}
            posts={posts}
            totalFilteredPosts={totalFilteredPosts}
            totalPosts={totalPosts}
            timezone={timezone}
            refetchPosts={refetchPosts}
            history={history}
            location={location}
            match={match}
            perPage={PER_PAGE}
          />
        </>
      )}
    </App>
  );
};

export default (props: OwnProps) => {
  const { match } = props;

  // Queries
  const { loading, error, data } = useQuery(GET_REPORTS);

  const { filter, page } = parseFilterParams(match, location);
  const {
    loading: loadingPosts,
    error: postError,
    data: postData,
    refetch: refetchPosts
  } = useQuery(GET_POSTS, {
    variables: {
      page: buildPaginator(page, PER_PAGE),
      filter,
      sort: parseSortParams(location)
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });

  // Mutations
  const [
    dismissOnboarding,
    { loading: _dismissingOnboarding, error: _dismissOnboardingError }
  ] = useMutation(DISMISS_ONBOARDING);

  const posts = postData?.posts?.data || [];

  if (loading) {
    return <History {...props} loadingPosts={loadingPosts} posts={posts} />;
  }

  if (error) {
    return (
      <History
        {...props}
        error="Uh-oh something went wrong 😿"
        loadingPosts={loadingPosts}
        posts={posts}
      />
    );
  }

  return (
    <History
      {...props}
      loadingPosts={loadingPosts}
      postError={postError}
      posts={posts}
      totalFilteredPosts={postData?.posts?.totalFilteredPosts}
      totalPosts={postData?.posts?.totalPosts}
      timezone={postData?.company?.timeZone}
      refetchPosts={refetchPosts}
      dismissOnboarding={dismissOnboarding}
      onboardingProgress={data?.user?.onboardingProgress}
      reports={data?.reports?.data}
    />
  );
};
