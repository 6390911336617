/* @flow */
import React from "react";
// $FlowFixMe
import { FacebookProvider, EmbeddedPost } from "react-facebook";

import PulseGIF from "components/common/images/loading-pulse.svg";

import type { IProps } from "./types.js";
import useContent from "./use";
import { Wrapper, Container, Embed, Loading } from "./styles";

import Comments from "./Comments";
import Footer from "./Footer";

// Facebook appID for production: 534740913303527
// Facebook appID for development: 716053645172252

const Content = (props: IProps) => {
  const { post } = props;
  const {
    loading,
    comments,
    fetchComments,
    loadingMore,
    loadOlderComments,
    afterCommentCreate,
    containerRef
  } = useContent(props);

  return (
    <Wrapper>
      <Container ref={containerRef}>
        {/* <Header>
          <HButton className="disabled test">Direct messages</HButton>
          <HButton className="active">Comments</HButton>
        </Header> */}

        {!post ? (
          <Loading>You need to select an account and a post.</Loading>
        ) : (
          <>
            <FacebookProvider appId="534740913303527">
              <Embed>
                <EmbeddedPost href={post.url} width="auto" />
              </Embed>
            </FacebookProvider>

            {loading ? (
              <Loading>
                Loading post comments <img src={PulseGIF} alt="loading" />
              </Loading>
            ) : (
              <Comments
                data={comments}
                loadOlderComments={loadOlderComments}
                loadingMore={loadingMore}
                post={post}
              />
            )}
          </>
        )}
      </Container>

      {!!post && (
        <Footer
          post={post}
          triggerReloadComments={fetchComments}
          afterCommentCreate={afterCommentCreate}
        />
      )}
    </Wrapper>
  );
};

export default Content;
