import React from "react";
import { Modal } from "components/common/layout/modals";
import { Button } from "components/common/buttons";
import styles from "./index.css";

type Props = {
  onClose: () => void
};

const AddMoreContentDialog = ({ onClose }: Props) => (
  <Modal className={styles.root} onClose={onClose}>
    <h3 className={styles.title}>
      Great job!
      <br />
      Ready for more?
    </h3>
    <span className={styles.message}>
      Now that you've mastered adding content to your library, continue filling
      it in.
    </span>
    <Button type="secondary" href="/import" className={styles.button}>
      Import content
    </Button>
    <Button type="primary" onClick={onClose} className={styles.button}>
      Add more content
    </Button>
  </Modal>
);

export default AddMoreContentDialog;
