// @flow
import React from "react";
import ConfirmationModal from "layout/modals/Confirmation";

const TITLE =
  "Welcome to the add new content page! This is where you create your social " +
  "media content.";
const SUB_TITLE =
  "But first, connect your social media accounts so Edgar knows where to post!";

export const ConnectAccountsModal = () => (
  <ConfirmationModal
    title={TITLE}
    subTitle={SUB_TITLE}
    confirmLabel="Connect accounts"
    type="none"
    onConfirm={() => {
      window.location.href = "/accounts";
    }}
  />
);

export default ConnectAccountsModal;
