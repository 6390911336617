import React, { useState, useEffect } from "react";
import Select, { components } from 'react-select';
import {
    Container,
    Section,
    Fieldset,
    CommercialOptions,
    Option,
    CheckboxContainer,
    PaddedDiv,
    Input,
    ToggleContainer,
    ToggleLabel,
    ToggleSwitch,
    InfoBox,
    Description,
    Prompt,
    FlexContainer,
    SectionVideoContent,
    MarginedDiv,
    PrivacyStatus
} from "./styles";

const initialFieldsState = {};

const declarationText1 = (
    <>By posting, you agree to TikTok's{" "}
        <a
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            target="_blank"
            rel="noopener noreferrer"
        >
            Music Usage Confirmation
        </a>.
    </>
);

const declarationText2 = (
    <>By posting, you agree to TikTok's{" "}
        <a
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            target="_blank"
            rel="noopener noreferrer"
        >
            Branded Content Policy
        </a> and{" "}
        <a
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            target="_blank"
            rel="noopener noreferrer"
        >
            Music Usage Confirmation
        </a>.
    </>
);

const customStyles = {
    control: (provided) => ({
        ...provided,
        minWidth: '200px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#ccc' : '#333',
        backgroundColor: state.isDisabled ? '#f8f8f8' : '#fff',
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        '&:hover': {
            backgroundColor: state.isDisabled ? '#f8f8f8' : '#f0f0f0' // Ensure hover color on non-disabled options
        }
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
};

const CustomOption = (props) => {
    const { data, isDisabled, innerRef, innerProps } = props;
    const { setTooltipVisible, setTooltipPosition } = props.selectProps.selectProps;

    const handleMouseEnter = (e) => {
        if (isDisabled && data.value === 'SELF_ONLY') {
            const rect = e.target.getBoundingClientRect();
            setTooltipVisible(true);
            setTooltipPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
        }
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };

    return (
        <div
            ref={innerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            {...innerProps}
        >
            <components.Option {...props} />
        </div>
    );
};
export const TiktokFields = ({ setDisableButton, isEditing, content, onChangeTiktokFields, variations, setVariations, variationId }) => {
    const [privacyOptions, setPrivacyOptions] = useState([]);
    const [creatorNickname, setCreatorNickname] = useState('');
    const [commentDisabled, setCommentDisabled] = useState(false);
    const [duetDisabled, setDuetDisabled] = useState(false);
    const [stitchDisabled, setStitchDisabled] = useState(false);
    const [promptMessage, setPromptMessage] = useState('');
    const [declarationText, setDeclarationText] = useState(declarationText1);
    const [selectOptions, setSelectOptions] = useState([]);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });



    useEffect(() => {


        if (content && content.accountRelationships.length > 0) {
            const options = content.accountRelationships?.flatMap(acc => acc?.account?.tiktokMeta ? acc.account.tiktokMeta?.privacyLevelOptions : [])
                .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates
            setPrivacyOptions(options);

        }

        const tiktokAccount = content.accountRelationships?.find(acc => acc?.account?.platform === 'TIKTOK');
        if (tiktokAccount && tiktokAccount.account.nickname) {
            setCreatorNickname(tiktokAccount.account.nickname);
        }


        if (tiktokAccount && tiktokAccount.account.tiktokMeta) {
            setCommentDisabled(tiktokAccount.account.tiktokMeta.commentDisabled);
            setDuetDisabled(tiktokAccount.account.tiktokMeta.duetDisabled);
            setStitchDisabled(tiktokAccount.account.tiktokMeta.stitchDisabled);
            onChangeTiktokFields('maxVideoPostDurationSec', tiktokAccount.account.tiktokMeta.maxVideoPostDurationSec);
        }


        const variationExists = variations.some(variation => variation.variationId === variationId);

        if (!variationExists) {
            setVariations(prevVariations => [
                ...prevVariations,
                { variationId, privacyStatus: privacyOptions[0] || '', ...initialFieldsState }
            ]);
        }

    }, []);

    function formatPrivacyOption(option) {
        return option
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
            .join(' ');
    }
    const handleFieldChange = (field, value) => {
        setVariations(prevVariations => prevVariations.map(variation => {
            if (variation.variationId === variationId) {
                let updatedVariation = { ...variation, [field]: value };
                if (field === 'showCommercialContent' && !value) {
                    setPromptMessage('');
                    setDeclarationText(declarationText1);
                    updatedVariation.commercialType = [];
                }
                if (field === 'commercialType' && value.includes("brandedContent") && updatedVariation.privacyStatus === 'SELF_ONLY') {
                    updatedVariation.privacyStatus = 'FOLLOWER_OF_CREATOR';
                    setPromptMessage("Privacy set to Public as Branded Content can't be private.");
                    onChangeTiktokFields('privacyStatus', 'FOLLOWER_OF_CREATOR');
                }

                return updatedVariation;
            }
            return variation;
        }));

        onChangeTiktokFields(field, value);
    };

    const toggleCommercialType = (type) => {
        const currentVariation = variations.find(variation => variation.variationId === variationId);
        const updatedCommercialType = currentVariation.commercialType.includes(type)
            ? currentVariation.commercialType.filter(t => t !== type)
            : [...currentVariation.commercialType, type];
        handleFieldChange('commercialType', updatedCommercialType);
        if (updatedCommercialType.includes("brandedContent")) {
            setPromptMessage("Your video will be labeled as 'Paid partnership'");
            setDeclarationText(declarationText2);
        } else if (updatedCommercialType.includes("yourBrand")) {
            setPromptMessage("Your video will be labeled as 'Promotional content'");
            setDeclarationText(declarationText1);
        } else {
            setPromptMessage('');
            setDeclarationText(declarationText1);
        }




    };

    const currentVariation = variations.find(vari => vari.variationId === variationId) || {};


    useEffect(() => {
        setSelectOptions([
            ...privacyOptions.map(option => ({
                value: option,
                label: formatPrivacyOption(option),
                isDisabled: currentVariation.commercialType?.includes("brandedContent") && option === "SELF_ONLY"
            })),
        ]);
    }, [privacyOptions, currentVariation.commercialType]);



    return (
        <Container>
            <Section row>
                <PrivacyStatus>
                    <h3>Privacy Status:</h3>
                    <Select
                        value={selectOptions.find(option => option.value === currentVariation.privacyStatus)}
                        onChange={selected => handleFieldChange('privacyStatus', selected.value)}
                        options={selectOptions}
                        styles={customStyles}
                        placeholder="Who can view the video"
                        isOptionDisabled={option => option.isDisabled}
                        components={{ Option: CustomOption }}
                        selectProps={{ setTooltipVisible, setTooltipPosition }}
                    />
                    {tooltipVisible && (
                        <div
                            style={{
                                position: 'absolute',
                                top: tooltipPosition.top + 10, // Adjust distance below the option
                                left: tooltipPosition.left,
                                backgroundColor: '#333',
                                color: '#fff',
                                padding: '5px 10px',
                                borderRadius: '4px',
                                fontSize: '12px',
                                whiteSpace: 'nowrap',
                                zIndex: 1000,
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                            }}
                        >
                            Branded content cannot be set to private
                        </div>
                    )}
                </PrivacyStatus>
                <MarginedDiv>

                    <h3>Allow users to:</h3>
                    <Fieldset>
                        <CheckboxContainer disabled={commentDisabled}>
                            <Input
                                type="checkbox"
                                checked={!currentVariation.commentDisabled}
                                disabled={commentDisabled}
                                onChange={() => handleFieldChange('commentDisabled', !currentVariation.commentDisabled)}
                                id="commentDisabled"
                            />
                            <span className="checkmark"></span>
                            Comment
                        </CheckboxContainer>
                        <CheckboxContainer disabled={duetDisabled}>
                            <Input
                                type="checkbox"
                                checked={!currentVariation.duetDisabled}
                                disabled={duetDisabled}
                                onChange={() => handleFieldChange('duetDisabled', !currentVariation.duetDisabled)}
                                id="duetDisabled"
                            />
                            <span className="checkmark"></span>
                            Duet
                        </CheckboxContainer>
                        <CheckboxContainer disabled={stitchDisabled}>
                            <Input
                                type="checkbox"
                                checked={!currentVariation.stitchDisabled}
                                disabled={stitchDisabled}
                                onChange={() => handleFieldChange('stitchDisabled', !currentVariation.stitchDisabled)}
                                id="stitchDisabled"
                            />
                            <span className="checkmark"></span>
                            Stitch
                        </CheckboxContainer>
                    </Fieldset>
                </MarginedDiv>

                <SectionVideoContent row>
                    <PaddedDiv>
                        <ToggleContainer>
                            <ToggleLabel>Disclose video content</ToggleLabel>
                            <ToggleSwitch
                                type="checkbox"
                                checked={currentVariation.showCommercialContent}
                                onChange={() => handleFieldChange('showCommercialContent', !currentVariation.showCommercialContent)}
                            />
                        </ToggleContainer>
                        {promptMessage && <InfoBox>{promptMessage}</InfoBox>}

                        <Description>
                            Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a third party, or both.
                        </Description>
                    </PaddedDiv>
                    <FlexContainer>
                        {currentVariation.showCommercialContent && (
                            <CommercialOptions>
                                <CheckboxContainer>
                                    <input
                                        type="checkbox"
                                        value="yourBrand"
                                        checked={currentVariation.commercialType.includes('yourBrand')}
                                        onChange={() => toggleCommercialType('yourBrand')}
                                        id="yourBrand"
                                    />
                                    <span className="checkmark"></span>
                                    <h3>Your Brand</h3>
                                    <Description>You are promoting yourself or your own business. This content will be classified as Brand Organic.</Description>
                                </CheckboxContainer>
                                <CheckboxContainer>
                                    <input
                                        type="checkbox"
                                        value="brandedContent"
                                        checked={currentVariation.commercialType.includes('brandedContent')}
                                        onChange={() => toggleCommercialType('brandedContent')}
                                        id="brandedContent"
                                    />
                                    <span className="checkmark"></span>
                                    <h3>Branded Content</h3>
                                    <Description>You are promoting another brand or a third party. This content will be classified as Branded Content.</Description>
                                </CheckboxContainer>
                            </CommercialOptions>
                        )}
                    </FlexContainer>

                </SectionVideoContent>
            </Section>


            <Section>
                <p>{declarationText}</p>
            </Section>
        </Container>
    );


}