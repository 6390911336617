/* @flow */
import React from "react";
import classNames from "classnames";

import Label from "typography/Label";
import RadioButton from "forms/RadioButton2";

import type { getUserSettings_user_company_subscriptionPlan as SubscriptionPlan } from "graphql-types/getUserSettings";

import styles from "./index.css";

type Props = {
  isCurrent?: boolean,
  isSelectable?: boolean,
  isSelected?: boolean,
  product?: string,
  stripeId?: string,
  selectProduct?: (selectedProduct: ?string) => void
};

const DEFAULT_PLAN_SAVINGS_AMOUNT = -1;
const MONTHS_IN_YEAR = 12;

export const computeYearlyPlanAmount = (plan: SubscriptionPlan): number => {
  const amount = plan.amount || 0;
  switch (plan.interval) {
    case "month":
      return amount * MONTHS_IN_YEAR;
    case "year":
      return amount;
    default:
      return DEFAULT_PLAN_SAVINGS_AMOUNT;
  }
};

export const formatAmount = (amount: number): string => {
  return `$${amount.toFixed(2)}`;
};

export const formatInterval = (
  interval: string,
  intervalCount: number
): string => {
  if (interval === "") {
    return "";
  }
  return intervalCount > 1 ? `every ${interval}s` : `per ${interval}`;
};

export const computePlanName = (product: ?string, stripeId: ?string) => {
  let grand_father_plan_array = [
    "eddie",
    "eddie_nt",
    "eddie_annual",
    "eddie_annual_nt"
  ];

  if (stripeId == "eddie_free_shopify") {
    return (
      <div>
        <span>Free Plan</span>
        <ul className={styles.planLabelDetails}>
          <li>1 social accounts</li>
          <li>4 categories</li>
          <li>5 automated time slots</li>
        </ul>
      </div>
    );
  } else if (grand_father_plan_array.includes(stripeId)) {
    return (
      <div>
        <span>Eddie</span>
        <ul className={styles.planLabelDetails}>
          <li>3 social accounts</li>
          <li>4 categories</li>
          <li>10 automated time slots</li>
        </ul>
      </div>
    );
  } else {
    if (product !== "edgar") {
      return (
        <div>
          <span>Eddie</span>
          <ul className={styles.planLabelDetails}>
            <li>5 social accounts</li>
            <li>4 categories</li>
            <li>10 automated time slots</li>
          </ul>
        </div>
      );
    } else {
      // all other plans, including grandfathered plans, are 'Edgar' plans
      return (
        <div>
          <span>Edgar</span>
          <ul className={styles.planLabelDetails}>
            <li>25 social accounts</li>
            <li>Unlimited categories</li>
            <li>1000 automated time slots</li>
          </ul>
        </div>
      );
    }
  }
};

export const Body = ({ isSelectable = false, product, stripeId }: Props) => {
  return (
    <>
      <Label
        className={classNames({
          [styles.labelOverride]: isSelectable
        })}
      >
        {computePlanName(product, stripeId)}
      </Label>
    </>
  );
};

const Product = (props: Props) => {
  const { isCurrent = false, isSelectable = false, isSelected = false } = props;
  if (isSelectable) {
    const addClass = isSelected ? styles.selectedPlan : styles.unselectedPlan;
    return (
      <div
        className={`${styles.plan} ${styles.selectablePlan} ${addClass}`}
        data-plan-is-current={isCurrent}
        data-plan-is-selected={isSelected}
        onClick={() =>
          props.selectProduct && props.selectProduct(props.product)
        }
      >
        <RadioButton className={styles.radioButton} selected={isSelected} />
        <div>
          <Body {...props} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.plan} data-plan-is-current={isCurrent}>
      <Body {...props} />
    </div>
  );
};

export default Product;
