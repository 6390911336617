// @flow
export type ComposerPersistActionType =
  | "PERSIST"
  | "PERSIST_AND_SEND_NOW"
  | "OPEN_SET_SEND_AT_MODAL"
  | "APPROVE_AND_PERSIST"
  | "APPROVE_PERSIST_AND_SEND_NOW"
  | "APPROVE_AND_OPEN_SET_SEND_AT_MODAL";

const makeApprovedComboButtonOptions = (
  sendAt,
  sendAtWasInPastAtMount,
  isNewContent,
  loading,
  onAction
) => {
  const primaryOptions = [];
  primaryOptions.push({
    children: isNewContent ? "Save to Library" : "Save",
    loading,
    onClick: onAction.bind(null, "PERSIST")
  });
  const secondaryOptions = [];
  secondaryOptions.push({
    children: "Send now and save",
    onClick: onAction.bind(null, "PERSIST_AND_SEND_NOW")
  });
  if (!sendAt || sendAtWasInPastAtMount) {
    secondaryOptions.push({
      children: "Schedule send and save",
      onClick: onAction.bind(null, "OPEN_SET_SEND_AT_MODAL")
    });
  }

  return {
    primaryOptions,
    secondaryOptions
  };
};

const makePendingComboButtonOptions = (
  sendAt,
  sendAtWasInPastAtMount,
  loading,
  onAction
) => {
  const primaryOptions = [];
  primaryOptions.push({
    children: "Save and approve",
    loading,
    onClick: onAction.bind(null, "APPROVE_AND_PERSIST")
  });
  primaryOptions.push({
    children: "Save and leave pending",
    loading,
    onClick: onAction.bind(null, "PERSIST")
  });
  const secondaryOptions = [];
  secondaryOptions.push({
    children: "Approve, send now, and save",
    onClick: onAction.bind(null, "APPROVE_PERSIST_AND_SEND_NOW")
  });
  if (!sendAt || sendAtWasInPastAtMount) {
    secondaryOptions.push({
      children: "Approve, schedule send, and save",
      onClick: onAction.bind(null, "APPROVE_AND_OPEN_SET_SEND_AT_MODAL")
    });
  }

  return {
    primaryOptions,
    secondaryOptions
  };
};

type Options = {
  contentRequiresApproval: boolean,
  isNewContent: boolean,
  sendAt: ?string,
  sendAtWasInPastAtMount: boolean,
  loading: boolean,
  onAction: (actionType: ComposerPersistActionType) => void
};

const getComboButtonOptions = ({
  contentRequiresApproval,
  isNewContent,
  sendAt,
  sendAtWasInPastAtMount,
  loading,
  onAction
}: Options) =>
  contentRequiresApproval
    ? makePendingComboButtonOptions(
        sendAt,
        sendAtWasInPastAtMount,
        loading,
        onAction
      )
    : makeApprovedComboButtonOptions(
        sendAt,
        sendAtWasInPastAtMount,
        isNewContent,
        loading,
        onAction
      );

export default getComboButtonOptions;
