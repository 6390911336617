// @flow
import React from "react";
import ScaledImage from "components/common/ScaledImage";
import styles from "./index.css";

type Props = {
  urls: string[]
};

const LandscapeFour = ({ urls }: Props) => (
  <div className={styles.container}>
    <div className={styles.primaryImage}>
      <ScaledImage value={urls[0]} targetWidth={474} targetHeight={315} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[1]} targetWidth={155} targetHeight={155} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[2]} targetWidth={155} targetHeight={155} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[3]} targetWidth={155} targetHeight={155} />
      {urls.length > 4 && (
        <div className={styles.overlay}>+{urls.length - 4 + 1}</div>
      )}
    </div>
  </div>
);

export default LandscapeFour;
