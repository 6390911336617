/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query loadPayment {
    user {
      id
      onboardingProgress {
        id
        addAccount
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
    }
    company {
      id
      firstDueDate
      subscriptionPlan {
        id
        stripeId
        interval
        productName
        hasTrial
      }
      availableSubscriptionPlans {
        id
        stripeId
        name
        interval
        amount
        productName
        doNotDisplayYearlySavings
        savingsPerYear
        hasTrial
      }
    }
  }
`;
