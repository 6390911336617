// @flow
import React from "react";
import classNames from "classnames";
import Alert from "layout/Alert";
import { AutoLinkText } from "links";

type Props = { errors: string[], className?: string };

const ErrorMessageList = ({ errors, className = "" }: Props) => {
  return (
    <div
      className={classNames({ [className]: !!className })}
      style={{
        display: errors.length ? "flex" : "none"
      }}
    >
      <Alert type="error">
        {errors.length === 1 && <AutoLinkText>{errors[0]}</AutoLinkText>}
        {errors.length > 1 && (
          <ul>
            {errors.map((message, i) => (
              <li key={i}>
                <AutoLinkText>{message}</AutoLinkText>
              </li>
            ))}
          </ul>
        )}
      </Alert>
    </div>
  );
};

export default ErrorMessageList;
