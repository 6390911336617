import styled from "styled-components";

export const Item = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 6px 0;
`;

export const Label = styled.span`
  color: #444;
  font-size: 14px;
  font-weight: 400;
  margin: -1px 0 0 9px;
`;
