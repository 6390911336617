/* @flow */
import {
  find,
  differenceBy,
  intersectionBy,
  isEmpty,
  isUndefined
} from "lodash";
import type {
  loadContent_content as ContentData,
  loadContent_content_variations_videos as VideoData,
  loadContent_content_variations_images as ImageData,
  ContentStatus
} from "graphql-types/loadContent";

type VariationChangeset = {|
  id?: string,
  clientId: string,
  text?: string,
  fbText?: string,
  link?: ?string,
  pinterestDestinationLink?: ?string,
  pinterestTitle?: ?string,
  imageRelationships?: {|
    imageId: string,
    remove?: boolean,
    image?: ImageData
  |}[],
  videoRelationships?: {|
    videoId: string,
    remove?: boolean,
    video?: VideoData
  |}[],
  remove?: boolean
|};

type AccountChangeset = {|
  accountId: string,
  remove?: boolean,
  pinterestBoardRelationships?: {|
    pinterestBoardId: string,
    remove?: boolean
  |}[]
|};

type ContentChangeset = {|
  id?: string,
  categoryId?: string,
  status?: ContentStatus,
  useOnce?: boolean,
  sendAt?: string,
  expiresAt?: string,
  useShortLinks?: boolean,
  sendMobileReminder?: boolean,
  instaReels?: boolean,
  accountRelationships?: AccountChangeset[],
  variations?: VariationChangeset[]
|};

const SCALAR_CONTENT_FIELDS = Object.freeze([
  "status",
  "useOnce",
  "useShortLinks",
  "sendAt",
  "expiresAt",
  "sendMobileReminder",
  "instaReels"
]);

const SCALAR_VARIATION_FIELDS = Object.freeze([
  "text",
  "fbText",
  "origin",
  "link",
  "rawRichTextEntityMap",
  "pinterestDestinationLink",
  "pinterestTitle"
]);

export default function(
  original: ?ContentData,
  modified: ContentData
): ?ContentChangeset {
  const changes = {};
  // #######################################
  // Content Scalars
  // #######################################
  SCALAR_CONTENT_FIELDS.reduce((acc, field) => {
    if (modified[field] !== original?.[field]) {
      acc[field] = modified[field];
    }
    return acc;
  }, changes);

  if (modified.category !== original?.category) {
    changes.categoryId = modified.category?.id;
  }

  // #######################################
  // Accounts
  // #######################################
  const newAccounts = differenceBy(
    modified.accountRelationships,
    original?.accountRelationships ?? [],
    "account.id"
  ).map(({ account: { id: accountId }, pinterestBoards }) => {
    const changes = {};
    changes.accountId = accountId;
    const pinterestBoardRelationships = pinterestBoards.map(b => ({
      pinterestBoardId: b.id
    }));
    if (!isEmpty(pinterestBoardRelationships)) {
      changes.pinterestBoardRelationships = pinterestBoardRelationships;
    }
    return changes;
  });

  const removedAccounts = differenceBy(
    original?.accountRelationships ?? [],
    modified.accountRelationships,
    "account.id"
  ).map(({ account: { id: accountId } }) => ({
    accountId,
    remove: true
  }));

  const modifiedAccounts = intersectionBy(
    modified.accountRelationships,
    original?.accountRelationships ?? [],
    "account.id"
  )
    .map(({ account: { id: accountId } }) => {
      const originalAccount = find(original?.accountRelationships ?? [], [
        "account.id",
        accountId
      ]);
      const modifiedAccount = find(modified.accountRelationships, [
        "account.id",
        accountId
      ]);

      if (!originalAccount || !modifiedAccount) {
        throw "Error diffing accounts for save";
      }

      // =======================================
      // Pinterest Boards
      // =======================================
      const newBoards = differenceBy(
        modifiedAccount.pinterestBoards,
        originalAccount.pinterestBoards,
        "id"
      ).map(({ id }) => ({ pinterestBoardId: id }));
      const removedBoards = differenceBy(
        originalAccount.pinterestBoards,
        modifiedAccount.pinterestBoards,
        "id"
      ).map(({ id }) => ({ pinterestBoardId: id, remove: true }));

      const pinterestBoardChanges = [...newBoards, ...removedBoards];

      if (isEmpty(pinterestBoardChanges)) {
        return {};
      }

      return {
        accountId,
        pinterestBoardRelationships: pinterestBoardChanges
      };
    })
    .filter(a => !isEmpty(a));

  const accountRelationshipChanges = [
    ...newAccounts,
    ...removedAccounts,
    ...modifiedAccounts
  ];
  if (!isEmpty(accountRelationshipChanges)) {
    changes.accountRelationships = accountRelationshipChanges;
  }

  // #######################################
  // Variations
  // #######################################
  const newVariations = modified.variations
    .filter(v => !v.id)
    .map(v => {
      const variationChanges = {};
      variationChanges.clientId = v.clientId;
      SCALAR_VARIATION_FIELDS.forEach(field => {
        if (!isUndefined(v[field])) {
          variationChanges[field] = v[field];
        }
      });
      const imageRelationships = v.images.map((i, index) => ({
        imageId: i.id,
        position: index + 1
      }));
      if (!isEmpty(imageRelationships)) {
        variationChanges.imageRelationships = imageRelationships;
      }
      const videoRelationships = v.videos.map(i => ({
        videoId: i.id
      }));
      if (!isEmpty(videoRelationships)) {
        variationChanges.videoRelationships = videoRelationships;
      }
      return variationChanges;
    });
  const removedVariations = differenceBy(
    original?.variations ?? [],
    modified.variations,
    "id"
  ).map(v => ({
    id: v.id,
    clientId: v.clientId,
    remove: true
  }));
  const modifiedVariations = intersectionBy(
    modified.variations,
    original?.variations ?? [],
    "id"
  )
    .map(({ id, clientId }) => {
      const originalVariation = (original?.variations ?? []).find(
        v => v.clientId === clientId
      );
      const modifiedVariation = modified.variations.find(
        v => v.clientId === clientId
      );
      if (!originalVariation || !modifiedVariation) {
        throw "Error diffing variations for save";
      }

      const variationChanges = SCALAR_VARIATION_FIELDS.reduce((acc, field) => {
        if (modifiedVariation[field] !== originalVariation[field]) {
          acc[field] = modifiedVariation[field];
        }
        return acc;
      }, {});

      // =======================================
      // Images
      // =======================================
      const allImages = modifiedVariation.images.map((item, index) => ({
        imageId: item.id,
        position: index + 1
      }));
      const removedImages = differenceBy(
        originalVariation.images,
        modifiedVariation.images,
        "id"
      ).map(({ id }) => ({ imageId: id, remove: true }));
      const imageRelationships = [...allImages, ...removedImages];

      // =======================================
      // Videos
      // =======================================
      const newVideos = differenceBy(
        modifiedVariation.videos,
        originalVariation.videos,
        "id"
      ).map(({ id }) => ({ videoId: id }));
      const removedVideos = differenceBy(
        originalVariation.videos,
        modifiedVariation.videos,
        "id"
      ).map(({ id }) => ({ videoId: id, remove: true }));
      const videoRelationships = [...newVideos, ...removedVideos];

      if (!isEmpty(imageRelationships)) {
        variationChanges.imageRelationships = imageRelationships;
      }

      if (!isEmpty(videoRelationships)) {
        variationChanges.videoRelationships = videoRelationships;
      }

      if (!isEmpty(variationChanges)) {
        variationChanges.id = id;
        variationChanges.clientId = clientId;
      }

      return variationChanges;
    })
    .filter(c => !isEmpty(c));

  const variationChanges = [
    ...newVariations,
    ...removedVariations,
    ...modifiedVariations
  ];
  if (!isEmpty(variationChanges)) {
    changes.variations = variationChanges;
  }

  if (isEmpty(changes)) {
    return null;
  }

  if (original?.id) {
    changes.id = original?.id;
  }

  return changes;
}
