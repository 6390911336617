// @flow
import type { loadContent_content as ContentData } from "graphql-types/loadContent";
import type { ComposerPersistActionType } from "../getComboButtonOptions";
import { featureFlag } from "util";

export type ConfirmationText = {
  title: string,
  subTitle: string
};

const getRequiredConfirmations = (
  content: ContentData,
  actionType: ComposerPersistActionType
) => {
  const requiredConfirmations = [];

  if (!content.category) {
    requiredConfirmations.push({
      title: "No category has been selected.",
      subTitle:
        "Please select a category.",
      category: true,
    });
  }

  if (!content.accountRelationships.length) {
    requiredConfirmations.push({
      title: "No social accounts have been selected.",
      subTitle:
        "Are you sure you want to save? This content won't be sent until one or more accounts are selected."
    });
  }

  if (content.variations.some(({ videos, images, text }) => !videos.length && !images.length && !text)) {
    requiredConfirmations.push({
      title: "Invalid Content.",
      subTitle:
        "Please add text, image or video to the variation.",
      text: true
    });
  }

  if (content.sendAt && actionType === "PERSIST_AND_SEND_NOW") {
    requiredConfirmations.push({
      title: "This content is already scheduled to send at a specific date.",
      subTitle:
        "Are you sure you want to continue? The content will send now and the original schedule will be lost."
    });
  }

  const confirmationLinkedInCarousel = needsLinkedInImageTitle(content);
  if (confirmationLinkedInCarousel.length > 0) {
    requiredConfirmations.push({
      title: "Invalid Content.",
      subTitle:
        confirmationLinkedInCarousel[0],
      text: true
    });
  }

  const hasTikTokPlatform = content.accountRelationships.some(acc => acc.account.platform === 'TIKTOK');

  const tikTokDirectPost = featureFlag("tiktok_direct_post");
  if (hasTikTokPlatform && tikTokDirectPost) {
    content.variations.forEach(variation => {
      if (variation.tiktokMeta) {
        if (variation.tiktokMeta.privacyStatus === "") {
          requiredConfirmations.push({
            title: "Invalid Content.",
            subTitle:
              "Privacy status for TikTok must be set for variation.",
            text: true
          });
        }

        if (variation.tiktokMeta.showCommercialContent && (!variation.tiktokMeta.commercialType || variation.tiktokMeta.commercialType.length === 0)) {
          requiredConfirmations.push({
            title: "Invalid Content.",
            subTitle:
              "Commercial types must be specified when disclosing commercial content for variation.",
            text: true
          });
        }
      }
    });
  }

  return requiredConfirmations;
};
const needsLinkedInImageTitle = (content) => {
  return (content.variations || []).map(({ text, images, imagesTextVariations }) => {
    if (!content.linkedinCarousel) {
      return "";
    }
    if (images?.length < 2) {
      return "LinkedIn Carousel Requires At Least 2 Images";
    }

    if (!text) {
      return "LinkedIn Carousel Requires Text for the Base Content"
    }

    function getAlternateText(imageId) {
      return imagesTextVariations?.find(x => x.imageId == imageId)?.text || "";
    }
    const imagesWithNoText = [...images].some(x => {
      const textEvaluate = x.text || getAlternateText(x.id);
      return !textEvaluate || textEvaluate.trim() == "";
    });
    if (imagesWithNoText) {
      return "LinkedIn Carousel Requires Titles for Images"
    }

    return "";
  }).filter(x => x.length > 0)
}
export default getRequiredConfirmations;
