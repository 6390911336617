/* @flow */
import React from "react";
import { withRouter } from "react-router-dom";

import { useNewUICached } from "util";
import NewHeader from "components/NewHeader";
import NavTabs, { Tab } from "layout/NavTabs";
import Container from "layout/Container";
import { Body, H1 } from "components/common/typography";

import type { Props } from "./types.js";
import useImportManager from "./use";
import styles from "./index.css";

import AddNew from "./AddNew";
import ShopifyAuthentication from "./ShopifyAuthentication";
import Manage from "./Manage";
import { featureFlag } from "util";

export const ImportManager = (props: Props) => {
  const { location, topNav } = props;
  const useNewUI = featureFlag("navbar_modules_ui");

  const {
    authorizationSuccess,
    shopifyModalVisible,
    hideShopifyModal
  } = useImportManager(props);

  return (
    <div>
      <Container>
        <div className={!useNewUI ? styles.header : styles.newUIHeader}>
          {useNewUI ? (
            <NewHeader title="Import content" mb {...topNav} />
          ) : (
            <H1>Import content</H1>
          )}
          <Body>
            Click on any icon below to import content. Edgar will check each
            connection once a day to import new content automatically.
          </Body>
        </div>

        <div className={styles.tabs}>
          <NavTabs>
            <Tab exact to={"/import"}>
              Add new
            </Tab>
            <Tab exact to={"/import/manage"}>
              Manage connections
            </Tab>
          </NavTabs>
        </div>

        <div>
          {location && location.pathname === "/import/manage" ? (
            <Manage />
          ) : (
            <AddNew />
          )}
        </div>
      </Container>

      {authorizationSuccess && shopifyModalVisible ? (
        <ShopifyAuthentication
          authorizationSuccess={authorizationSuccess}
          onClickHide={hideShopifyModal}
        />
      ) : null}
    </div>
  );
};

export default withRouter(ImportManager);
