// @flow
import React from "react";
import classNames from "classnames";
import Caption from "typography/Caption";
import tooltipper from "behavioral/tooltipper";
import styles from "./index.css";

type RemainingCharacterCounts = { [key: string]: number };
type Props = {
  value: RemainingCharacterCounts
};

export const CharacterCountNotice = ({ value }: Props) => {
  const visible = Object.keys(value).length;
  const closestLimit = Object.keys(value)
    .map(key => value[key])
    .sort((a, b) => a - b)[0];
  const overLimit = closestLimit < 0;

  return (
    <Caption
      helpText
      className={classNames(styles.root, { [styles.overLimit]: overLimit })}
      style={{ display: visible ? "block" : "none" }}
    >
      {overLimit && <span>Exceeded character limit </span>}
      {closestLimit}
    </Caption>
  );
};

export const TipBody = ({ value }: Props) => (
  <ul>
    {Object.keys(value).map(key => (
      <li key={key}>
        {key}: {value[key]} left
      </li>
    ))}
  </ul>
);

export default tooltipper(TipBody, { title: "Character limits" })(
  CharacterCountNotice
);
