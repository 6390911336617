// @flow
import React from "react";
import HtmlizedText from "components/common/HtmlizedText";
import styles from "./index.css";
import type { RawRichTextEntityMap } from "types";

export const LINK_CHAR_COUNT = 23;

type Props = {
  value: string,
  omitTrailingLinks?: boolean,
  mentions: string[],
  rawRichTextEntityMap: ?RawRichTextEntityMap
};

const Text = ({
  value,
  omitTrailingLinks,
  mentions,
  rawRichTextEntityMap
}: Props) => (
  <HtmlizedText
    value={value}
    provider="twitter"
    truncateLinksTo={LINK_CHAR_COUNT}
    omitTrailingLinks={omitTrailingLinks}
    omitLinkScheme
    className={styles.root}
    newLineClassName={styles.newLine}
    mentions={mentions}
    rawRichTextEntityMap={rawRichTextEntityMap}
  />
);

export default Text;
