// @flow
import React, { type ComponentType } from "react";
import { withRouter } from "react-router-dom";

import { extractSort, patchNextSearchSort } from "./helpers";

import type { Location } from "react-router-dom";

export type SortDirection = "asc" | "desc";

export type ContentSortableField =
  | "category"
  | "sent_at"
  | "account"
  | "content"
  | "clicks"
  | "times_sent"
  | "avg_clicks";

export type ContentSort = {
  direction?: ?SortDirection,
  field?: ?ContentSortableField
};

type Props = {
  location: Location,
  history: any // https://github.com/flowtype/flow-typed/pull/284 Maybe add if extra time?
};

export function parseSortParams(location: Location): ContentSort {
  return extractSort(location);
}

type HoF = (WrappedComponent: ComponentType<any>) => ComponentType<any>;
const withSort = (): HoF => WrappedComponent => {
  const composedComponent = class extends React.Component<Props> {
    handleSortPatch = (changes: ContentSort) => {
      const {
        location: { pathname, search },
        history
      } = this.props;
      const nextSearch = patchNextSearchSort(search, changes);
      const nextUrl = `${pathname}?${nextSearch}`;
      history.push(nextUrl);
    };

    getSort = (): ContentSort => {
      const { location } = this.props;
      return extractSort(location);
    };

    render() {
      return (
        <WrappedComponent
          sort={this.getSort()}
          {...this.props}
          onSortPatch={this.handleSortPatch}
        />
      );
    }
  };
  return withRouter(composedComponent);
};

export default withSort;
