/* eslint-disable flowtype/require-valid-file-annotation */
import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./index.css";

type Props = {
  to: string,
  exact?: boolean,
  isActive?: (match: any, location: any) => boolean,
  className?: string,
  children?: React.Element<*>
};

const Tab = ({ to, exact, isActive, className, children }: Props) => {
  const classes = classNames(styles.root, { [className]: !!className });
  return (
    <NavLink
      to={to}
      exact={exact}
      isActive={isActive}
      className={classes}
      activeClassName={styles.active}
    >
      {children}
    </NavLink>
  );
};

export default Tab;
