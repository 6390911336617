/* @flow */
import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Label from "typography/Label";
import LoadingIcon from "icons/LoadingIcon";
import AccountSelect from "components/common/AccountSelect";

import styles from "./index.css";

import type {
  bulkContentUpdate as BulkContentUpdateResult,
  bulkContentUpdateVariables,
  ContentsFilter
} from "graphql-types/bulkContentUpdate";
import type { getAccounts_accounts as AccountData } from "graphql-types/getAccounts";

type Props = {
  accounts: AccountData[],
  bulkContentUpdate?: ({
    variables: bulkContentUpdateVariables
  }) => Promise<{ data: BulkContentUpdateResult }>,
  close: () => void,
  error: boolean,
  failUpdate: () => void,
  filter: ContentsFilter,
  initUpdate: () => void,
  isSelectAll: boolean,
  loading: boolean,
  selected: string[],
  startUpdate: () => void,
  unselected: string[],
  updateState: string
};

type SubProps = {
  selectedAccounts: string[],
  setSelectedAccounts: (string[]) => void
} & Props;

// Pinterest requires extra handling with selecting boards so not allowing for now.
const DISABLED_PROVIDERS = ["PINTEREST"];

const AccountSelector = (props: SubProps) => {
  return (
    <AccountSelect
      accounts={props.accounts}
      allowMultipleTwitter={true}
      className={styles.sectionFieldInput}
      disabledMessaging={props.accounts.reduce((map, account) => {
        if (!DISABLED_PROVIDERS.includes(account.provider)) {
          return map;
        }
        switch (account.provider) {
          case "PINTEREST":
            return {
              ...map,
              [account.id]:
                "Pinterest accounts cannot be bulk edited at this time."
            };
          default:
            return map;
        }
      }, {})}
      disabledProviders={DISABLED_PROVIDERS}
      displayOneTwitterAccountPerAlert={false}
      hasError={false}
      onChange={props.setSelectedAccounts}
      selected={props.selectedAccounts}
    />
  );
};

const AccountSelectorWrapper = (props: SubProps) => {
  if (props.error) {
    return <span>Unable to load accounts</span>;
  }

  if (props.loading) {
    return <LoadingIcon className={styles.loading} />;
  }

  return <AccountSelector {...props} />;
};

const RemoveAccounts = (props: Props) => {
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  return (
    <Modal
      footer={
        <>
          <Button
            className={styles.buttonCancel}
            disabled={props.updateState !== "None"}
            onClick={props.close}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={
              selectedAccounts.length < 1 || props.updateState !== "None"
            }
            onClick={async (): Promise<void> => {
              const bulkContentUpdate = props.bulkContentUpdate;
              if (!bulkContentUpdate) {
                return;
              }

              try {
                props.initUpdate();

                const resp = await bulkContentUpdate({
                  variables: {
                    input: {
                      action: "remove_account_ids",
                      accountIds: selectedAccounts,
                      filter: props.filter,
                      isSelectAll: props.isSelectAll,
                      selected: props.selected,
                      unselected: props.unselected
                    }
                  }
                });

                const errors = resp?.data?.bulkContentUpdate?.errors || [];
                if (errors.length > 0) {
                  props.failUpdate();
                } else {
                  props.startUpdate();
                }
              } catch (_err) {
                props.failUpdate();
              }
            }}
            type="primary"
          >
            Apply
          </Button>
        </>
      }
      onClose={props.close}
      size="large"
      title="Remove accounts"
    >
      <div className={styles.main}>
        <Label>Select the social accounts you would like to remove</Label>
        <small className={styles.sectionFieldDescription}>
          All selected content items will have this social account removed. If
          you selected one without this account, nothing will happen.
        </small>
        <AccountSelectorWrapper
          {...props}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
        />
      </div>
    </Modal>
  );
};

export default RemoveAccounts;
