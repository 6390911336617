/* @flow */
import { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import TOGGLE_NEW_UI from "mutations/toggleNewUI";

const useUISwitch = () => {
  const [loading, setLoading] = useState(false);

  const apolloClient = useApolloClient();

  const toggleNewUI = async () => {
    setLoading(true);

    try {
      const res = await apolloClient.mutate({
        mutation: TOGGLE_NEW_UI,
        variables: { enable: true }
      });

      if (res.data) {
        localStorage.setItem("navbar_modules_ui", "true");
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return { loading, toggleNewUI };
};

export default useUISwitch;
