/* @flow */
import React, { useCallback } from "react";
import RadioGroup from "forms/RadioGroup";
import RadioButton from "./RadioButton";

import styles from "./index.css";

import type { loadPayment_company_availableSubscriptionPlans as SubscriptionPlanData } from "graphql-types/loadPayment";

type Props = {
  availablePlans: SubscriptionPlanData[],
  selectedPlan: SubscriptionPlanData,
  onChoosePlan: SubscriptionPlanData => void
};

function costPerDay(amount) {
  return Math.ceil((amount * 100) / 30);
}

function savingsText(plan) {
  return `(save $${plan.savingsPerYear.toFixed(2)} every year!) `;
}

export const PlanSelector = ({
  availablePlans,
  selectedPlan,
  onChoosePlan
}: Props) => {
  const handleChangePlan = useCallback(
    stripeId => {
      const newPlan = availablePlans.find(plan => plan.stripeId === stripeId);
      if (newPlan) {
        onChoosePlan(newPlan);
      }
    },
    [availablePlans, onChoosePlan]
  );

  return (
    <div className={styles.root}>
      <RadioGroup
        selectedValue={selectedPlan.stripeId}
        onChange={handleChangePlan}
      >
        {// assumes our base plan is always the first monthly one we get back.
        availablePlans.map((plan, i) => (
          <RadioButton
            key={i}
            value={plan.stripeId}
            title={plan.name}
            selected={plan.stripeId === selectedPlan.stripeId}
          >
            ${plan.amount.toFixed(2)} per {plan.interval}
            <span className={styles.savingsText}>
              {plan.interval === "year"
                ? plan.savingsPerYear > 0.0 &&
                  !plan.doNotDisplayYearlySavings &&
                  savingsText(plan)
                : null}
              {plan.interval === "month" && costPerDay(plan.amount) < 100
                ? `(That's less than ${costPerDay(plan.amount)} cents a day!)`
                : null}
              {plan.interval === "year" && "(get 2 months FREE)"}
            </span>
          </RadioButton>
        ))}
      </RadioGroup>
    </div>
  );
};

export default PlanSelector;
