/* @flow */
import React from "react";
import Select from "../common/Select";
import FeedUrl from "components/common/FeedUrl";

import type {
  loadFilters_teamMembers as TeamMemberData,
  loadFilters_rssFeeds as RssFeedData
} from "graphql-types/loadFilters";

type Props = {
  loading: boolean,
  teamMembers: TeamMemberData[],
  rssFeeds: RssFeedData[]
};

export const Source = ({ loading, teamMembers, rssFeeds }: Props) => {
  const options = teamMembers
    .map(({ id, email }) => ({
      value: `creator-${id}`,
      label: email
    }))
    .concat(
      rssFeeds.map(({ id, name, url }) => ({
        value: `feed-${id}`,
        label: name ? name : <FeedUrl url={url} />
      }))
    )
    .concat([
      {
        value: "unknown",
        label: "Deleted sources"
      }
    ]);

  return (
    <Select
      label="Source"
      filterKey="source"
      options={options}
      loading={loading}
    />
  );
};

export default Source;
