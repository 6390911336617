/* @flow */
import React from "react";

import CategoryIcon from "icons/CategoryIcon";

import type { getPosts_posts_data } from "graphql-types/getPosts";

import styles from "./index.css";

type Props = {
  post: getPosts_posts_data,
  className?: string
};

const Category = ({ className = "", post }: Props) => {
  return (
    <div className={`${styles.category} ${className}`}>
      {post.content.category && (
        <>
          <div>
            <CategoryIcon category={post.content.category} />
          </div>{" "}
          <span
            className={styles.categoryName}
            title={post.content.category.name}
          >
            {post.content.category.name}
          </span>
        </>
      )}
    </div>
  );
};

export default Category;
