import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px;
`;

export const Empty = styled.div``;

export const LoadOlder = styled.div`
  text-align: center;

  div {
    align-items: center;
    background-color: #f2f3f4;
    border: 1px solid #f2f3f4;
    border-radius: 3px;
    cursor: pointer;
    display: inline-flex;
    margin: 0 auto 30px;
    padding: 10px 15px;
    text-align: center;
    width: auto;

    img {
      height: 18px;
      margin: 0 0 0 5px;
    }
  }

  ${({ isLoading }) =>
    isLoading
      ? `
    div {
      background-color: #fafafa;
      border-color: #fafafa;
      color: #777;
      cursor: default;
    }
  `
      : `
    div:hover {
      border-color: #efefef;
      box-shadow: 1px 1px 3px rgba(100, 100, 100, 0.1);
    }
  `}
`;
