/* @flow */
import React from "react";

import { colorFromIndex } from "colors";
import Paginator from "components/Paginator";
import SVGLoading from "components/common/images/loading-pulse.svg";

import type { IProps } from "./types.js";
import usePosts from "./use";
import {
  Wrapper,
  Post,
  Category,
  Circle,
  Content,
  Picture,
  Description,
  Loading,
  Info,
  PaginatorStyles
} from "./styles";

function colorWithRandom(idx?: number) {
  if (!idx) return null;
  return idx == -1 ? "var(--inky400)" : colorFromIndex(idx);
}

const PostsList = (props: IProps) => {
  const { selectedCategory, selectPost } = props;
  const {
    loadingContents,
    contents,
    page,
    pageCount,
    handlePageChange
  } = usePosts(props);
  return (
    <Wrapper>
      {!selectedCategory ? (
        <Info>Waiting for a category.</Info>
      ) : loadingContents ? (
        <Loading>
          <img src={SVGLoading} alt="loading contents" />
          Loading contents...
        </Loading>
      ) : !contents.length ? (
        <Info>There are no posts for the selected category.</Info>
      ) : (
        contents.map((item, index) => {
          if (!item.variations.length) return null;
          const variation = item.variations[0];
          const category: any = item.category;

          const image = variation.images?.length
            ? variation.images[0].url
            : null;

          return (
            <Post
              key={index}
              title="Click to select this content"
              onClick={() => selectPost(item)}
            >
              <Content>
                {image && (
                  <Picture>
                    <img src={image} alt="post image" />
                  </Picture>
                )}
                {variation.displayText && (
                  <Description>{variation.displayText}</Description>
                )}
              </Content>
              <Category>
                <Circle color={colorWithRandom(category.colorIndex)} />
                {category.name}
              </Category>
            </Post>
          );
        })
      )}
      {!loadingContents && contents && selectedCategory && (
        <PaginatorStyles>
          <Paginator
            page={page}
            pageCount={pageCount}
            onPageChange={handlePageChange}
          />
        </PaginatorStyles>
      )}
    </Wrapper>
  );
};

export default PostsList;
