/* @flow */
import React from "react";

import CategoriesBreakdown from "./Categories";
import AccountsBreakdown from "./Accounts";

import useBreakdowns from "./use";
import type { IProps } from "./types.js";
import { Wrapper, Tabs, Tab } from "./styles";

const Breakdowns = (props: IProps) => {
  const { filters, selectedAccount } = props;
  const { currentTab, showCategories, showAccounts } = useBreakdowns();

  return (
    <Wrapper>
      {!selectedAccount && (
        <Tabs>
          <Tab
            className={currentTab === "categories" ? "active" : ""}
            onClick={showCategories}
          >
            Category breakdown
          </Tab>
          <Tab
            className={currentTab === "accounts" ? "active" : ""}
            onClick={showAccounts}
          >
            Account breakdown
          </Tab>
        </Tabs>
      )}

      {currentTab === "categories" || selectedAccount ? (
        <CategoriesBreakdown
          filters={filters}
          selectedAccount={selectedAccount}
        />
      ) : (
        <AccountsBreakdown filters={filters} />
      )}
    </Wrapper>
  );
};

export default Breakdowns;
