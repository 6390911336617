/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 16px;

  b {
    font-weight: 600;
  }
`;

export const List = styled.div``;

export const Alerts = styled.div`
  border-top: solid 1px var(--inky200);
  padding: var(--midVertGutter) var(--midHorzGutter);

  > * {
    margin-top: var(--tinyVertGutter);
  }
`;
