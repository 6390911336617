/* @flow */
import React from "react";

import CategoryIcon from "icons/CategoryIcon";

import styles from "./index.css";

import type { Category as CategoryType } from "graphql-types/category";

type Props = {
  category?: CategoryType
};

const Category = ({ category }: Props) => {
  return (
    <div className={styles.category}>
      {category && (
        <>
          <CategoryIcon category={category} />
          <span className={styles.categoryName} title={category.name}>
            {category.name}
          </span>
        </>
      )}
    </div>
  );
};

export default Category;
