import { useState, useEffect, useRef, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";
import moment from "moment";

import GET_INSTAGRAM_COMMENTS from "queries/getInstagramComments";
import type { instagramPostComments } from "graphql-types/instagramPostComments";

import type { IProps } from "./types.js";

const useContent = (props: IProps) => {
  const containerRef = useRef();
  const apolloClient = useApolloClient();

  const [loadingEmbed, setLoadingEmbed] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const [comments, setComments] = useState({});

  useEffect(() => {
    fetchComments();
  }, [fetchComments, props.post]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const dissmissLoadingEmbed = () => {
    setTimeout(() => {
      setLoadingEmbed(false);
    }, 1000);
  };

  const fetchComments = useCallback(
    async (triggerLoading: boolean = true, cursor: null | string = null) => {
      if (!props.post) return;
      if (!cursor) setComments(undefined);

      if (triggerLoading) {
        if (cursor) setLoadingMore(true);
        else setLoading(true);
      }

      const res: Promise<instagramPostComments> = await apolloClient.query({
        query: GET_INSTAGRAM_COMMENTS,
        variables: {
          postId: parseInt(props.post.id),
          cursor
        },
        fetchPolicy: "no-cache"
      });

      if (cursor) {
        setComments({
          comments: [
            ...(comments?.comments || []),
            ...get(res, "data.instagramPostComments.comments", [])
          ],
          cursor: get(res, "data.instagramPostComments.cursor", null),
          error: get(res, "data.instagramPostComments.error", null)
        });
      } else {
        setComments({
          comments: get(res, "data.instagramPostComments.comments", []),
          cursor: get(res, "data.instagramPostComments.cursor", null),
          error: get(res, "data.instagramPostComments.error", null)
        });
      }

      setLoading(false);
      setLoadingMore(false);
      // } else {
      //   setComments([]);
      //   setLoading(false);
      //   setLoadingMore(false);
      // }
    },
    [apolloClient, comments, props.post]
  );

  const afterCommentCreate = (id: string, body: string) => {
    const newComment = {
      id: id,
      text: body,
      createdTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      attachment: null,
      comments: null,
      likeCount: 0,
      from: {
        id: props.post.account.uid,
        username: props.post.account.name
      }
    };

    setComments({
      comments: [{ ...newComment }, ...comments.comments],
      cursor: comments.cursor
    });

    scrollToBottom();
  };

  const loadOlderComments = () => {
    if (loadingMore) return;

    const { cursor } = comments;
    fetchComments(true, cursor);
  };

  return {
    loading,
    loadingMore,
    comments,
    fetchComments,
    loadOlderComments,
    afterCommentCreate,
    containerRef,
    loadingEmbed,
    dissmissLoadingEmbed
  };
};

export default useContent;
