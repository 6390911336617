// @flow
import React from "react";
import RejectionModal from "../common/RejectionModal";

type Props = {
  reasons: string[],
  onAcknowledge: () => void
};

export const INVALID_ACCOUNT_REASONS = {
  REMOVED: {
    MENTION:
      "Please remove any existing mention tags before deselecting a network."
  }
};

const ACTION_TYPE = {
  ADDED: "added",
  REMOVED: "removed"
};

// Subtitles
const ADDED_ACCOUNT_INCOMPATIBLE =
  "The following requirements make this account incompatible:";
const REMOVED_MENTION_ACCOUNT =
  "The following requirements prevented us from removing the account:";

const InvalidAccount = ({ reasons, onAcknowledge }: Props) => {
  let actionType = ACTION_TYPE.ADDED;
  let subTitle = ADDED_ACCOUNT_INCOMPATIBLE;

  if (reasons.includes(INVALID_ACCOUNT_REASONS.REMOVED.MENTION)) {
    actionType = ACTION_TYPE.REMOVED;
    subTitle = REMOVED_MENTION_ACCOUNT;
  }

  return (
    <RejectionModal
      reasons={reasons}
      title={`This account cannot be ${actionType}`}
      subTitle={subTitle}
      onAcknowledge={onAcknowledge}
    />
  );
};

export default InvalidAccount;
