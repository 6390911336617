import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 20px 0;

  > div {
    overflow: hidden;
    position: relative;
  }
`;

export const Loading = styled.div``;
