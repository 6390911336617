/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getInstagramConversationThread(
    $accountId: Int!
    $threadId: String!
    $cursor: String!
  ) {
    fetchInstagramConversationThreads(
      accountId: $accountId
      threadId: $threadId
      cursor: $cursor
    ) {
      cursor
      error
      threads {
        attachments {
          previewUrl
        }
        createdTime
        from {
          id
          name
        }
        id
        message
        to {
          id
          name
        }
      }
    }
  }
`;
