// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  children: Node,
  className?: string,
  hasBottomMargin?: boolean
};

const H4 = ({
  children,
  hasBottomMargin = false,
  className,
  ...others
}: Props) => (
  <h4
    className={classNames(styles.root, {
      [styles.hasBottomMargin]: !!hasBottomMargin,
      [className || ""]: !!className
    })}
    {...others}
  >
    {children}
  </h4>
);

export default H4;
