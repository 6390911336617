// @flow
import React from "react";
import ScaledImage from "components/common/ScaledImage";
import styles from "./index.css";

type Props = {
  urls: string[]
};

const SquareThree = ({ urls }: Props) => (
  <div className={styles.container}>
    <div className={styles.image}>
      <ScaledImage value={urls[0]} targetWidth={155} targetHeight={157} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[1]} targetWidth={155} targetHeight={157} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[2]} targetWidth={155} targetHeight={157} />
      {urls.length > 3 && (
        <div className={styles.overlay}>+{urls.length - 3}</div>
      )}
    </div>
  </div>
);

export default SquareThree;
