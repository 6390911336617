/* @flow */
import React from "react";
import classNames from "classnames";
import tooltipper from "behavioral/tooltipper";
import Caption from "typography/Caption";
import styles from "./index.css";

type Props = {
  activePage: string,
  url: string
};

const LINK = "/dashboard";

const TipBody = () => {
  return (
    <Caption className={styles.caption} textAlign="center">
      Visit Edgar's Dashboard for tips, tricks, and social strategy!
    </Caption>
  );
};

const Link = ({ activePage, url }: Props) => {
  return (
    <a
      className={classNames(styles.link, {
        [styles.activeLink]: activePage === LINK
      })}
      href={LINK}
    >
      <img src={url} alt="Edgar" />
    </a>
  );
};

const TooltippedLink = tooltipper(TipBody, {
  attachment: "top center",
  targetClassName: styles.target,
  tetherClassName: styles.tooltip,
  isAutoArrow: true
})(Link);

const Logo = (props: Props) => {
  return (
    <div className={styles.logo}>
      <TooltippedLink {...props} />
    </div>
  );
};

export default Logo;
