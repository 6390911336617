/* @flow */
import React from "react";
import { Element, scroller } from "react-scroll";

import { Label } from "typography";
import DropDown from "layout/DropDown";

import SVGIcon from "./icon.svg";
import TimeInput from "./TimeInput";

import type { IProps } from "./types.js";
import useSendAt from "./use";
import {
  Wrapper,
  LabelIcon,
  DropDownWrapper,
  DropDownContent,
  Item
} from "./styles";

import { Value } from "../SendOn/styles";

const ScheduleSendAt = (props: IProps) => {
  const { readOnly } = props;
  const {
    dropdownVisible,
    showDropdown,
    hideDropdown,
    handleChange,
    minutes
  } = useSendAt(props, scroller);

  return (
    <Wrapper>
      <LabelIcon>
        <img src={SVGIcon} alt="send at icon" />
      </LabelIcon>
      <Label htmlFor="sendAt">Send at</Label>
      {readOnly ? (
        <Value className={readOnly ? "readOnly" : ""}>{props.value}</Value>
      ) : (
        <TimeInput
          id="sendAt"
          value={props.value}
          onFocus={showDropdown}
          onChange={handleChange}
          onKeyDown={hideDropdown}
        />
      )}

      <DropDownWrapper>
        <DropDown
          open={dropdownVisible}
          onClose={hideDropdown}
          omitDefaultStyle
        >
          <Element name="mySendAtScrollToElement">
            <DropDownContent id="sendAtDropdown">
              {Array(24)
                .fill()
                .map((item, index) => {
                  return minutes.map(min => {
                    const hour = index > 11 ? index - 12 : index;
                    const time = `${hour === 0 ? 12 : hour}:${min} ${
                      index > 11 ? "pm" : "am"
                    }`;

                    return (
                      <Item
                        data-value={time}
                        key={time}
                        onClick={() => handleChange(time)}
                      >
                        {time}
                      </Item>
                    );
                  });
                })}
            </DropDownContent>
          </Element>
        </DropDown>
      </DropDownWrapper>
    </Wrapper>
  );
};

export default ScheduleSendAt;
