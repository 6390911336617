/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation deleteSchedule($id: ID!) {
    deleteSchedule(id: $id) {
      schedule {
        id
      }
      company {
        id
        scheduleLimitReached
      }
      errors
    }
  }
`;
