/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation updateDefaultLinkShortenerDomain($domainId: ID!) {
    updateDefaultLinkShortenerDomain(domainId: $domainId) {
      errors
    }
  }
`;
