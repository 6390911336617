/* @flow */
import React from "react";
import Icon from "icons/Icon";
import { AutoLinkText } from "links";
import styles from "./index.css";

type Props = {
  link: string,
  className?: string,
  platform?: string
};

const LinkPreviewHint = (props: Props) => {
  const { link, platform = 'Facebook' } = props;
  const capitalizeName = platform === "LINKEDIN" ? "LinkedIn" : "Facebook"
  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <span className={styles.title}>
          <Icon type="picture-o" />
          &nbsp; {capitalizeName} link preview
        </span>
        <span className={styles.description}>
          {capitalizeName} will display a link preview for{" "}
          <AutoLinkText>{link}</AutoLinkText> when this update is posted. You
          can see this preview by clicking the <b>Edit</b> button.
        </span>
      </div>
    </div>
  );
};

export default LinkPreviewHint;
