/* @flow

  Breaks up text into Tokens based on how the portion of the text might
  be rendered. Looks for URLs to classify as links, new lines to classify as newLines,
  and groups the remaining characters as phrases.
*/
import { last as arrayLast } from "lodash";
import {
  extractUrlsWithIndices,
  extractMentionsWithIndices
} from "twitter-text";
import type { AccountProvider } from "types";

type Options = {
  omitTrailingLinks?: boolean,
  otherTextRequiredForLinkOmission?: boolean,
  mentions?: string[],
  provider?: ?AccountProvider
};
type Phrase = { type: "phrase", value: string };
type Link = { type: "link", value: string };
type Mention = { type: "mention", provider: AccountProvider, value: string };
type NewLine = { type: "newLine" };
type Token = Phrase | Link | Mention | NewLine;

// Fb and twitter collapse sequential new lines down to a max of 2 (havent checked linkedin yet)
const collapseNewLines = (tokens: Token[], maxNewLines = 2) => {
  const cleaned = [];
  let newLinesFound = 0;
  tokens.forEach(token => {
    switch (token.type) {
      case "phrase":
      case "link":
      case "mention":
        cleaned.push(token);
        newLinesFound = 0;
        break;
      case "newLine":
        if (newLinesFound < maxNewLines) {
          cleaned.push(token);
        }
        newLinesFound++;
        break;
      default:
        throw new Error("Unknown token type");
    }
  });
  return cleaned;
};

const removeTrailingLinks = (
  tokens,
  otherTextRequiredForLinkOmission = false
) => {
  const last = arrayLast(tokens);

  if (otherTextRequiredForLinkOmission && tokens.length === 1) {
    return tokens;
  }

  if (last && last.type === "link") {
    tokens.pop();
  }
  return tokens;
};

const omitTrailingNewLines = (tokens: Token[]) => {
  let last = arrayLast(tokens);
  while (last && last.type === "newLine") {
    tokens.pop();
    last = arrayLast(tokens);
  }
  return tokens;
};

const findFacebookMentionMatch = (trimmedText: string, i: number) => (
  mention: string
) => trimmedText.indexOf(mention) === i;

const tokenizeText = (text: string, options: Options = {}): Token[] => {
  const {
    omitTrailingLinks,
    otherTextRequiredForLinkOmission,
    provider,
    mentions = []
  } = options;

  let trimmedText = text.trim();

  const urls = extractUrlsWithIndices(trimmedText).map(({ url, indices }) => ({
    url,
    start: indices[0],
    end: indices[1]
  }));

  let tokens = [];
  let curr = null;
  let last = null;
  let urlMatch;
  let twitterMentionMatch = {};
  let facebookMentionMatch = "";

  for (let i = 0; i < trimmedText.length; i++) {
    last = curr;
    urlMatch = urls.find(url => url.start === i);

    if (provider === "TWITTER") {
      const twitterMentions = extractMentionsWithIndices(trimmedText).map(
        ({ screenName, indices }) => ({
          screenName,
          start: indices[0],
          end: indices[1]
        })
      );
      twitterMentionMatch =
        twitterMentions.find(mention => mention.start === i) || {};
    } else if (provider === "FACEBOOK_PAGE") {
      facebookMentionMatch = mentions.find(
        findFacebookMentionMatch(trimmedText, i)
      );
    }

    if (urlMatch) {
      curr = { type: "link", value: urlMatch.url };
      i = urlMatch.end - 1;
    } else if (provider && !!facebookMentionMatch) {
      const facebookMentionName = facebookMentionMatch.substr(1);
      trimmedText = trimmedText.replace(
        facebookMentionMatch,
        `_${facebookMentionName}`
      );
      curr = { type: "mention", provider, value: facebookMentionName };
      i += facebookMentionMatch.length - 1;
    } else if (
      provider &&
      twitterMentionMatch.screenName &&
      twitterMentionMatch.end
    ) {
      curr = {
        type: "mention",
        provider,
        value: twitterMentionMatch.screenName
      };
      i = twitterMentionMatch.end - 1;
    } else if (trimmedText[i] === "\n") {
      curr = { type: "newLine" };
    } else {
      curr =
        last && last.type === "phrase" ? last : { type: "phrase", value: "" };
      curr.value += trimmedText[i];
    }

    if (curr.type !== "phrase") {
      if (last && last.type === "phrase") {
        tokens.push(last);
      }
      tokens.push(curr);
    }

    // handle input end if ended mid phrase
    if (i === trimmedText.length - 1 && curr.type === "phrase") {
      tokens.push(curr);
    }
  }

  tokens = collapseNewLines(tokens);
  tokens = omitTrailingNewLines(tokens);

  if (omitTrailingLinks) {
    removeTrailingLinks(tokens, otherTextRequiredForLinkOmission);
    tokens = omitTrailingNewLines(tokens);
  }
  return tokens;
};

export default tokenizeText;
