/* @flow */
import getInitialData from "./getInitialData";
import type { Action } from "types";

type PlatformData = { id: string };
export type State = {
  data: PlatformData[]
};

export const initialState: State = {
  data: getInitialData("platforms")
};

const platformsReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default platformsReducer;
