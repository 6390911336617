/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation cancelSubscription {
    cancelSubscription {
      errors
    }
  }
`;
