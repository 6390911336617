/* @flow */
import React from "react";
import ReactPaginate from "react-paginate";

import styles from "./index.css";

type Props = {
  page: number,
  pageCount: number,
  onPageChange: number => void
};

const Paginator = ({ page, pageCount, onPageChange }: Props) =>
  pageCount > 1 ? (
    <ReactPaginate
      activeClassName={styles.currentPage}
      activeLinkClassName={styles.currentPage}
      pageCount={pageCount}
      forcePage={page - 1}
      pageRangeDisplayed={5}
      marginPagesDisplayed={3}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      containerClassName={styles.root}
      pageClassName={styles.page}
      pageLinkClassName={styles.link}
    />
  ) : null;

export default Paginator;
