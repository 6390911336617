import React, { type Node } from "react";

import styles from "./index.css";

type Props = {
  items?: Node[]
};

const Grid = ({ items = [] }: Props) => {
  if (items.length < 1) {
    return null;
  }
  return (
    <div className={styles.grid}>
      {items.map((item, index) => {
        return (
          <div className={styles.item} key={index}>
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default Grid;
