// @flow
import { createSelector, type OutputSelector } from "reselect";
import selectRawState, { type UsersState } from "../raw";
import type { AppState } from "types";

// Unused function
const selectTimeZone: OutputSelector<AppState, void, string> = createSelector(
  selectRawState,
  (usersState: UsersState): string =>
    usersState.data.length
      ? usersState.data[0].attributes.company?.timeZone
      : ""
);

export default selectTimeZone;
