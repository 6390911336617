/* @flow */
import React from "react";

import RadioButton from "forms/RadioButton2";

import type { Props } from "./types.js";
import { Item, Label } from "./styles";

import { SectionTitle } from "../styles";

const ShopifyFormImportOption = (props: Props) => {
  const { importContentAsPending, handleImportContentAsPending } = props;

  return (
    <>
      <SectionTitle>Import options</SectionTitle>

      <Item onClick={() => handleImportContentAsPending(true)}>
        <RadioButton selected={importContentAsPending} />
        <Label>Import to Pending Content</Label>
      </Item>

      <Item onClick={() => handleImportContentAsPending(false)}>
        <RadioButton selected={!importContentAsPending} />
        <Label>Import directly to you library</Label>
      </Item>
    </>
  );
};

export default ShopifyFormImportOption;
