/* @flow */
import React from "react";

import type { Props } from "./types.js";
import { Wrapper, Title } from "./styles";

import Account from "./Account";

const VariationAccounts = (props: Props) => {
  const {
    accounts,
    removeVariationAccount,
    onChangeAccountText,
    variation
  } = props;

  return !accounts?.length ? null : (
    <Wrapper>
      <Title>
        Customized content <span>for selected accounts</span>:
      </Title>
      {accounts.map((item, index) => (
        <Account
          item={item}
          key={index}
          variation={variation}
          removeVariationAccount={removeVariationAccount}
          onChangeAccountText={onChangeAccountText}
        />
      ))}
      <strong>
        The base content will only be used for those accounts which do not have
        customized content.
      </strong>
    </Wrapper>
  );
};

export default VariationAccounts;
