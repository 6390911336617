/* @flow */
/* global EventHandler */
import React from "react";
import History from "../buttons/History";
import Delete from "../buttons/Delete";
import Edit from "../buttons/Edit";
import styles from "./index.css";

type Props = { contentId: string };

const Approved = ({ contentId }: Props) => (
  <div className={styles.root}>
    <History contentId={contentId} />
    <Delete contentId={contentId} type="secondary" />
    <Edit contentId={contentId} />
  </div>
);

export default Approved;
