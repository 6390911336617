/* @flow */
import { useEffect, useCallback, useState } from "react";

import type { IProps } from "./types.js";

const useSidebarNav = (
  props: IProps,
  ReactTooltip: any,
  windowWidth: number
) => {
  const [showInboxModal, setShowInboxModal] = useState(false);

  const handleInboxModal = () => setShowInboxModal(true);
  const hideInboxModal = () => setShowInboxModal(false);

  const handleRailsScreen = useCallback(
    (newValue: boolean) => {
      const pagesWithoutComponents = [
        "/subaccounts",
        "/contents/import",
        "/admin/",
        "/statistics",
        "/accounts",
        "/import"
      ];

      if (
        pagesWithoutComponents.some(page => props.activePage.includes(page))
      ) {
        const wrapDiv = document.querySelector(".wrap");
        const lineFooterDiv = document.querySelector(".linefooter");
        const adminDashboardDiv = document.querySelector(".admin-dashboard");
        const performanceStatsDiv = document.querySelector(".statistics-show");
        const maximumAccountsReachedDiv = document.querySelector(
          ".maximum_accounts_reached"
        );
        const queuePausedNoticeDiv = document.querySelector(
          ".queue_paused_notice"
        );

        if (wrapDiv) {
          if (
            props.activePage.indexOf("/accounts") == -1 &&
            props.activePage.indexOf("/import") == -1 
          ) {
            wrapDiv.classList.add("new-ui");
            if (newValue) wrapDiv.classList.add("collapsed");
            else wrapDiv.classList.remove("collapsed");
          }
        }

        if (lineFooterDiv) {
          lineFooterDiv.classList.add("new-ui");
          if (newValue) lineFooterDiv.classList.add("collapsed");
          else lineFooterDiv.classList.remove("collapsed");
        }

        if (adminDashboardDiv) {
          adminDashboardDiv.classList.add("new-ui");
          if (newValue) adminDashboardDiv.classList.add("collapsed");
          else adminDashboardDiv.classList.remove("collapsed");
        }

        if (performanceStatsDiv) {
          performanceStatsDiv.classList.add("new-ui");
          if (newValue) performanceStatsDiv.classList.add("collapsed");
          else performanceStatsDiv.classList.remove("collapsed");
        }

        if (maximumAccountsReachedDiv) {
          maximumAccountsReachedDiv.classList.add("new-ui");
          if (newValue) maximumAccountsReachedDiv.classList.add("collapsed");
          else maximumAccountsReachedDiv.classList.remove("collapsed");
        }

        if (queuePausedNoticeDiv) {
          queuePausedNoticeDiv.classList.add("new-ui");
          if (newValue) queuePausedNoticeDiv.classList.add("collapsed");
          else queuePausedNoticeDiv.classList.remove("collapsed");
        }
      }
    },
    [props.activePage]
  );

  const toggleCollapsed = () => {
    handleRailsScreen(!props.navbarCollapsed);
    props.toggleCollapsed(!props.navbarCollapsed);
  };

  useEffect(() => {
    handleRailsScreen(props.navbarCollapsed);
    ReactTooltip.rebuild();
  }, [ReactTooltip, handleRailsScreen, props.navbarCollapsed]);

  useEffect(() => {
    if (windowWidth < 1300) {
      ReactTooltip.rebuild();
      handleRailsScreen(true);
    } else {
      handleRailsScreen(props.navbarCollapsed);
    }
  }, [ReactTooltip, handleRailsScreen, props.navbarCollapsed, windowWidth]);

  return { toggleCollapsed, showInboxModal, handleInboxModal, hideInboxModal };
};

export default useSidebarNav;
