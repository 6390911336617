/* @flow */
import React from "react";
import Cropper from "react-cropper";
// $FlowFixMe
import "cropperjs/dist/cropper.css";

import Icon from "icons/Icon";
import Button from "buttons/Button";
import Modal from "layout/modals/Modal";
import PulseGIF from "components/common/images/loading-pulse.svg";

import useEditImageModal from "./use";
import type { Props } from "./types.js";
import styles from "./index.css";
import {
  Wrapper,
  Header,
  IconWrapper,
  Title,
  Content,
  Sidebar,
  Buttons,
  ImageWrapper,
  Loading,
  LiTitle,
  MobileViewport
} from "./styles";

const EditImageModal = (props: Props) => {
  const { hideModal, src, alt, updatingImage } = props;
  const {
    cropperRef,
    handleCrop,
    editing,
    handleSelectOption
  } = useEditImageModal(props);

  const EditItem = editItemProps => {
    const { label, icon } = editItemProps;
    const active = editing === label;

    return (
      <li
        className={active ? "active" : ""}
        onClick={() => handleSelectOption(label)}
      >
        <div className="left">
          <IconWrapper className={active ? "active" : ""}>
            <Icon type={icon} />
          </IconWrapper>
        </div>
        <LiTitle>
          <div>{label}</div>
          {active && (
            <span>
              Hold shift to maintain aspect ratio when resizing. Scroll to zoom
              in/out.
            </span>
          )}
        </LiTitle>
      </li>
    );
  };

  return (
    <Modal
      onClose={hideModal}
      className={styles.modal}
      closeOnOverlayClick
      forceCenter
    >
      <Wrapper>
        <Header>
          <IconWrapper onClick={hideModal}>
            <Icon type="arrow-left" />
          </IconWrapper>
          <Title>Photo edit</Title>
          <div />
        </Header>
        <Content>
          <Sidebar>
            <ul className={updatingImage ? "disabled" : ""}>
              <EditItem label="Crop" icon="crop-alt" />
            </ul>
            {updatingImage ? (
              <Loading>
                <img src={PulseGIF} alt="loading" />
              </Loading>
            ) : (
              <Buttons>
                <Button
                  className={styles.buttonSave}
                  onClick={handleCrop}
                  type="primary"
                >
                  Save
                </Button>
                <Button
                  className={styles.buttonCancel}
                  onClick={hideModal}
                  type="secondary-negative"
                >
                  Cancel
                </Button>
              </Buttons>
            )}
          </Sidebar>
          <ImageWrapper>
            <img src={src} alt={alt} className="background" />
            {/* <img src={src} alt={alt} className="image" /> */}
            <Cropper
              src={src}
              className="crop"
              ref={cropperRef}
              autoCrop={editing === "Crop"}
              disable={!editing === "Crop"}
              guides={true}
              responsive={true}
              viewMode={2}
              modal={false}
              background={false}
            />
          </ImageWrapper>
        </Content>
        <MobileViewport>
          This feature is not available on mobile devices.
        </MobileViewport>
      </Wrapper>
    </Modal>
  );
};

export default EditImageModal;
