// @flow
import React from "react";
import Alert from "layout/Alert";
import DateTimeField from "forms/DateTimeField";

type Props = {
  value: ?string,
  timeZone: string,
  showWarning: boolean,
  isEditing: boolean,
  onChange: (sendAt: ?string) => void
};

const SendAtField = ({
  value,
  timeZone,
  showWarning,
  isEditing,
  onChange
}: Props) => (
  <DateTimeField
    value={value}
    timeZone={timeZone}
    notice={
      showWarning && (
        <Alert type="alert" small>
          This content has been sent. Make sure there are publishable variations
          on this content before scheduling a new time.{" "}
          <a
            href="https://help.meetedgar.com/edgar-s-features-and-best-practices/the-add-new-content-button/schedule-settings-when-adding-a-new-post"
            target="_blank"
          >
            Learn More
          </a>
        </Alert>
      )
    }
    popperPlacement="top-start"
    enableResetLink={isEditing}
    onChange={onChange}
  />
);

export default SendAtField;
