/* @flow */
import React from "react";
import classNames from "classnames";

import Caption from "typography/Caption";

import SVGToggleOn from "./images/toggleOn.svg";
import SVGToggleOff from "./images/toggleOff.svg";

import type { Props } from "./types.js";
import styles from "./index.css";

export default class Toggle extends React.Component<Props> {
  handleClick = () => {
    const { value, onChange } = this.props;
    onChange(!value);
  };

  render() {
    const {
      value,
      label,
      className,
      iconClassName,
      labelClassName,
      ...others
    } = this.props;

    const iconType = value ? SVGToggleOn : SVGToggleOff;
    const iconAlt = value ? "toggle-on" : "toggle-off";

    const iconClasses = classNames(styles.icon, {
      [iconClassName || ""]: !!iconClassName
    });

    const labelClasses = classNames(styles.label, {
      [labelClassName || ""]: !!labelClassName
    });

    return (
      <div
        className={classNames(styles.root, { [className || ""]: !!className })}
      >
        <img
          src={iconType}
          alt={iconAlt}
          onClick={this.handleClick}
          className={iconClasses}
          {...others}
        />

        {label && (
          <Caption className={labelClasses} onClick={this.handleClick}>
            {label}
          </Caption>
        )}
      </div>
    );
  }
}
