/* @flow */
import React from "react";
import classNames from "classnames";
import Link from "links/Link";
import Icon from "icons/Icon";
import styles from "./index.css";

type Props = {
  className?: string,
  onClick: (e: Event) => void
};

const SpyGlass = (props: Props) => {
  const { className, onClick } = props;
  const classes = classNames(styles.root, className);
  return (
    <Link className={classes} onClick={onClick}>
      <Icon type="search" data-test="searchIcon" />
    </Link>
  );
};

export default SpyGlass;
