const ExtraPriceForPLan = selectedPlan => {
  const planName = selectedPlan?.stripeId;

  const newEddieMonth =
    planName === "eddie_lite_monthly_plan_nt" ||
    planName === "eddie_lite_monthly_plan";

  const newEddieAnnual =
    planName === "eddie_lite_annual_plan" ||
    planName === "eddie_lite_annual_plan_nt";

  const edgarMonth =
    planName === "edgar_monthly_plan" || planName === "edgar_monthly_plan_nt";

  const edgarAnnual =
    planName === "edgar_annual_plan" || planName === "edgar_annual_plan_nt";

  let additionalPrice = 0;

  if (newEddieMonth) {
    additionalPrice = 4.99;
  } else if (newEddieAnnual) {
    additionalPrice = 47.88;
  } else if (edgarMonth) {
    additionalPrice = 2.99;
  } else if (edgarAnnual) {
    additionalPrice = 23.88;
  }

  return { additionalPrice };
};

export default ExtraPriceForPLan;
