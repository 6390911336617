import styled from "styled-components";

import { breakpointWidth } from "../../../styles";

export const Wrapper = styled.div`
  border-left: 1px solid #f0f0f0;
  flex: 1;
  margin: 20px 0 0 0;
  padding: 0 15px 0 0;
  overflow-y: auto;

  @media (min-width: ${breakpointWidth}px) {
    margin: 0 0 0 5px;
    padding: 0 15px 0 20px;
  }
`;

export const Loading = styled.div`
  align-items: center;
  display: flex;

  img {
    height: auto;
    width: 30px;
  }
`;

export const Post = styled.div`
  border-radius: 4px;
  border: 1px solid #eaeaea;
  cursor: pointer;
  margin: 10px 0 0 0;
  transition: all 0.1s ease;

  &:first-child {
    margin-top: 0;
  }

  &:not(.readOnly):hover {
    background-color: #fdfdfd;
    border-color: #ddd;
  }

  &.selected {
    margin-bottom: 20px;
  }

  &.error {
    border-color: var(--dangerColor);
  }

  &.readOnly {
    cursor: not-allowed;
    margin-bottom: 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  padding: 12px;
`;

export const Picture = styled.div`
  margin: 0 10px 0 0;

  img {
    border-radius: 4px;
    object-fit: contain;
    height: 56px;
    width: 56px;
  }
`;

export const Description = styled.p`
  margin: 0;
`;

export const Category = styled.div`
  align-items: center;
  border-top: 1px solid #eaeaea;
  display: flex;
  padding: 8px 12px;
`;

export const Circle = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 50%;
  height: 16px;
  margin: 0 4px 0 0;
  width: 16px;
`;

export const Info = styled.div`
  color: #888;
  font-style: italic;
`;

export const PaginatorStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0.5rem;
`;
