/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 12px 0;
`;

export const Field = styled.div`
  &:nth-child(2) {
    border-top: 1px solid #c5c5c5;
    border-bottom: 1px solid #c5c5c5;
    margin: 25px 0;
    padding: 25px 0;
  }
`;
