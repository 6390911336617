/* @flow */
import React, { type Node } from "react";

import styles from "./index.css";

type Props = {
  children: Node,
  className?: string
};

const PageSubtitle = ({ children, className = "" }: Props) => {
  return <p className={`${styles.subtitle} ${className}`}>{children}</p>;
};

export default PageSubtitle;
