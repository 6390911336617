/* @flow */
import React from "react";
import tooltipper from "behavioral/tooltipper";
import Caption from "typography/Caption";

import type { IProps } from "./types.js";
import { AccountsMore } from "./styles";

const TipBody = (props: IProps) => {
  const { accounts } = props;

  const numberToShow = 10;

  return (
    <Caption>
      {accounts.map((item, index) =>
        index > 2 && index < numberToShow ? (
          <div key={index}>{item.description}</div>
        ) : null
      )}
      {accounts.length > numberToShow && "..."}
    </Caption>
  );
};

const Tooltip = (props: IProps) => {
  const { accounts } = props;

  return <AccountsMore>+{accounts.length - 3}</AccountsMore>;
};

export default tooltipper(TipBody)(Tooltip);
