/* @flow */
import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Button from "buttons/Button";
import Modal from "layout/modals/Modal";
import { Caption } from "typography";

import GET_DASHBOARD from "queries/getDashboard";
import DISMISS_INFORMATION_BOX from "mutations/dismissInformationBox";

import styles from "./index.css";

const INFORMATION_BOX_NAME = "trialExtensionOffer";
const CANCELLATION_PATH_NAME = "cancellation/new";
import { CloseButton } from "components/common/buttons";

type Props = {
  daysRemaining: number,
  currentPlanAmount: number,
  currentPlanInterval: string,
  status: "trialing" | "pending_cancel" | "canceled"
};

const SubscriptionStatus = ({
  status = "",
  daysRemaining,
  currentPlanAmount,
  currentPlanInterval
}: Props) => {
  // Queries
  const { _loading, _error, data } = useQuery(GET_DASHBOARD);

  // Mutations
  const [dismissInformationBox] = useMutation(DISMISS_INFORMATION_BOX, {
    refetchQueries: _ => [
      {
        query: GET_DASHBOARD
      }
    ],
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true
  });

  const isTrialing = status.includes("trialing");
  const [showTrialExtensionModal, setShowTrialExtensionModal] = useState(null);
  const [openPendingMessage, setOpenPendingMessage] = useState(true);

  if (data && showTrialExtensionModal === null) {
    setShowTrialExtensionModal(
      window.location.pathname.includes(CANCELLATION_PATH_NAME) &&
        isTrialing &&
        !data?.user?.dismissedInformationBoxes?.[INFORMATION_BOX_NAME]
    );
  }

  if (isTrialing && daysRemaining > 0) {
    function handleClose() {
      setShowTrialExtensionModal(false);
    }

    function handleExtendTrial() {
      dismissInformationBox({
        variables: { informationBoxName: INFORMATION_BOX_NAME }
      });

      setShowTrialExtensionModal(false);
      window.location.href = "mailto:support@meetedgar.com";
    }

    function renderFooter() {
      return (
        <div className={styles.actions}>
          <span className={styles.secondaryActions}>
            <Button onClick={handleClose} type="secondary">
              No Thanks
            </Button>
          </span>
          <Button submit onClick={handleExtendTrial} type="primary">
            Contact Us
          </Button>
        </div>
      );
    }

    return (
      <>
        {status === "trialing" && (
          <div className={styles.rootAlert}>
            <div className={styles.body}>
              <span className={styles.messageAlert}>
                Your Free Trial ends in {daysRemaining} day
                {daysRemaining !== 1 ? "s" : ""}. Add your payment details now
                and keep riding the wave with Edgar!
              </span>
              <Button className={styles.ctaAlert} url="/payment?payment=true">
                Add payment
              </Button>
            </div>
          </div>
        )}
        {showTrialExtensionModal && (
          <Modal
            title="Need more time to decide?"
            closeOnOverlayClick={false}
            size="medium"
            autoFocus
            footer={renderFooter()}
          >
            <Caption className={styles.trialExtensionModalCaption}>
              Haven't had enough time to become BFFs with Edgar during your
              trial? No worries! Contact us and we'll be happy to extend your
              trial time.
            </Caption>
          </Modal>
        )}
      </>
    );
  } else if (status === "pending_cancel") {
    return (
      openPendingMessage && 
      <div className={styles.root}>
        <div className={styles.body}>
          <span className={styles.message}>
            Your subscription ends in {daysRemaining} days. Go ahead and put
            Edgar to work, he doesn't mind! And if we humans can help get you
            started, just give us a shout.
          </span>
          <Button
            className={styles.ctaButton}
            url="mailto:support@meetedgar.com"
            target="_blank"
          >
            Contact us
          </Button>
        </div>
        <CloseButton onClick={() => setOpenPendingMessage(false)} />
      </div>
    );
  } else if (status === "canceled" && data?.user?.cardData) {
    const confirmMessage = `
      Edgar's tentacles are all a-wiggle because you're back!
      Clicking OK will reactivate your subscription at $${currentPlanAmount}/${currentPlanInterval}
    `;
    return (
      <div className={styles.root}>
        <div className={styles.body}>
          <span className={styles.message}>
            Your subscription is no longer active.
          </span>
          <Button
            className={styles.ctaButton}
            href="/resubscribe"
            method="POST"
            confirm
            confirmType="warning"
            confirmMessage={confirmMessage}
          >
            Click here to reactivate!
          </Button>
        </div>
      </div>
    );
  }
  return null;
};

export default SubscriptionStatus;
