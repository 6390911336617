// @flow
import React from "react";
import Label from "typography/Label";
import BaseCategorySelect from "components/common/CategorySelect";
import Tooltip from "./Tooltip";
import OnboardingTooltip from "components/common/OnboardingTooltip";
import styles from "./index.css";

import type { loadComposer_categories as CategoryData } from "graphql-types/loadComposer";

type Props = {
  showOnboardingTooltip: boolean,
  categories: CategoryData[],
  value: CategoryData,
  clientErrors: string[],
  serverErrors: string[],
  showOnboardingTooltip: boolean,
  onChange: (selection: CategoryData) => void,
  onNextOnboardingStep: () => void
};

const TOOLTIP_BODY =
  "Your schedule is category-based. Select the category this post belongs in.";

export const CategorySelect = function(props: Props) {
  const {
    value,
    clientErrors,
    serverErrors,
    showOnboardingTooltip,
    onChange,
    onNextOnboardingStep
  } = props;

  const hasError = (clientErrors || []).concat(serverErrors || []).length > 0;

  return (
    <div className={styles.root}>
      {showOnboardingTooltip && (
        <OnboardingTooltip tooltipBody={TOOLTIP_BODY} />
      )}
      <div className={styles.labelContainer}>
        <Label htmlFor="category">
          Category <Tooltip />
          {hasError && (
            <span className={styles.errorText}> {hasError && "*"}</span>
          )}
        </Label>
      </div>
      <BaseCategorySelect
        id="category"
        alwaysTriggerOnClickOption
        value={value?.id}
        className={styles.select}
        hasError={hasError}
        onChange={value => {
          if (!value.settingDefaultValue) {
            onNextOnboardingStep();
          }

          onChange(value);
        }}
      />
      {hasError && (
        <span className={styles.errorText}>Category is required</span>
      )}
    </div>
  );
};

export default CategorySelect;
