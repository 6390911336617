import styled from "styled-components";

export const Wrapper = styled.div``;

export const Circle = styled.div`
  background-color: ${props => props.color};
  border-radius: 50%;
  height: var(--largeIconSize);
  margin-right: 10px;
  width: var(--largeIconSize);
`;

export const Items = styled.div`
  max-height: 500px;
  overflow-y: auto;
  padding-right: 2.5px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const Item = styled.label`
  align-items: center;
  border-bottom: 1px solid var(--inky150);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 24px;

  > div {
    align-items: center;
    display: flex;
  }

  input {
    accent-color: #333;
  }

  &:hover,
  &.active {
    background: var(--inky50);
  }
`;
