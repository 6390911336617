/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation scrapeUrl($url: String!) {
    scrapeUrl(url: $url) {
      pageScrape {
        id
        url
        status
        title
        description
        destinationUrl
        twitterCardType
        twitterTitle
        twitterDescription
        twitterImage
        images {
          id
          alt
          src
          size
          type
          width
          height
          sha256
        }
      }
      errors
    }
  }
`;
