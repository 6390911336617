/* eslint-disable flowtype/require-valid-file-annotation */
import React, { type Node } from "react";
import styles from "./index.css";

export default function Label({
  type,
  children
}: {
  type: string,
  children?: Node
}) {
  const styleName = type ? styles[type] : styles.root;
  return <span className={styleName}>{children}</span>;
}
