/* @flow */
import React from "react";

import SVGLoading from "components/common/images/loading-pulse.svg";

import type { IProps } from "./types.js";
import useCategories from "./use";
import {
  Wrapper,
  Title,
  Loading,
  Empty,
  List,
  Category,
  Circle
} from "./styles";

const PostsModalCategories = (props: IProps) => {
  const { selectedCategory, handleCategory } = props;
  const { loading, categories } = useCategories();

  return (
    <Wrapper>
      <Title>Categories</Title>
      {loading ? (
        <Loading>
          <img src={SVGLoading} alt="loading pulse" />
        </Loading>
      ) : !categories.length ? (
        <Empty>There are no categories.</Empty>
      ) : (
        <List>
          {categories.map((item, index) => (
            <Category
              key={index}
              className={selectedCategory === item.value ? "active" : ""}
              onClick={() => handleCategory(item.value)}
            >
              <Circle color={item.color} />
              <span>{item.label}</span>
            </Category>
          ))}
        </List>
      )}
    </Wrapper>
  );
};

export default PostsModalCategories;
