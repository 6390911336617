// @flow
import React from "react";
import ScaledImage from "components/common/ScaledImage";

import { MAX_IMAGES } from "../index.js";
import styles from "./index.css";

type Props = {
  urls: string[]
};

const PRIMARY_IMAGE_WIDTH = 275.5;
const PRIMARY_IMAGE_HEIGHT = 330;

const IMAGE_WIDTH = 183;
const IMAGE_HEIGHT = 221;

const Five = ({ urls }: Props) => (
  <div className={styles.container}>
    <div className={styles.primaryImage}>
      <ScaledImage
        value={urls[urls.length - 1]}
        targetWidth={PRIMARY_IMAGE_WIDTH}
        targetHeight={PRIMARY_IMAGE_HEIGHT}
      />
    </div>
    <div className={styles.primaryImage}>
      <ScaledImage
        value={urls[urls.length - 2]}
        targetWidth={PRIMARY_IMAGE_WIDTH}
        targetHeight={PRIMARY_IMAGE_HEIGHT}
      />
    </div>
    <div className={styles.image}>
      <ScaledImage
        value={urls[urls.length - 3]}
        targetWidth={IMAGE_WIDTH}
        targetHeight={IMAGE_HEIGHT}
      />
    </div>
    <div className={styles.image}>
      <ScaledImage
        value={urls[urls.length - 4]}
        targetWidth={IMAGE_WIDTH}
        targetHeight={IMAGE_HEIGHT}
      />
    </div>
    <div className={styles.image}>
      {urls.length > MAX_IMAGES && (
        <>
          <div className={styles.imageBackground} />
          <p className={styles.imageCounter}>+{urls.length - MAX_IMAGES}</p>
        </>
      )}
      <ScaledImage
        value={urls[urls.length - MAX_IMAGES]}
        targetWidth={IMAGE_WIDTH}
        targetHeight={IMAGE_HEIGHT}
      />
    </div>
  </div>
);

export default Five;
