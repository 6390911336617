/* @flow */
import React from "react";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";

import Layout from "./Layout";
import ActionBar from "../common/ActionBar";
import VariationText from "../common/VariationText";
import styles from "./index.css";

import type { getContents_contents_data as ContentData } from "graphql-types/getContents";

type Props = {
  content: ?ContentData,
  timezone: ?string
};

export const AbbreviatedContent = ({ content, timezone }: Props) => {
  const id = content?.id;
  const category = content?.category;
  const status = content?.status;
  const queueable = content?.queueable;
  const variations = content?.variations;
  const accounts = (content?.accountRelationships ?? []).map(
    ({ account }) => account
  );

  const classes = classNames(styles.root, {
    [styles.unqueueable]: !queueable
  });

  {
    /* we display only the first variation in this view... */
  }
  return (
    <div className={classes}>
      <div className={styles.body}>
        <Layout content={content} timezone={timezone}>
          {variations?.[0]?.id ? (
            <VariationText variation={variations?.[0]} accounts={accounts} />
          ) : (
            <>
              <Skeleton width={500} />
              <Skeleton width={300} />
              <Skeleton width={400} />
            </>
          )}
        </Layout>
      </div>
      <ActionBar contentId={id} status={status} category={category} />
    </div>
  );
};

export default AbbreviatedContent;
