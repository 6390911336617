// @flow
import React from "react";
import classNames from "classnames";
import Alert from "layout/Alert";
import { AutoLinkText } from "links";
import type { ValidationError } from "types";

type Props = { errors: ValidationError[], className?: string };

const ValidationErrorList = ({ errors, className = "" }: Props) => {
  const filteredErrors = errors.filter(err => !err.suppressDisplay);

  return (
    <div
      className={classNames({ [className]: !!className })}
      style={{
        display: filteredErrors.length ? "flex" : "none"
      }}
    >
      <Alert type="error">
        {filteredErrors.length === 1 && (
          <AutoLinkText>{filteredErrors[0].message}</AutoLinkText>
        )}
        {filteredErrors.length > 1 && (
          <ul>
            {filteredErrors.map(({ message }, i) => (
              <li key={i}>
                <AutoLinkText>{message}</AutoLinkText>
              </li>
            ))}
          </ul>
        )}
      </Alert>
    </div>
  );
};

export default ValidationErrorList;
