/* @flow */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";
import { get } from "lodash";

import Alert from "layout/Alert";
import { featureFlag, useNewUICached } from "util";

import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import GET_ONBOARDING_PROGRESS from "queries/getOnboardingProgress";

// import Search from "components/Search";
import App from "components/App";
import Message from "layout/Message";
import Link from "links/Link";
import PageTitle from "links/PageTitle";
import NewHeader from "components/NewHeader";

import useInbox from "./use";
import { Wrapper, BetaText, RefreshAccount } from "./styles";

import Account from "./Account";
import Sidebar from "./Sidebar";
import FacebookComments from "./FacebookComments";
import InstagramComments from "./InstagramComments";
import ConversationThread from "./ConversationThread";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";
import type { getOnboardingProgress_user_onboardingProgress as OnboardingProgressData } from "graphql-types/getOnboardingProgress";

type OwnProps = {
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type IProps = {
  data?: any, // CHANGE THIS
  dismissOnboarding?: () => Promise<DismissOnboardingResult>,
  onboardingProgress?: OnboardingProgressData,
  error?: string,
  loading?: boolean,
  paused?: boolean
} & OwnProps;

export const Inbox = (props: IProps) => {
  const {
    post,
    selectPost,
    clearPost,
    selectAccount,
    selectedAccount,
    accounts,
    loadingAccounts,
    lastPosts,
    loadingMoreOffset,
    fetchLastPosts,
    totalPosts,
    loadingLastPosts,
    contentType,
    handleMessages,
    handleComments,
    selectedThread,
    handleSelectedThread
  } = useInbox();
  const useNewUI = useNewUICached();

  const RenderComponent = () => {
    return (post && post.account.provider) === "INSTAGRAM_BUSINESS" ? (
      <InstagramComments post={post} />
    ) : contentType === "messages" ? (
      <ConversationThread
        selectedAccount={selectedAccount}
        selectedThread={selectedThread}
      />
    ) : (
      <FacebookComments post={post} />
    );
  };

  if (!featureFlag("fb_comments")) {
    window.location.href = "/queue";
  }

  return !!featureFlag("fb_comments") ? (
    <App
      loggedIn={true}
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      onboardingProgress={props.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      newWidth
      header={
        <>
          {useNewUI ? (
            <NewHeader title="Inbox" beta {...props.topNav} />
          ) : (
            <PageTitle>
              Inbox
              <BetaText>Beta</BetaText>
            </PageTitle>
          )}
          {/* <Search /> */}
        </>
      }
      messages={
        props.paused ? (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting in{" "}
            <Link href="/queue">your Queue</Link>.
          </Message>
        ) : null
      }
    >
      <>
        <RefreshAccount>
          <Alert type="info">
            Refresh your Facebook account to integrate with your Inbox. Please
            ignore if you have already refreshed.
          </Alert>
        </RefreshAccount>

        <Account
          selectAccount={selectAccount}
          selectedAccount={selectedAccount}
          accounts={accounts}
          loading={loadingAccounts}
        />

        <Wrapper>
          <Sidebar
            selectedAccount={selectedAccount}
            selectPost={selectPost}
            selectedPost={post}
            clearPost={clearPost}
            loadingAccounts={loadingAccounts}
            lastPosts={lastPosts}
            totalPosts={totalPosts}
            loadingMoreOffset={loadingMoreOffset}
            fetchLastPosts={fetchLastPosts}
            loadingLastPosts={loadingLastPosts}
            contentType={contentType}
            handleMessages={handleMessages}
            handleComments={handleComments}
            selectedThread={selectedThread}
            handleSelectedThread={handleSelectedThread}
          />
          <RenderComponent post={post} />
        </Wrapper>
      </>
    </App>
  ) : null;
};

const InboxWrapper = (ownProps: OwnProps) => {
  const { data } = useQuery(GET_ONBOARDING_PROGRESS);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);

  const onboardingProgress = get(data, "user.onboardingProgress");

  return (
    <Inbox
      {...ownProps}
      onboardingProgress={onboardingProgress}
      dismissOnboarding={dismissOnboarding}
    />
  );
};

export default InboxWrapper;
