/* @flow */
import React from "react";
import classNames from "classnames";
import { get, orderBy } from "lodash";

import { Icon } from "icons";
import AccountAvatarWithBadge from "icons/AccountAvatarWithBadge";
import { H5, Caption } from "typography";
import FacebookTooltip from "./FacebookTooltip";
import InstagramBusinessTooltip from "./InstagramBusinessTooltip";
import InstagramPersonalTooltip from "./InstagramPersonalTooltip";

import type { Account } from "types";

import styles from "./index.css";

export const HELP_URL =
  "https://help.meetedgar.com/introduction-to-edgar-and-faq/categories";

type Props = {
  id: string,
  uid: string,
  name: string,
  nickname: string,
  platform: string,
  provider: string,
  providerDisplayName: string,
  image: string,
  parent: Account,
  parentNickname: string,
  cssGridArea: string,
  canRefresh: boolean,
  canDelete: boolean,
  hasDevices: boolean,
  children: Account[],
  onClickDelete: (accountId: string) => void,
  generateOAuthUri: (provider: string, uid: ?string) => string
};

export function AccountCard({
  id,
  uid,
  name,
  nickname,
  platform,
  provider,
  providerDisplayName,
  image,
  parent,
  parentNickname,
  cssGridArea,
  canRefresh,
  canDelete,
  hasDevices,
  children = [],
  onClickDelete,
  generateOAuthUri
}: Props) {
  return (
    <div className={styles.container} style={{ gridArea: cssGridArea }}>
      {
        <AccountRow
          key={id}
          account={{
            id,
            uid,
            name,
            nickname,
            platform,
            provider,
            providerDisplayName,
            canRefresh,
            canDelete,
            image,
            parent,
            parentNickname
          }}
          hasDevices={hasDevices}
          isChild={false}
          onClickDelete={onClickDelete}
          generateOAuthUri={generateOAuthUri}
        />
      }

      {!!children.length && (
        <div className={styles.childrenContainer}>
          {orderBy(children).map(childAccount => (
            <AccountRow
              key={childAccount.id}
              account={childAccount}
              isChild
              hasDevices={hasDevices}
              onClickDelete={onClickDelete}
              generateOAuthUri={generateOAuthUri}
            />
          ))}
        </div>
      )}
    </div>
  );
}

const AccountRow = ({
  account,
  onClickDelete,
  generateOAuthUri,
  hasDevices,
  isChild = false
}) => {
  const isLinkedInStandalone =
    account.provider.toLowerCase() == "linkedin_company" && !account.parent;
  const showFacebookTooltip = account.provider.toLowerCase() == "facebook";
  const showInstagramPersonalTooltip =
    account.provider.toLowerCase() == "instagram_personal";
  const showInstagramBusinessTooltip =
    account.provider.toLowerCase() == "instagram_business" && !hasDevices;
  const oauthProvider = isLinkedInStandalone
    ? "linkedin standalone company"
    : account.providerDisplayName;

  function handleOnClickDelete(accountId) {
    onClickDelete(accountId);
  }

  function formatName(account) {
    return account.name;
  }

  function formatCaption(account) {
    if (!isLinkedInStandalone) {
      return account.nickname && account.nickname.length
        ? `@${account.nickname}`
        : "";
    } else if (account.parentNickname) {
      return `Added by @${account.parentNickname}`;
    }
  }

  return (
    <div
      className={classNames(
        styles.accountContainer,
        isChild ? styles.childAccount : {}
      )}
    >
      <AccountAvatarWithBadge value={account} size="medium" />
      <div className={styles.accountInfo}>
        <div className={styles.details}>
          <H5 className={styles.name}>{formatName(account)}</H5>
          <Caption helpText className={styles.caption}>
            {formatCaption(account)}{" "}
            {showFacebookTooltip && <FacebookTooltip />}
          </Caption>
          {showInstagramBusinessTooltip && (
            <div style={{ width: "130px" }}>
              <a
                href="http://help.meetedgar.com/en/articles/2481056-how-to-install-the-meetedgar-mobile-app-for-instagram-reminders"
                target="_blank"
                className={styles.link}
              >
                + mobile reminders
              </a>
              <InstagramBusinessTooltip />
            </div>
          )}
          {showInstagramPersonalTooltip && (
            <div style={{ width: "130px" }}>
              <a
                onClick={async function() {
                  window.location.href = await generateOAuthUri(
                    "Instagram Business",
                    get(account, "parent.uid", account.uid)
                  );
                }}
                target="_blank"
                className={styles.link}
              >
                Reconnect as a business account
              </a>
              <InstagramPersonalTooltip />
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.accountActionsContainer}
        style={{
          justifyContent: account.canRefresh ? "space-between" : "flex-end"
        }}
      >
        {account.canRefresh && (
          <Icon
            id="refreshAccountButton"
            type="refresh"
            onClick={async function() {
              window.location.href = await generateOAuthUri(
                oauthProvider,
                get(account, "parent.uid", account.uid)
              );
            }}
            className={styles.icon}
          />
        )}

        {account.canDelete && (
          <Icon
            id="deleteAccountButton"
            type="trash"
            onClick={handleOnClickDelete.bind(this, account.id)}
            className={styles.icon}
          />
        )}
      </div>
    </div>
  );
};

export default AccountCard;
