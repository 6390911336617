/* @flow */
import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./index.css";

type Props = {
  seconds: number
};

type State = {
  remainingSeconds: number,
  timerId: ?number
};

class Timer extends Component<Props, State> {
  state: State = { remainingSeconds: this.props.seconds, timerId: null };
  FADE_OUT_TIME = 1750;

  componentDidMount() {
    const timerId = window.setTimeout(this.tick, 1000);
    this.setState({ timerId });
  }

  componentWillUnmount() {
    window.clearTimeout(this.state.timerId);
  }

  tick = () => {
    const { timerId, remainingSeconds } = this.state;
    if (remainingSeconds >= 0) {
      const newRemainingSeconds = remainingSeconds - 1;
      window.clearTimeout(timerId);
      const newTimerId = window.setTimeout(this.tick, 1000);

      this.setState({
        remainingSeconds: newRemainingSeconds,
        timerId: newTimerId
      });
    }
  };

  render() {
    const { remainingSeconds } = this.state;
    const displaySeconds =
      remainingSeconds > 9 ? remainingSeconds : "0" + remainingSeconds;
    return (
      <CSSTransition
        appear={true}
        classNames={{
          appearActive: styles.appearActive,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone
        }}
        in={remainingSeconds > 0}
        timeout={this.FADE_OUT_TIME}
      >
        <div className={styles.root}>
          0:
          {displaySeconds}
        </div>
      </CSSTransition>
    );
  }
}

export default Timer;
