import React from "react";
import { Caption } from "typography";
import { Icon } from "icons";
import styles from "./index.css";

const MultiImageHelpText = () => {
  return (
    <Caption helpText className={styles.root}>
      <Icon type="info-circle" className={styles.infoIcon} />
      <div>
        <strong>Note about multiple images</strong>
        <br />
        Social networks determine the order, placement and cropping of multiple
        image content at the time it posts. Images may appear differently than
        displayed here.
      </div>
    </Caption>
  );
};

export default MultiImageHelpText;
