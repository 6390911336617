/* @flow */
import React from "react";
import { DefaultPlayer } from "react-html5video";
import Modal from "layout/modals/Modal";
// $FlowFixMe
import "react-html5video/dist/styles.css";
import "./index.css";

type Props = {
  videoUrl: ?string,
  onClose: () => void
};

export const VideoThumbnailModal = ({ videoUrl, onClose }: Props) => (
  <Modal title="Video preview" closeOnOverlayClick onClose={onClose}>
    <DefaultPlayer
      controls={[]}
      className="videomodal_player"
      controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
    >
      <source src={videoUrl} type="video/mp4" />
    </DefaultPlayer>
  </Modal>
);

export default VideoThumbnailModal;
