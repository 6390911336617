// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  children: Node,
  className?: string
};

const H2 = ({ children, className, ...others }: Props) => (
  <h2 className={classNames(styles.root, className)} {...others}>
    {children}
  </h2>
);

export default H2;
