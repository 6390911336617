/* @flow */
import { useState } from "react";

import type { IProps } from "./types.js";

const usePostSelect = (props: IProps) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const handlePost = (post: any) => {
    props.selectPost(post);
    hideModal();
  };

  return { modalVisible, showModal, hideModal, handlePost };
};

export default usePostSelect;
