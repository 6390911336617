/* @flow */
import React, { useCallback } from "react";

import TextInput from "forms/TextInput";
import Button from "buttons/Button";
import Icon from "icons/Icon";

import styles from "./index.css";

import type { validateCoupon_validateCoupon_coupon as CouponData } from "graphql-types/validateCoupon";
import type { loadPayment_company_availableSubscriptionPlans as SubscriptionPlanData } from "graphql-types/loadPayment";

type Props = {
  value: string,
  coupon: ?CouponData,
  selectedPlan: SubscriptionPlanData,
  error: ?string,
  loading: boolean,
  onApply: (string, string) => void,
  onRemove: () => void,
  onUpdateCouponCode: string => void
};

export const PromoCode = ({
  value,
  coupon,
  selectedPlan,
  error,
  loading,
  onApply,
  onRemove,
  onUpdateCouponCode
}: Props) => {
  const handleApplyCoupon = useCallback(() => {
    onApply(value, selectedPlan?.stripeId);
  }, [onApply, value, selectedPlan]);
  const hasError = !!error;

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <TextInput
          id="promoCode"
          placeholder="Promo code"
          hasError={hasError}
          className={styles.couponField}
          value={value}
          onChange={onUpdateCouponCode}
          onEnter={handleApplyCoupon}
        />
        <div className={styles.applyButton}>
          <Button
            type="secondary"
            loading={loading}
            onClick={handleApplyCoupon}
          >
            Apply
          </Button>
        </div>
      </div>
      {hasError && (
        <div className={styles.errorMessage} role="alert">
          {error}
        </div>
      )}
      {!hasError && coupon && value && (
        <div className={styles.successMessage} role="alert">
          <Icon type="check" /> Success.
          {coupon?.description}
          <a onClick={onRemove}>Remove</a>
        </div>
      )}
    </div>
  );
};

export default PromoCode;
