// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import { capitalize } from "util";
import styles from "./index.css";

type Props = {
  elemType?: string,
  children: Node,
  textAlign?: "left" | "center" | "right",
  className?: string,
  onClick?: () => {}
};

const Body = ({
  children,
  textAlign,
  elemType = "div",
  className,
  onClick
}: Props) => {
  const Elem = elemType;
  return (
    <Elem
      className={classNames(styles.root, {
        [className || ""]: !!className,
        [styles[`textAlign${capitalize(textAlign)}`]]: !!textAlign
      })}
      onClick={onClick}
    >
      {children}
    </Elem>
  );
};

export default Body;
