// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";
import Icon from "icons/Icon";
import Caption from "typography/Caption";

export const Tooltip = () => <Icon type="info-circle" color="textMid" />;

const TipBody = () => (
  <Caption textAlign="center">
    Install Edgar’s mobile app to publish stories or multi-image posts using
    reminder notifications.
  </Caption>
);

export default tooltipper(TipBody)(Tooltip);
