import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: rgba(113, 120, 121, 0.25);
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  margin: 20px 0 15px;
  padding: 16px;
  transition: all 0.1s ease-in-out;

  &:hover {
    background-color: rgba(113, 120, 121, 0.275);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  }

  &.collapsed {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }

  &.disabled {
    cursor: wait;
    opacity: 0.5;
  }
`;

export const Header = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0 0 16px;

  h3 {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
  }

  img {
    margin-left: 10px;
  }
`;

export const Button = styled.div`
  font-weight: 700;

  img {
    margin: 0 0 0 5px;
    position: absolute;
  }
`;

export const CollapsedIcon = styled.img`
  margin: 0 -6px 0 0;
  width: 36px;
`;
