/* @flow */
import React from "react";

import DateRange from "components/DateRange";

import type { IProps } from "./types.js";
import { Topbar, Title, Filters, DateRangeWrapper } from "./styles";

import Boxes from "./Boxes";
import AccountSelect from "./AccountSelect";

const ActivityOverview = (props: IProps) => {
  const {
    filters,
    setFilters,
    selectAccount,
    selectedAccount,
    minDate,
    maxDate
  } = props;

  return (
    <>
      <Topbar>
        <Title>Activity overview</Title>
        <Filters>
          <AccountSelect
            selectAccount={selectAccount}
            selectedAccount={selectedAccount}
          />
          <DateRangeWrapper>
            <DateRange
              startDate={filters.startDate}
              endDate={filters.endDate}
              minDate={minDate}
              maxDate={maxDate}
              handleStartDate={date =>
                setFilters({ ...filters, startDate: date })
              }
              handleEndDate={date => setFilters({ ...filters, endDate: date })}
            />
          </DateRangeWrapper>
        </Filters>
      </Topbar>

      <Boxes filters={filters} selectedAccount={selectedAccount} />
    </>
  );
};

export default ActivityOverview;
