/* @flow */
import React from "react";
import { Button } from "buttons";
import styles from "./index.css";

type Props = {
  paused: ?boolean,
  loading: boolean,
  onUnpauseQueue: () => void,
  onPauseQueue: () => void
};

const PauseButton = ({
  paused,
  onUnpauseQueue,
  onPauseQueue,
  loading
}: Props) => {
  if (paused === true) {
    return (
      <Button
        type="primary"
        loading={loading}
        onClick={onUnpauseQueue}
        className={styles.root}
      >
        Unpause queue
      </Button>
    );
  } else if (paused === false) {
    return (
      <Button
        type="primary"
        loading={loading}
        onClick={onPauseQueue}
        className={styles.root}
      >
        Pause queue
      </Button>
    );
  } else {
    return (
      <Button type="primary" disabled={true} className={styles.root}>
        &nbsp;
      </Button>
    );
  }
};

export default PauseButton;
