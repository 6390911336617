/* @flow */
import React from "react";
import classNames from "classnames";
import styles from "./index.css";
import facebookLogo from "../../images/facebook-inverted.svg";
import facebookFlag from "../../images/facebook-flag.svg";
import linkedinLogo from "../../images/linkedin-inverted.svg";
import xLogo from "../../images/x-logo.png";
import instagramLogo from "../../images/instagram.svg";
import pinterestLogo from "../../images/pinterest.svg";
import googleLogo from "../../images/google.svg";
import googleBusinessLogo from "../../images/google-my-business.svg";
import tiktokLogo from "../../images/tiktok-logo.svg";
import threadsLogo from "../../images/threads.png";

type ProviderAttributes = {
  imageUrl?: string,
  providerStyles?: string
};

type Props = {
  provider: string,
  className?: string,
  small?: boolean
};

export default function AccountProviderIcon({
  provider,
  className = "",
  small
}: Props) {
  const { imageUrl, providerStyles } = getProviderAttributes(provider);

  if (!imageUrl && !providerStyles) {
    return null;
  }

  const classes = classNames(providerStyles, {
    [className]: !!className,
    [styles.small]: small
  });
  return <img className={classes} src={imageUrl} alt={provider} />;
}

function getProviderAttributes(provider: string): ProviderAttributes {
  switch (provider.replace(/ /g, "_").toUpperCase()) {
    case "FACEBOOK":
      return { imageUrl: facebookLogo, providerStyles: styles.facebookIcon };
    case "FACEBOOK_PAGE":
    case "FACEBOOK_GROUP":
      return { imageUrl: facebookFlag, providerStyles: styles.facebookIcon };
    case "TWITTER":
      return { imageUrl: xLogo, providerStyles: styles.xIcon };
    //return { imageUrl: twitterLogo, providerStyles: styles.twitterIcon };
    case "LINKEDIN_COMPANY":
    case "LINKEDIN":
      return { imageUrl: linkedinLogo, providerStyles: styles.linkedinIcon };
    case "INSTAGRAM":
    case "INSTAGRAM_BUSINESS":
    case "INSTAGRAM_PERSONAL":
      return { imageUrl: instagramLogo, providerStyles: styles.instagramIcon };
    case "PINTEREST":
      return { imageUrl: pinterestLogo, providerStyles: styles.pinterestIcon };
    case "GOOGLE":
      return { imageUrl: googleLogo, providerStyles: styles.googleIcon };
    case "GOOGLE_BUSINESS":
      return {
        imageUrl: googleBusinessLogo,
        providerStyles: styles.googleBusinessIcon
      };
    case "TIKTOK":
      return {
        imageUrl: tiktokLogo,
        providerStyles: styles.tiktokIcon
      };
    case "THREADS":
      return {
        imageUrl: threadsLogo,
        providerStyles: styles.threadsIcon
      };
    default:
      return {};
  }
}
