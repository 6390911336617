// @flow
import qs from "qs";

import type { Location } from "react-router-dom";
import type { ContentSort } from "types";

export const extractSort = (location: Location): ContentSort => {
  const { sort = {} } = qs.parse(location.search, { ignoreQueryPrefix: true });
  sort.direction = sort.direction || "desc";
  sort.field = sort.field || "sent_at";
  return sort;
};

export const patchNextSearchSort = (
  search: string,
  nextSort: ContentSort
): string => {
  let qsAsHash = qs.parse(search.replace(/^\?/, ""));
  qsAsHash.sort = qsAsHash.sort || {};
  Object.keys(nextSort).forEach(sortKey => {
    qsAsHash.sort[sortKey] = nextSort[sortKey];
  });
  return qs.stringify(qsAsHash);
};
