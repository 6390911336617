import { useState } from "react";

const useModalAddAccounts = () => {
  const [total, setTotal] = useState(0);

  const handleChange = e => {
    if (e.target.value >= 0) setTotal(e.target.value);
  };

  return { total, handleChange };
};

export default useModalAddAccounts;
