// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  children: Node,
  htmlFor?: string,
  small?: boolean,
  bold?: boolean,
  link?: boolean,
  className?: string,
  onClick?: (evt: Event) => void,
  title?: string
};

const Label = ({
  children,
  htmlFor,
  small,
  bold,
  link,
  className,
  onClick,
  title = ""
}: Props) => (
  <label
    className={classNames(styles.root, {
      [className || ""]: !!className,
      [styles.small]: !!small,
      [styles.link]: !!link,
      [styles.bold]: !!bold
    })}
    htmlFor={htmlFor}
    onClick={onClick}
    title={title}
  >
    {children}
  </label>
);

export default Label;
