// @flow
/* eslint-disable flowtype/require-valid-file-annotation */
import React, { type Node } from "react";
import { preventDanglingWords } from "util";
import { Icon } from "icons";
import { H4, Caption } from "typography";
import { Button, type ButtonType } from "buttons";
import Modal from "../Modal";
import styles from "./index.css";

export type ConfirmType = "warning" | "error" | "success" | "info" | "none";

export type Props = {
  type?: ConfirmType,
  size?: "small" | "medium",
  title?: string,
  subTitle?: string,
  subTitleAlignment?: "left" | "center",
  confirmLabel?: string,
  autoFocus?: boolean,
  category?: boolean,
  text?: boolean,
  confirmType?: ButtonType,
  cancelLabel?: string,
  children?: Node,
  onConfirm?: (evt: Event) => void,
  onCancel?: (evt: Event) => void,
  onClose?: (evt: Event) => void
};

const iconMap = {
  warning: "exclamation-triangle",
  error: "ban",
  success: "check-circle",
  info: "info-circle"
};

const Confirmation = ({
  type = "info",
  size = "small",
  title = "Are you sure?",
  subTitle = "",
  subTitleAlignment = "center",
  confirmLabel = "OK",
  confirmType = "primary",
  cancelLabel = "Cancel",
  autoFocus = true,
  children,
  onCancel,
  onClose,
  onConfirm,
  text = false,
  category = false
}: Props) => (
  <Modal
    autoFocus={autoFocus}
    size={size}
    className={styles.root}
    onClose={onClose || onCancel}
  >
    <Icon type={iconMap[type]} className={styles[`${type}Icon`]} />

    <H4 className={styles.title}>{preventDanglingWords(title)}</H4>

    <Caption
      helpText
      style={{ textAlign: subTitleAlignment }}
      className={styles.subTitle}
    >
      {preventDanglingWords(subTitle)}
    </Caption>

    {children}

    {!(category || text ) && (
      <div>
        {onCancel && (
          <span className={styles.secondaryActions}>
            <Button type="secondary" onClick={onCancel}>
              {cancelLabel}
            </Button>
          </span>
        )}
        <Button type={confirmType} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </div>
    )}
  </Modal>
);

export default Confirmation;
