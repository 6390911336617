/* @flow */
import React from "react";
import { ActionBarButton } from "buttons";

const History = ({ contentId }: { contentId: string }) => (
  <ActionBarButton
    url={`/content-performance/${contentId}`}
    disabled={!contentId}
    type="secondary"
  >
    History
  </ActionBarButton>
);

export default History;
