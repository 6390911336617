// @flow
import filesize from "filesize";
import { reportException } from "errors";
import { inRange } from "lodash";
import analyzeImage, {
  ANALYSIS_ERROR_MESSAGE,
  type ImageAnalysis
} from "./analyzeImage";
import {
  PLATFORM_MAX_IMAGE_COUNT,
  PLATFORM_DISPLAY_NAME_MAP,
  COMPOSER_MAX_IMAGE_COUNT
} from "constants";

export type ImageValidationRuleSet = {
  size?: number,
  types?: string[],
  dimensions?: {
    [key: string]: [number, number]
  },
  aspectRatioRanges: {
    [key: string]: Object
  }
};
import type { Platform, Provider } from "graphql-types/loadComposer";

export type ImageValidationResponse = {
  valid: boolean,
  errors?: string[]
};

const VALIDATION_ERROR_MESSAGE = "An error occured while validating the image.";

export function mostStrictImageLimit(
  platforms: Platform[],
  providers: Provider[],
  sendMobileReminder: ?boolean
) {
  const leastStrictImageLimit = {
    count: PLATFORM_MAX_IMAGE_COUNT.FACEBOOK,
    platformName: "Edgar"
  };

  return platforms.reduce((limit, platform) => {
    const maxImageCountKey =
      platform === "INSTAGRAM"
        ? !providers.includes("INSTAGRAM_BUSINESS") || sendMobileReminder
          ? "INSTAGRAM_MOBILE"
          : "INSTAGRAM_DIRECT"
        : platform;
    const platformName =
      platform === "INSTAGRAM"
        ? !providers.includes("INSTAGRAM_BUSINESS") || sendMobileReminder
          ? "Instagram Mobile"
          : "Instagram Direct"
        : PLATFORM_DISPLAY_NAME_MAP[platform];

    return COMPOSER_MAX_IMAGE_COUNT[maxImageCountKey] < limit.count
      ? {
          platformName,
          count: COMPOSER_MAX_IMAGE_COUNT[maxImageCountKey]
        }
      : limit;
  }, leastStrictImageLimit);
}

const validateImage = (
  file: File,
  validations: ImageValidationRuleSet,
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  sendMobileReminder: boolean
): Promise<ImageValidationResponse> =>
  new Promise((resolve, reject) =>
    analyzeImage(file)
      .then((analysis: ImageAnalysis) => {
        const { size, type, width, height } = analysis;
        const errors = [];

        if (validations.types && !validations.types.includes(type)) {
          errors.push(
            `Image type ${type.replace("image/", "")} not supported.`
          );
        }

        if (validations.size && size >= validations.size) {
          errors.push(
            `Images must be less than ${filesize(validations.size)}.`
          );
        }

        const maxDimensions =
          validations.dimensions && validations.dimensions[type];
        if (maxDimensions) {
          const [maxWidth, maxHeight] = maxDimensions;
          if (
            maxWidth &&
            maxHeight &&
            (width > maxWidth || height > maxHeight)
          ) {
            errors.push(
              `Image resolution should be at most ${maxWidth}x${maxHeight} pixels.`
            );
          }
        }

        if (
          selectedProviders &&
          selectedProviders.includes("INSTAGRAM_BUSINESS") &&
          !sendMobileReminder
        ) {
          validateAspectRatio(width, height, validations, errors);
        }

        if (errors.length) {
          return reject({ valid: false, errors });
        }
        return resolve({ valid: true });
      })
      .catch(reason => {
        let error = ANALYSIS_ERROR_MESSAGE;
        if (reason !== ANALYSIS_ERROR_MESSAGE) {
          reportException(reason, "lib:images:validateImage");
          error = VALIDATION_ERROR_MESSAGE;
        }
        reject({ valid: false, errors: [error] });
      })
  );

// Instagram Direct requires specific aspect ratios (width:height).
//
// Square    or 1:1    (1.00)  - optimal size is 1080px by 1080px
// Landscape or 1.91:1 (1.91)  - optimal size is 1080px wide by 608px tall  - strict on the 1.91 ~ 1.927
// Portrait  or 4:5    (0.80)  - optimal size is 1080px wide by 1350px tall - strict on the 0.80 ~ 0.795
//
// Allowed by the API:
//   It seems like the range is 0.795 ~ 1.927 ish
//

export const validateAspectRatio = (
  width: number,
  height: number,
  validations: Object,
  errors: string[]
) => {
  const aspectRatio = width / height;
  const [
    minRange,
    maxRange
  ] = validations.aspectRatioRanges.INSTAGRAM_DIRECT.range;
  const imageInRange = inRange(aspectRatio, minRange, maxRange);

  if (!imageInRange) {
    errors.push(validations.aspectRatioRanges.INSTAGRAM_DIRECT.errorMessage);
  }
};

export default validateImage;
