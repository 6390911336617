/* @flow */
import getInitialData from "./getInitialData";
import { ensureEnum, featureFlag } from "util";
import type { Action } from "types";

export type AccountPlatform =
  | "FACEBOOK"
  | "TWITTER"
  | "LINKEDIN"
  | "INSTAGRAM"
  | "PINTEREST";

const ACCOUNT_PROVIDERS_MAP: { [AccountProvider]: "" } = {
  facebook: "",
  "facebook page": "",
  "facebook group": "",
  twitter: "",
  linkedin: "",
  "linkedin company": "",
  "instagram personal": "",
  "instagram business": "",
  pinterest: ""
};

if (featureFlag("pinterest")) {
  ACCOUNT_PROVIDERS_MAP["pinterest"] = "";
}

export type AccountProvider = $Keys<typeof ACCOUNT_PROVIDERS_MAP>;
const ACCOUNT_PROVIDERS: Array<AccountProvider> = Object.keys(
  ACCOUNT_PROVIDERS_MAP
);

export const toAccountProviderEnum = (provider: string): AccountProvider =>
  ensureEnum(provider, ACCOUNT_PROVIDERS);

export const getPlatformForProvider = (
  provider: AccountProvider
): AccountPlatform => {
  let platform = provider.split(" ")[0];
  platform = ensureEnum<AccountPlatform>(platform, ACCOUNT_PLATFORMS);
  return platform;
};

export const ACCOUNT_PLATFORMS: AccountPlatform[] = [
  "FACEBOOK",
  "TWITTER",
  "LINKEDIN",
  "INSTAGRAM"
];

if (featureFlag("pinterest")) {
  ACCOUNT_PLATFORMS.push("PINTEREST");
}

export type Attributes = {
  uid: string,
  name: string,
  displayName: string,
  nickname?: string,
  parent: Account,
  provider: AccountProvider,
  providerDisplayName: string,
  description: string,
  platform: AccountPlatform,
  image: string,
  parentId: ?string,
  parentImage: ?string,
  parentNickname: ?string,
  canRefresh: boolean,
  canDelete: boolean,
  isDisallowed: ?boolean
};

export type AccountData = {
  id: string,
  type: "accounts",
  attributes: Attributes
};

export type Account = { id: string } & Attributes;

export type State = {
  data: AccountData[]
};

export const initialState: State = {
  data: getInitialData("accounts")
};

const accountsReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default accountsReducer;
