/* @flow */
import React from "react";
import pluralize from "pluralize";
import { colorFromIndex } from "colors";
import { H3, Caption, Body } from "typography";
import Icon from "icons/Icon";
import styles from "./index.css";

type Props = {
  id: string,
  name: string,
  colorIndex: number,
  useForRandom: boolean,
  editable: boolean,
  approvedContentsCount: number,
  timeslotCount: number,
  randomTimeslotCount: number,
  handleOnClickEdit: () => void,
  handleOnClickDelete: () => void,
  handleOnClickShuffle: () => void
};

export default function CategoryCard({
  id,
  name,
  colorIndex,
  useForRandom,
  editable,
  approvedContentsCount,
  timeslotCount,
  randomTimeslotCount,
  handleOnClickEdit,
  handleOnClickDelete,
  handleOnClickShuffle
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.categoryColorContainer}>
        <div
          className={styles.categoryColor}
          style={{
            borderColor: colorFromIndex(colorIndex),
            backgroundColor: colorFromIndex(colorIndex)
          }}
        />
      </div>

      <div className={styles.categoryInfoContainer}>
        <H3>{name}</H3>
        <Body
          className={styles.contentsCountContainer}
          helpText
          onClick={() =>
            (window.location.href =
              "/contents?" + encodeURIComponent("filter[category]") + `=${id}`)
          }
        >
          {approvedContentsCount} {pluralize("update", approvedContentsCount)}{" "}
          in Library
        </Body>
        <Caption className={styles.caption} helpText>
          {!!timeslotCount ? (
            <span
              className={styles.timeslotCountContainer}
              onClick={() =>
                (window.location.href =
                  "/schedule?" +
                  encodeURIComponent("filter[category]") +
                  `=${id}`)
              }
            >
              <Icon type="check" className={styles.timeslotCountCheckIcon} />
              {timeslotCount} {pluralize("time slot", timeslotCount)} scheduled
            </span>
          ) : (
            <span>
              <Icon
                type="exclamation-triangle"
                className={styles.timeslotCountExclamationIcon}
              />
              No time slots scheduled
            </span>
          )}
        </Caption>
        <Caption className={styles.caption} helpText>
          <span
            className={useForRandom ? styles.timeslotCountContainer : null}
            onClick={() =>
              useForRandom &&
              (window.location.href =
                "/schedule?" + encodeURIComponent("filter[category]") + "=0")
            }
          >
            {useForRandom ? (
              `Included in ${randomTimeslotCount} Random ${pluralize(
                "time slot",
                randomTimeslotCount
              )}`
            ) : (
              <span>
                <b>Not</b> included in Random time slots
              </span>
            )}{" "}
          </span>
        </Caption>
      </div>

      <div className={styles.categoryActionsContainer}>
        <Icon
          title="shuffle"
          name="shuffleCategoryButton"
          type="random"
          onClick={handleOnClickShuffle}
          className={styles.icon}
        />

        {editable && (
          <Icon
            title="edit"
            name="editCategoryButton"
            type="pencil"
            onClick={handleOnClickEdit}
            className={styles.icon}
          />
        )}

        {editable && (
          <Icon
            title="delete"
            name="deleteCategoryButton"
            type="times"
            onClick={handleOnClickDelete}
            className={styles.icon}
          />
        )}
      </div>
    </div>
  );
}
