/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation deleteAllContent($filter: ContentsFilter!) {
    deleteAllContent(filter: $filter) {
      contents {
        id
      }
      errors
    }
  }
`;
