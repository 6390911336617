/* @flow */
import React, { Component } from "react";
import invariant from "invariant";

type Props = {
  onClose: () => void
};

export default class Overlay extends Component<Props> {
  handleClick = (evt: MouseEvent): void => {
    const originalTarget = evt.target;
    evt.stopPropagation();
    invariant(
      originalTarget instanceof HTMLElement,
      "Unexpected event target."
    );
    originalTarget.style.pointerEvents = "none";
    const realTarget = document.elementFromPoint(evt.clientX, evt.clientY);
    invariant(!!realTarget, "Expected to find target for click");
    this.props.onClose();
    realTarget.click();
    originalTarget.style.pointerEvents = "auto";
  };

  render() {
    return (
      <div
        style={{ position: "fixed", top: 0, right: 0, left: 0, bottom: 0 }}
        onClick={this.handleClick}
      />
    );
  }
}
