/* @flow */
import ReactDOM from "react-dom";
import React from "react";
import "normalize.css";
import camelize from "camelize";
import { nanoid } from "nanoid";
import invariant from "invariant";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";

// Import common components so their styles are included first by webpack, allowing
// them to be more easily overridden
import * as _commonComponents from "components/common";

import Analytics from "components/Analytics";
import AccountLimitModal from "components/AccountLimitModal";
import PlanModal from "components/PlanModal";
import AccountCancellation from "./pages/AccountCancellation";
import AccountCancellationConfirmation from "./pages/AccountCancellationConfirmation";
import Accounts from "components/Accounts";
import BulkEdit from "./pages/BulkEdit";
import Categories from "components/Categories";
import ContentPerformance from "./pages/ContentPerformance";
import Dashboard from "./pages/Dashboard";
import NewDashboard from "./pages/NewDashboard";
import Errors from "./pages/Errors";
import Library from "./pages/Library";
import Filters from "components/Filters";
import FullContent from "components/common/content/FullContent";
import History from "./pages/History";
import ImportManager from "components/ImportManager";
import Invoices from "./pages/Invoices";
import Invoice from "./pages/Invoice";
import OnboardingBar from "components/OnboardingBar";
import ProgressBar from "widgets/ProgressBar";
import QueueInformation from "./pages/Queue/QueueInformation";
import ReportList from "components/ReportList";
import Schedule from "./pages/Schedule";
import ScheduleOld from "./pages/ScheduleOld";
import Inbox from "./pages/Inbox";
import Composer from "./pages/Composer";
import NewComposer from "./pages/NewComposer";
import Search from "components/Search";
import Payment from "./pages/Payment";
import SubscriptionStatus from "components/SubscriptionStatus";
import TooltippedIcon from "components/common/icons/TooltippedIcon";
import TopNav from "components/TopNav";
import Queue from "./pages/Queue";
import UserProfile from "./pages/UserProfile";
import UserSettings from "./pages/UserSettings";
import SettingsBundle from "./pages/SettingsBundle";
import VideoThumbnail from "components/VideoThumbnail";
import VideoThumbnailModal from "components/VideoThumbnail/VideoThumbnailModal";
import { Widget } from "./pages/Composer";
import RootErrorBoundary from "components/RootErrorBoundary";
import Invitation from "./pages/Invitation";

import { Provider } from "react-redux";
import configureStore from "./store/configure-store.js";
import csrfToken from "./csrf.js";

import _styles from "./main.css";
import { getClient, getQsAsObject, featureFlag } from "./util/util";

const store = configureStore();
window.__REDUX_STORE__ = store;

const componentMap = {
  analytics: Analytics,
  accountLimitModal: AccountLimitModal,
  planModal: PlanModal,
  onboardingBar: OnboardingBar,
  progressBar: ProgressBar,
  queueInformation: QueueInformation,
  reportList: ReportList,
  subscriptionStatus: SubscriptionStatus,
  tooltippedIcon: TooltippedIcon,
  topNav: TopNav,
  videoThumbnail: VideoThumbnail,
  videoThumbnailModal: VideoThumbnailModal
};

// it would be nice if we could define a single client that can be used for
//  all component instances, but currently this causes graphql to return an
//  "invalid token" error... need to look into this a bit more, for now, we'll
//  use individual instances but will build them using the utility
//const client = getClient();

//const componentWithRouter = new Set([ContentComposer, Widget]);

const ready = fn => {
  if (document.readyState !== "loading") {
    fn();
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
};

const loadComponents = () => {
  const wrappers = document.querySelectorAll(".component--bootstrap");
  const client = getClient();

  Array.prototype.forEach.call(wrappers, el => {
    const Component = componentMap[camelize(el.dataset.name)];
    if (!Component) return;

    const props = el.dataset.props ? JSON.parse(el.dataset.props) : {};

    let childComponent;

    childComponent = <Component {...props} />;

    ReactDOM.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <RootErrorBoundary>{childComponent}</RootErrorBoundary>
        </Provider>
      </ApolloProvider>,
      el
    );
  });
};

const loadDevComponent = () => {
  const devComponents = document.querySelectorAll(".component--dev-component");

  Array.prototype.forEach.call(devComponents, el => {
    ReactDOM.render(<></>, el);
  });
};

const loadAccounts = () => {
  const client = getClient();
  const accounts = document.querySelectorAll(".component--accounts");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const navbar_modules_ui = localStorage.getItem("navbar_modules_ui");
  const accountsRequiredDiv = document.querySelector(
    ".accounts-require-refresh"
  );

  if (navbar_modules_ui === "true" && accountsRequiredDiv) {
    accountsRequiredDiv.classList.add("new-ui");

    const newUISpacerDiv = document.querySelector(".new-ui-spacer");
    if (newUISpacerDiv) newUISpacerDiv.classList.remove("hidden");
  }

  Array.prototype.forEach.call(accounts, el => {
    ReactDOM.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <RootErrorBoundary>
            <Router>
              <Route
                component={routerProps => (
                  <Accounts topNav={topNav} {...routerProps} />
                )}
              />
            </Router>
          </RootErrorBoundary>{" "}
        </Provider>
      </ApolloProvider>,
      el
    );
  });
};

const loadBulkEdit = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const bulkEdit = document.querySelectorAll(".component--bulk-edit");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(bulkEdit, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <BulkEdit
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadCategories = () => {
  const client = getClient();
  const categories = document.querySelectorAll(".component--categories");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  Array.prototype.forEach.call(categories, el => {
    ReactDOM.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <RootErrorBoundary>
            <Router>
              <Route
                component={routerProps => (
                  <Categories topNav={topNav} {...routerProps} />
                )}
              />
            </Router>
          </RootErrorBoundary>{" "}
        </Provider>
      </ApolloProvider>,
      el
    );
  });
};

const loadDashboard = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const dashboard = document.querySelectorAll(".component--dashboard");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(dashboard, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps =>
                  featureFlag("new_dashboard") ? (
                    <NewDashboard
                      {...routerProps}
                      topNav={topNav}
                      subscriptionStatus={subscriptionStatus}
                    />
                  ) : (
                    <Dashboard
                      {...routerProps}
                      topNav={topNav}
                      subscriptionStatus={subscriptionStatus}
                    />
                  )
                }
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadUniversity = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const university = document.querySelectorAll(".component--university");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(university, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <Dashboard
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadQueue = () => {
  const client = getClient();
  const upcomingPosts = document.querySelectorAll(".component--queue");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(upcomingPosts, el => {
    ReactDOM.render(
      <Provider store={store}>
        <ApolloProvider client={client}>
          <RootErrorBoundary>
            <Router>
              <Route
                render={routerProps => (
                  <Queue
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </RootErrorBoundary>{" "}
        </ApolloProvider>
      </Provider>,
      el
    );
  });
};

const loadLibrary = () => {
  /**
   * the following calls use an apollo client generator for testing new props
   *  before they become available on the backend
   * //const client = getClient('story_172360543_pinterest_variations');
   * //const client = getClient('story_172360543_pinterest_variations_video');
   * //const client = getClient('story_172360543_link_variations');
   */
  const client = getClient();

  const contents = document.querySelectorAll(".component--contents");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(contents, el => {
    const props = el.dataset.props ? JSON.parse(el.dataset.props) : {};
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                path="/contents/:status?"
                render={routerProps => (
                  <Library
                    {...routerProps}
                    {...props}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadContentPerformance = () => {
  /**
   * the following calls use an apollo client generator for testing new props
   *  before they become available on the backend
   * //const client = getClient('story_172360543_pinterest_variations');
   * //const client = getClient('story_172360543_pinterest_variations_video');
   * //const client = getClient('story_172360543_link_variations');
   */
  const client = getClient();

  const contentPerformance = document.querySelectorAll(
    ".component--content-performance"
  );

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(contentPerformance, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <RootErrorBoundary>
              <Router>
                <Route
                  render={routerProps => (
                    <ContentPerformance
                      {...routerProps}
                      topNav={topNav}
                      subscriptionStatus={subscriptionStatus}
                    />
                  )}
                />
              </Router>
            </RootErrorBoundary>{" "}
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadFilters = () => {
  const filters = document.querySelectorAll(".component--filters");
  Array.prototype.forEach.call(filters, el => {
    const props = el.dataset.props ? JSON.parse(el.dataset.props) : {};
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <Router>
            <Route
              render={routerProps => <Filters {...routerProps} {...props} />}
            />
          </Router>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadSearch = () => {
  const search = document.querySelectorAll(".component--search");
  Array.prototype.forEach.call(search, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <Router>
            <Route component={Search} />
          </Router>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadImportManagerComponent = () => {
  const importManager = document.querySelectorAll(".component--import-manager");
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  Array.prototype.forEach.call(importManager, el => {
    ReactDOM.render(
      <ApolloProvider client={client}>
        <Provider store={store}>
          <RootErrorBoundary>
            <Router>
              <Route
                path="/import/"
                component={routerProps => (
                  <ImportManager {...routerProps} topNav={topNav} />
                )}
              />
            </Router>
          </RootErrorBoundary>
        </Provider>
      </ApolloProvider>,
      el
    );
  });
};

const loadFullContentComponent = () => {
  const fullContentComponents = document.querySelectorAll(
    ".component--full_content"
  );
  Array.prototype.forEach.call(fullContentComponents, el => {
    const props = el.dataset.props ? JSON.parse(el.dataset.props) : {};
    // enforce typecheck at runtime, as props are passed down from Rails
    // and can't be checked at compile time.
    invariant(
      !(props.content === undefined && props.pageScrape === undefined),
      "FullContent requires content and pageScrape props."
    );
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <Router>
            <Route
              path="/contents/:id"
              render={routerProps => (
                <FullContent {...routerProps} {...props} />
              )}
            />
          </Router>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadSchedule = () => {
  const client = getClient();
  const upcomingPosts = document.querySelectorAll(".component--schedule");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(upcomingPosts, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <RootErrorBoundary>
              <Router>
                <Route
                  path="/schedule/:startAt?"
                  render={routerProps => (
                    <Schedule
                      {...routerProps}
                      topNav={topNav}
                      subscriptionStatus={subscriptionStatus}
                    />
                  )}
                />
              </Router>
            </RootErrorBoundary>{" "}
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadScheduleOld = () => {
  const client = getClient();
  const upcomingPosts = document.querySelectorAll(".component--schedule_old");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(upcomingPosts, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <RootErrorBoundary>
              <Router>
                <Route
                  render={routerProps => (
                    <ScheduleOld
                      {...routerProps}
                      topNav={topNav}
                      subscriptionStatus={subscriptionStatus}
                    />
                  )}
                />
              </Router>
            </RootErrorBoundary>{" "}
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadInbox = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const inbox = document.querySelectorAll(".component--inbox");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(inbox, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <Inbox
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadHistory = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const history = document.querySelectorAll(".component--history");
  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(history, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <History
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadComposer = () => {
  const client = new ApolloClient({
    headers: { "X-CSRF-Token": csrfToken() },
    resolvers: {
      Variation: {
        clientId: variation => {
          return variation.id || nanoid();
        }
      }
    }
  });
  const upcomingPosts = document.querySelectorAll(".component--composer");
  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};
  Array.prototype.forEach.call(upcomingPosts, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                path="/composer/:contentId?"
                render={routerProps =>
                  featureFlag("new_composer") ? (
                    <NewComposer
                      {...routerProps}
                      topNav={topNav}
                      subscriptionStatus={subscriptionStatus}
                    />
                  ) : (
                    <Composer
                      {...routerProps}
                      topNav={topNav}
                      subscriptionStatus={subscriptionStatus}
                    />
                  )
                }
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadWidget = () => {
  const client = new ApolloClient({
    headers: { "X-CSRF-Token": csrfToken() },
    resolvers: {
      Variation: {
        clientId: variation => {
          return variation.id || nanoid();
        }
      }
    }
  });
  const widget = document.querySelectorAll(".component--widget");
  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  const { title, url } = getQsAsObject();

  Array.prototype.forEach.call(widget, el => {
    el.style.height = "100%";
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                path="/composer/:contentId?"
                render={routerProps => (
                  <Widget
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                    initialVariationText={`${title} ${url}`}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadErrors = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const errors = document.querySelectorAll(".component--errors");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(errors, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <Errors
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadUserProfile = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const userProfile = document.querySelectorAll(".component--user-profile");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(userProfile, el => {
    const props = el.dataset.props ? JSON.parse(el.dataset.props) : {};
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <UserProfile
                    {...routerProps}
                    {...props}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadUserSettings = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const userSettings = document.querySelectorAll(".component--user-settings");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(userSettings, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <UserSettings
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadSettingsBundle = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const userSettings = document.querySelectorAll(".component--settings-bundle");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(userSettings, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <SettingsBundle
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadAccountCancellation = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const accountCancellation = document.querySelectorAll(
    ".component--account-cancellation"
  );

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(accountCancellation, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <AccountCancellation
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadAccountCancellationConfirmation = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const accountCancellationConfirmation = document.querySelectorAll(
    ".component--account-cancellation-confirmation"
  );

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(accountCancellationConfirmation, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <AccountCancellationConfirmation
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadInvoices = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const invoices = document.querySelectorAll(".component--invoices");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(invoices, el => {
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <Invoices
                    {...routerProps}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadInvoice = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const invoice = document.querySelectorAll(".component--invoice");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(invoice, el => {
    const props = el.dataset.props ? JSON.parse(el.dataset.props) : {};
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <Invoice
                    {...routerProps}
                    {...props}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadPayment = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const payment = document.querySelectorAll(".component--payment");

  const topNav = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.topNav
    : {};

  const subscriptionStatus = window.INITIAL_STORE_STATE
    ? window.INITIAL_STORE_STATE.subscriptionStatus
    : {};

  Array.prototype.forEach.call(payment, el => {
    const props = el.dataset.props ? JSON.parse(el.dataset.props) : {};
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <Payment
                    {...routerProps}
                    {...props}
                    topNav={topNav}
                    subscriptionStatus={subscriptionStatus}
                  />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

const loadInvitation = () => {
  const client = new ApolloClient({ headers: { "X-CSRF-Token": csrfToken() } });
  const invitation = document.querySelectorAll(".component--invitation");

  Array.prototype.forEach.call(invitation, el => {
    const props = {};
    ReactDOM.render(
      <Provider store={store}>
        <RootErrorBoundary>
          <ApolloProvider client={client}>
            <Router>
              <Route
                render={routerProps => (
                  <Invitation {...routerProps} {...props} />
                )}
              />
            </Router>
          </ApolloProvider>
        </RootErrorBoundary>
      </Provider>,
      el
    );
  });
};

ready(loadDevComponent);
ready(loadErrors);
ready(loadAccounts);
ready(loadBulkEdit);
ready(loadCategories);
ready(loadContentPerformance);
ready(loadQueue);
ready(loadComponents);
ready(loadLibrary);
ready(loadFilters);
ready(loadImportManagerComponent);
ready(loadSearch);
ready(loadFullContentComponent);
ready(loadSchedule);
ready(loadScheduleOld);
ready(loadInbox);
ready(loadHistory);
ready(loadComposer);
ready(loadWidget);
ready(loadDashboard);
ready(loadUniversity);
ready(loadUserProfile);
ready(loadUserSettings);
ready(loadSettingsBundle);
ready(loadAccountCancellation);
ready(loadAccountCancellationConfirmation);
ready(loadInvoices);
ready(loadInvoice);
ready(loadPayment);
ready(loadInvitation);
