/* @flow */
import React from "react";
import emptyQueue from "./empty_queue.svg";

const REASONS = {
  no_accounts: (
    <p key="no_accounts">
      Your account doesn&rsquo;t have any social media profiles associated with
      it, so Edgar doesn&rsquo;t know where he should share your updates. If you
      head on over to <a href="/accounts">your Accounts page</a>, you can give
      him permission to post all over the place!
    </p>
  ),

  no_approved_content: (
    <p key="no_approved_content">
      There aren&rsquo;t any social media updates saved in your Library yet, so
      Edgar just plain doesn&rsquo;t have anything to post! You can{" "}
      <a href="/composer">go right here to start adding updates</a>
      &mdash;it&rsquo;s a pretty invigorating experience, if we do say so
      ourselves.
    </p>
  ),

  no_categories: (
    <p key="no_categories">
      It looks like you don&rsquo;t have any categories where you can save
      social media updates. (These are how Edgar knows what types of updates to
      post at which times!) Why not head over to{" "}
      <a href="/categories">your Categories page</a> and see how good it feels
      to create a few?
    </p>
  ),

  no_contents_with_accounts: (
    <p key="no_contents_with_accounts">
      None of the status updates in your Library are assigned to social media
      accounts yet, so Edgar isn’t sure where he should post them! You can tell
      him which updates are for which social accounts{" "}
      <a href="/contents">right in the Library</a>&mdash;you can even use{" "}
      <a href="https://help.meetedgar.com/edgar-s-features-and-best-practices/edgar-s-library/library-bulk-actions-bulk-edit">
        our bulk editing tool
      </a>{" "}
      to do a whole bunch of them at once!
    </p>
  ),

  no_timeslots: (
    <p key="no_timeslots">
      It looks like your schedule doesn&rsquo;t have any timeslots on it yet, so
      Edgar doesn&rsquo;t know when he should post your updates. If you head
      over to <a href="/schedules">your Schedule page</a> and add a few,
      he&rsquo;ll be able to build your queue! (Hey, that rhymes!)
    </p>
  ),

  no_scheduled_content_accounts: (
    <p key="no_scheduled_content_accounts">
      Looks like none of the social media accounts for which you&rsquo;ve saved
      status updates show up on your posting schedule. In{" "}
      <a href="/contents">your Library</a>, you can see which of your social
      accounts have status updates associated with them. If you visit{" "}
      <a href="/schedules">your Schedule page</a> and add a few timeslots for
      those social accounts, Edgar will post to those accounts at the times you
      choose!
    </p>
  ),

  no_scheduled_content_categories: (
    <p key="no_scheduled_content_categories">
      Looks like none of the categories in which you&rsquo;ve saved status
      updates show up on your posting schedule. In{" "}
      <a href="/contents">your Library</a>, you can see which of your categories
      have status updates saved in them. If you visit{" "}
      <a href="/schedules">your Schedule page</a>
      and add a few timeslots using those categories, Edgar will post those
      types of updates at the times you choose!
    </p>
  ),

  all_content_use_once_and_used: (
    <p key="all_content_use_once_and_used">
      All of the status updates in your Library are in the &ldquo;Use
      Once&rdquo; category&mdash;and they&rsquo;ve all been used once! (So hey,
      good to know that thing&rsquo;s workin&rsquo;.) You can visit{" "}
      <a href="/contents">your Library</a> to change any of those updates to a
      different category, or you can go ahead and{" "}
      <a href="/composer">start adding some brand new status updates</a> for
      Edgar to share!
    </p>
  )
};

type Props = {
  reasons: string[]
};

const EmptyState = ({ reasons }: Props) => (
  <div>
    <h2>Your queue is empty right now.</h2>
    {reasons.map(r => REASONS[r])}
    <img src={emptyQueue} />
  </div>
);

export default EmptyState;
