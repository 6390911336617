// @flow
import React from "react";
import classNames from "classnames";
import HtmlizedText from "components/common/HtmlizedText";
import styles from "./index.css";
import type { AccountProvider, RawRichTextEntityMap } from "types";

type Props = {
  value: string,
  hasAttachment: boolean,
  provider: AccountProvider,
  mentions: string[],
  rawRichTextEntityMap: ?RawRichTextEntityMap
};

export const BIG_TEXT_THRESHOLD = 86;
export const LINK_CHAR_COUNT = 59;

// so, the trailing link should be omited on Twitter (which it is) and on FB
// groups (which it isn't) when media is NOT present.
// So, just text + link = no trailing link on TW & FB groups.
//
// Right now, they're showing up no matter what the post configuration is.
//
// Facebook pages DO show trailing links regardless of media presence. If
// media is present, trailing links should appear on FB groups and TW as well.

const Text = ({
  value,
  hasAttachment,
  provider,
  mentions,
  rawRichTextEntityMap
}: Props) => {
  // fb transitions to small text at 86 characters or if there is an attachment
  const bigText = !hasAttachment && value.length < BIG_TEXT_THRESHOLD;
  const providerOmitsTrailingLinks =
    !hasAttachment && provider === "FACEBOOK GROUP";

  return (
    <HtmlizedText
      className={classNames(styles.root, { [styles.big]: bigText })}
      newLineClassName={styles.newLine}
      value={value}
      omitTrailingLinks={providerOmitsTrailingLinks}
      otherTextRequiredForLinkOmission
      truncateLinksTo={LINK_CHAR_COUNT}
      mentions={mentions}
      provider={provider}
      rawRichTextEntityMap={rawRichTextEntityMap}
    />
  );
};

export default Text;
