/* @flow */
import neil from "./images/Neil_Patel.png";
import neilLogo from "./images/NeilPatelLogo.png";
import arriane from "./images/Arriane_Serafico.png";
import appSumo from "./images/AppSumoLogo.png";
import noah from "./images/Noah_Kagan.png";
import nathalie from "./images/nathalielussier.png";
import ambitionally from "./images/ambitionallylogo.png";

import type { EndorsementData } from "./Endorsement";

export const ENDORSEMENTS: EndorsementData[] = [
  {
    name: "Neil Patel",
    photo: neil,
    logo: neilLogo,
    quote: `The benefit of this is a never-ending schedule that keeps your social media profiles
            fresh, while refilling itself without your input. Use Edgar once and you may soon be
            calling him your BFF.`,
    logoName: "Neil Patel Logo"
  },
  {
    name: "Arriane Serafico, Entrepreneur",
    logo: null,
    photo: arriane,
    quote: `As a solo entrepreneur, I'm starting to realize that the more important factor I need to
            look for in tools I invest in -- is, 'Will it give me more freedom?' Edgar is a thousand
            times yes to that. :)`,
    logoName: null
  },
  {
    name: "Noah Kagan, Founder",
    photo: noah,
    logo: appSumo,
    quote: "Edgar makes life better.",
    logoName: "AppSumo Logo"
  },
  {
    name: "Nathalie Lussier, Founder and CEO",
    logo: ambitionally,
    photo: nathalie,
    quote: `So why do I happily pay for Edgar, with its little octopus mascot, to handle the
            majority of my social media scheduling needs? Because I get to do the work once, and
            essentially get paid for it over and over again.`,
    logoName: "Ambitionally Logo"
  }
];
