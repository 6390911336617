import { useState, useEffect, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import GET_ACCOUNTS from "queries/getAccounts";
import GET_LAST_COMMENTS from "queries/getLastComments";

import type {
  getAccounts,
  getAccounts_accounts
} from "graphql-types/getAccounts";
import type {
  getPosts_posts,
  getPosts_posts_data
} from "graphql-types/getPosts";
import type { getFacebookConversations_fetchFacebookConversations_conversations as ConversationType } from "../../graphql/types/getFacebookConversations";

const useInbox = () => {
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [loadingLastPosts, setLoadingLastPosts] = useState(true);
  const [loadingMoreOffset, setLoadingMoreOffset] = useState(true);
  const [lastPosts, setLastPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(undefined);
  const [post, setPost] = useState(undefined);
  const [contentType, setContentType] = useState("comments");
  const [selectedThread, setSelectedThread] = useState();

  const postsPerPage = 10;

  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchAccounts = async () => {
      setLoadingAccounts(true);

      const res: Promise<getAccounts> = await apolloClient.query({
        query: GET_ACCOUNTS,
        variables: { onlyPostable: true, withInboxSupport: true }
      });

      setAccounts(res.data.accounts);
      setLoadingAccounts(false);

      if (res.data.accounts?.length > 0) {
        selectAccount(res.data.accounts[0]);
      }
    };

    fetchAccounts();
  }, [apolloClient, selectAccount]);

  const selectAccount = useCallback(
    (account: getAccounts_accounts) => {
      if (account && account != selectedAccount) {
        clearPost();
        setSelectedAccount(account);
        fetchLastPosts(account);
      }
    },
    [clearPost, fetchLastPosts, selectedAccount]
  );

  const fetchLastPosts = useCallback(
    async (
      accountParam: getAccounts_accounts | undefined,
      loadingMore: boolean = false
    ) => {
      if (contentType !== "comments") return;

      const account = accountParam || selectedAccount;
      if (!account) return;

      if (loadingMore) {
        setLoadingMoreOffset(true);
      } else {
        setLoadingLastPosts(true);
        setLastPosts([]);
      }

      const res: Promise<getPosts_posts> = await apolloClient.query({
        query: GET_LAST_COMMENTS,
        variables: {
          page: {
            limit: postsPerPage,
            offset: lastPosts?.length || 0
          },
          filter: {
            status: "APPROVED",
            account: account.id
          },
          sort: {
            field: "post_updated_at",
            direction: "desc"
          }
        },
        fetchPolicy: "network-only"
      });

      const newPosts = [...lastPosts, ...get(res, "data.posts.data", [])];
      setLastPosts(newPosts);
      setTotalPosts(get(res, "data.posts.totalFilteredPosts", undefined));

      setLoadingLastPosts(false);
      setLoadingMoreOffset(false);
    },
    [apolloClient, lastPosts, selectedAccount, contentType]
  );

  const selectPost = (post: getPosts_posts_data) => {
    setPost(post);
  };

  const handleMessages = () => {
    setContentType("messages");
    clearPost();
  };

  const handleComments = () => {
    setContentType("comments");
    clearPost();
    fetchLastPosts();
  };

  const handleSelectedThread = (thread: ConversationType | null) => {
    setSelectedThread(thread);
  };

  const clearPost = useCallback(() => setPost(undefined));

  return {
    post,
    selectPost,
    clearPost,
    selectAccount,
    selectedAccount,
    accounts,
    loadingAccounts,
    lastPosts,
    loadingMoreOffset,
    fetchLastPosts,
    totalPosts,
    loadingLastPosts,
    contentType,
    handleMessages,
    handleComments,
    selectedThread,
    handleSelectedThread
  };
};

export default useInbox;
