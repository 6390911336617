/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getUserDevices {
    user {
      id
      devices {
        id
      }
    }
  }
`;
