/* @flow */
import React from "react";
import { ActionBarButton } from "buttons";

type Props = {
  contentId: string,
  type?: string
};

const Edit = (props: Props) => {
  const { contentId, type = "primary" } = props;

  return (
    <ActionBarButton
      url={`/composer/${contentId}`}
      type={type}
      disabled={!contentId}
    >
      Edit
    </ActionBarButton>
  );
};

export default Edit;
