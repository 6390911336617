/* @flow */
import React from "react";
import classNames from "classnames";
import styles from "./index.css";

export const Header = ({
  children,
  className
}: {
  children?: any,
  className: string
}) => {
  const classes = classNames(styles.header, { [className]: !!className });
  return <header className={classes}>{children}</header>;
};

export const TitleWithNav = ({ children }: { children?: any }) => (
  <div className={styles.titleWithNav}>{children}</div>
);

export const MoreNav = ({ children }: { children?: any }) => (
  <div className={styles.moreNav}>More: {children}</div>
);
