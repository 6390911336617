/* @flow */
import { useState } from "react";

const usePostsModal = () => {
  const [selectedCategory, setSelectedCategory] = useState(undefined);

  const handleCategory = (category: string) => {
    setSelectedCategory(category);
  };

  return { selectedCategory, handleCategory };
};

export default usePostsModal;
