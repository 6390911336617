/* @flow */
import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { selectDismissedInformationBoxes } from "selectors/users";
import { CloseButton } from "components/common/buttons";
import { dismissInformationBox } from "actions/informationBoxes";
import lightbulb from "./images/lightbulb.svg";
import styles from "./index.css";

import type { AppState, Dispatch, Connector } from "types";

type OwnProps = {
  children: any,
  name: string,
  className?: string
};

type Props = OwnProps & {
  dismissed: boolean,
  onDismiss: string => void
};

export const Information = ({
  children,
  onDismiss,
  dismissed,
  name,
  className
}: Props) =>
  dismissed ? null : (
    <div className={classNames(styles.root, className)}>
      <div className={styles.icon}>
        <img src={lightbulb} />
      </div>
      <div className={styles.body}>{children}</div>
      <div className={styles.close}>
        <CloseButton onClick={() => onDismiss(name)} />
      </div>
    </div>
  );

const mapStateToProps = (state: AppState, { name }: OwnProps) => ({
  dismissed: !!selectDismissedInformationBoxes(state)[name]
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onDismiss: name => dispatch(dismissInformationBox(name))
});

const connector: Connector<OwnProps, Props> = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(Information);
