/* @flow */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { get } from "lodash";
import Link from "links/Link";
import { onboardingLinkState } from "./util.js";

import GET_ONBOARDING_PROGRESS from "queries/getOnboardingProgress";
import RESUME_ONBOARDING from "mutations/resumeOnboarding";
import RESTART_ONBOARDING from "mutations/restartOnboarding";

import type { getOnboardingProgress_user_onboardingProgress as OnboardingProgressData } from "graphql-types/getOnboardingProgress";

type Props = {
  onboardingProgress: OnboardingProgressData,
  resumeOnboarding: () => void,
  restartOnboarding: () => void
};

export const ResumeOnboardingLink = ({
  onboardingProgress,
  resumeOnboarding,
  restartOnboarding
}: Props) => {
  const { visible, canResume } = onboardingLinkState(onboardingProgress);
  return visible ? (
    canResume ? (
      <Link menuLink onClick={resumeOnboarding}>
        Resume guide
      </Link>
    ) : (
      <Link menuLink onClick={restartOnboarding}>
        Restart guide
      </Link>
    )
  ) : null;
};

export default () => {
  // Queries
  const { data } = useQuery(GET_ONBOARDING_PROGRESS);

  // Mutations
  const [resumeOnboarding] = useMutation(RESUME_ONBOARDING);
  const [restartOnboarding] = useMutation(RESTART_ONBOARDING);

  const onboardingProgress = get(data, "user.onboardingProgress");

  if (!onboardingProgress) {
    return null;
  }

  return (
    <ResumeOnboardingLink
      onboardingProgress={onboardingProgress}
      resumeOnboarding={resumeOnboarding}
      restartOnboarding={restartOnboarding}
    />
  );
};
