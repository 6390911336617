/* @flow */
import React from "react";
import _ from "lodash";

import LoadingSVG from "components/common/images/loading-pulse.svg";

import type { IProps } from "./types.js";
import { Wrapper, Loading, LoadMore } from "./styles";

import Buttons from "./Buttons";
import DirectMessages from "./DirectMessages";
import LastPosts from "./LastPosts";

const Sidebar = (props: IProps) => {
  const {
    selectedAccount,
    loadingLastPosts,
    lastPosts,
    totalPosts,

    selectPost,
    selectedPost,

    loadingMoreOffset,
    fetchLastPosts,

    contentType,
    handleMessages,
    handleComments,

    selectedThread,
    handleSelectedThread
  } = props;

  return (
    <Wrapper>
      {selectedAccount && (
        <Buttons
          contentType={contentType}
          handleMessages={handleMessages}
          handleComments={handleComments}
        />
      )}

      {!selectedAccount || _.isEmpty(selectedAccount) ? (
        <Loading>
          <p>Waiting for an account</p>
        </Loading>
      ) : loadingLastPosts && contentType === "comments" ? (
        <Loading>
          <p>Loading posts...</p>
        </Loading>
      ) : (
        <>
          {contentType === "messages" ? (
            <DirectMessages
              account={selectedAccount}
              selectedThread={selectedThread}
              handleSelectedThread={handleSelectedThread}
            />
          ) : (
            <>
              <LastPosts
                posts={lastPosts}
                selectPost={selectPost}
                selectedPost={selectedPost}
              />
              {!loadingLastPosts && totalPosts > lastPosts?.length && (
                <LoadMore onClick={() => fetchLastPosts(undefined, true)}>
                  <div className={loadingMoreOffset ? `loading` : ``}>
                    Load{loadingMoreOffset && "ing"} more{" "}
                    <img src={LoadingSVG} alt="loading" />
                  </div>
                </LoadMore>
              )}
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Sidebar;
