/* @flow */
import React, { useState } from "react";
import get from "lodash/get";
import zipObject from "lodash/zipObject";
import some from "lodash/some";
import { Alert } from "layout";
import { Label } from "typography";
import CategorySelect from "components/common/CategorySelect";
import Checkbox from "forms/CheckboxInput";
import TimeInput from "forms/TimeInput";
import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import AccountSelect from "components/common/AccountSelect";
import Select from "forms/Select";
import styles from "./index.css";

import type { getSchedule_accounts as AccountData } from "graphql-types/getSchedule";
import type { CalendarItem } from "../Calendar/util.js";

import { DAYS, SHORT_DAYS } from "constants";

const DAY_OPTIONS = DAYS.map(day => ({ value: day, label: day }));
const SHORT_TO_LONG_DAYS = zipObject(SHORT_DAYS, DAYS);

type SubmitProps = {
  sendAt: string,
  accountIds: Set<string>,
  categoryId: string,
  sendOn: Set<string>,
  id?: ?string
};

type ScheduleModalProps = {
  onClose: (evt: Event) => void,
  onSubmit: (timeslot: SubmitProps) => void,
  onDelete: (timeslotId: string) => void,
  onDismissScheduleSuggestion: (offset: number, platforms: string[]) => void,
  accounts: AccountData[],
  creating: boolean,
  updating: boolean,
  deleting: boolean,
  error: ?string,
  calendarItem?: CalendarItem
};

const ScheduleModalFooter = ({
  onCancel,
  onSubmit,
  onDelete,
  creating,
  updating,
  deleting
}) => (
  <div className={styles.modalFooter}>
    {onSubmit ? (
      <Button
        onClick={onSubmit}
        type="primary"
        loading={creating || updating}
        disabled={deleting}
      >
        Save
      </Button>
    ) : null}
    <div className={styles.secondaryActions}>
      {onCancel ? (
        <Button
          onClick={onCancel}
          type="secondary"
          disabled={creating || updating || deleting}
        >
          Cancel
        </Button>
      ) : null}
      {onDelete ? (
        <Button
          confirm
          confirmTitle="Are you sure you want to delete this time slot?"
          confirmMessage="This action cannot be undone."
          confirmLabel="Yes"
          onClick={onDelete}
          type="secondary-negative"
          disabled={creating || updating}
          loading={deleting}
        >
          Delete
        </Button>
      ) : null}
    </div>
  </div>
);

const ScheduleModal = ({
  onClose,
  onSubmit,
  onDelete,
  onDismissScheduleSuggestion,
  accounts,
  calendarItem,
  creating,
  updating,
  deleting,
  error
}: ScheduleModalProps) => {
  const editing =
    get(calendarItem, "id", false) &&
    get(calendarItem, "__typename") === "Schedule";
  const isSuggestedSchedule =
    get(calendarItem, "__typename") === "ScheduleSuggestion";
  const [selectedAccounts, setSelectedAccounts] = useState(
    get(calendarItem, "accounts", []).map(a => a.id)
  );
  const [selectedCategoryId, setSelectedCategoryId] = useState(
    get(calendarItem, "category.id", null)
  );
  const [sendAt, setSendAt] = useState(get(calendarItem, "sendAt", null));
  const [selectedDay, setSelectedDay] = useState(
    get(calendarItem, "sendOn", null)
  );
  const [selectedDays, setSelectedDays] = useState(
    selectedDay ? new Set([selectedDay]) : new Set()
  );
  const [errors, setErrors] = useState({
    categoryId: false,
    sendOn: false,
    accountIds: false
  });

  function onChangeDay(day, checked) {
    if (checked) {
      selectedDays.add(day);
      setSelectedDays(new Set(selectedDays));
    } else {
      selectedDays.delete(day);
      setSelectedDays(new Set(selectedDays));
    }
    setErrors({ ...errors, sendOn: false });
  }

  function handleChangeCategory(category) {
    setSelectedCategoryId(category.id);
    setErrors({ ...errors, categoryId: false });
  }

  function handleChangeAccounts(accountIds) {
    setSelectedAccounts(accountIds);
    setErrors({ ...errors, accountIds: false });
  }

  function handleSubmit() {
    const categoryId = selectedCategoryId;
    const accountIds = selectedAccounts;
    const sendOn = editing ? selectedDay : selectedDays;
    const id = editing ? get(calendarItem, "id") : null;

    const newErrors = {
      ...errors,
      sendOn: !sendOn || sendOn.size < 1,
      categoryId: !categoryId,
      accountIds: accountIds.length < 1
    };

    setErrors(newErrors);

    if (some(newErrors) || !categoryId) {
      return;
    }

    const attrs = {
      sendAt,
      accountIds,
      categoryId,
      sendOn,
      id
    };

    if (!id) {
      delete attrs.id;
    }

    onSubmit(attrs);
  }

  function handleDelete() {
    const id = get(calendarItem, "id");
    const type = get(calendarItem, "__typename");
    if (type === "Schedule") {
      onDelete(id);
    } else if (type === "ScheduleSuggestion") {
      onDismissScheduleSuggestion(
        get(calendarItem, "localWeekOffset"),
        get(calendarItem, "platforms")
      );
    }
  }

  return (
    <Modal
      onClose={onClose}
      title={editing ? "Edit time slot" : "Add time slot"}
      footer={
        <ScheduleModalFooter
          onCancel={onClose}
          onSubmit={handleSubmit}
          onDelete={editing || isSuggestedSchedule ? handleDelete : null}
          creating={creating}
          updating={updating}
          deleting={deleting}
        />
      }
      size="legacy"
      closeOnOverlayClick
    >
      <div className={styles.modalBody}>
        <AccountSelect
          accounts={accounts}
          selected={selectedAccounts}
          onChange={handleChangeAccounts}
          hasError={errors.accountIds}
          className={styles.accountSelect}
          allowMultipleTwitter
          stackOptions
        />
        <div className={styles.sendAtAndCategory}>
          <div className={styles.category}>
            <div className={styles.labelContainer}>
              <Label
                htmlFor="category"
                className={styles.sendAtAndCategoryLabel}
              >
                Category
              </Label>
            </div>
            <CategorySelect
              id="category"
              value={selectedCategoryId}
              addRandomCategory
              disableDefaultCategory
              onChange={handleChangeCategory}
              hasError={errors.categoryId}
            />
          </div>
          <div>
            <Label htmlFor="sendAt" className={styles.sendAtAndCategoryLabel}>
              Send at
            </Label>
            <div className={styles.sendAt}>
              <TimeInput
                id="sendAt"
                className={styles.time}
                value={sendAt}
                onChange={time => setSendAt(time)}
              />
              {editing ? (
                <>
                  <label htmlFor="sendOn">on </label>
                  <Select
                    className={styles.day}
                    id="sendOn"
                    value={DAY_OPTIONS.find(o => o.value === selectedDay)}
                    onChange={day => setSelectedDay(day.value)}
                    options={DAY_OPTIONS}
                  />
                </>
              ) : null}
            </div>
          </div>
          {!editing ? (
            <div>
              <Label>Send on the following day(s)</Label>
              <div className={styles.dayOptions}>
                {SHORT_DAYS.map(d => (
                  <Checkbox
                    key={d}
                    label={d}
                    value={selectedDays.has(SHORT_TO_LONG_DAYS[d])}
                    onChange={checked =>
                      onChangeDay(SHORT_TO_LONG_DAYS[d], checked)
                    }
                    labelPlacemenr="bottom"
                    hasError={errors.sendOn}
                    className={styles.dayOption}
                  />
                ))}
              </div>
            </div>
          ) : null}
          {error ? <Alert type="error">{error}</Alert> : null}
        </div>
      </div>
    </Modal>
  );
};

export default ScheduleModal;
