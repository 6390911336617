// @flow
import React, { useState } from "react";
import styles from "./index.css";
import { Button } from "buttons";
import Icon from "icons/Icon";
import CreditRemainingTooltip from "./CreditRemainingTooltip";
import CoversationContainer from "./CoversationContainer";
import Select from "forms/Select";
import useAIGenerator from "./use";
import inkyImage from "./Images/inky.png";

const AIGenerator = props => {
  const { toggleAIGenerator, selectedPlatforms, selectedProviders } = props;
  const [showAIComposer, setShowAIComposer] = useState(false);
  const {
    aiContent,
    params,
    handleUpdateParams,
    generateContent,
    credits,
    loading
  } = useAIGenerator(selectedProviders);

  const aiGenerateButton = () => {
    return (
      <div className={styles.aiEditorButton}>
        <img src={inkyImage} alt="Inky" />
        <Button
          type="primary"
          onClick={e => {
            setShowAIComposer(true);
          }}
        >
          Generate Content with Inky
        </Button>
        <Button
          type="secondary"
          onClick={e => {
            toggleAIGenerator();
          }}
        >
          No thanks, disable it for now
        </Button>
      </div>
    );
  };
  const toneOptions = [
    {
      value: "happy",
      label: "Happy"
    },
    {
      value: "informational",
      label: "Informational"
    },
    {
      value: "angry",
      label: "Angry"
    },
    {
      value: "objective",
      label: "Objective (Informative)"
    },
    {
      value: "argumentative",
      label: "Argumentative (Persuasive)"
    },
    {
      value: "inspirational",
      label: "Inspirational (Emotional)"
    },
    {
      value: "sentimental",
      label: "Sentimental (Emotional)"
    },
    {
      value: "academic",
      label: "Academic (Formal)"
    },
    {
      value: "professional",
      label: "Professional (Formal)"
    },
    {
      value: "conversational",
      label: "Conversational (Casual)"
    },
    {
      value: "colloquial",
      label: "Colloquial (Casual)"
    },
    {
      value: "witty",
      label: "Witty (Humorous)"
    },
    {
      value: "sarcastic",
      label: "Sarcastic (Humorous)"
    },
    {
      value: "vivid",
      label: "Vivid (Descriptive)"
    },
    {
      value: "contemplative",
      label: "Contemplative (Reflective)"
    },
    {
      value: "analytical",
      label: "Analytical (Critical)"
    },
    {
      value: "skeptical",
      label: "Skeptical (Critical)"
    },
    {
      value: "optimistic",
      label: "Optimistic (Enthusiastic)"
    },
    {
      value: "excited",
      label: "Excited (Enthusiastic)"
    },
    {
      value: "melodramatic",
      label: "Melodramatic (Dramatic)"
    },
    {
      value: "impartial",
      label: "Impartial (Neutral)"
    }
  ];

  const handleHashtagChange = () => {
    handleUpdateParams("hasTag", !params.hasTag);
  };

  const aiEditor = () => {
    return (
      <>
        <div className={styles.aiComposer}>
          <div className={styles.aiComposerHeading}>
            {credits} Credits Remaining
            <CreditRemainingTooltip />
          </div>

          {aiContent?.length === 0 && (
            <div className={styles.aiComposerInfo}>
              To ensure quality of prompt, we capped the output generated by
              ChatGPT to 1000 words for each question.
            </div>
          )}
          <CoversationContainer aiContent={aiContent} />
          <div className={styles.aiInput}>
            {/* <div className={styles.buttonDiv}>
              <button className={styles.regenerateButton}>
                <Icon type="sync-alt" /> Regenerate
              </button>
            </div> */}
            <div className={styles.aiInputText}>
              <input
                type="text"
                placeholder="Provide contents with option to certain topic"
                value={params.prompt}
                disabled={credits <= 0 || loading}
                onChange={e => {
                  handleUpdateParams("prompt", e.target.value);
                }}
              />{" "}
              <p className={styles.limitCount}>
                Words {params.prompt.length} / 500
              </p>
              <Button
                className={styles.iconContainer}
                disabled={credits <= 0 || loading}
                loading={loading}
                type="primary"
                onClick={e => {
                  e.preventDefault();
                  generateContent();
                }}
              >
                <Icon type="long-arrow-alt-right" />
              </Button>
            </div>
            <div className={styles.emotionButtons}>
              <Select
                className={styles.selectEmojiContainer}
                placeholder="Select an option"
                value={toneOptions.find(o => o.value === params.tone)}
                options={toneOptions}
                onChange={e => {
                  handleUpdateParams("tone", e.value);
                }}
              />

              <div className={styles.checkBoxContainer}>
                <input
                  type="checkbox"
                  checked={params.hasTag}
                  onChange={handleHashtagChange}
                />
                <label>Generate suggested hashtags</label>
              </div>
            </div>
          </div>
        </div>
        {credits <= 0 && (
          <div className={styles.upgradeMessage}>
            You have run out of credits. Please buy more credits to continue
            <Button
              type="inline"
              onClick={() => {
                window.location.href = "/settings";
              }}
            >
              Buy Credits
            </Button>
          </div>
        )}
      </>
    );
  };
  return (
    <div
      className={`${showAIComposer ? "" : styles.aiButtonBackgroud} ${
        styles.aiEditor
      }`}
    >
      {showAIComposer ? aiEditor() : aiGenerateButton()}
    </div>
  );
};

export default AIGenerator;
