/* @flow */
import React, { Component, type Node } from "react";

type Props = {
  children?: Node,
  onInitiateLoading: () => void
};

type State = {
  containerHeight: number
};

class InfiniteScroller extends Component<Props, State> {
  state = {
    containerHeight: 0
  };

  componentDidMount(): void {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount(): void {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll: () => void = () => {
    const containerHeight = this.refs.scrollerContainer.scrollHeight;
    const beginLoadingDistance = 1024;

    if (
      window.scrollY + window.innerHeight >
      containerHeight - beginLoadingDistance
    ) {
      this.props.onInitiateLoading();
    }
  };

  render() {
    return <div ref="scrollerContainer">{this.props.children}</div>;
  }
}

export default InfiniteScroller;
