// @flow
import React, { Component } from "react";
import ScaledImage from "components/common/ScaledImage";
import styles from "./index.css";

export const SCALE_THRESHOLD_WIDTH = 440;
export const SCALE_THRESHOLD_HEIGHT = 220;
export const TARGET_WIDTH = 504;
export const TARGET_HEIGHT = 506;

type Props = {
  value: string
};

type State = {
  imageWidth: number,
  imageHeight: number
};

export default class Image extends Component<Props, State> {
  state = { imageWidth: 0, imageHeight: 0 };

  handleImageLoad = (imageWidth: number, imageHeight: number): void => {
    this.setState({ imageWidth, imageHeight });
  };

  shouldScaleImage() {
    const { imageWidth, imageHeight } = this.state;
    return (
      imageWidth >= SCALE_THRESHOLD_WIDTH &&
      imageHeight >= SCALE_THRESHOLD_HEIGHT
    );
  }

  renderImageChecker() {
    const { value } = this.props;

    return (
      <ScaledImage
        className={styles.imageChecker}
        value={value}
        targetWidth={TARGET_WIDTH}
        targetHeight={TARGET_HEIGHT}
        forceScaleDimension="x"
        onLoad={this.handleImageLoad}
      />
    );
  }

  renderUnscaledImage() {
    const { value } = this.props;
    return (
      <div
        test-data="unscaledImage"
        style={{ maxHeight: `${TARGET_HEIGHT}px` }}
      >
        <img
          style={{ maxWidth: `${TARGET_WIDTH}px` }}
          src={value}
          role="presentation"
        />
      </div>
    );
  }

  render() {
    const { value } = this.props;
    const { imageWidth, imageHeight } = this.state;

    if (imageWidth === 0 || imageHeight === 0) {
      return this.renderImageChecker();
    }

    if (!this.shouldScaleImage()) {
      return this.renderUnscaledImage();
    }

    return (
      <ScaledImage
        value={value}
        forceScaleDimension="x"
        targetWidth={TARGET_WIDTH}
        targetHeight={TARGET_HEIGHT}
      />
    );
  }
}
