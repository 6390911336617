/* @flow */
import React from "react";
import { Button } from "buttons";

import type { Props } from "./types.js";
import { Wrapper } from "./styles";

const NextButton = (props: Props) => {
  const { handleNextStep, stepNumber } = props;

  return (
    <Wrapper className={`step-${stepNumber}`}>
      <Button type="primary" onClick={() => handleNextStep(stepNumber)}>
        Next step
      </Button>
    </Wrapper>
  );
};

export default NextButton;
