// @flow
import React from "react";
import ScaledImage from "components/common/ScaledImage";
import styles from "./index.css";

type Props = {
  urls: string[]
};

const Four = ({ urls }: Props) => (
  <div className={styles.container}>
    <div className={styles.primaryImage}>
      <ScaledImage value={urls[0]} targetWidth={380} targetHeight={380} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[1]} targetWidth={126} targetHeight={126} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[2]} targetWidth={126} targetHeight={126} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[3]} targetWidth={126} targetHeight={126} />
    </div>
  </div>
);

export default Four;
