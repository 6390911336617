/* @flow */
/* global EventHandler, SyntheticKeyboardEvent */
import React, { Component, type ElementRef } from "react";
import AutogrowTextarea from "react-textarea-autosize";
import { get } from "lodash";
import classNames from "classnames";
import { reportMessage } from "errors";
import styles from "./index.css";

type Props = {
  value: string,
  className?: string,
  multiline?: boolean,
  autogrow?: boolean,
  placeholder?: string,
  hasError?: boolean,
  clear?: boolean,
  monitorExternalChanges?: boolean,
  name?: string,
  small?: boolean,
  onChange: (text: string, name: ?string) => void,
  onEnter?: (SyntheticKeyboardEvent<*>) => void,
  type?: "password" | "email" | "text"
};

type InputRefType =
  | ElementRef<"input">
  | ElementRef<"textarea">
  | ElementRef<AutogrowTextarea>;

export default class TextInput extends Component<Props> {
  componentDidMount() {
    const { autogrow, monitorExternalChanges } = this.props;

    // react-textarea-autosize can initially render at wrong height
    // https://github.com/andreypopp/react-textarea-autosize/issues/93
    if (autogrow) {
      this._startResizeTimer();
    }

    if (monitorExternalChanges) {
      this._startValueMonitor();
    }
  }

  componentWillReceiveProps({ value: newValue }: Props) {
    if (!this.props.monitorExternalChanges) {
      return;
    }

    // When the value changes restart the value monitor so the value still present in DOM
    // cannot override the new value coming from props
    if (this.props.value !== newValue) {
      this._restartValueMonitor();
    }
  }

  componentDidUpdate() {
    // Without this typical react prop changes to value get overridden by the
    // input's value in the DOM.
    if (
      this.props.monitorExternalChanges &&
      this.inputEl &&
      this.inputEl.value
    ) {
      this.inputEl.value = this.props.value;
    }
  }

  componentWillUnmount() {
    this._clearResizeTimer();
    this._clearValueMonitor();
  }

  _resizeTimer = null;

  _startResizeTimer = () => {
    this._resizeTimer = setTimeout(() => {
      if (
        !this.inputEl ||
        this.inputEl instanceof HTMLInputElement ||
        this.inputEl instanceof HTMLTextAreaElement ||
        typeof this.inputEl._resizeComponent !== "function"
      ) {
        return;
      }
      this.inputEl._resizeComponent();
    }, 0);
  };

  _clearResizeTimer = () => {
    if (this._resizeTimer) {
      clearTimeout(this._resizeTimer);
    }
  };

  _valueMonitor = null;

  _restartValueMonitor = () => {
    this._clearValueMonitor();
    this._startValueMonitor();
  };

  _startValueMonitor = () => {
    this._valueMonitor = setInterval(() => {
      const { value: currentValue, onChange, name } = this.props;
      const input = this._hasAutogrowTextArea()
        ? this.autogrowInputEl
        : this.inputEl;
      if (!input) return;

      const { value: inputValue } = input;

      if (currentValue === inputValue) return;

      try {
        reportMessage("<TextInput /> value set by value monitor", {
          metaData: {
            sniplyButtonHtml: window.$("[data-sniply=target-input]").html()
          }
        });
      } catch (e) {
        console.log("Failed to report sniply usage to bugsnag", e);
      }

      onChange(inputValue, name);
    }, 20);
  };

  _clearValueMonitor = () => {
    if (this._valueMonitor) {
      clearInterval(this._valueMonitor);
    }
  };

  handleChange: (evt: Event) => void = evt => {
    const { multiline, onChange, name } = this.props;
    const { target } = evt;
    const prototype = multiline ? HTMLTextAreaElement : HTMLInputElement;

    // In enzyme the evt.target is an AutogrowTextarea instance, however in the browser
    // it is a HTMLTextAreaElement DOM object, so handle both cases :P
    if (target instanceof prototype || target instanceof AutogrowTextarea) {
      onChange(target.value, name);
    }
  };

  handleKeyDown: (evt: SyntheticKeyboardEvent<*>) => void = evt => {
    const { onEnter } = this.props;
    if (!onEnter) {
      return;
    }
    const { keyCode } = evt;

    if (keyCode === 13) {
      onEnter(evt);
    }
  };

  focus = () => {
    if (this._hasAutogrowTextArea()) {
      if (!this.autogrowInputEl) return;
      this.autogrowInputEl.focus();
    } else {
      if (!this.inputEl) return;
      this.inputEl.focus();
    }
  };

  extractFieldProps: (props: any) => any = props => {
    const { onEnter: _onEnter, onChange: _onChange, ...fieldProps } = props;
    return fieldProps;
  };

  _hasAutogrowTextArea = () => !!this.props.multiline && !!this.props.autogrow;

  inputEl: ?InputRefType;

  autogrowInputEl: ?ElementRef<"input">;

  render() {
    const fieldProps = this.extractFieldProps(this.props);
    const {
      value,
      className,
      multiline,
      placeholder,
      hasError,
      clear,
      autogrow,
      monitorExternalChanges,
      name,
      small,
      type = "text",
      ...others
    } = fieldProps;
    const classes = classNames(styles.root, {
      [className]: !!className,
      [styles.hasError]: hasError,
      [styles.multiline]: multiline,
      [styles.clear]: clear,
      [styles.small]: small
    });

    const props = {
      value,
      ref: el => (this.inputEl = el),
      className: classes,
      placeholder,
      name,
      ...others,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyDown
    };

    const inputVal = get(this, "inputEl.value");
    if (monitorExternalChanges && inputVal) {
      props.value = inputVal;
    }

    if (multiline) {
      return autogrow ? (
        <AutogrowTextarea
          {...props}
          inputRef={el => (this.autogrowInputEl = el)}
        />
      ) : (
        <textarea {...props} />
      );
    }
    return <input type={type} {...props} />;
  }
}
