/* @flow */
import React, { useState, useEffect } from "react";

import { Expander } from "layout";
import imageAttachmentIcon from "images/image-attachment-icon.svg";
import AttachmentContainer from "../common/AttachmentContainer";
import MediaList from "../common/MediaList";
import MediaOverlay from "./MediaOverlay";
import styles from "./index.css";

import type { scrapeUrl_scrapeUrl_pageScrape_images as ScrapedImageData } from "graphql-types/scrapeUrl";
import type {
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData
} from "graphql-types/loadContent";

type Props = {
  allAttachedVideos: VideoData[],
  allAttachedImages: ImageData[],
  videos: VideoData[],
  images: ImageData[],
  scrapedImages: ScrapedImageData[],
  pageScrapeUrl: string,
  visible: boolean,
  loadingScrape: boolean,
  attachingImage: boolean,
  atMediaCountLimit: boolean,
  hasLinkPreviewAttachment: boolean,
  hasImageAttachment: boolean,
  hasVideoAttachment: boolean,
  onAttachImage: (image: ImageData) => void,
  onAttachVideo: (video: VideoData) => void,
  onAttachScrapedImage: (image: ScrapedImageData) => void,
  disableDuplicateMedia: boolean,
  disabledMessage: string
};

export const MediaAttacher = ({
  allAttachedVideos = [],
  allAttachedImages = [],
  videos,
  images,
  scrapedImages,
  pageScrapeUrl,
  visible,
  loadingScrape,
  atMediaCountLimit,
  hasLinkPreviewAttachment,
  hasImageAttachment,
  hasVideoAttachment,
  attachingImage,
  onAttachImage,
  onAttachVideo,
  onAttachScrapedImage,
  disableDuplicateMedia = false,
  disabledMessage
}: Props) => {
  const [hasScraped, setHasScraped] = useState(null);

  useEffect(() => {
    if (loadingScrape && hasScraped === null) {
      setHasScraped(false);
    }

    if (!loadingScrape && hasScraped === false) {
      setHasScraped(true);
    }
  }, [hasScraped, loadingScrape]);

  const noSuggestions =
    !loadingScrape &&
    videos.length === 0 &&
    images.length === 0 &&
    scrapedImages.length === 0;
  const imageItems = images
    .map(i => ({
      src: i.url,
      renderOverlay: () => (
        <MediaOverlay
          width={i.width}
          height={i.height}
          disabled={
            atMediaCountLimit ||
            hasVideoAttachment ||
            (disableDuplicateMedia &&
              allAttachedImages.some(
                attachedImg =>
                  attachedImg.clientProvidedSha256 === i.clientProvidedSha256
              ))
          }
          disabledMessage={disabledMessage}
          onAttach={onAttachImage.bind(null, i)}
        />
      )
    }))
    .concat(
      scrapedImages.map(i => ({
        src: i.src,
        alt: i.alt,
        renderOverlay: () => (
          <MediaOverlay
            width={i.width}
            height={i.height}
            disabled={
              atMediaCountLimit ||
              hasVideoAttachment ||
              (disableDuplicateMedia &&
                allAttachedImages.some(
                  attachedImg => attachedImg.clientProvidedSha256 === i.sha256
                ))
            }
            disabledMessage={disabledMessage}
            onAttach={onAttachScrapedImage.bind(null, i)}
          />
        )
      }))
    );
  const videoItems = videos.map(v => ({
    src: v.url,
    renderOverlay: () => (
      <MediaOverlay
        width={v.width}
        height={v.height}
        disabled={
          atMediaCountLimit ||
          hasImageAttachment ||
          (disableDuplicateMedia &&
            allAttachedVideos.some(
              attachedVid =>
                attachedVid.clientProvidedSha256 === v.clientProvidedSha256
            ))
        }
        disabledMessage={disabledMessage}
        onAttach={onAttachVideo.bind(null, v)}
      />
    )
  }));

  const mediaCount = videoItems.length + imageItems.length;
  const loading = loadingScrape || attachingImage;

  const [show, setShow] = React.useState(true);
  const onClose = () => {
    setShow(false);
  };
  return (
    <>
      <Expander
        className={styles.hasLinkAttachmentNoticeExpander}
        expanded={visible && hasLinkPreviewAttachment && mediaCount > 0}
        expandedHeight={100}
      >
        <div className={styles.hasLinkAttachmentNotice}>
          Images found from {pageScrapeUrl} (remove link preview to attach)
        </div>
      </Expander>

      <Expander
        className={styles.mediaListExpander}
        expanded={
          (visible || loading || hasScraped) && !hasLinkPreviewAttachment
        }
        expandedHeight={350}
      >
        {noSuggestions ? (
          <AttachmentContainer className={styles.attachmentContainer}>
            <div className={styles.noScrapedImagesNotice}>
              No attachable media found
            </div>
          </AttachmentContainer>
        ) : (
          show && (
            <AttachmentContainer className={styles.attachmentContainer}>
              <MediaList
                loading={loading}
                caption={
                  <span className={styles.mediaListCaption}>
                    <img
                      className={styles.imageIcon}
                      src={imageAttachmentIcon}
                    />
                    Suggested media
                  </span>
                }
                scrollToNewMedia={false}
                images={imageItems}
                videos={videoItems}
                onClose={onClose}
              />
            </AttachmentContainer>
          )
        )}
      </Expander>
    </>
  );
};

export default MediaAttacher;
