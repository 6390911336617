/* @flow */
import React, { createRef, useState, useEffect } from "react";
import get from "lodash/get";
import buildColor from "color";
import moment from "moment";
import classnames from "classnames";

import { colorFromIndex } from "colors";
import AccountBadge from "icons/AccountBadge";
import Icon from "icons/Icon";

import styles from "./index.css";

import type { CalendarHour, CalendarMinute, CalendarItem } from "../util.js";

type Props = {
  hour: CalendarHour,
  minute: CalendarMinute,
  schedules: CalendarItem[],
  focusedTimeslotIds: string[],
  onClickTimeslot: CalendarItem => void
};

const Timeslots = ({
  hour,
  minute,
  schedules,
  focusedTimeslotIds,
  onClickTimeslot
}: Props) => {
  if (schedules.length === 0) {
    return null;
  }

  const label = moment
    .utc()
    .set({ hour, minute })
    .format("h:mm a");
  return (
    <div className={styles.minute}>
      <div className={styles.minuteLabel}>{label}</div>
      {schedules.map(schedule =>
        schedule.__typename === "Schedule" ? (
          <Timeslot
            focused={focusedTimeslotIds.includes(schedule.id)}
            key={schedule.id}
            schedule={schedule}
            onClick={onClickTimeslot}
          />
        ) : (
          <SuggestedTimeslot
            key={`${schedule.sendOn} ${schedule.sendAt}`}
            suggestedSchedule={schedule}
            onClick={onClickTimeslot}
          />
        )
      )}
    </div>
  );
};

const RANDOM_CATEGORY_COLOR = "rgba(0, 0, 0, 0.65)";

const Timeslot = ({ schedule, focused, onClick }) => {
  const colorIndex = get(schedule, "category.colorIndex");
  const backgroundColor = colorIndex
    ? buildColor(colorFromIndex(colorIndex))
    : buildColor(RANDOM_CATEGORY_COLOR);
  const color = backgroundColor.isDark()
    ? buildColor("white")
    : buildColor("black");

  const ref = createRef();
  const [wasFocused, setWasFocused] = useState(false);

  useEffect(() => {
    if (!wasFocused && focused && ref.current) {
      // If this timeslot gained focus scroll to it
      const scrollToY = ref.current.offsetTop - 200;
      setTimeout(() => window.scrollTo(0, scrollToY), 0);
    }
    setWasFocused(focused);
  }, [focused, ref, wasFocused]);

  function handleClick(evt) {
    onClick(schedule);
    evt.stopPropagation();
  }

  return (
    <div
      ref={ref}
      className={classnames(styles.timeslot, { [styles.focused]: focused })}
      id={`schedule-${schedule.id}`}
      style={{
        color,
        backgroundColor
      }}
      onClick={handleClick}
    >
      {get(schedule, "category.name", "Random")}
      <AccountBadges accounts={schedule.accounts} />
    </div>
  );
};

const SuggestedTimeslot = ({ suggestedSchedule, onClick }) => {
  function handleClick(evt) {
    onClick(suggestedSchedule);
    evt.stopPropagation();
  }

  return (
    <div className={styles.suggestedTimeslot} onClick={handleClick}>
      Suggested time
      <AccountBadges accounts={suggestedSchedule.accounts} />
    </div>
  );
};

const AccountBadges = ({ accounts }) =>
  accounts.length > 0 ? (
    <div className={styles.accountBadges}>
      {accounts.map(account => (
        <AccountBadge key={account.id} value={account} size="tiny" />
      ))}
    </div>
  ) : (
    <div className={styles.noAccounts}>
      <Icon type="ban" size="lg" className={styles.noAccountsIcon} />
      No accounts
    </div>
  );

export default Timeslots;
