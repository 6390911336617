/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation createVideo($input: CreateVideoInput!) {
    createVideo(input: $input) {
      video {
        id
        url
        fileSize
        formats
        width
        height
        seconds
        clientProvidedSha256
      }
      errors
    }
  }
`;
