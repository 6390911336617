/* @flow */
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import UPDATE_SHOPIFY_ACCOUNT from "mutations/updateShopifyAccount";

import type {
  ShopifyFormErrors,
  ImportAttributesType,
  Props,
  PinterestBoards
} from "./types.js";

const useShopifyProductForm = (props: Props) => {
  const { showImportSuccessful } = props;
  const localDataString = localStorage.getItem("shopify-product-form");
  const localData = localDataString ? JSON.parse(localDataString) : {};
  const apolloClient = useApolloClient();

  const [accounts, setAccounts] = useState<[]>([]);
  const [selectedPinterestBoards, setSelectedPinterestBoards] = useState<
    PinterestBoards[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ShopifyFormErrors>({
    accountIds: false,
    categoryId: false,
    pinterestBoards: false,
    submit: false
  });
  const [accountIds, setAccountIds] = useState([]);
  const [categoryId, setCategoryId] = useState(localData.categoryId || null);
  const [importAttributes, setImportAttributes] = useState<
    ImportAttributesType[]
  >(localData.importAttributes || []);
  const [importContentAsPending, setImportContentAsPending] = useState<boolean>(
    localData.importContentAsPending !== undefined
      ? localData.importContentAsPending
      : true
  );

  useEffect(() => {
    const localData = JSON.stringify({
      categoryId,
      importAttributes,
      importContentAsPending
    });
    localStorage.setItem("shopify-product-form", localData);
  }, [categoryId, importAttributes, importContentAsPending]);

  const handleAccounts = (accountIds: string[]) => {
    setAccountIds(accountIds);
    let hasPinterestAccount = false;
    accountIds.forEach(accountId => {
      const account = accounts.find(account => account.id === accountId);
      if (account) {
        if (
          account.platform === "PINTEREST" &&
          selectedPinterestBoards.length === 0
        ) {
          hasPinterestAccount = true;
        }
      }
    });

    setErrors({
      ...errors,
      pinterestBoards: hasPinterestAccount,
      accountIds: false
    });
  };

  const handleCategory = (id: string) => {
    setCategoryId(id);
    setErrors({ ...errors, categoryId: false });
  };

  const handleAttribute = (attribute: ImportAttributesType, add: boolean) => {
    if (add) {
      if (importAttributes.includes(attribute)) return;
      setImportAttributes([...importAttributes, attribute]);
    } else {
      setImportAttributes(importAttributes.filter(attr => attr !== attribute));
    }
  };

  const handleImportContentAsPending = (value: boolean) => {
    setImportContentAsPending(value);
  };

  const onSelectPinterestBoard = (
    accountId: string,
    pinterestBoardId: string
  ) => {
    const newSelectedPinterestBoards = selectedPinterestBoards.filter(
      board => board.accountId !== accountId
    );

    newSelectedPinterestBoards.push({
      accountId,
      pinterestBoardId
    });

    setSelectedPinterestBoards(newSelectedPinterestBoards);
    setErrors({ ...errors, pinterestBoards: false });
  };

  const handleSubmit = async () => {
    const newErrors = { ...errors, submit: false };

    if (accountIds.length === 0) newErrors.accountIds = true;
    if (!categoryId) newErrors.categoryId = true;
    setErrors(newErrors);

    const hasPinterestAccount = accountIds.some(
      accountId =>
        accounts.find(account => account.id === accountId)?.platform ===
        "PINTEREST"
    );
    if (hasPinterestAccount) {
      const hasSelectedPinterestBoard =
        selectedPinterestBoards.filter(board => board.accountId !== null)
          .length ===
        accountIds.filter(
          accountId =>
            accounts.find(account => account.id === accountId)?.platform ===
            "PINTEREST"
        ).length;
      if (!hasSelectedPinterestBoard) {
        newErrors.pinterestBoards = true;
        setErrors(newErrors);
      }
    }

    if (Object.values(newErrors).includes(true)) return;

    const params = {
      accountRelationships: accountIds.map(id => {
        const pinterestBoard = selectedPinterestBoards.find(
          board => board.accountId === id
        );

        if (pinterestBoard) {
          return {
            accountId: id,
            pinterestBoardRelationships: selectedPinterestBoards
              .map(board =>
                board.accountId === id
                  ? {
                      pinterestBoardId: board.pinterestBoardId
                    }
                  : null
              )
              .filter(Boolean)
          };
        } else {
          return {
            accountId: id
          };
        }
      }),
      categoryId: categoryId,
      importAttributes: {
        name: importAttributes.includes("name"),
        url: importAttributes.includes("url"),
        description: importAttributes.includes("description"),
        pricing: importAttributes.includes("pricing")
      },
      importContentAsPending
    };

    try {
      setLoading(true);
      const res = await apolloClient.mutate({
        mutation: UPDATE_SHOPIFY_ACCOUNT,
        variables: { params }
      });

      if (res.data.updateShopifyAccount.shopifyAccount.id) {
        showImportSuccessful();
      } else {
        throw new Error("Error");
      }
    } catch (err) {
      setErrors({ ...errors, submit: true });
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    errors,
    accountIds,
    handleAccounts,
    setAccounts,
    categoryId,
    handleCategory,
    importAttributes,
    handleAttribute,
    handleSubmit,
    importContentAsPending,
    handleImportContentAsPending,
    selectedPinterestBoards,
    onSelectPinterestBoard
  };
};

export default useShopifyProductForm;
