import React, { Component } from "react";
import { reportException } from "errors";
import { Card } from "layout";

class RootErrorBoundary extends Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    reportException(error, "RootErrorBoundary", info);
  }

  renderError() {
    return (
      <Card>
        <h1>Uh-oh something went wrong 😿</h1>
        <p>
          Sorry! Please reload this page. If this problem persists{" "}
          <a href="https://help.meetedgar.com/" target="_blank">
            contact support
          </a>
          .
        </p>
      </Card>
    );
  }

  render() {
    return this.state.hasError ? this.renderError() : this.props.children;
  }
}

export default RootErrorBoundary;
