/* @flow */
import React, { useEffect, useState } from "react";

import LoadingIcon from "icons/LoadingIcon";
import H2 from "typography/H2";
import CheckboxInput from "forms/CheckboxInput";
import DropDown from "layout/DropDown";
import Button from "buttons/Button";
import Accounts from "components/common/content/common/Accounts";
import Body from "typography/Body";
import Paginator from "components/Paginator";
import Icon from "icons/Icon";
import Category from "./Category";
import CategoryEdit from "./CategoryEdit";
import UseOnceEdit from "./UseOnceEdit";
import AddAccounts from "./AddAccounts";
import RemoveAccounts from "./RemoveAccounts";
import Delete from "./Delete";
import Variation from "./Variation";
import NumSelected from "./NumSelected";

import styles from "./index.css";

import type { FlashType } from "reducers/flash";
import type { diffBulkContentIdsVariables } from "graphql-types/diffBulkContentIds";
import type { getAccounts_accounts as AccountData } from "graphql-types/getAccounts";
import type { getContents_contents_data as ContentData } from "graphql-types/getContents";
import type {
  bulkContentUpdate as BulkContentUpdateResult,
  bulkContentUpdateVariables,
  ContentsFilter
} from "graphql-types/bulkContentUpdate";
import type { getLatestBulkUpdateDisplayErrors as GetLatestBulkUpdateDisplayErrorsData } from "graphql-types/getLatestBulkUpdateDisplayErrors";

type Props = {
  accounts: AccountData[],
  accountsError: boolean,
  accountsLoading: boolean,
  bulkContentUpdate?: ({
    variables: bulkContentUpdateVariables
  }) => Promise<{ data: BulkContentUpdateResult }>,
  contents: ContentData[],
  diffBulkContentIds?: ({
    variables: diffBulkContentIdsVariables
  }) => void,
  diffBulkContentIdsError?: string,
  diffBulkContentIdsLoading?: boolean,
  diffedBulkContentIds?: string[],
  displayErrorsData?: GetLatestBulkUpdateDisplayErrorsData,
  displayErrorsLoading?: boolean,
  encodedFilter: string,
  filter: ContentsFilter,
  flash: (type: FlashType, body: string) => void,
  getLatestBulkUpdateDisplayErrors?: () => void,
  getLibraryStatus: () => void,
  libraryStatus?: string,
  libraryStatusError?: string,
  libraryStatusLoading?: boolean,
  loading: boolean,
  onPageChange?: number => void,
  page: number,
  perPage: number,
  refetchContents: () => void,
  totalFilteredContents: number
};

type IdMap = { [string]: boolean };

type VisibleSelection = "None" | "Page";

type VisibleSelectionMap = { [string]: VisibleSelection };

type ActionModalType =
  | "None"
  | "Category"
  | "UseOnce"
  | "AddAccounts"
  | "RemoveAccounts"
  | "Delete";

type State = {
  actionModalType: ActionModalType,
  encodedFilter: string,
  hasDiffBeenHandled: boolean,
  hasFlashBeenHandled: boolean,
  hasUpdateBeenAttempted: boolean,
  hasUpdateTimeExceededThreshold: boolean,
  isEditActionOpen: boolean,
  isMultiSelectOpen: boolean,
  isSelectAll: boolean,
  numAttemptedToUpdate: number,
  pageMultiSelectTypeMap: VisibleSelectionMap,
  selectedMap: IdMap,
  unselectedMap: IdMap,
  // This keeps track of the update sequence, since we have to poll for comletion as
  // all the updates are handled in the background (i.e., not in the web request):
  // - "None": no update has occurred or we've finished an update
  // - "Initialized": an update has been submitted but we've not yet received confirmation
  //   from the web request
  // - "Failed": we failed to create a background job for some reason
  // - "Started": the web request has confirmed the background job has been created, so we
  //   can start polling for completion state
  // - "Fetched": we've received completion state and have sent out to fetch the updated
  //   content items
  updateState: "None" | "Initialized" | "Failed" | "Started" | "Fetched",
  updateStateTimeoutId: ?TimeoutID
};

type ActionModalProps = {
  accounts: AccountData[],
  accountsError: boolean,
  accountsLoading: boolean,
  actionModalType: ActionModalType,
  bulkContentUpdate?: ({
    variables: bulkContentUpdateVariables
  }) => Promise<{ data: BulkContentUpdateResult }>,
  closeModal: () => void,
  failUpdate: () => void,
  filter: ContentsFilter,
  initUpdate: () => void,
  isSelectAll: boolean,
  selected: string[],
  startUpdate: () => void,
  totalSelected: number,
  unselected: string[],
  updateState: string
};

const INSTAGRAM_VARIATIONS_DISPLAY_ERROR =
  "To add an Instagram account, all variations must have an image or video attached.";

const LIBRARY_STATUS_OK = "ok";

const UPDATE_TIME_THRESHOLD = 10e3;

const VARIATIONS_DISPLAY_ERROR_RGX = /variations require/;

const addToMap = (id: string, map: IdMap): IdMap => {
  return {
    ...map,
    [id]: true
  };
};

const diffMap = (exclude: IdMap, map: IdMap): IdMap => {
  return Object.keys(map).reduce((newMap, curId) => {
    if (Boolean(exclude[curId])) {
      return newMap;
    }
    return {
      ...newMap,
      [curId]: true
    };
  }, {});
};

const removeFromMap = (id: string, map: IdMap): IdMap => {
  return Object.keys(map).reduce((newMap, curId) => {
    if (curId === id) {
      return newMap;
    }
    return {
      ...newMap,
      [curId]: true
    };
  }, {});
};

const getVisibleSelectionByPage = (
  page: number,
  map: VisibleSelectionMap
): VisibleSelection => {
  const visibleSelection = map["" + page];
  return Boolean(visibleSelection) ? visibleSelection : "None";
};

const setVisibleSelectionByPage = (
  page: number,
  visibleSelection: VisibleSelection,
  map: VisibleSelectionMap
): VisibleSelectionMap => {
  return {
    ...map,
    ["" + page]: visibleSelection
  };
};

const getFlashBody = (
  numAttemptedToUpdate: number,
  displayErrorsData: ?GetLatestBulkUpdateDisplayErrorsData
): string => {
  if (numAttemptedToUpdate < 1) {
    return "";
  }

  const uniqueDisplayErrorContentItems = [
    // Use the `Set` constructor to dedupe content IDs so we can use the length to deduct
    // from the number the user attempted to update.
    ...new Set(
      // Convert `displayErrorsData` into a list of possibly duplicated content IDs.
      (displayErrorsData?.latestBulkUpdateDisplayErrors?.data || []).reduce(
        (arr, displayError) => {
          const contentId = displayError?.content?.id || "";
          return Boolean(contentId) ? arr.concat(contentId) : arr;
        },
        []
      )
    )
  ];
  const numSuccessFullyUpdated =
    numAttemptedToUpdate - uniqueDisplayErrorContentItems.length;
  if (numSuccessFullyUpdated < 1) {
    return "";
  }

  const obj = numSuccessFullyUpdated === 1 ? "item" : "items";
  return `${numSuccessFullyUpdated} content ${obj} were successfully updated!`;
};

const ActionModal = (props: ActionModalProps) => {
  switch (props.actionModalType) {
    case "Category":
      return (
        <CategoryEdit
          bulkContentUpdate={props.bulkContentUpdate}
          close={props.closeModal}
          failUpdate={props.failUpdate}
          filter={props.filter}
          initUpdate={props.initUpdate}
          isSelectAll={props.isSelectAll}
          selected={props.selected}
          startUpdate={props.startUpdate}
          unselected={props.unselected}
          updateState={props.updateState}
        />
      );
    case "UseOnce":
      return (
        <UseOnceEdit
          bulkContentUpdate={props.bulkContentUpdate}
          close={props.closeModal}
          failUpdate={props.failUpdate}
          filter={props.filter}
          initUpdate={props.initUpdate}
          isSelectAll={props.isSelectAll}
          selected={props.selected}
          startUpdate={props.startUpdate}
          unselected={props.unselected}
          updateState={props.updateState}
        />
      );
    case "AddAccounts":
      return (
        <AddAccounts
          accounts={props.accounts}
          bulkContentUpdate={props.bulkContentUpdate}
          close={props.closeModal}
          error={props.accountsError}
          failUpdate={props.failUpdate}
          filter={props.filter}
          initUpdate={props.initUpdate}
          isSelectAll={props.isSelectAll}
          loading={props.accountsLoading}
          selected={props.selected}
          startUpdate={props.startUpdate}
          unselected={props.unselected}
          updateState={props.updateState}
        />
      );
    case "RemoveAccounts":
      return (
        <RemoveAccounts
          accounts={props.accounts}
          bulkContentUpdate={props.bulkContentUpdate}
          close={props.closeModal}
          error={props.accountsError}
          failUpdate={props.failUpdate}
          filter={props.filter}
          initUpdate={props.initUpdate}
          isSelectAll={props.isSelectAll}
          loading={props.accountsLoading}
          selected={props.selected}
          startUpdate={props.startUpdate}
          unselected={props.unselected}
          updateState={props.updateState}
        />
      );
    case "Delete":
      return (
        <Delete
          bulkContentUpdate={props.bulkContentUpdate}
          close={props.closeModal}
          failUpdate={props.failUpdate}
          filter={props.filter}
          initUpdate={props.initUpdate}
          isSelectAll={props.isSelectAll}
          selected={props.selected}
          startUpdate={props.startUpdate}
          totalSelected={props.totalSelected}
          unselected={props.unselected}
          updateState={props.updateState}
        />
      );
    default:
      return null;
  }
};

const BulkEditTable = (props: Props) => {
  const [state, setState] = useState<State>({
    actionModalType: "None",
    encodedFilter: props.encodedFilter,
    hasDiffBeenHandled: true,
    hasFlashBeenHandled: true,
    hasUpdateBeenAttempted: false,
    hasUpdateTimeExceededThreshold: false,
    isEditActionOpen: false,
    isMultiSelectOpen: false,
    isSelectAll: false,
    numAttemptedToUpdate: 0,
    pageMultiSelectTypeMap: {},
    selectedMap: {},
    unselectedMap: {},
    updateState: "None",
    updateStateTimeoutId: null
  });

  const selected = Object.keys(state.selectedMap);
  const unselected = Object.keys(state.unselectedMap);
  const totalSelected = state.isSelectAll
    ? props.totalFilteredContents - unselected.length
    : selected.length;

  const initUpdate = () => {
    setState({
      ...state,
      updateState: "Initialized"
    });
  };

  const failUpdate = () => {
    setState({
      ...state,
      updateState: "Failed"
    });
  };

  const startUpdate = () => {
    setState({
      ...state,
      hasUpdateBeenAttempted: true,
      numAttemptedToUpdate: totalSelected,
      updateState: "Started"
    });
  };

  useEffect(() => {
    // Do not send out multiple polls; wait for response until we try again, if necessary.
    if (
      props.loading ||
      props.libraryStatusLoading ||
      props.diffBulkContentIdsLoading ||
      props.displayErrorsLoading
    ) {
      return;
    }

    // To make things easy on ourselves, we're just going to reset the user selection
    // whenever a filter changes (e.g., a user filters on a different category). This
    // does not affect pagination, however; page changes maintain user selection.
    if (props.encodedFilter !== state.encodedFilter) {
      setState({
        ...state,
        encodedFilter: props.encodedFilter,
        hasUpdateBeenAttempted: false,
        hasUpdateTimeExceededThreshold: false,
        isSelectAll: false,
        pageMultiSelectTypeMap: {},
        selectedMap: {},
        unselectedMap: {},
        updateStateTimeoutId: null
      });
      return;
    }

    if (state.updateState === "Failed") {
      props.flash(
        "error",
        "We were unable to process the bulk update. Please try again."
      );
      setState({
        ...state,
        hasFlashBeenHandled: true,
        updateState: "None"
      });
      return;
    }

    // We have nothing to do if the user has either (a) not performed any mutation (i.e.,
    // "None"), (b) we have not yet received a response back from the server that created
    // the processing job (i.e., clicked "Apply" but we do not have confirmation), or (c)
    // the user has completed a mutation but has not yet triggered another.
    if (state.updateState === "None") {
      // Handle flash messaging if necessary.
      if (!state.hasFlashBeenHandled) {
        const flashBody = getFlashBody(
          state.numAttemptedToUpdate,
          props.displayErrorsData
        );
        if (Boolean(flashBody)) {
          props.flash("notice", flashBody);
        }
        setState({
          ...state,
          hasFlashBeenHandled: true
        });
      }

      // We do not need to update our manual selection if (a) we already have or (b) no
      // content items "fell out of" the selection.
      if (state.hasDiffBeenHandled) {
        return;
      }
      const diffedIds = props.diffedBulkContentIds || [];
      if (diffedIds.length < 1) {
        return;
      }

      // This is probably overkill, but converting this to a map so we do not have to diff
      // against a list for each of the manual item selection maps.
      const exclude = diffedIds.reduce((map, id) => {
        return {
          ...map,
          [id]: true
        };
      }, {});
      setState({
        ...state,
        hasDiffBeenHandled: true,
        hasFlashBeenHandled: true,
        // This covers an edge case where all items are selected, but all items "fall out"
        // of the result set after processing.
        isSelectAll:
          props.totalFilteredContents > 0 ? state.isSelectAll : false,
        // This tries to cover an edge case where all items on the page may have been
        // selected, but, post-processing, all the items have fallen out of the page
        // or entire result set.
        pageMultiSelectTypeMap:
          props.contents.length > 0
            ? state.pageMultiSelectTypeMap
            : setVisibleSelectionByPage(
                props.page,
                "None",
                state.pageMultiSelectTypeMap
              ),
        selectedMap: diffMap(exclude, state.selectedMap),
        unselectedMap: diffMap(exclude, state.unselectedMap)
      });
      return;
    }

    if (state.updateState === "Initialized") {
      return;
    }

    // At this point, we've received a response back from the server, so we'll now start
    // polling for the library status until it's completed (i.e., "ok").
    if (state.updateState === "Started") {
      clearTimeout(state.updateStateTimeoutId);
      if (state.hasUpdateTimeExceededThreshold) {
        return;
      }

      const updateStateTimeoutId = setTimeout(() => {
        setState({
          ...state,
          hasUpdateTimeExceededThreshold: true,
          // This is a little hacky as well, but it seems like `state` is cached at
          // the time this timeout handler is registered, so `updateState` was getting
          // "reset" to "Started". This overrides that and makes sure we can continue
          // to poll the library status.
          updateState: "Fetched",
          updateStateTimeoutId: null
        });
      }, UPDATE_TIME_THRESHOLD);

      setState({
        ...state,
        hasUpdateTimeExceededThreshold: false,
        updateState: "Fetched",
        updateStateTimeoutId
      });
      props.getLibraryStatus();
      return;
    }

    // The job process has not yet completed; poll for status again.
    if (props.libraryStatus !== LIBRARY_STATUS_OK) {
      props.getLibraryStatus();
      return;
    }

    // The background job should be done now, so let's do a fresh fetch to get all the
    // content items with their updated state.
    const ids = Object.keys(state.selectedMap).concat(
      Object.keys(state.unselectedMap)
    );
    // We only need to check if any items "fell out" if the user has manually (de)selected
    // any items.
    if (ids.length > 0) {
      props.diffBulkContentIds &&
        props.diffBulkContentIds({
          variables: {
            ids,
            filter: props.filter
          }
        });
    }

    props.refetchContents();
    if (state.hasUpdateBeenAttempted) {
      props.getLatestBulkUpdateDisplayErrors &&
        props.getLatestBulkUpdateDisplayErrors();
    }

    clearTimeout(state.updateStateTimeoutId);

    setState({
      ...state,
      actionModalType: "None",
      // If we have no manual items, we can mark the diff check as already being handled.
      hasDiffBeenHandled: ids.length < 1,
      hasFlashBeenHandled: false,
      hasUpdateTimeExceededThreshold: false,
      updateState: "None",
      updateStateTimeoutId: null
    });
  }, [props, state]);

  if (
    props.loading ||
    props.diffBulkContentIdsLoading ||
    props.displayErrorsLoading
  ) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingIcon className={styles.loading} />
      </div>
    );
  }

  if (props.totalFilteredContents < 1) {
    return <H2 className={styles.emptyState}>No content items present</H2>;
  }

  if (props.contents.length < 1) {
    return <H2 className={styles.emptyState}>No content matching filters</H2>;
  }

  if (state.updateState === "Started" || state.updateState === "Fetched") {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingIcon className={styles.loading} />
        {state.hasUpdateTimeExceededThreshold && (
          <div className={styles.updating}>
            <Body>
              Edgar is working on updating your content. This may take a few
              minutes. Do not close this page until the update has finished.
            </Body>
          </div>
        )}
      </div>
    );
  }

  const contentIdMap = props.contents.reduce((map, content) => {
    return {
      ...map,
      [content.id]: true
    };
  }, {});

  const selectVisible = (): void => {
    const { selectedMap } = state;
    setState({
      ...state,
      isMultiSelectOpen: false,
      isSelectAll: false,
      pageMultiSelectTypeMap: setVisibleSelectionByPage(
        props.page,
        "Page",
        state.pageMultiSelectTypeMap
      ),
      selectedMap: {
        ...selectedMap,
        ...contentIdMap
      },
      unselectedMap: {}
    });
  };

  const selectAll = (): void => {
    setState({
      ...state,
      isMultiSelectOpen: false,
      isSelectAll: true,
      pageMultiSelectTypeMap: setVisibleSelectionByPage(
        props.page,
        "None",
        state.pageMultiSelectTypeMap
      ),
      selectedMap: {},
      unselectedMap: {}
    });
  };

  const deselectAll = (): void => {
    setState({
      ...state,
      isMultiSelectOpen: false,
      isSelectAll: false,
      pageMultiSelectTypeMap: {},
      selectedMap: {},
      unselectedMap: {}
    });
  };

  const closeActionModal = (): void => {
    setState({ ...state, actionModalType: "None" });
  };

  // This is to allow a normal click event on `<DropDown />` items w/o triggering our `onBlur`
  // event. This needs to be bound to the `onMouseDown` event; normal `onClick` handling can
  // then be bound to the `onMouseUp` event.
  //
  // @see https://stackoverflow.com/a/57630197/1858091
  const preventBlurEvent = (evt: Event): void => {
    evt.preventDefault();
  };

  const openActionModal = (actionModalType: ActionModalType): (() => void) => {
    return (): void => {
      setState({ ...state, actionModalType, isEditActionOpen: false });
    };
  };

  const curPageVisibleSelection = getVisibleSelectionByPage(
    props.page,
    state.pageMultiSelectTypeMap
  );

  const displayErrorsMap = (
    props.displayErrorsData?.latestBulkUpdateDisplayErrors?.data || []
  ).reduce((map, displayError) => {
    const contentId = displayError?.content?.id || "";
    if (!Boolean(contentId)) {
      return map;
    }

    // This is really hacky but trying to update messaging on the client-side so
    // we don't have to touch the database for now.
    if (VARIATIONS_DISPLAY_ERROR_RGX.test(displayError.sanitizedBody || "")) {
      displayError.sanitizedBody = INSTAGRAM_VARIATIONS_DISPLAY_ERROR;
    }

    return {
      ...map,
      [contentId]: Boolean(map[contentId])
        ? map[contentId].concat(displayError)
        : [displayError]
    };
  }, {});
  const numDisplayErrors = Object.keys(displayErrorsMap).length;

  return (
    <div>
      <ActionModal
        accounts={props.accounts}
        accountsError={props.accountsError}
        accountsLoading={props.accountsLoading}
        actionModalType={state.actionModalType}
        bulkContentUpdate={props.bulkContentUpdate}
        closeModal={closeActionModal}
        failUpdate={failUpdate}
        filter={props.filter}
        initUpdate={initUpdate}
        isSelectAll={state.isSelectAll}
        selected={selected}
        startUpdate={startUpdate}
        totalSelected={totalSelected}
        unselected={unselected}
        updateState={state.updateState}
      />
      {state.hasUpdateBeenAttempted && numDisplayErrors > 0 && (
        <div className={styles.topDisplayErrors}>
          <div
            className={`${styles.topDisplayErrorsCol} ${
              styles.topDisplayErrorsIcon
            }`}
          >
            <div className={styles.topDisplayErrorsIconWrapper}>
              <Icon color="danger" size="2x" type="ban" />
            </div>
          </div>
          <div className={`${styles.topDisplayErrorsCol}`}>
            {numDisplayErrors}{" "}
            {numDisplayErrors === 1 ? "post was" : "posts were"} NOT updated due
            to conflicts. See the conflicts below. You can choose to return to
            the library (and leave the posts as they were) or try to resolve the
            conflicts by editing here. All other posts were updated.
          </div>
        </div>
      )}
      <div className={styles.totalFilteredContents}>
        {props.totalFilteredContents}{" "}
        {props.totalFilteredContents > 1 ? "posts" : "post"}
      </div>
      <div className={`${styles.actions} ${styles.stickyEl}`}>
        <div className={styles.action}>
          <div className={styles.multiSelectWrapper}>
            <CheckboxInput
              className={styles.multiSelectDefault}
              onChange={(_newValue: boolean): void => {
                if (state.isSelectAll) {
                  deselectAll();
                  return;
                }

                switch (curPageVisibleSelection) {
                  case "None":
                    // If we only have one page of content items, "Select all" instead of
                    // "visible".
                    if (props.totalFilteredContents <= props.perPage) {
                      selectAll();
                    } else {
                      selectVisible();
                    }
                    break;
                  case "Page":
                    setState({
                      ...state,
                      isSelectAll: false,
                      pageMultiSelectTypeMap: setVisibleSelectionByPage(
                        props.page,
                        "None",
                        state.pageMultiSelectTypeMap
                      ),
                      selectedMap: Object.keys(state.selectedMap).reduce(
                        (map, id) => {
                          // We are deselecting the current page's visible content items, so we
                          // want the new map to skip items on this page.
                          if (Boolean(contentIdMap[id])) {
                            return map;
                          }
                          return {
                            ...map,
                            [id]: true
                          };
                        },
                        {}
                      ),
                      unselectedMap: {}
                    });
                    break;
                  default:
                  // Pass.
                }
              }}
              tooltip={(() => {
                if (state.isSelectAll) {
                  return <span>Deselect all ({totalSelected})</span>;
                }

                switch (curPageVisibleSelection) {
                  case "None":
                    // We only want to show the "Select all" option if we only have a single
                    // page's worth of content items.
                    return props.totalFilteredContents <= props.perPage ? (
                      <span>Select all ({props.totalFilteredContents})</span>
                    ) : (
                      <span>Select visible ({props.contents.length})</span>
                    );
                  case "Page":
                    return (
                      <span>
                        Deselect visible (
                        {props.contents.length -
                          props.contents.filter(
                            content => !Boolean(state.selectedMap[content.id])
                          ).length}
                        )
                      </span>
                    );
                  default:
                    return null;
                }
              })()}
              value={state.isSelectAll || curPageVisibleSelection === "Page"}
            />{" "}
            <div
              className={styles.multiSelectAction}
              onBlur={(): void => {
                setState({ ...state, isMultiSelectOpen: false });
              }}
              onClick={(): void => {
                setState({
                  ...state,
                  isMultiSelectOpen: !state.isMultiSelectOpen
                });
              }}
              tabIndex="0"
            >
              <i className="fa fa-angle-down" />
            </div>
          </div>
          <DropDown
            handleEscapeKeyPress={(): void => {
              setState({ ...state, isMultiSelectOpen: false });
            }}
            includeOverlay={false}
            open={state.isMultiSelectOpen}
          >
            {/* Only allow "Select visible" if we have more than one page. */}
            {props.totalFilteredContents > props.perPage && (
              <div onMouseDown={preventBlurEvent} onMouseUp={selectVisible}>
                Select visible ({props.contents.length})
              </div>
            )}
            <div onMouseDown={preventBlurEvent} onMouseUp={selectAll}>
              Select all ({props.totalFilteredContents})
            </div>
            {totalSelected > 0 && (
              <div onMouseDown={preventBlurEvent} onMouseUp={deselectAll}>
                Deselect all
              </div>
            )}
          </DropDown>
        </div>
        <div className={styles.action}>
          <div
            onBlur={(): void => {
              setState({ ...state, isEditActionOpen: false });
            }}
            tabIndex="0"
          >
            <Button
              href="#"
              disabled={totalSelected < 1}
              onClick={(evt: Event): void => {
                evt.preventDefault();

                if (totalSelected < 1) {
                  return;
                }

                setState({
                  ...state,
                  isEditActionOpen: !state.isEditActionOpen
                });
              }}
              type="light"
            >
              Actions <i className="fa fa-caret-down" />
            </Button>
          </div>
          <DropDown
            handleEscapeKeyPress={(): void => {
              setState({ ...state, isEditActionOpen: false });
            }}
            includeOverlay={false}
            open={state.isEditActionOpen}
          >
            <div
              onMouseDown={preventBlurEvent}
              onMouseUp={openActionModal("Category")}
            >
              Edit category
            </div>
            <div
              onMouseDown={preventBlurEvent}
              onMouseUp={openActionModal("UseOnce")}
            >
              Edit use once setting
            </div>
            <div
              onMouseDown={preventBlurEvent}
              onMouseUp={openActionModal("AddAccounts")}
            >
              Add social accounts
            </div>
            <div
              onMouseDown={preventBlurEvent}
              onMouseUp={openActionModal("RemoveAccounts")}
            >
              Remove social accounts
            </div>
            <div
              onMouseDown={preventBlurEvent}
              onMouseUp={openActionModal("Delete")}
            >
              Delete
            </div>
          </DropDown>
        </div>
        <NumSelected
          className={styles.totalSelected}
          totalAvailable={props.totalFilteredContents}
          totalSelected={totalSelected}
        />
      </div>
      <div className={styles.contentItems}>
        {props.contents.map(content => {
          return (
            <div key={content.id}>
              {(displayErrorsMap[content.id] || []).map(displayError => {
                return (
                  <div
                    key={`display-error-${displayError.id}`}
                    className={styles.displayError}
                  >
                    <Icon
                      className={styles.displayErrorIcon}
                      color="danger"
                      size="1x"
                      type="ban"
                    />{" "}
                    <span>
                      {(
                        displayError.sanitizedBody.split("-").pop() || ""
                      ).trim()}
                    </span>
                  </div>
                );
              })}
              <div key={content.id} className={styles.row}>
                <div className={`${styles.cell} ${styles.selectItem}`}>
                  <CheckboxInput
                    onChange={(willBeSelected: boolean): void => {
                      if (state.isSelectAll) {
                        const { unselectedMap } = state;
                        setState({
                          ...state,
                          unselectedMap: willBeSelected
                            ? removeFromMap(content.id, unselectedMap)
                            : addToMap(content.id, unselectedMap)
                        });
                        return;
                      }

                      const { selectedMap } = state;
                      setState({
                        ...state,
                        selectedMap: willBeSelected
                          ? addToMap(content.id, selectedMap)
                          : removeFromMap(content.id, selectedMap)
                      });
                    }}
                    value={
                      (state.isSelectAll &&
                        !Boolean(state.unselectedMap[content.id])) ||
                      Boolean(state.selectedMap[content.id])
                    }
                  />
                </div>
                <div className={`${styles.cell} ${styles.category}`}>
                  <Category category={content.category} />
                </div>
                <div className={`${styles.cell} ${styles.useOnce}`}>
                  {content.useOnce && <>Use once</>}
                </div>
                <div className={`${styles.cell} ${styles.accounts}`}>
                  <Accounts
                    className={styles.avatars}
                    content={content}
                    exhaustedDisplay="Inline"
                    shouldDisplayNoAccountWarning={false}
                  />
                </div>
                <div className={`${styles.cell} ${styles.variations}`}>
                  {content.variations.map(variation => {
                    return (
                      <Variation
                        key={variation.id}
                        content={content}
                        variation={variation}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Paginator
        page={props.page}
        pageCount={
          props.totalFilteredContents
            ? Math.ceil(props.totalFilteredContents / props.perPage)
            : null
        }
        onPageChange={props.onPageChange}
      />
    </div>
  );
};

export default BulkEditTable;
