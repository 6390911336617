/* @flow */
import React from "react";

// import { featureFlag } from "util";
import Link from "links/Link";
import Badge from "typography/Badge";
import DropDown from "layout/DropDown";
import ResumeOnboardingLink from "components/TopNav/MainNav/ResumeOnboardingLink";
// import { useNewUICached } from "util";

// import useNewHeader from "./use";

import type { IProps } from "./types.js";

const NewHeader = (props: IProps) => {
  const { open, onClose, isAdmin, companyStatus } = props;
  // const { toggleNewUI } = useNewHeader();
  // const useNewUI = useNewUICached();

  return (
    <DropDown
      open={open}
      onClose={onClose}
      anchor="bottomRight"
      handleEscapeKeyPress={onClose}
    >
      {companyStatus ? (
        <Link menuLink url="/errors" key="errors">
          Notifications &nbsp;
          {companyStatus?.errorCount > 0 && (
            <Badge type="danger">{companyStatus.errorCount}</Badge>
          )}
        </Link>
      ) : null}
      <Link menuLink url="/settings" key="user">
        Settings
      </Link>
      <Link
        menuLink
        url="https://help.meetedgar.com/"
        target="_blank"
        key="help"
      >
        Help center
      </Link>
      <Link
        menuLink
        url="https://affiliates.meetedgar.com/"
        target="_blank"
        key="share"
      >
        Share Edgar 💰
      </Link>
      <ResumeOnboardingLink key="resumeOnboaringLink" />
      {/* {featureFlag("navbar_modules_ui") && (
        <Link menuLink onClick={toggleNewUI} key="new_UI">
          {useNewUI ? "Disable" : "Enable"} new UI
        </Link>
      )} */}
      {isAdmin && (
        <Link menuLink url="/admin/dashboard" key="admin">
          Admin
        </Link>
      )}
      <Link menuLink url="/users/sign_out" method="DELETE" key="sign_out">
        Sign out
      </Link>
    </DropDown>
  );
};

export default NewHeader;
