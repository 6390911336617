/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation dismissOnboarding {
    dismissOnboarding {
      onboardingProgress {
        id
        addAccount
        viewCategories
        addContent
        addTimeslots
        importContent
        dismissedAt
      }
      errors
    }
  }
`;
