/* @flow */
import styled from "styled-components";

import { mobileBreakpoint } from "../styles";

export const Wrapper = styled.div`
  margin: 0 0 10px;

  @media (min-width: ${mobileBreakpoint}px) {
    margin: 0 10px 0 0;
  }
`;

export const Container = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(197, 197, 197, 1);
  display: flex;
  font-size: 16px;
  height: 40px;
  justify-content: space-between;
  padding: 0 4px;
  width: 100%;

  img.caret-down {
    margin: 1px 0 0 12px;
  }
`;

export const Loading = styled.div`
  align-items: center;
  color: #aaa;
  display: flex;
  font-size: 14px;
  padding: 8px 6px;
`;

export const Selected = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  padding: 8px 8.5px;

  ${({ hasFeatureFlag }) =>
    !hasFeatureFlag &&
    `
    cursor: default;
    padding: 8px 6px;
  `}
`;

export const Item = styled.div`
  align-items: center;
  display: flex !important;
  font-size: 14px !important;
  padding: 4px 10px !important;
`;
