/* @flow */
import React from "react";
import moment from "moment";

import AccountAvatar from "images/AccountAvatar";
import LoadingSVG from "components/common/images/loading-pulse.svg";

import useDirectMessages from "./use";
import type { Props } from "./types.js";

import { Loading, LoadMore } from "../styles";
import { Post as Preview, Right, Title as Name } from "../LastPosts/styles";
import { Wrapper, Picture, Message } from "./styles";

const DirectMessages = (props: Props) => {
  const { account, selectedThread } = props;
  const {
    loading,
    conversations,
    handleSelect,
    loadingMore,
    cursor,
    handleLoadMore,
    error
  } = useDirectMessages(props);

  return (
    <Wrapper>
      {error ? (
        <Loading>{error}</Loading>
      ) : !account ? (
        <Loading>Waiting for an account...</Loading>
      ) : loading ? (
        <Loading>Loading messages...</Loading>
      ) : conversations?.length === 0 ? (
        <Loading>
          No messages found for the {account.name}. You can choose a different
          account above.
        </Loading>
      ) : (
        <>
          {conversations.map((item, index) => (
            <Preview
              key={index}
              active={selectedThread?.id === item.id}
              onClick={() => handleSelect(item)}
            >
              <Picture>
                <AccountAvatar
                  src={item.sender.profilePic}
                  alt={item.sender.name}
                />
              </Picture>
              <Right>
                <Name>
                  <h4 className="has-image">
                    {item?.name ? item.name : item.sender.name}
                  </h4>
                  <span>
                    {moment(item.updatedTime).format("MMM D, h:mm a")}
                  </span>
                </Name>
                <Message active={false}>
                  {item.snippet === null
                    ? "Please, check your Instagram account to accept this message request"
                    : item.snippet
                    ? item.snippet
                    : "This type of message is not accepted outside Instagram"}
                  {/* {item.unreadCount && item.unreadCount > 0 ? (
                    <Unread>{item.unreadCount}</Unread>
                  ) : null} */}
                </Message>
              </Right>
            </Preview>
          ))}

          {cursor ? (
            <LoadMore onClick={handleLoadMore}>
              <div className={loadingMore ? `loading` : ``}>
                Load{loadingMore && "ing"} more{" "}
                <img src={LoadingSVG} alt="loading" />
              </div>
            </LoadMore>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

export default DirectMessages;
