/* @flow */
import React, { useState } from "react";
import Icon from "icons/Icon";
import Button from "buttons/Button";
import Modal from "layout/modals/Modal";
import FullContent from "../../../FullContent";

import styles from "./index.css";

import type { getContents_contents_data as ContentData } from "graphql-types/getContents";

type Props = {
  content: ContentData,
  timezone: string
};

const MoreVariations = ({ content, timezone }: Props) => {
  const [show, setShow] = useState(null);
  const { id, variations } = content;

  if ((variations?.length ?? 0) > 1) {
    return (
      <div>
        <Button
          onClick={() => {
            setShow(id);
          }}
          type="tint"
        >
          <span className={styles.buttonIconContainer}>
            <Icon type="clone" />
          </span>
          {`${variations.length} total variations`}
        </Button>

        {show && (
          <Modal
            closeOnOverlayClick
            className={styles.variationsSummary}
            size="large"
            onClose={() => {
              setShow(null);
            }}
          >
            <FullContent content={content} timezone={timezone} />
          </Modal>
        )}
      </div>
    );
  }

  return null;
};

export default MoreVariations;
