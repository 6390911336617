/* @flow */
import React from "react";

import CheckboxInput from "forms/CheckboxInput";

import type { Props } from "./types.js";
import styles from "./index.css";
import { Wrapper } from "./styles";
import { SectionTitle } from "../styles";

const ShopifyFormAttributes = (props: Props) => {
  const { importAttributes, handleAttribute } = props;

  return (
    <Wrapper>
      <SectionTitle>Import attributes</SectionTitle>
      <CheckboxInput
        value={importAttributes.includes("name")}
        labelClassName={styles.checkboxLabel}
        label="Name"
        onChange={value => handleAttribute("name", value)}
      />
      <CheckboxInput
        value={importAttributes.includes("url")}
        labelClassName={styles.checkboxLabel}
        label="URL"
        className={styles.checkboxStyle}
        onChange={value => handleAttribute("url", value)}
      />
      <CheckboxInput
        value={importAttributes.includes("description")}
        labelClassName={styles.checkboxLabel}
        label="Description"
        className={styles.checkboxStyle}
        onChange={value => handleAttribute("description", value)}
      />
      <CheckboxInput
        value={importAttributes.includes("pricing")}
        labelClassName={styles.checkboxLabel}
        label="Pricing"
        className={styles.checkboxStyle}
        onChange={value => handleAttribute("pricing", value)}
      />
    </Wrapper>
  );
};

export default ShopifyFormAttributes;
