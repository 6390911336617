/* @flow */
import React from "react";
import { DefaultPlayer as VideoPlayer } from "react-html5video";
import Actions from "../../Actions";
import Timer from "./Timer";
import styles from "./index.css";

import type { Video as VideoType } from "types";

type Props = {
  video: VideoType
};

export const Video = ({ video }: Props) => (
  <div className={styles.root}>
    <VideoPlayer controls={[]} autoPlay className={styles.video}>
      <source src={video.url} type="video/mp4" />
    </VideoPlayer>

    <div className={styles.timer}>
      <Timer seconds={video.seconds} />
    </div>
    <Actions itemCount={1} />
  </div>
);

export default Video;
