// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import { capitalize } from "util";
import styles from "./index.css";

type Props = {
  children: Node,
  helpText?: boolean,
  error?: boolean,
  hasBottomMargin?: boolean,
  textAlign?: "left" | "center" | "right",
  className?: string
};
// TODO tests for helpText prop
// TODO cleanup style

const Caption = ({
  children,
  className,
  helpText,
  error,
  hasBottomMargin,
  textAlign,
  ...others
}: Props) => (
  <div
    className={classNames(styles.root, {
      [className || ""]: !!className,
      [styles.helpText]: !!helpText,
      [styles.error]: !!error,
      [styles.hasBottomMargin]: !!hasBottomMargin,
      [styles[`textAlign${capitalize(textAlign)}`]]: !!textAlign
    })}
    {...others}
  >
    {children}
  </div>
);

export default Caption;
