/* @flow */
import React from "react";

import { Button } from "components/common/buttons";
import Modal from "components/common/layout/modals/Modal";

import ModalSuccessImage from "components/common/images/icon.png";
import ModalErrorImage from "components/common/images/shopifyError.png";

import type { Props } from "./types.js";
import useShopifyAuthentication from "./use";
import { Content, Title, Info, Icon } from "./styles";

import ShopifyProductForm from "./ShopifyProductForm";

export const ShopifyAuthentication = (props: Props) => {
  const { authorizationSuccess, onClickHide } = props;

  const {
    shopifyImportModal,
    showShopifyModal,
    hideShopifyModal,
    importSuccessFul,
    showImportSuccessful
  } = useShopifyAuthentication(props);

  return shopifyImportModal && !importSuccessFul ? (
    <ShopifyProductForm
      onClickHide={hideShopifyModal}
      showImportSuccessful={showImportSuccessful}
    />
  ) : (
    <Modal onClose={onClickHide} closeOnOverlayClick>
      {importSuccessFul ? (
        <Content>
          <Icon>
            <span>
              <img src={ModalSuccessImage} />
            </span>
          </Icon>
          <Title>Import successful</Title>
          <Info>
            We are fetching and converting your product pages to content. Please
            check back in few minutes by navigating to Library-->Pending
            Content.
          </Info>
          <Button type="primary" onClick={hideShopifyModal}>
            Okay
          </Button>
        </Content>
      ) : authorizationSuccess == "true" ? (
        <Content>
          <Icon>
            <span>
              <img src={ModalSuccessImage} />
            </span>
          </Icon>
          <Title>Shopify store connected</Title>
          <Info>Continue to import and schedule content in MeetEdgar</Info>
          <Button type="primary" onClick={showShopifyModal}>
            Import content
          </Button>
        </Content>
      ) : (
        <Content>
          <Icon>
            <span className="error">
              <img src={ModalErrorImage} />
            </span>
          </Icon>
          <Title>Connection failed</Title>
          <Info>Continue to import and schedule content in MeetEdgar</Info>
        </Content>
      )}
    </Modal>
  );
};

export default ShopifyAuthentication;
