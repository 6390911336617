/* @flow */
import { useState } from "react";

const useNewHeader = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const showDropdown = () => setDropdownOpen(true);
  const hideDropdown = () => setDropdownOpen(false);

  return { dropdownOpen, showDropdown, hideDropdown };
};

export default useNewHeader;
