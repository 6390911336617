/* @flow */
import React from "react";
import { capitalize } from "util";
import Alert from "layout/Alert";
import Icon from "icons/Icon";

import styles from "./index.css";

type Props = {
  hasInstagram: boolean,
  hasPinterest: boolean
};

const renderSingleWarning = provider => (
  <Alert clear>
    <Icon type={provider} className={styles[`${provider}Icon`]} />
    {capitalize(provider)} requires an image to post, so it can't be selected
    for RSS feeds, which only import a link.
  </Alert>
);

export const ImageRequiredForRssAlert = ({
  hasInstagram,
  hasPinterest
}: Props) => {
  if (!hasInstagram && !hasPinterest) return null;
  if (!hasInstagram && hasPinterest) return renderSingleWarning("pinterest");
  if (hasInstagram && !hasPinterest) return renderSingleWarning("instagram");

  return (
    <Alert clear>
      <Icon type="instagram" className={styles.instagramIcon} />
      <Icon type="pinterest" className={styles.pinterestIcon} />
      Instagram and Pinterest require an image to post, so they can't be
      selected for RSS feeds, which only import a link.
    </Alert>
  );
};

export default ImageRequiredForRssAlert;
