// @flow
import React from "react";
import VideoThumbnailModal from "./VideoThumbnailModal";
import classNames from "classnames";
import styles from "./index.css";
import playButton from "./images/playButton.svg";

type Props = {
  videoUrl: string,
  className?: string
};
type State = {
  modalIsOpen: boolean
};

export default class VideoThumbnail extends React.Component<Props, State> {
  state = { modalIsOpen: false };

  handleClick = (): void => {
    this.setState({ modalIsOpen: true });
  };

  handleClose = (): void => {
    this.setState({ modalIsOpen: false });
  };

  render = () => {
    const { videoUrl, className } = this.props;
    const { modalIsOpen } = this.state;
    return (
      <div
        className={classNames(styles.videoContainer, {
          [className || ""]: !!className
        })}
        onClick={this.handleClick}
      >
        <video className={styles.thumbnail}>
          <source src={videoUrl} />
        </video>
        <div className={styles.thumbnailOverlay}>
          <img src={playButton} alt="Play video" className={styles.playIcon} />
        </div>
        {modalIsOpen && (
          <VideoThumbnailModal videoUrl={videoUrl} onClose={this.handleClose} />
        )}
      </div>
    );
  };
}
