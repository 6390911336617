/* @flow */
import React from "react";
import Icon from "icons/Icon";
import styles from "./index.css";

import type { ComposerMention } from "types";
import type { Provider } from "graphql-types/loadComposer";

type Props = {
  className: string,
  mention: ComposerMention,
  onMouseDown: () => void,
  onMouseUp: () => void,
  onMouseEnter: () => void,
  selectedProviders: Provider[]
};

export const MentionSuggestionsEntry = ({
  className,
  mention,
  onMouseDown,
  onMouseUp,
  onMouseEnter,
  selectedProviders
}: Props) => {
  if (mention.displayName === "edgarInfoEntry") {
    // eslint-disable-next-line max-len
    const text =
      "Facebook supports tagging only Pages when using third-party tools like Edgar.";
    return (
      <div className={styles.edgarInfoEntry}>
        <Icon type={"info-circle"} className={styles.infoIcon} />
        <span className={styles.infoTextContainer}>{text}</span>
      </div>
    );
  }

  const provider = selectedProviders[0];
  const isTwitter = provider === "TWITTER";
  const style = className.match("Focused") ? styles.rootFocused : styles.root;

  return (
    <div
      className={style}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
    >
      {mention.profileImage && (
        <div className={styles.imageContainer}>
          <img
            alt="Profile"
            src={mention.profileImage}
            className={styles.image}
          />
        </div>
      )}

      <div className={styles.textContainer}>
        <div className={styles.name}>
          {mention[isTwitter ? "title" : "displayName"]}
          &nbsp;
          {mention.verified && (
            <Icon type={"check-circle"} className={styles.checkCircle} />
          )}
        </div>

        <div className={styles.description}>
          {isTwitter ? `@${mention.displayName}` : mention.title}
        </div>
      </div>
    </div>
  );
};

//const mapStateToProps = (state: AppState) => ({
//  selectedProviders: selectSelectedAccountProviders(state)
//});
//
//const connector: Connector<OwnProps, Props> = connect(mapStateToProps);

export default MentionSuggestionsEntry;
