/* @flow */
import React from "react";
import ImageCarousel from "./ImageCarousel";
import Video from "./Video";
import type { loadContent_content_variations as VariationData } from "graphql-types/loadContent";

import styles from "./index.css";

type Props = {
  variation: VariationData
};

const Media = ({ variation: { images, videos } }: Props) => (
  <div className={styles.root}>
    {images.length > 0 && <ImageCarousel images={images} />}
    {videos.length > 0 && <Video video={videos[0]} />}
  </div>
);

export default Media;
