// @flow
import React from "react";
import Confirmation from "layout/modals/Confirmation";
import { Caption } from "typography";
import styles from "./index.css";

type MediaRejectionReasons = { [key: string]: string[] };

type Props = {
  title: string,
  subTitle: string,
  reasons?: string[],
  groupedReasons?: MediaRejectionReasons,
  groupedReasonsLabels?: [string, string],
  onAcknowledge: () => void
};

const RejectionModal = ({
  title,
  subTitle,
  reasons = [],
  groupedReasons = {},
  groupedReasonsLabels,
  onAcknowledge
}: Props) => (
  <Confirmation
    type="error"
    className={styles.root}
    size="medium"
    title={title}
    subTitle={subTitle}
    subTitleAlignment="left"
    confirmLabel="Close"
    onConfirm={onAcknowledge}
  >
    {!!reasons && (
      <ul className={styles.reasons}>
        {reasons.map(reason => (
          <li key={reason}>
            <Caption helpText>{reason}</Caption>
          </li>
        ))}
      </ul>
    )}
    {!!Object.keys(groupedReasons).length && (
      <div className={styles.groupedReasons}>
        {groupedReasonsLabels && (
          <div className={styles.groupedReasonsLabels}>
            <Caption helpText className={styles.key}>
              {groupedReasonsLabels[0]}
            </Caption>
            <Caption helpText className={styles.reason}>
              {groupedReasonsLabels[1]}
            </Caption>
          </div>
        )}
        {Object.keys(groupedReasons).map(key => (
          <div key={key} className={styles.group}>
            <Caption className={styles.key} title={key}>
              {key}
            </Caption>
            {groupedReasons[key].map((reason, i) => (
              <Caption key={`${reason}${i}`} helpText className={styles.reason}>
                {reason}
              </Caption>
            ))}
          </div>
        ))}
      </div>
    )}
  </Confirmation>
);

export default RejectionModal;
