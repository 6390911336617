/* @flow */
import React from "react";
import classNames from "classnames";
import styles from "./index.css";

// eslint-disable-next-line max-len
const text =
  "Tags can only be created for Twitter accounts. Facebook and LinkedIn do not support tagging by third party services at this time. Please remove all accounts except Twitter to create a tag.";

const MentionSuggestionsError = () => (
  <div className={styles.root}>
    <i className={classNames("fa", "fa-ban", styles.banIcon)} />
    <span className={styles.textContainer}>{text}</span>
  </div>
);

export default MentionSuggestionsError;
