// @flow
import { get } from "lodash";
import type { Provider } from "graphql-types/loadComposer";

export default (
  profileName: string,
  provider: ?Provider,
  rawRichTextEntityMap: ?Object
): ?string => {
  switch (provider) {
    case "TWITTER": {
      return `https://twitter.com/${profileName}`;
    }
    case "FACEBOOK_PAGE": {
      const entityMap = get(rawRichTextEntityMap, "entityMap");
      const pageSearchUrl = `https://www.facebook.com/search/pages/?q=${profileName}`;

      if (!entityMap) return pageSearchUrl;

      const profileNameToIdMap = {};

      Object.keys(entityMap).forEach(key => {
        const { name, id, provider: mentionProvider } = get(
          entityMap,
          `[${key}].data.mention`,
          {}
        );

        if (
          name &&
          id &&
          mentionProvider.toUpperCase().replace(" ", "_") === "FACEBOOK_PAGE"
        ) {
          profileNameToIdMap[name] = id;
        }
      });

      return profileNameToIdMap[profileName]
        ? `https://www.facebook.com/${profileName}-${
            profileNameToIdMap[profileName]
          }`
        : pageSearchUrl;
    }
    default:
      return null;
  }
};
