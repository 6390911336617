import React, { Component } from "react";
import moment from "moment-timezone";
import { Button } from "buttons";
import UpdateSendAtModal from "./UpdateSendAtModal";
import styles from "./index.css";

type Props = {
  sendAt: ?string,
  timeZone: string,
  showWarning: boolean,
  onChangeSendAt: (date: string) => void
};

type State = {
  originalSendAt: string,
  isModalOpen: boolean,
  sendAtWasInPastAtMount: boolean
};

export class SendAtNotice extends Component<Props, State> {
  state = {
    originalSendAt: this.props.sendAt,
    isModalOpen: false,
    sendAtWasInPastAtMount: this.props.sendAt
      ? moment()
          .tz(this.props.timeZone)
          .isAfter(moment(this.props.sendAt).tz(this.props.timeZone))
      : false
  };

  handleEditClick = () => {
    this.setState({ isModalOpen: true });
  };

  handleCancelModal = () => {
    this.props.onChangeSendAt(this.state.originalSendAt);
    this.setState({ isModalOpen: false });
  };

  handleRemoveSendAt = () => {
    this.props.onChangeSendAt(null);
    this.setState({ isModalOpen: false });
  };

  handleUpdateSendAt = () => {
    this.setState({ isModalOpen: false, originalSendAt: this.props.sendAt });
  };

  render() {
    const { sendAt, timeZone, showWarning, onChangeSendAt } = this.props;
    const { originalSendAt, sendAtWasInPastAtMount } = this.state;

    if (!sendAt || !originalSendAt || sendAtWasInPastAtMount) return null;

    const sendAtMoment = moment(sendAt).tz(timeZone);

    return (
      <div className={styles.root}>
        <span>
          Scheduled to send at <strong>{sendAtMoment.format("LT")}</strong> on{" "}
          <strong>{sendAtMoment.format("L")}</strong>.
          <Button type="secondary" onClick={this.handleEditClick}>
            Edit
          </Button>
        </span>
        {this.state.isModalOpen && (
          <UpdateSendAtModal
            sendAt={sendAt}
            timeZone={timeZone}
            showWarning={showWarning}
            onChangeSendAt={onChangeSendAt}
            onCancel={this.handleCancelModal}
            onRemoveSendAt={this.handleRemoveSendAt}
            onUpdateSendAt={this.handleUpdateSendAt}
          />
        )}
      </div>
    );
  }
}

export default SendAtNotice;
