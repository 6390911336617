/* @flow */
import React, { useState } from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Label from "typography/Label";
import Select from "components/common/CategorySelect";

import styles from "./index.css";

import type {
  bulkContentUpdate as BulkContentUpdateResult,
  bulkContentUpdateVariables,
  ContentsFilter
} from "graphql-types/bulkContentUpdate";
import type { loadComposer_categories as CategoryData } from "graphql-types/loadComposer";

type Props = {
  bulkContentUpdate?: ({
    variables: bulkContentUpdateVariables
  }) => Promise<{ data: BulkContentUpdateResult }>,
  close: () => void,
  failUpdate: () => void,
  filter: ContentsFilter,
  initUpdate: () => void,
  isSelectAll: boolean,
  selected: string[],
  startUpdate: () => void,
  unselected: string[],
  updateState: string
};

const CategoryEdit = (props: Props) => {
  const [category, selectCategory] = useState<?CategoryData>(null);
  return (
    <Modal
      footer={
        <>
          <Button
            className={styles.buttonCancel}
            disabled={props.updateState !== "None"}
            onClick={props.close}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={!Boolean(category?.id) || props.updateState !== "None"}
            onClick={async (): Promise<void> => {
              const bulkContentUpdate = props.bulkContentUpdate;
              if (!bulkContentUpdate) {
                return;
              }

              try {
                props.initUpdate();

                const resp = await bulkContentUpdate({
                  variables: {
                    input: {
                      action: "category_id",
                      categoryId: category?.id,
                      filter: props.filter,
                      isSelectAll: props.isSelectAll,
                      selected: props.selected,
                      unselected: props.unselected
                    }
                  }
                });

                const errors = resp?.data?.bulkContentUpdate?.errors || [];
                if (errors.length > 0) {
                  props.failUpdate();
                } else {
                  props.startUpdate();
                }
              } catch (_err) {
                props.failUpdate();
              }
            }}
            type="primary"
          >
            Apply
          </Button>
        </>
      }
      onClose={props.close}
      size="large"
      title="Edit category"
    >
      <div className={styles.main}>
        <Label>Select category</Label>
        <small className={styles.sectionFieldDescription}>
          All selected content items will become this category.
        </small>
        <Select
          alwaysTriggerOnClickOption
          className={styles.sectionFieldInput}
          disableDefaultCategory
          id="category"
          onChange={value => {
            selectCategory(value);
          }}
          value={category || {}}
        />
      </div>
    </Modal>
  );
};

export default CategoryEdit;
