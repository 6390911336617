/* @flow */
import React from "react";

import Icon from "icons/Icon";
import Link from "links/Link";
import Button from "buttons/Button";

import useNewHeader from "./use";
import type { IProps } from "./types.js";
import {
  Wrapper,
  Title,
  BetaText,
  Right,
  // PlusBtn,
  User,
  DropdownWrapper,
  Alert,
  AddContent
} from "./styles";

import IconDropdown from "./dropdown.svg";
// import IconPlus from "./plus.svg";

import UserMenu from "./UserMenu";

const NewHeader = (props: IProps) => {
  const {
    title,
    beta,
    isAdmin,
    companyStatus,
    helpUrl,
    mb,
    subtitle,
    hideAddNew,
    titleContent
  } = props;
  const { dropdownOpen, showDropdown, hideDropdown } = useNewHeader();

  return (
    <Wrapper className={mb ? "marginBottom" : ""}>
      <Title>
        {title && (
          <>
            <h1>{title}</h1>
            {helpUrl && (
              <Link className="helpLink" url={helpUrl} target="_blank">
                <Icon type="question-circle" />
              </Link>
            )}
            {beta && <BetaText>Beta</BetaText>}
            {subtitle && <h4>{subtitle}</h4>}
            {titleContent && <>{titleContent}</>}
          </>
        )}
      </Title>

      <Right>
        <AddContent className="desktop">
          {(companyStatus?.hasAccess && hideAddNew !== true) || companyStatus?.planStatus ? (
            <Button url="/composer" type="primary">
              Add new content
            </Button>
          ) : null}
        </AddContent>
        <User onClick={showDropdown}>
          {/* <img
            src="https://www.fillmurray.com/100/100"
            className="profile"
            alt="profile picture"
          /> */}
          <div className="pictureLetter">
            {props.userName ? props.userName.substring(0, 1) : "/"}
            {companyStatus?.errorCount > 0 && (
              <Alert>
                <Link url="/errors">
                  <Icon type="exclamation-circle" />
                </Link>
              </Alert>
            )}
          </div>
          <img src={IconDropdown} className="dropdown" alt="dropdown icon" />
        </User>
        <DropdownWrapper>
          <UserMenu
            open={dropdownOpen}
            onClose={hideDropdown}
            isAdmin={isAdmin}
            companyStatus={companyStatus}
          />
        </DropdownWrapper>
      </Right>

      <AddContent>
        {companyStatus?.hasAccess && hideAddNew !== true ? (
          <Button url="/composer" type="primary">
            Add new content
          </Button>
        ) : null}
      </AddContent>
    </Wrapper>
  );
};

export default NewHeader;
