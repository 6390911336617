/* @flow */
import React from "react";
import Select from "../common/Select";

const options = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" }
];

export const Posted = () => (
  <Select
    label="Has publishable variations"
    filterKey="hasPublishableVariations"
    options={options}
  />
);

export default Posted;
