/* @flow */
import React from "react";
import classNames from "classnames";
import { uniq, get, filter } from "lodash";
import Skeleton from "react-loading-skeleton";

import Layout from "./Layout";
import ActionBar from "../common/ActionBar";
import CategoryBar from "../common/CategoryBar";
import VariationText from "../common/VariationText";
import Thumbnail from "components/Thumbnail";
import { Body } from "components/common/typography";
import LinkPreview from "links/LinkPreview";
import LinkPreviewHint from "../common/LinkPreviewHint";
import SocialMediaIcon from "icons/SocialMediaIcon";
import styles from "./index.css";

import type { getContents_contents_data as ContentData } from "graphql-types/getContents";

type Props = {
  content: ?ContentData,
  hideButtons?: boolean,
  timezone: ?string
};

export const FullContent = ({ content, timezone, hideButtons }: Props) => {
  const id = content?.id;
  const category = content?.category;
  const status = content?.status;
  const queueable = content?.queueable;
  const accounts = (content?.accountRelationships ?? []).map(
    ({ account }) => account
  );
  const variations = content?.variations;

  const classes = classNames(styles.root, {
    [styles.unqueueable]: !queueable
  });

  let platforms = [];
  if (accounts) {
    platforms = uniq(accounts.map(a => a.platform));
  }

  const LOADING_STATE_COUNT = 3;
  const pinterestAccounts = filter(
    accounts,
    account => account.provider === "PINTEREST"
  );
  const pinterestAccountsTotal = pinterestAccounts.length;

  const pinterestAccountIsPresent = !!platforms.filter(p => {
    return p === "PINTEREST";
  }).length;

  const facebookAccountIsPresent = !!platforms.filter(p => {
    return p === "FACEBOOK";
  }).length;

  const linkedInAccountIsPresent = !!platforms.filter(p => {
    return p === "LINKEDIN";
  }).length;

  return (
    <div className={classes}>
      <div className={styles.body}>
        <Layout content={content} timezone={timezone}>
          {(variations || Array.from(new Array(LOADING_STATE_COUNT))).map(
            (variation, idx) => {
              const expiredTwitterVariationCaption = get(
                variation,
                "lastPostedTo"
              ) &&
                variation.lastPostedTo.twitter && (
                  <div
                    key={`expired-twitter-${variation.id}`}
                    className={styles.expiredTitle}
                  >
                    expired for Twitter
                  </div>
                );

              const expiredPinterestVariationCaption = !!pinterestAccountsTotal &&
                get(variation, "reachedPostingLimit") &&
                filter(
                  pinterestAccounts,
                  account => !!variation.reachedPostingLimit[account.id]
                ).length === pinterestAccountsTotal && (
                  <div
                    key={`expired-pinterest-${variation.id}`}
                    className={styles.expiredTitle}
                  >
                    expired for Pinterest
                  </div>
                );

              return (
                <div key={idx} className={styles.textContainer}>
                  <div className={styles.variationTitle}>
                    {variation ? (
                      <>
                        <div>{`Variation ${idx + 1}`}</div>
                        {expiredTwitterVariationCaption ||
                          expiredPinterestVariationCaption}{" "}
                      </>
                    ) : (
                      <Skeleton width={300} />
                    )}
                  </div>

                  {variation ? (
                    <>
                      <div className={styles.variationContentContainer}>
                        {((variation.images && !!variation.images.length) ||
                          variation.videos) && (
                          <div className={styles.thumbnailContainer}>
                            <Thumbnail
                              contentId={id}
                              images={variation.images}
                              video={
                                variation.videos && variation.videos.length > 0
                                  ? variation.videos[0]
                                  : null
                              }
                              videos={variation?.videos || []}
                              queueable={queueable}
                            />
                          </div>
                        )}
                        <VariationText
                          variation={variation}
                          accounts={accounts}
                        />
                      </div>

                      <div className={styles.linkPreviewContainer}>
                        {/* LinkPreview is currently only supported by LI and FB */}
                        {!!accounts.length &&
                        (facebookAccountIsPresent ||
                          linkedInAccountIsPresent) &&
                        variation?.pageScrape?.url ? (
                          <LinkPreview
                            pageScrape={variation.pageScrape}
                            platforms={platforms}
                          />
                        ) : null}
                        {/* LinkPreviewHint appears to be specific only to Facebook; only show if an fb
                              account is present */}
                        {!!accounts.length &&
                        facebookAccountIsPresent &&
                        variation.pageScrape &&
                        !variation.pageScrape.url ? (
                          <LinkPreviewHint link={variation.link || ""} />
                        ) : null}
                      </div>

                      {!!accounts.length &&
                      (variation?.pinterestDestinationLink ||
                        variation.pinterestTitle) &&
                      pinterestAccountIsPresent ? (
                        <div className={styles.pinterestDetailContainer}>
                          <div className={styles.destinationLink}>
                            <SocialMediaIcon
                              className={styles.pinterestIcon}
                              value="pinterest"
                              tiny
                            />
                          </div>
                          <div className={styles.pinterestTitleAndLink}>
                            {variation.pinterestTitle && (
                              <Body>Pin title: {variation.pinterestTitle}</Body>
                            )}
                            {variation.pinterestDestinationLink && (
                              <Body>
                                Destination link:{" "}
                                <a
                                  href={variation.pinterestDestinationLink}
                                  target="_blank"
                                >
                                  {variation.pinterestDestinationLink}
                                </a>
                              </Body>
                            )}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Skeleton width={500} />
                      <Skeleton width={500} />
                    </>
                  )}
                </div>
              );
            }
          )}
        </Layout>
      </div>
      {!hideButtons && (
        <ActionBar contentId={id} status={status} category={category} />
      )}
      {!!hideButtons && (
        <CategoryBar contentId={id} status={status} category={category} />
      )}
    </div>
  );
};

export default FullContent;
