// @flow
import React from "react";
import Icon from "../Icon";

type Props = {
  className?: string
};

const LoadingIcon = ({ className = "" }: Props) => (
  <Icon className={className} type="spinner" animation="spin" />
);

export default LoadingIcon;
