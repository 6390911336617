/* @flow */
import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import colors from "colors";
import styles from "./index.css";

const STRIPE_ELEMENT_STYLE = {
  base: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: "15px",
    fontWeight: 300,
    // Had some trouble using inky400 here. This color looks pretty close.
    "::placeholder": {
      color: "rgb(122,122,122)"
    }
  },
  invalid: {
    iconColor: colors.coral600.toString(),
    color: colors.coral600.toString()
  }
};

type Props = {
  stripe: Object,
  onComplete: () => void,
  onIncomplete: () => void
};

type State = {
  errorMessage: ?string,
  complete: boolean
};

class StripeCardElement extends Component<Props, State> {
  state = { errorMessage: null, complete: false };

  handleChange = evt => {
    if (evt.error) {
      this.setState({ errorMessage: evt.error.message });
    } else if (this.state.errorMessage) {
      this.setState({ errorMessage: null });
    }

    if (evt.complete !== this.state.complete) {
      this.setState({ complete: evt.complete }, () => {
        evt.complete ? this.props.onComplete() : this.props.onIncomplete();
      });
    }
  };

  getToken = async () => {
    let token = null;
    try {
      const res = await this.props.stripe.createToken();
      if (res.error) {
        this.setState({ errorMessage: res.error.message });
        return null;
      }
      token = res.token;
    } catch (e) {
      this.setState({ errorMessage: e.message });
      return null;
    }
    return token;
  };

  render() {
    return (
      <div>
        <div
          className={styles.cardElementContainer}
          id="stripe-iframe-container"
        >
          <CardElement
            style={STRIPE_ELEMENT_STYLE}
            onChange={this.handleChange}
          />
        </div>
        <div className={styles.errorMessage}>{this.state.errorMessage}</div>
      </div>
    );
  }
}

export default injectStripe(StripeCardElement, { withRef: true });
