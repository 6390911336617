/* @flow */
import React from "react";
import { Link } from "components/common/links";
import { Caption } from "components/common/typography";

type Props = {
  description: string,
  addtlInfoUrl: string,
  addtlInfoText: string,
  handleInfoLinkClick: EventHandler
};

export const ConnectionFooter = ({
  description,
  addtlInfoUrl,
  addtlInfoText,
  handleInfoLinkClick
}: Props) => {
  return (
    <Caption>
      {description}{" "}
      {addtlInfoUrl && (
        <Link href={addtlInfoUrl} onClick={handleInfoLinkClick}>
          {addtlInfoText}
        </Link>
      )}
    </Caption>
  );
};

export default ConnectionFooter;
