import styled from "styled-components";

export const Wrapper = styled.div`
  input {
    background-color: #f0f0f0;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    max-width: 80px;
    margin: 0 0 0 10px;
    padding: 7px 8px;

    &:focus,
    &:active {
      border-color: #e9e9e9;
      outline: none;
    }
  }
`;
