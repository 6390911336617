// @flow
import React from "react";
import { PLATFORMS_SUPPORTING_LINK_PREVIEWS, SCRAPE_STATUSES } from "constants";
import LinkPreview from "links/LinkPreview";
import { CloseButton } from "buttons";
import { Caption } from "typography";
import { SocialMediaIcon, LoadingIcon } from "icons";
import ValidationErrorList from "layout/ValidationErrorList";
import AttachmentContainer from "../../common/AttachmentContainer";
import styles from "./index.css";

import type { ScrapedPage, AccountPlatform } from "types";

const SCRAPE_STATUS_POSTFIX =
  "You can still use the link for attachments, just triple check that it's correct!";

const {
  BAD_URL,
  NO_CONTENT,
  SERVER_ERROR,
  FORBIDDEN,
  PAGE_NOT_FOUND,
  UNSUPPORTED_MIME_TYPE,
  TIMED_OUT,
  UNKNOWN_ERROR
} = SCRAPE_STATUSES;

const STATUS_COPY_MAP = {
  [BAD_URL]: `Hmmm. Looks like that particular URL doesn't want to play
              friendly with Edgar.`,
  [NO_CONTENT]: `Edgar found the page, but there was nothing there his little
                 suction cuppies could grab!`,
  [SERVER_ERROR]: `That URL returned a server error. Probably not what you had in mind,
                   but it may be temporary - trying again in a bit could help.`,
  [FORBIDDEN]: `Uh oh, that web site has a firewall that is refusing to talk to Edgar.
                Please contact the site owners and ask them to let him through.`,
  [PAGE_NOT_FOUND]: `How anticlimactic - Edgar couldn\'t find a page at that URL!
                     Double check that it's the right address?`,
  [UNSUPPORTED_MIME_TYPE]: `Edgar only knows how to investigate HTML pages and that URL points
                            to a different type of file.`,
  [TIMED_OUT]: `That URL is taking too long for Edgar to read. Trying again in a bit could help.`,
  [UNKNOWN_ERROR]:
    "Something unexpected happened while Edgar was checking out that page :("
};

const getCopy = status => {
  if (!status) return null;
  return STATUS_COPY_MAP[status];
};

type Props = {
  platforms: AccountPlatform[],
  pageScrape: ScrapedPage,
  onClose: () => void
};

const LinkPreviewAttachment = ({ platforms, pageScrape, onClose }: Props) => {
  const { status } = pageScrape;
  const statusCopy = getCopy(status);
  const scrapeErrorMessage = statusCopy
    ? `${statusCopy} ${SCRAPE_STATUS_POSTFIX}`
    : null;
  return (
    <AttachmentContainer>
      <div className={styles.content}>
        <Caption helpText className={styles.header}>
          {platforms
            .filter(platform =>
              PLATFORMS_SUPPORTING_LINK_PREVIEWS.includes(platform)
            )
            .map(platform => (
              <SocialMediaIcon
                tiny
                value={platform.toLowerCase()}
                key={platform}
              />
            ))}
          <span className={styles.url} title={pageScrape.url}>
            link preview: {pageScrape.url}
          </span>
        </Caption>
        <CloseButton onClick={onClose} />
        {!pageScrape.status || pageScrape.status === SCRAPE_STATUSES.WAITING ? (
          <div className={styles.loadingIconContainer}>
            <LoadingIcon />
          </div>
        ) : (
          <>
            <LinkPreview pageScrape={pageScrape} unstyled omitDestination />
            {!!scrapeErrorMessage && (
              <ValidationErrorList
                errors={[
                  {
                    message: scrapeErrorMessage
                  }
                ]}
              />
            )}
          </>
        )}
      </div>
    </AttachmentContainer>
  );
};

export default LinkPreviewAttachment;
