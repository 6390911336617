/* @flow */
import { useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import CREATE_COMMENT from "mutations/createComment";

import type { IProps } from "./types.js";

const useFooter = (props: IProps) => {
  const apolloClient = useApolloClient();

  const [newComment, setNewComment] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleChange = (e: any) => {
    setError("");
    setNewComment(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.key == "Enter" || e.keyCode == 13) {
      createComment();
    }
  };

  const createComment = async () => {
    setError("");
    if (newComment.length > 8000) return false;
    if (!newComment.trim().length || loading) return false;
    setLoading(true);

    try {
      const res: any = await apolloClient.mutate({
        mutation: CREATE_COMMENT,
        variables: {
          postId: parseInt(props.post.id),
          commentBody: newComment,
          commentId: props.post.providerUid
        }
      });

      if (res.data.createComment.id) {
        props.afterCommentCreate(res.data.createComment.id, newComment);
        setNewComment("");
      } else if (res.data.createComment.error) {
        setError(res.data.createComment.error);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return {
    newComment,
    setNewComment,
    handleChange,
    loading,
    createComment,
    handleKeyPress,
    error
  };
};

export default useFooter;
