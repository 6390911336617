/* @flow */
import moment from "moment";

import type { IProps } from "./types.js";

const useSendOn = (props: IProps) => {
  const handleChange = (date: any) => {
    props.onChange(moment(date).format("YYYY-MM-DD"));
  };

  return { handleChange };
};

export default useSendOn;
