import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  border-top: 1px solid #eaeaea;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
  display: flex;
  flex-direction: column;
  height: 76px;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex: 2;
  width: 100%;

  ${({ isLoading }) => isLoading && "opacity: 0.5;"}
`;

export const Error = styled.div`
  color: #f44336;
  flex: 1;
  font-size: 12px;
  font-style: italic;
  height: 18px;
  margin: -5px 0 2px 0;
  padding: 0 0 0 72px;
  width: 100%;
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0 15px;
`;

export const Icon = styled.div`
  align-items: center;
  background-color: #4d948f;
  border-radius: 50%;
  display: flex;
  height: 38px;
  justify-content: center;
  width: 38px;

  img {
    height: 16px;
    width: 20px;
  }
`;

export const Input = styled.div`
  flex: 2;

  input {
    background-color: #f0f0f0;
    border: 1px solid transparent;
    border-radius: 30px;
    height: 38px;
    padding: 10px 20px;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: 0 0 12px rgba(77, 148, 143, 0.15);
      border-color: #ececec;
    }
  }
`;

export const Send = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 10px;
  max-width: 28px;

  svg {
    height: 26px;
    width: 26px;
  }

  ${({ active }) =>
    active === false &&
    `
    svg {
      opacity: 0.3;
    }
  `}
`;
