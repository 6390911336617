// @flow
import React, { Component } from "react";
import classNames from "classnames";
import createEmojiPlugin from "draft-js-emoji-plugin";

import ErrorMessageList from "layout/ErrorMessageList";
import TextInput from "forms/TextInput";
import ButtonRow from "./ButtonRow";
import BlurredInput from "./BlurredInput";
import PostEditor from "./PostEditor";
import OnboardingTooltip from "components/common/OnboardingTooltip";
import TopSection from "./TopSection";
import styles from "./index.css";
import { reportException } from "errors";

import type {
  loadContent_content_variations as VariationData,
  Provider,
  Platform
} from "graphql-types/loadContent";
import type { createMentionTagMap_createMentionTagMap_mentionTagMap as MentionTagMapData } from "graphql-types/createMentionTagMap";

type RemainingCharacterCounts = { [key: string]: number };
type Props = {
  value: VariationData,
  selectedPlatforms: Platform[],
  selectedProviders: Provider[],
  className: string,
  fieldName: string,
  mentionSuggestions: MentionTagMapData[],
  isWidget: boolean,
  focused: boolean,
  fieldFocused: boolean,
  fbTextVisible: boolean,
  hasErrors: boolean,
  hasWarnings: boolean,
  remainingCharacterCounts: ?RemainingCharacterCounts,
  errors: string[],
  onNextOnboardingStep: () => void,
  showOnboardingTooltip: boolean,
  isConnectAccountsModalOpen: boolean,
  autogenerateVariationsPristine: boolean,
  onClearMentionSuggestions: () => void,
  onChange: (text: string) => void,
  onAddVariation: (evt: Event) => void,
  onAddMention: (mentionTagMap: Object) => void,
  onMentionSuggestionWarning: (value: string) => void,
  onEntityMapChange: (entityMap: Object) => void,
  onSearchSocialMedia: (provider: Provider, query: string) => void,
  onFocus: () => void,
  onBlur: () => void
};

const TOOLTIP_BODY = "Add the text and links for your post here.";
const emojiPlugin = createEmojiPlugin({
  useNativeArt: true,
  selectButtonContent: "🙂"
});

export class TextField extends Component<Props> {
  componentDidMount() {
    if (this.props.focused && !this.props.isConnectAccountsModalOpen) {
      // HACK: If the <PostEditor /> is given focus immediately then mentions don't work
      // (╯°□°）╯︵ ┻━┻
      setTimeout(() => {
        if (this.input?.editor) {
          try {
            this.input.focus();
          } catch (e) {
            reportException(e, "NewComposer:TextField");
          }
        }
      }, 50);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const {
      focused,
      fieldFocused,
      showOnboardingTooltip,
      value: { text },
      onNextOnboardingStep
    } = this.props;

    if (!prevProps.focused && focused && fieldFocused) {
      setTimeout(() => {
        if (this.input?.editor) {
          this.input.focus();
        }
      }, 50);
    }

    if (showOnboardingTooltip && text?.length) {
      onNextOnboardingStep();
    }
  }

  input: any;

  setInput = (node: any) => {
    this.input = node;
  };

  renderBlurredInput = () => (
    <div className={styles.blurredInputContainer}>
      <BlurredInput
        value={this.props.value.text || ""}
        rawRichTextEntityMap={this.props.value.rawRichTextEntityMap}
        decorateMentions={!this.props.fbTextVisible}
        onClick={this.props.onFocus}
      />
    </div>
  );

  renderSimpleInput = () => (
    <TextInput
      id={this.props.fieldName}
      ref={node => this.setInput(node)}
      value={this.props.value.text}
      multiline
      autogrow
      monitorExternalChanges
      clear
      rows={5}
      onChange={this.props.onChange}
      onBlur={this.props.onBlur}
    />
  );

  renderMentionableInput = () => (
    <PostEditor
      id={this.props.fieldName}
      value={this.props.value.text ?? ""}
      selectedProviders={this.props.selectedProviders}
      inputRef={node => this.setInput(node)}
      rawRichTextEntityMap={this.props.value.rawRichTextEntityMap}
      emojiPlugin={emojiPlugin}
      mentionSuggestions={this.props.mentionSuggestions}
      isWidget={this.props.isWidget}
      onClearMentionSuggestions={this.props.onClearMentionSuggestions}
      onEntityMapChange={this.props.onEntityMapChange}
      onSearchSocialMedia={this.props.onSearchSocialMedia}
      onAddMention={this.props.onAddMention}
      onMentionSuggestionWarning={this.props.onMentionSuggestionWarning}
      onChange={this.props.onChange}
      onBlur={this.props.onBlur}
    />
  );

  renderInput = () => {
    let input;
    if (!this.props.focused) {
      input = this.renderBlurredInput();
    } else if (this.props.fbTextVisible) {
      input = this.renderSimpleInput();
    } else {
      input = this.renderMentionableInput();
    }
    return input;
  };

  render() {
    const {
      value,
      focused,
      fbTextVisible,
      hasErrors,
      hasWarnings,
      showOnboardingTooltip,
      errors,
      className,
      fieldName,
      selectedPlatforms,
      remainingCharacterCounts,
      onAddVariation
    } = this.props;

    const classes = classNames(styles.root, className, {
      [styles.blurred]: !focused,
      [styles.hasTopSection]: fbTextVisible,
      [styles.hasWarnings]: !hasErrors && hasWarnings,
      [styles.hasErrors]: hasErrors
    });

    return (
      <div className={classes}>
        {showOnboardingTooltip && (
          <OnboardingTooltip tooltipBody={TOOLTIP_BODY} />
        )}
        {fbTextVisible && (
          <TopSection
            fieldName={fieldName}
            selectedPlatforms={selectedPlatforms}
            remainingCharacterCounts={remainingCharacterCounts}
          />
        )}
        {this.renderInput()}
        {focused && (
          <div>
            <ErrorMessageList className={styles.errorList} errors={errors} />
            {
              // https://trello.com/c/p8N7E7M0/1629-add-data-sniply-target-input-to-composer-textarea
            }
            <div className={styles.sniplyTarget} data-sniply="target-input" />

            {!fbTextVisible && (
              <ButtonRow
                value={value}
                emojiPlugin={emojiPlugin}
                onAddVariation={onAddVariation}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default TextField;
