/* @flow */
import React, { Component } from "react";
import Endorsement from "./Endorsement";
import Pager from "./Pager";
import styles from "./index.css";
import { ENDORSEMENTS } from "./copy.js";

import tentacle from "../../../components/common/images/Tentacle.svg";
import wave from "../../../components/common/images/Wave.svg";

type Props = {};

export const ENDORSEMENT_ROTATION_PERIOD_MS = 8000;

type State = { activeIndex: number, timerId: ?number };

export default class Testimonials extends Component<Props, State> {
  state = { activeIndex: 0, timerId: null };

  componentWillMount() {
    const timerId = window.setInterval(
      this.rotateEndorsement,
      ENDORSEMENT_ROTATION_PERIOD_MS
    );
    this.setState({ timerId });
  }

  rotateEndorsement = () => {
    let { activeIndex } = this.state;
    activeIndex = (activeIndex + 1) % ENDORSEMENTS.length;
    this.setState({ activeIndex });
  };

  handlePagerClicked = (activeIndex: number) => {
    const { timerId } = this.state;
    window.clearInterval(timerId);
    this.setState({ activeIndex });
  };

  render() {
    const { activeIndex } = this.state;
    return (
      <div className={styles.root} role="marquee" aria-live="off">
        <div className={styles.wrapper}>
          {ENDORSEMENTS.map((endorsement, index) => {
            const active = index === activeIndex;
            return (
              <Endorsement
                endorsement={endorsement}
                key={index}
                active={active}
              />
            );
          })}
        </div>
        <div className={styles.pager}>
          <Pager
            activeIndex={activeIndex}
            endorsements={ENDORSEMENTS}
            onClick={this.handlePagerClicked}
          />
        </div>
        <img
          src={wave}
          alt="Wave"
          role="presentation"
          className={styles.wave}
        />
        <img
          src={tentacle}
          alt="Tentacle"
          role="presentation"
          className={styles.tentacle}
        />
      </div>
    );
  }
}
