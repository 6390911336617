/* @flow */
import React from "react";
import { Button, DropDownButton } from "buttons";

type Props = {
  disableSaveButton: ?boolean,
  postsLimitReached: ?Boolean,
  options: Array<Object>,
  disabled: boolean,
  loading: boolean,
  label: string,
  type: string
};

const ActionsButton = ({ disableSaveButton, postsLimitReached, options, disabled, loading, label, type }: Props) => {
  return (
    <>
      {options.length === 1 ? (
        <Button
          disableSaveButton={disableSaveButton}
          postsLimitReached={postsLimitReached}
          type={type}
          disabled={disabled}
          loading={loading}
          onClick={options[0].onClick}
        >
          {options[0].children}
        </Button>
      ) : (
        <DropDownButton disableSaveButton={disableSaveButton} postsLimitReached={postsLimitReached} label={label} type={type} disabled={disabled}>
          {options.map((option, i) => (
            <div type={type} key={i.toString()} onClick={option.onClick}>
              {option.children}
            </div>
          ))}
        </DropDownButton>
      )}
    </>
  );
};

export default ActionsButton;
