/* @flow */
import React, { type Node } from "react";
import classnames from "classnames";
import styles from "./index.css";

type Props = {
  children?: Node,
  className?: string
};

const Table = ({ children, className }: Props) => (
  <table className={classnames(styles.root, className)}>{children}</table>
);

export default Table;
