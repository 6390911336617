/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query instagramPostComments($postId: Int!, $cursor: String) {
    instagramPostComments(postId: $postId, cursor: $cursor) {
      comments {
        cursor
        from {
          username
          id
        }
        id
        likeCount
        replies {
          text
          timestamp
          from {
            id
            username
          }
          id
          likeCount
        }
        text
        timestamp
      }
      cursor
      error
    }
  }
`;
