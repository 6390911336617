/* @flow */
import React from "react";
import { Icon } from "icons";
import { Link } from "links";
import { Body } from "typography";
import { ORDERED_STEPS, STEP_COPY } from "../constants.js";
import styles from "./index.css";

import type { OnboardingStatus } from "../selectOnboardingStatus";

export type Props = {
  onboardingStatus: OnboardingStatus
};

const makeCopy = ({
  locationStatus,
  currentStep,
  currentStepIndex,
  currentStepUrl
}) => {
  if (locationStatus === "onCurrentStep") {
    if (!currentStep || currentStep === "importContent") {
      return null;
    }
    return (
      <Body className={styles.text}>
        {`${currentStepIndex + 1}. ${STEP_COPY[currentStep]}`}
      </Body>
    );
  }

  if (locationStatus === "onLastCompletedStep") {
    return (
      <span className={styles.textWrapper}>
        <Icon className={styles.checkIcon} type="check" />
        <Body className={styles.text}>
          {STEP_COPY[ORDERED_STEPS[currentStepIndex - 1]]}
        </Body>
      </span>
    );
  }

  // offTrack
  return (
    <span className={styles.textWrapper}>
      <Body className={styles.text}>Take a look around, then&nbsp;</Body>
      <Link className={styles.returnLink} href={currentStepUrl}>
        return to step {currentStepIndex + 1}
      </Link>
    </span>
  );
};

const StepInstruction = ({
  onboardingStatus,
  onboardingStatus: { currentStepUrl, locationStatus }
}: Props) => {
  return (
    <div className={styles.root}>
      {makeCopy(onboardingStatus)}
      <Link
        className={styles.nextStepLink}
        href={currentStepUrl}
        disabled={locationStatus !== "onLastCompletedStep"}
      >
        <Icon type="arrow-circle-right" className={styles.nextStepIcon} />
      </Link>
    </div>
  );
};

export default StepInstruction;
