/* @flow */
import React from "react";
import sortBy from "lodash/sortBy";
import { LoadingIcon } from "icons";
import { Caption } from "typography";
import Select, { type OptionData } from "forms/Select";
import styles from "./index.css";

import type { loadComposer_accounts_pinterestBoards as PinterestBoardData } from "graphql-types/loadComposer";

type Props = {
  boards: PinterestBoardData[],
  selectedBoardId: ?string,
  isLoading: boolean,
  error: ?Error,
  onSelect: (pinterestBoardId: string) => void
};

export const BoardSelect = ({
  boards,
  selectedBoardId,
  isLoading,
  error,
  onSelect
}: Props) => {
  if (isLoading) {
    return <LoadingIcon />;
  }

  if (error) {
    return <Caption error>{error.message}</Caption>;
  }

  if (boards.length === 0) {
    return (
      <Caption error>This pinterest account doesn't have any boards.</Caption>
    );
  }

  const handleSelect = ({ value: boardId }: OptionData) => {
    onSelect(boardId);
  };
  const options = sortBy(boards, ({ name }) => name.toLowerCase()).map(
    ({ id, name }) => ({
      value: id,
      label: name
    })
  );
  const selectedOption = options.find(o => o.value === selectedBoardId) ?? null;

  return (
    <>
      <Select
        className={styles.select}
        placeholder="Select board…"
        value={selectedOption}
        options={options}
        hasError={!selectedBoardId}
        onChange={handleSelect}
      />
      {!selectedBoardId && (
        <Caption error textAlign="right">
          Required
        </Caption>
      )}
    </>
  );
};

export default BoardSelect;
