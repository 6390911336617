/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getUserProfile {
    user {
      id
      firstName
      lastName
      email
      phone
      sendPerformanceReport
      role
      company {
        id
        name
        timeZone
        address1
        address2
        city
        region
        zip
        country
      }
    }
  }
`;
