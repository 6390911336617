// @flow
import React from "react";
import { omit, get } from "lodash";
import classNames from "classnames";
import AccountProviderIcon from "../AccountProviderIcon";
import AccountAvatar from "images/AccountAvatar";
import Caption from "typography/Caption";
import tooltipper from "behavioral/tooltipper";
import Skeleton from "react-loading-skeleton";
import styles from "./index.css";

import type { Account } from "types";

type Props = {
  value: ?Account,
  size: "small" | "medium",
  tooltipPostfix?: string,
  className?: string
};

export const AccountAvatarWithBadge = (props: Props) => {
  const { value, className, size, ...others } = props;
  const image = get(value, "image");
  const provider = get(value, "provider");
  const rootClasses = classNames(
    styles.root,
    className,
    styles[size || "small"]
  );

  return (
    <div className={rootClasses} {...omit(others, ["tooltipPostfix"])}>
      {image ? (
        <>
          <AccountAvatar className={styles.avatar} src={image} alt={provider} />
          <AccountProviderIcon
            provider={provider}
            className={styles.platformIcon}
          />
        </>
      ) : (
        <div className={styles.avatar}>
          <Skeleton width={24} height={24} />
        </div>
      )}
    </div>
  );
};

export const TipBody = ({ value, tooltipPostfix }: Props) => {
  if (!value) {
    return null;
  }

  const { name, nickname = "", providerDisplayName } = value;

  return (
    <div className={styles.tipBody}>
      <Caption className={styles.tipCaption}>{name || `@${nickname}`}</Caption>
      <Caption className={styles.tipCaption}>
        {nickname ? `@${nickname}` : providerDisplayName}
      </Caption>
      <Caption className={styles.tipCaption}>{tooltipPostfix}</Caption>
    </div>
  );
};

export default tooltipper(TipBody, { maxWidth: null })(AccountAvatarWithBadge);
