/* @flow */
import React from "react";
import filter from "lodash/filter";
import AccountAvatarWithBadge from "icons/AccountAvatarWithBadge";
import Exhausted, {
  TWITTER_DELETED_POST,
  getPinterestLimitMessage,
  getTwitterExhaustedMessage
} from "./Exhausted";
import styles from "./index.css";
import type { getContents_contents_data as ContentData } from "graphql-types/getContents";

const LOADING_COUNT = 2;

type Props = {
  className?: string,
  content: ?ContentData,
  exhaustedDisplay?: "Alert" | "Inline"
};

function tooltipPostfix(account, content, pinterestBoards) {
  if (!account) {
    return null;
  }

  if (account.provider === "PINTEREST") {
    const boardNames = (pinterestBoards || [])
      .filter(b => b.account.id === account.id)
      .map(b => b.name)
      .join(", ");
    return boardNames.length > 0 ? `Pinterest Board: ${boardNames}` : "";
  } else if (account.provider === "INSTAGRAM_BUSINESS") {
    return content?.sendMobileReminder ? "Mobile reminder" : "Direct to feed";
  }
}

const Avatars = ({
  className = "",
  content,
  exhaustedDisplay = "Alert"
}: Props) => {
  const variations = content?.variations;
  const accounts = (content?.accountRelationships ?? []).map(
    ({ account }) => account
  );
  const lastPostedTo = content?.lastPostedTo;
  const twitterPostedVariationsCount =
    content?.twitterPostedVariationsCount || 0;
  const variationsCount = variations?.length || 0;
  const lastTwitterPost = lastPostedTo?.twitter;
  const remainingTwitterVariations =
    variationsCount - twitterPostedVariationsCount;

  const lastTwitterPostWasNotDeleted =
    lastTwitterPost && lastTwitterPost !== "DELETED_POST";
  const sentAccount =
    lastTwitterPostWasNotDeleted &&
    lastTwitterPost?.account &&
    accounts.some(account => lastTwitterPost?.account?.id === account.id)
      ? lastTwitterPost?.account
      : null;

  const isTwitterExhausted =
    lastTwitterPostWasNotDeleted && remainingTwitterVariations === 0;
  const twitterExhaustedMessage =
    twitterPostedVariationsCount > 0
      ? lastTwitterPostWasNotDeleted
        ? getTwitterExhaustedMessage(
            sentAccount,
            lastTwitterPost,
            variationsCount,
            twitterPostedVariationsCount
          )
        : TWITTER_DELETED_POST
      : null;

  // In some views, e.g., bulk content edit, we want to still display exhausted items but
  // want to have a different style. This maps the account into a list of objects that
  // allow us to maintain some extra context around the account if necessary.
  const wrappedAccounts = accounts.map(account => {
    switch (account.provider) {
      case "PINTEREST":
        const pinterestExpiredVariations = filter(
          variations,
          variation =>
            variation.reachedPostingLimit &&
            variation.reachedPostingLimit[account.id]
        );
        const isPinterestAcctExhausted = pinterestExpiredVariations.length > 0;
        return {
          account,
          isExhausted: isPinterestAcctExhausted,
          exhaustedMessage: isPinterestAcctExhausted
            ? getPinterestLimitMessage(
                variationsCount,
                pinterestExpiredVariations.length
              )
            : null
        };
      case "TWITTER":
        return {
          account,
          isExhausted: isTwitterExhausted,
          exhaustedMessage: twitterExhaustedMessage
        };
      default:
        return {
          account,
          isExhausted: false,
          exhaustedMessage: null
        };
    }
  });

  const exhaustedPinterestAccounts = wrappedAccounts.filter(
    acctData =>
      acctData.isExhausted && acctData.account.provider === "PINTEREST"
  );
  const pinterestBoards = content?.pinterestBoards;

  return (
    <div>
      <div className={`${styles.avatarsContainer} ${className}`}>
        {(wrappedAccounts || Array.from(new Array(LOADING_COUNT))).map(
          (acctData, key) => {
            // With `Array.from` we might get back `undefined` for `acctData`.
            if (!acctData) {
              return (
                <AccountAvatarWithBadge
                  className={styles.item}
                  key={key}
                  value={acctData}
                  tooltipPostfix={null}
                />
              );
            }

            // Exhausted Twitter accounts do not get displayed here unless we have
            // specified the exhausted display to be "Inline".
            if (
              acctData.account &&
              acctData.account.provider === "TWITTER" &&
              acctData.isExhausted &&
              exhaustedDisplay !== "Inline"
            ) {
              return null;
            }

            const exhaustedClass = acctData.isExhausted
              ? styles.exhaustedAvatar
              : "";
            return (
              <AccountAvatarWithBadge
                className={`${styles.item} ${exhaustedClass}`}
                key={key}
                value={acctData.account}
                tooltipPostfix={
                  acctData.isExhausted
                    ? acctData.exhaustedMessage
                    : tooltipPostfix(acctData.account, content, pinterestBoards)
                }
              />
            );
          }
        )}
      </div>

      {exhaustedDisplay === "Alert" &&
        exhaustedPinterestAccounts.map((acctData, index) => {
          return (
            <div className={styles.exhaustedContainer}>
              <Exhausted
                key={`reachedPostingLimit-${acctData.account.id}-${index}`}
                account={acctData.account}
                exhaustedMessage={acctData.exhaustedMessage}
                exhaustedType="PINTEREST"
              />
            </div>
          );
        })}

      {exhaustedDisplay === "Alert" && twitterPostedVariationsCount > 0 && (
        <div className={styles.exhaustedContainer}>
          <Exhausted
            key={"twitterExhausted"}
            account={sentAccount}
            exhaustedMessage={twitterExhaustedMessage}
            exhaustedType={lastTwitterPost ? "TWITTER" : "TWITTER_DELETED_POST"}
          />
        </div>
      )}
    </div>
  );
};

export default Avatars;
