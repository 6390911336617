/* @flow */
import React from "react";

import { Link } from "links";
import { Alert } from "layout";

import styles from "./index.css";

type Props = {
  children: any
};

const ErrorWithRetry = ({ children }: Props) => (
  <Alert className={styles.error} type="error">
    <div className={styles.errorBody}>
      <span className={styles.errorMessage}>{children}</span>
      <Link className={styles.retryLink}>Retry</Link>
    </div>
  </Alert>
);

export default ErrorWithRetry;
