// @flow
import React from "react";
import tooltipper from "behavioral/tooltipper";

const TooltipAnchor = () => <div />;
const TipBody = ({ tooltipBody }) => <div>{tooltipBody}</div>;

export const CenterAlignedOnboardingToolTip = tooltipper(TipBody, {
  alwaysOpen: true,
  attachment: "bottom left",
  narrowDownArrow: false,
  leftAlignDownArrow: false
})(TooltipAnchor);

export default tooltipper(TipBody, {
  alwaysOpen: true,
  attachment: "bottom left",
  narrowDownArrow: true,
  leftAlignDownArrow: true
})(TooltipAnchor);
