import React from "react";

import Icon from "icons/Icon";
import styles from "./index.css";

const Message = ({ children }) => (
  <div className={styles.info}>
    <Icon type="info-circle" /> {children}
  </div>
);

export default Message;
