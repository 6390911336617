import { useState } from "react";

const useUsage = () => {
  const [modal, setModal] = useState("undefined");

  const closeModal = () => setModal(undefined);

  const openAddAccounts = () => setModal("add-accounts");

  return { modal, closeModal, openAddAccounts };
};

export default useUsage;
