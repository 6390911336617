// @flow
import { createSelector, type OutputSelector } from "reselect";
import selectRawState, { type UsersState } from "../raw";
import type { AppState } from "types";

const selectIsAdmin: OutputSelector<AppState, void, boolean> = createSelector(
  selectRawState,
  (usersState: UsersState): boolean =>
    usersState.data.length ? usersState.data[0].attributes.isAdmin : false
);

export default selectIsAdmin;
