/* @flow */
import React from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Label from "typography/Label";
import Icon from "icons/Icon";

import styles from "./index.css";

import type {
  deleteInvitation as DeleteInvitationResult,
  deleteInvitationVariables
} from "graphql-types/deleteInvitation";
import type { FlashType } from "reducers/flash";

type Props = {
  close: () => void,
  deleteInvitation?: ({
    variables: deleteInvitationVariables
  }) => Promise<{ data: DeleteInvitationResult }>,
  deleteInvitationLoading?: boolean,
  flash: (type: FlashType, body: string) => void,
  teamMemberEmail?: string,
  teamMemberId: string
};

const DeleteInvitation = (props: Props) => {
  return (
    <Modal onClose={props.close}>
      <div className={styles.main}>
        <Icon color="danger" size="2x" type="ban" />
        <Label className={styles.title}>Delete invitation</Label>
        <div className={styles.message}>
          {props.teamMemberEmail ? (
            <>
              Are you sure you want to delete the invitation sent to{" "}
              <strong>{props.teamMemberEmail}</strong>?
            </>
          ) : (
            <>Are you sure you want to delete this invitation?</>
          )}
        </div>
        <Button
          className={styles.buttonCancel}
          disabled={props.deleteInvitationLoading}
          onClick={props.close}
          type="secondary"
        >
          Cancel
        </Button>
        <Button
          disabled={props.deleteInvitationLoading}
          onClick={async (): Promise<void> => {
            const deleteInvitation = props.deleteInvitation;
            if (!deleteInvitation) {
              return;
            }

            try {
              const resp = await deleteInvitation({
                variables: {
                  input: {
                    user: {
                      id: props.teamMemberId
                    }
                  }
                }
              });

              props.close();

              const errors = resp?.data?.deleteInvitation?.errors || [];
              if (errors.length > 0) {
                props.flash(
                  "error",
                  "An error occurred when trying to delete the invitation. Please try again."
                );
              } else {
                props.flash(
                  "notice",
                  "The invitation was successfully deleted!"
                );
              }
            } catch (_err) {}
          }}
          type="primary-negative"
        >
          Yes, delete invitation
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteInvitation;
