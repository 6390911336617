/* @flow */
import React, { useEffect, useReducer, useState } from "react";
import reducer, { initialState, initialStateBuilder } from "./state.js";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useApolloClient
} from "@apollo/react-hooks";
import { uniq, uniqBy, compact, flatMap } from "lodash";
import { produce } from "immer";
import moment from "moment-timezone";

import Toggle from "forms/Toggle";
import UPDATE_FEATURE_FLAG from "mutations/updateFeatureFlag";
import { createFlash } from "actions/flash";
import { mostStrictImageLimit } from "lib/images/validateImage";
import { mostStrictVideoLimit } from "lib/videos/validateVideo";
import { hashFile, goBack, urlsEqual, useNewUICached, featureFlag } from "util";
import analyzeVideo from "lib/videos/analyzeVideo.js";
import analyzeImage from "lib/images/analyzeImage.js";
import { reportMessage } from "errors";
import getChangeset from "./getChanges.js";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Link from "links/Link";
import AccountSelect from "./AccountSelect";
import CategorySelect from "./CategorySelect";
import Variation from "./Variation";
import AdvancedSettings from "./AdvancedSettings";
import Confirmation from "./Confirmation";
import SendAtNotice from "./SendAtNotice";
import Actions from "./Actions";
import RejectionModals from "./RejectionModals";
import { LoadingIcon } from "icons";
import Message from "layout/Message";
import NewHeader from "components/NewHeader";

import {
  fbTextVisible,
  hasLinkAttachment,
  remainingCharacterCounts
} from "./util.js";
import validateClient from "./util/clientValidation";
import validateIsSubmittable from "./util/isSubmittable";
import validateVariations from "./util/variationWarnings";
import styles from "./index.css";

import LOAD_COMPOSER from "queries/loadComposer";
import { GET_CATEGORIES } from "queries/category";
import LOAD_CONTENT from "queries/loadContent";
import SEARCH_SOCIAL_MEDIA from "queries/searchSocialMedia";
import GET_VARIATION_SUGGESTIONS from "queries/getVariationSuggestions";
import UPDATE_CONTENT from "mutations/updateContent";
import CREATE_CONTENT from "mutations/createContent";
import SCRAPE_URL from "mutations/scrapeUrl";
import CREATE_MENTION_TAG_MAP from "mutations/createMentionTagMap";
import CREATE_IMAGE from "mutations/createImage";
import CREATE_VIDEO from "mutations/createVideo";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";

import type { Dispatch, Connector } from "types";
import type {
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations_videos as VideoData,
  loadContent_content_variations_pageScrape_images as ScrapedImageData
} from "graphql-types/loadContent";
import type { Provider } from "graphql-types/loadComposer";

import type {
  Props,
  OnboardingTooltipStep,
  PageScrapesByVariation
} from "./types.js";
import { VariationWrapper } from "./styles";

import useComposer from "./use";
import Step from "./Step";
import CraftYourContent from "./CraftYourContent";

import { DragDropContext } from "react-beautiful-dnd";

import CustomModal from "./CustomModal";

const nativeSelectionGetRange = Selection.prototype.getRangeAt;

Selection.prototype.getRangeAt = function (...args) {
  try {
    return nativeSelectionGetRange.apply(this, args);
  } catch (error) {
    reportMessage("nativeSelectionGetRangeError", error);
  }
};

const nativeSelectionExtend = Selection.prototype.extend;

Selection.prototype.extend = function (...args) {
  try {
    return nativeSelectionExtend.apply(this, args);
  } catch (error) {
    reportMessage("nativeSelectionExtend", error);
  }
};
const COMPOSER_HELP_URL =
  "https://help.meetedgar.com/edgar-s-features-and-best-practices/add-content-to-edgar/add-new-content-features-overview";

const NewComposer = (props: Props) => {
  const {
    topNav,
    subscriptionStatus,
    accounts,
    categories,
    content,
    mentionSuggestions,
    pageScrapesByVariation,
    uploadingMedia,
    attachingImage,
    allowSuggestVariations,
    timeZone,
    mobileDevices,
    queuePaused,
    onboardingProgress,
    linkShortenerDomainName,
    editorFocus,
    loadingComposer,
    loadingContent,
    loadingVariationSuggestions,
    isSetSendAtModalOpen,
    isPersisting,
    isEditing,
    isSubmittable,
    isConnectAccountsModalOpen,
    isWidget = false,
    loadedContent,
    linkAttachmentRemoved,
    widgetCompletedCreate,
    variationWarnings,
    clientErrors,
    serverErrors,
    rejectionModalReasons,
    dismissOnboarding,
    suggestionsFailed,
    onDismissSuggestionsFailed,
    onClearMentionSuggestions,
    onAddMention,
    onFocusEditor,
    onBlurEditor,
    onMouseMoveEditor,
    onMouseLeaveEditor,
    onChangeVariationText,
    onChangeVariationAccountText,
    onChangeVariationFbText,
    onEntityMapChange,
    onAddVariation,
    onDeleteVariation,
    onSearchSocialMedia,
    onMentionSuggestionWarning,
    onPersist,
    onPersistAndSendNow,
    onOpenSetSendAtModal,
    onCloseSetSendAtModal,
    onFinishSetSendAtModal,
    onApprovePersistAndSendNow,
    onApproveAndPersist,
    onApproveAndOpenSetSendAtModal,
    onAttachImage,
    onAttachScrapedImage,
    onImageUploadStart,
    onImageUploadProgress,
    onImageUploadError,
    onImageUploadValidationError,
    onImageUploadFinish,
    onImagesOrderChange,
    onImagesTitleChange,
    onRemoveImage,
    onAttachVideo,
    onVideoUploadStart,
    onVideoUploadProgress,
    onVideoUploadError,
    onVideoUploadValidationError,
    onVideoUploadFinish,
    onRemoveVideo,
    onEnableLinkPreview,
    onDisableLinkPreview,
    onChangePinterestDestinationLink,
    onChangePinterestTitle,
    onChangeUseOnce,
    onChangeUseShortLinks,
    onChangeExpiresAt,
    onChangeSendAt,
    onSuggestVariations,
    onSelectPinterestBoard,
    onSelectLiAdAccount,
    onChangeInstagramBusinessSetting,
    onChangeLinkedinPostType,
    onAcknowledgeRejectionModal,

    completeOnboardingTooltipStep,
    showAddVariationOnboardingTooltip,
    showTextFieldOnboardingTooltip,
    showAccountsSelectOnboardingTooltip,
    showCategorySelectOnboardingTooltip,
    showSaveContentOnboardingTooltip,
    showModalWithContent,
    onChangeVariationTiktokFields
  } = props;

  const useNewUI = useNewUICached();
  const {
    visibleSteps,
    toggleStep,
    step1Completed,
    step2Completed,
    step3Completed,
    step4Completed,
    step1Subtitle,
    step2Subtitle,
    step3Subtitle,
    step4Subtitle,
    handleCategoryStep,
    handleAccountsStep,
    handleNextStep
  } = useComposer(props, onChangeVariationAccountText);

  const [tiktokVariations, setTiktokVariations] = useState([]);
  const [disableSaveButton, setDisableButton] = useState(false);
  const [postsLimitReached, setPostsLimitReached] = useState(false);
  const [postLimitReachedError, setPostLimitReachedError] = useState('');
  const [showTiktokFields, setShowTiktokFields] = useState(true);

  useEffect(() => {
    if (content && content.variations) {

      const tiktokAccount = content.accountRelationships?.find(acc => acc?.account?.platform === 'TIKTOK');


      const tiktokData = content.variations
        .filter(variation => variation.tiktokMeta)
        .map(variation => {
          return ({
            variationId: variation.clientId,
            privacyStatus: variation.tiktokMeta.privacyStatus || '',
            commentDisabled: variation.tiktokMeta.commentDisabled,
            duetDisabled: variation.tiktokMeta.duetDisabled,
            stitchDisabled: variation.tiktokMeta.stitchDisabled,
            showCommercialContent: variation.tiktokMeta.showCommercialContent || false,
            commercialType: variation.tiktokMeta.commercialType || [],
            maxVideoPostDurationSec: tiktokAccount?.account?.tiktokMeta?.maxVideoPostDurationSec || null
          })
        });
      const shouldDisableButton = tiktokData.some(variation =>
        variation.showCommercialContent === true && variation.commercialType.length === 0
      );

      if (tiktokAccount && tiktokAccount?.account && tiktokAccount?.account?.tiktokMeta && tiktokAccount?.account?.tiktokMeta?.code !== "ok") {
        setPostsLimitReached(true);
        setPostLimitReachedError(tiktokAccount?.account?.tiktokMeta?.message)
      } else {
        setPostsLimitReached(false);
      }
      setDisableButton(shouldDisableButton);
      setTiktokVariations(tiktokData);
      setShowTiktokFields(featureFlag("tiktok_direct_post"));
    }
  }, [content]);

  const [updateData] = useMutation(UPDATE_FEATURE_FLAG, {
    notifyOnNetworkStatusChange: true,
    awaitRefetchQueries: true
  });

  function onChangeToggle() {
    const promise = updateData({
      variables: {
        input: false
      }
    });
    if (promise) {
      promise.then(
        (response): void => {
          const errors = response?.data?.updateFeatureFlag?.errors || [];
          if (errors.length == 0) {
            window.location.reload();
          }
        }
      );
    }
  }

  const selectedAccounts = (content?.accountRelationships ?? []).map(rel => ({
    ...rel.account,
    ...accounts.find(a => a.id === rel.account.id)
  }));
  const selectedPlatforms = uniq(selectedAccounts.map(a => a.platform));
  const selectedProviders = uniq(selectedAccounts.map(a => a.provider));
  const selectedPinterestBoardsByAccount = (
    content?.accountRelationships ?? []
  ).reduce((acc, rel) => {
    acc[rel.account.id] = (rel.pinterestBoards ?? []).map(p => p.id);
    return acc;
  }, {});

  const selectedLiAdAccountsByAccount = (
    content?.accountRelationships ?? []
  ).reduce((acc, rel) => {
    acc[rel.account.id] = (rel.linkedinAdAccounts ?? []).map(p => p.id);
    return acc;
  }, {});

  const variations = content?.variations ?? [];
  const allAttachedImages = uniqBy(
    flatMap(variations, v => v.images),
    i => i.clientProvidedSha256
  );
  const allAttachedVideos = uniqBy(
    flatMap(variations, v => v.videos),
    v => v.clientProvidedSha256
  );

  if (widgetCompletedCreate) {
    return <Confirmation />;
  }

  const formFullyLoaded = isEditing
    ? !loadingComposer && loadedContent
    : !loadingComposer;

  let getOnDragEnd = (result: any, provided: any): void => {
    console.log(result);
    console.log(provided);
  };

  const setOnDragEndMethod = newMethod => {
    getOnDragEnd = newMethod;
  };

  const [showAIGenerator, setShowAIGenerator] = useState(true);

  const toggleAIGenerator = () => {
    setShowAIGenerator(!showAIGenerator);
  };

  return (
    <App
      loggedIn
      layout={isWidget ? "widget" : "default"}
      topNav={topNav}
      subscriptionStatus={subscriptionStatus}
      onboardingProgress={onboardingProgress}
      onDismissOnboarding={dismissOnboarding}
      newWidth
      header={
        useNewUI ? (
          <NewHeader
            title={isEditing ? "Edit content" : "Add content to library"}
            helpUrl={COMPOSER_HELP_URL}
            mb
            hideAddNew
            {...topNav}
          />
        ) : (
          <PageTitle helpUrl={COMPOSER_HELP_URL}>
            {isEditing ? "Edit content" : "Add content to library"}
          </PageTitle>
        )
      }
      messages={
        queuePaused ? (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting in{" "}
            <Link href="/queue">your Queue</Link>.
          </Message>
        ) : null
      }
    >
      {loadingComposer || loadingContent ? (
        <LoadingIcon className={styles.loading} />
      ) : (
        <div>
          {featureFlag("new_composer_existing_user") && (
            <>
              {" "}
              <div className={styles.wrapper}>
                <strong>Meet Inky, my new AI sidekick!</strong> <br />
                Inky writes your copy and suggests hashtags for you, right
                inside the new content composer
              </div>
              <div className={styles.toggle_area}>
                <div>
                  <p>Try our new composer screen</p>
                </div>
                <div>
                  <Toggle
                    value={featureFlag("new_composer")}
                    className={styles.toggleNewUi}
                    onChange={onChangeToggle}
                  />
                </div>
              </div>
            </>
          )}

          <Step
            number={1}
            title="Choose your category"
            subtitle={step1Subtitle}
            expanded={visibleSteps.step1}
            toggleStep={toggleStep}
            completed={step1Completed}
            nextButton={false}
          >
            <CategorySelect
              onNextOnboardingStep={() =>
                completeOnboardingTooltipStep("categories")
              }
              showOnboardingTooltip={showCategorySelectOnboardingTooltip}
              value={content?.category || ""}
              categories={categories}
              serverErrors={serverErrors?.category_id}
              onChange={handleCategoryStep}
            />
          </Step>

          <Step
            number={2}
            title="Select your social media accounts"
            subtitle={step2Subtitle}
            expanded={visibleSteps.step2}
            toggleStep={toggleStep}
            completed={step2Completed}
            handleNextStep={handleNextStep}
          >
            <AccountSelect
              accounts={accounts}
              accountRelationships={content?.accountRelationships}
              selectedAccountIds={selectedAccounts.map(({ id }) => id)}
              selectedPinterestBoardsByAccount={
                selectedPinterestBoardsByAccount
              }
              selectedLiAdAccountsByAccount={
                selectedLiAdAccountsByAccount
              }
              postedVariationsCount={{
                twitterPostedVariationsCount:
                  content?.twitterPostedVariationsCount || 0,
                variationsCount: variations.length
              }}
              lastPostedTo={content?.lastPostedTo}
              onNextOnboardingStep={() =>
                completeOnboardingTooltipStep("accounts")
              }
              showOnboardingTooltip={showAccountsSelectOnboardingTooltip}
              sendMobileReminder={content?.sendMobileReminder}
              instaReels={content?.instaReels}
              linkedinCarousel={content?.linkedinCarousel}
              hasMobileDevices={!!mobileDevices.length}
              onSelect={(accounts: string[]) =>
                handleAccountsStep("select", accounts)
              }
              onDeselect={(accounts: string[]) =>
                handleAccountsStep("deselect", accounts)
              }
              onSelectPinterestBoard={onSelectPinterestBoard}
              onSelectLiAdAccount={onSelectLiAdAccount}
              onChangeInstagramBusinessSetting={
                onChangeInstagramBusinessSetting
              }
              onChangeLinkedinPostType={
                onChangeLinkedinPostType
              }
            />
          </Step>

          <Step
            number={3}
            title="Craft your content"
            subtitle={step3Subtitle}
            expanded={visibleSteps.step3}
            toggleStep={toggleStep}
            completed={step3Completed}
            handleNextStep={handleNextStep}
          >
            <CraftYourContent
              variations={variations}
              idx={editorFocus.idx}
              loadingVariationSuggestions={loadingVariationSuggestions}
              suggestionsFailed={suggestionsFailed}
              onDismissSuggestionsFailed={onDismissSuggestionsFailed}
              showAddVariationOnboardingTooltip={
                showAddVariationOnboardingTooltip
              }
              allowSuggestVariations={allowSuggestVariations}
              onAddVariation={onAddVariation}
              onSuggestVariations={onSuggestVariations}
              onFocusEditor={onFocusEditor}
            >
              {variations.map((variation, index) => {
                const accountsWithNoCustomize = selectedAccounts.filter(x => {
                  return !(variation.accountsData || []).some(
                    y => y.accountId == x.id
                  );
                });
                const remainingChars = remainingCharacterCounts(
                  accountsWithNoCustomize,
                  variation.text || ""
                );

                const title =
                  variations.length > 1 ? `Variation ${index + 1}` : "Post";

                return (
                  <VariationWrapper
                    active={index === editorFocus.idx}
                    key={variation.clientId}
                  >
                    <DragDropContext
                      onDragStart={() => { }}
                      onDragUpdate={() => { }}
                      onDragEnd={(result: any, provided: any) =>
                        getOnDragEnd(result, provided)
                      }
                    >
                      <Variation
                        tiktokDirectPost={showTiktokFields}
                        postLimitReachedError={postLimitReachedError}
                        postsLimitReached={postsLimitReached}
                        setDisableButton={setDisableButton}
                        isEditing={isEditing}
                        tiktokVariations={tiktokVariations}
                        setTiktokVariations={setTiktokVariations}
                        toggleAIGenerator={toggleAIGenerator}
                        showAIGenerator={showAIGenerator}
                        setOnDragEndMethod={setOnDragEndMethod}
                        index={index}
                        value={variation}
                        content={content}
                        title={title}
                        isConnectAccountsModalOpen={isConnectAccountsModalOpen}
                        selectedPlatforms={selectedPlatforms}
                        selectedProviders={selectedProviders}
                        selectedAccounts={selectedAccounts}
                        selectedPinterestBoardsByAccount={
                          selectedPinterestBoardsByAccount
                        }
                        selectedLiAdAccountsByAccount={
                          selectedLiAdAccountsByAccount
                        }
                        mentionSuggestions={mentionSuggestions}
                        uploadingMedia={
                          uploadingMedia[variation.clientId] ?? []
                        }
                        allAttachedVideos={allAttachedVideos}
                        allAttachedImages={allAttachedImages}
                        attachingImage={
                          attachingImage[variation.clientId] ?? false
                        }
                        pageScrape={pageScrapesByVariation[variation.clientId]}
                        remainingCharacterCounts={remainingChars}
                        focused={editorFocus.idx === index}
                        focusedField={editorFocus.field}
                        hovered={editorFocus.hovered === index}
                        deleteVisible={variations.length > 1}
                        fbTextVisible={fbTextVisible(
                          variation,
                          selectedPlatforms
                        )}
                        linkAttachmentRemoved={
                          linkAttachmentRemoved[variation.clientId]
                        }
                        warnings={variationWarnings?.[index]?.warnings ?? []}
                        requiredFieldErrorVisible={
                          variationWarnings?.[index]
                            ?.requiredFieldErrorVisible ?? false
                        }
                        clientErrors={
                          clientErrors?.variations?.[variation.clientId]
                        }
                        serverErrors={
                          serverErrors?.variations?.[variation.clientId]
                        }
                        onNextOnboardingStep={() =>
                          completeOnboardingTooltipStep("textfield")
                        }
                        showOnboardingTooltip={showTextFieldOnboardingTooltip}
                        onClearMentionSuggestions={onClearMentionSuggestions}
                        onAddMention={onAddMention}
                        onMouseMove={onMouseMoveEditor.bind(null, index)}
                        onMouseLeave={onMouseLeaveEditor.bind(null, index)}
                        onFocusText={onFocusEditor.bind(null, index, "text")}
                        onFocusFbText={onFocusEditor.bind(
                          null,
                          index,
                          "fbText"
                        )}
                        onFocusVariation={onFocusEditor.bind(
                          null,
                          index,
                          "text"
                        )}
                        onBlurField={onBlurEditor}
                        onChangeText={onChangeVariationText}
                        onChangeAccountText={onChangeVariationAccountText}
                        onChangeFbText={onChangeVariationFbText}
                        onEntityMapChange={onEntityMapChange}
                        onSearchSocialMedia={onSearchSocialMedia}
                        onMentionSuggestionWarning={onMentionSuggestionWarning}
                        onDelete={onDeleteVariation.bind(
                          null,
                          variation.clientId
                        )}
                        onAttachImage={onAttachImage}
                        onAttachScrapedImage={onAttachScrapedImage}
                        onImageUploadStart={onImageUploadStart}
                        onImageUploadProgress={onImageUploadProgress}
                        onImageUploadError={onImageUploadError}
                        onImageUploadValidationError={
                          onImageUploadValidationError
                        }
                        onImageUploadFinish={onImageUploadFinish}
                        onImagesOrderChange={onImagesOrderChange}
                        onImagesTitleChange={onImagesTitleChange}
                        onRemoveImage={onRemoveImage}
                        onAttachVideo={onAttachVideo}
                        onVideoUploadStart={onVideoUploadStart}
                        onVideoUploadProgress={onVideoUploadProgress}
                        onVideoUploadError={onVideoUploadError}
                        onVideoUploadValidationError={
                          onVideoUploadValidationError
                        }
                        onVideoUploadFinish={onVideoUploadFinish}
                        onRemoveVideo={onRemoveVideo}
                        onEnableLinkPreview={onEnableLinkPreview}
                        onDisableLinkPreview={onDisableLinkPreview}
                        onChangePinterestDestinationLink={onChangePinterestDestinationLink.bind(
                          null,
                          variation.clientId
                        )}
                        onChangePinterestTitle={onChangePinterestTitle.bind(
                          null,
                          variation.clientId
                        )}
                        onChangeTiktokFields={onChangeVariationTiktokFields}
                      />
                    </DragDropContext>
                  </VariationWrapper>
                );
              })}
            </CraftYourContent>
          </Step>

          {content && formFullyLoaded && (
            <>
              <Step
                number={4}
                title="Advanced settings"
                subtitle={step4Subtitle}
                expanded={visibleSteps.step4}
                toggleStep={toggleStep}
                completed={step4Completed}
                handleNextStep={handleNextStep}
              >
                <AdvancedSettings
                  timeZone={timeZone}
                  useOnce={content.useOnce ?? false}
                  expiresAt={content.expiresAt}
                  useShortLinks={content.useShortLinks}
                  linkShortenerDomainName={linkShortenerDomainName}
                  openAtMount={content.expiresAt || content.useOnce}
                  onChangeExpiresAt={onChangeExpiresAt}
                  onChangeUseOnce={onChangeUseOnce}
                  onChangeUseShortLinks={onChangeUseShortLinks}
                  isEditing={isEditing}
                />
                {content.sendAt && (
                  <SendAtNotice
                    sendAt={content.sendAt}
                    timeZone={timeZone}
                    showWarning={false}
                    onChangeSendAt={onChangeSendAt}
                  />
                )}
              </Step>
              <Actions
                disableSaveButton={disableSaveButton}
                postsLimitReached={postsLimitReached}
                content={content}
                timeZone={timeZone}
                isSubmittable={isSubmittable}
                isPersisting={isPersisting}
                isSetSendAtModalOpen={isSetSendAtModalOpen}
                isConnectAccountsModalOpen={isConnectAccountsModalOpen}
                onNextOnboardingStep={() =>
                  completeOnboardingTooltipStep("save")
                }
                showOnboardingTooltip={showSaveContentOnboardingTooltip}
                showImportContentModal={
                  //TODO: The import modal during onboarding is heavily tied into RSS Feed
                  //      data stored in Redux. That data isn't there now :\.
                  /*
                  onboardingProgress.importContent == null &&
                  pullAll(uniq(accounts.map(({ platform }) => platform)), [
                    "INSTAGRAM",
                    "PINTEREST"
                  ]).length > 0
                  */
                  false
                }
                onChangeSendAt={onChangeSendAt}
                onPersist={onPersist}
                onPersistAndSendNow={onPersistAndSendNow}
                onOpenSetSendAtModal={onOpenSetSendAtModal}
                onCloseSetSendAtModal={onCloseSetSendAtModal}
                onFinishSetSendAtModal={onFinishSetSendAtModal}
                onApprovePersistAndSendNow={onApprovePersistAndSendNow}
                onApproveAndPersist={onApproveAndPersist}
                onApproveAndOpenSetSendAtModal={onApproveAndOpenSetSendAtModal}
              />
              <RejectionModals
                rejectionModalReasons={rejectionModalReasons}
                onAcknowledge={onAcknowledgeRejectionModal}
              />
            </>
          )}
        </div>
      )}

      {showModalWithContent && <CustomModal {...showModalWithContent} />}
    </App>
  );
};

type OwnProps = {
  contentId: string,
  onSetFlash: (message: string) => void
} & Props;

const NewComposerWithState = (props: OwnProps) => {
  const apolloClient = useApolloClient();
  const { contentId, onSetFlash, isWidget, initialVariationText } = props;
  const isEditing = !!contentId;

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    initialStateBuilder(isWidget, initialVariationText)
  );
  window.STATE = state;

  const {
    content,
    uploadingMedia,
    attachingImage,
    editorFocus,
    rejectionModalReasons,
    mentionSuggestions,
    variationSuggestions,
    accounts,
    categories,
    serverErrors,
    persistResult,
    onboardingTooltipStep,
    isSetSendAtModalOpen,
    scrapes,
    linkAttachmentRemoved,
    hasModifiedVariations,
    loadedContent,
    hasAccountWithoutSchedule
  } = state;

  const [showModalWithContent, setShowModalWithContent] = useState < null | {} > (
    null
  );

  // Handle the side-effects of content create/update
  useEffect(() => {
    switch (persistResult) {
      case "CREATED": {
        if (isWidget) {
          // The widget displays the success component forever after content is created
          return;
        }
        const flashMessage = hasAccountWithoutSchedule
          ? 'Content was successfully added to your Library. However, one or more of your social accounts does not have a schedule! <a href="/schedule">Click here</a> to schedule.'
          : "Content was successfully added to your Library";
        onSetFlash(flashMessage);
        dispatch({ type: "FINISH_PERSIST" });

        console.log(isSetSendAtModalOpen);
        console.log(content);

        // show a modal instead of a flash message
        return;
      }
      case "UPDATED": {
        console.log(content);
        goBack();
        dispatch({ type: "FINISH_PERSIST" });
        return;
      }
      case "ERROR": {
        if (serverErrors.category_id) {
          window.scrollTo(0, 0);
        }
        dispatch({ type: "FINISH_PERSIST" });
        return;
      }
    }
  }, [
    persistResult,
    serverErrors,
    isWidget,
    onSetFlash,
    hasAccountWithoutSchedule,
    isSetSendAtModalOpen,
    content
  ]);

  const [scrapeUrl] = useMutation(SCRAPE_URL);
  // Handle the side-effects of page scraper
  useEffect(() => {
    Object.keys(scrapes).forEach(variationClientId => {
      const scrape = scrapes[variationClientId];
      const { status, timeoutId: currentTimeoutId } = scrape;
      switch (status) {
        case "QUEUED": {
          if (currentTimeoutId) {
            clearTimeout(currentTimeoutId);
          }
          dispatch({ type: "START_SCRAPE", variationClientId });
          break;
        }
        case "STARTED": {
          const timeoutId = setTimeout(
            pollScrapeUrl.bind(null, variationClientId),
            1000
          );
          dispatch({ type: "POLL_SCRAPE", variationClientId, timeoutId });
          break;
        }
      }
    });

    async function pollScrapeUrl(variationClientId) {
      const { url } = scrapes[variationClientId];
      const {
        data: {
          scrapeUrl: { pageScrape, errors }
        }
      } = await scrapeUrl({
        variables: {
          url
        }
      });

      const currentUrl = scrapes[variationClientId]?.pageScrape?.url;
      if (pageScrape?.url && currentUrl && !urlsEqual(pageScrape?.url, url)) {
        return;
      }

      if (errors && errors.length > 0) {
        dispatch({
          type: "SCRAPE_FAILED",
          variationClientId,
          pageScrape: pageScrape || { status: "error" },
          errors
        });
        return;
      }

      if (pageScrape?.status === "ok") {
        dispatch({
          type: "SCRAPE_SUCCEEDED",
          variationClientId,
          pageScrape
        });
        return;
      }

      if (pageScrape?.status === "waiting") {
        const timeoutId = setTimeout(
          pollScrapeUrl.bind(null, variationClientId),
          1000
        );
        dispatch({ type: "POLL_SCRAPE", variationClientId, timeoutId });
        return;
      }
    }
  }, [scrapes, content, scrapeUrl, state.scrapes]);

  const suggestionsFailed = variationSuggestions.status === "FAILED";
  const usedSuggestVariations = variationSuggestions.status === "COMPLETE";
  const allScrapedUrls = compact(
    uniq(
      Object.keys(scrapes).reduce(
        (acc, variationClientId) => [
          ...acc,
          scrapes[variationClientId]?.pageScrape?.url
        ],
        []
      )
    )
  );
  const platforms = uniq(
    (content?.accountRelationships ?? []).map(rel => rel.account?.platform)
  );
  const providers = uniq(
    (content?.accountRelationships ?? []).map(rel => rel.account?.provider)
  );

  // Queries
  const { loading: loadingComposer, error, data } = useQuery(LOAD_COMPOSER, {
    onCompleted: data => {
      dispatch({
        type: "LOAD_COMPOSER",
        categories: data?.categories,
        accounts: data?.accounts
      });
      if (isWidget) {
        dispatch({ type: "LOAD_WIDGET" });
      }
    }
  });

  const [
    loadContent,
    { loading: loadingContent, error: _contentError, data: contentData }
  ] = useLazyQuery(LOAD_CONTENT, {
    variables: {
      contentId
    },
    onCompleted: data => {
      dispatch({ type: "LOAD_CONTENT", content: data?.content });
    }
  });
  if (!loadingContent && !contentData && contentId) {
    loadContent();
  }
  const [searchSocialMedia] = useLazyQuery(SEARCH_SOCIAL_MEDIA, {
    onCompleted: data =>
      dispatch({
        type: "SET_MENTION_SUGGESTIONS",
        suggestions: data.searchSocialMedia
      }),
    fetchPolicy: "network-only"
  });

  // Mutations
  const [updateContent, { loading: isUpdatingContent }] = useMutation(
    UPDATE_CONTENT,
    {
      onError(err) {
        let errorMsg;
        if (err?.networkError?.result?.error?.message) {
          errorMsg = err.networkError.result.error.message;
        } else {
          errorMsg = err.message;
        }

        dispatch({
          type: "UPDATE_CONTENT_FINISH",
          content: null,
          errors: [{ field: "base", messages: [errorMsg] }]
        });
      },

      update(_cache, { data }) {
        const {
          updateContent: { content, errors }
        } = data;
        dispatch({ type: "UPDATE_CONTENT_FINISH", content, errors });
      }
    }
  );
  const [createContent, { loading: isCreatingContent }] = useMutation(
    CREATE_CONTENT,
    {
      onError(err) {
        dispatch({
          type: "CREATE_CONTENT_FINISH",
          content: null,
          errors: [{ field: "base", messages: [err.message] }],
          hasAccountWithoutSchedule: false
        });
      },

      update(_cache, { data }) {
        const {
          createContent: { content, errors, hasAccountWithoutSchedule }
        } = data;
        dispatch({
          type: "CREATE_CONTENT_FINISH",
          content,
          errors,
          hasAccountWithoutSchedule
        });
        handleFocusEditor(0, "text");
      }
    }
  );
  const [createMentionTagMap] = useMutation(CREATE_MENTION_TAG_MAP);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);
  const [createImage] = useMutation(CREATE_IMAGE);
  const [createVideo] = useMutation(CREATE_VIDEO);

  const timeZone = data?.company?.timeZone;
  const queuePaused = data?.company?.queueStatus === "PAUSED";
  const onboardingProgress = data?.user?.onboardingProgress;
  const mobileDevices = data?.user?.devices || [];
  const linkShortenerDomainName =
    data?.company?.defaultLinkShortenerDomain?.name;

  const pageScrapesByVariation = (
    content?.variations ?? []
  ).reduce < PageScrapesByVariation > ((acc, variation) => {
    const { clientId } = variation;
    acc[clientId] = scrapes[clientId]?.pageScrape;
    return acc;
  }, {});

  const [
    getVariationSuggestions,
    { loading: loadingVariationSuggestions }
  ] = useLazyQuery(GET_VARIATION_SUGGESTIONS, {
    onCompleted: ({ variationSuggestions: suggestions }) => {
      dispatch({ type: "ADD_SUGGESTED_VARIATIONS", suggestions });
      completeOnboardingTooltipStep("add-variation");
    },
    onError: () =>
      dispatch({ type: "ADD_SUGGESTED_VARIATIONS", suggestions: [] }),
    fetchPolicy: "network-only"
  });

  //#####################################################################################################
  // Validation and errors
  //#####################################################################################################
  const variationWarnings = validateVariations(
    content?.variations ?? [],
    hasModifiedVariations,
    platforms
  );
  const clientErrors = validateClient(content, pageScrapesByVariation);

  const isSubmittable = (function () {
    const selectedPinterestBoardsByAccount = (
      content?.accountRelationships ?? []
    )
      .filter(({ account }) => account.platform === "PINTEREST")
      .reduce((acc, rel) => {
        acc[rel.account.id] = (rel.pinterestBoards ?? []).map(p => p.id);
        return acc;
      }, {});

    const selectedLiAdAccountsByAccount = (content?.accountRelationships ?? [])
      .filter(({ account }) => account.platform === "LINKEDIN_COMPANY")
      .reduce((acc, rel) => {
        acc[rel.account.id] = (rel.linkedinAdAccounts ?? []).map(p => p.id);
        return acc;
      }, {});

    const uploadsInProgress = Object.keys(uploadingMedia).reduce(
      (acc, variationClientId) => [
        ...acc,
        ...uploadingMedia[variationClientId]
      ],
      []
    );
    const pageScrapesInProgress = Object.keys(pageScrapesByVariation).some(
      v => pageScrapesByVariation[v]?.status === "waiting"
    );

    return validateIsSubmittable(
      selectedPinterestBoardsByAccount,
      uploadsInProgress,
      pageScrapesInProgress,
      clientErrors
    );
  })();

  //#############################
  // Image attach
  //#############################
  async function handleAttachImage(
    variationClientId: string,
    image: ImageData
  ) {
    const imageCountLimit = mostStrictImageLimit(
      platforms,
      providers,
      content?.sendMobileReminder
    );
    const variation = (content?.variations ?? []).find(
      v => v.clientId === variationClientId
    );
    if (!variation) {
      return;
    }
    if (
      imageCountLimit.count - variation.images.length <= 0 ||
      variation.videos.length > 0
    ) {
      return;
    }

    dispatch({ type: "ATTACH_IMAGE", variationClientId, image });
  }

  //#############################
  // Scraped image attach
  //#############################
  async function handleAttachScrapedImage(
    variationClientId: string,
    scrapedImage: ScrapedImageData
  ) {
    const imageCountLimit = mostStrictImageLimit(
      platforms,
      providers,
      content?.sendMobileReminder
    );
    const variation = (content?.variations ?? []).find(
      v => v.clientId === variationClientId
    );
    if (!variation) {
      return;
    }
    if (
      imageCountLimit.count - variation.images.length <= 0 ||
      variation.videos.length > 0
    ) {
      return;
    }

    dispatch({ type: "ATTACH_SCRAPED_IMAGE_START", variationClientId });
    const {
      src: url,
      size,
      type: format,
      width,
      height,
      sha256
    } = scrapedImage;
    // TODO: Handle error case
    const {
      data: {
        createImage: { image }
      }
    } = await createImage({
      variables: {
        input: {
          url,
          format,
          fileSize: size,
          width,
          height,
          clientProvidedSha256: sha256
        }
      }
    });
    dispatch({ type: "ATTACH_SCRAPED_IMAGE_FINISH", variationClientId, image });
  }

  //#############################
  // Image upload
  //#############################

  function handleImageUploadStart(_variationClientId, _file) { }

  function handleImageUploadProgress(
    variationClientId,
    progress,
    status,
    file
  ) {
    const fileName = file.name;
    dispatch({
      type: "IMAGE_UPLOAD_PROGRESS",
      variationClientId,
      progress,
      fileName
    });
  }

  function handleImageUploadError(variationClientId, message, file) {
    reportMessage("S3MediaUploaderFailed", message);
    _handleImageUploadError(variationClientId, [message], file);
  }

  function handleImageUploadValidationError(variationClientId, errors, file) {
    _handleImageUploadError(variationClientId, errors, file);
  }

  function _handleImageUploadError(variationClientId, messages, file) {
    const fileName = file.name;
    dispatch({
      type: "IMAGE_UPLOAD_ERROR",
      variationClientId,
      fileName,
      messages
    });
  }

  async function handleImageUploadFinish(variationClientId, url, file) {
    const clientProvidedSha256 = await hashFile(file);
    const metadata = await analyzeImage(file);
    const fileName = file.name;
    // TODO: Handle error case
    const {
      data: {
        createImage: { image }
      }
    } = await createImage({
      variables: {
        input: {
          fileSize: metadata.size,
          format: metadata.type,
          width: metadata.width,
          height: metadata.height,
          url,
          clientProvidedSha256
        }
      }
    });
    dispatch({
      type: "IMAGE_UPLOAD_FINISH",
      variationClientId,
      fileName,
      image
    });
  }

  function handleImagesOrder(variationClientId, images) {
    dispatch({
      type: "CHANGE_IMAGE_ORDER_ACTION",
      variationClientId,
      images
    });
  }
  function handleImagesTitleUpdate(variationClientId, images) {
    dispatch({
      type: "CHANGE_IMAGE_TEXT_ACTION",
      variationClientId,
      images
    });
  }

  function handleRemoveImage(variationClientId, imageId) {
    dispatch({ type: "REMOVE_IMAGE", variationClientId, imageId });
  }

  //#############################
  // Video attach
  //#############################
  async function handleAttachVideo(
    variationClientId: string,
    video: VideoData
  ) {
    const videoCountLimit = mostStrictVideoLimit(platforms);
    const variation = (content?.variations ?? []).find(
      v => v.clientId === variationClientId
    );
    if (!variation) {
      return;
    }
    if (
      videoCountLimit.count - variation.videos.length <= 0 ||
      variation.images.length > 0
    ) {
      return;
    }

    dispatch({ type: "ATTACH_VIDEO", variationClientId, video });
  }

  //#############################
  // Video upload
  //#############################

  function handleVideoUploadStart(_variationClientId, _file) { }

  function handleVideoUploadProgress(
    variationClientId,
    progress,
    status,
    file
  ) {
    dispatch({
      type: "VIDEO_UPLOAD_PROGRESS",
      variationClientId,
      progress,
      fileName: file.name
    });
  }

  function handleVideoUploadError(variationClientId, message, file) {
    reportMessage("S3MediaUploaderFailed", message);
    _handleVideoUploadError(variationClientId, [message], file);
  }

  function handleVideoUploadValidationError(variationClientId, errors, file) {
    _handleVideoUploadError(variationClientId, errors, file);
  }

  function _handleVideoUploadError(variationClientId, messages, file) {
    dispatch({
      type: "VIDEO_UPLOAD_ERROR",
      variationClientId,
      fileName: file.name,
      messages
    });
  }

  async function handleVideoUploadFinish(variationClientId, url, file) {
    const clientProvidedSha256 = await hashFile(file);
    const metadata = await analyzeVideo(file);
    // TODO: Handle error case
    const {
      data: {
        createVideo: { video }
      }
    } = await createVideo({
      variables: {
        input: {
          ...metadata,
          url,
          clientProvidedSha256
        }
      }
    });
    dispatch({
      type: "VIDEO_UPLOAD_FINISH",
      variationClientId,
      fileName: file.name,
      video
    });
  }

  function handleRemoveVideo(variationClientId, videoId) {
    dispatch({ type: "REMOVE_VIDEO", variationClientId, videoId });
  }

  //#############################
  // Editor focus
  //#############################

  function handleFocusEditor(idx, field) {
    dispatch({ type: "FOCUS_EDITOR", idx, field });
  }

  function handleBlurEditor() {
    dispatch({ type: "BLUR_EDITOR" });
  }

  function handleMouseMoveEditor(idx) {
    dispatch({ type: "MOUSE_MOVE_EDITOR", idx });
  }

  function handleMouseLeaveEditor(idx) {
    dispatch({ type: "MOUSE_LEAVE_EDITOR", idx });
  }

  //#############################
  // Content
  //#############################

  function handleSelectAccounts(accountIds) {
    accountIds.forEach(accountId =>
      dispatch({ type: "SELECT_ACCOUNT", accountId })
    );
  }

  function handleDeselectAccounts(accountIds) {
    accountIds.forEach(accountId =>
      dispatch({ type: "DESELECT_ACCOUNT", accountId })
    );
  }

  async function handleChangeSelectedCategory(data) {
    if (!data) {
      dispatch({ type: "SELECT_CATEGORY", categoryId: null });
    } else {
      const { id: categoryId, isNew } = data;

      if (isNew) {
        const { data } = await apolloClient.query({
          query: GET_CATEGORIES
        });

        dispatch({
          type: "LOAD_CATEGORIES",
          categories: data?.categories
        });
      }

      dispatch({ type: "SELECT_CATEGORY", categoryId });
    }
  }

  function handleChangeUseOnce(useOnce: boolean) {
    dispatch({ type: "CHANGE_USE_ONCE", useOnce });
  }

  function handleChangeUseShortLinks(useShortLinks: boolean) {
    dispatch({ type: "CHANGE_USE_SHORT_LINKS", useShortLinks });
  }

  function handleChangeExpiresAt(expiresAt: ?string) {
    dispatch({ type: "CHANGE_EXPIRES_AT", expiresAt });
  }

  function handleChangeSendAt(sendAt: ?string) {
    dispatch({ type: "CHANGE_SEND_AT", sendAt });
  }

  function handleChangeVariationText(variationClientId, text) {
    dispatch({ type: "CHANGE_TEXT", variationClientId, text });
  }

  function handleChangeVariationTiktokFields(variationClientId, field, value) {
    dispatch({ type: "CHANGE_TIKTOK_FIELDS", variationClientId, field, value });
  }

  function handleChangeVariationAccountText(
    variationClientId,
    accountId,
    text,
    removeFromUpdate
  ) {
    dispatch({
      type: "CHANGE_ACCOUNT_TEXT",
      variationClientId,
      accountId,
      text,
      removeFromUpdate
    });
  }

  function handleChangeVariationFbText(variationClientId, fbText) {
    dispatch({ type: "CHANGE_FB_TEXT", variationClientId, fbText });
  }

  function handleAddVariation() {
    dispatch({ type: "ADD_VARIATION" });
    completeOnboardingTooltipStep("add-variation");
  }

  function handleDeleteVariation(variationClientId) {
    dispatch({ type: "DELETE_VARIATION", variationClientId });
  }

  function handleSuggestVariations() {
    const urls = allScrapedUrls ?? [];
    if (urls.length > 0) {
      getVariationSuggestions({
        variables: {
          urls
        }
      });
    }
  }

  function handleDismissSuggestionsFailed() {
    dispatch({ type: "DISMISS_SUGGESTED_VARIATIONS_FAILED" });
  }

  //#############################
  // Mentions
  //#############################

  function handleEntityMapChange(variationClientId, rawRichTextEntityMap) {
    dispatch({
      type: "CHANGE_ENTITY_MAP",
      variationClientId,
      rawRichTextEntityMap
    });
  }

  function handleClearMentionSuggestions() {
    dispatch({ type: "SET_MENTION_SUGGESTIONS", suggestions: [] });
  }

  function handleMentionSuggestionWarning(message: string) {
    console.log("handleMentionSuggestionWarning", message);
  }

  function handleAddMention(mentionTagMap: Object) {
    createMentionTagMap({
      variables: {
        input: mentionTagMap
      }
    });
  }

  function handleSearchSocialMedia(provider: Provider, query: string) {
    searchSocialMedia({
      variables: {
        provider,
        query
      }
    });
  }

  //#############################
  // Link preview
  //#############################
  function handleEnableLinkPreview(variationClientId: string) {
    dispatch({ type: "ATTACH_LINK", variationClientId });
    //TODO: track("Composer link enabled");
  }

  function handleDisableLinkPreview(variationClientId: string) {
    dispatch({ type: "REMOVE_LINK", variationClientId });
    //TODO: track("Composer link disabled");
  }

  //#############################
  // Pinterest settings
  //#############################

  function handleChangePinterestDestinationLink(
    variationClientId: string,
    link: string
  ) {
    dispatch({
      type: "CHANGE_PINTEREST_DESTINATION_LINK",
      variationClientId,
      link
    });
    // TODO: Track manually set?
  }

  function handleChangePinterestTitle(
    variationClientId: string,
    title: string
  ) {
    dispatch({ type: "CHANGE_PINTEREST_TITLE", variationClientId, title });
  }

  function handleSelectPinterestBoard(accountId, pinterestBoardId) {
    dispatch({ type: "SELECT_PINTEREST_BOARD", accountId, pinterestBoardId });
  }

  function handleSelectLiAdAccount(accountId, liAdAccountId) {
    dispatch({ type: "SELECT_LINKEDIN_AD_ACCOUNT", accountId, liAdAccountId });
  }

  function handleChangeInstagramBusinessSetting(
    sendMobileReminder,
    instaReels
  ) {
    dispatch({
      type: "CHANGE_INSTAGRAM_BUSINESS_SETTING",
      sendMobileReminder,
      instaReels: instaReels ?? false
    });
  }

  function handleChangeLinkedinPostType(linkedinCarousel, accountRelationship) {
    dispatch({
      type: "CHANGE_LINKEDIN_POST_TYPE",
      linkedinCarousel: linkedinCarousel ?? false,
      accountRelationship: accountRelationship
    });
  }

  //#############################
  // Persistence
  //#############################

  function handlePersist() {
    persist(content);
  }

  function handlePersistAndSendNow() {
    persist(
      produce(content, draft => {
        draft.sendAt = moment()
          .tz(timeZone)
          .add(59, "seconds")
          .format();
      })
    );
  }

  function handleOpenSetSendAtModal() {
    dispatch({ type: "OPEN_SEND_AT_MODAL" });
  }

  function handleCloseSetSendAtModal() {
    dispatch({ type: "CLOSE_SEND_AT_MODAL" });
  }

  function handleFinishSetSendAtModal(sendAt: string) {
    // setShowModalWithContent({
    //   type: "sucess",
    //   title: "Content scheduled",
    //   text: "You can view it in the schedule or queue.",
    //   primaryButton: {
    //     label: "OK",
    //     onClick: () => setShowModalWithContent(null)
    //   }
    // });
    onSetFlash("Content scheduled. You can view it in the schedule or queue.");
    dispatch({ type: "CLOSE_SEND_AT_MODAL" });
    persist(
      produce(content, draft => {
        draft.sendAt = sendAt;
      })
    );
  }

  function handleApproveAndPersist() {
    persist(
      produce(content, draft => {
        draft.status = "APPROVED";
      })
    );
  }

  function handleApprovePersistAndSendNow() {
    persist(
      produce(content, draft => {
        draft.sendAt = moment()
          .tz(timeZone)
          .add(59, "seconds")
          .format();
        draft.status = "APPROVED";
      })
    );
  }

  function handleApproveAndOpenSetSendAtModal() {
    dispatch({ type: "APPROVE" });
    dispatch({ type: "OPEN_SEND_AT_MODAL" });
  }

  function persist(modifiedContent) {
    const selectedPlatforms = uniq(
      (content?.accountRelationships ?? [])
        .map(rel => rel.account)
        .map(a => a.platform)
    );

    let changeset =
      modifiedContent && getChangeset(contentData?.content, modifiedContent);

    // Rules for setting links are a bit complicated. They depend on state outside
    // of the content object (specifically scrapes). The changeset function is
    // (mostly) a simple diff so it's unaware when the link should be set or unset.
    // This takes a second look at the variations on the content and determines if
    // the link has changed and should be sent along to the server. This weirdness
    // is probably an indication that our data model is a bit off on links/scrapes.
    const linkChanges = (content?.variations ?? []).reduce((acc, variation) => {
      const pageScrape = pageScrapesByVariation[variation.clientId];
      const linkAttached = hasLinkAttachment(
        variation,
        selectedPlatforms,
        pageScrape,
        uploadingMedia[variation.clientId],
        linkAttachmentRemoved[variation.clientId]
      );
      const link = linkAttached ? pageScrape?.url : null;
      if (variation.link !== link) {
        acc[variation.clientId] = { link, id: variation.id };
      }
      return acc;
    }, {});

    if (Object.values(linkChanges).length > 0) {
      if (!changeset) {
        changeset = { id: content?.id, variations: [] };
      }
      if (!changeset.variations) {
        changeset.variations = [];
      }
      Object.keys(linkChanges).forEach(clientId => {
        const { link, id } = linkChanges[clientId];
        const existingChanges = (changeset?.variations || []).find(
          v => v.clientId === clientId
        );
        if (existingChanges) {
          existingChanges.link = link;
        } else {
          // Suppress the Flow error here :( The null check above guarantees the
          // changeset and the variations array on it will exist but Flow isn't
          // able to see that
          // $FlowFixMe
          changeset.variations.push({ id, clientId, link });
        }
      });
    }

    const pinterestLinkChanges = (content?.variations ?? []).reduce(
      (acc, variation) => {
        const pageScrape = pageScrapesByVariation[variation.clientId];
        if (!selectedPlatforms.includes("PINTEREST")) {
          if (variation.pinterestDestinationLink !== null) {
            acc[variation.clientId] = {
              id: variation.id,
              pinterestDestinationLink: null
            };
          }
        } else {
          const pinterestDestinationLink =
            variation.pinterestDestinationLink ?? (pageScrape?.url || null);
          if (variation.pinterestDestinationLink !== pinterestDestinationLink) {
            acc[variation.clientId] = {
              pinterestDestinationLink,
              id: variation.id
            };
          }
        }
        return acc;
      },
      {}
    );

    if (Object.values(pinterestLinkChanges).length > 0) {
      if (!changeset) {
        changeset = { id: content?.id, variations: [] };
      }
      if (!changeset.variations) {
        changeset.variations = [];
      }
      Object.keys(pinterestLinkChanges).forEach(clientId => {
        const { pinterestDestinationLink, id } = pinterestLinkChanges[clientId];
        const existingChanges = (changeset?.variations || []).find(
          v => v.clientId === clientId
        );
        if (existingChanges) {
          existingChanges.pinterestDestinationLink = pinterestDestinationLink;
        } else {
          // Suppress the Flow error here :( The null check above guarantees the
          // changeset and the variations array on it will exist but Flow isn't
          // able to see that
          // $FlowFixMe
          changeset.variations.push({ id, clientId, pinterestDestinationLink });
        }
      });
    }

    // No changes were made to this content, no need to let the server know. Just
    // pretend that they were and do the normal flash messages and/or redirects.
    if (!changeset) {
      if (contentId) {
        return dispatch({ type: "UPDATE_CONTENT_FINISH", content, errors: [] });
      }
      return dispatch({
        type: "CREATE_CONTENT_FINISH",
        content,
        errors: [],
        hasAccountWithoutSchedule: false
      });
    }

    // The api expects the raw rich text entity map in string form. This is
    // easier because GraphQL generally requires you to describe the shape of
    // objects. This is a complex object that for our purposes can be treated
    // like an opaque data type that gets passed along to the editor field.
    if (changeset.variations) {
      changeset = produce(changeset, draft => {
        draft.variations.forEach(v => {
          if (v.rawRichTextEntityMap) {
            v.rawRichTextEntityMap = JSON.stringify(v.rawRichTextEntityMap);
          }
        });
      });
    }

    // filter variations.accountsData
    // remove if there's no text and no remove
    if (changeset.variations) {
      changeset = produce(changeset, draft => {
        draft.variations.forEach(v => {
          if (v.accountsData) {
            v.accountsData = v.accountsData.filter(accountData => {
              const { text, remove } = accountData;
              if (!text && !remove) return false;
              return true;
            });
          }
        });
      });
    }

    // Send inside each variations.accountsData
    // only the accountId, text and remove (if it exists)
    if (changeset.variations) {
      changeset = produce(changeset, draft => {
        draft.variations.forEach(v => {
          if (v.accountsData) {
            v.accountsData = v.accountsData.map(accountData => {
              const { id, accountId, text, remove } = accountData;
              const newData = { accountId, text, id, remove };
              if (!remove) delete newData.remove;
              return newData;
            });
          }
        });
      });
    }

    // Validate accountsData before sending to the server
    let accountsDataError: string | null = null;
    let accountsDataErrorVariation: Number | null = null;
    // create a new var called variationsForValidation
    // the first index should be the last one on changeset.variations for updating content
    // the second and next indexes should be the first ones on changeset.variations
    // for creating content we don't need to do this
    const variationsForValidation =
      changeset.variations && changeset.variations?.length
        ? contentId
          ? [
            changeset.variations[changeset.variations.length - 1],
            ...changeset.variations.slice(0, changeset.variations.length - 1)
          ]
          : changeset.variations
        : [];

    if (variationsForValidation) {
      variationsForValidation.forEach((v, vIndex) => {
        if (accountsDataError) {
          return false;
        } else {
          if (v.accountsData) {
            v.accountsData.forEach(a => {
              if (accountsDataError) {
                return false;
              } else {
                if (a.text === "" && a.remove !== true) {
                  accountsDataError = a.accountId;
                  accountsDataErrorVariation = new Number(vIndex + 1);
                  return;
                }
              }
            });
          }
        }
      });

      if (accountsDataError && accountsDataErrorVariation) {
        const account = accounts.find(a => a.id === accountsDataError);
        const variationName =
          changeset.variations && changeset.variations?.length > 1
            ? `Variation ${accountsDataErrorVariation.toString()}`
            : "Base content";
        const errorMessages = [
          `${variationName} text for ${account?.providerDisplayName ||
          ""} "${account?.name || ""}" should not be empty.`
        ];

        if (contentId) {
          dispatch({
            type: "UPDATE_CONTENT_FINISH",
            content: null,
            errors: [{ field: "base", messages: errorMessages }]
          });
        } else {
          dispatch({
            type: "CREATE_CONTENT_FINISH",
            content: null,
            errors: [{ field: "base", messages: errorMessages }],
            hasAccountWithoutSchedule: false
          });
        }
        return false;
      }
    }

    if (contentId) {
      updateContent({
        variables: { input: changeset }
      });
    } else {
      createContent({
        variables: { input: changeset }
      });
    }
  }

  //#############################
  // Onboarding
  //#############################
  const isConnectAccountsModalOpen =
    onboardingProgress?.addAccount == null && accounts.length === 0;

  function completeOnboardingTooltipStep(step: OnboardingTooltipStep) {
    dispatch({ type: "COMPLETE_ONBOARDING_TOOLTIP_STEP", step });
  }

  function showOnboardingTooltipStep(step: OnboardingTooltipStep) {
    return (
      onboardingProgress?.dismissedAt == null &&
      !isConnectAccountsModalOpen &&
      onboardingProgress?.addContent == null &&
      onboardingTooltipStep === step
    );
  }

  const showAddVariationOnboardingTooltip = showOnboardingTooltipStep(
    "add-variation"
  );
  const showTextFieldOnboardingTooltip = showOnboardingTooltipStep("textfield");
  const showAccountsSelectOnboardingTooltip = showOnboardingTooltipStep(
    "accounts"
  );
  const showCategorySelectOnboardingTooltip = showOnboardingTooltipStep(
    "categories"
  );
  const showSaveContentOnboardingTooltip = showOnboardingTooltipStep("save");

  //#############################
  // Errors and validation
  //#############################
  function handleAcknowledgeRejectionModal() {
    dispatch({ type: "DISMISS_REJECTION_MODAL" });
  }

  //#############################

  const composerProps = {
    ...props,
    accounts,
    categories,
    content,
    mentionSuggestions,
    editorFocus,
    uploadingMedia,
    attachingImage,
    pageScrapesByVariation,
    allowSuggestVariations: allScrapedUrls.length > 0 && !usedSuggestVariations,
    timeZone,
    mobileDevices,
    queuePaused,
    onboardingProgress,
    linkShortenerDomainName,
    loadingComposer,
    loadingContent,
    loadingVariationSuggestions,
    isSetSendAtModalOpen,
    isPersisting: isCreatingContent || isUpdatingContent,
    isEditing,
    isSubmittable,
    isConnectAccountsModalOpen,
    loadedContent,
    variationWarnings,
    serverErrors,
    clientErrors,
    rejectionModalReasons,
    dismissOnboarding,
    suggestionsFailed,
    linkAttachmentRemoved,
    widgetCompletedCreate: isWidget && persistResult === "CREATED",
    onDismissSuggestionsFailed: handleDismissSuggestionsFailed,
    onClearMentionSuggestions: handleClearMentionSuggestions,
    onFocusEditor: handleFocusEditor,
    onBlurEditor: handleBlurEditor,
    onMouseMoveEditor: handleMouseMoveEditor,
    onMouseLeaveEditor: handleMouseLeaveEditor,
    onSelectAccounts: handleSelectAccounts,
    onDeselectAccounts: handleDeselectAccounts,
    onChangeCategory: handleChangeSelectedCategory,
    onChangeVariationText: handleChangeVariationText,
    onChangeVariationAccountText: handleChangeVariationAccountText,
    onChangeVariationFbText: handleChangeVariationFbText,
    onEntityMapChange: handleEntityMapChange,
    onAddVariation: handleAddVariation,
    onDeleteVariation: handleDeleteVariation,
    onAddMention: handleAddMention,
    onSearchSocialMedia: handleSearchSocialMedia,
    onMentionSuggestionWarning: handleMentionSuggestionWarning,
    onPersist: handlePersist,
    onPersistAndSendNow: handlePersistAndSendNow,
    onOpenSetSendAtModal: handleOpenSetSendAtModal,
    onCloseSetSendAtModal: handleCloseSetSendAtModal,
    onFinishSetSendAtModal: handleFinishSetSendAtModal,
    onApprovePersistAndSendNow: handleApprovePersistAndSendNow,
    onApproveAndPersist: handleApproveAndPersist,
    onApproveAndOpenSetSendAtModal: handleApproveAndOpenSetSendAtModal,
    onAttachImage: handleAttachImage,
    onAttachScrapedImage: handleAttachScrapedImage,
    onImageUploadStart: handleImageUploadStart,
    onImageUploadProgress: handleImageUploadProgress,
    onImageUploadError: handleImageUploadError,
    onImageUploadValidationError: handleImageUploadValidationError,
    onImageUploadFinish: handleImageUploadFinish,
    onImagesOrderChange: handleImagesOrder,
    onImagesTitleChange: handleImagesTitleUpdate,
    onRemoveImage: handleRemoveImage,
    onAttachVideo: handleAttachVideo,
    onVideoUploadStart: handleVideoUploadStart,
    onVideoUploadProgress: handleVideoUploadProgress,
    onVideoUploadError: handleVideoUploadError,
    onVideoUploadValidationError: handleVideoUploadValidationError,
    onVideoUploadFinish: handleVideoUploadFinish,
    onRemoveVideo: handleRemoveVideo,
    onEnableLinkPreview: handleEnableLinkPreview,
    onDisableLinkPreview: handleDisableLinkPreview,
    onChangePinterestDestinationLink: handleChangePinterestDestinationLink,
    onChangePinterestTitle: handleChangePinterestTitle,
    onChangeUseOnce: handleChangeUseOnce,
    onChangeUseShortLinks: handleChangeUseShortLinks,
    onChangeExpiresAt: handleChangeExpiresAt,
    onChangeSendAt: handleChangeSendAt,
    onSuggestVariations: handleSuggestVariations,
    onSelectPinterestBoard: handleSelectPinterestBoard,
    onSelectLiAdAccount: handleSelectLiAdAccount,
    onChangeInstagramBusinessSetting: handleChangeInstagramBusinessSetting,
    onChangeLinkedinPostType: handleChangeLinkedinPostType,
    onAcknowledgeRejectionModal: handleAcknowledgeRejectionModal,
    completeOnboardingTooltipStep,
    showAddVariationOnboardingTooltip,
    showTextFieldOnboardingTooltip,
    showAccountsSelectOnboardingTooltip,
    showCategorySelectOnboardingTooltip,
    showSaveContentOnboardingTooltip,
    showModalWithContent,
    setShowModalWithContent,
    onChangeVariationTiktokFields: handleChangeVariationTiktokFields
  };

  if (loadingComposer) {
    return <NewComposer {...composerProps} />;
  }

  if (error) {
    return (
      <NewComposer {...composerProps} error="Uh-oh something went wrong 😿" />
    );
  }

  return <NewComposer {...composerProps} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSetFlash: body => {
    dispatch(createFlash("notice", body));
  }
});

const connector: Connector<{}, Props> = connect(
  () => ({}),
  mapDispatchToProps
);

export const Widget = withRouter(
  connector(
    ({
      location: _l,
      history: _h,
      dispatch: _d,
      match: { params },
      ...restProps
    }) => (
      <NewComposerWithState
        {...restProps}
        isWidget
        contentId={params.contentId}
      />
    )
  )
);

export default withRouter(
  connector(
    ({
      location: _l,
      history: _h,
      dispatch: _d,
      match: { params },
      ...restProps
    }) => <NewComposerWithState {...restProps} contentId={params.contentId} />
  )
);
