/* @flow */
import React from "react";
import moment from "moment-timezone";
import compact from "lodash/compact";
import { Caption } from "typography";
import styles from "./index.css";

function sendAtCaption(sendAt, timezone) {
  if (!sendAt) {
    return null;
  }

  const now = moment().tz(timezone);
  const sendAtWithTz = moment(sendAt).tz(timezone);
  if (now.isSameOrAfter(sendAtWithTz)) {
    return null;
  }
  return sendAtWithTz.format("[Sending on] MMMM D, YYYY [at] h:mma");
}

function useOnceCaption(useOnce) {
  return useOnce ? "Use once" : null;
}

function expiresAtCaption(expiresAt, timezone) {
  if (!expiresAt) {
    return null;
  }

  const now = moment().tz(timezone);
  const expiresAtWithTz = moment(expiresAt).tz(timezone);
  const isExpired = now.isSameOrAfter(expiresAtWithTz);
  const captionPrefix = isExpired ? "Expired on" : "Expiring on";

  return expiresAtWithTz.format(`[${captionPrefix}] MMMM D, YYYY [at] h:mma`);
}

type Props = {
  timezone: ?string,
  sendAt: ?Date,
  expiresAt: ?Date,
  useOnce: ?boolean
};

const AdvancedSettingsDescription = ({
  timezone,
  sendAt,
  expiresAt,
  useOnce
}: Props) => (
  <Caption className={styles.root}>
    {compact([
      useOnceCaption(useOnce),
      sendAtCaption(sendAt, timezone),
      expiresAtCaption(expiresAt, timezone)
    ]).join(" | ")}
  </Caption>
);

export default AdvancedSettingsDescription;
