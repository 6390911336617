/**
 *  @flow
 *
 *  Returns dimension and positioning information sufficient to scale a rectangle
 *  while maintaining its aspect ratio, such that its shorter dimension matches
 *  target and it can be centered within a target sized rect along its longer
 *  dimension. Allows forcing the dimension which should be matched
 *  while enforcing a maximum in the other dimension. In other words if the dimension
 *  to scale is forced, then the target for the other dimension is treated as its max
 *  centering any potential overflow (this behavior is used by twitter media images).
 *
 * @param {number} width - The actual width of the rectangle being scaled
 * @param {number} height - The actual height of the rectangle being scaled
 * @param {number} targetWidth - The width of the target rectangle to scale to
 * @param {number} targetHeight - The height of the target rectangle to scale to
 * @param {string} [forceScaleDimension] - Force scaling to target in x|y dimension
 */

type RectScalingValues = {
  width: number,
  height: number,
  offsetX: number,
  offsetY: number
};

export default (
  width: number,
  height: number,
  targetWidth: number,
  targetHeight: number,
  forceScaleDimension?: "x" | "y"
): RectScalingValues => {
  const target = {
    width: targetWidth,
    height: targetHeight
  };
  let scaledByX;
  let shortSide;
  let longSide;
  // Determine which dimension, relative to its target, is smallest
  // being sure to respect potential override
  let scaleDimension = forceScaleDimension;
  if (!scaleDimension) {
    scaleDimension = width / target.width < height / target.height ? "x" : "y";
  }

  if (scaleDimension === "x") {
    scaledByX = true;
    shortSide = width;
    longSide = height;
  } else {
    scaledByX = false;
    shortSide = height;
    longSide = width;
  }

  const factor = target[scaledByX ? "width" : "height"] / shortSide;
  const longSideSize = longSide * factor;
  const overflow = target[scaledByX ? "height" : "width"] - longSideSize;
  const offset = overflow < 0 ? overflow / 2 : 0;

  return {
    width: scaledByX ? target.width : longSideSize,
    height: !scaledByX ? target.height : longSideSize,
    offsetX: scaledByX ? 0 : offset,
    offsetY: !scaledByX ? 0 : offset
  };
};
