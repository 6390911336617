/* @flow */
import { useState, useEffect, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { client } from "lib/subscriptions";

import GET_FACEBOOK_CONVERSATIONS from "../../../../graphql/queries/getFacebookConversations";
import GET_INSTAGRAM_CONVERSATIONS from "../../../../graphql/queries/getInstagramConversations";
import REFRESH_LIST from "../../../../graphql/subscriptions/refreshList";

import type { Props } from "./types.js";

import type { getFacebookConversations_fetchFacebookConversations_conversations as ConversationType } from "../../../../graphql/types/getFacebookConversations";

const useDirectMessages = (props: Props) => {
  const { account } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const [loadingMore, setLoadingMore] = useState(true);
  const [conversations, setConversations] = useState<ConversationType[]>([]);
  const [cursor, setCursor] = useState();

  const apolloClient = useApolloClient();

  const fetchConversations = useCallback(
    async (fromSubscription?: boolean, loadMore: boolean = false) => {
      setError(null);
      if (!account) return;

      if (fromSubscription !== true && !loadMore) setLoading(true);
      if (loadMore) setLoadingMore(true);
      const accountId = parseInt(account.id);

      const isInstagram = account.platform === "INSTAGRAM";

      try {
        const res = await apolloClient.query({
          query: isInstagram
            ? GET_INSTAGRAM_CONVERSATIONS
            : GET_FACEBOOK_CONVERSATIONS,
          variables: {
            accountId,
            cursor: loadMore ? cursor : null
          },
          fetchPolicy: "network-only"
        });

        const loadedConversations = isInstagram
          ? res.data.fetchInstagramConversations.conversations
          : res.data.fetchFacebookConversations.conversations;

        const loadedCursor = isInstagram
          ? res.data.fetchInstagramConversations.cursor
          : res.data.fetchFacebookConversations.cursor;

        const resError = isInstagram
          ? res.data.fetchInstagramConversations.error
          : res.data.fetchFacebookConversations.error;

        if (!!resError) {
          if (resError.indexOf("disabled") !== -1) {
            setError(
              "Instagram Direct Messages are disabled for this account. Please enable them in the Instagram app by Settings -> Privacy -> Messages -> toggle on 'Allow Access to Messages'"
            );
          } else {
            setError(resError);
          }
          return;
        }

        if (loadMore) {
          setConversations([...conversations, ...loadedConversations]);
          setCursor(loadedCursor);
        } else if (fromSubscription) {
          const newConversations = loadedConversations;
          const oldConversations = conversations.filter(
            conversation =>
              !newConversations.find(
                newConversation => newConversation.id === conversation.id
              )
          );
          // verify for duplicates on old
          const filteredOldConversations = oldConversations.filter(
            oldConversation =>
              !newConversations.find(
                newConversation => newConversation.id === oldConversation.id
              )
          );
          // update first pagination and keep filtered old ones
          setConversations([...newConversations, ...filteredOldConversations]);
        } else {
          setCursor(loadedCursor);
          setConversations([...loadedConversations]);
        }
      } catch (err) {
        // console.dir(err);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    }
  );

  useEffect(() => {
    if (!account) return;

    const observer = client.subscribe({
      query: REFRESH_LIST,
      variables: { pageId: account.uid }
    });

    const subscription = observer.subscribe({
      next: data => {
        console.log(data);
        if (data.data.refreshList.refreshList) {
          fetchConversations(true, false);
        }
      },
      error: error => {
        console.log("error", error);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [account, fetchConversations]);

  useEffect(() => {
    if (!!account) fetchConversations();
  }, [account]);

  const handleSelect = (conversation: ConversationType) => {
    const { handleSelectedThread } = props;
    handleSelectedThread(conversation);
  };

  const handleLoadMore = () => {
    if (!cursor) return;
    fetchConversations(false, true);
  };

  return {
    loading,
    conversations,
    handleSelect,
    loadingMore,
    cursor,
    handleLoadMore,
    error
  };
};

export default useDirectMessages;
