/* @flow */
import { gql } from "apollo-boost";

export default gql`
  mutation createComment(
    $postId: Int!
    $commentBody: String!
    $commentId: String!
  ) {
    createComment(
      postId: $postId
      commentBody: $commentBody
      commentId: $commentId
    ) {
      id
      error
    }
  }
`;
