// @flow
import React from "react";
import H3 from "typography/H3";
import Icon from "icons/Icon";
import styles from "./index.css";

const Confirmation = () => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <Icon type="check-circle" size="3x" className={styles.icon} />
      <H3 className={styles.text}>
        The content has successfully been added to your library.
      </H3>
    </div>
  </div>
);

export default Confirmation;
