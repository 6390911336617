// @flow
import React, { useState } from "react";
import { getFutureDateInTimeZone } from "util";
import { Modal } from "layout/modals";
import { Button } from "buttons";

import type { Props } from "./types.js";
import styles from "./index.css";
import { Wrapper, Title, Text, Footer } from "./styles";

import SendAtField from "./SendAtField";

export const SetSendAtModal = (props: Props) => {
  const [sendAt, setSendAt] = useState(
    getFutureDateInTimeZone({ minutes: 15 }, props.timeZone)
  );

  const handleChange = (sendAt: ?string) => {
    setSendAt(sendAt);
  };

  const {
    timeZone,
    showWarning,
    isEditing,
    onCancelSetSendAtModal,
    onFinish
  } = props;

  return (
    <Modal onClose={onCancelSetSendAtModal} className={styles.root}>
      <Wrapper>
        <Title>Schedule send</Title>
        <Text>
          Edgar will start posting your content on the date/time you specify,
          and will wait until after that time to start posting any variations
          you’ve saved. Content posted to Twitter or set to "Use Once" will be
          marked as expired in your library, while Facebook and LinkedIn content
          will be used again in the future.{" "}
          <a href="https://help.meetedgar.com/edgar-integrations/edgars-authentic-automation-support#twitter">
            (Why not Twitter?)
          </a>
        </Text>

        <SendAtField
          value={sendAt}
          timeZone={timeZone}
          showWarning={showWarning}
          isEditing={isEditing}
          onChange={handleChange}
        />

        <Footer>
          <Button type="secondary" onClick={onCancelSetSendAtModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            loading={false}
            onClick={onFinish.bind(null, sendAt)}
          >
            Finish
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default SetSendAtModal;
