// @flow
import React, { Component } from "react";
import { getUrlDomain, openWindowToUrl } from "util";
import ScaledImage from "components/common/ScaledImage";
import styles from "./index.css";

type Props = {
  title: ?string,
  description: ?string,
  url: ?string,
  destinationUrl: ?string,
  image: ?string
};

type State = {
  imageWidth: number,
  imageHeight: number
};

type LayoutMode = "ROWS" | "COLS";

// Threshholds from https://developers.facebook.com/docs/sharing/best-practices
export const ROWS_LAYOUT_IMAGE_WIDTH = 476;
export const ROWS_LAYOUT_IMAGE_HEIGHT = 249;
export const COLS_LAYOUT_IMAGE_WIDTH = 158;
export const COLS_LAYOUT_IMAGE_HEIGHT = 158;
export const COLS_LAYOUT_IMAGE_WIDTH_THRESHOLD = 600;
export const COLS_LAYOUT_IMAGE_HEIGHT_THRESHOLD = 315;

export class LinkPreview extends Component<Props, State> {
  state = { imageWidth: 0, imageHeight: 0 };

  handleImageLoad = (imageWidth: number, imageHeight: number): void => {
    this.setState({ imageWidth, imageHeight });
  };

  handleClick = (): void => {
    const { url } = this.props;
    if (url) {
      openWindowToUrl(url);
    }
  };

  getLayoutMode(): LayoutMode {
    const { image } = this.props;
    const { imageWidth, imageHeight } = this.state;
    if (!image) {
      return "ROWS";
    }
    if (
      imageWidth < COLS_LAYOUT_IMAGE_WIDTH_THRESHOLD ||
      imageHeight < COLS_LAYOUT_IMAGE_HEIGHT_THRESHOLD
    ) {
      return "COLS";
    }
    return "ROWS";
  }

  renderImageChecker() {
    const { image } = this.props;
    if (!image) {
      return null;
    }
    return (
      <ScaledImage
        className={styles.imageChecker}
        value={image}
        onLoad={this.handleImageLoad}
      />
    );
  }

  render() {
    const { destinationUrl, title, description, image } = this.props;
    const { imageWidth, imageHeight } = this.state;

    if (!destinationUrl) {
      throw new Error("destinationUrl is not present");
    }

    if (image && (imageWidth === 0 || imageHeight === 0)) {
      return this.renderImageChecker();
    }

    const layoutAsCols = this.getLayoutMode() === "COLS";

    return (
      <div
        onClick={this.handleClick}
        className={`${styles.root} ${layoutAsCols ? styles.cols : ""}`}
      >
        {image && (
          <div className={styles.imageWrapper}>
            <ScaledImage
              className={styles.image}
              value={image}
              targetWidth={
                layoutAsCols ? COLS_LAYOUT_IMAGE_WIDTH : ROWS_LAYOUT_IMAGE_WIDTH
              }
              targetHeight={
                layoutAsCols
                  ? COLS_LAYOUT_IMAGE_HEIGHT
                  : ROWS_LAYOUT_IMAGE_HEIGHT
              }
            />
          </div>
        )}
        <div className={styles.details}>
          <div className={styles.meta}>
            <div className={styles.title}>
              {title || "Facebook will choose a title"}
            </div>
            {description && (
              <div className={styles.description}>{description}</div>
            )}
          </div>
          <div className={styles.domain}>{getUrlDomain(destinationUrl)}</div>
        </div>
      </div>
    );
  }
}

export default LinkPreview;
