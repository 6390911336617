// @flow
import type { loadContent_content as ContentData } from "graphql-types/loadContent";
import type { ComposerPersistActionType } from "../getComboButtonOptions";

export type ConfirmationText = {
  title: string,
  subTitle: string
};

const getRequiredConfirmations = (
  content: ContentData,
  actionType: ComposerPersistActionType
) => {
  const requiredConfirmations = [];
  if (!content.accountRelationships.length) {
    requiredConfirmations.push({
      title: "No social accounts have been selected.",
      subTitle:
        "Are you sure you want to save? This content won't be sent until one or more accounts are selected."
    });
  }

  if (content.sendAt && actionType === "PERSIST_AND_SEND_NOW") {
    requiredConfirmations.push({
      title: "This content is already scheduled to send at a specific date.",
      subTitle:
        "Are you sure you want to continue? The content will send now and the original schedule will be lost."
    });
  }
  return requiredConfirmations;
};

export default getRequiredConfirmations;
