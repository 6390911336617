// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  children: Node,
  className?: string
};

const Field = ({ children, className }: Props) => (
  <div className={classNames(styles.root, { [className || ""]: !!className })}>
    {children}
  </div>
);

export default Field;
