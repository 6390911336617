/* @flow */
import React from "react";

import type { Account } from "types";

import styles from "./index.css";

type Props = {
  account: Account
};

export const ProfilePicture = ({ account }: Props) => (
  <div className={styles.root}>
    <img className={styles.image} src={account.image} />
  </div>
);

export default ProfilePicture;
