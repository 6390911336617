/* @flow */
import React from "react";
import H2 from "typography/H2";
import Body from "typography/Body";
import Link from "links/Link";
import pendingEmptyState from "./images/pendingContentEmptyState.svg";
import emptyLibrary from "./images/libraryEmptyState.svg";
import styles from "./index.css";

import type { ContentStatus } from "graphql-types/getContents";

type Props = {
  status: ?ContentStatus,
  hasFeeds: boolean
};

export const EmptyState = (props: Props) => {
  const { status, hasFeeds } = props;

  switch (status) {
    case "PENDING":
      if (hasFeeds) {
        return (
          <div>
            <H2 className={styles.emptyState}>No pending content</H2>
            <Body>
              Edgar imports new content via RSS about once a day. Check back
              tomorrow, and he should be here with a twinkle in his eye and some
              fabulous new content for you to approve! You can always add
              another feed on the{" "}
              <Link className={styles.importLink} url="/import">
                Import
              </Link>{" "}
              page.
            </Body>
            <img src={pendingEmptyState} alt="No pending content" />
          </div>
        );
      }
      return (
        <div>
          <H2 className={styles.emptyState}>
            There are no RSS feeds currently connected.
          </H2>
          <Body>
            Add a feed or two on the{" "}
            <Link className={styles.importLink} url="/import">
              Import
            </Link>{" "}
            page, and Edgar can start importing content for you!
          </Body>
          <img src={pendingEmptyState} alt="No pending content" />
        </div>
      );
    case "REJECTED":
      return (
        <div>
          <H2 className={styles.emptyState}>No rejected content yet!</H2>
          <Body>
            When you reject pending content, Edgar archives it here, in case you
            change your mind later. (Or if you just want to visit and say hi).
            If you haven't added any RSS feeds yet, try adding a feed or two on
            the{" "}
            <Link className={styles.importLink} url="/import">
              Import
            </Link>{" "}
            page, and Edgar can start writing content for you!
          </Body>
        </div>
      );
    case "APPROVED":
    default:
      return (
        <div>
          <H2 className={styles.emptyState}>Your library is currently empty</H2>
          <Body>
            Time to start adding content! You can even use Bulk Actions to
            upload a whole spreadsheet at once, or attach an{" "}
            <Link url="/import">RSS Feed</Link> so that Edgar can automatically
            import content for you. (Gosh!)
          </Body>
          <img src={emptyLibrary} alt="No content in library" />
        </div>
      );
  }
};

export default EmptyState;
