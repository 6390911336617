/* @flow */
import React from "react";
import styles from "./index.css";

type Props = {
  emojiPlugin: Object
};

export const VariationsButtonRow = ({
  emojiPlugin: { EmojiSelect }
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.emojiSelect}>
        <EmojiSelect className={styles.emojiSelectButton} />
      </div>
    </div>
  );
};

export default VariationsButtonRow;
