/* @flow */
import React from "react";

import DropDown from "layout/DropDown";
import LoadingIcon from "icons/LoadingIcon";
import FacebookLogo from "components/common/images/facebook-inverted.svg";
import InstagramLogo from "components/common/images/instagram-inverted.svg";
import type { getAccounts_accounts, Platform } from "graphql-types/getAccounts";

import useInboxAccount from "./use";
import type { IProps } from "./types.js";
import {
  Wrapper,
  Current,
  Loading,
  Left,
  Icon,
  AccountNames,
  Name,
  ProviderName,
  DropdownWrapper,
  DropdownAccount,
  Help
} from "./styles";

const InboxAccount = (props: IProps) => {
  const { loading, accounts, selectedAccount } = props;
  const {
    dropdownVisible,
    closeDropdown,
    toggleDropdown,
    selectAccount
  } = useInboxAccount(props);

  const availableAccounts = accounts.filter(
    o => o.provider === "FACEBOOK_PAGE" || o.provider === "INSTAGRAM_BUSINESS"
  );

  const platformLogo: { [key: Platform]: string } = {
    FACEBOOK: FacebookLogo,
    INSTAGRAM: InstagramLogo
  };

  const Logo = (logoProps: { item: getAccounts_accounts }) => {
    const { item } = logoProps;
    return (
      <Icon className={item.platform.toLowerCase()}>
        <img src={platformLogo[item.platform]} alt={`${item.platform} logo`} />
      </Icon>
    );
  };

  return (
    <Wrapper>
      {!loading && (
        <Help onClick={toggleDropdown}>Click to change your account:</Help>
      )}
      <Current
        onClick={toggleDropdown}
        title="Click to change"
        className={dropdownVisible ? "active" : ""}
      >
        {loading ? (
          <Loading>
            <LoadingIcon />
            <span>loading accounts...</span>
          </Loading>
        ) : (
          <>
            <Left>
              {!!selectedAccount && <Logo item={selectedAccount} />}

              <AccountNames isEmpty={!selectedAccount}>
                <Name>{selectedAccount?.name || "Select an account"}</Name>
                {!!selectedAccount && (
                  <ProviderName>
                    {selectedAccount.providerDisplayName}
                  </ProviderName>
                )}
              </AccountNames>
            </Left>
            <svg
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4.5 7L9 0H4.5H0L4.5 7Z" fill="#202F39" />
            </svg>
          </>
        )}
      </Current>

      {dropdownVisible && (
        <DropdownWrapper>
          <DropDown
            handleEscapeKeyPress={closeDropdown}
            includeOverlay
            onClose={closeDropdown}
            open
          >
            {availableAccounts.map((item, index) => (
              <div key={index}>
                <DropdownAccount onClick={() => selectAccount(item)}>
                  <Left>
                    <Logo item={item} />
                    <AccountNames>
                      <Name>{item.name}</Name>
                      <ProviderName>{item.providerDisplayName}</ProviderName>
                    </AccountNames>
                  </Left>
                </DropdownAccount>
              </div>
            ))}
          </DropDown>
        </DropdownWrapper>
      )}
    </Wrapper>
  );
};

export default InboxAccount;
