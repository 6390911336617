/* @flow */
import React from "react";
import moment from "moment";

import { IProps } from "./types.js";
import {
  Wrapper,
  Post,
  Picture,
  Right,
  Title,
  Preview,
  EmptyLabel
} from "./styles";

const InboxLastPosts = (props: IProps) => {
  const { posts, selectPost, selectedPost } = props;

  return (
    <Wrapper>
      {!posts?.length ? (
        <EmptyLabel>There are no posts for the selected account.</EmptyLabel>
      ) : (
        posts.map((item, index) => {
          const image =
            item.content.images?.length && item.content.images[0].url
              ? item.content.images[0].url
              : undefined;

          return (
            <Post
              key={index}
              active={selectedPost?.id === item.id}
              onClick={() => selectPost(item)}
            >
              {image && <Picture src={image} />}
              <Right>
                <Title>
                  <h4
                    title={
                      item.variation.displayText?.length > 18
                        ? item.variation.displayText.substring(0, 18) + "..."
                        : null
                    }
                    className={!!image ? "has-image" : "without-image"}
                  >
                    {item.variation.displayText || <span>No description</span>}
                  </h4>
                  <span>
                    {item.postUpdatedAt
                      ? moment(item.postUpdatedAt).format("MMM D, h:mm a")
                      : moment(item.sentAt).format("MMM D, h:mm a")}
                  </span>
                </Title>
                <Preview active={item.id === selectedPost?.id}>
                  Category: {item.content?.category?.name || "N/A"}
                </Preview>
              </Right>
            </Post>
          );
        })
      )}
    </Wrapper>
  );
};

export default InboxLastPosts;
