import * as Yup from "yup";

export const initialValues = invitation => ({
  firstName: "",
  lastName: "",
  email: invitation?.email || "",
  password: "",
  passwordConfirmation: ""
});

export const formSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required."),

  lastName: Yup.string().required("This field is required."),

  email: Yup.string()
    .email("This email address is invalid")
    .required("This field is required."),

  password: Yup.string()
    .min(6, "Password must be at least 6 characters.")
    .max(128, "Password must be shorter than 128 characters.")
    .required("This field is required."),

  passwordConfirmation: Yup.string().test(
    "passwords-match",
    "Passwords do not match.",
    function(value) {
      return this.parent.password === value;
    }
  )
});
