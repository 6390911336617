/* @flow */
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";

import { featureFlag } from "util";
import GET_NEW_UI from "queries/getNewUI";

const useApp = () => {
  const [useNewUI, setUseNewUI] = useState(false);
  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchGetNewUI = async () => {
      if (featureFlag("force_new_ui")) {
        setUseNewUI(true);
        localStorage.setItem("navbar_modules_ui", "true");
      } else if (!featureFlag("navbar_modules_ui")) {
        setUseNewUI(false);
        localStorage.setItem("navbar_modules_ui", "false");
      } else {
        const dataFromLocal = localStorage.getItem("navbar_modules_ui");
        if (dataFromLocal && dataFromLocal === "true") setUseNewUI(true);

        try {
          const res = await apolloClient.query({
            query: GET_NEW_UI,
            fetchPolicy: "no-cache"
          });

          setUseNewUI(get(res, "data.getNewUI.enabled", false));
          localStorage.setItem(
            "navbar_modules_ui",
            get(res, "data.getNewUI.enabled", false).toString()
          );
        } catch (e) {
          console.log(e);
        }
      }
    };

    fetchGetNewUI();
  }, [apolloClient]);

  return { useNewUI };
};

export default useApp;
