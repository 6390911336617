import styled from "styled-components";

export const Wrapper = styled.div``;

export const Post = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #e1e1e1;
  cursor: pointer;
  display: flex;
  padding: 15px 13px;

  &:hover {
    background-color: #fcfcfc;
  }

  @media (min-width: 768px) {
    &:last-child {
      border-bottom: none;
    }
  }

  ${({ active }) =>
    active &&
    `
    background-color: #f6f6f6;

    &:hover {
      background-color: #f6f6f6;
    }
  `}
`;

export const Picture = styled.img`
  border-radius: 50%;
  height: 35px;
  object-fit: contain;
  width: 35px;
`;

export const Right = styled.div`
  flex: 1;
  margin-left: 5px;
`;

export const Title = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  h4 {
    color: #000;
    font-weight: normal;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.has-image {
      max-width: 82px;
    }

    span {
      opacity: 0.65;
      font-style: italic;
    }
  }

  span {
    color: #a6a6a6;
    font-size: 12px;
  }
`;

export const Preview = styled.div`
  color: #aaa;
  font-size: 12px;
  margin: 5px 0 0;

  ${({ active }) => active && `color: #777;`}
`;

export const EmptyLabel = styled.div`
  font-style: italic;
  opacity: 0.5;
  padding: 15px;
`;
