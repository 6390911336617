/* @flow */
import React, { Component, type Node } from "react";
import Icon from "icons/Icon";
import styles from "./index.css";

type Props = {
  value: string,
  selected?: boolean,
  onClick?: (value: string) => void,
  children?: Node
};

export default class RadioButton extends Component<Props> {
  handleClick: () => void = () => {
    const { onClick, value } = this.props;
    if (onClick) {
      onClick(value);
    }
  };

  render() {
    const { value, selected, children } = this.props;
    return (
      <div
        className={styles.radioButton}
        aria-checked={selected}
        onClick={this.handleClick}
      >
        <span className={selected ? styles.selected : styles.deselected}>
          {selected ? <Icon type="check" /> : <Icon type="circle" />}
        </span>
        <label className={styles.label}>{children}</label>
        <input type="radio" value={value} selected={selected} />
      </div>
    );
  }
}
