import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { GET_INVITATION } from "queries/invitation";
import { ACCEPT_INVITATION } from "mutations/invitation";

import { IInvitationForm } from "./form/types.js";

const useInvitation = props => {
  const [error, setError] = useState("");
  const [errorSending, setErrorSending] = useState("");
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);
  const [invitation, setInvitation] = useState(undefined);
  const [token, setToken] = useState(undefined);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchInvitation = async (token: string) => {
      setError("");
      setLoading(true);
      setInvitation(undefined);
      setToken(token);

      try {
        const { data } = await apolloClient.query({
          query: GET_INVITATION,
          variables: { token }
        });

        if (data.invitation) {
          setInvitation(data.invitation);
        } else {
          throw new Error("Invitation not found");
        }
      } catch (err) {
        if (err.graphQLErrors && !!err.graphQLErrors?.length) {
          setError(err.graphQLErrors[0].message);
        } else {
          setError("Something went wrong.");
        }
      } finally {
        setLoading(false);
      }
    };

    let token = undefined;
    const url = location.href;
    const tokenParam = /token=([^&]+)/.exec(url);

    if (!props.token && !tokenParam) {
      setLoading(false);
      setError("No invitation token found.");
    } else {
      token = props.token || /token=([^&]+)/.exec(url)[1];
      fetchInvitation(token);
    }
  }, [apolloClient, props.token]);

  const handleSubmit = async (values: IInvitationForm) => {
    setErrorSending("");
    if (!hasAgreed) return false;
    delete values.email;

    try {
      setSending(true);
      const { data } = await apolloClient.mutate({
        mutation: ACCEPT_INVITATION,
        variables: {
          input: {
            invitationToken: token,
            user: values
          }
        }
      });

      if (!!data.acceptInvitation.errors.length) {
        const invitationError = data.acceptInvitation.errors[0];
        setErrorSending(
          `${invitationError.field} ${invitationError.messages[0]}`
        );
      } else if (data.acceptInvitation.user?.email) {
        window.location.href = "/users/sign_in";
      } else {
        setErrorSending("Something went wrong.");
      }
    } catch (err) {
      setSending(false);
      if (err.graphQLErrors) {
        setErrorSending(err.graphQLErrors[0].message);
      } else {
        setErrorSending("Something went wrong.");
      }
    }
  };

  return {
    loading,
    sending,
    error,
    errorSending,
    hasAgreed,
    invitation,
    setHasAgreed,
    handleSubmit
  };
};

export default useInvitation;
