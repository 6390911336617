/* @flow */
import React from "react";

type Props = {
  x?: number,
  y?: number,
  payload?: any,
  labelFormatter: (value: string) => string
};

const TEXT_FONT_SIZE = "11px";

const DateLabelTick = ({ x, y, payload, labelFormatter }: Props) => {
  if (!x || !y || !payload) {
    return null;
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fontSize={TEXT_FONT_SIZE}
        transform="rotate(-30)"
      >
        {labelFormatter(payload.value)}
      </text>
    </g>
  );
};

export default DateLabelTick;
