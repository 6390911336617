/* @flow */
import React, { Component } from "react";
import moment from "moment";
import withFilter from "behavioral/withFilter";
import Label from "../common/Label";
import DateInput from "forms/DateInput";
import styles from "./index.css";

import type { ContentFilter } from "types";

type Props = {
  filter: ContentFilter,
  onFilterPatch: (filterChange: ContentFilter) => void,
  title: string
};

type MomentObject = moment | Object;

export class DateCreated extends Component<Props> {
  formatDate = (value: MomentObject) =>
    value ? moment(value).format("YYYY-MM-DD") : null;

  handleChangeStartDate: (value: MomentObject) => void = value => {
    this.props.onFilterPatch({ startDate: this.formatDate(value) });
  };

  handleChangeEndDate: (value: MomentObject) => void = value => {
    this.props.onFilterPatch({ endDate: this.formatDate(value) });
  };

  render() {
    const {
      filter: { startDate, endDate },
      title
    } = this.props;

    return (
      <div className={styles.root}>
        <Label className={styles.title}>{title || "Date created"}</Label>
        <label className={styles.label}>From</label>
        <DateInput
          className={styles.input}
          value={startDate ? moment(startDate) : undefined}
          placeholder="Pick start date"
          onChange={this.handleChangeStartDate}
        />
        <label className={styles.label}>To</label>
        <DateInput
          className={styles.input}
          value={endDate ? moment(endDate) : undefined}
          placeholder="Pick end date"
          onChange={this.handleChangeEndDate}
        />
      </div>
    );
  }
}

export default withFilter()(DateCreated);
