/* @flow */
import React from "react";
import { connect } from "react-redux";

import { colorFromIndex } from "colors";
import AccountAvatar from "images/AccountAvatar";

import SVGArrowDown from "../icons/arrow-down.svg";

import useCategories from "./use";
import type { IProps } from "./types.js";
import {
  Wrapper,
  Header,
  HeaderItem,
  Body,
  Empty,
  BodyRow,
  BodyItem,
  Accounts,
  AvatarWrapper
} from "./styles";

import BodySkeleton from "./BodySkeleton";
import AccountsTooltip from "./AccountsTooltip";

const CategoriesBreakdown = (props: IProps) => {
  const { loading, data } = useCategories(props);

  // const data = oldData
  //   ? oldData.map(item => ({
  //       ...item,
  //       accounts: Array(11)
  //         .fill()
  //         .map((_, index) => ({
  //           id: "2",
  //           uid: "108443084987294",
  //           name: "Dev Tests",
  //           image:
  //             "https://graph.facebook.com/108443084987294/picture?access_token=EAALSgqUVxRIBAHcjmEihvFeAouQHMglCeUCXY3oXOq6gMYpXGJnJrkLYZAEH4TfbQMwkz0zJ8qSClNM74pExixCzTTdl1UUGmFYIklBx6kbXL02tAsZCXf4hi1dHkwfe91eOz1cU3KOSG0f5EIeDZB4tvZAtdgQsCsnLm7yMfuZBga2tOrjBs&width=60&height=60",
  //           provider: "FACEBOOK_PAGE",
  //           platform: "FACEBOOK",
  //           description: `Dev Tests ${index} (Facebook Page)`
  //         }))
  //     }))
  //   : [];

  return (
    <Wrapper>
      <Header>
        <HeaderItem className="label">
          Category
          <img src={SVGArrowDown} alt="arrow down" />
        </HeaderItem>
        <HeaderItem className="span-col-4">Accounts</HeaderItem>
        <HeaderItem className="span-col-4">Content sent</HeaderItem>
        <HeaderItem className="span-col-4">Total clicks</HeaderItem>
        <HeaderItem className="span-col-4">Impressions</HeaderItem>
      </Header>

      <Body>
        {loading ? (
          <BodySkeleton />
        ) : (
          <>
            {!data || !data?.length ? (
              <Empty>There are no data for the selected period.</Empty>
            ) : (
              <>
                {data.map((item, index) => (
                  <BodyRow key={index}>
                    <BodyItem className="label">
                      <span
                        className="cat-color"
                        style={{
                          backgroundColor: colorFromIndex(
                            item.category.colorIndex
                          )
                        }}
                      />
                      {item.category.name}
                    </BodyItem>
                    <BodyItem className="span-col-4">
                      <Accounts>
                        {!item?.accounts?.length ? (
                          <>-</>
                        ) : (
                          <>
                            {item.accounts.map((account, index) =>
                              index <= 2 ? (
                                <AvatarWrapper
                                  key={index}
                                  title={account.description}
                                >
                                  <AccountAvatar
                                    src={account.image}
                                    alt={account.provider}
                                  />
                                </AvatarWrapper>
                              ) : null
                            )}
                          </>
                        )}

                        {item.accounts?.length > 3 && (
                          <AccountsTooltip accounts={item.accounts} />
                        )}
                      </Accounts>
                    </BodyItem>
                    <BodyItem className="span-col-4">{item.sentCount}</BodyItem>
                    <BodyItem className="span-col-4">
                      {item.totalClicks}
                    </BodyItem>
                    <BodyItem className="span-col-4">
                      {item.impressionCount}
                    </BodyItem>
                  </BodyRow>
                ))}
              </>
            )}
          </>
        )}
      </Body>
    </Wrapper>
  );
};

export default connect(
  undefined,
  undefined
)(CategoriesBreakdown);
