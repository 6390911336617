import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`;

export const Button = styled.div`
  align-items: center;
  border-bottom: 2px solid transparent;
  color: #8a8a8a;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  width: 49%;

  &.active,
  &:hover {
    color: #6dbdc6;
    font-weight: bold;
    border-color: #6dbdc6;
  }

  &.only-comments {
    width: 100%;

    &:hover {
      border-color: transparent;
      color: #8a8a8a;
      cursor: default;
      font-weight: normal;
  }
`;
