/* @flow */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import { formatCurrency } from "util";

import GET_INVOICE from "queries/getInvoice";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Button from "buttons/Button";
import Message from "layout/Message";
import Link from "links/Link";
import LoadingIcon from "icons/LoadingIcon";
import ErrorWithRetry from "components/ErrorWithRetry";
import Icon from "icons/Icon";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";
import type {
  getInvoice as GetInvoice,
  getInvoice_user as User
} from "graphql-types/getInvoice";

import styles from "./index.css";

type OwnProps = {
  history: RouterHistory,
  invoiceId: string,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  data?: GetInvoice,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>,
  error?: string,
  loading?: boolean
} & OwnProps;

export const getUserFieldValue = (user: ?User, field: string): string => {
  return user?.[field] || "";
};

export const getUserFullName = (user: ?User): string => {
  return `${getUserFieldValue(user, "firstName")} ${getUserFieldValue(
    user,
    "lastName"
  )}`.trim();
};

export const getUserCityRegionZip = (user: ?User): string => {
  return [
    user?.company?.city,
    [user?.company?.region, user?.company?.zip].join(" ")
  ]
    .map(x => x && x.trim())
    .filter(x => !!x)
    .join(", ");
};

export const getUserCardNumber = (user: ?User): string => {
  const last4 = user?.company?.paymentSource?.last4;
  return last4 ? `xxxx xxxx xxxx ${last4}` : "N/A";
};

export const InvoiceMain = ({ data, error, loading }: Props) => {
  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (error) {
    return <ErrorWithRetry>{error}</ErrorWithRetry>;
  }
  const userFullName = getUserFullName(data?.user);
  const userCityRegionZip = getUserCityRegionZip(data?.user);
  const invoiceTotal = data?.invoice?.total;

  const check_per_account = data?.user?.company?.subscriptionPlan?.name;

  let per_account_amount;

  if (check_per_account == "Eddie Monthly Plan") {
    per_account_amount = 499;
  } else if (check_per_account == "Edgar Monthly Plan") {
    per_account_amount = 299;
  } else if (check_per_account == "Eddie Annual Plan") {
    per_account_amount = 399 * 12;
  } else {
    per_account_amount = 199 * 12;
  }

  const plan_total_amount =
    parseFloat(data?.user?.company?.subscriptionPlan?.amount) * 100;

  const plan_per_account_amount =
    per_account_amount * parseFloat(data?.user?.company?.additionalAccounts);

  const plan_and_additional_total = plan_total_amount + plan_per_account_amount;

  return (
    <div className={styles.section}>
      <div className={styles.row}>
        <div>
          <strong>SureSwift Capital, Inc.</strong>
          <br />
          2028 E Ben White Blvd #240-2850
          <br />
          Austin, TX 78741
          <br />
          USA
        </div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeading}>Prepared for</th>
              <th className={styles.tableHeading}>Billing information</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {userFullName && (
                  <>
                    {userFullName}
                    <br />
                  </>
                )}
                {data?.user?.company?.name && (
                  <>
                    <span className="company-name">
                      {data?.user?.company?.name}
                    </span>
                    <br />
                  </>
                )}
                {data?.user?.company?.address1 && (
                  <>
                    {data?.user?.company?.address1}
                    <br />
                  </>
                )}
                {data?.user?.company?.address2 && (
                  <>
                    {data?.user?.company?.address2}
                    <br />
                  </>
                )}
                {userCityRegionZip && (
                  <>
                    {userCityRegionZip}
                    <br />
                  </>
                )}
                {data?.user?.company?.countryName}
              </td>
              <td>
                {data?.user?.company?.paymentSource ? (
                  <>
                    <strong>Card type:</strong>{" "}
                    <span className="card-type">
                      {data?.user?.company?.paymentSource?.type || "N/A"}
                    </span>
                    <br />
                    <strong>Card number:</strong>{" "}
                    {getUserCardNumber(data?.user)}
                    <br />
                    <strong>Card expiry:</strong>{" "}
                    {data?.user?.company?.paymentSource?.expDisplay || "N/A"}
                  </>
                ) : (
                  "No card on file"
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className={styles.charge}>Charge</p>
      <div className={styles.row2}>
        <div className={styles.colCenter}>
          <table className={styles.table}>
            <thead>
              <tr>
               <th className={styles.description}>DESCRIPTION</th>
               <th>QTY</th>
               <th>UNIT PRICE</th>
               <th>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <p>
                <div className={styles.monthlyTotalRow}>
                  <span className={styles.date}>

                    {data?.invoice?.dueDate}
                  </span>
                </div>
              </p>
              {data?.invoice?.lines.map((item) => (
                <tr>
                  <td className={styles.dataBolder}>
                    <span className={styles.monthlyTotal}>
                      {item?.description}
                    </span>
                  </td>
                  <td className={styles.invoiceData}>
                    <span className={styles.monthlyTotal}>
                      {item?.quantity}
                    </span>
                  </td>
                  <td className={styles.invoiceData}>
                    <span className={styles.monthlyTotal}>
                      {formatCurrency(item?.unitAmount)}
                    </span>
                  </td>
                  <td className={styles.invoiceData}>
                    <span className={styles.total}>
                      {formatCurrency(item?.amount)}
                    </span>
                  </td>
                </tr>
              ))}
              <tr className={styles.subTotal}>
                <td> </td>
                <td className= {styles.paddingX}>
                  <span className={styles.monthlyTotal}>
                    Subtotal
                  </span>
                </td>
                <td> </td>
                <td className= {styles.paddingX}>
                  <span className={styles.total}>
                    {formatCurrency(data?.invoice?.subtotal)}
                  </span>
                </td>
              </tr>

              <tr className={styles.subTotal}>
                <td> </td>
                <td>
                  <span className={styles.monthlyTotal}>
                    Total
                  </span>
                </td>
                <td> </td>
                <td>
                  <span className={styles.total}>
                    {formatCurrency(invoiceTotal)}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          {data?.user?.company?.subscriptionPlan
            ?.shouldDisplay11xUpsellCopy && (
            <p className={styles.upsell}>
              Don’t forget, you can score a free month of Edgar by{" "}
              <a href="/settings#plan">switching to annual billing!</a> Just
              head over to <a href="/settings#plan">your account settings</a>,{" "}
              and we'll hook you up with a yearly discount.
            </p>
          )}
        </div>
      </div>
      <div className={styles.rowEqual}>
        <div className={styles.colRight}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.tableHeading}>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className={styles.status}>
                    <Icon type="check" /> Paid in full
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export const Invoice = (props: Props) => {
  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      messages={
        props.data?.user?.company?.queueStatus === "PAUSED" && (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from the{" "}
            <Link href="/queue">Queue</Link> section.
          </Message>
        )
      }
      onboardingProgress={props.data?.user?.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      isNewHeader
      header={
        props.loading || !props.data?.invoice ? (
          <PageTitle>Invoice</PageTitle>
        ) : (
          <div className={styles.header}>
            <PageTitle>
              Invoice{" "}
              <small className={styles.dueDate}>
                for {props.data?.invoice?.dueDate}
              </small>
            </PageTitle>
            <Button
              className={styles.printButton}
              onClick={(): void => {
                window.print();
              }}
              type="light"
            >
              Print
            </Button>
          </div>
        )
      }
    >
      <InvoiceMain {...props} />
    </App>
  );
};

const InvoiceWrapper = (ownProps: OwnProps) => {
  const { data, loading, error } = useQuery(GET_INVOICE, {
    variables: {
      invoiceId: ownProps.invoiceId
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true
  });
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);
  return (
    <Invoice
      {...ownProps}
      data={data}
      dismissOnboarding={dismissOnboarding}
      error={error}
      loading={loading}
    />
  );
};

export default InvoiceWrapper;
