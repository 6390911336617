/* @flow */
import {
  ORDERED_STEPS,
  STEP_URLS,
  type OnboardingStep,
  type OnboardingFlag
} from "./constants.js";

type LocationStatus = "onCurrentStep" | "onLastCompletedStep" | "offTrack";
type OnboardingFlags = {
  dismissedAt: OnboardingFlag,
  addAccount: OnboardingFlag,
  viewCategories: OnboardingFlag,
  addContent: OnboardingFlag,
  addTimeslots: OnboardingFlag,
  importContent: OnboardingFlag
};

export type OnboardingProgress = {
  id: string
} & OnboardingFlags;

export type OnboardingStatus = {
  currentStep: ?OnboardingStep,
  currentStepIndex: number,
  completedSteps: OnboardingStep[],
  hidden: boolean,
  dismissed: boolean,
  locationStatus: ?LocationStatus,
  currentStepUrl: string
};

function getCurrentStep(steps) {
  return ORDERED_STEPS.find(step => steps[step] === null) || null;
}

function getCompletedSteps(steps) {
  return Object.keys(steps).filter(key => !!steps[key]);
}

function indexOfStep(step) {
  return ORDERED_STEPS.indexOf(step);
}

function getIsOnStepsPage(step, currentPath) {
  const urlsForStep = STEP_URLS[step];
  if (!urlsForStep || !currentPath) {
    return false;
  }

  return urlsForStep.some(url => currentPath.match(url));
}

function getIsOnLastCompletedStep(currentStep, currentPath) {
  const lastCompletedStepIndex = indexOfStep(currentStep) - 1;
  if (lastCompletedStepIndex < 0) {
    return false;
  }

  return getIsOnStepsPage(ORDERED_STEPS[lastCompletedStepIndex], currentPath);
}

export function buildOnboardingStatus(
  { dismissedAt, ...steps }: OnboardingFlags,
  currentPath: string
): OnboardingStatus {
  const currentStep = getCurrentStep(steps);
  const completedSteps = getCompletedSteps(steps);

  if (!currentStep || dismissedAt) {
    return {
      hidden: true,
      dismissed: !!dismissedAt,
      completedSteps,
      currentStepIndex: currentStep ? indexOfStep(currentStep) : 3,
      currentStep: null,
      currentStepUrl: "#",
      locationStatus: null
    };
  }

  return {
    currentStep,
    currentStepIndex: indexOfStep(currentStep),
    locationStatus: getIsOnStepsPage(currentStep, currentPath)
      ? "onCurrentStep"
      : getIsOnLastCompletedStep(currentStep, currentPath)
      ? "onLastCompletedStep"
      : "offTrack",
    currentStepUrl:
      typeof STEP_URLS[currentStep][0] === "string"
        ? STEP_URLS[currentStep][0]
        : "",
    completedSteps,
    hidden: false,
    dismissed: false
  };
}

export function allStepsCompleted(stepsCompleted: OnboardingStep[]): boolean {
  return ORDERED_STEPS.every(step => stepsCompleted.includes(step));
}
