/* @flow */
import React from "react";
import { connect } from "react-redux";
import selectAccounts from "selectors/accounts";
import moment from "moment";
import styles from "./index.css";

import type { AppState, Connector, Account } from "types";

type OwnProps = { value: Account, hasLink: boolean };
type Props = OwnProps & { parentAccount: ?Account };

export const ByLine = ({ value: { name, image } }: Props) => {
  let byLineImage = image;

  return (
    <div className={styles.root}>
      <img className={styles.image} src={byLineImage} alt="Account's profile" />
      <div>
        <div className={styles.name}>{name}</div>
        <div className={styles.details}>
          {moment()
            .subtract(3, "days")
            .format("MMM DD, YYYY")}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (
  state: AppState,
  { value: { parentId } }: OwnProps
) => ({
  parentAccount: selectAccounts(state).find(acct => acct.id === parentId)
});

const connector: Connector<OwnProps, Props> = connect(mapStateToProps);

export default connector(ByLine);
