/* @flow */
import React from "react";

import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Label from "typography/Label";
import Icon from "icons/Icon";

import styles from "./index.css";

import type {
  deleteTeamMember as DeleteTeamMemberResult,
  deleteTeamMemberVariables
} from "graphql-types/deleteTeamMember";
import type { FlashType } from "reducers/flash";

type Props = {
  close: () => void,
  deleteTeamMember?: ({
    variables: deleteTeamMemberVariables
  }) => Promise<{ data: DeleteTeamMemberResult }>,
  deleteTeamMemberLoading?: boolean,
  flash: (type: FlashType, body: string) => void,
  teamMemberEmail?: string,
  teamMemberId: string
};

const DeleteTeamMember = (props: Props) => {
  return (
    <Modal onClose={props.close}>
      <div className={styles.main}>
        <Icon color="danger" size="2x" type="ban" />
        <Label className={styles.title}>Delete team member</Label>
        <div className={styles.message}>
          The accounts that are connected by this collaborator would be
          transferred to the admin, please ensure to re-connect the accounts in
          order to continue posting.
        </div>
        <div className={styles.message}>
          Any content created by a Collaborator will be transferred to the owner
          of the team.
        </div>
        <div className={styles.message}>
          Are you sure you want to delete this team member?
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.buttonCancel}
            disabled={props.deleteTeamMemberLoading}
            onClick={props.close}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            disabled={props.deleteTeamMemberLoading}
            onClick={async (): Promise<void> => {
              const deleteTeamMember = props.deleteTeamMember;
              if (!deleteTeamMember) {
                return;
              }

              try {
                const resp = await deleteTeamMember({
                  variables: {
                    input: {
                      user: {
                        id: props.teamMemberId
                      }
                    }
                  }
                });

                props.close();

                const errors = resp?.data?.deleteTeamMember?.errors || [];
                if (errors.length > 0) {
                  props.flash(
                    "error",
                    "An error occurred when trying to delete the team member. Please try again."
                  );
                } else {
                  props.flash(
                    "notice",
                    "The team member was successfully deleted!"
                  );
                }
              } catch (_err) {}
            }}
            type="primary-negative"
          >
            Yes, delete team member
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTeamMember;
