/* @flow */
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";

import { colorFromIndex } from "colors";
import { GET_CATEGORIES } from "queries/category";

function colorWithRandom(idx) {
  if (!idx) {
    return null;
  }
  return idx == -1 ? "var(--inky400)" : colorFromIndex(idx);
}

const useCategories = () => {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true);
      setCategories([]);

      try {
        const res = await apolloClient.query({
          query: GET_CATEGORIES,
          variables: {}
        });

        setCategories(
          res.data.categories.map(c => ({
            value: c.id,
            label: c.name,
            color: colorWithRandom(c.colorIndex)
          }))
        );
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };

    fetchCategories();
  }, [apolloClient]);

  return { loading, categories };
};

export default useCategories;
