// @flow
import React, { Component } from "react";

import Caption from "typography/Caption";
import Toggle from "forms/Toggle";
import styles from "./index.css";

const EDIT_LINK_SHORTENING_PATH = "/settings#link_shortener";

type Props = {
  useShortLinks: boolean,
  domainName: ?string,
  onChangeUseShortLinks: (useShortLinks: boolean) => void
};

export class LinkShorteningOption extends Component<Props> {
  render() {
    const { useShortLinks, domainName, onChangeUseShortLinks } = this.props;
    if (!domainName) {
      return (
        <Caption className={styles.setupLink}>
          <a href={EDIT_LINK_SHORTENING_PATH} target="_blank">
            Setup link shortening
          </a>
        </Caption>
      );
    }

    return (
      <Toggle
        value={useShortLinks}
        label={
          <>
            <b>Shorten links</b> with {domainName}
          </>
        }
        onChange={onChangeUseShortLinks}
      />
    );
  }
}

export default LinkShorteningOption;
