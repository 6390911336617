/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  background-color: #fcf0ca;
  border: 1px solid #f6cc50;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 30px;
  padding: 16px;
  width: 100%;
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
`;

export const Warning = styled.div`
  margin: 2px 8px -2px 0;

  img {
    width: 22px;
  }
`;

export const Text = styled.div`
  color: #7b6114;
  font-size: 15px;
`;

export const Close = styled.div`
  cursor: pointer;
  margin-left: 15px;
  opacity: 0.8;

  img {
    margin: 4px 0 -2px;
    width: 14px;
  }

  &:hover {
    opacity: 1;
  }
`;
