// @flow
import * as React from "react";
import styles from "./index.css";

type Props = {
  progress: number,
  forwardedRef: React.Ref<"div">
};

class PendingMedia extends React.Component<Props, void> {
  render() {
    return (
      <div className={styles.root} ref={this.props.forwardedRef}>
        Loading...
        <div
          className={styles.progressBar}
          style={{ width: `${this.props.progress.toString()}%` }}
        />
      </div>
    );
  }
}

export default React.forwardRef<Props, HTMLDivElement>((props, ref) => (
  <PendingMedia {...props} forwardedRef={ref} />
));
