/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0;

  &.step-3 {
    margin: 0;
  }
`;
