/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 11px;
`;

export const Button = styled.div`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 15px;
  font-weight: 600;
  padding: 11px;

  img {
    margin-right: 8px;
    width: 26px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
