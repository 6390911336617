/* @flow */
import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeCardElement from "./StripeCardElement";
import { ValidationErrorList } from "layout";
import invariant from "invariant";
import styles from "./index.css";

type Props = {
  errors: string[],
  onComplete: () => void,
  onIncomplete: () => void
};

type State = {
  stripeKey: string
};

const TEST_STRIPE_KEY = "pk_test_Y8GjmP8MbnqSFBdpXeVlfTvH";

export class PaymentMethod extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const el = document.querySelector('meta[name="stripe-key"]');
    invariant(!!el, "Unable to find stripe-key meta element on page");
    this.state = { stripeKey: el.getAttribute("content") || TEST_STRIPE_KEY };
  }

  getToken = async () => {
    invariant(
      this.stripeCardElementRef,
      "Expected stripeCardElementRef to be present"
    );
    return await this.stripeCardElementRef.getWrappedInstance().getToken();
  };

  stripeCardElementRef: ?StripeCardElement;

  render() {
    const { errors, onComplete, onIncomplete } = this.props;
    const { stripeKey } = this.state;
    const providerProps =
      global && global.isSpecs ? { stripe: null } : { apiKey: stripeKey };

    return (
      <StripeProvider {...providerProps}>
        <Elements>
          <div>
            <StripeCardElement
              ref={node => (this.stripeCardElementRef = node)}
              onComplete={onComplete}
              onIncomplete={onIncomplete}
            />
            <ValidationErrorList
              className={styles.formErrors}
              errors={errors.map(err => ({ message: err }))}
            />
          </div>
        </Elements>
      </StripeProvider>
    );
  }
}

export default PaymentMethod;
