/* @flow */
import React, { type Node } from "react";
import classNames from "classnames";
import styles from "./index.css";

type Props = {
  className?: string,
  error?: boolean,
  children?: Node
};

export const Field = ({ className, error, children }: Props) => (
  <div
    className={classNames({
      [styles.fieldWithError]: !!error,
      [styles.field]: !error,
      [className || ""]: !!className
    })}
  >
    {children}
  </div>
);
export default Field;
