/* @flow */
import React from "react";

type Props = {
  className?: string,
  totalAvailable: number,
  totalSelected: number
};

const NumSelected = ({
  className = "",
  totalAvailable,
  totalSelected
}: Props) => {
  if (totalSelected < 1) {
    return null;
  }

  const noun = totalAvailable === 1 ? "post" : "posts";
  return (
    <span className={className}>
      {totalSelected} of {totalAvailable} {noun} selected
    </span>
  );
};

export default NumSelected;
