// @flow
import React from "react";
import ScaledImage from "components/common/ScaledImage";
import styles from "./index.css";

type Props = {
  urls: string[]
};

const LandscapeTwo = ({ urls }: Props) => (
  <div className={styles.container}>
    <div className={styles.image}>
      <ScaledImage value={urls[0]} targetWidth={474} targetHeight={235} />
    </div>
    <div className={styles.image}>
      <ScaledImage value={urls[1]} targetWidth={474} targetHeight={235} />
    </div>
  </div>
);

export default LandscapeTwo;
