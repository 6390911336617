import { useState, useEffect, useRef, useCallback } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";
import moment from "moment";

import GET_FB_COMMENTS from "queries/getFbComments";
import type { fbPostComments } from "graphql-types/fbPostComments";

import type { IProps } from "./types.js";

const useContent = (props: IProps) => {
  const containerRef = useRef();
  const apolloClient = useApolloClient();

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(true);
  const [comments, setComments] = useState({});

  useEffect(() => {
    fetchComments();
  }, [fetchComments, props.post]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const fetchComments = useCallback(
    async (triggerLoading: boolean = true, cursor: null | string = null) => {
      if (!props.post) return;
      if (!cursor) setComments(undefined);

      if (triggerLoading) {
        if (cursor) setLoadingMore(true);
        else setLoading(true);
      }

      if (props.post.account.provider === "FACEBOOK_PAGE") {
        const res: Promise<fbPostComments> = await apolloClient.query({
          query: GET_FB_COMMENTS,
          variables: {
            postId: parseInt(props.post.id),
            cursor
          },
          fetchPolicy: "no-cache"
        });

        if (cursor) {
          setComments({
            comments: [
              ...(comments?.comments || []),
              ...get(res, "data.fbPostComments.comments", [])
            ],
            cursor: get(res, "data.fbPostComments.cursor", null)
          });
        } else {
          setComments({
            comments: get(res, "data.fbPostComments.comments", []),
            cursor: get(res, "data.fbPostComments.cursor", null)
          });
        }

        setLoading(false);
        setLoadingMore(false);
      } else {
        setComments([]);
        setLoading(false);
        setLoadingMore(false);
      }
    },
    [apolloClient, comments, props.post]
  );

  const afterCommentCreate = (id: string, body: string) => {
    const newComment = {
      id: id,
      message: body,
      createdTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      attachment: null,
      comments: null,
      likeCount: 0,
      userLikes: false,
      from: {
        id: props.post.account.uid,
        name: props.post.account.name,
        picture: props.post.account.image
      }
    };

    setComments({
      comments: [{ ...newComment }, ...comments.comments],
      cursor: comments.cursor
    });

    scrollToBottom();
  };

  const loadOlderComments = () => {
    if (loadingMore) return;

    const { cursor } = comments;
    fetchComments(true, cursor);
  };

  return {
    loading,
    loadingMore,
    comments,
    fetchComments,
    loadOlderComments,
    afterCommentCreate,
    containerRef
  };
};

export default useContent;
