/* @flow */
import React, { Component } from "react";
import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import Field from "forms/LegacyField";
import styles from "./index.css";

type Props = {
  couponCode: string,
  onApplyCoupon: (couponCode: string) => void,
  onClickHideCouponModal: () => void
};

export default class CouponModal extends Component<Props> {
  onKeyDown: (e: SyntheticKeyboardEvent<HTMLInputElement>) => void = e => {
    if (e.keyCode !== 13) {
      return;
    }

    if (e.target instanceof HTMLInputElement) {
      this.props.onApplyCoupon(e.target.value);
    }
  };

  handleClickApplyCoupon: () => void = () => {
    this.props.onApplyCoupon(this.refs.couponCode.value);
  };

  render() {
    const { couponCode, onClickHideCouponModal } = this.props;

    const footer = (
      <div>
        <Button onClick={onClickHideCouponModal} type="secondary">
          Cancel
        </Button>
        <Button onClick={this.handleClickApplyCoupon} type="primary">
          Apply
        </Button>
      </div>
    );

    return (
      <Modal
        size="large"
        title="Coupon code"
        footer={footer}
        onClose={onClickHideCouponModal}
      >
        <div className={styles.wrapper}>
          <Field>
            <input
              ref="couponCode"
              id="coupon-code"
              className={styles.couponInput}
              type="text"
              autoComplete="off"
              placeholder="Enter coupon code"
              defaultValue={couponCode}
              onKeyDown={this.onKeyDown}
            />
          </Field>
        </div>
      </Modal>
    );
  }
}
