import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const Tab = styled.div`
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px 0 0;
  padding: 10px;

  &.active {
    background-color: rgba(130, 197, 203, 0.25);
    color: #6dbdc6;
    font-size: 14px;
    font-weight: 600;
  }

  ${({ disabled }) =>
    disabled
      ? `
    cursor: not-allowed;
  `
      : `
    &:not(.active):hover {
      background-color: rgba(100, 100, 100, 0.1);
    }
  `}
`;
