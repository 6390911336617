/* @flow */
import React from "react";

import { CenterAlignedOnboardingToolTip } from "components/common/OnboardingTooltip";

import SVGPlus from "./plus.svg";
import type { Props } from "./types.js";
import { Wrapper, Label, AddVariation } from "./styles";

const Header = (props: Props) => {
  const {
    variations,
    idx,
    onAddVariation,
    onFocusEditor,
    showAddVariationOnboardingTooltip
  } = props;

  return (
    <Wrapper>
      {variations.length > 1 ? (
        variations.map((variation, index) => (
          <Label
            key={index}
            className={index === idx ? "active" : ""}
            onClick={() => onFocusEditor(index, "text")}
          >
            Variation {index + 1}
          </Label>
        ))
      ) : (
        <Label className="active">Base content</Label>
      )}

      {showAddVariationOnboardingTooltip && (
        <CenterAlignedOnboardingToolTip
          tooltipBody={
            "Add additional versions of this content to create more variety for your Library."
          }
        />
      )}
      <AddVariation onClick={onAddVariation}>
        <div>
          <img src={SVGPlus} alt="plus" />
          Add variation
        </div>
      </AddVariation>
    </Wrapper>
  );
};

export default Header;
