/* @flow */
import React, { type Node } from "react";
import { get } from "lodash";
import classNames from "classnames";
import AdvancedSettingsDescription from "../../common/AdvancedSettingsDescription";
import Accounts from "../../common/Accounts";
import styles from "./index.css";

import type { getContents_contents_data as ContentData } from "graphql-types/getContents";

export type Props = {
  children?: Node,
  content: ?ContentData,
  timezone: ?string
};

const Layout = ({ children, content, timezone }: Props) => {
  const queueable = get(content, "queueable");
  const accounts = get(content, "accounts", []);
  const pinterestBoards = get(content, "pinterestBoards");
  const sendAt = get(content, "sendAt");
  const expiresAt = get(content, "expiresAt");
  const useOnce = get(content, "useOnce");
  const lastPostedTo = get(content, "lastPostedTo");

  const rootClasses = classNames(styles.root, {
    [styles.unqueueable]: !queueable
  });

  return (
    <div className={rootClasses}>
      <div className={styles.primary}>
        <div className={styles.text}>{children}</div>
      </div>

      <div className={styles.metaContainer}>
        <div className={styles.accountsContainer}>
          <Accounts
            content={content}
            value={accounts}
            pinterestBoards={pinterestBoards}
            lastPostedTo={lastPostedTo}
          />
        </div>

        <AdvancedSettingsDescription
          timezone={timezone}
          sendAt={sendAt}
          expiresAt={expiresAt}
          useOnce={useOnce}
        />
      </div>
    </div>
  );
};

export default Layout;
