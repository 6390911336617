/* @flow */
import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { get } from "lodash";
import ReactTooltip from "react-tooltip";

import DASHBOARD_CARD from "queries/dashboardCard";

import type { IProps } from "./types.js";

const useImpressions = (props: IProps) => {
  const { filters, selectedAccount } = props;

  const [lastFilters, setLastFilters] = useState();
  const [lastAccountId, setLastAccountId] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const apolloClient = useApolloClient();

  useEffect(() => {
    const handleData = async () => {
      try {
        setLoading(true);
        const accountId = selectedAccount ? parseInt(selectedAccount.id) : null;

        const res = await apolloClient.query({
          query: DASHBOARD_CARD,
          variables: {
            startDate: filters.startDate,
            endDate: filters.endDate,
            cardType: "impressions",
            accountId
          }
        });

        setData(get(res, "data.dashboardCard", null));
      } catch (err) {
        setData(null);
      } finally {
        setLoading(false);
        ReactTooltip.rebuild();
      }
    };

    if (
      (filters && filters.startDate !== lastFilters?.startDate) ||
      (filters && filters.endDate !== lastFilters?.endDate) ||
      selectedAccount?.id !== lastAccountId
    ) {
      setLastFilters(filters);
      setLastAccountId(get(selectedAccount, "id", undefined));
      handleData();
    }
  }, [
    apolloClient,
    setData,
    filters,
    lastFilters,
    selectedAccount,
    lastAccountId
  ]);

  return { loading, data };
};

export default useImpressions;
