// @flow
import React from "react";
import Link from "links/Link";
import TooltippedRestoreLink from "./TooltippedRestoreLink";
import styles from "./index.css";

type Props = {
  hasAttachedMedia: boolean,
  onClick: () => void
};

const RestoreLinkPreviewAttachment = ({ hasAttachedMedia, onClick }: Props) => {
  const link = (
    <Link
      disabled={hasAttachedMedia}
      className={styles.restoreLinkPreviewLinkOverrides}
      onClick={onClick}
    >
      Restore link preview attachment
    </Link>
  );

  return (
    <div className={styles.root}>
      {hasAttachedMedia ? (
        <div className={styles.disabledRestoreLinkPreviewContainer}>
          <TooltippedRestoreLink>{link}</TooltippedRestoreLink>
        </div>
      ) : (
        link
      )}
    </div>
  );
};

export default RestoreLinkPreviewAttachment;
