import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 1100px) {
    justify-content: flex-start;
  }
`;

export const Box = styled.a`
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #c7c7c7;
  margin: 10px 0;
  padding: 20px;
  position: relative;
  text-decoration: none;
  width: 100%;

  h3 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin: 10px 0;
  }

  p {
    color: #444;
  }

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  }

  @media (min-width: 600px) {
    width: 49%;

    p {
      height: 80px;
    }
  }

  @media (min-width: 1100px) {
    width: 32%;

    &:nth-child(2),
    &:nth-child(5) {
      margin-left: 2%;
      margin-right: 2%;
    }
  }
`;

export const AlertIcon = styled.div`
  color: #a04250;
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 7.5px;
  z-index: 1;
`;
