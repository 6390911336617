/* @flow */
import React from "react";
import { useState } from "react";
import { csrfParam, csrfToken } from "../../../csrf.js";
import PageTitle from "links/PageTitle";
import Modal from "layout/modals/Modal";
import Button from "buttons/Button";
import styles from "./index.css";

type Props = {
  onClickHide: () => void
};

export const ImportCSVModal = (props: Props) => {
  const { onClickHide } = props;

  const [importButtonIsDisabled, setImportButtonIsDisabled] = useState(true);
  const handleFileChange = e => {
    if (e.target.files.length > 0) {
      return setImportButtonIsDisabled(false);
    }
    setImportButtonIsDisabled(true);
  };

  const handleSubmit = e => {
    setImportButtonIsDisabled(true);
  };

  const HELP_URL =
    "https://help.meetedgar.com/edgar-s-features-and-best-practices/add-content-to-edgar/bulk-import";

  return (
    <Modal onClose={onClickHide}>
      <div className={styles.body}>
        <PageTitle helpUrl={HELP_URL} className={styles.noFlexBasis}>
          Import Content from CSV
        </PageTitle>

        <ul className={styles.rules}>
          <li>Do not include a header row</li>
          <li>
            The first column must contain the content text, including any links
          </li>
          <li>A second column may contain the category name</li>
          <li>A third column may contain a link to an image</li>
          <li>The file must be in UTF-8 format</li>
        </ul>

        <form
          encType="multipart/form-data"
          action="/contents/import"
          acceptCharset="UTF-8"
          method="post"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name={csrfParam()} value={csrfToken()} />
          <input
            type="file"
            name="contents[upload]"
            accept="text/csv"
            onChange={handleFileChange}
          />
          <div className={styles.actions}>
            <Button onClick={onClickHide} type="secondary">
              Cancel
            </Button>
            <Button submit type="primary" disabled={importButtonIsDisabled}>
              Import
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ImportCSVModal;
