/* @flow */
import React, { type Node, type Element } from "react";
import styles from "./index.css";
import RadioButton from "./RadioButton";

type Props = {
  selectedValue: string,
  onChange?: (value: string) => void,
  children: Node
};

export { RadioButton };

const cloneButton = <T: Element<typeof RadioButton>>(
  component: any,
  props: Object
): T => React.cloneElement(component, props);

const RadioGroup = ({ selectedValue, onChange, children }: Props) => (
  <ul className={styles.radioGroup} role="radiogroup">
    {React.Children.map(children, child => {
      if (!child) {
        return child;
      }
      const selected = child.props.value === selectedValue;
      const clone = cloneButton(child, {
        selected,
        onClick: onChange
      });
      return (
        <li key={clone.props.value} data-selected={selected} role="radio">
          {clone}
        </li>
      );
    })}
  </ul>
);

export default RadioGroup;
