/* @flow */
import React from "react";

import H2 from "typography/H2";
import Paginator from "components/Paginator";
import { AbbreviatedContent } from "components/common/content";
import EmptyState from "./EmptyState";
import styles from "./index.css";

import type {
  ContentStatus,
  getContents_contents_data as ContentData
} from "graphql-types/getContents";

const LOADING_STATE_CONTENT_COUNT = 25;

type Props = {
  hasFeeds: boolean,
  contents: ?(ContentData[]),
  timezone: ?string,
  totalCount: number,
  filterStatus: ?ContentStatus,
  filteredCount: number,
  page: number,
  pageCount?: number,
  onPageChange: number => void
};

const List = ({
  page,
  pageCount,
  onPageChange,
  totalCount,
  filterStatus,
  filteredCount,
  contents,
  timezone,
  hasFeeds
}: Props) => {
  if (totalCount === 0) {
    return <EmptyState status={filterStatus} hasFeeds={hasFeeds} />;
  }

  if (filteredCount === 0) {
    return <H2 className={styles.emptyState}>No content matching filters</H2>;
  }

  return (
    <>
      <ul className={styles.list}>
        {(contents || Array.from(new Array(LOADING_STATE_CONTENT_COUNT))).map(
          (content, idx) => (
            <li key={idx}>
              <AbbreviatedContent content={content} timezone={timezone} />
            </li>
          )
        )}
      </ul>
      <Paginator
        page={page}
        pageCount={pageCount}
        onPageChange={onPageChange}
      />
    </>
  );
};

export default List;
