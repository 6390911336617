// @flow
import React from "react";
import { getUrlDomain } from "util";
import { Icon } from "icons";
import { H1 } from "typography";
import ByLine from "./ByLine";
import Text from "./Text";
import Media from "./Media";
import styles from "./index.css";

import type { loadContent_content_variations as VariationData } from "graphql-types/loadContent";

import type {
  loadComposer_accounts as AccountData,
  loadComposer_accounts_pinterestBoards as PinterestBoard
} from "graphql-types/loadComposer";

import type { loadContent_content_variations_pageScrape as PageScrapeData } from "graphql-types/loadContent";

type Props = {
  account: AccountData,
  variation: VariationData,
  pageScrape: ?PageScrapeData,
  selectedPinterestBoardId: ?string
};

const TRAILING_LINK_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

const PinterestPost = ({
  account,
  variation,
  pageScrape,
  selectedPinterestBoardId
}: Props) => {
  const hasMedia = variation.images.length > 0 || variation.videos.length > 0;

  if (!hasMedia) {
    return (
      <div className={styles.loading}>Please attach an image or video.</div>
    );
  }

  const spb: PinterestBoard | void = account.pinterestBoards.find(
    (board: PinterestBoard) => board.id === selectedPinterestBoardId
  );
  const boardName = spb ? spb.name : "Select Board";

  const text = variation.text;
  let pinterestDescription = text;
  let pinterestTitle = variation.pinterestTitle;

  if (!pinterestTitle && pinterestDescription) {
    if (pinterestDescription.length > 0 && pinterestDescription.length < 100) {
      pinterestTitle = pinterestDescription;
      pinterestDescription = null;
    } else if (pageScrape?.title) {
      pinterestTitle = pageScrape?.title;
    }
  }

  if (!pinterestDescription?.length) {
    pinterestDescription = pageScrape?.description;
  }

  if (pinterestTitle) {
    pinterestTitle = pinterestTitle
      .trim()
      .replace(TRAILING_LINK_REGEX, "")
      .trim();
  }
  if (pinterestDescription) {
    pinterestDescription = pinterestDescription
      .trim()
      .replace(TRAILING_LINK_REGEX, "")
      .trim();
  }

  return (
    <div className={styles.root}>
      {hasMedia && (
        <div className={styles.mediaWrapper}>
          <Media images={variation.images} videos={variation.videos} />
        </div>
      )}
      <div className={styles.bodyWrapper}>
        <div className={styles.header}>
          <div className={styles.iconBar}>
            <Icon type="pen" className={styles.largerIcon} />
            <Icon type="ellipsis-h" className={styles.largerIcon} />
            <Icon
              base="fas"
              type="sign-out"
              animation="rotate-270"
              className={styles.largerIcon}
            />
          </div>
          <div className={styles.boardSelector}>
            <div className={styles.boardName}>
              <span>{boardName}</span>
              <Icon type="chevron-down" className={styles.icon} />
            </div>
            <div className={styles.boardSaveButton}>Save</div>
          </div>
        </div>
        {variation.pinterestDestinationLink && (
          <a
            className={styles.destinationLink}
            href={variation.pinterestDestinationLink}
            target="_blank"
          >
            {getUrlDomain(variation.pinterestDestinationLink)}
          </a>
        )}
        <div className={styles.body}>
          <div className={styles.bodyRight}>
            <div className={styles.bodyHeader}>
              {pinterestTitle && (
                <H1 className={styles.pinterestTitle}>{pinterestTitle}</H1>
              )}
            </div>
            {pinterestDescription && <Text value={pinterestDescription} />}
          </div>
        </div>
        <ByLine value={account} hasLink={!!pageScrape?.url} />

        <div className={styles.footerContainer}>
          <div className={styles.footer}>
            <span className={styles.activeFooterElement}>Photos</span>
            <span className={styles.inactiveFooterElement}>Comments</span>
          </div>
          <span className={styles.activeFooterElementUnderline}> </span>
        </div>
      </div>
    </div>
  );
};

export default PinterestPost;
