/* @flow */
import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 12.5px;
`;

export const Text = styled.div`
  margin: 0 0 12.5px;
  text-align: center;
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  margin: 20px 0 0;

  > a {
    margin: 0 5px;
  }
`;
