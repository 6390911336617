/* @flow */
import React from "react";
import emptySchedule from "./empty_schedule.svg";
import styles from "./index.css";

type Props = {
  children: any
};

const EmptyState = ({ children }: Props) => (
  <div className={styles.root}>
    {children}
    <img className={styles.octoImage} src={emptySchedule} />
  </div>
);

export default EmptyState;
