/* @flow */
import React from "react";

import SVGBackIcon from "./icon-back.svg";
import SVGCloseIcon from "./icon-close.svg";

import type { IProps } from "./types.js";
import { Wrapper, Top, Close } from "./styles";

const PostsModalHeader = (props: IProps) => {
  const { hideModal } = props;

  return (
    <Wrapper>
      <Top>
        <h4 onClick={hideModal}>
          <img src={SVGBackIcon} alt="back icon" />
          Add new time slot
        </h4>

        <Close onClick={hideModal}>
          <img src={SVGCloseIcon} alt="close icon" />
        </Close>
      </Top>

      <h3>Posts library</h3>
    </Wrapper>
  );
};

export default PostsModalHeader;
