// @flow
import React from "react";

import Two from "./Two";
import Three from "./Three";
import Four from "./Four";

type Props = {
  urls: string[]
};

const ImageCollage = ({ urls }: Props) => (
  <div>
    {urls.length === 2 && <Two urls={urls} />}
    {urls.length === 3 && <Three urls={urls} />}
    {urls.length >= 4 && <Four urls={urls} />}
  </div>
);

export default ImageCollage;
