/* @flow */
import React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import { formatCurrency } from "util";

import tooltipper from "behavioral/tooltipper";

import GET_INVOICES from "queries/getInvoices";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Message from "layout/Message";
import Link from "links/Link";
import LoadingIcon from "icons/LoadingIcon";
import ErrorWithRetry from "components/ErrorWithRetry";
import Icon from "icons/Icon";
import Caption from "typography/Caption";

import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";
import type { getInvoices as GetInvoices } from "graphql-types/getInvoices";

import styles from "./index.css";

type OwnProps = {
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  data?: GetInvoices,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>,
  error?: string,
  loading?: boolean
} & OwnProps;

const TipBody = () => {
  return (
    <Caption textAlign="center">Showing invoices for the last 2 years</Caption>
  );
};

const Info = () => {
  return <Icon className={styles.info} type="info-circle" />;
};

const TooltippedInfo = tooltipper(TipBody)(Info);

export const InvoicesMain = ({ data, error, loading }: Props) => {
  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (error) {
    return <ErrorWithRetry>{error}</ErrorWithRetry>;
  }
  const invoices = data?.invoices?.data || [];
  if (invoices.length < 1) {
    // This case should not happen, but just in case...
    return <span>No invoices.</span>;
  }
  return (
    <div className={styles.section}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Coupon</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => {
            return (
              <tr key={index}>
                <td>
                  {invoice.paid && invoice.id ? (
                    <a href={`/user/invoices/${invoice.id}`}>
                      {invoice.dueDate}
                    </a>
                  ) : (
                    invoice.dueDate
                  )}
                </td>
                <td>{formatCurrency(invoice.total)}</td>
                <td>{invoice.coupon}</td>
                <td>{invoice.status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export const Invoices = (props: Props) => {
  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      messages={
        props.data?.user?.company?.queueStatus === "PAUSED" && (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from the{" "}
            <Link href="/queue">Queue</Link> section.
          </Message>
        )
      }
      onboardingProgress={props.data?.user?.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      isNewHeader
      header={<PageTitle extras={<TooltippedInfo />}>Invoices</PageTitle>}
    >
      <InvoicesMain {...props} />
    </App>
  );
};

const InvoicesWrapper = (ownProps: OwnProps) => {
  const { data, loading, error } = useQuery(GET_INVOICES);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);
  if (loading) {
    return <Invoices {...ownProps} loading />;
  }
  if (error) {
    return <Invoices {...ownProps} error="Uh-oh something went wrong 😿" />;
  }
  return (
    <Invoices
      {...ownProps}
      data={data}
      dismissOnboarding={dismissOnboarding}
      error={error}
      loading={loading}
    />
  );
};

export default InvoicesWrapper;
