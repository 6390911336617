/* @flow */
import React from "react";
import { useQuery } from "@apollo/react-hooks";
import uniqBy from "lodash/uniqBy";
import withFilter from "behavioral/withFilter";
import Account from "./Account";
import Category from "./Category";
import Source from "./Source";
import DateCreated from "./DateCreated";
import Header from "./Header";
import UseOnce from "./UseOnce";
import Posted from "./Posted";
import SocialNetwork from "./SocialNetwork";
import ErrorWithRetry from "components/ErrorWithRetry";

import LOAD_FILTERS from "queries/loadFilters";
import type {
  loadFilters_categories as CategoryData,
  loadFilters_accounts as AccountData,
  loadFilters_rssFeeds as RssFeedData,
  loadFilters_teamMembers as TeamMemberData
} from "graphql-types/loadFilters";

import type { Location } from "react-router-dom";

type FiltersProps = {
  loading: boolean,
  categories: CategoryData[],
  accounts: AccountData[],
  rssFeeds: RssFeedData[],
  teamMembers: TeamMemberData[]
};

// location is set and passed down to ensure re-renders in cases where no other state changed
export const Filters = ({
  location,
  loading,
  showNoAccount,
  showPosted,
  dateFilterTitle,
  categories,
  accounts,
  rssFeeds,
  teamMembers
}: FiltersProps & Props) => (
  <div>
    <Header location={location} />
    <Category location={location} categories={categories} loading={loading} />
    <Source
      location={location}
      teamMembers={teamMembers}
      rssFeeds={rssFeeds}
      loading={loading}
    />
    <SocialNetwork
      location={location}
      platforms={uniqBy(
        accounts.map(a => ({
          value: a.platform,
          name: a.platformDisplayName
        })),
        "value"
      )}
      loading={loading}
    />
    <Account
      location={location}
      showNoAccount={showNoAccount}
      accounts={accounts}
      loading={loading}
    />
    <UseOnce location={location} />
    {showPosted && <Posted location={location} />}
    <DateCreated title={dateFilterTitle} location={location} />
  </div>
);

type Props = {
  location: Location,
  showNoAccount: boolean,
  showPosted: boolean,
  dateFilterTitle: string
};
const FilterWrapper = (props: Props) => {
  const { data, loading, error } = useQuery(LOAD_FILTERS);

  if (error) {
    return <ErrorWithRetry>Failed to load filters</ErrorWithRetry>;
  }

  return (
    <Filters
      {...props}
      loading={loading}
      categories={data?.categories ?? []}
      accounts={data?.accounts ?? []}
      rssFeeds={data?.rssFeeds ?? []}
      teamMembers={data?.teamMembers ?? []}
    />
  );
};

export default withFilter()(FilterWrapper);
