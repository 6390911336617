/* @flow */
import { gql } from "apollo-boost";

export default gql`
  query getShopifyAccount {
    shopifyAccount {
      id
      storeUrl
      accessToken
    }
  }
`;
