/* @flow */
import React from "react";
import { connect } from "react-redux";
import { useQuery, useMutation } from "@apollo/react-hooks";
import type { Match, Location, RouterHistory } from "react-router-dom";

import { createFlash } from "actions/flash";

import GET_ACCOUNT_CANCELLATION_DATA from "queries/getAccountCancellationData";
import DISMISS_ONBOARDING from "mutations/dismissOnboarding";
import CANCEL_SUBSCRIPTION from "mutations/cancelSubscription";

import App from "components/App";
import PageTitle from "links/PageTitle";
import Message from "layout/Message";
import Link from "links/Link";
import LoadingIcon from "icons/LoadingIcon";
import ErrorWithRetry from "components/ErrorWithRetry";
import H3 from "typography/H3";
import Label from "typography/Label";
import AccountCancellationModal from "components/AccountCancellation";

import type { Dispatch } from "types";
import type { FlashType } from "reducers/flash";
import type { Props as TopNavProps } from "components/TopNav";
import type { Props as SubscriptionStatusProps } from "components/SubscriptionStatus";
import type { dismissOnboarding as DismissOnboardingResult } from "graphql-types/dismissOnboarding";
import type { getAccountCancellationData as AccountCancellationData } from "graphql-types/getAccountCancellationData";
import type { cancelSubscription as CancelSubscriptionResult } from "graphql-types/cancelSubscription";

import styles from "./index.css";

type OwnProps = {
  flash: (type: FlashType, body: string) => void,
  history: RouterHistory,
  location: Location,
  match: Match,
  subscriptionStatus: SubscriptionStatusProps,
  topNav: TopNavProps
};

type Props = {
  cancelSubscription?: () => Promise<{ data: CancelSubscriptionResult }>,
  cancelSubscriptionLoading?: boolean,
  data?: AccountCancellationData,
  dismissOnboarding?: () => Promise<DismissOnboardingResult>,
  error?: string,
  loading?: boolean
} & OwnProps;

export const AccountCancellationMain = ({
  cancelSubscription,
  cancelSubscriptionLoading,
  data,
  error,
  flash,
  loading
}: Props) => {
  if (loading) {
    return <LoadingIcon className={styles.loading} />;
  }
  if (error) {
    return <ErrorWithRetry>{error}</ErrorWithRetry>;
  }
  return (
    <>
      <div className={styles.section}>
        <H3>Perhaps there's another way we can help?</H3>
        <ul>
          <li>
            <a href="https://help.meetedgar.com/billing/what-is-meetedgars-cancellation-and-refund-policy">
              What is Edgar's cancellation and refund policy?
            </a>
          </li>
          <li>
            <a href="https://help.meetedgar.com/billing/how-do-i-change-my-plan">
              How do I change my plan?
            </a>
          </li>
          <li>
            <a href="https://help.meetedgar.com/billing/how-much-does-edgar-cost">
              How much does Edgar cost?
            </a>
          </li>
          <li>
            <a href="https://help.meetedgar.com/edgar-s-billing-plans-and-pricing/plans-and-pricing/non-profit-and-military-discount">
              Does Edgar offer a non-profit pricing discount?
            </a>
          </li>
        </ul>
      </div>
      <div className={styles.section}>
        <H3>Are you sure?</H3>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Account</Label>
          <span>{data?.user?.email}</span>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Items in library</Label>
          <span>{data?.user?.company?.totalApprovedContents}</span>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Content posted</Label>
          <span>{data?.user?.company?.totalPosts}</span>
        </div>
        <div className={styles.sectionField}>
          <Label className={styles.sectionFieldLabel}>Customer since</Label>
          <span>{data?.user?.company?.formattedCreatedAt}</span>
        </div>
        <div className={styles.sectionField}>
          <AccountCancellationModal
            cancelSubscription={cancelSubscription}
            cancelSubscriptionLoading={cancelSubscriptionLoading}
            flash={flash}
          />
        </div>
      </div>
    </>
  );
};

export const AccountCancellation = (props: Props) => {
  return (
    <App
      loggedIn
      topNav={props.topNav}
      subscriptionStatus={props.subscriptionStatus}
      messages={
        props.data?.user?.company?.queueStatus === "PAUSED" && (
          <Message>
            Edgar is currently paused. Edgar will not post anything to your
            social accounts until you unpause posting from the{" "}
            <Link href="/queue">Queue</Link> section.
          </Message>
        )
      }
      onboardingProgress={props.data?.user?.onboardingProgress}
      onDismissOnboarding={props.dismissOnboarding}
      isNewHeader
      header={<PageTitle>Close my account</PageTitle>}
    >
      <AccountCancellationMain {...props} />
    </App>
  );
};

const AccountCancellationWrapper = (ownProps: OwnProps) => {
  const { loading, error, data } = useQuery(GET_ACCOUNT_CANCELLATION_DATA);
  const [dismissOnboarding] = useMutation(DISMISS_ONBOARDING);
  const [
    cancelSubscription,
    { loading: cancelSubscriptionLoading }
  ] = useMutation(CANCEL_SUBSCRIPTION, {
    onError(): void {
      ownProps.flash(
        "error",
        "An error occurred when attempting to cancel your subscription."
      );
    }
  });
  if (loading) {
    return <AccountCancellation {...ownProps} loading />;
  }
  if (error) {
    return (
      <AccountCancellation
        {...ownProps}
        error="Uh-oh something went wrong 😿"
      />
    );
  }
  return (
    <AccountCancellation
      {...ownProps}
      cancelSubscription={cancelSubscription}
      cancelSubscriptionLoading={cancelSubscriptionLoading}
      data={data}
      dismissOnboarding={dismissOnboarding}
      error={error}
      loading={loading}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    flash(type, body) {
      dispatch(createFlash(type, body));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(AccountCancellationWrapper);
