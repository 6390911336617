// @flow
import React, { Component, type Node } from "react";
import classNames from "classnames";
import { uniqueId } from "lodash";
import tooltipper from "behavioral/tooltipper";
import Label from "typography/Label";
import checkboxChecked from "./images/checkboxChecked.svg";
import checkboxEmpty from "./images/checkboxEmpty.svg";
import newCheckboxChecked from "./images/newCheckboxChecked.svg";
import newCheckboxEmpty from "./images/newCheckboxEmpty.svg";
import styles from "./index.css";

type Props = {
  value: boolean,
  label?: string,
  className?: string,
  labelClassName?: string,
  labelPlacement?: "right" | "bottom",
  extraMargin?: boolean,
  small?: boolean,
  disabled?: boolean,
  tooltip?: Node,
  hasError: boolean,
  onChange: (newValue: boolean) => void,
  newStyle?: boolean
};

type State = { htmlId: string };

export default class CheckboxInput extends Component<Props, State> {
  componentWillMount() {
    this.setState({ htmlId: uniqueId("checkbox-") });
  }

  handleChange: (evt: Event) => void = evt => {
    const { value, onChange } = this.props;
    evt.stopPropagation();
    onChange(!value);
  };

  handleKeyPress: (evt: KeyboardEvent) => void = evt => {
    if (evt.key === " ") {
      this.handleChange(evt);
    }
  };

  render() {
    const {
      props: {
        value,
        label,
        className,
        labelClassName,
        extraMargin,
        small,
        disabled,
        labelPlacement = "right",
        tooltip = null,
        hasError = false,
        newStyle = false
      },
      state: { htmlId }
    } = this;

    const rootClasses = classNames(styles.root, {
      [className || ""]: !!className,
      [styles[labelPlacement]]: true,
      [styles.rootExtraMargin]: extraMargin,
      [styles.small]: small
    });
    const imgClasses = classNames(styles.icon, {
      [styles.extraMargin]: extraMargin,
      [styles.hasError]: hasError
    });
    const imgAlt = value ? "checked checkbox" : "empty checkbox";

    const imgCheckboxChecked = newStyle ? newCheckboxChecked : checkboxChecked;
    const imgCheckboxEmpty = newStyle ? newCheckboxEmpty : checkboxEmpty;

    const imgSrc = value ? imgCheckboxChecked : imgCheckboxEmpty;

    const labelClass = classNames(labelClassName || styles.label, {
      [styles.labelDisabled]: disabled
    });

    return (
      <div className={rootClasses}>
        <input
          id={htmlId}
          type="checkbox"
          style={{ display: "none" }}
          checked={value}
          disabled={disabled}
          onChange={this.handleChange}
        />
        <TooltippedWrapper tooltipBody={tooltip}>
          <img
            className={imgClasses}
            src={imgSrc}
            alt={imgAlt}
            onClick={!disabled ? this.handleChange : null}
            onKeyPress={!disabled ? this.handleKeyPress : null}
            tabIndex="0"
            disabled={disabled}
          />
        </TooltippedWrapper>
        {label && (
          <Label className={labelClass} htmlFor={htmlId}>
            {label}
          </Label>
        )}
      </div>
    );
  }
}

const Wrapper = ({ children }) => <div>{children}</div>;

const TooltippedWrapper = tooltipper()(Wrapper);
