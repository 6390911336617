/* @flow */
import { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import moment from "moment";
import { get } from "lodash";

import { createFlash } from "actions/flash";
import DASHBOARD_MAIN_GRAPH from "queries/dashboardMainGraph";

// import { dataExample } from "./utils";
import type { Props } from "./types.js";

const useNewPageLikes = (props: Props) => {
  const { filters, selectedAccount } = props;

  const [lastFilters, setLastFilters] = useState();
  const [lastAccountId, setLastAccountId] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState(null);
  const [netGrowth, setNetGrowth] = useState(null);

  const apolloClient = useApolloClient();

  useEffect(() => {
    const handleData = async () => {
      if (!selectedAccount) return;
      setLoading(true);

      const accountId = parseInt(selectedAccount.id);

      const labels = [];
      const startDate = moment(filters.startDate);
      const endDate = moment(filters.endDate);
      const diff = endDate.diff(startDate, "days");
      for (let i = 0; i <= diff; i++) {
        labels.push(
          startDate
            .clone()
            .add(i, "days")
            .format("MMM DD")
        );
      }

      try {
        const res = await apolloClient.query({
          query: DASHBOARD_MAIN_GRAPH,
          variables: {
            startDate: filters.startDate,
            endDate: filters.endDate,
            accountId
          }
        });

        // data from api
        const graphData = get(res, "data.dashboardMainGraph.graphData", null);
        const netGrowthData = get(
          res,
          "data.dashboardMainGraph.netGrowth",
          null
        );

        // data example
        // const graphData = get(
        //   dataExample,
        //   "data.dashboardMainGraph.graphData",
        //   null
        // );
        // const netGrowthData = get(
        //   dataExample,
        //   "data.dashboardMainGraph.netGrowth",
        //   null
        // );

        setNetGrowth(netGrowthData);
        if (graphData && Object.keys(graphData).length > 0) {
          setGraphData({
            labels,
            datasets: [
              {
                fill: true,
                label: "New likes",
                data: Object.values(graphData),
                borderColor: "#DD4B6F",
                backgroundColor: "rgba(232, 139, 163, .05)",
                tension: 0.6
              }
            ]
          });
        }
      } catch (err) {
        setGraphData(null);
        setNetGrowth(null);
        props.dispatch(
          createFlash("alert", "Could not load data for New Page Likes.")
        );
      } finally {
        setLoading(false);
      }
    };

    if (
      (filters && filters.startDate !== lastFilters?.startDate) ||
      (filters && filters.endDate !== lastFilters?.endDate) ||
      selectedAccount?.id !== lastAccountId
    ) {
      setLastFilters(filters);
      setLastAccountId(get(selectedAccount, "id", undefined));
      handleData();
    }
  }, [
    apolloClient,
    filters,
    lastAccountId,
    lastFilters,
    props,
    selectedAccount
  ]);

  return { loading, graphData, netGrowth };
};

export default useNewPageLikes;
