/* @flow */
import React from "react";
import styles from "./index.css";
import moment from "moment";

type Props = {
  endDate: string
};

export const ReportLink = (props: Props) => {
  const { endDate } = props;
  const endDateTime = moment(endDate, "YYYY-MM-DD");
  const startDateTime = moment(endDate, "YYYY-MM-DD").subtract(6, "days");
  return (
    <li className={styles.root} key={endDate}>
      <a href={`/statistics/${endDate}`}>
        {startDateTime.format("MMM D YYYY")} &ndash;{" "}
        {endDateTime.format("MMM D YYYY")}
      </a>
    </li>
  );
};

export default ReportLink;
