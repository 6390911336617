/* @flow */
import React, { Component } from "react";
import classNames from "classnames";
import styles from "./index.css";

export type EndorsementData = {
  name: string,
  photo: string,
  logo: ?string,
  quote: string,
  logoName: ?string
};

type Props = {
  endorsement: EndorsementData,
  active: boolean
};

type State = {
  active: boolean
};

export class Endorsement extends Component<Props, State> {
  state: State = {
    active: false
  };

  render() {
    const {
      endorsement: { photo, name, logo, quote, logoName },
      active
    } = this.props;
    const classes = classNames(styles.root, {
      [styles.active]: active,
      [styles.inactive]: !active
    });

    return (
      <div className={classes} aria-hidden={!active}>
        <div className={styles.wrapper}>
          <img src={photo} alt={name} className={styles.photo} />
          <blockquote className={styles.quote}>
            &ldquo;{quote}&rdquo;
          </blockquote>
          <p className={styles.citation}>{name}</p>
          {logo && <img src={logo} alt={logoName} className={styles.logo} />}
        </div>
      </div>
    );
  }
}

export default Endorsement;
