// @flow
import React, { type ComponentType } from "react";
import qs from "qs";
import { withRouter } from "react-router-dom";
import { extractFilter, patchNextSearchFilter } from "./helpers";

import type { Match, Location, RouterHistory } from "react-router-dom";

export type ContentFilter = {
  keywords?: ?string,
  category?: ?string,
  status?: ?string,
  account?: ?string,
  platform?: ?string,
  creator?: ?string,
  feed?: ?string,
  posted?: ?string,
  startDate?: ?string,
  hasPublishableVariations?: boolean,
  endDate?: ?string
};

export function parseFilterParams(match: Match, location: Location) {
  const status = match.params.status;
  const queryString = location.search;
  let { page = 1, filter = {}, ...other } = qs.parse(queryString, {
    ignoreQueryPrefix: true
  });

  if (filter.useOnce) {
    filter.useOnce = JSON.parse(filter.useOnce);
  }
  if (filter.hasPublishableVariations) {
    filter.hasPublishableVariations = JSON.parse(
      filter.hasPublishableVariations
    );
  }
  if (filter.platform) {
    filter.platform = filter.platform.toUpperCase();
  }
  filter.status = (status || "approved").toUpperCase();

  return { ...other, filter, page };
}

export function buildPaginator(pageNum: number, perPage: number) {
  return { offset: (pageNum - 1) * perPage, limit: perPage };
}

export function handlePageChange(
  match: Match,
  location: Location,
  history: RouterHistory,
  refetchFn: ({
    filter: ContentFilter,
    page: { limit: number, offset: number }
  }) => void,
  perPage: number,
  nextPage: number
) {
  const currParams = parseFilterParams(match, location);
  if (currParams.filter) {
    delete currParams.filter.status;
  }
  const nextParams = { ...currParams, page: nextPage };
  const { filter } = nextParams;
  const nextUrlParams =
    filter && filter.platform
      ? {
          ...nextParams,
          filter: { ...filter, platform: filter.platform.toLowerCase() }
        }
      : nextParams;
  window.scrollTo(0, 0);
  history.push(
    `${location.pathname}${qs.stringify(nextUrlParams, {
      addQueryPrefix: true
    })}`
  );
  refetchFn({
    filter: nextParams.filter,
    page: buildPaginator(nextPage, perPage)
  });
}

type Props = {
  location: Location,
  match: Match,
  history: any // https://github.com/flowtype/flow-typed/pull/284 Maybe add if extra time?
};

type HoF = (WrappedComponent: ComponentType<any>) => ComponentType<any>;
const withFilter = (): HoF => WrappedComponent => {
  const composedComponent = class extends React.Component<Props> {
    handleFilterPatch = (changes: ContentFilter) => {
      const {
        location: { pathname, search },
        history
      } = this.props;
      const nextSearch = patchNextSearchFilter(search, changes);
      const nextUrl = `${pathname}?${nextSearch}`;
      history.push(nextUrl);
    };

    getFilter = (): ContentFilter => {
      const { match, location } = this.props;
      return extractFilter(match, location);
    };

    render() {
      return (
        <WrappedComponent
          filter={this.getFilter()}
          {...this.props}
          onFilterPatch={this.handleFilterPatch}
        />
      );
    }
  };
  return withRouter(composedComponent);
};

export default withFilter;
