import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 0;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin: 16px 0 0;

  span {
    font-weight: 300;
    opacity: 0.9;
  }
`;
