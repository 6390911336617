// @flow
import React from "react";
import { SCRAPE_STATUSES } from "constants";
import LoadingIcon from "icons/LoadingIcon";
import ByLine from "./ByLine";
import Text from "./Text";
import LinkPreview from "./LinkPreview";
import Media from "./Media";
import styles from "./index.css";
import type {
  loadContent_content_variations as VariationData,
  loadContent_content_accountRelationships_account as Account
} from "graphql-types/loadContent";
import type { scrapeUrl_scrapeUrl_pageScrape as PageScrapeData } from "graphql-types/scrapeUrl";

type Props = {
  account: Account,
  variation: VariationData,
  mentions: string[],
  pageScrape?: PageScrapeData
};

const renderLinkPreview = (variation, pageScrape) => {
  let image;

  if (pageScrape.url && pageScrape.status === SCRAPE_STATUSES.OK) {
    if (pageScrape?.images?.length > 0) {
      image = pageScrape.images[0].src;
    }
    return (
      <LinkPreview
        title={pageScrape.title}
        description={pageScrape.description}
        url={pageScrape.url}
        destinationUrl={pageScrape.destinationUrl}
        omitDestination
        image={image}
      />
    );
  } else if (pageScrape.status === SCRAPE_STATUSES.WAITING) {
    return (
      <div className={styles.root}>
        <LoadingIcon /> Checking for Facebook story…
      </div>
    );
  }

  return null;
};

const TikTokPost = ({ variation, account, mentions, pageScrape }: Props) => {
  const FB_TEXT_ORIGINS = [
    "RSS_ONE_OFF",
    "RSS_SCHEDULED",
    "RSS",
    "WIDGET",
    "AUTOGENERATED"
  ];
  const hasAttachment =
    variation.images.length > 0 || variation.videos.length > 0;
  const text = FB_TEXT_ORIGINS.includes(variation.origin)
    ? variation.fbText
    : variation.text;

  return (
    <div className={styles.root}>
      <ByLine value={account} hasLink={!!pageScrape?.url} />
      {text && (
        <Text
          value={text}
          hasAttachment={hasAttachment}
          provider={account.provider}
          mentions={mentions}
          rawRichTextEntityMap={variation.rawRichTextEntityMap}
        />
      )}
      {hasAttachment && (
        <Media images={variation.images} videos={variation.videos} />
      )}
      {!hasAttachment && pageScrape && renderLinkPreview(variation, pageScrape)}
    </div>
  );
};

export default TikTokPost;
