// @flow
import * as api from "api";

import type { ThunkedAction } from "types";
import type { UserData } from "reducers/users";
import { ApiError } from "errors";
import { track } from "util";

export const START_DISMISS_INFORMATION_BOX = "START_DISMISS_INFORMATION_BOX";
export const FINISH_DISMISS_INFORMATION_BOX = "FINISH_DISMISS_INFORMATION_BOX";
export const FAIL_DISMISS_INFORMATION_BOX = "FAIL_DISMISS_INFORMATION_BOX";

// Dismiss Information Box
//
type StartDismissInformationBox = {
  type: "START_DISMISS_INFORMATION_BOX",
  payload: string
};
export const startDismissInformationBox = (name: string) =>
  ({
    type: START_DISMISS_INFORMATION_BOX,
    payload: name
  }: StartDismissInformationBox);

type FinishDismissInformationBox = {
  type: "FINISH_DISMISS_INFORMATION_BOX",
  payload: UserData
};
export const finishDismissInformationBox = (data: UserData) =>
  ({
    type: FINISH_DISMISS_INFORMATION_BOX,
    payload: data
  }: FinishDismissInformationBox);

type FailDismissInformationBox = {
  type: "FAIL_DISMISS_INFORMATION_BOX",
  payload: Object,
  error: boolean,
  meta: { error: Object }
};
export const failDismissInformationBox = (err: ApiError) =>
  ({
    type: FAIL_DISMISS_INFORMATION_BOX,
    payload: (err && err.body) || {},
    error: true,
    meta: { error: err }
  }: FailDismissInformationBox);

export const dismissInformationBox = (
  name: string
): ThunkedAction => dispatch => {
  track("Information box dismissed", { name });

  dispatch(startDismissInformationBox(name));

  const fail = (err: ApiError) => dispatch(failDismissInformationBox(err));

  return api
    .dismissInformationBox(name)
    .then(response => {
      if (!response) {
        return fail(new ApiError("An unknown error occured.", -1, {}));
      }
      return dispatch(finishDismissInformationBox(response.data));
    })
    .catch(fail);
};

export type Action =
  | StartDismissInformationBox
  | FinishDismissInformationBox
  | FailDismissInformationBox;
