/* @flow */
import { uniq } from "lodash";
import invariant from "invariant";
import { parseTweet } from "twitter-text";

import { ensureEnum, lengthInUtf8Bytes } from "util";
import {
  MAX_TWITTER_LENGTH,
  MAX_LINKEDIN_LENGTH,
  MAX_PINTEREST_LENGTH,
  MAX_INSTAGRAM_LENGTH,
  MAX_GOOGLE_LENGTH,
  MAX_THREADS_LENGTH
} from "constants";

import type {
  Provider,
  Platform,
  loadContent_content_variations_images as ImageData,
  loadContent_content_variations as VariationData,
  loadContent_content_variations_pageScrape as PageScrapeData
} from "graphql-types/loadContent";
import type { loadComposer_accounts as AccountData } from "graphql-types/loadComposer";

const countForTwitter = text => parseTweet(text).weightedLength;
const LIMITS_BY_PROVIDER = {
  TWITTER: { max: MAX_TWITTER_LENGTH, getCount: countForTwitter },
  LINKEDIN: { max: MAX_LINKEDIN_LENGTH },
  LINKEDIN_COMPANY: { max: MAX_LINKEDIN_LENGTH },
  PINTEREST: { max: MAX_PINTEREST_LENGTH },
  INSTAGRAM_BUSINESS: { max: MAX_INSTAGRAM_LENGTH },
  INSTAGRAM_PERSONAL: { max: MAX_INSTAGRAM_LENGTH },
  GOOGLE_BUSINESS: { max: MAX_GOOGLE_LENGTH },
  THREADS: { max: MAX_THREADS_LENGTH }
};

function getDisplayName(provider, accounts) {
  const account = accounts.find(it => it.provider === provider);
  invariant(account, "Account expected");
  return account.providerDisplayName;
}

type CharacterCounts = {
  [key: Provider]: number
};

export function remainingCharacterCounts(
  selectedAccounts: AccountData[],
  text: string
): CharacterCounts {
  const selectedProviders = uniq(selectedAccounts.map(a => a.provider));
  return selectedProviders
    .filter(provider => Object.keys(LIMITS_BY_PROVIDER).includes(provider))
    .reduce((memo, current) => {
      const provider = ensureEnum(current, Object.keys(LIMITS_BY_PROVIDER));
      const providerLimit = LIMITS_BY_PROVIDER[provider];
      const getCount = providerLimit.getCount
        ? providerLimit.getCount
        : lengthInUtf8Bytes;
      const remaining = providerLimit.max - getCount(text);
      memo[getDisplayName(provider, selectedAccounts)] = remaining;
      return memo;
    }, {});
}

export function fbTextVisible(
  { origin, rawRichTextEntityMap, fbText }: VariationData,
  selectedPlatforms: Platform[]
): boolean {
  const shouldShowFbText = !!origin.match("RSS|WIDGET|AUTOGENERATED");
  const mentionsPresent =
    !!rawRichTextEntityMap && !!rawRichTextEntityMap.entityMap;
  const hadFbMentions =
    !selectedPlatforms.length && !!fbText && mentionsPresent;
  return (
    shouldShowFbText &&
    (selectedPlatforms.includes("FACEBOOK") || hadFbMentions)
  );
}

export const DISALLOW_LINK_ATTACHMENT_PLATFORMS = ["PINTEREST", "INSTAGRAM"];
export const LINK_ATTACHMENT_PLATFORMS = ["FACEBOOK", "LINKEDIN"];
export function platformsSupportLinkPreview(platforms: Platform[]) {
  return (
    platforms.some(p => LINK_ATTACHMENT_PLATFORMS.includes(p)) &&
    !platforms.some(p => DISALLOW_LINK_ATTACHMENT_PLATFORMS.includes(p))
  );
}

export type MediaUpload = { fileName: string, progress: ?number };
export function hasLinkAttachment(
  variation: VariationData,
  platforms: Platform[] = [],
  pageScrape: ?PageScrapeData,
  uploadingMedia: MediaUpload[] = [],
  linkAttachmentRemoved: boolean = false
) {
  return (
    mayHaveLinkAttachment(platforms, pageScrape) &&
    !linkAttachmentRemoved &&
    uploadingMedia.length == 0 &&
    (variation.videos || []).length === 0 &&
    (variation.images || []).length === 0
  );
}

export function mayHaveLinkAttachment(
  platforms: Platform[],
  pageScrape: ?PageScrapeData
) {
  return pageScrape?.url && platformsSupportLinkPreview(platforms);
}

export function onlyOneGIFAllowedForPlatform(
  platforms: Platform[],
  images: ImageData[]
) {
  return (
    (platforms.includes("FACEBOOK") || platforms.includes("TWITTER")) &&
    images?.[0]?.format === "image/gif"
  );
}
