// @flow
import React, { type Node } from "react";
import classNames from "classnames";
import Icon from "icons/Icon";
import styles from "./index.css";

export const ALERT_TYPE_MAP = {
  notice: "",
  info: "",
  error: "",
  alert: "",
  twitter: "",
  facebook: "",
  instagram: "",
  pinterest: ""
};

export type AlertType = $Keys<typeof ALERT_TYPE_MAP>;
export const ALERT_TYPES: Array<AlertType> = Object.keys(ALERT_TYPE_MAP);

type Props = {
  type: AlertType,
  clear?: boolean,
  bland?: boolean,
  small?: boolean,
  children?: Node,
  className?: string,
  onDismiss?: () => void
};

const iconMap = {
  notice: "check",
  info: "info-circle",
  alert: "exclamation-triangle",
  error: "ban",
  twitter: "twitter",
  facebook: "facebook-official",
  instagram: "instagram",
  pinterest: "pinterest"
};

const Alert = ({
  type,
  clear,
  bland,
  small,
  children,
  className = "",
  onDismiss
}: Props) => (
  <div
    className={classNames(styles.root, styles[type], {
      [className]: !!className,
      [styles.clear]: !!clear,
      [styles.bland]: !!bland,
      [styles.small]: !!small
    })}
  >
    <Icon type={iconMap[type]} className={styles.icon} />

    <div className={styles.body}>{children}</div>

    {onDismiss && (
      <Icon type="times" className={styles.closeButton} onClick={onDismiss} />
    )}
  </div>
);

export default Alert;
