/* @flow */
import { useState, useEffect } from "react";

import { featureFlag } from "util";

const useApp = () => {
  const [useNewUI, setUseNewUI] = useState(false);

  useEffect(() => {
    const fetchGetNewUI = () => {
      if (!featureFlag("navbar_modules_ui")) {
        setUseNewUI(false);
        localStorage.setItem("navbar_modules_ui", "false");
      } else {
        const dataFromLocal = localStorage.getItem("navbar_modules_ui");
        if (dataFromLocal && dataFromLocal === "true") setUseNewUI(true);
      }
    };

    fetchGetNewUI();
  }, []);

  return { useNewUI };
};

export default useApp;
