import { useState } from "react";

import { IProps } from "./types";

import type { getAccounts_accounts } from "graphql-types/getAccounts";

const useInboxAccount = (props: IProps) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const closeDropdown = () => {
    if (dropdownVisible) setDropdownVisible(false);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const selectAccount = (account: getAccounts_accounts) => {
    closeDropdown();
    props.selectAccount(account);
  };

  return {
    dropdownVisible,
    closeDropdown,
    toggleDropdown,
    selectAccount
  };
};

export default useInboxAccount;
