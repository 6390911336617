// @flow
import React, { Component } from "react";
import invariant from "invariant";
import DatePicker from "react-datepicker";
import CustomInput from "./CustomInput";
// $FlowFixMe
import "react-datepicker/dist/react-datepicker.css";

import styles from "./index.css";

export type popperPlacement = "auto" | "top" | "right" | "bottom" | "left";
type Props = {
  value?: Object,
  placeholder?: string,
  minValue?: Object,
  disabled?: boolean,
  popperPlacement?: popperPlacement,
  onChange: (newValue: Object) => void
};

export default class DateInput extends Component<Props> {
  picker: ?DatePicker;

  componentWillReceiveProps(nextProps: Props) {
    // HACK - Explicitly call ReactDatePicker's internal API to simulate a select performed
    // from the picker itself. Otherwise the <input /> can be rendered with a stale value.
    if (nextProps.disabled === false && this.props.disabled === true) {
      invariant(this.picker, "Expected ReactDatePicker ref to be present.");
      // $FlowFixMe
      this.picker.handleSelect(nextProps.value);
    }
  }

  render() {
    const {
      value,
      placeholder,
      minValue,
      disabled,
      popperPlacement = "bottom",
      onChange
    } = this.props;

    const customInput = <CustomInput placeholderText={placeholder} />;
    return (
      <DatePicker
        customInput={customInput}
        ref={node => (this.picker = node)}
        selected={value}
        minDate={minValue}
        disabled={disabled}
        autoComplete="off"
        popperPlacement={popperPlacement}
        popperClassName={styles.popper}
        onChange={onChange}
      />
    );
  }
}
