/* @flow */
import { useState } from "react";

import type { Props } from "./types.js";

const useImportManager = (props: Props) => {
  const { location } = props;

  const shopifyParams = new URLSearchParams(location.search);
  const authorizationSuccess = shopifyParams.get("authorization_success");

  const [shopifyModalVisible, setShopifyModalVisible] = useState(true);

  const hideShopifyModal = () => {
    setShopifyModalVisible(false);
    window.history.replaceState({}, "", "/import");
  };

  return { authorizationSuccess, shopifyModalVisible, hideShopifyModal };
};

export default useImportManager;
