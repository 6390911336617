import ZapierIcon from "components/common/images/zapier-icon.svg";
import BookmarkletIcon from "components/common/images/bookmarklet-icon.svg";
import ChromeIcon from "components/common/images/chrome-icon.svg";
import SpreadsheetIcon from "components/common/images/spreadsheet-icon.svg";
import PinterestIcon from "components/common/images/pinterest-icon.svg";
import VimeoIcon from "components/common/images/vimeo-icon.svg";
import BlogIcon from "components/common/images/rss-icon.svg";
import EtsyIcon from "components/common/images/etsy.svg";
import ShopifyIcon from "components/common/images/shopify-icon.png";
import YouTubeIcon from "components/common/images/youtube.svg";
import WordPressIcon from "components/common/images/wordpress.svg";
import MediumIcon from "components/common/images/medium.svg";
import PodcastsIcon from "components/common/images/podcasts.svg";

export const availableConnections = [
  {
    title: "Zapier",
    description: "Connect Pocket, Feedly & more!",
    addtlInfoUrl: "https://help.meetedgar.com/en/articles/281178-edgar-zapier",
    addtlInfoText: "See how.",
    imageSource: ZapierIcon,
    isRecommended: false,
    targetUrl: "https://zapier.com/apps/edgar/integrations",
    type: "ZAPIER",
    connectModalCopy: null,
    displayPriority: 5
  },
  {
    title: "FireFox & Safari bookmarklet",
    description:
      "Add an Edgar button to FireFox or Safari to add content with one click while browsing the web.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#chrome-extension-and-firefox-or-safari-bookmarklet",
    addtlInfoText: "Learn more.",
    imageSource: BookmarkletIcon,
    isRecommended: true,
    targetUrl: "https://meetedgar.com/apps-and-integrations/",
    type: "BKMRKLT_FF_SAF",
    connectModalCopy: null,
    displayPriority: 3
  },
  {
    title: "Chrome extension",
    description:
      "Add an Edgar button to Chrome to add content with one click while browsing the web.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#chrome-extension-and-firefox-or-safari-bookmarklet",
    addtlInfoText: "Learn more.",
    imageSource: ChromeIcon,
    isRecommended: true,
    targetUrl: "https://meetedgar.com/apps-and-integrations/",
    type: "EXT_CHROME",
    connectModalCopy: null,
    displayPriority: 2
  },
  {
    title: "Import a spreadsheet (.csv)",
    description: "Import a .csv spreadsheet to create content quickly.",
    addtlInfoUrl:
      "https://help.meetedgar.com/edgar-s-features-and-best-practices/add-content-to-edgar/bulk-import",
    addtlInfoText: "Learn how to format your .csv file.",
    imageSource: SpreadsheetIcon,
    isRecommended: false,
    targetUrl: null,
    type: "CSV",
    connectModalCopy: null,
    displayPriority: 4
  },
  {
    title: "Pinterest",
    description: "Import content from a Pinterest feed or board automatically.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-pinterest-feed",
    addtlInfoText: "Learn more.",
    imageSource: PinterestIcon,
    isRecommended: false,
    targetUrl: null,
    type: "PINTEREST",
    connectModalCopy: [
      {
        heading: "Connect a Pinterest feed",
        description:
          "Enter your Pinterest username (or the username of a Pinterest user you like to share). For example, Edgar's Pinterest user name is meetedgar, which you can find at the end of Edgar's profile URL: https://www.pinterest.com/meetedgar",
        helpLinkText:
          "Learn more about importing Pinterest feeds or Pinterest boards.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-pinterest-feed",
        placeholder: "yourusername",
        errorText:
          "Edgar couldn't find a Pinterest feed for that username. Double check that the username is correct.",
        errorAddtlInfoText:
          "Learn more about finding a Pinterest user's username.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-pinterest-feed"
      },
      {
        heading: "Connect a Pinterest feed",
        description:
          "Enter the link to a Pinterest board to import content from that specific board, for example: https://www.pinterest.com/meetedgar/ultimate-social-media-how-to",
        helpLinkText:
          "Learn more about importing Pinterest feeds or Pinterest boards.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-pinterest-feed",
        placeholder: "https://www.pinterest.com/yourusername/yourboardname",
        errorText:
          "Edgar couldn't find a Pinterest feed for that board name. Double check that the board name is correct.",
        errorAddtlInfoText:
          "Learn more about finding a Pinterest user's board link.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-pinterest-feed"
      }
    ],
    configConnectionCopy: {
      heading: "Pinterest feed"
    },
    displayPriority: 12
  },
  {
    title: "Vimeo",
    description:
      "Import content from Vimeo channels or a channel's liked videos automatically.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-vimeo-channel",
    addtlInfoText: "Learn more.",
    imageSource: VimeoIcon,
    isRecommended: false,
    targetUrl: null,
    type: "VIMEO",
    connectModalCopy: [
      {
        heading: "Connect a Vimeo channel or likes",
        description:
          "Enter your Vimeo channel's username to import content you uploaded to your channel or content your channel has liked.",
        helpLinkText:
          "Learn more about importing from your channel or likes feed.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-vimeo-channel",
        placeholder: "yourchannelusername",
        errorText:
          "Edgar wasn't able to locate a feed for that channel name. Double check the channel name is correct or ",
        errorAddtlInfoText: "learn more about finding your channel name here.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-vimeo-channel"
      }
    ],
    configConnectionCopy: {
      heading: "Channel feed"
    },
    displayPriority: 11
  },
  {
    title: "Connect a blog",
    description:
      "Auto-import content daily from any website or blog with an RSS feed.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-blog",
    addtlInfoText: "Learn more.",
    imageSource: BlogIcon,
    isRecommended: true,
    targetUrl: null,
    type: "BLOG",
    connectModalCopy: [
      {
        heading: "Connect a blog",
        description:
          "Enter the link to your blog or a blog you like to share with your followers. Edgar will check the link for an RSS feed he can grab.",
        helpLinkText: null,
        helpLink: null,
        placeholder: "http://www.yourwebsitename.com",
        errorText:
          "Edgar couldn't find a feed at this location. To import from a blog or website, the website must have a valid RSS feed installed on it.",
        errorAddtlInfoText: "Learn more about RSS feeds.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-blog"
      }
    ],
    configConnectionCopy: {
      heading: "Feed URL"
    },
    displayPriority: 1
  },
  {
    title: "Etsy",
    description: "Import your Etsy shop listings automatically.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-an-etsy-shop",
    addtlInfoText: "Learn more.",
    imageSource: EtsyIcon,
    isRecommended: false,
    targetUrl: null,
    type: "ETSY",
    connectModalCopy: [
      {
        heading: "Import your Etsy listings",
        description:
          "Enter the URL for your Etsy shop to import your 10 most recent listings. Edgar will continue to import new listings automatically in the future.",
        helpLinkText: "Learn more.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-an-etsy-shop",
        placeholder: "https://www.etsy.com/shop/YourShopName",
        errorText:
          "Edgar couldn't find a shop at that URL. Double check the URL is correct for your main shop page (not an individual item listing).",
        errorAddtlInfoText: "Learn more.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-an-etsy-shop"
      }
    ],
    configConnectionCopy: {
      heading: "Etsy shop feed"
    },
    displayPriority: 10
  },
  {
    title: "Shopify",
    description: "Auto-import content from your Shopify store.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-shopify-shop",
    addtlInfoText: "Learn more.",
    imageSource: ShopifyIcon,
    isRecommended: true,
    targetUrl: null,
    type: "SHOPIFY",
    connectModalCopy: [
      {
        heading: "Import your Shopify listings",
        description:
          "Enter the URL for your Shopify store to import product information. Edgar will automatically create content based on the product listed in your shopify store.",
        helpLinkText: "Learn more.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-shopify-shop",
        placeholder: "yourapp.myshopify.com",
        errorText:
          "Edgar couldn't find a shop at that URL. Double check the URL is correct for your main shop page (not an individual item listing).",
        errorAddtlInfoText: "Learn more.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-an-shopify-shop"
      }
    ],
    configConnectionCopy: {
      heading: "Etsy shop feed"
    },
    displayPriority: 0
  },
  {
    title: "YouTube",
    description: "Connect a YouTube channel to import videos automatically.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-youtube-channel",
    addtlInfoText: "Learn more.",
    imageSource: YouTubeIcon,
    isRecommended: false,
    targetUrl: null,
    type: "YOUTUBE",
    connectModalCopy: [
      {
        heading: "Connect a YouTube channel",
        description:
          "Enter the link to your YouTube channel (or a channel you like to share) below. For example, Edgar's YouTube channel is https://www.youtube.com/Meetedgar.",
        helpLinkText: "Learn more.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-youtube-channel",
        placeholder: "https://www.youtube.com/youryoutubechannelid",
        errorText:
          "Edgar couldn't find a YouTube channel for that URL. Double check that the channel ID is correct in the link.",
        errorAddtlInfoText: "Learn more about finding your channel ID.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-youtube-channel"
      }
    ],
    configConnectionCopy: {
      heading: "YouTube channel"
    },
    displayPriority: 7
  },
  {
    title: "WordPress",
    description: "Import content automatically from your WordPress site.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-wordpress-site",
    addtlInfoText: "Learn more.",
    imageSource: WordPressIcon,
    isRecommended: false,
    targetUrl: null,
    type: "WORDPRESS",
    connectModalCopy: [
      {
        heading: "Connect a WordPress site",
        description:
          "Enter the link to your WordPress website below. Your site must have an RSS feed enabled on it for Edgar to import content.",
        helpLinkText: "Learn more.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-wordpress-site",
        placeholder: "http://www.yourwordpresssite.com/",
        errorText:
          "Edgar couldn't find a feed at this location. To import from a blog or website, the website must have a valid RSS feed installed on it.",
        errorAddtlInfoText: "Learn more about RSS feeds.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-wordpress-site"
      }
    ],
    configConnectionCopy: {
      heading: "Feed URL"
    },
    displayPriority: 6
  },
  {
    title: "Medium",
    description: "Connect a Medium blog to import new articles automatically.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-medium-blog",
    addtlInfoText: "Learn more.",
    imageSource: MediumIcon,
    isRecommended: false,
    targetUrl: null,
    type: "MEDIUM",
    connectModalCopy: [
      {
        heading: "Connect a Medium blog",
        description:
          "Enter your Medium @username (or the @username of a Medium user you like to share). Edgar will automatically import their 10 most recent posts and continue checking for new content automatically each night.",
        helpLinkText: "Learn more.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-medium-blog",
        placeholder: "@mediumusername",
        errorText:
          "Edgar couldn't find a Medium user by that name. Double check the user's @name is correct.",
        errorAddtlInfoText: " Learn more about finding a Medium user's @name.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-medium-blog"
      }
    ],
    configConnectionCopy: {
      heading: "Medium feed"
    },
    displayPriority: 9
  },
  {
    title: "Podcasts",
    description:
      "Enter your podcast's RSS feed to import new episodes automatically.",
    addtlInfoUrl:
      "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-podcast",
    addtlInfoText: "Learn more.",
    imageSource: PodcastsIcon,
    isRecommended: false,
    targetUrl: null,
    type: "PODCASTS",
    connectModalCopy: [
      {
        heading: "Connect a podcast",
        description:
          "Enter your podcast's RSS feed below. Not sure how to find your podcast's RSS feed?",
        helpLinkText: "Learn how here.",
        helpLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-podcast",
        placeholder: "enter your podcast RSS feed",
        errorText:
          "Edgar isn't able to find an RSS feed at that URL. Double check the URL is for your podcasts RSS feed. Learn how to find the link to a Podcast RSS feed here: ",
        errorAddtlInfoText: "Find a podcast RSS feed.",
        errorAddtlInfoLink:
          "https://help.meetedgar.com/en/articles/1618286-import-content-from-your-favorite-websites-and-services-automatically#connect-a-podcast"
      }
    ],
    configConnectionCopy: {
      heading: "Podcast feed"
    },
    displayPriority: 8
  }
];
